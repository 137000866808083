import React from 'react';
import styled from 'styled-components';

export default function GeneralResults(): JSX.Element {
  return (
    <Container>
      <Quantity>400 votos</Quantity>
      <LineContainer>
        <Line />
      </LineContainer>
      <Quantity>20 publicaciones</Quantity>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${(props) => props.theme.colors.lightSkyBlue};
  height: 54px;
  border-radius: 8px;
  padding-left: 26px;
  padding-right: 26px;
  width: fit-content;
  gap: 20px;
  margin-bottom: 30px;
`;

const Quantity = styled.p`
  color: ${(props) => props.theme.colors.skyBlue};
  font-family: ${(props) => props.theme.fonts.medium};
  font-size: 20px;
`;

const LineContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

const Line = styled.div`
  background-color: ${(props) => props.theme.colors.lightGray};
  width: 1px;
  height: 36px;
`;
