import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { Button, CircularProgress, Drawer, IconButton } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import AvailableOptionItem from './AvailableOptionItem';

interface Props {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
  title?: string;
  options: any[] | null;
  withInfiniteScroll?: boolean;
  canLoadMoreData?: boolean;
  isLoadingMoreData?: boolean;
  loadMoreData?: () => void;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
  type: string;
  categoriesIDs?: string[];
  setCategoriesIDs?: React.Dispatch<React.SetStateAction<string[]>>;
  resource?: string | null;
  setResource?: React.Dispatch<React.SetStateAction<string | null>>;
  selectedAuthors?: any[];
  setSelectedAuthors?: React.Dispatch<React.SetStateAction<any[]>>;
}

export default function OptionWithList({
  setIsOpen,
  isOpen,
  title,
  options,
  withInfiniteScroll,
  canLoadMoreData,
  isLoadingMoreData,
  loadMoreData,
  setUnsavedChanges,
  type,
  resource,
  setResource,
  categoriesIDs,
  setCategoriesIDs,
  selectedAuthors,
  setSelectedAuthors,
}: Props) {
  const closeDrawer = () => {
    setIsOpen(false);
  };

  const checkIfOptionsIsSelected = (item: any): boolean => {
    if (type === 'resource') {
      return item.value === resource;
    } else if (type === 'category' && categoriesIDs !== undefined) {
      return categoriesIDs?.includes(item.id);
    } else if (type === 'author' && selectedAuthors !== undefined) {
      return selectedAuthors?.findIndex((i) => i.authorId === item.id) !== -1;
    }
    return false;
  };

  const handleLoadMoreData = () => {
    if (loadMoreData !== undefined) {
      loadMoreData();
    }
  };

  const handleClick = (option: any) => {
    if (type === 'resource' && setResource !== undefined) {
      if (checkIfOptionsIsSelected(option)) {
        setResource(null);
      } else {
        setResource(option.value);
      }
    } else if (
      type === 'category' &&
      categoriesIDs !== undefined &&
      setCategoriesIDs !== undefined
    ) {
      if (checkIfOptionsIsSelected(option)) {
        const auxCategoriesIDs = [...categoriesIDs];
        setCategoriesIDs(auxCategoriesIDs.filter((item) => item !== option.id));
      } else {
        const auxCategoriesIDs = [...categoriesIDs, option.id];
        setCategoriesIDs(auxCategoriesIDs);
      }
    } else if (
      type === 'author' &&
      selectedAuthors !== undefined &&
      setSelectedAuthors !== undefined
    ) {
      if (checkIfOptionsIsSelected(option)) {
        const auxSelectedAuthors = [...selectedAuthors];
        setSelectedAuthors(auxSelectedAuthors.filter((item) => item.authorId !== option.id));
      } else {
        const auxSelectedAuthors = [
          ...selectedAuthors,
          { authorId: option.id, names: option.names },
        ];
        setSelectedAuthors(auxSelectedAuthors);
      }
    }
    setUnsavedChanges(true);
  };

  return (
    <StyledDrawer
      open={isOpen}
      anchor='left'
      ModalProps={{
        keepMounted: true,
      }}
      variant='persistent'
      sx={{
        '& .MuiDrawer-root': {
          position: 'absolute',
        },
        '& .MuiPaper-root': {
          position: 'absolute',
        },
      }}
    >
      <Container>
        <Header>
          <IconButton onClick={closeDrawer}>
            <ChevronLeft style={{ color: '#183582' }} />
          </IconButton>
          <Title>{title}</Title>
        </Header>
        {options == null ? (
          <LoaderContainer>
            <CircularProgress style={{ color: '#183582' }} size={30} />
          </LoaderContainer>
        ) : (
          <>
            {options?.map((item, index) => (
              <AvailableOptionItem
                key={index}
                option={item}
                isSelected={checkIfOptionsIsSelected(item)}
                handleClick={handleClick}
              />
            ))}
            {withInfiniteScroll && canLoadMoreData && (
              <StyledButton disabled={isLoadingMoreData} onClick={handleLoadMoreData}>
                {isLoadingMoreData ? (
                  <CircularProgress size={25} />
                ) : (
                  <ButtonText>Ver más</ButtonText>
                )}
              </StyledButton>
            )}
          </>
        )}
      </Container>
    </StyledDrawer>
  );
}

const StyledDrawer = styled(Drawer)``;

const Container = styled.div`
  width: calc(100vw - 40px);
  padding-bottom: 50px;
`;

const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
`;

const Title = styled.p`
  flex: 1;
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.primary};
  font-size: 16px;
  margin-left: 4px;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const ButtonText = styled.p`
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.primary};
  text-transform: none;
  font-size: 16px;
`;
