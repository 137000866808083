import React, { type ChangeEvent, useState } from 'react';
import { RiSearchLine } from 'react-icons/ri';
import styled from 'styled-components';

interface Props {
  title: string;
  handleInputChange: (value: string) => void;
}

interface InputI {
  inputIsFocus: boolean;
}

export default function SearchBar({ title, handleInputChange }: Props) {
  const [inputIsFocus, setInputIsFocus] = useState(false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleInputChange(event.target.value);
  };

  return (
    <InputContainer inputIsFocus={inputIsFocus}>
      <RiSearchLine color='#183582' size={25} />
      <Input
        onChange={handleChange}
        onFocus={() => {
          setInputIsFocus(true);
        }}
        onBlur={() => {
          setInputIsFocus(false);
        }}
        placeholder={`Buscar en ${title}`}
      />
    </InputContainer>
  );
}

const InputContainer = styled.div<InputI>`
  display: none;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: #f0f6fa;
  height: 34px;
  border-radius: 20px;
  padding-left: 12px;
  border: 1px solid ${(props) => (props.inputIsFocus ? props.theme.colors.primary : 'transparent')};
  margin-bottom: 20px;

  @media screen and (max-width: 860px) {
    display: flex;
    width: 100%;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
    height: 40px;
  }
`;

const Input = styled.input`
  flex: 1;
  color: ${(props) => props.theme.colors.primary};
  font-family: ${(props) => props.theme.fonts.regular};
  height: 100%;
  font-size: 16px;
  border: none;
  outline: none;
  margin-left: 10px;
  border-radius: 20px;
  background-color: transparent;
  ::placeholder {
    color: ${(props) => props.theme.colors.primary};
    font-family: ${(props) => props.theme.fonts.regular};
  }
`;
