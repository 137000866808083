import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonBase, CircularProgress, IconButton } from '@mui/material';
import { AxiosError } from 'axios';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import UploadFileIcon from '../../icons/UploadFileIcon';
import { type RootState } from '../../context/store';
import POSTS_STATUS from '../../constants/status';
import {
  setElementsContent,
  setUnsavedChanges,
  setWantToDownloadPDF,
} from '../../context/reducers/editor';
import { isAudioFileFormatValid, isPDFfileFormatValid } from '../../utils/files';
import { uploadFiles } from '../../api/files';
import { openSnackbar } from '../../context/reducers/generalSnackbar';
import { FILES_ERRORS } from '../../constants/errorMessages';
import { PDF_URL } from '../../constants/editorKeys';
import TrashIcon from '../../icons/TrashIcon';
import { RiDeleteBin6Line, RiDownload2Fill, RiMenuLine } from 'react-icons/ri';
import CustomMenu from '../globals/CustomMenu';
import sleep from '../../utils/sleep';

export default function UploadPDFSection() {
  const elementsContent = useSelector((state: RootState) => state.editor.elementsContent);
  const currentPostStatus = useSelector((state: RootState) => state.editor.currentPostStatus);
  const wantToDownloadPDF = useSelector((state: RootState) => state.editor.wantToDownloadPDF);
  const [errorMessage, setErrorMessage] = useState('');
  const [uploading, setUploading] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState<File | null>(null);
  const [pdfUrl, setPdfUrl] = useState<string>('');
  const [numPages, setNumPages] = useState<null | number>(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const inputFile = useRef<HTMLInputElement>(null);
  const pdfRef = useRef<HTMLAnchorElement>(null);
  const dispatch = useDispatch();
  const defaultLayoutPluginInstance = defaultLayoutPlugin({});

  useEffect(() => {
    const index = elementsContent.findIndex((item) => item.customKey === PDF_URL);
    if (index !== -1 && elementsContent[index].content !== null) {
      setPdfUrl(elementsContent[index].content as string);
    }
  }, [elementsContent]);

  const callApiToUploadFile = async () => {
    try {
      setPdfUrl('');
      setUploading(true);
      const formData = new FormData();
      if (selectedPdf) {
        formData.append('file', selectedPdf);
      }
      const response = await uploadFiles(formData);
      const { location } = response;
      setPdfUrl(location);
      setUploading(false);
      setSelectedPdf(null);
    } catch (error: unknown) {
      let errorStatus;
      if (error instanceof AxiosError) {
        errorStatus = error?.response?.status;
      }
      if (errorStatus !== 401) {
        dispatch(
          openSnackbar({
            type: 'error',
            message: FILES_ERRORS.PDF,
          }),
        );
        setUploading(false);
      }
    }
  };

  useEffect(() => {
    if (selectedPdf !== null) {
      callApiToUploadFile();
    }
  }, [selectedPdf]);

  useEffect(() => {
    dispatch(
      setElementsContent({
        customKey: PDF_URL,
        content: pdfUrl || null,
      }),
    );
  }, [pdfUrl]);

  const selectFile = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e?.target?.files != null && e?.target?.files?.length !== 0) {
      const file = e.target.files[0] || null;

      if (e.target.value) {
        e.target.value = '';
      }

      if (!isPDFfileFormatValid(file)) {
        setErrorMessage(
          'El archivo seleccionado es inválido. Solo se permiten archivos con el formato .pdf',
        );
      } else if (file && file?.size <= 5 * 1024 * 1024) {
        setSelectedPdf(file);
        dispatch(
          setUnsavedChanges({
            unsavedChanges: true,
          }),
        );
      } else {
        setErrorMessage('El archivo excede el tamaño máximo permitido (Máx 5MB)');
      }
    }
  };

  const openInputFile = () => {
    if (currentPostStatus === POSTS_STATUS.PUBLISHED || currentPostStatus === null) {
      return;
    }
    setErrorMessage('');
    inputFile?.current?.click();
  };

  const deletePDF = () => {
    dispatch(
      setElementsContent({
        customKey: PDF_URL,
        content: null,
      }),
    );
    dispatch(
      setUnsavedChanges({
        unsavedChanges: true,
      }),
    );
    setPdfUrl('');
  };

  useEffect(() => {
    const resetWantToDownloadPDF = async () => {
      if (wantToDownloadPDF) {
        await sleep(500);
        dispatch(
          setWantToDownloadPDF({
            wantToDownloadPDF: false,
          }),
        );
      }
    };
    resetWantToDownloadPDF();
  }, [wantToDownloadPDF]);

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const downloadPDF = async (e: React.MouseEvent<HTMLAnchorElement>): Promise<void> => {
    if (pdfUrl) {
      setAnchorEl(null);
      dispatch(
        setWantToDownloadPDF({
          wantToDownloadPDF: true,
        }),
      );
      await sleep(100);
      pdfRef?.current?.click();
    }
  };

  const options = [
    {
      label: 'Subir nuevo PDF',
      icon: <FileUploadOutlinedIcon />,
      action: () => {
        setAnchorEl(null);
        openInputFile();
      },
    },
    {
      label: 'Eliminar',
      icon: <RiDeleteBin6Line style={{ fontSize: '20px' }} />,
      action: () => {
        setAnchorEl(null);
        deletePDF();
      },
    },
    {
      label: 'Descargar',
      icon: <RiDownload2Fill style={{ fontSize: '20px' }} />,
      action: (e: React.MouseEvent<HTMLAnchorElement>) => {
        downloadPDF(e);
      },
    },
  ];

  if (pdfUrl !== '') {
    return (
      <PDFContainer>
        <a ref={pdfRef} href={pdfUrl} style={{ display: 'none' }} download>
          Download PDF
        </a>
        <input
          type='file'
          onChange={selectFile}
          ref={inputFile}
          style={{ display: 'none' }}
          accept='.pdf'
        />
        <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js'>
          <Viewer fileUrl={pdfUrl} withCredentials={false} enableSmoothScroll={true} />
        </Worker>
        {currentPostStatus !== POSTS_STATUS.PUBLISHED && (
          <>
            <MenuButton onClick={handleClick}>
              <RiMenuLine style={{ color: 'white', fontSize: '20px' }} />
            </MenuButton>
            <CustomMenu
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              options={options}
            />
          </>
        )}
      </PDFContainer>
    );
  }

  return (
    <Container onClick={openInputFile}>
      <input
        type='file'
        onChange={selectFile}
        ref={inputFile}
        style={{ display: 'none' }}
        accept='.pdf'
      />
      {uploading ? (
        <CircularProgress />
      ) : (
        <>
          <UploadFileIcon />
          <Text>Sube tu archivo en formato PDF</Text>
        </>
      )}
      {errorMessage !== '' && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Container>
  );
}

const PDFContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 24px;
  margin-bottom: 45px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Container = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 26px;
  height: 273px;
  background-color: ${(props) => props.theme.colors.matizBlue};
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid transparent;
  transition: border 0.1s ease-in, background-color 0.2s linear;

  &:hover {
    background-color: #5c9cb52a;
    border-color: ${(props) => props.theme.colors.primary};
  }

  @media screen and (max-width: 860px) {
    margin-top: 0px;
  }
`;

const Text = styled.p`
  text-align: center;
  font-family: ${(props) => props.theme.fonts.semiBold};
  color: ${(props) => props.theme.colors.darkGray};
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  margin-top: 25px;
`;

const ErrorMessage = styled.p`
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.red};
  font-size: 12px;
  margin-top: 8px;
`;

const MenuButton = styled(ButtonBase)`
  background-color: ${(props) => props.theme.colors.secondary};
  width: 32px;
  height: 32px;
  margin-left: 18px;
  border-radius: 5px;
`;
