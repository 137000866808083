import React, { useState } from 'react';
import styled from 'styled-components';
import CircleIcon from '@mui/icons-material/Circle';
import { type EditorState } from 'draft-js';
import CustomDraftWysiwyg from './CustomDraftWysiwyg';
import { KEY_IDEA_ITEM } from '../../constants/editorKeys';
import PreviewCustomDraftWysiwyg from './PreviewCustomDraftWysiwyg';

interface Props {
  placeholder: string;
  keyNumber: number;
  editorState: EditorState;
}

export default function PreviewKeyIdeaItem({
  placeholder,
  keyNumber,
  editorState,
}: Props): JSX.Element {
  return (
    <Container>
      <CircleIcon style={{ color: 'white', height: '6px', width: '6px', marginTop: '18px' }} />
      <DraftWysiwgContainer>
        <PreviewCustomDraftWysiwyg
          keyNumber={keyNumber}
          customKey={KEY_IDEA_ITEM}
          placeholder={placeholder}
          fontWeight={500}
          fontSize='17px'
          fontFamily='Figtree'
          color='white'
          editorState={editorState}
        />
      </DraftWysiwgContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 8px;
  margin-right: 10px;
`;

const DraftWysiwgContainer = styled.div`
  flex: 1;
`;

const Text = styled.p`
  margin-left: 8px;
`;
