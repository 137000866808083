import { createSlice } from '@reduxjs/toolkit';
import type Category from '../../interfaces/Category';

interface InitialStateI {
  categoriesList: Category[] | null;
  isRemovingCategory: boolean;
  categoryId: string | null;
}

const initialState: InitialStateI = {
  categoriesList: null,
  isRemovingCategory: false,
  categoryId: null,
};

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    setCategoriesList: (state, action) => {
      state.categoriesList = action.payload.categoriesList;
    },
    setIsRemovingCategory: (state, action) => {
      state.isRemovingCategory = action.payload.isRemovingCategory;
    },
    setCategoryId: (state, action) => {
      state.categoryId = action.payload.categoryId;
    },
  },
});

export const { setCategoriesList, setCategoryId, setIsRemovingCategory } = categoriesSlice.actions;
export default categoriesSlice.reducer;
