import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import Header from '../components/layout/Header';
import Sidebar from '../components/layout/Sidebar';
import CustomSnackbar from '../components/globals/CustomSnackbar';
import { type RootState } from '../context/store';
import { closeSnackbar } from '../context/reducers/generalSnackbar';
import { getTokens, setTokensInHeader } from '../api/config';
import { CircularProgress } from '@mui/material';
import { checkTokenExpiration } from '../utils/token';
import { PLAYSTORE_URL } from '../constants/stores';

interface MainContainerI {
  enabledScroll: boolean;
}

interface Tokens {
  idToken: string | null;
  accessToken: string | null;
}

const Layout: React.FC = () => {
  const generalSnackbar = useSelector((state: RootState) => state.generalSnackbar);
  const generalSearchbarIsVisible = useSelector(
    (state: RootState) => state.generalSearch.isVisible,
  );
  const [tokens, setTokens] = useState<Tokens>({
    idToken: null,
    accessToken: null,
  });
  const location = useLocation();
  const dispatch = useDispatch();
  const { loginWithRedirect, getIdTokenClaims, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const getToken = async () => {
      const { idToken, accessToken } = getTokens();
      const pathname = location.pathname;
      if (pathname === '/android') {
        window.location.href = PLAYSTORE_URL;
      } else {
        if (idToken == null || accessToken == null || checkTokenExpiration(idToken)) {
          try {
            const newAccessToken = await getAccessTokenSilently();
            const token = await getIdTokenClaims();
            const newIdToken = token?.__raw;
            if (newIdToken && newAccessToken) {
              setTokensInHeader(newIdToken, newAccessToken);
              setTokens({ idToken: newIdToken, accessToken: newAccessToken });
            } else {
              loginWithRedirect();
            }
          } catch (err) {
            loginWithRedirect();
          }
        } else {
          setTokensInHeader(idToken, accessToken);
          setTokens({ idToken, accessToken });
        }
      }
    };
    getToken();
  }, [getAccessTokenSilently, getIdTokenClaims]);

  if (tokens.accessToken == null || tokens.idToken == null) {
    return (
      <CircularProgressContainer>
        <CircularProgress />
      </CircularProgressContainer>
    );
  }

  return (
    <Container id='main-layout'>
      <Header />
      <MainContainer enabledScroll={generalSearchbarIsVisible}>
        {location.pathname !== '/new-post' &&
          location.pathname !== '/new-presentation' &&
          !location.pathname.startsWith('/post/') &&
          !location.pathname.startsWith('/presentation/') && <Sidebar />}
        <Outlet />
      </MainContainer>
      <CustomSnackbar
        type={generalSnackbar.type}
        open={generalSnackbar.isOpen}
        setOpen={() => dispatch(closeSnackbar({}))}
        message={generalSnackbar.message}
      />
    </Container>
  );
};

const CircularProgressContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div``;

const MainContainer = styled.div<MainContainerI>`
  display: grid;
  grid-template-columns: 192px 1fr;
  overflow: ${(props) => (props.enabledScroll ? 'scroll' : 'hidden')};
`;

export default Layout;
