import React from 'react';
import styled from 'styled-components';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import NotFound404Icon from '../icons/NotFound404Icon';
import POSTS_STATUS from '../constants/status';

export default function NotFound404() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/entries?type=${POSTS_STATUS.DRAFT.toLowerCase()}`);
  };

  return (
    <Container>
      <NotFound404Icon />
      <Message>
        No podemos encontrar la página que buscas. Si quieres revisar un artículo específico, usa el
        buscador o sigue el siguiente enlace:
      </Message>
      <CustomButton className='bg-primary rounded-2xl' onClick={handleClick}>
        <ButtonText>Todas las Entradas</ButtonText>
      </CustomButton>
    </Container>
  );
}

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 24px;
  padding-right: 24px;
  margin-left: 192px;
  margin-top: 72px;
  width: calc(100vw - 192px);
  height: calc(100vh - 120px);
`;

const Message = styled.p`
  font-family: ${(props) => props.theme.fonts.medium};
  color: black;
  font-size: 18px;
  text-align: center;
  max-width: 400px;
  margin-top: 32px;
`;

const CustomButton = styled(Button)`
  margin-top: 32px;
  background-color: ${(props) => props.theme.colors.skyBlue};
  padding-left: 20px;
  padding-right: 20px;
`;

const ButtonText = styled.p`
  text-transform: none;
  font-family: ${(props) => props.theme.fonts.medium};
  font-size: 13px;
  color: white;
`;
