import React, { useState } from 'react';
import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import TrashIcon from '../../icons/TrashIcon';
import CustomSwitch from '../globals/CustomSwitch';
import ConfirmDeleteModal from './ConfirmDeleteModal';

export default function SurveyItem(): JSX.Element {
  const [entriesQuantity, setEntriesQuantity] = useState(0);
  const [questionsQuantity, setQuestionsQuantity] = useState(0);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const handleOnClickTrashIcon = (): void => {
    setShowConfirmDeleteModal(true);
  };

  const handleClickEdit = (): void => {};

  return (
    <>
      <Container>
        <TextContainer>
          <Name>¿Habrá vacancia de Dina Boluarte en el 2023?</Name>
        </TextContainer>
        <TextContainer>
          <Quantity>25</Quantity>
        </TextContainer>
        <TextContainer>
          <Quantity>25</Quantity>
        </TextContainer>
        <OptionsContainer>
          <CustomSwitch />
          <ButtonsContainer>
            <CustomButton onClick={handleClickEdit} className='bg-sky-blue px-5 rounded-lg mr-2'>
              <ButtonText>Editar</ButtonText>
            </CustomButton>
            <IconButton onClick={handleOnClickTrashIcon}>
              <TrashIcon />
            </IconButton>
          </ButtonsContainer>
        </OptionsContainer>
      </Container>
      <ConfirmDeleteModal
        isVisible={showConfirmDeleteModal}
        setIsVisible={setShowConfirmDeleteModal}
        entriesQuantity={entriesQuantity}
      />
    </>
  );
}

const Container = styled.li`
  cursor: pointer;
  list-style-type: none;
  display: grid;
  grid-template-columns: 1.5fr 0.5fr 0.5fr 0.75fr;
  align-content: center;
  gap: 20px;
  padding-right: 30px;
  padding-left: 30px;
  padding-top: 18px;
  padding-bottom: 18px;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray2};
  transition: background-color 0.1s ease-out;

  :hover {
    background-color: rgba(0, 0, 0, 0.09);
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Name = styled.p`
  font-family: ${(props) => props.theme.fonts.regular};
`;

const Quantity = styled.p``;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  margin-left: 70px;
`;

const CustomButton = styled(Button)`
  &&& {
    border: 1px solid;
    border-color: ${(props) => props.theme.colors.skyBlue};
  }
`;

const ButtonText = styled.p`
  font-family: ${(props) => props.theme.fonts.medium};
  color: white;
  text-transform: none;
  font-size: 12px;
`;
