import React from 'react';
import styled from 'styled-components';
import PostItem from './PostItem';

export default function VotingBreakdown(): JSX.Element {
  return (
    <Container>
      <Title>Detalle de votos</Title>
      <PostItem />
      <PostItem />
      <PostItem />
    </Container>
  );
}

const Container = styled.div`
  padding-top: 18px;
`;

const Title = styled.p`
  font-family: ${(props) => props.theme.fonts.medium};
  font-size: 20px;
  margin-bottom: 24px;
`;
