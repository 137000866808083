import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import DrawerHeader from './DrawerHeader';
import {
  validationLinkEmbeddedYouTube,
  validationYoutubeEmail,
} from '../../utils/validationYoutubeLink';
import VideoPlayer from './VideoPlayer';
import { useDispatch, useSelector } from 'react-redux';
import { type RootState } from '../../context/store';
import {
  setElements,
  setIsMobileVideoDrawerOpen,
  setPreviewElements,
  setUnsavedChanges,
} from '../../context/reducers/editor';
import { Drawer } from '@mui/material';
import { validationVimeoLink } from '../../utils/validationVimeoLink';

interface Props {
  link: string;
  setLink: React.Dispatch<React.SetStateAction<string>>;
}

interface InpuI {
  error: boolean;
}

export default function MobileVideoDrawer({ link, setLink }: Props): JSX.Element {
  const isOpen = useSelector((state: RootState) => state.editor.isMobileVideoDrawerOpen);
  const elements = useSelector((state: RootState) => state.editor.elements);
  const previewElements = useSelector((state: RootState) => state.editor.previewElements);
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isOpen) {
      setErrorMessage('');
    }
    if (isOpen) {
      const video =
        elements[elements.findIndex((item) => item.key === 'video')].element.props?.link;
      setLink(video ?? '');
    }
  }, [isOpen]);

  const handleClickSaveButton = (): void => {
    if (link === '') {
      setErrorMessage('El campo está vacío');
    } else if (!validationLinkEmbeddedYouTube(link.trim()) && !validationVimeoLink(link.trim())) {
      setErrorMessage('Coloca un link embebido válido de Youtube o Vimeo');
    } else {
      const newElement = {
        key: 'video',
        element: <VideoPlayer link={link} />,
      };
      const newElements = [...elements];
      const newPreviewElements = [...previewElements];
      const index = newElements.findIndex((item) => item.key === newElement.key);
      newElements[index] = newElement;
      newPreviewElements[index] = newElement;
      dispatch(
        setElements({
          elements: newElements,
        }),
      );
      dispatch(
        setPreviewElements({
          previewElements: newPreviewElements,
        }),
      );
      dispatch(
        setUnsavedChanges({
          unsavedChanges: true,
        }),
      );
      dispatch(
        setIsMobileVideoDrawerOpen({
          isDrawerOpen: false,
        }),
      );
      setLink('');
      setErrorMessage('');
    }
  };

  const handleClose = () => {
    dispatch(
      setIsMobileVideoDrawerOpen({
        isDrawerOpen: false,
      }),
    );
  };

  const setIsOpen = (value: boolean) => {
    dispatch(
      setIsMobileVideoDrawerOpen({
        isDrawerOpen: value,
      }),
    );
  };

  return (
    <StyledDrawer
      anchor='bottom'
      ModalProps={{
        keepMounted: true,
      }}
      PaperProps={{
        style: {
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
        },
      }}
      open={isOpen}
      onClose={handleClose}
    >
      <DrawerContent>
        <DrawerHeader title='Añadir video' subtitle='' setIsOpen={setIsOpen} />
        <Card>
          <Title>Video</Title>
          <Input
            error={errorMessage !== ''}
            value={link}
            type='text'
            placeholder='Añadir link de Youtube o Vimeo'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setErrorMessage('');
              setLink(e.target.value);
            }}
          />
          {errorMessage !== '' && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </Card>
        <Footer>
          <SaveButton onClick={handleClickSaveButton}>
            <SaveBtnText>Guardar</SaveBtnText>
          </SaveButton>
        </Footer>
      </DrawerContent>
    </StyledDrawer>
  );
}

const StyledDrawer = styled(Drawer)``;

const DrawerContent = styled.div`
  background-color: white;
  width: 100vw;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Content = styled.div`
  flex: 1;
`;

const Card = styled.div`
  margin: 12px 20px;
  border: 1px solid ${(props) => props.theme.colors.lightSkyBlue};
  border-radius: 5px;
  padding-top: 21px;
  padding-left: 13px;
  padding-bottom: 28px;
  margin-bottom: 40px;
`;

const ErrorMessage = styled.p`
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.red};
  font-size: 10px;
  margin-top: 8px;
`;

const Title = styled.p`
  font-family: ${(props) => props.theme.fonts.bold};
  color: ${(props) => props.theme.colors.darkGray};
`;

const Input = styled.input<InpuI>`
  height: 40px;
  border: 1px solid
    ${(props) => (props.error ? props.theme.colors.red : props.theme.colors.lightSkyBlue)};
  outline: none;
  padding: 0px 14px;
  margin-top: 8px;
  width: calc(100% - 38px);
  border-radius: 3px;
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => (props.error ? props.theme.colors.red : props.theme.colors.black)};
  ::placeholder {
    color: ${(props) => (props.error ? props.theme.colors.red : props.theme.colors.accentBlue)};
  }
  :focus {
    border-color: ${(props) =>
      props.error ? props.theme.colors.red : props.theme.colors.accentBlue};
  }
`;

const Footer = styled.footer`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 17px 20px;
  border: 1px solid ${(props) => props.theme.colors.gray2};
`;

const SaveButton = styled(Button)`
  &&& {
    background-color: ${(props) => props.theme.colors.primary};
  }
  padding: 8px 40px;
  border-radius: 20px;
`;

const SaveBtnText = styled.p`
  font-family: ${(props) => props.theme.fonts.bold};
  text-transform: none;
  color: white;
`;
