import React from 'react';
import type Icon from '../interfaces/Icon';

export default function PenIcon({ color = '#9A9A9A' }: Icon) {
  return (
    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16.9789 23.2628L20.1246 14.7247L15.2754 9.87549L6.73734 13.0212L3.62338 25.8796L9.97582 19.5271C9.56338 18.988 9.37137 18.3122 9.43873 17.6367C9.50609 16.9613 9.82778 16.3367 10.3386 15.8897C10.8494 15.4426 11.511 15.2065 12.1895 15.2292C12.8679 15.252 13.5123 15.5318 13.992 16.0121C14.4717 16.4923 14.7509 17.137 14.7729 17.8154C14.7949 18.4939 14.5581 19.1553 14.1105 19.6656C13.6629 20.1759 13.038 20.497 12.3625 20.5636C11.687 20.6302 11.0114 20.4375 10.4727 20.0245L4.12049 26.3767L16.9789 23.2628Z'
        fill={color}
      />
      <path
        d='M13.5112 7.12061L17.0064 3.62547L26.3725 12.9915L22.8773 16.4867L13.5112 7.12061Z'
        fill={color}
      />
      <path
        d='M12.1003 19.8734C12.4906 19.8734 12.8722 19.7577 13.1968 19.5408C13.5213 19.3239 13.7743 19.0157 13.9237 18.6551C14.073 18.2944 14.1121 17.8976 14.036 17.5147C13.9598 17.1319 13.7718 16.7802 13.4958 16.5042C13.2663 16.2748 12.9837 16.1054 12.6732 16.0112C12.3626 15.917 12.0336 15.9009 11.7153 15.9642C11.3969 16.0275 11.0992 16.1684 10.8483 16.3743C10.5974 16.5802 10.4012 16.8448 10.277 17.1446C10.1528 17.4445 10.1045 17.7703 10.1363 18.0933C10.1681 18.4163 10.2791 18.7264 10.4594 18.9963C10.6397 19.2661 10.8838 19.4873 11.17 19.6403C11.4562 19.7933 11.7757 19.8734 12.1003 19.8734Z'
        fill={color}
      />
    </svg>
  );
}
