import React, { useState } from 'react';
import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import TrashIcon from '../../icons/TrashIcon';
import EntryItem from './EntryItem';
import ConfirmDeleteModal from './ConfIrmDeleteModal';
import type TagDetails from '../../interfaces/TagDetails';
import { useDispatch, useSelector } from 'react-redux';
import { openSnackbar } from '../../context/reducers/generalSnackbar';
import { TAGS_ERRORS } from '../../constants/errorMessages';
import { deleteTag } from '../../api/tags';
import { setTagDeleted, setTags } from '../../context/reducers/tags';
import { type RootState } from '../../context/store';
import { AxiosError } from 'axios';

interface Props extends TagDetails {}

export default function TagItem({ tag, id }: Props): JSX.Element {
  const tagDeleted = useSelector((state: RootState) => state.tags.tagDeleted);
  const tags = useSelector((state: RootState) => state.tags.tags);
  const [seeDetails, setSeeDetails] = useState(false);
  const [entriesQuantity, setEntriesQuantity] = useState(0);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const dispatch = useDispatch();

  const handleOnClick = (): void => {
    setSeeDetails(!seeDetails);
  };

  const handleOnClickTrashIcon = (): void => {
    setShowConfirmDeleteModal(true);
  };

  const confirmDeleteTag = async (): Promise<void> => {
    setShowConfirmDeleteModal(false);
    if (!deleting) {
      try {
        setDeleting(true);
        await deleteTag(id);
        if (tags !== null) {
          dispatch(
            setTags({
              tags: tags?.filter((item) => item.id !== id),
            }),
          );
        }
        dispatch(
          openSnackbar({
            type: 'success',
            message: 'Etiqueta eliminada correctamente',
          }),
        );
      } catch (error: unknown) {
        let errorStatus;
        if (error instanceof AxiosError) {
          errorStatus = error?.response?.status;
        }
        if (errorStatus !== 401) {
          setDeleting(false);
          dispatch(
            openSnackbar({
              type: 'error',
              message: TAGS_ERRORS.DELETE_TAG,
            }),
          );
        }
      }
    }
  };

  return (
    <>
      <Container>
        <TextContainer>
          <Name>{tag}</Name>
        </TextContainer>
        <ButtonsContainer>
          <IconButton onClick={handleOnClickTrashIcon}>
            <TrashIcon />
          </IconButton>
        </ButtonsContainer>
      </Container>
      {seeDetails && (
        <EntriesList>
          <EntryItem />
          <EntryItem />
          <EntryItem />
        </EntriesList>
      )}
      <ConfirmDeleteModal
        isVisible={showConfirmDeleteModal}
        setIsVisible={setShowConfirmDeleteModal}
        entriesQuantity={entriesQuantity}
        tag={tag}
        deleting={deleting}
        setDeleting={setDeleting}
        confirmDeleteTag={confirmDeleteTag}
      />
    </>
  );
}

const Container = styled.li`
  cursor: pointer;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  gap: 20px;
  padding-right: 30px;
  padding-left: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray2};
  height: 60px;
  transition: background-color 0.1s ease-out;

  :hover {
    background-color: rgba(0, 0, 0, 0.09);
  }
`;

const TextContainer = styled.div`
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`;

const Name = styled.p`
  font-family: ${(props) => props.theme.fonts.medium};
  text-transform: capitalize;
`;

const EntriesQuantity = styled.p`
  font-family: ${(props) => props.theme.fonts.medium};
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
`;

const CustomButton = styled(Button)`
  &&& {
    border: 1px solid;
    border-color: ${(props) => props.theme.colors.skyBlue};
  }
`;

const ButtonText = styled.p`
  font-family: ${(props) => props.theme.fonts.medium};
  color: white;
  text-transform: none;
  font-size: 12px;
`;

const EntriesList = styled.ul``;
