/* eslint-disable no-useless-escape */
export const validationYoutubeEmail = (link: string): boolean => {
  const re =
    /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/gm;
  return re.test(link);
};

export const validationLinkEmbeddedYouTube = (link: string): boolean => {
  const patron =
    /^https?:\/\/(?:www\.)?youtube\.com\/embed\/[a-zA-Z0-9_-]{11}(?:\?si=[a-zA-Z0-9_-]+)?$/;
  return patron.test(link);
};
