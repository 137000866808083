import React from 'react';
import type Icon from '../interfaces/Icon';

export default function CalendarIcon({ color = '#006F9B' }: Icon): JSX.Element {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.1667 2.50004H16.5001C16.7211 2.50004 16.9331 2.58784 17.0893 2.74412C17.2456 2.9004 17.3334 3.11236 17.3334 3.33337V16.6667C17.3334 16.8877 17.2456 17.0997 17.0893 17.256C16.9331 17.4122 16.7211 17.5 16.5001 17.5H1.50008C1.27907 17.5 1.06711 17.4122 0.910826 17.256C0.754545 17.0997 0.666748 16.8877 0.666748 16.6667V3.33337C0.666748 3.11236 0.754545 2.9004 0.910826 2.74412C1.06711 2.58784 1.27907 2.50004 1.50008 2.50004H4.83341V0.833374H6.50008V2.50004H11.5001V0.833374H13.1667V2.50004ZM11.5001 4.16671H6.50008V5.83337H4.83341V4.16671H2.33341V7.50004H15.6667V4.16671H13.1667V5.83337H11.5001V4.16671ZM15.6667 9.16671H2.33341V15.8334H15.6667V9.16671Z'
        fill={color}
      />
    </svg>
  );
}
