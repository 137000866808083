import {
  AtomicBlockUtils,
  ContentBlock,
  ContentState,
  Editor,
  EditorState,
  SelectionState,
  convertToRaw,
} from 'draft-js';
import Immutable from 'immutable';
import { type ElementContent } from '../interfaces/Editor';
import { EDITOR_NOTES } from '../constants/editorKeys';

export const addCustomElement = (
  currentEditorState: EditorState,
  type: string,
  imageUrl?: string | File,
) => {
  const currentContentState = currentEditorState.getCurrentContent();
  const selectionState = currentEditorState.getSelection();
  const currentBlockKey = selectionState.getStartKey();
  const currentBlock = currentContentState.getBlockForKey(currentBlockKey);

  const componentBlock = new ContentBlock({
    key: Math.random().toString(36).substr(2, 9),
    type,
    text: '',
    data:
      type === 'custom-image'
        ? typeof imageUrl === 'string'
          ? Immutable.Map({ url: imageUrl, height: null, width: null })
          : Immutable.Map({ file: imageUrl, height: null, width: null })
        : null,
  });

  let newContentState;
  let newEditorState;

  if (currentBlockKey === currentContentState.getLastBlock().getKey()) {
    newContentState = ContentState.createFromBlockArray([
      ...currentContentState.getBlocksAsArray(),
      componentBlock,
    ]);
    newEditorState = EditorState.createWithContent(newContentState);
  } else {
    const blockArray = currentContentState.getBlocksAsArray();
    const currentIndex = blockArray.findIndex(
      (block: ContentBlock) => block.getKey() === currentBlockKey,
    );
    const blocksAfter = blockArray.slice(currentIndex + 1);
    const newBlocks = [...blockArray.slice(0, currentIndex + 1), componentBlock, ...blocksAfter];

    newContentState = ContentState.createFromBlockArray(newBlocks);
    newEditorState = EditorState.createWithContent(newContentState);
  }

  const emptyBlock = new ContentBlock({
    key: Math.random().toString(36).substr(2, 9),
    type: 'unstyled',
    text: '',
  });
  const newContentStateWithEmptyBlock = ContentState.createFromBlockArray([
    ...newContentState.getBlocksAsArray(),
    emptyBlock,
  ]);
  const newEditorStateWithEmptyBlock = EditorState.createWithContent(newContentStateWithEmptyBlock);

  const selectionStateWithFocus = newEditorStateWithEmptyBlock.getSelection().merge({
    anchorKey: emptyBlock.getKey(),
    anchorOffset: 0,
    focusKey: emptyBlock.getKey(),
    focusOffset: 0,
  });
  const editorStateWithFocus = EditorState.forceSelection(
    newEditorStateWithEmptyBlock,
    selectionStateWithFocus,
  );

  return editorStateWithFocus;

  /*   setCurrentEditorState(editorStateWithFocus); */
  /* setTimeout(() => {
      if (editorRef.current) {
        editorRef.current?.focusEditor();
      }
      setCurrentEditorState(editorStateWithFocus);
    }, 0); */
};

export const getPlainText = (editorState: EditorState): string => {
  const currentContentState = editorState.getCurrentContent();
  const rawContentState = convertToRaw(currentContentState);
  const plainText = rawContentState.blocks.map((block) => block.text.trim()).join('\n');
  return plainText;
};

export const getImagesQuantity = (editorState: EditorState): number => {
  if (editorState === null) {
    return 0;
  }
  const currentContent = editorState.getCurrentContent();
  let imagesQuantity = 0;
  currentContent.getBlocksAsArray().forEach((block, index) => {
    if (block.getType() === 'custom-image') {
      imagesQuantity++;
    }
  });
  return imagesQuantity;
};

export const checkIfPostHasEditorNotes = (elementsContent: ElementContent[]): boolean => {
  const editorNotes = elementsContent[
    elementsContent.findIndex((item) => item.customKey === EDITOR_NOTES)
  ].content as string;
  if (editorNotes == null) {
    return false;
  }
  return editorNotes !== '';
};

export const updateBlockData = (
  editorState: EditorState,
  blockKey: string,
  newData: any,
): EditorState | null => {
  const contentState = editorState.getCurrentContent();
  const blockMap = contentState.getBlockMap();
  const blockToUpdate = blockMap.get(blockKey);

  if (blockToUpdate) {
    const newBlock = blockToUpdate.merge({
      data: blockToUpdate.getData().merge(newData),
    }) as ContentBlock;

    const newBlockMap = blockMap.set(blockKey, newBlock);
    const newContentState = ContentState.createFromBlockArray(newBlockMap.toArray());

    const newEditorState = EditorState.push(editorState, newContentState, 'change-block-data');
    return newEditorState;
  }

  return null;
};

export const selectEntityByKey = (
  editorState: EditorState,
  entityKey: string,
): EditorState | null => {
  const contentState = editorState.getCurrentContent();
  const blockMap = contentState.getBlockMap();
  const selection = editorState.getSelection();
  let finalEditorState = null;
  blockMap.forEach((block) => {
    let entityStart: number | null = null;
    let entityEnd: number | null = null;
    let foundEntity = false;
    const auxEntityKey = block?.getEntityAt(selection.getStartOffset());
    block?.findEntityRanges(
      (character) => {
        const entity = character.getEntity();
        if (entity !== null && entity === entityKey) {
          foundEntity = true;
          return true;
        }
        return false;
      },
      (start, end) => {
        entityStart = start;
        entityEnd = end;
      },
    );
    if (foundEntity && block !== undefined) {
      const selection = editorState.getSelection().merge({
        anchorOffset: entityStart || 0,
        focusOffset: entityEnd || 0,
      });

      const newEditorState = EditorState.forceSelection(editorState, selection);
      finalEditorState = newEditorState;
    }
  });
  return finalEditorState;
};
