import { createSlice } from '@reduxjs/toolkit';
import POSTS_STATUS from '../../constants/status';

interface InitialState {
  selectedStatus: string;
  draftsQuantity: undefined | number;
  pendingReviewQuantity: undefined | number;
  publishedQuantity: undefined | number;
}

const initialState: InitialState = {
  selectedStatus: POSTS_STATUS.DRAFT,
  draftsQuantity: undefined,
  pendingReviewQuantity: undefined,
  publishedQuantity: undefined,
};

const presentationsSlice = createSlice({
  name: 'presentations',
  initialState,
  reducers: {
    setSelectedState: (state, action) => {
      state.selectedStatus = action.payload.selectedStatus;
    },
    setDraftsQuantity: (state, action) => {
      state.draftsQuantity = action.payload.draftsQuantity;
    },
    setPendingReviewQuantity: (state, action) => {
      state.pendingReviewQuantity = action.payload.pendingReviewQuantity;
    },
    setPublishedQuantity: (state, action) => {
      state.publishedQuantity = action.payload.publishedQuantity;
    },
  },
});

export const {
  setSelectedState,
  setDraftsQuantity,
  setPendingReviewQuantity,
  setPublishedQuantity,
} = presentationsSlice.actions;
export default presentationsSlice.reducer;
