import React from 'react';
import type IconInterface from '../interfaces/Icon';

export default function CampaignsIcon({ color = '#F8F8F8' }: IconInterface): JSX.Element {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.016 2.25061C10.7805 2.25061 9.76538 3.27309 9.76538 4.50856C9.76538 4.77614 9.82048 5.02965 9.9077 5.26854C8.64439 5.52874 7.57377 6.03988 6.73131 6.82665C5.4535 8.02 4.7554 9.76965 4.51445 11.9382C4.51115 11.964 4.50919 11.99 4.50858 12.016V14.8153L2.36214 18.3937C2.06432 18.8939 2.42405 19.5281 3.00622 19.5292H9.01714C9.01714 20.7647 10.0323 21.7872 11.2677 21.7872H12.7701C14.0056 21.7872 15.0222 20.7647 15.0222 19.5292H21.0331C21.6146 19.527 21.9732 18.8933 21.6757 18.3937L19.5293 14.8124V12.016C19.5293 9.79162 18.8063 7.99357 17.4621 6.80318C16.5714 6.01446 15.4378 5.51238 14.1331 5.25974C14.2187 5.02329 14.2739 4.77291 14.2739 4.50856C14.2739 3.27309 13.2515 2.25061 12.016 2.25061ZM12.016 3.75444C12.4451 3.75444 12.7701 4.07941 12.7701 4.50856C12.7701 4.7467 12.664 4.94307 12.5016 5.07781C12.3404 5.07166 12.1809 5.06314 12.016 5.06314C11.851 5.06314 11.6935 5.07293 11.5333 5.07928C11.3719 4.94455 11.2677 4.74711 11.2677 4.50856C11.2677 4.07941 11.5868 3.75444 12.016 3.75444ZM12.016 6.5655C13.9802 6.5655 15.4769 7.05157 16.4688 7.92995C17.4607 8.80833 18.0269 10.1045 18.0269 12.016V15.0236H18.0284C18.0317 15.2148 18.068 15.3012 18.1384 15.4095L19.7068 18.0269H4.33106L5.89944 15.4095C5.9694 15.2929 6.00641 15.1596 6.00654 15.0236H6.01094V12.0879C6.22817 10.1544 6.81674 8.80342 7.75832 7.92408C8.70248 7.04233 10.0533 6.5655 12.016 6.5655ZM10.5195 19.5292H13.5184C13.5184 19.9584 13.1993 20.2848 12.7701 20.2848H11.2677C10.8386 20.2848 10.5195 19.9584 10.5195 19.5292Z'
        fill={color}
      />
    </svg>
  );
}
