const RESOURCES_TYPES: ResourceType[] = [
  {
    title: 'Solo texto',
    value: 'TEXT',
  },
  {
    title: 'Audio',
    value: 'AUDIO',
  },
];

export default RESOURCES_TYPES;
