const ARTCILE_MESSAGES = {
  IN_REVIEW: 'Artículo en Revisión',
  PENDING_REVIEW: 'Pendiente de Revisión',
};

const PRESENTATION_MESSAGES = {
  PENDING_REVIEW: 'Pendiente de Revisión',
  IN_REVIEW: 'PPT en Revisión',
};

export { ARTCILE_MESSAGES, PRESENTATION_MESSAGES };
