import React from 'react';
import styled from 'styled-components';
import TagItem from './TagItem';
import type TagDetails from '../../interfaces/TagDetails';

interface Props {
  selectedTags: TagDetails[];
}

export default function TagsList({ selectedTags }: Props) {
  if (selectedTags?.length === 0) return <></>;

  return (
    <Container>
      <Title>Etiquetas:</Title>
      <List>
        {selectedTags.map((tag, index) => (
          <TagItem key={index} tag={tag.tag} />
        ))}
      </List>
    </Container>
  );
}

const Container = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 25px;
`;

const Title = styled.p`
  color: ${(props) => props.theme.colors.darkGray};
  margin-bottom: 9px;
  font-family: ${(props) => props.theme.fonts.semiBold};
`;

const List = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;
`;
