import React from 'react';
import styled from 'styled-components';
import SurveyItem from '../components/surveys/SurveyItem';

export default function SurveysList(): JSX.Element {
  return (
    <Container>
      <ListHeader></ListHeader>
      <List>
        <SurveyItem />
        <SurveyItem />
        <SurveyItem />
        <SurveyItem />
      </List>
    </Container>
  );
}

const Container = styled.section`
  padding-bottom: 80px;
`;

const ListHeader = styled.header``;

const List = styled.ul``;
