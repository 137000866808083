import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { CircularProgress } from '@mui/material';
import { validationEmail } from '../utils/validationEmail';
import { getTokens } from '../api/config';
import { checkTokenExpiration } from '../utils/token';
import Loading from '../components/globals/Loading';

interface FormErrorI {
  email: boolean;
  emailError: string;
  password: boolean;
  passwordError: string;
}

interface Tokens {
  idToken: string | null;
  accessToken: string | null;
}

export default function Login(): JSX.Element {
  const navigation = useNavigate();
  const [microsoftBtnLoading, setMicrosoftBtnLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [formError, setFormError] = useState<FormErrorI>({
    email: false,
    emailError: '',
    password: false,
    passwordError: '',
  });
  const [tokens, setTokens] = useState<Tokens>({
    idToken: null,
    accessToken: null,
  });
  const navigate = useNavigate();
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    const { idToken, accessToken } = getTokens();
    if (idToken != null && accessToken != null && !checkTokenExpiration(idToken)) {
      navigate('/');
    } else {
      setTokens({
        idToken: '',
        accessToken: '',
      });
      loginWithRedirect();
    }
  }, []);

  useEffect(() => {
    if (email !== '' && validationEmail(email?.trim())) {
      setFormError({ ...formError, email: false });
    }
  }, [email]);

  const signIn = (): void => {
    const errors: FormErrorI = {
      email: false,
      emailError: '',
      password: false,
      passwordError: '',
    };

    if (email === '' || password === '') {
      if (email === '') {
        errors.email = true;
        errors.emailError = 'El correo es obligatorio';
      }
      if (password === '') {
        errors.password = true;
        errors.passwordError = 'La contraseña es obligatoria';
      }
    } else if (!validationEmail(email?.trim())) {
      errors.email = true;
      errors.emailError = 'Correo inválido';
    } else {
      navigation('/');
    }
    setFormError(errors);
  };

  if (tokens.accessToken == null || tokens.idToken == null) {
    return (
      <CircularProgressContainer>
        <CircularProgress />
      </CircularProgressContainer>
    );
  }

  return <Loading />;
}

const CircularProgressContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const LoginSaeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 860px) {
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @media screen and (max-width: 860px) {
    grid-template-columns: 1fr;
  }
`;

const Title = styled.h2`
  font-family: ${(props) => props.theme.fonts.semiBold};
  font-size: 40px;
  text-align: center;
  margin-top: 60px;

  @media screen and (max-width: 860px) {
    margin-top: 30px;
    font-size: 28px;
  }
`;

const Subtitle = styled.p`
  font-family: ${(props) => props.theme.fonts.medium};
  color: ${(props) => props.theme.colors.primary};
  text-align: center;
  margin-bottom: 42px;
  font-size: 15px;
  margin-top: 6px;

  @media screen and (max-width: 860px) {
    font-size: 13px;
  }
`;

const FormContainer = styled.div`
  width: 100%;
  max-width: 480px;

  @media screen and (max-width: 860px) {
    max-width: none;
  }
`;

const LeftContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  padding: 0px 10%;
  padding-top: 20vh;

  @media screen and (max-width: 860px) {
    position: relative;
    background-color: ${(props) => props.theme.colors.primary};
    padding: 0px;
    justify-content: center;
  }
`;

const AuxContainer = styled.div`
  flex: 1;
  @media screen and (max-width: 860px) {
    flex: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    width: calc(100% - 60px);
    padding: 60px 30px;
    border-radius: 10px;
  }
`;

const PoweredByZutunContainer = styled.div`
  padding-bottom: 40px;

  @media screen and (max-width: 860px) {
    position: absolute;
    bottom: 0px;
  }
`;

const ImageContainer = styled.div`
  position: fixed;
  right: 0px;
  width: 50%;
  height: 100vh;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

const ApoyoConsultoriaIconContainer = styled.div`
  position: absolute;
  bottom: 40px;
  max-width: 150px;
`;
