import React, { useState } from 'react';
import styled from 'styled-components';
import ButtonBase from '@mui/material/ButtonBase';
import { RiMenuLine, RiDeleteBin6Line } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import CustomMenu from '../globals/CustomMenu';
import { toogleVideoDrawer } from '../../context/reducers/toolBar';
import { type RootState } from '../../context/store';
import { setElements, setPreviewElements, setUnsavedChanges } from '../../context/reducers/editor';
import POSTS_STATUS from '../../constants/status';
import TrashIcon from '../../icons/TrashIcon';

interface Props {
  link: string;
}

interface ContainerI {
  isPreviewModeActive: boolean;
}

interface VideoContainerI {
  isPreviewModeActive: boolean;
}

interface IFrameI {
  isPreviewModeActive: boolean;
}

export default function VideoPlayer({ link }: Props): JSX.Element {
  const isPreviewModeActive = useSelector((state: RootState) => state.toolBar.isPreviewModeActive);
  const currentPostStatus = useSelector((state: RootState) => state.editor.currentPostStatus);
  const previewElements = useSelector((state: RootState) => state.editor.previewElements);
  const elements = useSelector((state: RootState) => state.editor.elements);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dispatch = useDispatch();

  const deleteVideo = () => {
    const newElement = {
      key: 'video',
      element: <div></div>,
    };
    const newElements = [...elements];
    const newPreviewElements = [...previewElements];
    const index = newElements.findIndex((item) => item.key === 'video');
    newElements[index] = newElement;
    newPreviewElements[index] = newElement;
    dispatch(
      setElements({
        elements: newElements,
      }),
    );
    dispatch(
      setPreviewElements({
        previewElements: newPreviewElements,
      }),
    );
    dispatch(
      setUnsavedChanges({
        unsavedChanges: true,
      }),
    );
  };

  const options = [
    {
      label: 'Editar Link',
      icon: <CreateOutlinedIcon />,
      action: () => {
        setAnchorEl(null);
        dispatch(
          toogleVideoDrawer({
            isVideoDrawerOpen: true,
          }),
        );
      },
    },
    {
      label: 'Eliminar',
      icon: <RiDeleteBin6Line style={{ fontSize: '20px' }} />,
      action: () => {
        deleteVideo();
      },
    },
  ];

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Container
      isPreviewModeActive={isPreviewModeActive}
      style={{
        marginLeft: isPreviewModeActive ? '20px' : '0px',
        marginRight: isPreviewModeActive ? '20px' : '0px',
      }}
    >
      <VideoContainer isPreviewModeActive={isPreviewModeActive}>
        <StyledIframe
          isPreviewModeActive={isPreviewModeActive}
          src={link}
          style={{ border: '0px' }}
          allowFullScreen
        ></StyledIframe>
      </VideoContainer>
      {!isPreviewModeActive && currentPostStatus !== POSTS_STATUS.PUBLISHED && (
        <>
          <DeleteButton onClick={deleteVideo}>
            <TrashIcon color={'#CD4F4F'} width={22} height={22} />
          </DeleteButton>
          <MenuButton onClick={handleClick}>
            <RiMenuLine style={{ color: 'white', fontSize: '20px' }} />
          </MenuButton>
          <CustomMenu
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            options={options}
          />
        </>
      )}
    </Container>
  );
}

const Container = styled.div<ContainerI>`
  position: relative;
  height: ${(props) => (!props.isPreviewModeActive ? `499px` : '332px')};
  border-radius: 10px;
  margin-bottom: 24px;
  margin-top: 20px;
  overflow: hidden;

  @media screen and (max-width: 860px) {
    height: 332px;
    margin-bottom: 0px;
  }
`;

const VideoContainer = styled.div<VideoContainerI>`
  width: ${(props) => (!props.theme.isPreviewModeActive ? '880px' : '446px')};
  height: 100%;
  border-radius: 10px;
  overflow: hidden;

  @media screen and (max-width: 860px) {
    width: 100%;
  }
`;

const StyledIframe = styled.iframe.attrs((props: IFrameI) => ({
  width: window.innerWidth <= 860 ? window.innerWidth - 80 : !props.isPreviewModeActive ? 880 : 446,
  height:
    window.innerWidth <= 860 ? window.innerWidth - 80 : !props.isPreviewModeActive ? 499 : 292,
}))<IFrameI>`
  border: none;
  border-radius: 10px;
`;

const MenuButton = styled(ButtonBase)`
  background-color: ${(props) => props.theme.colors.secondary};
  width: 32px;
  height: 32px;
  margin-left: 18px;
  border-radius: 5px;
  position: absolute;
  top: 0px;
  right: 0px;

  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const DeleteButton = styled(ButtonBase)`
  display: none;
  width: 32px;
  height: 32px;
  margin-left: 10px;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 500;

  @media screen and (max-width: 860px) {
    display: inline-flex;
  }
`;
