import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { CircularProgress, Skeleton } from '@mui/material';
import { Circle } from '@mui/icons-material';
import WithoutImageIcon from '../../icons/WithoutImageIcon';
import { type RootState } from '../../context/store';
import { setElementsContent, setUnsavedChanges } from '../../context/reducers/editor';
import {
  HEADER_IMAGE,
  KEY_PARAGRAPH,
  POST_THUMBNAIL,
  WYSIWYG_MAIN_TITLE,
} from '../../constants/editorKeys';
import { uploadImage } from '../../api/files';
import { openSnackbar } from '../../context/reducers/generalSnackbar';
import { FILES_ERRORS, POST_ERRORS } from '../../constants/errorMessages';
import POSTS_STATUS from '../../constants/status';
import PreviewCustomDraftWysiwyg from '../editor/PreviewCustomDraftWysiwyg';
import WatchIcon from '../../icons/WatchIcon';
import { type EditorState, convertToRaw } from 'draft-js';
import { formatLessThan10 } from '../../utils/formatLessThan10';
import { getLastPublicationDate } from '../../utils/getLastPublicationDate';
import getLastModifiedDate from '../../utils/getLastModifiedDate';
import { getLastPublicationDateInPreviewPage } from '../../utils/getLastPublicationDateInPreviewPage';

interface Props {
  postInformation: any;
  selectedCategories: string[];
}

interface ContainerI {
  isPreviewModeActive: boolean;
}

export default function HeaderImagePreviewMode({
  postInformation,
  selectedCategories,
}: Props): JSX.Element {
  const categoriesList = useSelector((state: RootState) => state.categories.categoriesList);
  const isPreviewModeActive = useSelector((state: RootState) => state.toolBar.isPreviewModeActive);
  const elementsContent = useSelector((state: RootState) => state.editor.elementsContent);
  const currentPostStatus = useSelector((state: RootState) => state.editor.currentPostStatus);
  const [previewImage, setPreviewImage] = useState<string | null | undefined>('');

  useEffect(() => {
    const index = elementsContent.findIndex((item) => item.customKey === HEADER_IMAGE);
    if (index !== -1 && elementsContent[index].content !== null) {
      setPreviewImage(elementsContent[index].content as string);
    }
  }, [elementsContent]);

  const getCategories = (): string[] => {
    if (selectedCategories === null || selectedCategories?.length === 0) {
      return [];
    }
    if (categoriesList !== null) {
      const categoriesNames: string[] = [];
      const auxCategoriesList: any[] = [...categoriesList];
      selectedCategories.forEach((category) => {
        auxCategoriesList?.forEach((auxCategory) => {
          if (category === auxCategory?.id) {
            categoriesNames.push(auxCategory?.name);
          }
        });
      });
      return categoriesNames;
    }
    return [];
  };

  const getPlainText = (editorState: EditorState): string => {
    const currentContentState = editorState.getCurrentContent();
    const plainText = currentContentState
      .getBlockMap()
      .filter((block) => block?.getType() !== 'custom-divider')
      .map((block) => block?.getText().trim())
      .join('\n');
    return plainText;
  };

  const getCharacteresOfContent = (): string => {
    const content =
      elementsContent[elementsContent.findIndex((item) => item.customKey === KEY_PARAGRAPH)]
        .content;
    return content !== null ? getPlainText(content as EditorState) : '';
  };

  const getWordsQuantity = (): number => {
    const allCharacteres = getCharacteresOfContent().trim().replace(/\s+/g, ' ').split(' ');
    const words = allCharacteres.filter((word) => word !== '');
    return words?.length;
  };

  const getTime = (): string => {
    const wordsQuantity = getWordsQuantity();
    const time = wordsQuantity / 250;
    const seconds = Math.round(time * 60);
    let final = time;
    if (time < 1) final = seconds;

    return `${formatLessThan10(Math.round(final))} ${
      time < 1 ? (time === 0 ? 'min' : 'seg') : 'min'
    }`;
  };

  const getDate = (): string => {
    if (postInformation === null) return getLastPublicationDateInPreviewPage(new Date().toString());
    return getLastPublicationDateInPreviewPage(postInformation?.createdDate);
  };

  return (
    <Container
      isPreviewModeActive={
        isPreviewModeActive || currentPostStatus === POSTS_STATUS.REVIEW_IN_PROGRESS
      }
    >
      {previewImage === '' || previewImage == null ? (
        <>
          <WithoutImageIcon />
        </>
      ) : (
        <Image src={previewImage} />
      )}
      <InformationContainer>
        {categoriesList == null ? (
          <Skeleton
            variant='rectangular'
            style={{
              width: '50px',
              height: '10px',
              borderRadius: '8px',
              marginTop: '3px',
              marginBottom: '3px',
            }}
          />
        ) : (
          <CategoryContainer>
            {getCategories().map((category, index) => (
              <Category key={index}>{category}</Category>
            ))}
          </CategoryContainer>
        )}
        <Row>
          <WatchIcon />
          <Duration>{getTime()} de lectura</Duration>
          <Circle style={{ height: '5px', width: '5px', color: '#D9D9D9' }} />
          <Duration style={{ textTransform: 'capitalize' }}>{getDate()}</Duration>
        </Row>
        <PreviewCustomDraftWysiwyg
          customKey={WYSIWYG_MAIN_TITLE}
          placeholder='Escribe un título...'
          fontSize='21px'
          color='white'
          fontFamily='Figtree'
          fontWeight={600}
          maximumOfCharacters={100}
        />
      </InformationContainer>
    </Container>
  );
}

const Container = styled.div<ContainerI>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 350px; //273px;;
  background-color: ${(props) => props.theme.colors.lightSkyBlue};
  overflow: hidden;
  margin-bottom: -50px;

  :hover {
    background-color: #5c9cb52a;
    border-color: ${(props) => props.theme.colors.skyBlue};
  }
`;

const Image = styled.img`
  position: relative;
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const InformationContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  bottom: 0px;
  width: 100%;
  z-index: 100;
  height: 100%;
  padding-bottom: 50px;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.6) 40%, transparent);
`;

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 23px;
  gap: 4px;
  flex-wrap: wrap;
`;

const Category = styled.p`
  font-family: ${(props) => props.theme.fonts.medium};
  background-color: #31b3dc;
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 20px;
  padding-top: 4px;
  padding-bottom: 4px;
  color: white;
  font-size: 15px;
  font-weight: 500;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 23px;
  padding-right: 23px;
  margin-top: 8px;
  gap: 4px;
`;

const Duration = styled.p`
  color: ${(props) => props.theme.colors.lightGray};
  font-family: ${(props) => props.theme.fonts.medium};
  font-weight: 500;
  font-size: 15px;
`;
