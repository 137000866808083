import React from 'react';
import type Icon from '../interfaces/Icon';

export default function EyeIcon({ width = 26, height = 18, color = '#0094CF' }: Icon): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 26 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13 14.1667C14.4583 14.1667 15.6979 13.6562 16.7187 12.6354C17.7395 11.6146 18.25 10.375 18.25 8.91666C18.25 7.45832 17.7395 6.21874 16.7187 5.19791C15.6979 4.17707 14.4583 3.66666 13 3.66666C11.5416 3.66666 10.302 4.17707 9.28121 5.19791C8.26038 6.21874 7.74996 7.45832 7.74996 8.91666C7.74996 10.375 8.26038 11.6146 9.28121 12.6354C10.302 13.6562 11.5416 14.1667 13 14.1667ZM13 12.0667C12.125 12.0667 11.3812 11.7604 10.7687 11.1479C10.1562 10.5354 9.84996 9.79166 9.84996 8.91666C9.84996 8.04166 10.1562 7.29791 10.7687 6.68541C11.3812 6.07291 12.125 5.76666 13 5.76666C13.875 5.76666 14.6187 6.07291 15.2312 6.68541C15.8437 7.29791 16.15 8.04166 16.15 8.91666C16.15 9.79166 15.8437 10.5354 15.2312 11.1479C14.6187 11.7604 13.875 12.0667 13 12.0667ZM13 17.6667C10.1611 17.6667 7.57496 16.8743 5.24163 15.2896C2.90829 13.7049 1.21663 11.5805 0.166626 8.91666C1.21663 6.25277 2.90829 4.12846 5.24163 2.54374C7.57496 0.959018 10.1611 0.166656 13 0.166656C15.8388 0.166656 18.425 0.959018 20.7583 2.54374C23.0916 4.12846 24.7833 6.25277 25.8333 8.91666C24.7833 11.5805 23.0916 13.7049 20.7583 15.2896C18.425 16.8743 15.8388 17.6667 13 17.6667ZM13 15.3333C15.1972 15.3333 17.2145 14.7549 19.052 13.5979C20.8895 12.441 22.2944 10.8805 23.2666 8.91666C22.2944 6.95277 20.8895 5.39235 19.052 4.23541C17.2145 3.07846 15.1972 2.49999 13 2.49999C10.8027 2.49999 8.78538 3.07846 6.94788 4.23541C5.11038 5.39235 3.70551 6.95277 2.73329 8.91666C3.70551 10.8805 5.11038 12.441 6.94788 13.5979C8.78538 14.7549 10.8027 15.3333 13 15.3333Z'
        fill={color}
      />
    </svg>
  );
}
