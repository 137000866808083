import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import POSTS_STATUS from '../../constants/status';
import ModalContainer from '../globals/ModalContainer';
import styled from 'styled-components';
import { Button, CircularProgress, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { setUnsavedChanges } from '../../context/reducers/editor';
import sleep from '../../utils/sleep';

interface Props {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

interface CustomButtonI {
  withBackground: boolean;
}

interface CustomButtonTextI {
  white: boolean;
}

export default function ErrorConcurrenceModal({ isVisible, setIsVisible }: Props): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const closeArticle = async () => {
    setIsLoading(true);
    dispatch(
      setUnsavedChanges({
        unsavedChanges: false,
      }),
    );
    await sleep(500);
    navigate(`/entries?type=${POSTS_STATUS.PENDING_REVIEW.toLowerCase()}`);
  };

  return (
    <ModalContainer
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      paddingTop={10}
      paddingRight={10}
      enabled={false}
    >
      <Container>
        <Header />
        <Title>Estás editando una versión desactualizada del artículo</Title>
        <Message>
          Este artículo ha tenido modificaciones que no se encuentran reflejadas en la versión
          actual. Por favor, vuelve a abrirlo antes de hacer cambios, ya que, estos no se guardarán.
        </Message>
        <ButtonsContainer>
          <CustomButton
            disabled={isLoading}
            onClick={closeArticle}
            withBackground={true}
            style={{ width: '160px' }}
          >
            {isLoading ? (
              <CircularProgress size={22} style={{ color: 'white' }} />
            ) : (
              <ButtonText white={true}>Salir del artículo</ButtonText>
            )}
          </CustomButton>
        </ButtonsContainer>
      </Container>
    </ModalContainer>
  );
}

const Container = styled.div`
  width: 480px;
  padding-top: 20px;
`;

const Title = styled.p`
  font-family: ${(props) => props.theme.fonts.semiBold};
  color: ${(props) => props.theme.colors.primary};
  font-size: 20px;
  margin-bottom: 15px;
  padding-left: 10px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 15;
`;

const Message = styled.p`
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.black};
  margin-bottom: 20px;
  padding-left: 10px;
  font-size: 19px;
  padding-right: 15px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
  padding-right: 15px;
`;

const CustomButton = styled(Button)<CustomButtonI>`
  &&& {
    border: 1px solid;
    border-radius: 20px;
    background-color: ${(props) =>
      props?.withBackground ? props.theme.colors.primary : 'transparent'};
  }
`;

const ButtonText = styled.p<CustomButtonTextI>`
  text-transform: none;
  font-family: ${(props) => props.theme.fonts.medium};
  color: ${(props) => (props.white ? 'white' : props.theme.colors.primary)};
  font-size: 12px;
  padding: 0px 15px;
`;
