import React from 'react';
import styled from 'styled-components';
import SectionHeader from '../../globals/SectionHeader';
import InputWithLabel from './InputWithLabel';
import type AuthorDetailsInterface from '../../../interfaces/AuthorDetails';
import { type FormErrorI } from '../../../interfaces/AuthorDetails';
import getAvatar from '../../../utils/getAvatar';

interface Props {
  formData: AuthorDetailsInterface;
  setFormData: React.Dispatch<React.SetStateAction<AuthorDetailsInterface>>;
  formError: FormErrorI;
  setFormError: React.Dispatch<React.SetStateAction<FormErrorI>>;
  unsavedChanges: boolean;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ProfileInformation({
  formData,
  setFormData,
  formError,
  setFormError,
  unsavedChanges,
  setUnsavedChanges,
}: Props): JSX.Element {
  const setValue = (newValue: string, type: string): void => {
    if (!unsavedChanges) {
      setUnsavedChanges(true);
    }
    setFormData({ ...formData, [type]: newValue });
  };

  return (
    <Container>
      <SectionHeader title='Información del Perfil' />
      <Text>Foto de perfil</Text>
      <InformationContainer>
        <AuxAvatarContainer>
          <AvatarContainer>
            <Avatar>{getAvatar(formData?.names?.trim())}</Avatar>
          </AvatarContainer>
        </AuxAvatarContainer>
        <InputContainer>
          <InputWithLabel
            label='Nombre'
            placeholder='Escribe aquí'
            setValue={setValue}
            type={'names'}
            value={formData.names}
            error={formError.names}
            errorMessage={formError.namesMessage}
          />
        </InputContainer>
      </InformationContainer>
    </Container>
  );
}

const Container = styled.div`
  padding-left: 24px;
`;

const Text = styled.p`
  margin-left: 30px;
  margin-top: 24px;
  font-size: 14px;

  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const AuxAvatarContainer = styled.div`
  @media screen and (max-width: 860px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`;

const InformationContainer = styled.div`
  padding-left: 30px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  @media screen and (max-width: 860px) {
    display: block;
    justify-content: inherit;
    padding-left: 0px;
  }
`;

const InputContainer = styled.div`
  flex: 1;
  margin-left: 40px;

  @media screen and (max-width: 860px) {
    margin-left: 12px;
    margin-top: 15px;
  }
`;

const AvatarContainer = styled.div`
  height: 90px;
  width: 90px;
  border-radius: 100%;
  background-color: ${(props) => props.theme.colors.accentBlue};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Avatar = styled.p`
  color: white;
  font-family: ${(props) => props.theme.fonts.bold};
  font-size: 40px;
  text-transform: uppercase;
`;
