import { createSlice } from '@reduxjs/toolkit';
import type DraftInterface from '../../interfaces/Draft';

interface InitialState {
  drafts: DraftInterface[] | null;
  currentPage: number;
  draftsTotal: number;
  isLoadingMoreData: boolean;
  canLoadMoreData: boolean;
  isDrawerOpen: boolean;
}

const initialState: InitialState = {
  drafts: null,
  currentPage: 0,
  draftsTotal: 0,
  isLoadingMoreData: false,
  canLoadMoreData: false,
  isDrawerOpen: false,
};

const entriesDraftsSlice = createSlice({
  name: 'entriesDrafts',
  initialState,
  reducers: {
    setEntriesDraftsResetValues: (state, action) => {
      state.drafts = null;
      state.currentPage = 0;
      state.draftsTotal = 0;
      state.isLoadingMoreData = false;
      state.canLoadMoreData = false;
    },
    setEntriesDrafts: (state, action) => {
      state.drafts = action.payload.drafts;
    },
    setEntriesDraftsCurrentPage: (state, action) => {
      state.currentPage = action.payload.currentPage;
    },
    setEntriesDraftsTotal: (state, action) => {
      state.draftsTotal = action.payload.draftsTotal;
    },
    setEntriesDraftsIsLoadingMoreData: (state, action) => {
      state.isLoadingMoreData = action.payload.isLoadingMoreData;
    },
    setEntriesDraftsCanLoadMoreData: (state, action) => {
      state.canLoadMoreData = action.payload.canLoadMoreData;
    },
    setEntriesDraftsIsDrawerOpen: (state, action) => {
      state.isDrawerOpen = action.payload.isDrawerOpen;
    },
  },
});

export const {
  setEntriesDrafts,
  setEntriesDraftsCanLoadMoreData,
  setEntriesDraftsCurrentPage,
  setEntriesDraftsIsLoadingMoreData,
  setEntriesDraftsTotal,
  setEntriesDraftsResetValues,
  setEntriesDraftsIsDrawerOpen,
} = entriesDraftsSlice.actions;
export default entriesDraftsSlice.reducer;
