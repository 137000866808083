import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import HeaderButtonWithText from '../components/globals/HeaderButtonWithText';
import PresentationsList from '../components/presentations/PresentationsList';
import { checkIfSomeFilterIsSelected } from '../utils/entries';
import TopTabs from '../components/entries/TopTabs';
import { FILE_TYPE } from '../constants/globals';
import MobileNewPublicationButton from '../components/globals/MobileNewPublicationButton';

export default function Presentations(): JSX.Element {
  const [searchInput, setSearchInput] = useState('');
  const [resource, setResource] = useState<string | null>(null);
  const [selectedAuthors, setSelectedAuthors] = useState<any[]>([]);
  const [categoriesIDs, setCategoriesIDs] = useState<string[]>([]);
  const isMounted = useRef(false);
  const dispatch = useDispatch();

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <Container>
      <HeaderButtonWithText title='PPTs' type='presentation' buttonText='Nuevo PPT' />
      <TopTabsContainer>
        <TopTabs contentType={FILE_TYPE} />
      </TopTabsContainer>
      <PresentationsList />
      <MobileNewPublicationButton type='presentation' />
    </Container>
  );
}

const Container = styled.section`
  padding-left: 24px;
  padding-right: 24px;
  margin-left: 192px;
  margin-top: 72px;
  width: calc(100vw - 192px);

  @media screen and (max-width: 860px) {
    margin-left: 0px;
    width: 100vw;
    margin-top: 80px;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const TopTabsContainer = styled.div`
  @media screen and (max-width: 860px) {
    display: none;
  }
`;
