import React from 'react';
import styled from 'styled-components';
import SectionHeader from '../../globals/SectionHeader';
import InputWithLabel from '../../authors/authorDetails/InputWithLabel';
import type SurveyDetailsInterface from '../../../interfaces/SurveyDetails';
import OptionsList from './OptionsList';

interface Props {
  formData: SurveyDetailsInterface;
  setFormData: React.Dispatch<React.SetStateAction<SurveyDetailsInterface>>;
}

export default function DesignSection({ formData, setFormData }: Props): JSX.Element {
  const setValue = (newValue: string, type: string): void => {
    setFormData({ ...formData, [type]: newValue });
  };

  return (
    <Container>
      <SectionHeader title='Diseño' />
      <Content>
        <Column>
          <InputWithLabel
            label='Título'
            placeholder='Escribe aquí'
            value={formData.title}
            setValue={setValue}
            type={'title'}
          />
        </Column>
        <Column>
          <OptionsList formData={formData} setFormData={setFormData} />
        </Column>
      </Content>
    </Container>
  );
}

const Container = styled.section`
  padding-left: 24px;
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 24px;
  gap: 30px;
`;

const Column = styled.div``;
