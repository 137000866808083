import React from 'react';
import type Icon from '../interfaces/Icon';

export default function ReaderIcon({ color = '#0094CF' }: Icon): JSX.Element {
  return (
    <svg width='24' height='29' viewBox='0 0 24 29' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16.6484 5.96906C16.6484 8.53631 14.5672 10.6175 12 10.6175C9.43275 10.6175 7.35156 8.53631 7.35156 5.96906C7.35156 3.40181 9.43275 1.32062 12 1.32062C14.5672 1.32062 16.6484 3.40181 16.6484 5.96906Z'
        stroke={color}
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21.0234 20.4612H20.2031C19.9011 20.4612 19.6562 20.2164 19.6562 19.9144V17.18C19.6562 16.878 19.9011 16.6331 20.2031 16.6331H21.0234C22.0805 16.6331 22.9375 17.4901 22.9375 18.5472C22.9375 19.6043 22.0805 20.4612 21.0234 20.4612Z'
        stroke={color}
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.97656 20.4612H3.79688C4.09886 20.4612 4.34375 20.2164 4.34375 19.9144V17.18C4.34375 16.878 4.09886 16.6331 3.79688 16.6331H2.97656C1.91945 16.6331 1.0625 17.4901 1.0625 18.5472C1.0625 19.6043 1.91945 20.4612 2.97656 20.4612Z'
        stroke={color}
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.6633 13.6969C17.0583 11.8128 14.6689 10.6175 12 10.6175C9.33104 10.6175 6.94158 11.8128 5.33667 13.6969'
        stroke={color}
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.75 16.6331V12.9144L12 16.1956V27.68L20.75 24.3987V20.4612'
        stroke={color}
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.25 20.4612V24.3987L12 27.68V16.1956L3.25 12.9144V16.6331'
        stroke={color}
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
