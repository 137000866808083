import { MenuItem } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import CheckIcon from '@mui/icons-material/Check';

interface Props {
  option: any;
  isSelected?: boolean;
  handleClick: (option: any) => void;
}

interface IconContainerI {
  isSelected?: boolean;
  color?: string | null;
}

export default function AvailableOptionItem({ option, isSelected, handleClick }: Props) {
  const { title, name, color, names } = option;

  const getTitle = () => {
    return title ?? name ?? names;
  };

  const onClick = () => {
    handleClick(option);
  };

  return (
    <MenuItem style={{ padding: '15px 12px' }} onClick={onClick}>
      <IconContainer isSelected={isSelected} color={option?.color}>
        <CheckIcon style={{ color: 'white' }} fontSize='small' />
      </IconContainer>
      <Title>{getTitle()}</Title>
    </MenuItem>
  );
}

const Title = styled.p`
  font-family: ${(props) => props.theme.fonts.regular};
  color: #183582;
  font-size: 16px;
  flex: 1;
`;

const IconContainer = styled.div<IconContainerI>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.isSelected ? props.color ?? props.theme.colors.primary : 'white'};
  border: 1px solid ${(props) => props.color ?? props.theme.colors.primary};
  height: 25px;
  width: 25px;
  border-radius: 6px;
  margin-right: 13px;
`;
