import React from 'react';

export default function ErrorInformationIcon() {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none'>
      <path
        d='M12.73 0H5.27L0 5.27V12.73L5.27 18H12.73L18 12.73V5.27L12.73 0ZM16 11.9L11.9 16H6.1L2 11.9V6.1L6.1 2H11.9L16 6.1V11.9Z'
        fill='#CD4F4F'
      />
      <path d='M10 4H8V10H10V4Z' fill='#CD4F4F' />
      <path d='M10 12H8V14H10V12Z' fill='#CD4F4F' />
    </svg>
  );
}
