const getAvatar = (namesValue: string | undefined): string => {
  if (namesValue) {
    const names = namesValue.replace(/\s+/g, ' ').trim();
    const namesArray = names?.split(' ');
    const firstLetter = namesArray[0][0];
    const secondLetter = namesArray?.length >= 2 ? namesArray[1][0] : '';
    return `${firstLetter}${secondLetter}`;
  }
  return '';
};

export default getAvatar;
