import React from 'react';
import type Icon from '../interfaces/Icon';

export default function ZoomImageIcon({ width = 14, height = 12, color = 'white' }: Icon) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 14 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.36824 9.4211H11.7893V6.00005H10.4209V8.05268H8.36824V9.4211ZM2.21035 6.00005H3.57877V3.94742H5.6314V2.579H2.21035V6.00005ZM1.52614 11.4737C1.14982 11.4737 0.827671 11.3397 0.559689 11.0718C0.291706 10.8038 0.157715 10.4816 0.157715 10.1053V1.89479C0.157715 1.51847 0.291706 1.19632 0.559689 0.928341C0.827671 0.660358 1.14982 0.526367 1.52614 0.526367H12.4735C12.8498 0.526367 13.172 0.660358 13.44 0.928341C13.7079 1.19632 13.8419 1.51847 13.8419 1.89479V10.1053C13.8419 10.4816 13.7079 10.8038 13.44 11.0718C13.172 11.3397 12.8498 11.4737 12.4735 11.4737H1.52614ZM1.52614 10.1053H12.4735V1.89479H1.52614V10.1053Z'
        fill={color}
      />
    </svg>
  );
}
