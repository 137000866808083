import React, { useEffect, useRef, useState } from 'react';
import { Resizable } from 're-resizable';
import styled from 'styled-components';
import { IoIosResize } from 'react-icons/io';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { useSelector, useDispatch } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import Immutable from 'immutable';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { type RootState } from '../../context/store';
import { type ContentState, type ContentBlock, EditorState } from 'draft-js';
import { uploadFiles } from '../../api/files';
import { openSnackbar } from '../../context/reducers/generalSnackbar';
import { FILES_ERRORS } from '../../constants/errorMessages';
import { updateBlockData } from '../../utils/wysiwyg';
import {
  setBlockKeyToDelete,
  setCurrentSelectedEditorState,
  setUnsavedChanges,
  toogleEditSomeStyleOfCurrentSelectedEditor,
} from '../../context/reducers/editor';
import ZoomImageIcon from '../../icons/ZoomImageIcon';
import { AxiosError } from 'axios';
import POSTS_STATUS from '../../constants/status';

interface Props {
  imageUrl: string;
  customKey: string;
  index: number;
  block?: ContentBlock;
  blockProps?: any;
  contentState?: ContentState;
}

interface CustomResizableI {
  isPreviewModeActive: boolean;
}

interface DimensionesI {
  width: null | number;
  height: null | number;
}

const MAX_WIDTH = 920;

export default function ResizableImage({
  customKey,
  index,
  block,
  blockProps,
  contentState,
}: Props): JSX.Element {
  const data = block?.getData();
  const url = data?.get('url');
  const file = data?.get('file');
  const height = data?.get('height');
  const width = data?.get('width');
  const key = blockProps?.key;
  const currentSelectedEditorState = useSelector(
    (state: RootState) => state.editor.currentSelectedEditorState,
  );
  const currentPostStatus = useSelector((state: RootState) => state.editor.currentPostStatus);
  const isPreviewModeActive = useSelector((state: RootState) => state.toolBar.isPreviewModeActive);
  const elements = useSelector((state: RootState) => state.editor.elements);
  const [defaultSize, setDefaultSize] = useState({
    width: isPreviewModeActive ? 420 : 720,
    height: isPreviewModeActive ? 420 : 720,
  });
  const [dimensions, setDimensions] = useState<DimensionesI>({
    width: width ?? null,
    height: height ?? null,
  });
  const [mobileDimensions, setMobileDimensions] = useState<DimensionesI>({
    width: null,
    height: null,
  });
  const [imageUrl, setImageUrl] = useState(url ?? '');
  const [isThereImage, setIsThereImage] = useState(true);
  const dispatch = useDispatch();
  const imageRef = useRef<HTMLImageElement>(null);

  const getScale = (realWidth: number, realHeight: number): number => {
    const defaultWidth = defaultSize.width;
    const defaultHeight = defaultSize.height;
    const auxFactor = 0.99;
    if (realWidth <= defaultWidth && realHeight <= defaultHeight) {
      return 1;
    } else if (realWidth >= defaultWidth || realHeight >= defaultHeight) {
      if (realWidth > realHeight) {
        let scaleFactor = defaultWidth / realWidth;
        if (realHeight * scaleFactor <= defaultHeight) {
          return scaleFactor;
        }
        while (realHeight * scaleFactor > defaultHeight) {
          scaleFactor *= auxFactor;
        }
        return scaleFactor;
      } else if (realHeight > realWidth) {
        let scaleFactor = defaultHeight / realHeight;
        if (realWidth * scaleFactor <= defaultWidth) {
          return scaleFactor;
        }
        while (realWidth * scaleFactor > defaultWidth) {
          scaleFactor *= auxFactor;
        }
        return scaleFactor;
      } else {
        let scaleFactor = defaultWidth / realWidth;
        if (realHeight * scaleFactor <= defaultHeight) {
          return scaleFactor;
        }
        while (realHeight * scaleFactor > defaultHeight) {
          scaleFactor *= auxFactor;
        }
        return scaleFactor;
      }
    }
    return 1;
  };

  const callApiToUploadFile = async () => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      const response = await uploadFiles(formData);
      const { location } = response;
      setImageUrl(location);
      const newData = Immutable.Map({ url: location, file: null, height: null, width: null });
      const newEditorState = updateBlockData(currentSelectedEditorState, key, newData);
      if (newEditorState !== null) {
        dispatch(
          setCurrentSelectedEditorState({
            currentSelectedEditorState: EditorState.forceSelection(
              newEditorState,
              currentSelectedEditorState.getSelection(),
            ),
          }),
        );
        dispatch(toogleEditSomeStyleOfCurrentSelectedEditor({}));
      }
    } catch (error: unknown) {
      let errorStatus;
      if (error instanceof AxiosError) {
        errorStatus = error?.response?.status;
      }
      if (errorStatus !== 401) {
        dispatch(
          openSnackbar({
            type: 'error',
            message: FILES_ERRORS.IMAGE,
          }),
        );
      }
    }
  };

  useEffect(() => {
    if (
      (file === null || file === undefined || file === '') &&
      (imageUrl === '' || imageUrl === null || imageUrl === undefined)
    ) {
      setIsThereImage(false);
    }
  }, []);

  useEffect(() => {
    if (file !== null && file !== undefined) {
      callApiToUploadFile();
    }
  }, []);

  useEffect(() => {
    setDefaultSize({
      ...defaultSize,
      width: isPreviewModeActive ? 420 : 720,
      height: isPreviewModeActive ? 420 : 720,
    });
  }, [isPreviewModeActive]);

  useEffect(() => {
    const image = new Image();
    image.src = imageUrl;
    if (
      imageUrl !== null &&
      imageUrl !== undefined &&
      dimensions.height == null &&
      dimensions.width == null
    ) {
      image.onload = () => {
        const width = image.naturalWidth;
        const height = image.naturalHeight;
        setDimensions({
          width: getScale(width, height) * width,
          height: getScale(width, height) * height,
        });
      };
    }
  }, [defaultSize, imageUrl]);

  useEffect(() => {
    if (isPreviewModeActive) {
      const currentWidth = dimensions.width;
      const currentHeight = dimensions.height;
      if (currentWidth !== null && currentHeight !== null) {
        setMobileDimensions({
          width: getScale(currentWidth, currentHeight) * currentWidth,
          height: getScale(currentWidth, currentHeight) * currentHeight,
        });
      }
    }
  }, [isPreviewModeActive, defaultSize, dimensions]);

  useEffect(() => {
    if (imageUrl !== '') {
      const newData = Immutable.Map({
        url: imageUrl,
        file: null,
        height: dimensions.height,
        width: dimensions.width,
      });
      const newEditorState = updateBlockData(currentSelectedEditorState, key, newData);
      if (newEditorState !== null) {
        dispatch(
          setUnsavedChanges({
            unsavedChanges: true,
          }),
        );
        dispatch(
          setCurrentSelectedEditorState({
            currentSelectedEditorState: EditorState.forceSelection(
              newEditorState,
              currentSelectedEditorState.getSelection(),
            ),
          }),
        );
        dispatch(toogleEditSomeStyleOfCurrentSelectedEditor({}));
      }
    }
  }, [dimensions, imageUrl]);

  const deleteImage = (): void => {
    dispatch(
      setBlockKeyToDelete({
        blockKeyToDelete: key,
      }),
    );
  };

  const checkIfResizableImageIsEnabled = (): boolean => {
    return currentPostStatus === POSTS_STATUS.DRAFT;
  };

  if (!isThereImage) {
    return <></>;
  }

  return (
    <>
      {dimensions?.height === null || dimensions?.width === null ? (
        <CircularProgressContainer>
          <CircularProgress />
        </CircularProgressContainer>
      ) : (
        <ResizableContainer>
          <CustomResizable
            isPreviewModeActive={isPreviewModeActive || !checkIfResizableImageIsEnabled()}
            enable={{
              bottom: !isPreviewModeActive && checkIfResizableImageIsEnabled(),
              bottomLeft: !isPreviewModeActive && checkIfResizableImageIsEnabled(),
              bottomRight: !isPreviewModeActive && checkIfResizableImageIsEnabled(),
              left: !isPreviewModeActive && checkIfResizableImageIsEnabled(),
              right: !isPreviewModeActive && checkIfResizableImageIsEnabled(),
              top: !isPreviewModeActive && checkIfResizableImageIsEnabled(),
              topLeft: !isPreviewModeActive && checkIfResizableImageIsEnabled(),
              topRight: !isPreviewModeActive && checkIfResizableImageIsEnabled(),
            }}
            resizeRatio={1}
            lockAspectRatio={false}
            size={{
              width: isPreviewModeActive
                ? mobileDimensions.width !== null
                  ? mobileDimensions.width
                  : dimensions.width
                : dimensions.width,
              height: isPreviewModeActive
                ? mobileDimensions.height !== null
                  ? mobileDimensions.height
                  : dimensions.height
                : dimensions.height,
            }}
            onResizeStop={(e, direction, ref, d) => {
              if (dimensions.width !== null && dimensions.height !== null) {
                setDimensions({
                  width: dimensions.width + d.width,
                  height: dimensions.height + d.height,
                });
              }
            }}
            maxWidth={`${MAX_WIDTH}px`}
            snapGap={0}
            handleComponent={{
              bottomRight:
                isPreviewModeActive || !checkIfResizableImageIsEnabled() ? (
                  <></>
                ) : (
                  <Handle style={{ userSelect: 'auto' }}>
                    <IconButton className='bg-primary' onClick={() => {}}>
                      <IoIosResize color='white' size={18} />
                    </IconButton>
                  </Handle>
                ),
              topRight: <></>,
            }}
          >
            {!isPreviewModeActive && checkIfResizableImageIsEnabled() && (
              <Handle2>
                <IconButton className='bg-primary' onClick={deleteImage}>
                  <RiDeleteBin6Line color='white' size={15} />
                </IconButton>
              </Handle2>
            )}

            <ImageContainer>
              {!isPreviewModeActive ? (
                <>
                  <Overlay />
                  {imageUrl !== '' && <StyledImage ref={imageRef} src={imageUrl} />}
                </>
              ) : (
                <>
                  <Zoom
                    wrapStyle={{
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    {imageUrl !== '' && <StyledImage ref={imageRef} src={imageUrl} />}
                  </Zoom>
                  <ZoomImageIconContainer>
                    <ZoomImageIcon width={16} height={14} />
                  </ZoomImageIconContainer>
                </>
              )}
            </ImageContainer>
          </CustomResizable>
        </ResizableContainer>
      )}
    </>
  );
}

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: 'none';
`;

const CircularProgressContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 15px;
  width: 100%;
`;

const ResizableContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  padding-top: 22px;
  padding-bottom: 22px;
  user-select: contain;
  ::-webkit-scrollbar {
    width: 0px;
  }
`;

const CustomResizable = styled(Resizable)<CustomResizableI>`
  position: relative;
  border: 2px solid transparent;
  transition: border 0.1s ease-in;
  &&& {
    user-select: auto;
  }
  :hover {
    border-color: ${(props) =>
      props.isPreviewModeActive ? 'transparent' : props.theme.colors.primary};
  }
`;

const Handle2 = styled.div`
  position: absolute;
  z-index: 500;
  transition: opacity 0.2s ease-out;
  opacity: 0;
  top: -15px;
  right: -15px;
  display: inline-block;
  ${CustomResizable}:hover & {
    opacity: 1;
  }
`;

const Handle = styled.div`
  position: absolute;
  transition: opacity 0.2s ease-out;
  opacity: 0;
  display: inline-block;
  ${CustomResizable}:hover & {
    opacity: 1;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const StyledImage = styled.img`
  height: 100%;
  width: 100%;
  min-height: 20px;
  object-fit: fill;
`;

const ZoomImageIconContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  top: 0px;
  right: 10px;
  background-color: ${(props) => props.theme.colors.darkGray};
  height: 30px;
  width: 30px;
  border-radius: 30px;
  pointer-events: none;
`;
