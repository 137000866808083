import { createSlice } from '@reduxjs/toolkit';
import type AuthorDetails from '../../interfaces/AuthorDetails';

interface InitialState {
  authorDeleted: boolean;
  newAuthorAdded: boolean;
  authorUpdated: boolean;
  authors: AuthorDetails[] | null;
  currentPage: number;
  authorsTotal: number;
  isLoadingMoreData: boolean;
  canLoadMoreData: boolean;
  isRemovingAuthor: boolean;
  authorId: string | null;
}

const initialState: InitialState = {
  authorDeleted: false,
  newAuthorAdded: false,
  authorUpdated: false,
  authors: null,
  currentPage: 0,
  authorsTotal: 0,
  isLoadingMoreData: false,
  canLoadMoreData: false,
  isRemovingAuthor: false,
  authorId: null,
};

const authorsSlice = createSlice({
  name: 'authors',
  initialState,
  reducers: {
    setAuthorsResetValues: (state, action) => {
      state.authors = null;
      state.currentPage = 0;
      state.authorsTotal = 0;
      state.isLoadingMoreData = false;
      state.canLoadMoreData = false;
    },
    setAuthorDeleted: (state, action) => {
      state.authorDeleted = action.payload.authorDeleted;
    },
    setNewAuthorAdded: (state, action) => {
      state.newAuthorAdded = action.payload.newAuthorAdded;
    },
    setAuthorUpdated: (state, action) => {
      state.authorUpdated = action.payload.authorUpdated;
    },
    setAuthors: (state, action) => {
      state.authors = action.payload.authors;
    },
    setAuthorsCurrentPage: (state, action) => {
      state.currentPage = action.payload.currentPage;
    },
    setAuthorsTotal: (state, action) => {
      state.authorsTotal = action.payload.authorsTotal;
    },
    setAuthorsIsLoadingMoreData: (state, action) => {
      state.isLoadingMoreData = action.payload.isLoadingMoreData;
    },
    setAuthorsCanLoadMoreData: (state, action) => {
      state.canLoadMoreData = action.payload.canLoadMoreData;
    },
    setIsRemovingAuthor: (state, action) => {
      state.isRemovingAuthor = action.payload.isRemovingAuthor;
    },
    setAuthorId: (state, action) => {
      state.authorId = action.payload.authorId;
    },
  },
});

export const {
  setAuthorDeleted,
  setNewAuthorAdded,
  setAuthorUpdated,
  setAuthors,
  setAuthorsCanLoadMoreData,
  setAuthorsCurrentPage,
  setAuthorsIsLoadingMoreData,
  setAuthorsTotal,
  setAuthorsResetValues,
  setAuthorId,
  setIsRemovingAuthor,
} = authorsSlice.actions;
export default authorsSlice.reducer;
