import React, { useState } from 'react';
import styled from 'styled-components';
import type SurveyDetailsInterface from '../interfaces/SurveyDetails';
import DesignSection from '../components/surveys/SurveyDetails.tsx/DesignSection';
import SettingsSection from '../components/surveys/SurveyDetails.tsx/SettingsSection';
import ResultsSection from '../components/surveys/SurveyDetails.tsx/ResultsSection';
import CustomDivider from '../components/globals/CustomDivider';
import PageDetailsHeader from '../components/globals/PageDetailsHeader';

export default function SurveyDetails(): JSX.Element {
  const [formData, setFormData] = useState<SurveyDetailsInterface>({
    title: '',
    options: [{ content: '' }],
  });

  const handleClickSave = (): void => {};

  return (
    <Container>
      <PageDetailsHeader
        title='Nueva encuesta'
        subtitle='Control general de la encuesta'
        handleClickSave={handleClickSave}
      />
      <DesignSection formData={formData} setFormData={setFormData} />
      <DividerContainer>
        <CustomDivider marginTop={33} marginBottom={24} />
      </DividerContainer>
      <SettingsSection />
      <DividerContainer>
        <CustomDivider marginTop={33} marginBottom={24} />
      </DividerContainer>
      <ResultsSection />
      <DividerContainer>
        <CustomDivider marginTop={33} marginBottom={24} />
      </DividerContainer>
    </Container>
  );
}

const Container = styled.section`
  padding-right: 24px;
  margin-left: 192px;
  margin-top: 72px;
  width: calc(100vw - 192px);
  padding-bottom: 50px;
`;

const DividerContainer = styled.div`
  padding-left: 24px;
  margin-top: 33px;
`;
