import { createSlice } from '@reduxjs/toolkit';
import POSTS_STATUS from '../../constants/status';

interface InitialState {
  selectedStatus: string;
  postPublished: boolean;
  draftPostDeleted: boolean;
  draftPostUpdated: boolean;
  draftPostAdded: boolean;
  pendingReviewPostDeleted: boolean;
  pendingReviewPostUpdated: boolean;
  pendingReviewPostAdded: boolean;
  publishedPostDeleted: boolean;
  publishedPostUpdated: boolean;
  publishedPostAdded: boolean;
  draftsQuantity: undefined | number;
  pendingReviewQuantity: undefined | number;
  publishedQuantity: undefined | number;
}

const initialState: InitialState = {
  selectedStatus: POSTS_STATUS.DRAFT,
  postPublished: false,
  draftPostDeleted: false,
  draftPostUpdated: false,
  draftPostAdded: false,
  pendingReviewPostDeleted: false,
  pendingReviewPostUpdated: false,
  pendingReviewPostAdded: false,
  publishedPostDeleted: false,
  publishedPostUpdated: false,
  publishedPostAdded: false,
  draftsQuantity: undefined,
  pendingReviewQuantity: undefined,
  publishedQuantity: undefined,
};

const entriesSlice = createSlice({
  name: 'entries',
  initialState,
  reducers: {
    setSelectedState: (state, action) => {
      state.selectedStatus = action.payload.selectedStatus;
    },
    setPostPublished: (state, action) => {
      state.postPublished = action.payload.postPublished;
    },
    setDraftPostDeleted: (state, action) => {
      state.draftPostDeleted = action.payload.draftPostDeleted;
    },
    setDraftPostUpdated: (state, action) => {
      state.draftPostUpdated = action.payload.draftPostUpdated;
    },
    setDraftPostAdded: (state, action) => {
      state.draftPostAdded = action.payload.draftPostAdded;
    },
    setPendingReviewPostDeleted: (state, action) => {
      state.pendingReviewPostDeleted = action.payload.pendingReviewPostDeleted;
    },
    setPendingReviewPostUpdated: (state, action) => {
      state.pendingReviewPostUpdated = action.payload.pendingReviewPostUpdated;
    },
    setPendingReviewPostAdded: (state, action) => {
      state.pendingReviewPostAdded = action.payload.pendingReviewPostAdded;
    },
    setPublishedPostDeleted: (state, action) => {
      state.publishedPostDeleted = action.payload.publishedPostDeleted;
    },
    setPublishedPostUpdated: (state, action) => {
      state.publishedPostUpdated = action.payload.publishedPostUpdated;
    },
    setPublishedPostAdded: (state, action) => {
      state.publishedPostAdded = action.payload.publishedPostAdded;
    },
    setDraftsQuantity: (state, action) => {
      state.draftsQuantity = action.payload.draftsQuantity;
    },
    setPendingReviewQuantity: (state, action) => {
      state.pendingReviewQuantity = action.payload.pendingReviewQuantity;
    },
    setPublishedQuantity: (state, action) => {
      state.publishedQuantity = action.payload.publishedQuantity;
    },
  },
});

export const {
  setSelectedState,
  setPostPublished,
  setDraftPostDeleted,
  setDraftPostUpdated,
  setDraftPostAdded,
  setPublishedPostDeleted,
  setPublishedPostAdded,
  setPublishedPostUpdated,
  setPendingReviewPostDeleted,
  setPendingReviewPostAdded,
  setPendingReviewPostUpdated,
  setDraftsQuantity,
  setPendingReviewQuantity,
  setPublishedQuantity,
} = entriesSlice.actions;
export default entriesSlice.reducer;
