import React, { type ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import { RiSearchLine } from 'react-icons/ri';
import { Button, CircularProgress } from '@mui/material';
import TrashIcon from '../../icons/TrashIcon';

interface Props {
  searchInput: string;
  setSearchInput?: React.Dispatch<React.SetStateAction<string>>;
  handleInputChange: (value: string) => void;
  selectedCampaigns?: number[];
  page?: string;
  showModal?: () => void;
  loading?: boolean;
}

interface InputI {
  inputIsFocus: boolean;
}

export default function SearchBarFilter({
  searchInput,
  setSearchInput,
  handleInputChange,
  selectedCampaigns = [],
  page,
  showModal,
  loading = false,
}: Props): JSX.Element {
  const [inputIsFocus, setInputIsFocus] = useState(false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleInputChange(event.target.value);
  };

  return (
    <Container
      style={{
        justifyContent:
          page === 'campaigns' && selectedCampaigns?.length > 0 ? 'space-between' : 'end',
      }}
    >
      {page === 'campaigns' && selectedCampaigns?.length > 0 && (
        <>
          <CustomButton onClick={showModal} startIcon={<TrashIcon />} disabled={loading}>
            {loading ? (
              <CircularProgress size={20} style={{ color: '#CD4F4F' }} />
            ) : (
              <ButtonText>Eliminar</ButtonText>
            )}
          </CustomButton>
        </>
      )}
      <InputContainer inputIsFocus={inputIsFocus}>
        <RiSearchLine color='#183582' size={25} />
        <Input
          onChange={handleChange}
          value={searchInput}
          onFocus={() => {
            setInputIsFocus(true);
          }}
          onBlur={() => {
            setInputIsFocus(false);
          }}
          placeholder='Buscar'
        />
      </InputContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 65px;
  background-color: ${(props) => props.theme.colors.lightSkyBlue};
  border-radius: 10px 10px 0px 0px;
  margin-top: 20px;
  padding-right: 20px;
  padding-left: 20px;

  @media screen and (max-width: 860px) {
    background-color: transparent;
    margin-top: 10px;
    justify-content: inherit;
  }
`;

const InputContainer = styled.div<InputI>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  height: 34px;
  border-radius: 20px;
  padding-left: 12px;
  border: 1px solid ${(props) => (props.inputIsFocus ? props.theme.colors.primary : 'transparent')};

  @media screen and (max-width: 860px) {
    width: 100%;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
    height: 40px;
  }
`;

const Input = styled.input`
  flex: 1;
  color: ${(props) => props.theme.colors.darkGray};
  font-family: ${(props) => props.theme.fonts.regular};
  height: 100%;
  font-size: 16px;
  border: none;
  outline: none;
  margin-left: 10px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  ::placeholder {
    color: ${(props) => props.theme.colors.darkGray};
    font-family: ${(props) => props.theme.fonts.regular};
  }
`;

const CustomButton = styled(Button)`
  border: 1px solid ${(props) => props.theme.colors.red};
  padding: 8px 18px;
  background-color: rgba(205, 79, 79, 0.1);
  margin-left: 10px;
  border-radius: 12px;
`;

const ButtonText = styled.p`
  font-family: ${(props) => props.theme.fonts.medium};
  color: ${(props) => props.theme.colors.red};
  text-transform: none;
`;
