import { createSlice } from '@reduxjs/toolkit';

interface InitialState {
  isMainDrawerOpen: boolean;
}

const initialState: InitialState = {
  isMainDrawerOpen: false,
};

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setIsMainDrawerOpen: (state, action) => {
      state.isMainDrawerOpen = action.payload.isMainDrawerOpen;
    },
  },
});

export const { setIsMainDrawerOpen } = layoutSlice.actions;
export default layoutSlice.reducer;
