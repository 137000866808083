import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import ModalContainer from '../globals/ModalContainer';
import { CircularProgress } from '@mui/material';
import { deleteAuthor } from '../../api/authors';

interface Props {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  authorId: number | null | string;
  authorName: string;
  deleting: boolean;
  setDeleting: React.Dispatch<React.SetStateAction<boolean>>;
  confirmDeleteAuthor: () => Promise<void>;
}

interface CustomButtonI {
  withBackground: boolean;
}

interface CustomButtonTextI {
  white: boolean;
}

export default function ConfirmDeleteModal({
  isVisible,
  setIsVisible,
  authorId,
  authorName,
  deleting,
  setDeleting,
  confirmDeleteAuthor,
}: Props): JSX.Element {
  const handleClose = (): void => {
    setIsVisible(false);
  };

  return (
    <ModalContainer isVisible={isVisible} setIsVisible={setIsVisible}>
      <Container>
        <Title>¿Eliminar autor?</Title>
        <Message>
          Si eliminas a este autor en artículos existentes pero no podrás recuperar su información
          en un futuro.
        </Message>
        <ButtonsContainer>
          <CustomButton
            disabled={deleting}
            onClick={handleClose}
            withBackground={false}
            className='rounded-2xl'
          >
            <ButtonText white={false}>Cancelar</ButtonText>
          </CustomButton>
          <CustomButton
            disabled={deleting}
            onClick={confirmDeleteAuthor}
            withBackground={true}
            style={{ width: '210px' }}
          >
            {deleting ? (
              <CircularProgress size={22} style={{ color: 'white' }} />
            ) : (
              <ButtonText white={true}>Confirmar eliminación</ButtonText>
            )}
          </CustomButton>
        </ButtonsContainer>
      </Container>
    </ModalContainer>
  );
}

const Container = styled.div``;

const Title = styled.p`
  font-family: ${(props) => props.theme.fonts.semiBold};
  color: ${(props) => props.theme.colors.primary};
  font-size: 20px;
  margin-bottom: 15px;
`;

const Message = styled.p`
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.black};
  margin-bottom: 30px;
`;

const Name = styled.span`
  text-transform: capitalize;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
`;

const CustomButton = styled(Button)<CustomButtonI>`
  &&& {
    border: 1px solid;
    border-radius: 20px;
    border-color: ${(props) =>
      props?.withBackground ? 'transparent' : props.theme.colors.primary};
    background-color: ${(props) => (props?.withBackground ? '#F3264B' : 'transparent')};
  }
`;

const ButtonText = styled.p<CustomButtonTextI>`
  text-transform: none;
  font-family: ${(props) => props.theme.fonts.medium};
  color: ${(props) => (props.white ? 'white' : props.theme.colors.primary)};
  font-size: 13px;
  padding: 0px 15px;
`;
