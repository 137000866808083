import React from 'react';
import styled from 'styled-components';
import SectionHeader from '../../globals/SectionHeader';
import GeneralResults from './GeneralResults';
import OptionsPercents from './OptionsPercents';
import VotingBreakdown from './VotingBreakdown';

export default function ResultsSection(): JSX.Element {
  return (
    <Container>
      <SectionHeader title='Resultados' />
      <Content>
        <Column>
          <GeneralResults />
          <OptionsPercents />
        </Column>
        <VotingBreakdown />
      </Content>
    </Container>
  );
}

const Container = styled.section`
  padding-left: 24px;
`;

const Message = styled.p`
  font-family: ${(props) => props.theme.fonts.bold};
  color: ${(props) => props.theme.colors.gray2};
  text-align: center;
  margin-top: 100px;
  margin-bottom: 100px;
`;

const Content = styled.div`
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 14px;
  display: grid;
  grid-template-columns: 1.25fr 1fr;
  gap: 30px;
`;

const Column = styled.div``;
