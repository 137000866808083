import React, { createRef, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { Button } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { ContentState, EditorState, Modifier, SelectionState } from 'draft-js';
import ClickOutsideDetector from '../globals/ClickOutsideDetector';
import { type RootState } from '../../context/store';
import {
  setCurrentSelectedEditorState,
  setCurrentSelectedLink,
  setUnsavedChanges,
  toggleIsCustomLinkModalVisible,
  toogleEditSomeStyleOfCurrentSelectedEditor,
} from '../../context/reducers/editor';
import {
  KEY_IDEA_ITEM,
  WYSIWYG_MAIN_SUBTITLE,
  WYSIWYG_MAIN_TITLE,
} from '../../constants/editorKeys';
import { isValidateURL } from '../../utils/validationURL';

interface CustomInputI {
  error?: boolean;
}

interface ButtonI {
  bgColor: string;
  withBorder: boolean;
}

interface ContainerI {
  isUrlSelected?: boolean;
}

export default function CustomLinkModal() {
  const currentSelectedEditorState = useSelector(
    (state: RootState) => state.editor.currentSelectedEditorState,
  );
  const currentSelectedLink = useSelector((state: RootState) => state.editor.currentSelectedLink);
  const selectedEditorKey = useSelector((state: RootState) => state.editor.selectedEditorKey);
  const [formError, setFormError] = useState<any>({});
  const [text, setText] = useState<string>('');
  const [url, setUrl] = useState<string>('');
  const dispatch = useDispatch();
  const modalRef = createRef<HTMLDivElement>();

  useEffect(() => {
    setText(currentSelectedLink?.title ?? '');
    setUrl(currentSelectedLink?.url ?? '');
  }, [currentSelectedLink]);

  const hideModal = () => {
    dispatch(
      toggleIsCustomLinkModalVisible({
        isCustomLinkModalVisible: false,
      }),
    );
    dispatch(
      setCurrentSelectedLink({
        url: '',
        title: '',
      }),
    );
  };

  const addLinkEntity = () => {
    const contentState = currentSelectedEditorState.getCurrentContent();
    const title = text !== '' ? text : url;
    const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', {
      url,
      text: title,
      title,
    });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const contentStateWithLink = Modifier.applyEntity(
      contentStateWithEntity,
      currentSelectedEditorState.getSelection(),
      entityKey,
    );

    const newEditorState = EditorState.set(currentSelectedEditorState, {
      currentContent: contentStateWithLink,
    });

    const newContentState = Modifier.replaceText(
      contentStateWithLink,
      newEditorState.getSelection(),
      title, // text,
      undefined,
      entityKey,
    );

    const newEditorState2 = EditorState.push(
      currentSelectedEditorState,
      newContentState,
      'insert-characters',
    );

    dispatch(
      setCurrentSelectedEditorState({
        currentSelectedEditorState: newEditorState2,
      }),
    );
    dispatch(toogleEditSomeStyleOfCurrentSelectedEditor({}));
    dispatch(
      setUnsavedChanges({
        unsavedChanges: true,
      }),
    );
    hideModal();
  };

  const handleAddLink = () => {
    const errors: any = {};
    if (!url) {
      errors.url = true;
      errors.urlMessage = 'La URL es obligatoria';
    } else if (!isValidateURL(url)) {
      errors.url = true;
      errors.urlMessage = 'Introduce una URL válida';
    } else if (
      currentSelectedEditorState !== null &&
      selectedEditorKey !== WYSIWYG_MAIN_TITLE &&
      selectedEditorKey !== WYSIWYG_MAIN_SUBTITLE &&
      selectedEditorKey !== KEY_IDEA_ITEM &&
      selectedEditorKey !== null
    ) {
      addLinkEntity();
    }
    setFormError(errors);
  };

  const updateSelectedLink = () => {
    if (
      currentSelectedEditorState !== null &&
      selectedEditorKey !== WYSIWYG_MAIN_TITLE &&
      selectedEditorKey !== WYSIWYG_MAIN_SUBTITLE &&
      selectedEditorKey !== KEY_IDEA_ITEM &&
      selectedEditorKey !== null
    ) {
      const selection = currentSelectedEditorState.getSelection();
      const currentContent = currentSelectedEditorState.getCurrentContent();
      const currentBlock = currentContent.getBlockForKey(selection.getStartKey());
      const entityKey = currentBlock.getEntityAt(selection.getStartOffset());

      if (entityKey) {
        const newEntityData = {
          url,
          text,
        };

        const newEntityKey = currentContent
          .createEntity('LINK', 'MUTABLE', newEntityData)
          .getLastCreatedEntityKey();
        const updatedContent = currentContent.mergeEntityData(entityKey, {
          url,
        });

        const newContentState = Modifier.replaceText(
          updatedContent,
          selection,
          text,
          undefined,
          newEntityKey,
        );

        const newEditorState = EditorState.push(
          currentSelectedEditorState,
          newContentState,
          'apply-entity',
        );
        dispatch(
          setCurrentSelectedEditorState({
            currentSelectedEditorState: newEditorState,
          }),
        );
      }
      dispatch(toogleEditSomeStyleOfCurrentSelectedEditor({}));
      dispatch(
        setUnsavedChanges({
          unsavedChanges: true,
        }),
      );
      hideModal();
    }
  };

  const saveChanges = () => {
    if (currentSelectedLink.url) {
      updateSelectedLink();
    } else {
      handleAddLink();
    }
  };

  const getTitle = () => {
    if (currentSelectedLink?.url) {
      return 'Editar link';
    }
    return 'Insertar un link';
  };

  const getButtonTitle = () => {
    if (currentSelectedLink?.url) {
      return 'Guardar cambios';
    }
    return 'Añadir link';
  };

  return (
    <Container
      layout
      initial={{ opacity: 0.5, x: -10 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.2 }}
      exit={{ opacity: 0.5, x: -10 }}
      isUrlSelected={currentSelectedLink.url !== ''}
    >
      <Title>{getTitle()}</Title>
      <CustomInput
        id='custom-link-modal-text'
        value={text}
        onChange={(e) => {
          setText(e.currentTarget.value);
        }}
        placeholder='Ingresa un texto...'
      />
      <CustomInput
        id='custom-link-modal-url'
        error={formError.url}
        value={url}
        placeholder='Ingresa una URL...'
        onChange={(e) => {
          setUrl(e.currentTarget.value);
          setFormError({ ...formError, url: false, urlMessage: '' });
        }}
      />
      {formError?.url && <ErrorMessage>{formError?.urlMessage}</ErrorMessage>}
      <ButtonsContainer>
        <CustomButton
          onClick={hideModal}
          bgColor='#F0F5FF'
          withBorder={false}
          style={{ width: '140px' }}
        >
          <ButtonText color='#3C3C3B'>Cancelar</ButtonText>
        </CustomButton>
        <CustomButton bgColor={'#183582'} withBorder={false} onClick={saveChanges}>
          <ButtonText color='white'>{getButtonTitle()}</ButtonText>
        </CustomButton>
      </ButtonsContainer>
      <Triangle />
    </Container>
  );
}

const Container = styled(motion.div)<ContainerI>`
  width: 360px;
  position: absolute;
  top: 70px;
  left: -55px;
  box-shadow: 1px 1px 10px rgba(113, 112, 112, 0.15);
  border-radius: 10px;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 32px;
  padding-right: 32px;
  background-color: white;
  z-index: 999;
  border: 1px solid ${(props) => props.theme.colors.lightGray};
`;

const Title = styled.p`
  font-family: ${(props) => props.theme.fonts.medium};
  font-size: 18px;
  margin-bottom: 16px;
  color: ${(props) => props.theme.colors.primary};
`;

const CustomInput = styled.input<CustomInputI>`
  outline: none;
  border: 1px solid
    ${(props) => (props.error ? props.theme.colors.red : props.theme.colors.lightSkyBlue)};
  height: 40px;
  border-radius: 5px;
  width: 300px;
  font-family: ${(props) => props.theme.fonts.regular};
  font-size: 16px;
  color: ${(props) => (props.error ? props.theme.colors.red : props.theme.colors.black)};
  padding-left: 8px;
  padding-right: 8px;
  margin-bottom: 10px;

  @media screen and (max-width: 860px) {
    width: 100%;
  }
`;

const ErrorMessage = styled.p`
  color: ${(props) => props.theme.colors.red};
  font-family: ${(props) => props.theme.fonts.regular};
  font-size: 14px;
  padding-left: 5px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-top: 20px;

  @media screen and (max-width: 860px) {
    justify-content: flex-end;
  }
`;

const CustomButton = styled(Button)<ButtonI>`
  &&& {
    background-color: ${(props) => props.bgColor};
    border-radius: 20px;
  }
  width: 160px;
  height: 44px;
  border: 1px solid ${(props) => (props.withBorder ? props.theme.colors.lightGray : 'white')};
`;

const ButtonText = styled.p`
  font-family: ${(props) => props.theme.fonts.medium};
  color: ${(props) => props.color};
  text-transform: none;
  font-size: 16px;
`;

const Triangle = styled.div`
  position: absolute;
  top: -25px;
  left: 20%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-bottom: 25px solid white;
  @media screen and (max-width: 860px) {
    display: none;
  }
`;
