import React, { useState } from 'react';
import styled from 'styled-components';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { useSelector } from 'react-redux';
import { Skeleton } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import CustomMenu from '../globals/CustomMenu';
import { type RootState } from '../../context/store';
import getAvatar from '../../utils/getAvatar';

interface Props {
  parentComponent?: string;
}

interface ContainerI extends Props {}

interface ProfileI extends Props {}

interface NameI extends Props {}

export default function HeaderProfile({ parentComponent = 'any' }: Props): JSX.Element {
  const userProfile = useSelector((state: RootState) => state.userProfile.userProfile);
  const { logout } = useAuth0();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const options = [
    {
      label: 'Cerrar sesión',
      icon: <LogoutIcon style={{ color: '#3C3C3B' }} />,
      action: async () => {
        try {
          localStorage.clear();
          await logout({
            logoutParams: {
              returnTo: window.location.origin,
            },
          });
        } catch (err) {
          setAnchorEl(null);
        }
      },
    },
  ];

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (parentComponent !== 'menu') {
      setAnchorEl(event.currentTarget);
    }
  };

  const getFullName = () => {
    const fullName = userProfile?.fullName;
    return fullName ?? '';
  };

  return (
    <>
      <Container onClick={handleClick} parentComponent={parentComponent}>
        {parentComponent !== 'menu' && (
          <LineContainer>
            <Line />
          </LineContainer>
        )}
        <Content>
          {userProfile == null ? (
            <Skeleton
              variant='rectangular'
              style={{
                width: '180px',
                height: '40px',
                borderRadius: '8px',
                marginTop: '3px',
                marginBottom: '3px',
              }}
            />
          ) : (
            <>
              <ProfileContainer>
                <Profile parentComponent={parentComponent}>
                  {getAvatar(userProfile?.fullName)}
                </Profile>
              </ProfileContainer>
              <Name parentComponent={parentComponent}>{getFullName()}</Name>
              <IconContainer>
                <KeyboardArrowDownIcon style={{ color: 'white' }} />
              </IconContainer>
            </>
          )}
        </Content>
      </Container>
      <CustomMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        options={options}
        color={'#3C3C3B'}
      />
    </>
  );
}

const Container = styled.div<ContainerI>`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: ${(props) => (props.parentComponent !== 'menu' ? 'pointer' : 'inherit')};
  height: 100%;
  transition: background-color 0.2s linear;
  margin-left: ${(props) => (props.parentComponent === 'menu' ? '0px' : '15px')};
  :hover {
    background-color: rgba(155, 155, 155, 0.2);
  }
`;

const LineContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;

  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const Line = styled.div`
  background-color: ${(props) => props.theme.colors.lightGray};
  width: 1px;
  height: 54px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  padding-left: 12px;
  padding-right: 25px;

  @media screen and (max-width: 860px) {
    padding: 0px;
    padding-right: 10px;
    padding-left: 10px;
  }
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 44px;
  border-radius: 100%;
  background-color: #3431dc;
  border: 1px solid white;
`;

const Profile = styled.div<ProfileI>`
  color: white;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: ${(props) => (props.parentComponent === 'drawer' ? 'bold' : '600')};
`;

const Name = styled.p<NameI>`
  font-size: 13px;
  margin: 0px 14px;
  text-transform: capitalize;
  color: white;

  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const IconContainer = styled.div`
  @media screen and (max-width: 860px) {
    display: none;
  }
`;
