type Timer = ReturnType<typeof setTimeout>;

export default function debounce<T extends (...args: any[]) => void>(
  func: T,
  timeout = 300,
): (...args: Parameters<T>) => void {
  let timer: Timer;
  let previousPromise: Promise<any> | null = null;

  return function (this: any, ...args: Parameters<T>): void {
    clearTimeout(timer);

    const execute = () => {
      previousPromise = null;
      func.apply(this, args);
    };

    timer = setTimeout(() => {
      if (previousPromise) {
        previousPromise.then(execute);
      } else {
        execute();
      }
    }, timeout);

    if (previousPromise) {
      previousPromise.then(() => {
        clearTimeout(timer);
      });
    }
  };
}
