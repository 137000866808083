import React, { useState } from 'react';
import ModalContainer from '../globals/ModalContainer';
import styled, { css } from 'styled-components';
import { Button, CircularProgress, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Close } from '@mui/icons-material';
import POSTS_STATUS from '../../constants/status';
import { setUnsavedChanges } from '../../context/reducers/editor';
import { useDispatch } from 'react-redux';
import sleep from '../../utils/sleep';
import { HTML_TEXT } from '../../constants/globals';

interface Props {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  savingChanges: boolean;
  saveChanges: () => void;
  blockerHandleCancel: () => void;
  blockerHandleDiscardChanges: () => void;
  contentType?: string | null;
}

interface CustomButtonI {
  withBackground: boolean;
  borderColor?: string;
  customType?: string;
}

interface CustomButtonTextI {
  white: boolean;
}

export default function ConfirmDiscardChanges({
  isVisible,
  setIsVisible,
  savingChanges,
  saveChanges,
  blockerHandleCancel,
  blockerHandleDiscardChanges,
  contentType = HTML_TEXT,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleCancel = () => {
    blockerHandleCancel();
    setIsVisible(false);
  };

  const handleDiscardChanges = async () => {
    setIsLoading(true);
    dispatch(
      setUnsavedChanges({
        unsavedChanges: false,
      }),
    );
    await sleep(500);
    navigate(
      `${
        contentType === HTML_TEXT ? '/entries' : '/presentations'
      }?type=${POSTS_STATUS.DRAFT.toLowerCase()}`,
    );
  };

  return (
    <ModalContainer
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      paddingTop={10}
      paddingRight={10}
    >
      <Container>
        <Header>
          <IconButton disabled={isLoading} onClick={handleCancel}>
            <Close />
          </IconButton>
        </Header>
        <Title>¿Estás seguro de salir?</Title>
        <Message>
          Has modificado {contentType === HTML_TEXT ? 'este artículo' : 'este PPT'}. Puedes guardar
          o descartar los cambios. Si quieres seguir editando, cierra este mensaje usando la “x”.
        </Message>
        <ButtonsContainer>
          <CustomButton
            disabled={savingChanges || isLoading}
            onClick={handleDiscardChanges}
            withBackground={false}
          >
            {isLoading ? (
              <CircularProgress size={22} style={{ color: 'white' }} />
            ) : (
              <ButtonText white={false}>Descartar cambios</ButtonText>
            )}
          </CustomButton>
          <CustomButton
            borderColor='#0094CF'
            disabled={savingChanges || isLoading}
            onClick={saveChanges}
            withBackground={true}
            customType='save'
          >
            {savingChanges ? (
              <CircularProgress size={22} style={{ color: 'white' }} />
            ) : (
              <ButtonText white={true}>Guardar cambios</ButtonText>
            )}
          </CustomButton>
        </ButtonsContainer>
      </Container>
    </ModalContainer>
  );
}

const Container = styled.div`
  width: 480px;
  @media screen and (max-width: 660px) {
    width: 100%;
  }
`;

const Title = styled.p`
  font-family: ${(props) => props.theme.fonts.semiBold};
  color: ${(props) => props.theme.colors.primary};
  font-size: 20px;
  margin-bottom: 15px;
  padding-left: 10px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const Message = styled.p`
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.black};
  margin-bottom: 30px;
  padding-left: 10px;
  font-size: 19px;
  padding-right: 15px;
  @media screen and (max-width: 660px) {
    font-size: 16px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
  padding-right: 15px;
  @media screen and (max-width: 660px) {
    flex-direction: column-reverse;
  }
`;

const CustomButton = styled(Button)<CustomButtonI>`
  &&& {
    border: 1px solid;
    border-radius: 20px;
    background-color: ${(props) =>
      props?.withBackground ? props.theme.colors.primary : 'transparent'};
    width: 160px;
    @media screen and (max-width: 660px) {
      width: 100%;
    }
  }
`;

const ButtonText = styled.p<CustomButtonTextI>`
  text-transform: none;
  font-family: ${(props) => props.theme.fonts.medium};
  color: ${(props) => (props.white ? 'white' : props.theme.colors.primary)};
  font-size: 14px;
  padding: 0px 15px;
  white-space: nowrap;
`;
