import React from 'react';
import type Icon from '../interfaces/Icon';

export default function PublishIcon({ width = 19, height = 19, color = 'black' }: Icon) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 19 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.8818 0.68878C18.0565 0.863393 18.1156 1.12259 18.034 1.35567L12.3344 17.6403C12.2473 17.889 12.0172 18.0595 11.7539 18.0702C11.4905 18.0809 11.2473 17.9298 11.1403 17.689L7.98379 10.5868L0.881651 7.43033C0.640802 7.32329 0.489699 7.08007 0.500446 6.81672C0.511186 6.55337 0.681599 6.32326 0.930368 6.2362L17.2149 0.536596C17.448 0.455019 17.7072 0.514166 17.8818 0.68878ZM2.88543 6.91402L8.73185 9.51246C8.8773 9.57709 8.9936 9.69331 9.05823 9.83875L11.6566 15.6852L16.3795 2.19108L2.88543 6.91402Z'
        fill='black'
      />
      <path
        d='M17.8818 0.688762C18.1329 0.939793 18.133 1.3468 17.8819 1.59784L8.92539 10.5544C8.67435 10.8054 8.26732 10.8054 8.01628 10.5544C7.76524 10.3034 7.76524 9.89633 8.01628 9.64529L16.9728 0.688762C17.2238 0.43773 17.6308 0.43773 17.8818 0.688762Z'
        fill='black'
      />
    </svg>
  );
}
