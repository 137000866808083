import React from 'react';
import type Icon from '../interfaces/Icon';

export default function WatchIcon({ width = 18, height = 18 }: Icon) {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <mask id='mask0_1627_1701' maskUnits='userSpaceOnUse' x='0' y='0' width='18' height='18'>
        <rect width='18' height='18' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_1627_1701)'>
        <path
          d='M9.0002 16.5C8.0627 16.5 7.18457 16.3219 6.36582 15.9656C5.54707 15.6094 4.83457 15.1281 4.22832 14.5219C3.62207 13.9156 3.14082 13.2031 2.78457 12.3844C2.42832 11.5656 2.2502 10.6875 2.2502 9.75001C2.2502 8.81251 2.42832 7.93439 2.78457 7.11564C3.14082 6.29689 3.62207 5.58439 4.22832 4.97814C4.83457 4.37189 5.54707 3.89064 6.36582 3.53439C7.18457 3.17814 8.0627 3.00001 9.0002 3.00001C9.9377 3.00001 10.8158 3.17814 11.6346 3.53439C12.4533 3.89064 13.1658 4.37189 13.7721 4.97814C14.3783 5.58439 14.8596 6.29689 15.2158 7.11564C15.5721 7.93439 15.7502 8.81251 15.7502 9.75001C15.7502 10.6875 15.5721 11.5656 15.2158 12.3844C14.8596 13.2031 14.3783 13.9156 13.7721 14.5219C13.1658 15.1281 12.4533 15.6094 11.6346 15.9656C10.8158 16.3219 9.9377 16.5 9.0002 16.5ZM11.1002 12.9L12.1502 11.85L9.7502 9.45001V6.00001H8.2502V10.05L11.1002 12.9ZM4.2002 1.76251L5.2502 2.81251L2.0627 6.00001L1.0127 4.95001L4.2002 1.76251ZM13.8002 1.76251L16.9877 4.95001L15.9377 6.00001L12.7502 2.81251L13.8002 1.76251ZM9.0002 15C10.4627 15 11.7033 14.4906 12.7221 13.4719C13.7408 12.4531 14.2502 11.2125 14.2502 9.75001C14.2502 8.28751 13.7408 7.04689 12.7221 6.02814C11.7033 5.00939 10.4627 4.50001 9.0002 4.50001C7.5377 4.50001 6.29707 5.00939 5.27832 6.02814C4.25957 7.04689 3.7502 8.28751 3.7502 9.75001C3.7502 11.2125 4.25957 12.4531 5.27832 13.4719C6.29707 14.4906 7.5377 15 9.0002 15Z'
          fill='#D9D9D9'
        />
      </g>
    </svg>
  );
}
