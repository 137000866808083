import React, { useEffect } from 'react';
import type Campaign from '../../interfaces/Campaigns';
import styled from 'styled-components';
import { Checkbox } from '@mui/material';
import { CAMPAIGNS_STATUS } from '../../constants/status';

interface Props {
  selectedAll: boolean;
  setSelectedAll: React.Dispatch<React.SetStateAction<boolean>>;
  list: null | Campaign[];
  deleting: boolean;
  selectedCampains: number[];
  setSelectedCampaigns: React.Dispatch<React.SetStateAction<number[]>>;
}

export default function ScheduledListHeader({
  selectedAll,
  setSelectedAll,
  list,
  deleting,
  selectedCampains,
  setSelectedCampaigns,
}: Props) {
  useEffect(() => {
    if (selectedCampains.length === 0) {
      setSelectedAll(false);
    }
  }, [selectedCampains]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    if (newValue) {
      const newSelectedCampaigns = list?.filter(
        (item) => item.status !== CAMPAIGNS_STATUS.IN_PROGRESS,
      );
      setSelectedCampaigns(newSelectedCampaigns?.map((item) => item.id) as number[]);
    } else {
      setSelectedCampaigns([]);
    }
    setSelectedAll(newValue);
  };

  return (
    <Row>
      <LeftContainer>
        <Checkbox
          checked={selectedAll}
          disabled={list === null || deleting}
          onChange={handleChange}
        />
        <Title>Artículo</Title>
      </LeftContainer>
      <MiddleLabel>Notificación programada</MiddleLabel>
      <RightLabel>Audiencia</RightLabel>
    </Row>
  );
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 20px;
  padding-left: 20px;
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr;

  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`;

const Title = styled.p`
  flex: 1;
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.primary};
  font-size: 16px;
  margin-left: 10px;
`;

const MiddleLabel = styled.p`
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.primary};
`;

const RightLabel = styled.p`
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.primary};
  padding-left: 50px;
`;
