import React from 'react';
import type Icon from '../interfaces/Icon';

export default function FiltersIcon({ width = 20, height = 20, color = 'white' }: Icon) {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.125 3.75757C11.6205 3.75757 10.3553 4.83595 10.0635 6.25757H1.875C1.52983 6.25757 1.25 6.53739 1.25 6.88257C1.25 7.22774 1.52983 7.50757 1.875 7.50757H10.0635C10.3553 8.92919 11.6205 10.0076 13.125 10.0076C14.6295 10.0076 15.8947 8.92919 16.1865 7.50757H18.125C18.4702 7.50757 18.75 7.22774 18.75 6.88257C18.75 6.53739 18.4702 6.25757 18.125 6.25757H16.1865C15.8947 4.83595 14.6295 3.75757 13.125 3.75757ZM13.125 5.00757C14.1679 5.00757 15 5.83965 15 6.88257C15 7.92549 14.1679 8.75757 13.125 8.75757C12.0821 8.75757 11.25 7.92549 11.25 6.88257C11.25 5.83965 12.0821 5.00757 13.125 5.00757Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.875 10.0076C5.37051 10.0076 4.10528 11.0859 3.81348 12.5076H1.875C1.52983 12.5076 1.25 12.7874 1.25 13.1326C1.25 13.4777 1.52983 13.7576 1.875 13.7576H3.81348C4.10528 15.1792 5.37051 16.2576 6.875 16.2576C8.37949 16.2576 9.64472 15.1792 9.93652 13.7576H18.125C18.4702 13.7576 18.75 13.4777 18.75 13.1326C18.75 12.7874 18.4702 12.5076 18.125 12.5076H9.93652C9.64472 11.0859 8.37949 10.0076 6.875 10.0076ZM6.875 11.2576C7.91792 11.2576 8.75 12.0896 8.75 13.1326C8.75 14.1755 7.91792 15.0076 6.875 15.0076C5.83208 15.0076 5 14.1755 5 13.1326C5 12.0896 5.83208 11.2576 6.875 11.2576Z'
        fill={color}
      />
    </svg>
  );
}
