import React from 'react';
import styled from 'styled-components';
import loading from '../../assets/loading.svg';

const Loading = (): JSX.Element => (
  <Container>
    <img src={loading} alt='Loading' />
  </Container>
);

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;

export default Loading;
