/* eslint-disable no-empty */
import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import htmlToDraft from 'html-to-draftjs';
import { useLocation } from 'react-router-dom';
import { type EditorElement } from '../../interfaces/Editor';
import { type RootState } from '../../context/store';
import {
  setAddedNewElement,
  setCurrentPostStatus,
  setCurrentSelectedEditorState,
  setElements,
  setElementsContent,
  setEmptyElementsContent,
  setPreviewElements,
  setSelectedEditorKey,
  setUnsavedChanges,
  toggleIsCustomLinkModalVisible,
} from '../../context/reducers/editor';
import {
  EDITOR_NOTES,
  HEADER_IMAGE,
  KEY_IDEAS,
  KEY_PARAGRAPH,
  PDF_URL,
  WYSIWYG_MAIN_SUBTITLE,
  WYSIWYG_MAIN_TITLE,
} from '../../constants/editorKeys';
import {
  BlockMapBuilder,
  ContentState,
  EditorState,
  convertFromHTML,
  ContentBlock,
} from 'draft-js';
import { FILE_TYPE, HTML_TEXT, SEPARATOR } from '../../constants/globals';
import VideoPlayer from './VideoPlayer';
import { tooglePreviewMode } from '../../context/reducers/toolBar';
import EditorNotes from './EditorNotes';
import { checkIfPostHasEditorNotes } from '../../utils/wysiwyg';
import AudioPlayer from './AudioPlayer';
import Immutable, { Map } from 'immutable';
import generateRandomoUUID from '../../utils/generateRandomUUID';
import POSTS_STATUS from '../../constants/status';
import EditorNotesModal from './EditorNotesModal';
import { checkIfIsPresentationPage } from '../../utils/checkIfIsPresentationPage';
import UploadPDFSection from './UploadPDFSection';

interface Props {
  postInformation: any;
  currentStatus: string | null;
  contentType: string | null;
}

interface ContainerI {
  isPreviewModeActive: boolean;
  reviewInProgress: boolean;
}

export default function EditingArea({
  postInformation,
  currentStatus,
  contentType,
}: Props): JSX.Element {
  const isPreviewModeActive = useSelector((state: RootState) => state.toolBar.isPreviewModeActive);
  const elements = useSelector((state: RootState) => state.editor.elements);
  const previewElements = useSelector((state: RootState) => state.editor.previewElements);
  const elementsContent = useSelector((state: RootState) => state.editor.elementsContent);
  const isThereAnyFocusedEditor = useSelector(
    (state: RootState) => state.editor.isThereAnyFocusedEditor,
  );
  const addedNewElement = useSelector((state: RootState) => state.editor.addedNewElement);
  const location = useLocation();
  const dispatch = useDispatch();
  const containerRef = useRef<HTMLDivElement>(null);

  const getKeyIdeas = () => {
    if (postInformation?.content !== null && postInformation?.content !== undefined) {
      const content = postInformation?.content;
      const parsedContent = JSON.parse(content);
      if (Array.isArray(parsedContent)) {
        const keyIdeasIndex = parsedContent?.findIndex((item: any) => item.id === KEY_IDEAS);
        if (keyIdeasIndex !== -1) {
          const htmlContent = parsedContent[keyIdeasIndex]?.content;
          const htmlArray = htmlContent.split(SEPARATOR);

          const newEditorStates = htmlArray.map((item: any) => {
            const contentBlocks = convertFromHTML(item);
            const contentState = ContentState.createFromBlockArray(contentBlocks.contentBlocks);
            return EditorState.createWithContent(contentState);
          });
          return newEditorStates;
        }
      }
    }

    return null;
  };

  const getPostVideoAndAudio = () => {
    const newElements = [...elements];
    const newPreviewElements = [...previewElements];
    if (postInformation?.content !== null && postInformation?.content !== undefined) {
      const content = postInformation?.content;
      const parsedContent = JSON.parse(content);
      const videoIndex = parsedContent?.findIndex((item: any) => item.id === 'video');
      if (videoIndex !== -1) {
        const videoUrl = parsedContent[videoIndex].content;
        const newElement = {
          key: 'video',
          element: <VideoPlayer link={videoUrl} />,
        };
        const index = newElements.findIndex((item) => item.key === newElement.key);
        newElements[index] = newElement;
        newPreviewElements[index] = newElement;
      }
    }

    if (postInformation?.audio?.file !== undefined && postInformation?.audio?.file !== null) {
      const audioFile = postInformation?.audio?.file;
      const newElement = {
        key: 'audio',
        element: <AudioPlayer file={null} audioSource={audioFile} />,
      };
      const index = newElements.findIndex((item) => item.key === newElement.key);
      newElements[index] = newElement;
      newPreviewElements[index] = newElement;
    }
    dispatch(
      setElements({
        elements: newElements,
      }),
    );
    dispatch(
      setPreviewElements({
        previewElements: newPreviewElements,
      }),
    );
  };

  const getMainParagraph = () => {
    if (postInformation?.content !== null && postInformation?.content !== undefined) {
      const content = postInformation?.content;
      const parsedContent = JSON.parse(content);
      if (Array.isArray(parsedContent)) {
        const mainParagraphIndex = parsedContent?.findIndex(
          (item: any) => item.id === KEY_PARAGRAPH,
        );
        if (mainParagraphIndex !== -1) {
          const htmlContent = parsedContent[mainParagraphIndex]?.content;
          let finalContentBlocks: ContentBlock[] = [];
          const entitiesMap: any[] = [];
          htmlContent.blocks?.forEach((item: any, index: number) => {
            if (item?.type === 'custom-divider') {
              const customDividerBlock = new ContentBlock({
                key: generateRandomoUUID(),
                type: 'custom-divider',
                text: '',
                data: Map(),
              });
              finalContentBlocks.push(customDividerBlock);
            } else if (item?.type === 'custom-image') {
              const data = item?.data;
              const customDividerBlock = new ContentBlock({
                key: generateRandomoUUID(),
                type: 'custom-image',
                text: '',
                data: Immutable.Map({
                  url: data?.url,
                  file: data?.file,
                  height: data?.height,
                  width: data?.width,
                }),
              });
              finalContentBlocks.push(customDividerBlock);
            } else {
              const contentBlocks = htmlToDraft(item?.content ?? '');
              finalContentBlocks = [...finalContentBlocks, ...contentBlocks.contentBlocks];
            }
          });
          const contentState = ContentState.createFromBlockArray(finalContentBlocks);
          return EditorState.createWithContent(contentState);
        }
      }
    }
    return null;
  };

  const getPostAudio = () => {
    if (postInformation?.audio?.file !== undefined && postInformation?.audio?.file !== null) {
      const audioFile = postInformation?.audio?.file;
      const newElement = {
        key: 'audio',
        element: <AudioPlayer file={null} audioSource={audioFile} />,
      };
      const newElements = [...elements];
      const index = newElements.findIndex((item) => item.key === newElement.key);
      newElements[index] = newElement;
      dispatch(
        setElements({
          elements: newElements,
        }),
      );
    }
  };

  const getEditorNotes = () => {
    if (postInformation?.content !== null && postInformation?.content !== undefined) {
      const content = postInformation?.content;
      const parsedContent = JSON.parse(content);
      if (Array.isArray(parsedContent)) {
        const editorNotesIndex = parsedContent?.findIndex((item: any) => item.id === EDITOR_NOTES);
        if (editorNotesIndex !== -1) {
          const editorNotes = parsedContent[editorNotesIndex]?.content;
          return editorNotes;
        }
      }
    }
    return null;
  };

  const getHeaderImage = (): string | null => {
    if (postInformation?.images !== null && postInformation?.images !== undefined) {
      return postInformation?.images?.principalImage;
    }
    return null;
  };

  useEffect(() => {
    if (postInformation !== undefined) {
      dispatch(
        setElementsContent({
          customKey: WYSIWYG_MAIN_TITLE,
          content: postInformation?.title,
        }),
      );
      if (postInformation?.contentType === HTML_TEXT) {
        dispatch(
          setElementsContent({
            customKey: WYSIWYG_MAIN_SUBTITLE,
            content: postInformation?.summary ?? null,
          }),
        );
        dispatch(
          setElementsContent({
            customKey: KEY_IDEAS,
            content: getKeyIdeas() ?? new Array(3).fill(EditorState.createEmpty()),
          }),
        );
        dispatch(
          setElementsContent({
            customKey: KEY_PARAGRAPH,
            content: getMainParagraph() ?? null,
          }),
        );
        dispatch(
          setElementsContent({
            customKey: EDITOR_NOTES,
            content: getEditorNotes() ?? '',
          }),
        );
      }
      dispatch(
        setElementsContent({
          customKey: HEADER_IMAGE,
          content: getHeaderImage() ?? '',
        }),
      );
      if (postInformation?.contentType === FILE_TYPE) {
        dispatch(
          setElementsContent({
            customKey: PDF_URL,
            content: postInformation?.content ?? '',
          }),
        );
      }
      if (postInformation?.contentType === HTML_TEXT) {
        getPostVideoAndAudio();
      }
    }
    return () => {
      dispatch(setEmptyElementsContent({}));
      dispatch(tooglePreviewMode({ isPreviewModeActive: false }));
      dispatch(setUnsavedChanges({ unsavedChanges: false }));
      /*       dispatch(
        setCurrentPostStatus({
          currentPostStatus: null,
        }),
      ); */
      dispatch(
        setCurrentSelectedEditorState({
          currentSelectedEditorState: EditorState.createEmpty(),
        }),
      );
      dispatch(
        setSelectedEditorKey({
          selectedEditorKey: null,
        }),
      );
      dispatch(
        toggleIsCustomLinkModalVisible({
          isCustomLinkModalVisible: false,
        }),
      );
    };
  }, []);

  useEffect(() => {
    if (addedNewElement) {
      containerRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
      dispatch(setAddedNewElement({ addedNewElement: false }));
    }
  }, [addedNewElement]);

  return (
    <>
      <Container
        ref={containerRef}
        isPreviewModeActive={isPreviewModeActive}
        reviewInProgress={
          (checkIfPostHasEditorNotes(elementsContent) ||
            currentStatus === POSTS_STATUS.REVIEW_IN_PROGRESS) &&
          currentStatus !== POSTS_STATUS.PUBLISHED
        }
      >
        {elementsContent[0].content !== null &&
          elements.map((editorElement: EditorElement, index) => {
            if (checkIfIsPresentationPage(location.pathname)) {
              if (
                editorElement.key === 'header-image' ||
                editorElement.key === WYSIWYG_MAIN_TITLE
              ) {
                return <div key={`${editorElement.key}_${index}`}>{editorElement.element}</div>;
              }
              return <></>;
            }
            return <div key={`${editorElement.key}_${index}`}>{editorElement.element}</div>;
          })}
        {checkIfIsPresentationPage(location.pathname) && <UploadPDFSection />}
      </Container>
      {elementsContent[0].content !== null &&
        !isPreviewModeActive &&
        !checkIfIsPresentationPage(location.pathname) && (
          <>
            <EditorNotes currentStatus={currentStatus} />
            <EditorNotesModal currentStatus={currentStatus} />
          </>
        )}
    </>
  );
}

const Container = styled.section<ContainerI>`
  width: 920px;
  margin: 0px auto;
  padding-bottom: ${(props) => (props.reviewInProgress ? '0px' : '85px')};
  ${(props) =>
    props.isPreviewModeActive &&
    css`
      height: 10px;
      padding-bottom: 0px;
      overflow: hidden;
    `}

  @media screen and (max-width: 860px) {
    width: calc(100vw - 40px);
  }
`;
