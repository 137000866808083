import React from 'react';
import Button from '@mui/material/Button';
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import styled from 'styled-components';
import { motion } from 'framer-motion';

interface Props {
  buttonText: string;
  handleOnClick: () => void;
}

export default function NewPublicationBtn({ buttonText, handleOnClick }: Props): JSX.Element {
  return (
    <motion.div whileHover={{ scale: 1.03 }}>
      <Button
        onClick={handleOnClick}
        className='bg-primary-blue px-9 rounded-2xl'
        startIcon={<ControlPointOutlinedIcon style={{ color: 'white' }} />}
      >
        <ButtonText>{buttonText}</ButtonText>
      </Button>
    </motion.div>
  );
}

const ButtonText = styled.p`
  color: white;
  font-family: ${(props) => props.theme.fonts.regular};
  font-size: 15px;
  text-transform: none;
`;
