import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import WelcomeMessage from '../components/home/WelcomeMessage';
import NewPublicationBtn from '../components/home/NewPublicationBtn';
import PublicationsOverview from '../components/home/PublicationsOverview';
import DraftsList from '../components/home/DraftsList';
import PublicationsList from '../components/home/PublicationsList';
import { setInputOfGeneralSearch } from '../context/reducers/generalSearch';
import { useDispatch, useSelector } from 'react-redux';
import { type RootState } from '../context/store';
import { getCategories } from '../api/categories';
import { setCategoriesList } from '../context/reducers/categories';
import { openSnackbar } from '../context/reducers/generalSnackbar';
import { CATEGORIES_ERRORS } from '../constants/errorMessages';
import { AxiosError } from 'axios';
import MobileNewPublicationButton from '../components/globals/MobileNewPublicationButton';
import MobilePublicationsList from '../components/home/MobilePublicationsList';
import MobileHomeNewButton from '../components/home/MobileHomeNewButton';

export default function Home(): JSX.Element {
  const categoriesList = useSelector((state: RootState) => state.categories.categoriesList);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const callApiToGetCategories = (): void => {
    getCategories()
      .then((res) => {
        dispatch(
          setCategoriesList({
            categoriesList: res,
          }),
        );
      })
      .catch((error: unknown) => {
        let errorStatus;
        if (error instanceof AxiosError) {
          errorStatus = error?.response?.status;
        }
        if (errorStatus !== 401) {
          dispatch(
            openSnackbar({
              type: 'error',
              message: CATEGORIES_ERRORS.CATEGORIES_LIST,
            }),
          );
        }
      });
  };

  useEffect(() => {
    if (categoriesList === null) {
      callApiToGetCategories();
    }
  }, []);

  const handleOnClick = (): void => {
    dispatch(
      setInputOfGeneralSearch({
        searchInput: '',
      }),
    );
    navigate('/new-post', { state: { prevRouteName: location.pathname } });
  };

  return (
    <Container>
      <Header>
        <WelcomeMessage />
        <NewPublicationBtnContainer>
          <NewPublicationBtn buttonText='Nueva publicación' handleOnClick={handleOnClick} />
        </NewPublicationBtnContainer>
      </Header>
      <PublicationsOverview />
      <PublicationsContainer>
        <PublicationsList />
        <Line />
        <DraftsList />
      </PublicationsContainer>
      <MobileHomeNewButton />
    </Container>
  );
}

const Container = styled.section`
  padding-left: 24px;
  padding-right: 24px;
  margin-left: 192px;
  margin-top: 72px;
  width: calc(100vw - 192px);

  @media screen and (max-width: 860px) {
    margin-left: 0px;
    width: 100vw;
    margin-top: 140px;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 42px;

  @media screen and (max-width: 860px) {
    padding: 0px 24px;
  }
`;

const NewPublicationBtnContainer = styled.div`
  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const PublicationsContainer = styled.section`
  display: grid;
  margin-top: 32px;
  grid-template-columns: 1.6fr 1px 1fr;
  grid-gap: 24px;
  padding-bottom: 100px;

  @media screen and (max-width: 860px) {
    grid-template-columns: 1fr;
    padding-left: 24px;
    padding-right: 24px;
    gap: 0px;
  }
`;

const Line = styled.div`
  border: 1px solid;
  border-color: ${(props) => props.theme.colors.lightGray};

  @media screen and (max-width: 860px) {
    display: none;
  }
`;
