/* eslint-disable react/jsx-key */
import React, { type SyntheticEvent, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { IconButton } from '@mui/material';
import PenIcon from '../../icons/PenIcon';
import TagChip from './TagChip';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setUnsavedChanges } from '../../context/reducers/editor';
import { type RootState } from '../../context/store';
import type Category from '../../interfaces/Category';
import {
  MAX_AUTHORS_QUANTITY,
  MAX_CATEGORIES_QUANTITY,
  MAX_TAGS_QUANTITY,
} from '../../constants/globals';
import { openSnackbar } from '../../context/reducers/generalSnackbar';
import { POST_ERRORS } from '../../constants/errorMessages';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;
const COMBOBOX_WIDTH = '240px';

interface Props {
  page?: string;
  type?: string;
  label?: string;
  placeholder: string;
  parent?: string;
  auxOptions?: any[] | null;
  multiple?: boolean;
  values?: any[] | null | string;
  setValues?: React.Dispatch<React.SetStateAction<any[]>>;
  handleChange?: (
    newValues: any[] | string | null,
    deleteFromTagChip: React.MutableRefObject<boolean>,
  ) => void;
  setWantToLoadMoreData?: React.Dispatch<React.SetStateAction<boolean>>;
  canLoadMoreData?: boolean;
  setCurrentVersion?: React.Dispatch<React.SetStateAction<number>>;
}

interface ListboxProps {
  onScroll?: React.UIEventHandler<HTMLUListElement>;
  ref?: React.Ref<HTMLUListElement>;
}

interface AutocompleteI {
  page: string;
}

interface TagsContainerI {
  page?: string;
}

interface InputI {
  parent?: string;
  open: boolean;
  type: string;
  page?: string;
  hiddenOptionsCount?: number;
}

interface IconButtonI {
  rotate: boolean;
}

const getDefaultValue = (
  type: string,
  values: any[] | null | undefined | string,
): any[] | string | null => {
  if (type === 'tag' || type === 'author' || type === 'categories' || type === 'subscription') {
    if (values !== null && values !== undefined) {
      return values;
    }
    return [];
  } else if (type === 'resources' && values !== undefined) {
    return values;
  }
  return [];
};

export default function ComboBox({
  page = 'entries',
  type = 'any',
  label = '',
  placeholder,
  parent = 'home',
  auxOptions = [],
  multiple = false,
  values,
  handleChange,
  setWantToLoadMoreData,
  canLoadMoreData,
  setCurrentVersion,
}: Props): JSX.Element {
  const categoriesList = useSelector((state: RootState) => state.categories.categoriesList);
  const isRemovingSubscription = useSelector(
    (state: RootState) => state.subscriptions.isRemovingSubscription,
  );
  const isRemovingCategory = useSelector((state: RootState) => state.categories.isRemovingCategory);
  const isRemovingTag = useSelector((state: RootState) => state.tags.isRemovingTag);
  const isRemovingAuthor = useSelector((state: RootState) => state.authors.isRemovingAuthor);
  const [open, setOpen] = useState(false);
  const [rotateIcon, setRotateIcon] = useState(false);
  const [options, setOptions] = useState<any[] | null>(auxOptions);
  const [auxValues, setAuxValues] = useState<any[] | string | null>(getDefaultValue(type, values));
  const [visibleAuxValues, setVisibleAuxValues] = useState<any[] | string | null>(
    getDefaultValue(type, values),
  );
  const [hiddenOptionsCount, setHiddenOptionsCount] = useState<number>(0);
  const location = useLocation();
  const dispatch = useDispatch();
  const listboxRef = useRef<HTMLUListElement>(null);
  const comboBoxRef = useRef<HTMLSelectElement>();
  const deleteFromTagChip = useRef(false);

  useEffect(() => {
    if (type === 'author') {
      setOptions(auxOptions);
    }
  }, [auxOptions]);

  const getCategoryName = (selectedCategory: Category | string): string => {
    if (categoriesList !== null) {
      const auxCategoriesList: Category[] = [...categoriesList];
      const index = auxCategoriesList?.findIndex((category) =>
        typeof selectedCategory === 'string'
          ? category.id === selectedCategory
          : category.id === selectedCategory.id,
      );
      return index === -1 ? '' : auxCategoriesList[index].name;
    }
    return '';
  };

  const getOptionName = (option: any): string => {
    if (type === 'author') {
      return option.names;
    } else if (type === 'categories') {
      return getCategoryName(option);
    }
    return '';
  };

  const calculateVisibleOptions = () => {
    const comboBoxWidth = comboBoxRef.current?.offsetWidth || 0;
    let visibleWidth = 0;
    let hiddenCount = 0;
    let optionWidth = 0;
    let lastOptionWidth = 0;
    let totalRealVisibleWidth = 0;
    let realOptionWidth = 0;
    let isTheFirstSelectedOption = true;
    let lastOptionIndex = 0;
    if (auxValues !== null && Array.isArray(auxValues)) {
      auxValues.forEach((value, index) => {
        optionWidth = getOptionName(value).length * 6;
        realOptionWidth += getOptionName(value).length * 6 + 13 + 30;
        if (isTheFirstSelectedOption) {
          totalRealVisibleWidth += getOptionName(value).length * 6 + 13 + 30;
        }
        if (visibleWidth + realOptionWidth <= comboBoxWidth - 30 - 25 - 4) {
          visibleWidth += realOptionWidth;
        } else {
          if (!isTheFirstSelectedOption) {
            hiddenCount += 1;
          } else {
            lastOptionWidth = realOptionWidth;
            lastOptionIndex = index;
          }
          isTheFirstSelectedOption = false;
        }
      });

      const finalComboBoxWidth = comboBoxWidth - 30 - 25;
      const lastOption = auxValues[lastOptionIndex];
      const newAuxValues = auxValues.slice(0, auxValues.length - hiddenCount);
      setHiddenOptionsCount(hiddenCount);
      if (Array.isArray(newAuxValues)) {
        setVisibleAuxValues(
          newAuxValues.map((item, index) => ({
            ...item,
            finalComboBoxWidth,
            visibleWidth,
            lastOptionWidth,
            totalRealVisibleWidth: totalRealVisibleWidth + 3 * (newAuxValues.length - 1),
          })),
        );
      }
    }
  };

  useEffect(() => {
    if (page === 'entries') {
      calculateVisibleOptions();
    }
  }, [auxValues, categoriesList]);

  useEffect(() => {
    setRotateIcon(open);
  }, [open]);

  useEffect(() => {
    if (handleChange !== undefined) {
      handleChange(auxValues, deleteFromTagChip);
    }
    deleteFromTagChip.current = false;
  }, [auxValues]);

  useEffect(() => {
    setOptions(auxOptions);
  }, [auxOptions]);

  const getOptionTitle = (option: any): string => {
    if (type === 'author') {
      return option?.names;
    } else if (type === 'categories' || type === 'subscription') {
      return option?.name;
    } else if (type === 'tag') {
      return option?.tag;
    }
    return option?.title;
  };

  const getOptionLabel = (option: any): string => {
    if (type === 'author') {
      return option?.names;
    } else if (type === 'categories' || type === 'subscription') {
      return option?.name;
    } else if (type === 'tag') {
      return option?.tag;
    }
    return option?.title;
  };

  const isOptionEqualToValue = (option: any, value: any): boolean => {
    if (type === 'categories' || type === 'subscription') {
      return value.id ? value.id === option.id : option.id === value;
    } else if (type === 'author') {
      return value.authorId ? option.id === value.authorId : option.id === value.id;
    } else if (type === 'tag') {
      return option?.tag === value?.tag;
    }
    return option.title === value.title;
  };

  const getMessage = () => {
    if (type === 'author') return 'Aún no se han registrado autores.';
    if (type === 'tag') return 'Aún no se han registrado etiquetas.';
    if (type === 'categories') return 'No se encontraron categorías';
    if (type === 'subscription') return 'No se encontraron suscripciones';
    return 'Sin resultados';
  };

  const handleDelete = (id: string) => {
    if (page === 'editor') {
      deleteFromTagChip.current = true;
    }
    if (auxValues !== null && Array.isArray(auxValues)) {
      if (type === 'tag') {
        setAuxValues(auxValues?.filter((auxValue, index) => auxValue.tag !== id));
      } else if (type === 'author') {
        setAuxValues(
          auxValues?.filter((auxValue, index) =>
            auxValue.authorId ? auxValue.authorId !== id : auxValue.id !== id,
          ),
        );
      } else if (type === 'categories' || type === 'subscription') {
        setAuxValues(
          auxValues?.filter((auxValue, index) =>
            auxValue.id ? auxValue.id !== id : auxValue !== id,
          ),
        );
      }
      // detectChanges();
    }
  };

  const detectChanges = () => {
    if (
      (type === 'categories' || type === 'tag' || type === 'author' || type === 'subscription') &&
      location.pathname.startsWith('/post/')
    ) {
      dispatch(
        setUnsavedChanges({
          unsavedChanges: true,
        }),
      );
    }
  };

  const handleMenuScroll: React.UIEventHandler<HTMLUListElement> = (event) => {
    if (type === 'author') {
      const listbox = listboxRef.current;
      if (listbox) {
        const scrolledToBottom = listbox.scrollTop + listbox.clientHeight === listbox.scrollHeight;
        if (scrolledToBottom && setWantToLoadMoreData !== undefined) {
          setWantToLoadMoreData(true);
        }
      }
    }
  };

  return (
    <>
      {label !== '' && <Label>{label}</Label>}
      <Autocomplete
        disabled={isRemovingSubscription || isRemovingCategory || isRemovingTag || isRemovingAuthor}
        ListboxProps={{ onScroll: handleMenuScroll, ref: listboxRef } as ListboxProps}
        ref={comboBoxRef}
        sx={{
          minWidth: page === 'entries' ? (type === 'resources' ? '180px' : COMBOBOX_WIDTH) : '100%',
        }}
        value={auxValues}
        multiple={multiple}
        loadingText={
          <CircularProgressContainer>
            <CircularProgress size={20} />
          </CircularProgressContainer>
        }
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        disableCloseOnSelect={true}
        onChange={(event: SyntheticEvent<Element, Event>, value: any[]) => {
          if (Array.isArray(values) && page === 'editor') {
            if (type === 'categories' && value.length > MAX_CATEGORIES_QUANTITY) {
              dispatch(
                openSnackbar({
                  type: 'error',
                  message: POST_ERRORS.MAX_CATEGORIES_QUANTITY,
                }),
              );
              return;
            }
            if (type === 'tag' && value.length > MAX_TAGS_QUANTITY) {
              dispatch(
                openSnackbar({
                  type: 'error',
                  message: POST_ERRORS.MAX_TAGS_QUANTITY,
                }),
              );
              return;
            }
            if (type === 'author' && value.length > MAX_AUTHORS_QUANTITY) {
              dispatch(
                openSnackbar({
                  type: 'error',
                  message: POST_ERRORS.MAX_AUTHORS_QUANTITY,
                }),
              );
              return;
            }
          }
          // detectChanges();
          if (setAuxValues !== undefined) {
            setAuxValues(value);
          }
        }}
        noOptionsText={
          <NoOptionsContainer>
            <PenIcon />
            <Message>{getMessage()}</Message>
          </NoOptionsContainer>
        }
        isOptionEqualToValue={(option, value) => isOptionEqualToValue(option, value)}
        getOptionLabel={(option) => getOptionLabel(option)}
        options={options ?? []}
        loading={options == null}
        renderInput={(params) => {
          return (
            <CustomInputContainer
              page={page}
              type={auxValues !== null && auxValues?.length > 0 && page === 'editor' ? 'tag' : ''}
              open={open}
              parent={parent}
              ref={params.InputProps.ref}
              style={{
                paddingLeft:
                  page === 'editor'
                    ? '12px'
                    : auxValues !== null && auxValues?.length > 0
                    ? '4px'
                    : '12px',
              }}
            >
              <Row
                style={{
                  flexWrap: page === 'editor' ? 'wrap' : 'nowrap',
                  gap: page === 'editor' ? '10px' : '2px',
                }}
              >
                {type !== 'resources' &&
                  (page === 'entries' ? (
                    visibleAuxValues !== null &&
                    visibleAuxValues?.length > 0 && (
                      <TagsContainer page={page}>
                        {Array.isArray(visibleAuxValues) &&
                          visibleAuxValues?.map((item, index) => (
                            <TagChip
                              type={type}
                              item={item}
                              key={index}
                              isTheLast={index === visibleAuxValues?.length - 1}
                              handleDelete={handleDelete}
                              page={page}
                              hiddenOptionsCount={hiddenOptionsCount}
                              finalComboBoxWidth={item?.finalComboBoxWidth}
                              visibleWidth={item?.visibleWidth}
                              lastOptionWidth={item?.lastOptionWidth}
                              totalRealVisibleWidth={item?.totalRealVisibleWidth}
                              setCurrentVersion={setCurrentVersion}
                            />
                          ))}
                      </TagsContainer>
                    )
                  ) : auxValues !== null && auxValues?.length > 0 ? (
                    <TagsContainer page={page}>
                      {Array.isArray(auxValues) &&
                        auxValues?.map((item, index) => (
                          <TagChip
                            type={type}
                            item={item}
                            key={index}
                            isTheLast={index === auxValues?.length - 1}
                            handleDelete={handleDelete}
                            page={page}
                            hiddenOptionsCount={hiddenOptionsCount}
                            finalComboBoxWidth={item?.finalComboBoxWidth}
                            visibleWidth={item?.visibleWidth}
                            lastOptionWidth={item?.lastOptionWidth}
                            totalRealVisibleWidth={item?.totalRealVisibleWidth}
                            setCurrentVersion={setCurrentVersion}
                          />
                        ))}
                    </TagsContainer>
                  ) : (
                    <></>
                  ))}
                <CustomInput
                  style={{
                    marginLeft: auxValues !== null && auxValues?.length > 0 ? '5px' : '0px',
                    width: hiddenOptionsCount > 0 ? '0px' : '100%',
                    userSelect: hiddenOptionsCount > 0 ? 'none' : 'auto',
                  }}
                  open={open}
                  parent={parent}
                  placeholder={placeholder}
                  hiddenOptionsCount={hiddenOptionsCount}
                  type='text'
                  {...params}
                  {...params.inputProps}
                  size={1}
                />
              </Row>
              {hiddenOptionsCount > 0 && type !== 'resources' && page === 'entries' && (
                <HiddenOptionsCountContainer>
                  <HiddenOptionsCount>+{hiddenOptionsCount}</HiddenOptionsCount>
                </HiddenOptionsCountContainer>
              )}
              <CustomIconButton
                rotate={rotateIcon}
                onClick={() => {
                  if (
                    !(
                      (type === 'subscription' && isRemovingSubscription) ||
                      (type === 'categories' && isRemovingCategory) ||
                      (type === 'tag' && isRemovingTag) ||
                      (type === 'author' && isRemovingAuthor)
                    )
                  ) {
                    setOpen(!open);
                  }
                }}
              >
                <KeyboardArrowDownIcon style={{ color: '#183582' }} />
              </CustomIconButton>
            </CustomInputContainer>
          );
        }}
        renderOption={(props, option, { selected }) => (
          <Option {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ borderRadius: '15px', color: '#183582' }}
              checked={selected}
            />
            <OptionTitle>{getOptionTitle(option)}</OptionTitle>
          </Option>
        )}
      />
    </>
  );
}

const Label = styled.p`
  font-family: ${(props) => props.theme.fonts.semiBold};
  color: ${(props) => props.theme.colors.darkGray};
  font-size: 14px;
  padding-left: 12px;
  margin-bottom: 10px;
  margin-top: 28px;
`;

const CustomInputContainer = styled.div<InputI>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  height: ${(props) =>
    props.parent === 'drawer' ? (props.type === 'tag' ? 'auto' : '40px') : '34px'};
  padding-top: ${(props) => (props.type === 'tag' ? '10px' : '0px')};
  padding-bottom: ${(props) => (props.type === 'tag' ? '10px' : '0px')};
  border-radius: 20px;
  margin-left: ${(props) => (props.parent === 'drawer' ? '12px' : '0px')};
  margin-right: ${(props) => (props.parent === 'drawer' ? '20px' : '0px')};
  gap: 2px;
  border: 1px solid
    ${(props) =>
      props.open
        ? props.theme.colors.primary
        : props.parent === 'drawer'
        ? props.theme.colors.lightSkyBlue
        : 'white'};
  flex-wrap: ${(props) => (props.page === 'entries' ? 'nowrap' : 'wrap')};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  overflow-y: hidden;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

const TagsContainer = styled.div<TagsContainerI>`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: ${(props) => (props.page === 'entries' ? 'nowrap' : 'wrap')};
  gap: 3px;
`;

const CustomInput = styled.input<InputI>`
  font-family: ${(props) => props.theme.fonts.regular};
  height: 100%;
  color: ${(props) =>
    props.parent === 'drawer' ? props.theme.colors.gray2 : props.theme.colors.darkGray};
  font-size: ${(props) => (props.parent === 'drawer' ? '13px' : '16px')};
  border: none;
  outline: none;
  ::placeholder {
    color: ${(props) =>
      props.parent === 'drawer' ? props.theme.colors.gray2 : props.theme.colors.darkGray};
    font-family: ${(props) => props.theme.fonts.regular};
  }
`;

const CustomIconButton = styled(IconButton)<IconButtonI>`
  height: 30px;
  width: 30px;
  transition: all 0.3s ease-out;
  ${({ rotate }) => rotate && `transform: rotate(180deg)`};
`;

const Option = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  border: 1px solid ${(props) => props.theme.colors.lightSkyBlue};
  &&& {
    padding-left: 0px;
  }
`;

const OptionTitle = styled.p`
  flex: 1;
  font-size: 13px;
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.darkGray};
  text-transform: capitalize;
`;

const CircularProgressContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const NoOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  gap: 8px;
  padding-top: 15px;
  padding-bottom: 15px;
`;

const Message = styled.p`
  font-family: ${(props) => props.theme.fonts.medium};
  color: ${(props) => props.theme.colors.gray2};
  font-size: 14px;
  text-align: center;
`;

const HiddenOptionsCountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #cceaf5;
  width: 25px;
  height: 25px;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 20px;
`;

const HiddenOptionsCount = styled.p`
  font-family: ${(props) => props.theme.fonts.regular};
  font-size: 11px;
  color: ${(props) => props.theme.colors.darkGray};
`;
