import React from 'react';
import styled from 'styled-components';

export default function PercentBar(): JSX.Element {
  return (
    <Container>
      <Bar />
      <Title>Sí, antes de mitad del año</Title>
      <Percent>45%</Percent>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  border: 1px solid ${(props) => props.theme.colors.primary};
  border-radius: 5px;
  margin-bottom: 12px;
`;

const Bar = styled.div`
  width: 7px;
  height: 100%;
  background-color: ${(props) => props.theme.colors.skyBlue};
  margin-right: 9px;
`;

const Title = styled.p`
  flex: 1;
  margin-right: 20px;
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.gray2};
`;

const Percent = styled.p`
  margin-right: 11px;
  color: ${(props) => props.theme.colors.gray2};
`;
