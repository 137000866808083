import React from 'react';
import type Icon from '../interfaces/Icon';

export default function ClockIcon({ color = '#006F9B' }: Icon): JSX.Element {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.00008 0.666626C13.6001 0.666626 17.3334 4.39996 17.3334 8.99996C17.3334 13.6 13.6001 17.3333 9.00008 17.3333C4.40008 17.3333 0.666748 13.6 0.666748 8.99996C0.666748 4.39996 4.40008 0.666626 9.00008 0.666626ZM9.00008 15.6666C12.6834 15.6666 15.6667 12.6833 15.6667 8.99996C15.6667 5.31663 12.6834 2.33329 9.00008 2.33329C5.31675 2.33329 2.33341 5.31663 2.33341 8.99996C2.33341 12.6833 5.31675 15.6666 9.00008 15.6666ZM11.9467 4.87496L13.1251 6.05329L9.00008 10.1783L7.82175 8.99996L11.9467 4.87496Z'
        fill={color}
      />
    </svg>
  );
}
