import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import type PublicationInterface from '../../interfaces/Publication';
import { type RootState } from '../../context/store';
import PublishedItem from '../entries/PublishedItem';
import PublicationItem from '../home/PublicationItem';
import NotFoundIcon from '../../icons/NotFoundIcon';
import { getPosts } from '../../api/posts';
import POSTS_STATUS from '../../constants/status';
import { PAGE_SIZE } from '../../constants/pagination';
import { openSnackbar } from '../../context/reducers/generalSnackbar';
import { POST_ERRORS } from '../../constants/errorMessages';
import { debounce } from 'lodash';
import PublicationSkeleton from '../home/PublicationSkeleton';
import {
  setGeneralSearchPosts,
  setGeneralSearchPostsCanLoadMoreData,
  setGeneralSearchPostsCurrentPage,
  setGeneralSearchPostsIsLoadingMoreData,
  setGeneralSearchPostsTotal,
  setInputOfGeneralSearch,
} from '../../context/reducers/generalSearch';
import { RiSearchLine } from 'react-icons/ri';
import { AxiosError } from 'axios';

interface ContainerI {
  fullWidth: boolean;
}

export default function GeneralSearch(): JSX.Element {
  const isVisible = useSelector((state: RootState) => state.generalSearch.isVisible);
  const searchInput = useSelector((state: RootState) => state.generalSearch.searchInput);
  const posts = useSelector((state: RootState) => state.generalSearch.generalSearchPosts);
  const currentPage = useSelector(
    (state: RootState) => state.generalSearch.generalSearchPostsCurrentPage,
  );
  const isLoadingMoreData = useSelector(
    (state: RootState) => state.generalSearch.generalSearchPostsIsLoadingMoreData,
  );
  const canLoadMoreData = useSelector(
    (state: RootState) => state.generalSearch.generalSearchPostsCanLoadMoreData,
  );
  const total = useSelector((state: RootState) => state.generalSearch.generalSearchPostsTotal);
  const location = useLocation();
  const dispatch = useDispatch();
  const customComponentRef = useRef<HTMLDivElement>(null);

  const loadMoreData = async () => {
    if (canLoadMoreData && !isLoadingMoreData && posts !== null) {
      dispatch(
        setGeneralSearchPostsIsLoadingMoreData({
          generalSearchPostsIsLoadingMoreData: true,
        }),
      );
      try {
        const response = await getPosts(
          searchInput || '',
          [
            POSTS_STATUS.DRAFT,
            POSTS_STATUS.PENDING_REVIEW,
            POSTS_STATUS.REVIEW_IN_PROGRESS,
            POSTS_STATUS.PUBLISHED,
          ],
          PAGE_SIZE,
          currentPage,
          'lastModifiedDate,DESC',
          [],
          [],
          undefined,
          undefined,
        );
        const { last, content } = response;
        if (!last) {
          dispatch(
            setGeneralSearchPostsCurrentPage({
              generalSearchPostsCurrentPage: currentPage + 1,
            }),
          );
          dispatch(
            setGeneralSearchPostsCanLoadMoreData({
              generalSearchPostsCanLoadMoreData: true,
            }),
          );
        } else {
          dispatch(
            setGeneralSearchPostsCanLoadMoreData({
              generalSearchPostsCanLoadMoreData: false,
            }),
          );
        }
        dispatch(
          setGeneralSearchPostsIsLoadingMoreData({
            generalSearchPostsIsLoadingMoreData: false,
          }),
        );
        if (posts !== null) {
          const newPosts = [...posts, ...content];
          dispatch(
            setGeneralSearchPosts({
              generalSearchPosts: newPosts,
            }),
          );
        }
      } catch (error: unknown) {
        let errorStatus;
        if (error instanceof AxiosError) {
          errorStatus = error?.response?.status;
        }
        if (errorStatus !== 401) {
          dispatch(
            openSnackbar({
              type: 'error',
              message: POST_ERRORS.SEARCH_GENERAL,
            }),
          );
        }
      }
    }
  };

  useEffect(() => {
    const customComponentElement = customComponentRef.current;

    const handleScroll = () => {
      if (
        customComponentElement &&
        customComponentElement.scrollTop + customComponentElement.clientHeight + 10 >=
          customComponentElement.scrollHeight
      ) {
        loadMoreData();
      }
    };
    if (customComponentElement) {
      customComponentElement.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (customComponentElement) {
        customComponentElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [currentPage, canLoadMoreData, isLoadingMoreData, searchInput]);

  useEffect(() => {
    return () => {
      dispatch(
        setGeneralSearchPosts({
          generalSearchPosts: null,
        }),
      );
    };
  }, []);

  useEffect(() => {
    if (searchInput !== '') {
      getPosts(
        searchInput || '',
        [
          POSTS_STATUS.DRAFT,
          POSTS_STATUS.PENDING_REVIEW,
          POSTS_STATUS.REVIEW_IN_PROGRESS,
          POSTS_STATUS.PUBLISHED,
        ],
        PAGE_SIZE,
        0,
        '',
        [],
        [],
        undefined,
        undefined,
      )
        .then((res) => {
          dispatch(
            setGeneralSearchPosts({
              generalSearchPosts: res.content,
            }),
          );
          dispatch(
            setGeneralSearchPostsTotal({
              generalSearchPostsTotal: res.totalElements,
            }),
          );
          if (!res.last) {
            dispatch(
              setGeneralSearchPostsCurrentPage({
                generalSearchPostsCurrentPage: 1,
              }),
            );
            dispatch(
              setGeneralSearchPostsCanLoadMoreData({
                generalSearchPostsCanLoadMoreData: true,
              }),
            );
          } else {
            dispatch(
              setGeneralSearchPostsCurrentPage({
                generalSearchPostsCurrentPage: 0,
              }),
            );
            dispatch(
              setGeneralSearchPostsCanLoadMoreData({
                generalSearchPostsCanLoadMoreData: false,
              }),
            );
          }
        })
        .catch((error: unknown) => {
          let errorStatus;
          if (error instanceof AxiosError) {
            errorStatus = error?.response?.status;
          }
          if (errorStatus !== 401) {
            dispatch(
              openSnackbar({
                type: 'error',
                message: POST_ERRORS.SEARCH_GENERAL,
              }),
            );
          }
        });
    }
  }, []);

  if (posts === null && searchInput !== '') {
    return (
      <MainSkeletonsContainer style={{ flex: 1 }}>
        <PublicationSkeleton />
        <PublicationSkeleton />
        <PublicationSkeleton />
        <PublicationSkeleton />
        <PublicationSkeleton />
        <PublicationSkeleton />
        <PublicationSkeleton />
      </MainSkeletonsContainer>
    );
  }

  return (
    <Container
      ref={customComponentRef}
      layout
      initial={{ opacity: 0.4, x: -10 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.2 }}
      exit={{ opacity: 0.8, x: -10 }}
      fullWidth={location.pathname === '/new-post' || location.pathname.startsWith('/post/')}
    >
      {searchInput && (
        <Title>
          {total === 0 ? 'Sin' : `${total}`} resultados para <Span>{`"${searchInput}"`}</Span>
        </Title>
      )}
      {searchInput === '' ? (
        <MessageContainer>
          <IconContainer>
            <RiSearchLine color='#006F9B' size={30} />
          </IconContainer>
          <Message>Introduce una palabra para realizar la búsqueda de artículos</Message>
        </MessageContainer>
      ) : posts !== null && posts?.length > 0 ? (
        <>
          <List>
            {posts?.map((post: any, index: number) => (
              <PublicationItem key={`${post.id}_${index}`} {...post} type='search' />
            ))}
          </List>
          {canLoadMoreData && (
            <SkeletonsContainer>
              <PublicationSkeleton />
              <PublicationSkeleton />
              <PublicationSkeleton />
              <PublicationSkeleton />
              <PublicationSkeleton />
            </SkeletonsContainer>
          )}
        </>
      ) : (
        <MessageContainer>
          <IconContainer>
            <NotFoundIcon />
          </IconContainer>
          <Message>
            ¡Lo sentimos! No encontramos ningún artículo relacionado a tu búsqueda, prueba con otra
            palabra.
          </Message>
        </MessageContainer>
      )}
    </Container>
  );
}

const Container = styled(motion.div)<ContainerI>`
  margin-top: 72px;
  margin-left: ${(props) => (props.fullWidth ? '0px' : '192px')};
  background-color: white;
  width: ${(props) => (props.fullWidth ? '100vw' : 'calc(100vw - 192px)')};
  height: calc(100vh - 72px);
  overflow-y: scroll;
  padding-bottom: 90px;
`;

const MainSkeletonsContainer = styled(motion.div)`
  margin-top: 90px;
  margin-left: 192px;
  background-color: white;
  width: calc(100vw - 192px);
  padding-left: 29px;
  padding-right: 20px;
  padding-bottom: 40px;
`;

const Title = styled.p`
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 24px;
  font-family: ${(props) => props.theme.fonts.bold};
  font-size: 22px;
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: calc(100% - 60px);
`;

const IconContainer = styled.div`
  margin-bottom: 13px;
`;

const Message = styled.p`
  max-width: 350px;
  text-align: center;
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.black};
  font-size: 17px;
`;

const List = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 20px;
  padding-bottom: 10px;
`;

const Span = styled.span`
  color: ${(props) => props.theme.colors.primary};
`;

const SkeletonsContainer = styled.div`
  padding-left: 29px;
  padding-right: 20px;
  padding-bottom: 10px;
`;
