const ARTICLE_TITLE_MAX_LENGTH = 100;
const ARTICLE_SUBTITLE_MAX_LENGTH = 200;
const ARTICLE_KEY_IDEA_MAX_LENGTH = 130;
const SEPARATOR = '|new-wysiwyg-separator|';
const MAX_AUTHORS_QUANTITY = 4;
const MAX_CATEGORIES_QUANTITY = 3;
const MAX_TAGS_QUANTITY = 10;
const MAX_IMAGES_QUANTITY = 20;
const HTML_TEXT = 'HTML_TEXT';
const FILE_TYPE = 'FILE';

export {
  ARTICLE_TITLE_MAX_LENGTH,
  ARTICLE_SUBTITLE_MAX_LENGTH,
  ARTICLE_KEY_IDEA_MAX_LENGTH,
  SEPARATOR,
  MAX_AUTHORS_QUANTITY,
  MAX_TAGS_QUANTITY,
  MAX_CATEGORIES_QUANTITY,
  MAX_IMAGES_QUANTITY,
  HTML_TEXT,
  FILE_TYPE,
};
