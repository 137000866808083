import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError } from 'axios';
import type PublicationInterface from '../../interfaces/Publication';
import PublicationItem from './PublicationItem';
import PublicationSkeleton from './PublicationSkeleton';
import ListingIcon from '../../icons/ListingIcon';
import { openSnackbar } from '../../context/reducers/generalSnackbar';
import { PAGE_SIZE } from '../../constants/pagination';
import { HOME_ERRORS } from '../../constants/errorMessages';
import { getPosts } from '../../api/posts';
import POSTS_STATUS from '../../constants/status';
import { setSelectedState } from '../../context/reducers/entries';
import { getPublications } from '../../api/publications';
import { type RootState } from '../../context/store';
import {
  setPublications,
  setPublicationsCanLoadMoreData,
  setPublicationsCurrentPage,
  setPublicationsIsLoadingMoreData,
  setPublicationsStats,
  setPublicationsTotal,
} from '../../context/reducers/publications';
import { getPublicationsStats } from '../../api/stats';
import type PublicationStats from '../../interfaces/PublicationStats';

export default function PublicationsList(): JSX.Element {
  const navigate = useNavigate();
  const publications = useSelector((state: RootState) => state.publications.publications);
  const publicationsStats = useSelector((state: RootState) => state.publications.publicationsStats);
  const currentPage = useSelector((state: RootState) => state.publications.currentPage);
  const publicationsTotal = useSelector((state: RootState) => state.publications.publicationsTotal);
  const isLoadingMoreData = useSelector((state: RootState) => state.publications.isLoadingMoreData);
  const canLoadMoreData = useSelector((state: RootState) => state.publications.canLoadMoreData);
  const dispatch = useDispatch();

  const loadMoreData = async () => {
    if (canLoadMoreData && !isLoadingMoreData && publications !== null) {
      dispatch(
        setPublicationsIsLoadingMoreData({
          isLoadingMoreData: true,
        }),
      );
      try {
        const response = await getPublications('', PAGE_SIZE, currentPage);
        const { last, content } = response;
        if (!last) {
          dispatch(
            setPublicationsCurrentPage({
              currentPage: currentPage + 1,
            }),
          );
          dispatch(
            setPublicationsCanLoadMoreData({
              canLoadMoreData: true,
            }),
          );
        } else {
          dispatch(
            setPublicationsCanLoadMoreData({
              canLoadMoreData: false,
            }),
          );
        }
        dispatch(
          setPublicationsIsLoadingMoreData({
            isLoadingMoreData: false,
          }),
        );
        if (publications != null) {
          dispatch(
            setPublications({
              publications: [...publications, ...content],
            }),
          );
        }
      } catch (error: unknown) {
        let errorStatus;
        if (error instanceof AxiosError) {
          errorStatus = error?.response?.status;
        }
        if (errorStatus !== 401) {
          dispatch(
            openSnackbar({
              type: 'error',
              message: HOME_ERRORS.LAST_PUBLICATIONS_LIST,
            }),
          );
        }
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
      const margin = 400;
      if (scrollTop + clientHeight + margin >= scrollHeight) {
        loadMoreData();
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [currentPage, canLoadMoreData, isLoadingMoreData]);

  const callApiToGetPublications = (): void => {
    const newCurrentPage = currentPage > 0 ? (canLoadMoreData ? currentPage : currentPage + 1) : 0;
    getPublications('', PAGE_SIZE, newCurrentPage)
      .then((res) => {
        if (publications === null || newCurrentPage === 0) {
          dispatch(
            setPublications({
              publications: res.content,
            }),
          );
        } else {
          dispatch(
            setPublications({
              publications: [...publications, ...res.content],
            }),
          );
        }
        dispatch(
          setPublicationsTotal({
            publicationsTotal: res.totalElements,
          }),
        );
        if (!res.last) {
          dispatch(
            setPublicationsCurrentPage({
              currentPage: newCurrentPage + 1,
            }),
          );
          dispatch(
            setPublicationsCanLoadMoreData({
              canLoadMoreData: true,
            }),
          );
        } else {
          dispatch(
            setPublicationsCanLoadMoreData({
              canLoadMoreData: false,
            }),
          );
        }
      })
      .catch((error: unknown) => {
        let errorStatus;
        if (error instanceof AxiosError) {
          errorStatus = error?.response?.status;
        }
        if (errorStatus !== 401) {
          dispatch(
            openSnackbar({
              type: 'error',
              message: HOME_ERRORS.LAST_PUBLICATIONS_LIST,
            }),
          );
        }
      });
  };

  useEffect(() => {
    callApiToGetPublications();
  }, []);

  const getPublicationsIDs = (): number[] => {
    if (publications === null) return [];
    return publications.map((publication) => publication.id);
  };

  const callApiToGetPublicationsStats = (): void => {
    getPublicationsStats(getPublicationsIDs())
      .then((res) => {
        dispatch(
          setPublicationsStats({
            publicationsStats: res,
          }),
        );
      })
      .catch((error: unknown) => {
        let errorStatus;
        if (error instanceof AxiosError) {
          errorStatus = error?.response?.status;
        }
        if (errorStatus !== 401) {
          dispatch(
            openSnackbar({
              type: 'error',
              message: HOME_ERRORS.PUBLICATION_STATS,
            }),
          );
        }
      });
  };

  useEffect(() => {
    if (publications !== null && publications?.length > 0) {
      callApiToGetPublicationsStats();
    }
  }, [publications]);

  const handleClickAdminPublications = (): void => {
    navigate(`/entries?type=${POSTS_STATUS.PUBLISHED.toLowerCase()}`);
  };

  const getStatsOfEachPublication = (publicationId: number): PublicationStats | null => {
    if (publicationsStats === null) return null;
    const index = publicationsStats.findIndex((item) => item.publicationId === publicationId);
    if (index === -1) return null;
    return { ...publicationsStats[index] };
  };

  return (
    <Container>
      <Header>
        <Title>Últimas publicaciones</Title>
        <StyledButton onClick={handleClickAdminPublications}>
          <ButtonText>Administrar publicaciones</ButtonText>
        </StyledButton>
      </Header>
      {publications === null ? (
        <List>
          <PublicationSkeleton />
          <PublicationSkeleton />
          <PublicationSkeleton />
          <PublicationSkeleton />
          <PublicationSkeleton />
        </List>
      ) : publications?.length === 0 ? (
        <MessageContainer>
          <div style={{ marginBottom: '20px' }}>
            <ListingIcon color='#033BCF' />
          </div>
          <Message>Sin publicaciones recientes.</Message>
          <Message>Las publicaciones que estén publicadas se verán aquí</Message>
        </MessageContainer>
      ) : (
        <List>
          {publications.map((publication, index) => (
            <PublicationItem
              key={index}
              {...publication}
              stats={getStatsOfEachPublication(publication.id)}
            />
          ))}
        </List>
      )}
    </Container>
  );
}

const Container = styled.div``;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;

const Title = styled.p`
  font-family: ${(props) => props.theme.fonts.medium};
  font-size: 22px;
  margin-bottom: 20px;
`;

const StyledButton = styled(Button)`
  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const ButtonText = styled.p`
  text-transform: none;
  font-family: ${(props) => props.theme.fonts.medium};
  color: ${(props) => props.theme.colors.accentBlue};
  font-size: 16px;
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  flex: 1;
  padding-top: 130px;
  height: calc(100vh - 420px);
`;

const Message = styled.p`
  font-family: ${(props) => props.theme.fonts.medium};
  color: ${(props) => props.theme.colors.black};
  font-size: 16px;
  text-align: center;
`;

const List = styled.ul``;
