import React, { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import classNames from 'classnames';
import { styled } from '@mui/material';
import { formatLessThan10 } from '../../utils/formatLessThan10';
import { useDispatch, useSelector } from 'react-redux';
import {
  setDraftsQuantity,
  setPendingReviewQuantity,
  setPublishedQuantity,
} from '../../context/reducers/entries';
import {
  setDraftsQuantity as setPresentationsDraftsQuantity,
  setPendingReviewQuantity as setPresentationsPendingReviewQuantity,
  setPublishedQuantity as setPresentationsPublishedQuantity,
} from '../../context/reducers/presentations';
import { type RootState } from '../../context/store';
import { useNavigate, useSearchParams } from 'react-router-dom';
import POSTS_STATUS from '../../constants/status';
import { getPosts } from '../../api/posts';
import { PAGE_SIZE } from '../../constants/pagination';
import { FILE_TYPE, HTML_TEXT } from '../../constants/globals';

interface Props {
  contentType?: string;
}

export default function TopTabs({ contentType = HTML_TEXT }: Props): JSX.Element {
  const draftsQuantity =
    contentType === HTML_TEXT
      ? useSelector((state: RootState) => state.entries.draftsQuantity)
      : useSelector((state: RootState) => state.presentations.draftsQuantity);
  const pendingReviewQuantity =
    contentType === HTML_TEXT
      ? useSelector((state: RootState) => state.entries.pendingReviewQuantity)
      : useSelector((state: RootState) => state.presentations.pendingReviewQuantity);
  const publishedQuantity =
    contentType === HTML_TEXT
      ? useSelector((state: RootState) => state.entries.publishedQuantity)
      : useSelector((state: RootState) => state.presentations.publishedQuantity);
  const [selectedStatus, setSelectedStatus] = useState(POSTS_STATUS.DRAFT);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const queryParamType = searchParams.get('type')?.toUpperCase();
    if (
      queryParamType === POSTS_STATUS.DRAFT ||
      queryParamType === POSTS_STATUS.PENDING_REVIEW ||
      queryParamType === POSTS_STATUS.PUBLISHED
    ) {
      setSelectedStatus(queryParamType ?? POSTS_STATUS.DRAFT);
    } else {
      navigate('/404');
    }
  }, [searchParams]);

  const callApiToGetPosts = (type: string[]) => {
    getPosts('', type, 1, 0, 'lastModifiedDate,DESC', [], [], undefined, contentType, false)
      .then((res) => {
        if (type.includes(POSTS_STATUS.DRAFT)) {
          if (contentType === HTML_TEXT) {
            dispatch(
              setDraftsQuantity({
                draftsQuantity: res.totalElements,
              }),
            );
          } else if (contentType === FILE_TYPE) {
            dispatch(
              setPresentationsDraftsQuantity({
                draftsQuantity: res.totalElements,
              }),
            );
          }
        } else if (
          type.includes(POSTS_STATUS.PENDING_REVIEW) ||
          type.includes(POSTS_STATUS.REVIEW_IN_PROGRESS)
        ) {
          if (contentType === HTML_TEXT) {
            dispatch(
              setPendingReviewQuantity({
                pendingReviewQuantity: res.totalElements,
              }),
            );
          } else {
            dispatch(
              setPresentationsPendingReviewQuantity({
                pendingReviewQuantity: res.totalElements,
              }),
            );
          }
        } else if (type.includes(POSTS_STATUS.PUBLISHED)) {
          if (contentType === HTML_TEXT) {
            dispatch(
              setPublishedQuantity({
                publishedQuantity: res.totalElements,
              }),
            );
          } else {
            dispatch(
              setPresentationsPublishedQuantity({
                publishedQuantity: res.totalElements,
              }),
            );
          }
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (draftsQuantity === undefined) {
      callApiToGetPosts([POSTS_STATUS.DRAFT]);
    }
  }, []);

  useEffect(() => {
    if (pendingReviewQuantity === undefined) {
      callApiToGetPosts([POSTS_STATUS.PENDING_REVIEW, POSTS_STATUS.REVIEW_IN_PROGRESS]);
    }
  }, []);

  useEffect(() => {
    if (publishedQuantity === undefined) {
      callApiToGetPosts([POSTS_STATUS.PUBLISHED]);
    }
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: string): void => {
    navigate({
      pathname: contentType === HTML_TEXT ? '/entries' : '/presentations',
      search: `?type=${newValue.toLowerCase()}`,
    });
    setSelectedStatus(newValue);
  };

  return (
    <Tabs value={selectedStatus} onChange={handleChange} className={classNames('mt-9')}>
      <Tab
        label={`Borradores ${
          draftsQuantity === undefined ? '' : `(${formatLessThan10(draftsQuantity)})`
        }`}
        value={'DRAFT'}
        className={classNames('font-semiBold normal-case text-base px-10', {
          'text-primary': selectedStatus === 'DRAFT',
        })}
      />
      <Tab
        label={`Por revisar ${
          pendingReviewQuantity === undefined ? '' : `(${formatLessThan10(pendingReviewQuantity)})`
        }`}
        value={'PENDING_REVIEW'}
        className={classNames('font-semiBold normal-case text-base px-10', {
          'text-primary': selectedStatus === 'PENDING_REVIEW',
        })}
      />
      <Tab
        label={`Publicados ${
          publishedQuantity === undefined ? '' : `(${formatLessThan10(publishedQuantity)})`
        }`}
        value={'PUBLISHED'}
        className={classNames('font-semiBold normal-case text-base px-10', {
          'text-primary': selectedStatus === 'PUBLISHED',
        })}
      />
    </Tabs>
  );
}
