import React from 'react';
import styled from 'styled-components';
import PercentBar from './PercentBar';

export default function OptionsPercents(): JSX.Element {
  return (
    <Container>
      <Label>ENCUESTA</Label>
      <Title>¿Habrá vacancia de Dina Boluarte en el 2023?</Title>
      <PercentBar />
      <PercentBar />
      <PercentBar />
    </Container>
  );
}

const Container = styled.div`
  border: 1px solid ${(props) => props.theme.colors.lightGray};
  border-radius: 10px;
  padding: 0px 32px;
  padding-bottom: 22px;
`;

const Label = styled.p`
  font-family: ${(props) => props.theme.fonts.bold};
  color: ${(props) => props.theme.colors.gray2};
  font-size: 15px;
  margin-top: 32px;
`;

const Title = styled.p`
  font-family: ${(props) => props.theme.fonts.bold};
  color: ${(props) => props.theme.colors.secondaries.purple};
  font-size: 22px;
  margin-top: 12px;
  margin-bottom: 22px;
`;
