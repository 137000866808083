import React from 'react';
import type Icon from '../interfaces/Icon';

export default function JustifyIcon({ width = 16, height = 11, color = '#3C3C3B' }: Icon) {
  return (
    <svg width='16' height='11' viewBox='0 0 16 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.571289 10.2857H15.9999V8.57143H0.571289V10.2857ZM0.571289 4.28571V6H15.9999V4.28571H0.571289ZM0.571289 0V1.71429H15.9999V0H0.571289Z'
        fill={color}
      />
    </svg>
  );
}
