import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Button, IconButton } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { useSelector, useDispatch } from 'react-redux';
import { type RootState } from '../../context/store';
import { type EditorElement } from '../../interfaces/Editor';
import TagsList from '../previewMode/TagsList';
import AuthorsAndEditor from '../previewMode/AuthorsAndEditor';
import AudioPlayer from '../previewMode/AudioPlayer';
import HeaderImagePreviewMode from '../previewMode/HeaderImagePreviewMode';
import type TagDetails from '../../interfaces/TagDetails';
import POSTS_STATUS from '../../constants/status';
import { tooglePreviewMode } from '../../context/reducers/toolBar';
import EditNotesIcon from '../../icons/EditNotesIcon';

interface Props {
  postInformation: any;
  selectedTags: TagDetails[];
  selectedAuthors: any[];
  selectedCategories: string[];
  currentStatus: null | string;
}

interface ContainerI {
  isPreviewModeActive: boolean;
}

export default function PreviewMode({
  postInformation,
  selectedTags,
  selectedAuthors,
  selectedCategories,
  currentStatus,
}: Props): JSX.Element {
  const currentPostStatus = useSelector((state: RootState) => state.editor.currentPostStatus);
  const isPreviewModeActive = useSelector((state: RootState) => state.toolBar.isPreviewModeActive);
  const previewElements = useSelector((state: RootState) => state.editor.previewElements);
  const elementsContent = useSelector((state: RootState) => state.editor.elementsContent);
  const dispatch = useDispatch();

  const handleClickPreviewMode = () => {
    dispatch(
      tooglePreviewMode({
        isPreviewModeActive: !isPreviewModeActive,
      }),
    );
  };

  return (
    <Container isPreviewModeActive={isPreviewModeActive}>
      <Mockup>
        <BroveContainer>
          <Brove>
            <Speaker></Speaker>
          </Brove>
        </BroveContainer>
        <Screen>
          <Header>
            <IconButton disabled style={{ backgroundColor: 'white' }}>
              <ArrowBack style={{ color: '#1C1B1F' }} />
            </IconButton>
          </Header>
          {elementsContent[0].content !== null && (
            <>
              <HeaderImagePreviewMode
                selectedCategories={selectedCategories}
                postInformation={postInformation}
              />
              <List>
                {previewElements.map((previewElement: EditorElement, index) => (
                  <div key={`${previewElement.key}_${index}`}>{previewElement.element}</div>
                ))}
                <TagsList selectedTags={selectedTags} />
                <AuthorsAndEditor
                  selectedAuthors={selectedAuthors}
                  postInformation={postInformation}
                  currentStatus={currentStatus}
                />
              </List>
            </>
          )}
        </Screen>
        <AudioPlayer />
      </Mockup>
      {currentPostStatus === POSTS_STATUS.PENDING_REVIEW ||
        (currentPostStatus === POSTS_STATUS.REVIEW_IN_PROGRESS && (
          <CustomButton
            className='px-3'
            startIcon={<EditNotesIcon width={21} height={24} color='#033BCF' />}
            onClick={handleClickPreviewMode}
          >
            <ButtonText>Volver a vista de edición</ButtonText>
          </CustomButton>
        ))}
    </Container>
  );
}

const Container = styled.section<ContainerI>`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.lightSkyBlue};
  height: calc(100vh - 72px - 90px - 20px - 18px);
  min-height: 937px;
  margin: 18px 24px;
  border-radius: 10px;
  padding-top: 18px;
  padding-bottom: 18px;
  ${(props) =>
    !props.isPreviewModeActive &&
    css`
      height: 0px;
      width: 0px;
      min-height: 0px;
      margin: 0px;
      padding: 0px;
      overflow: hidden;
    `}

  @media screen and (max-width: 860px) {
    background-color: transparent;
  }
`;

const Mockup = styled.div`
  position: relative;
  width: 500px;
  box-shadow: 0 0 20px #e2e2e2;
  border-radius: 30px;
  padding: 10px 10px 30px;
  background-color: #001117;

  @media screen and (max-width: 860px) {
    width: 100%;
  }
`;

const Screen = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 30px;
  overflow-y: auto;
`;

const Header = styled.div`
  width: 100%;
  position: absolute;
  top: 0px;
  z-index: 100;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px 24px;
  justify-content: space-between;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Image = styled.img`
  position: absolute;
  height: 100%;
  width: 100%;
`;

const List = styled.div`
  z-index: 100;
  background-color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  position: absolute;
  width: 100%;
  padding-top: 20px;
  /*   padding-bottom: 120px; */
`;

const BroveContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Brove = styled.div`
  width: 50%;
  height: 30px;
  background-color: ${(props) => props.theme.colors.black};
  position: absolute;
  z-index: 899;
  top: 0px;
  margin: 0 100px;
  border-radius: 0 0 20px 20px;
`;

const Speaker = styled.span`
  width: 30%;
  height: 5px;
  background: #d2d2d2;
  display: block;
  margin: auto;
  margin-top: 10px;
  border-radius: 20px;
`;

const CustomButton = styled(Button)`
  position: absolute;
  right: 5px;

  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const ButtonText = styled.p`
  text-transform: none;
  font-size: 15px;
  font-family: ${(props) => props.theme.fonts.semiBold};
  color: ${(props) => props.theme.colors.accentBlue};
`;
