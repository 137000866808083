import React from 'react';
import styled from 'styled-components';

interface Props {
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  color?: string;
}

export default function CustomDivider({
  marginTop = 20,
  marginBottom = 20,
  marginLeft = 0,
  marginRight = 0,
  color = '#9A9A9A',
}: Props): JSX.Element {
  return (
    <p style={{ userSelect: 'none' }}>
      <Divider
        color={color}
        marginTop={marginTop}
        marginBottom={marginBottom}
        marginLeft={marginLeft}
        marginRight={marginRight}
      />
    </p>
  );
}

const Divider = styled.div<Props>`
  height: 1px;
  background-color: ${(props) => props.color};
  width: 100%;
  margin-top: ${(props) => (props.marginTop !== undefined ? `${props.marginTop}px` : '0px')};
  margin-bottom: ${(props) =>
    props.marginBottom !== undefined ? `${props.marginBottom}px` : '0px'};
  margin-left: ${(props) => (props.marginLeft !== undefined ? `${props.marginLeft}px` : '0px')};
  margin-right: ${(props) => (props.marginRight !== undefined ? `${props.marginRight}px` : '0px')};
`;
