import type React from 'react';
import { deletePost } from '../api/posts';
import { openSnackbar } from '../context/reducers/generalSnackbar';
import { POST_ERRORS } from '../constants/errorMessages';
import {
  setDraftPostDeleted,
  setDraftsQuantity,
  setPendingReviewPostDeleted,
  setPendingReviewQuantity,
  setPublishedPostDeleted,
  setPublishedQuantity,
} from '../context/reducers/entries';
import {
  setDraftsQuantity as setPresentationsDraftsQuantity,
  setPendingReviewQuantity as setPresentationsPendingReviewQuantity,
  setPublishedQuantity as setPresentationsPublishedQuantity,
} from '../context/reducers/presentations';
import POSTS_STATUS from '../constants/status';
import { AxiosError } from 'axios';
import { type NavigateFunction } from 'react-router-dom';
import { setEntriesDrafts } from '../context/reducers/entriesDrafts';
import { setEntriesPendingReviews } from '../context/reducers/entriesPendingReviews';
import { setEntriesPublished } from '../context/reducers/entriesPublished';
import { FILE_TYPE, HTML_TEXT } from '../constants/globals';
import { setPresentationsDrafts } from '../context/reducers/presentationsDrafts';
import { setPresentationsPendingReviews } from '../context/reducers/presentationsPendingReview';
import { setPresentationsPublished } from '../context/reducers/presentationsPublished';
import { ARTICLE_SUCCESFUL_MESSAGES } from '../constants/successfulMessages';

export const checkIfSomeFilterIsSelected = (
  resource: string | null,
  selectedAuthors: any[],
  categoriesIDs: string[],
  searchInput: string,
): boolean => {
  if (
    resource !== null ||
    selectedAuthors?.length > 0 ||
    categoriesIDs?.length > 0 ||
    searchInput !== ''
  ) {
    return true;
  }
  return false;
};

export const deleteEntry = async (
  setConfirmDeleteModalIsVisible: React.Dispatch<React.SetStateAction<boolean>>,
  deleting: boolean,
  setDeleting: React.Dispatch<React.SetStateAction<boolean>>,
  id: number,
  dispatch: any,
  status: string,
  draftPostDeleted: boolean,
  pendingReviewPostDeleted: boolean,
  publishedPostDeleted: boolean,
  page: string = 'entries',
  setConfirmForceDeleteModallIsVisible: React.Dispatch<React.SetStateAction<boolean>>,
  navigate?: NavigateFunction, // any,
  posts?: any[] | null,
  setPosts?: React.Dispatch<React.SetStateAction<any[] | null>>,
  quantity?: number,
  setQuantity?: React.Dispatch<React.SetStateAction<number>>,
  forceDelete = false,
  setErrorDeleteMessage?: React.Dispatch<React.SetStateAction<string>>,
  contentType = HTML_TEXT,
) => {
  setConfirmDeleteModalIsVisible(false);
  setConfirmForceDeleteModallIsVisible(false);
  if (!deleting) {
    try {
      setDeleting(true);
      await deletePost(id, forceDelete);
      if (posts !== null && posts !== undefined) {
        const newPosts = posts.filter((item) => item.id !== id);
        if (status === POSTS_STATUS.DRAFT) {
          if (contentType === HTML_TEXT) {
            dispatch(
              setEntriesDrafts({
                drafts: newPosts,
              }),
            );
          } else if (contentType === FILE_TYPE) {
            dispatch(
              setPresentationsDrafts({
                drafts: newPosts,
              }),
            );
          }
        } else if (status === POSTS_STATUS.PENDING_REVIEW) {
          if (contentType === HTML_TEXT) {
            dispatch(
              setEntriesPendingReviews({
                pendingReviews: newPosts,
              }),
            );
          } else if (contentType === FILE_TYPE) {
            dispatch(
              setPresentationsPendingReviews({
                pendingReviews: newPosts,
              }),
            );
          }
        } else if (status === POSTS_STATUS.PUBLISHED) {
          if (contentType === HTML_TEXT) {
            dispatch(
              setEntriesPublished({
                publications: newPosts,
              }),
            );
          } else if (contentType === FILE_TYPE) {
            dispatch(
              setPresentationsPublished({
                publications: newPosts,
              }),
            );
          }
        }
      }
      if (quantity !== null && quantity !== undefined) {
        if (status === POSTS_STATUS.DRAFT) {
          if (contentType === HTML_TEXT) {
            dispatch(
              setDraftsQuantity({
                draftsQuantity: quantity - 1,
              }),
            );
          } else if (contentType === FILE_TYPE) {
            dispatch(
              setPresentationsDraftsQuantity({
                draftsQuantity: quantity - 1,
              }),
            );
          }
        } else if (status === POSTS_STATUS.PENDING_REVIEW) {
          if (contentType === HTML_TEXT) {
            dispatch(
              setPendingReviewQuantity({
                pendingReviewQuantity: quantity - 1,
              }),
            );
          } else if (contentType === FILE_TYPE) {
            dispatch(
              setPresentationsPendingReviewQuantity({
                pendingReviewQuantity: quantity - 1,
              }),
            );
          }
        } else if (status === POSTS_STATUS.PUBLISHED) {
          if (contentType === HTML_TEXT) {
            dispatch(
              setPublishedQuantity({
                publishedQuantity: quantity - 1,
              }),
            );
          } else if (contentType === FILE_TYPE) {
            dispatch(
              setPresentationsPublishedQuantity({
                publishedQuantity: quantity - 1,
              }),
            );
          }
        }
        /* if (quantity !== null && quantity !== undefined && setQuantity !== undefined) {
          setQuantity(quantity - 1);
        } */
      }
      dispatch(
        openSnackbar({
          type: 'success',
          message:
            contentType === HTML_TEXT
              ? ARTICLE_SUCCESFUL_MESSAGES.DELETE_ARTICLE
              : ARTICLE_SUCCESFUL_MESSAGES.DELETE_PRESENTATION,
        }),
      );
      if (status === POSTS_STATUS.DRAFT) {
        dispatch(
          setDraftPostDeleted({
            draftPostDeleted: !draftPostDeleted,
          }),
        );
      } else if (status === POSTS_STATUS.PENDING_REVIEW) {
        dispatch(
          setPendingReviewPostDeleted({
            pendingReviewPostDeleted: !pendingReviewPostDeleted,
          }),
        );
      } else if (status === POSTS_STATUS.PUBLISHED) {
        dispatch(
          setPublishedPostDeleted({
            publishedPostDeleted: !publishedPostDeleted,
          }),
        );
      }
      if (page === 'editor' && navigate !== undefined) {
        navigate(
          `${
            contentType === HTML_TEXT ? '/entries' : '/presentations'
          }?type=${POSTS_STATUS.DRAFT.toLowerCase()}`,
        );
      }
    } catch (error: unknown) {
      setDeleting(false);
      let errorMessage = POST_ERRORS.DELETE_POST;
      let errorStatus;
      if (error instanceof AxiosError) {
        const errorData = error?.response?.data;
        errorStatus = error?.response?.status;
        if (errorData?.message) {
          errorMessage = errorData?.message;
        }
      }
      if (errorStatus === 428) {
        if (setErrorDeleteMessage !== undefined) {
          setErrorDeleteMessage(errorMessage);
        }
        setConfirmForceDeleteModallIsVisible(true);
      } else if (errorStatus !== 401) {
        dispatch(
          openSnackbar({
            type: 'error',
            message: errorMessage,
          }),
        );
      }
    }
  }
};
