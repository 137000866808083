/* eslint-disable react/prop-types */
/* eslint-disable array-callback-return */
import React, { useEffect, useRef, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { useDispatch, useSelector } from 'react-redux';
import { ContentState, EditorState, type ContentBlock } from 'draft-js';
import styled, { css } from 'styled-components';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './index.css';
import { type RootState } from '../../context/store';
import {
  KEY_IDEA_ITEM,
  KEY_PARAGRAPH,
  WYSIWYG_MAIN_SUBTITLE,
  WYSIWYG_MAIN_TITLE,
} from '../../constants/editorKeys';
import CustomDivider from '../globals/CustomDivider';
import ResizableImage from './ResizableImage';

interface Props {
  isVisible?: boolean;
  customKey: string;
  placeholder: string;
  fontSize?: string;
  color?: string;
  fontFamily?: string;
  fontWeight?: number;
  fontStyle?: string;
  editorState?: EditorState;
  setEditorState?: React.Dispatch<React.SetStateAction<EditorState>>;
  isFocus?: boolean;
  setIsFocus?: React.Dispatch<React.SetStateAction<boolean>>;
  handleFocusWysiwyg?: () => void;
  handleBlurWysiwyg?: () => void;
  maximumOfCharacters?: number | null;
  keyNumber?: number | null;
  marginTop?: number;
}

interface EditorWrapperI {
  customKey: string;
}

export default function PreviewCustomDraftWysiwyg({
  isVisible = true,
  customKey = '',
  placeholder,
  fontSize = '16px',
  color = 'black',
  fontFamily = 'Arial',
  fontWeight = 400,
  fontStyle = 'normal',
  marginTop = 0,
  editorState,
  setEditorState,
  handleFocusWysiwyg,
  handleBlurWysiwyg,
  maximumOfCharacters = null,
  keyNumber = null,
}: Props) {
  const isPreviewModeActive = useSelector((state: RootState) => state.toolBar.isPreviewModeActive);
  const elementsContent = useSelector((state: RootState) => state.editor.elementsContent);
  const [auxEditorState, setAuxEditorState] = useState(
    editorState !== undefined ? editorState : EditorState.createEmpty(),
  );
  const [isFocus, setIsFocus] = useState(false);
  const [isMouseOverEditor, setIsMouseOverEditor] = useState(false);
  const editorRef = useRef<Editor>(null);
  const editorWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      (customKey === WYSIWYG_MAIN_TITLE && elementsContent[0].content) ||
      (customKey === WYSIWYG_MAIN_SUBTITLE && elementsContent[1].content) ||
      customKey === KEY_IDEA_ITEM ||
      (customKey === KEY_PARAGRAPH && elementsContent[3].content)
    ) {
      if (typeof elementsContent[0].content === 'string' && customKey === WYSIWYG_MAIN_TITLE) {
        setAuxEditorState(
          EditorState.createWithContent(ContentState.createFromText(elementsContent[0].content)),
        );
      }
      if (typeof elementsContent[1].content === 'string' && customKey === WYSIWYG_MAIN_SUBTITLE) {
        setAuxEditorState(
          EditorState.createWithContent(ContentState.createFromText(elementsContent[1].content)),
        );
      }
      if (
        customKey === KEY_PARAGRAPH &&
        elementsContent[3].content !== undefined &&
        elementsContent[3].content instanceof EditorState
      ) {
        setAuxEditorState(
          EditorState.createWithContent(elementsContent[3].content.getCurrentContent()),
        );
      }
      if (customKey === KEY_IDEA_ITEM && keyNumber !== null) {
        const content = elementsContent[2]?.content as EditorState[];
        if (content !== null) {
          setAuxEditorState(EditorState.createWithContent(content[keyNumber].getCurrentContent()));
        }
      }
    }
  }, [elementsContent]);

  const onEditorStateChange = (newEditorState: EditorState): void => {
    if (setEditorState !== undefined) {
      setEditorState(newEditorState);
    }
    setAuxEditorState(newEditorState);
  };

  const renderResizableImage = (props: any) => {
    return <ResizableImage customKey='image' index={props?.block?.getKey()} {...props} />;
  };

  const customBlockRenderer = (contentBlock: ContentBlock) => {
    const blockType = contentBlock.getType();
    if (blockType === 'custom-divider') {
      return {
        component: CustomDivider,
        editable: true,
      };
    } else if (blockType === 'custom-image') {
      return {
        component: renderResizableImage,
        editable: false,
        props: {
          key: contentBlock.getKey(),
        },
      };
    }
  };

  if (!isVisible) return <></>;

  return (
    <EditorWrapper
      customKey={customKey}
      ref={editorWrapperRef}
      style={{
        borderColor:
          isPreviewModeActive || customKey === KEY_PARAGRAPH
            ? 'transparent'
            : isFocus
            ? '#0094CF'
            : isMouseOverEditor
            ? '#b7b6b6'
            : 'transparent',
        paddingBottom: customKey === KEY_PARAGRAPH ? '8px' : '0px',
      }}
    >
      <style>
        {`
          :root {
            --blockquote-color: ${isPreviewModeActive ? '#3357EC' : '#0094cf'};
          }
          .rdw-editor-main blockquote {
            border-left: 5px solid var(--blockquote-color);
          }
        `}
      </style>
      <Editor
        ref={editorRef}
        readOnly={true}
        editorState={auxEditorState}
        onEditorStateChange={onEditorStateChange}
        customBlockRenderFunc={customBlockRenderer}
        toolbarHidden
        editorStyle={{
          fontFamily,
          fontSize,
          fontStyle,
          color,
          fontWeight,
          padding: '0px',
          paddingTop: '0px',
          boxSizing: 'border-box',
        }}
        placeholder={placeholder}
        wrapperStyle={{
          boxSizing: 'border-box',
          paddingTop: '0px',
          paddingBottom: '0px',
        }}
      />
    </EditorWrapper>
  );
}

const EditorWrapper = styled.div<EditorWrapperI>`
  border: 1px solid transparent;
  transition: border 0.1s ease-in;
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
  z-index: 2;
  :hover {
    border-color: ${(props) => props.theme.colors.gray2};
  }
  ${(props) =>
    props.customKey === WYSIWYG_MAIN_SUBTITLE &&
    css`
      background-color: white;
    `}
`;
