/* eslint-disable @typescript-eslint/prefer-optional-chain */
import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  createBrowserRouter,
  RouterProvider,
  Routes,
  Route,
  useNavigate,
} from 'react-router-dom';
import { pdfjs } from 'react-pdf';
import Login from './pages/Login';
import Layout from './pages/Layout';
import Home from './pages/Home';
import Entries from './pages/Entries';
import Tags from './pages/Tags';
import Authors from './pages/Authors';
import Surveys from './pages/Surveys';
import AuthorDetails from './pages/AuthorDetails';
import SurveyDetails from './pages/SurveyDetails';
import Editor from './pages/Editor';
import GeneralSearch from './components/globals/GeneralSearch';
import NotFound404 from './pages/NotFound404';
import Presentations from './pages/Presentations';
import Campaigns from './pages/Campaigns';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const AuthorsRouter = (): JSX.Element => {
  return (
    <Routes>
      <Route path='/' element={<Authors />} />
      <Route path='/new-author' element={<AuthorDetails />} />
      <Route path=':authorId' element={<AuthorDetails />} />
    </Routes>
  );
};

const SurveysRouter = (): JSX.Element => {
  return (
    <Routes>
      <Route path='/' element={<Surveys />} />
      <Route path='/new-survey' element={<SurveyDetails />} />
      <Route path=':surveyId' element={<SurveyDetails />} />
    </Routes>
  );
};

const router = createBrowserRouter([
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: '/new-post',
        element: <Editor />,
      },
      {
        path: '/new-presentation',
        element: <Editor />,
      },
      {
        path: '/post/:postId',
        element: <Editor />,
      },
      {
        path: '/entries',
        element: <Entries />,
      },
      {
        path: '/presentations',
        element: <Presentations />,
      },
      {
        path: '/presentation/:postId',
        element: <Editor />,
      },
      {
        path: '/tags',
        element: <Tags />,
      },
      {
        path: '/authors/*',
        element: <AuthorsRouter />,
      },
      {
        path: '/surveys/*',
        element: <SurveysRouter />,
      },
      {
        path: '/reports',
        element: <Entries />,
      },
      {
        path: '/search',
        element: <GeneralSearch />,
      },
      {
        path: '*',
        element: <NotFound404 />,
      },
      {
        path: '/campaigns',
        element: <Campaigns />,
      },
    ],
  },
]);

export default function App(): JSX.Element {
  return <RouterProvider router={router} />;
}
