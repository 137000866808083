import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Checkbox } from '@mui/material';
import axios, { AxiosError } from 'axios';
import type Campaign from '../../interfaces/Campaigns';
import WithoutImageIcon from '../../icons/WithoutImageIcon';
import capitalizeFirstLetter from '../../utils/capitalizeFirstLetter';
import ScheduledCalendarIcon from '../../icons/ScheduledCalendarIcon';
import TrashIcon from '../../icons/TrashIcon';
import EyeIcon from '../../icons/EyeIcon';
import { CAMPAIGNS_STATUS } from '../../constants/status';
import { days, months } from '../../utils/date';
import { formatLessThan10 } from '../../utils/formatLessThan10';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import CampaignDetails from './CampaignDetails';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../../context/reducers/generalSnackbar';
import { CAMPAIGNS_ERRORS } from '../../constants/errorMessages';
import { deleteCampaign } from '../../api/campaings';
import { CAMPAINGS_SUCCESFUL_MESSAGES } from '../../constants/successfulMessages';
import { setScheduledCampaigns } from '../../context/reducers/scheduledCampaigns';
import { setScheduledDeleted, setScheduledQuantity } from '../../context/reducers/campaigns';
import ErrorInformationIcon from '../../icons/ErrorInformationIcon';
import MoreInfoModal from './MoreInfoModal';

interface Props extends Campaign {
  campaigns: null | Campaign[];
  campaignsQuantity: number;
  selectedCampaigns: number[];
  setSelectedCampaigns: React.Dispatch<React.SetStateAction<number[]>>;
  deletedCampaign: boolean;
  setDeletedCampaign: React.Dispatch<React.SetStateAction<boolean>>;
  isDeletingMultiple?: boolean;
}

interface TextI {
  error: boolean;
}

export default function ScheduledItem({
  title,
  body,
  scheduledAt,
  status,
  id,
  thumbnailUrl,
  campaigns,
  campaignsQuantity,
  selectedCampaigns,
  setSelectedCampaigns,
  deletedCampaign,
  setDeletedCampaign,
  isDeletingMultiple,
}: Props): JSX.Element {
  const [confirmDeleteModalIsVisible, setConfirmDeleteModalIsVisible] = useState(false);
  const [moreInfoModalIsVisible, setMoreInfoModalIsVisible] = useState(false);
  const [detailsModalIsVisible, setDetailsModalIsVisible] = useState(false);
  const [checked, setChecked] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const dispatch = useDispatch();

  const checkIfCampaignIsIncluded = (): boolean => {
    return selectedCampaigns.includes(id);
  };

  useEffect(() => {
    setChecked(checkIfCampaignIsIncluded() && status !== CAMPAIGNS_STATUS.IN_PROGRESS);
  }, [selectedCampaigns]);

  const getTitle = () => {
    if (!title) return 'Sin título';
    return title;
  };

  const getFormattedScheduledAt = () => {
    if (!scheduledAt) return '';
    const utcDate = new Date(scheduledAt);
    const offset = -5;
    const auxScheduledAt = new Date(utcDate.getTime() + offset * 60 * 60 * 1000);
    return `${capitalizeFirstLetter(days[auxScheduledAt.getDay()])}, ${formatLessThan10(
      auxScheduledAt.getDate(),
    )} de ${capitalizeFirstLetter(months[auxScheduledAt.getMonth()])}  ${
      auxScheduledAt.getHours() === 0 ? '00' : formatLessThan10(auxScheduledAt.getHours())
    }:${auxScheduledAt.getMinutes() === 0 ? '00' : formatLessThan10(auxScheduledAt.getMinutes())}`;
  };

  const handleDelete = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    setConfirmDeleteModalIsVisible(true);
  };

  const seeDetails = () => {
    if (deleting) return;
    setDetailsModalIsVisible(true);
  };

  const confirmDeleteCampaign = async () => {
    if (deleting) return;
    try {
      setConfirmDeleteModalIsVisible(false);
      setDeletedCampaign(!deletedCampaign);
      setDeleting(true);
      await deleteCampaign(id);
      if (campaigns !== null) {
        dispatch(
          setScheduledCampaigns({
            scheduled: campaigns?.filter((item) => item.id !== id),
          }),
        );
        dispatch(
          setScheduledQuantity({
            scheduledQuantity: campaignsQuantity > 0 ? campaignsQuantity - 1 : campaignsQuantity,
          }),
        );
      }
      dispatch(
        openSnackbar({
          type: 'success',
          message: CAMPAINGS_SUCCESFUL_MESSAGES.DELETE_CAMPAIGN,
        }),
      );
    } catch (error: unknown) {
      let errorStatus;
      if (error instanceof AxiosError) {
        errorStatus = error?.response?.status;
      }
      if (!axios.isCancel(error) && errorStatus !== 401) {
        dispatch(
          openSnackbar({
            type: 'error',
            message: CAMPAIGNS_ERRORS.DELETE,
          }),
        );
      }
    } finally {
      setDeleting(false);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    if (newValue) {
      setSelectedCampaigns([...selectedCampaigns, id]);
    } else {
      setSelectedCampaigns(selectedCampaigns.filter((item) => item !== id));
    }
    setChecked(newValue);
  };

  const moreInfo = () => {
    setMoreInfoModalIsVisible(true);
  };

  return (
    <>
      <Container>
        <Grid>
          <LeftContainer>
            <CheckBoxContainer>
              <Checkbox
                checked={checked}
                disabled={status === CAMPAIGNS_STATUS.IN_PROGRESS || isDeletingMultiple}
                onChange={handleChange}
              />
            </CheckBoxContainer>
            <ImageContainer>
              {thumbnailUrl ? <Image src={thumbnailUrl} alt={title} /> : <WithoutImageIcon />}
            </ImageContainer>
            <Title error={status === CAMPAIGNS_STATUS.ERROR}>
              {capitalizeFirstLetter(getTitle())}
            </Title>
          </LeftContainer>
          <MiddleContainer>
            <ScheduledAtContainer>
              {status === CAMPAIGNS_STATUS.ERROR ? (
                <ErrorInformationIcon />
              ) : (
                <ScheduledCalendarIcon />
              )}
              <ScheduledAt error={status === CAMPAIGNS_STATUS.ERROR}>
                {getFormattedScheduledAt()}
              </ScheduledAt>
            </ScheduledAtContainer>
            {status === CAMPAIGNS_STATUS.ERROR && (
              <Button onClick={moreInfo} className='bg-error px-2 rounded-2xl mr-2 py-0'>
                <ButtonText>Ver más información</ButtonText>
              </Button>
            )}
          </MiddleContainer>
          <ButtonsContainer>
            <AudienceContainer>
              <Button
                disabled={true}
                onClick={seeDetails}
                className='px-5 rounded-2xl mr-2'
                style={{
                  border: '1px solid #183582',
                }}
              >
                <ButtonText style={{ color: '#183582' }}>Todos</ButtonText>
              </Button>
            </AudienceContainer>
            <VisualizationContainer>
              <Button
                onClick={seeDetails}
                className='bg-primary-blue px-5 rounded-2xl mr-2'
                startIcon={<EyeIcon color='white' height={12} width={20} />}
              >
                <ButtonText>Visualizar</ButtonText>
              </Button>
              <IconButton
                onClick={handleDelete}
                disabled={status === CAMPAIGNS_STATUS.IN_PROGRESS || isDeletingMultiple}
              >
                <TrashIcon
                  color={
                    status !== CAMPAIGNS_STATUS.IN_PROGRESS ? '#CD4F4F' : 'rgba(0, 0, 0, 0.26)'
                  }
                />
              </IconButton>
            </VisualizationContainer>
          </ButtonsContainer>
        </Grid>
        <Footer>
          <FooterLabel>Notificación programada</FooterLabel>
          <Row>
            <MobileScheduledAtContainer>
              <ScheduledCalendarIcon />
              <ScheduledAt error={status === CAMPAIGNS_STATUS.ERROR}>
                {getFormattedScheduledAt()}
              </ScheduledAt>
            </MobileScheduledAtContainer>
            <Button onClick={seeDetails} className='bg-primary-blue px-5 rounded-2xl mr-2'>
              <ButtonText>Visualizar</ButtonText>
            </Button>
          </Row>
        </Footer>
      </Container>
      <ConfirmDeleteModal
        isVisible={confirmDeleteModalIsVisible}
        setIsVisible={setConfirmDeleteModalIsVisible}
        deleting={deleting}
        setDeleting={setDeleting}
        confirmDeleteCampaign={confirmDeleteCampaign}
      />
      <MoreInfoModal
        isVisible={moreInfoModalIsVisible}
        setIsVisible={setMoreInfoModalIsVisible}
        deleting={deleting}
        setDeleting={setDeleting}
        confirmDeleteCampaign={confirmDeleteCampaign}
      />
      <CampaignDetails
        isVisible={detailsModalIsVisible}
        setIsVisible={setDetailsModalIsVisible}
        id={id}
        body={body}
        title={title}
        scheduledAt={scheduledAt}
      />
    </>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid;
  border-bottom-color: ${(props) => props.theme.colors.gray2};
  height: 97px;
  padding-right: 20px;
  padding-left: 20px;

  @media screen and (max-width: 860px) {
    display: block;
    height: inherit;
    padding-top: 15px;
    border: 1px solid ${(props) => props.theme.colors.lightSkyBlue};
    border-radius: 8px;
    margin-bottom: 8px;
    padding-right: 10px;
    margin-left: 24px;
    margin-right: 24px;
  }
`;

const Grid = styled.div`
  height: 97px;
  width: 100%;
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    height: inherit;
  }
`;

const Footer = styled.footer`
  display: none;
  margin-top: 15px;
  @media screen and (max-width: 768px) {
    display: block;
  }
`;

const FooterLabel = styled.p`
  font-family: ${(props) => props.theme.fonts.medium};
  margin-bottom: 7px;
  font-size: 0.85rem;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`;

const CheckBoxContainer = styled.div`
  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const ImageContainer = styled.div`
  height: 72px;
  width: 72px;
  border-radius: 10px;
  overflow: hidden;
  background-color: ${(props) => props.theme.colors.lightSkyBlue};
  margin-left: 9px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 860px) {
    height: 90px;
    margin-left: 0px;
  }
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const Title = styled.p<TextI>`
  margin-left: 12px;
  flex: 1;
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => (props.error ? props.theme.colors.red : props.theme.colors.darkGray)};
  font-size: 16px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;

  @media screen and (max-width: 860px) {
    -webkit-line-clamp: 3;
    line-clamp: 3;
  }
`;

const MiddleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 6px;
`;

const ScheduledAtContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const MobileScheduledAtContainer = styled.div`
  display: none;
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
  }
`;

const ScheduledAt = styled.p<TextI>`
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => (props.error ? props.theme.colors.red : props.theme.colors.darkGray)};
  font-size: 16px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 50px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const AudienceContainer = styled.div`
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

const VisualizationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const ButtonText = styled.p`
  font-family: ${(props) => props.theme.fonts.medium};
  color: white;
  text-transform: none;
`;
