import React from 'react';
import type Icon from '../interfaces/Icon';

export default function ReturnToDraft({ width = 14, height = 18, color = 'black' }: Icon) {
  return (
    <svg width={width} height={height} viewBox='0 0 14 18' fill='none'>
      <path
        d='M10.4485 12.3921C10.2891 12.4581 10.1048 12.4221 9.9814 12.2995M10.4485 12.3921L10.4866 12.4845C10.4866 12.4845 10.4866 12.4845 10.4867 12.4845C10.4867 12.4845 10.4867 12.4845 10.4868 12.4845L10.4485 12.3921ZM10.4485 12.3921C10.6088 12.3261 10.7133 12.1692 10.7133 11.9961C10.7133 9.54826 9.59057 8.28663 7.28501 8.15207M9.9814 12.2995L7.02017 6.02907M9.9814 12.2995C9.35619 11.6735 8.41489 11.5831 7.38501 11.5695L9.9814 12.2995ZM7.28501 8.15207H7.38501V8.0584C7.35383 8.05614 7.32244 8.05408 7.29084 8.05224L7.28501 8.15207ZM7.28501 8.15207V6.42505C7.28501 6.25191 7.18045 6.09507 7.02017 6.02907M7.02017 6.02907L7.05842 5.93668C7.0584 5.93667 7.05838 5.93666 7.05836 5.93665C7.05833 5.93664 7.05829 5.93662 7.05825 5.93661L7.02017 6.02907ZM9.42844 0.754006V0.754004H9.42772H0.856886C0.564973 0.754004 0.328345 0.991749 0.328345 1.28255V16.71C0.328345 17.0028 0.565233 17.2386 0.856886 17.2386H12.8561C13.1477 17.2386 13.3846 17.0028 13.3846 16.71V4.71088C13.3846 4.56535 13.3255 4.43205 13.2292 4.33662C13.2291 4.33654 13.229 4.33646 13.2289 4.33637L9.8027 0.910142L9.73198 0.980852L9.80269 0.910141C9.70669 0.814142 9.57464 0.755058 9.42844 0.754006ZM6.32793 11.139V12.0058L4.17552 9.85338L6.32793 7.70097V8.56775C6.32793 8.85954 6.56469 9.0963 6.85647 9.0963C7.75543 9.0963 8.40952 9.25288 8.86496 9.58117C9.27837 9.87917 9.54102 10.3282 9.66734 10.9657C8.95845 10.6719 8.15729 10.6215 7.36894 10.6124L7.36817 10.6124L6.85685 10.6105H6.85647C6.56469 10.6105 6.32793 10.8472 6.32793 11.139ZM1.38543 1.81109H8.89918V4.71088C8.89918 5.00365 9.13607 5.23942 9.42772 5.23942H12.3275V16.1815H1.38543V1.81109ZM11.5801 4.18234H9.95626V2.55847L11.5801 4.18234Z'
        fill='black'
        stroke='black'
        strokeWidth='0.2'
      />
    </svg>
  );
}
