import React from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import { BsChevronLeft } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface Props {
  title: string;
  subtitle: string;
  handleClickSave: () => void;
  loading?: boolean;
  unsavedChanges?: boolean;
  setUnsavedChanges?: React.Dispatch<React.SetStateAction<boolean>>;
}

interface CustomButtonI {
  withBackground: boolean;
  unsavedChanges: boolean;
}

interface CustomButtonTextI {
  white: boolean;
}

export default function PageDetailsHeader({
  title,
  subtitle,
  handleClickSave,
  loading = false,
  unsavedChanges = false,
  setUnsavedChanges,
}: Props): JSX.Element {
  const navigate = useNavigate();

  const handleClickCancel = (): void => {
    navigate('/authors');
  };

  return (
    <Container>
      <LeftContainer>
        <TitleContainer>
          <StyledIconButton onClick={handleClickCancel}>
            <BsChevronLeft style={{ color: '#006F9B' }} />
          </StyledIconButton>
          <MobileIconButton onClick={handleClickCancel}>
            <ArrowBackIcon style={{ color: '#006F9B' }} />
          </MobileIconButton>
          <Title>{title}</Title>
        </TitleContainer>
        <Subtitle>{subtitle}</Subtitle>
      </LeftContainer>
      <RightContainer>
        <CustomButton
          unsavedChanges={true}
          onClick={handleClickCancel}
          withBackground={false}
          className='rounded-2xl'
        >
          <ButtonText white={false}>Cancelar</ButtonText>
        </CustomButton>
        <CustomButton
          unsavedChanges={unsavedChanges}
          disabled={loading || !unsavedChanges}
          onClick={handleClickSave}
          withBackground={true}
          className='rounded-2xl'
        >
          {loading ? (
            <CircularProgress style={{ color: 'white' }} size={20} />
          ) : (
            <ButtonText white={true}>Guardar</ButtonText>
          )}
        </CustomButton>
      </RightContainer>
    </Container>
  );
}

const Container = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 42px;
`;

const LeftContainer = styled.div`
  flex: 1;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10px;
  margin-bottom: 5px;

  @media screen and (max-width: 860px) {
    padding-left: 20px;
  }
`;

const StyledIconButton = styled(IconButton)`
  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const MobileIconButton = styled(IconButton)`
  display: none;
  &&& {
    background-color: #f0f6fa;
    margin-right: 8px;
  }
  @media screen and (max-width: 860px) {
    display: flex;
  }
`;

const Title = styled.p`
  font-family: ${(props) => props.theme.fonts.medium};
  font-size: 28px;

  @media screen and (max-width: 860px) {
    font-size: 18px;
  }
`;

const Subtitle = styled.p`
  padding-left: 24px;
  font-family: ${(props) => props.theme.fonts.medium};
  color: ${(props) => props.theme.colors.darkGray};
  font-size: 14px;

  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const CustomButton = styled(Button)<CustomButtonI>`
  &&& {
    min-width: 120px;
    border: 1px solid;
    border-color: ${(props) =>
      !props?.unsavedChanges ? 'transparent' : props.theme.colors.primary};
    border-radius: 5px;
    background-color: ${(props) =>
      !props?.unsavedChanges
        ? props.theme.colors.btnPrimaryDisabled
        : props?.withBackground
        ? props.theme.colors.primary
        : 'transparent'};
  }
`;

const ButtonText = styled.p<CustomButtonTextI>`
  text-transform: none;
  font-family: ${(props) => props.theme.fonts.medium};
  color: ${(props) => (props.white ? 'white' : props.theme.colors.primary)};
  font-size: 17px;
  padding: 0px 15px;
`;
