import React from 'react';
import styled from 'styled-components';
import SectionHeader from '../../globals/SectionHeader';
import CustomSwitch from '../../globals/CustomSwitch';
import DateTimeSelector from '../../globals/DateTimeSelector';

export default function SettingsSection(): JSX.Element {
  return (
    <Container>
      <SectionHeader title='Configuración de la encuesta' />
      <Content>
        <Title>
          Vigencia
          <Span>*</Span>
        </Title>
        <SelectorsContainer>
          <ColumnsContainer>
            <Column>
              <Label>Desde</Label>
              <DateTimeSelector type='date' />
              <DateTimeSelector type='time' />
            </Column>
            <Column>
              <Label>Hasta</Label>
              <DateTimeSelector type='date' />
              <DateTimeSelector type='time' />
            </Column>
          </ColumnsContainer>
          <CustomSwitch />
        </SelectorsContainer>
      </Content>
    </Container>
  );
}

const Container = styled.section`
  padding-left: 24px;
`;

const Content = styled.div`
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 24px;
`;

const Title = styled.p`
  font-family: ${(props) => props.theme.fonts.bold};
  color: ${(props) => props.theme.colors.darkGray};
  font-size: 14px;
  margin-bottom: 10px;
`;

const Span = styled.span`
  font-family: ${(props) => props.theme.fonts.bold};
  color: ${(props) => props.theme.colors.primary};
`;

const SelectorsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const ColumnsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 35px;
  flex: 1;
`;

const Column = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 11px;
`;

const Label = styled.p`
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.dark};
  font-size: 13px;
`;
