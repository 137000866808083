/* eslint-disable @typescript-eslint/promise-function-async */
import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Skeleton } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import type DraftInterface from '../../interfaces/Draft';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import TrashIcon from '../../icons/TrashIcon';
import getAvatar from '../../utils/getAvatar';
import getLastModifiedDate from '../../utils/getLastModifiedDate';
import WithoutImageIcon from '../../icons/WithoutImageIcon';
import PublicationIcon from '../../icons/PublicationIcon';
import MovieIcon from '../../icons/MovieIcon';
import AudioIcon from '../../icons/AudioIcon';
import { type RootState } from '../../context/store';
import capitalizeFirstLetter from '../../utils/capitalizeFirstLetter';
import { deleteEntry } from '../../utils/entries';
import POSTS_STATUS from '../../constants/status';
import ConfirmForceDeleteModal from './ConfirmForceDeleteModal';
import { setHomeDraftPostDeleted } from '../../context/reducers/homeDrafts';
import { getLastPublicationDate } from '../../utils/getLastPublicationDate';
import { HTML_TEXT } from '../../constants/globals';
import PDFIcon from '../../icons/PDFIcon';

interface Props extends DraftInterface {
  drafts: DraftInterface[] | null;
}

export default function DrafItem({
  id,
  title,
  images,
  categories,
  lastModifiedDate,
  writer,
  hasAudio,
  drafts,
  createdDate,
  contentType,
}: Props): JSX.Element {
  const draftsQuantity = useSelector((state: RootState) => state.entries.draftsQuantity);
  const draftPostDeleted = useSelector((state: RootState) => state.entries.draftPostDeleted);
  const categoriesList = useSelector((state: RootState) => state.categories.categoriesList);
  const [deleting, setDeleting] = useState(false);
  const [checked, setChecked] = useState(false);
  const [confirmDeleteModalIsVisible, setConfirmDeleteModalIsVisible] = useState(false);
  const [confirmForceDeleteModalIsVisible, setConfirmForceDeleteModallIsVisible] = useState(false);
  const [errorDeleteMessage, setErrorDeleteMessage] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setChecked(event.target.checked);
  };

  const handleClickEdit = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    navigate(`${contentType === HTML_TEXT ? '/post/' : '/presentation/'}${id}`, {
      state: { prevRouteName: location.pathname },
    });
  };

  const confirmDeletePost = async (forceDelete: boolean): Promise<void> => {
    try {
      await deleteEntry(
        setConfirmDeleteModalIsVisible,
        deleting,
        setDeleting,
        id,
        dispatch,
        POSTS_STATUS.DRAFT,
        draftPostDeleted,
        false,
        false,
        'entries',
        setConfirmForceDeleteModallIsVisible,
        undefined,
        drafts,
        undefined,
        draftsQuantity,
        undefined,
        forceDelete,
        setErrorDeleteMessage,
        contentType,
      );
      setDeleting(false);
      dispatch(
        setHomeDraftPostDeleted({
          draftPostDeleted: true,
        }),
      );
    } catch (err) {}
  };

  const onClickTrashBtn = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setConfirmDeleteModalIsVisible(true);
  };

  const getCategories = (): any[] => {
    if (categories === null || categories?.length === 0) {
      return [];
    }
    if (categoriesList !== null) {
      const categoriesNames: any[] = [];
      const auxCategoriesList: any[] = [...categoriesList];
      categories?.forEach((category) => {
        auxCategoriesList?.forEach((auxCategory) => {
          if (category === auxCategory?.id) {
            categoriesNames.push({
              name: auxCategory?.name,
              color: auxCategory?.color,
            });
          }
        });
      });
      return categoriesNames;
    }
    return [];
  };

  const getTitle = () => {
    if (!title) return 'Sin título';
    return title;
  };

  return (
    <>
      <Container
        to={`${contentType === HTML_TEXT ? '/post/' : '/presentation/'}${id}`}
        state={{ prevRouteName: location.pathname }}
      >
        <Row>
          <AuxDateContainer>
            <DateContainer>
              <DateText>{getLastPublicationDate(createdDate)}</DateText>
            </DateContainer>
            <MobileIconsContainer>
              {contentType === HTML_TEXT ? (
                <>
                  <PublicationIcon />
                  {hasAudio && <AudioIcon />}
                </>
              ) : (
                <PDFIcon />
              )}
            </MobileIconsContainer>
          </AuxDateContainer>
          <ImageContainer>
            {images?.principalImage ? (
              <Image src={images?.principalImage} alt={title} />
            ) : (
              <WithoutImageIcon />
            )}
          </ImageContainer>
          <InfoContainer>
            <MainContainer>
              <Title>{capitalizeFirstLetter(getTitle())}</Title>
              {categoriesList == null ? (
                <Skeleton
                  variant='rectangular'
                  style={{
                    width: '50px',
                    height: '10px',
                    borderRadius: '8px',
                    marginTop: '3px',
                    marginBottom: '3px',
                  }}
                />
              ) : (
                <Categories>
                  {getCategories()?.length === 0 ? (
                    <Category style={{ color: '#183582' }}>Sin categorías</Category>
                  ) : (
                    getCategories().map((category, index) => (
                      <CategoryContainer style={{ backgroundColor: category?.color }} key={index}>
                        <Category key={index} style={{ color: 'white' }}>
                          {category.name}
                        </Category>
                      </CategoryContainer>
                    ))
                  )}
                </Categories>
              )}
              <MainContainerFooter>
                <Date>{getLastModifiedDate(lastModifiedDate)}</Date>
              </MainContainerFooter>
            </MainContainer>
            <IconsContainer>
              {contentType === HTML_TEXT ? (
                <>
                  <PublicationIcon />
                  {hasAudio && <AudioIcon />}
                </>
              ) : (
                <PDFIcon />
              )}
            </IconsContainer>
            <AuthorContainer>
              <AvatarContainer>
                <Avatar>{getAvatar(writer?.names)}</Avatar>
              </AvatarContainer>
              <AuthorName>{writer?.names}</AuthorName>
            </AuthorContainer>
          </InfoContainer>
          <ButtonsContainer>
            <Button onClick={handleClickEdit} className='bg-primary-blue px-5 rounded-2xl mr-2'>
              <ButtonText>Editar</ButtonText>
            </Button>
            <IconButton onClick={onClickTrashBtn}>
              <TrashIcon />
            </IconButton>
          </ButtonsContainer>
        </Row>
        <MobileContainerFooter>
          <MobileCategories>
            {getCategories().map((category, index) => (
              <CategoryContainer style={{ backgroundColor: category?.color }} key={index}>
                <Category key={index} style={{ color: 'white' }}>
                  {category.name}
                </Category>
              </CategoryContainer>
            ))}
          </MobileCategories>
          <Footer>
            <MobileDate>{getLastModifiedDate(lastModifiedDate)}</MobileDate>
            <Button
              onClick={handleClickEdit}
              className='bg-primary-blue px-8 py-2 rounded-2xl mr-2'
            >
              <ButtonText>Editar</ButtonText>
            </Button>
          </Footer>
        </MobileContainerFooter>
      </Container>
      <ConfirmDeleteModal
        title={contentType === HTML_TEXT ? '¿Eliminar artículo?' : '¿Eliminar PPT?'}
        message={`¿Seguro que quieres eliminar ${
          contentType === HTML_TEXT ? 'este artículo' : 'este PPT'
        }? Recuerda que esta acción no es reversible. `}
        isVisible={confirmDeleteModalIsVisible}
        setIsVisible={setConfirmDeleteModalIsVisible}
        deleting={deleting}
        setDeleting={setDeleting}
        confirmDeletePost={() => confirmDeletePost(false)}
      />
      <ConfirmForceDeleteModal
        isVisible={confirmForceDeleteModalIsVisible}
        setIsVisible={setConfirmForceDeleteModallIsVisible}
        message={errorDeleteMessage}
        deleting={deleting}
        setDeleting={setDeleting}
        confirmDeletePost={() => confirmDeletePost(true)}
      />
    </>
  );
}

const Container = styled(Link)`
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid;
  border-bottom-color: ${(props) => props.theme.colors.gray2};
  height: 97px;
  padding-right: 20px;
  padding-left: 20px;
  transition: background-color 0.1s ease-out;

  :hover {
    background-color: rgba(0, 0, 0, 0.02);
  }

  @media screen and (max-width: 860px) {
    display: block;
    height: inherit;
    padding-top: 15px;
    border: 1px solid ${(props) => props.theme.colors.lightSkyBlue};
    border-radius: 8px;
    margin-bottom: 8px;
    padding-right: 10px;
  }
`;

const Row = styled.div`
  height: 97px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media screen and (max-width: 860px) {
    height: inherit;
    align-items: flex-start;
  }
`;

const AuxDateContainer = styled.div`
  display: none;
  position: absolute;
  left: 0px;
  z-index: 100;
  top: 9px;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  @media screen and (max-width: 860px) {
    display: flex;
  }
`;

const MobileIconsContainer = styled.div`
  display: none;
  @media screen and (max-width: 860px) {
    display: inline-flex;
    gap: 4px;
  }
`;

const DateContainer = styled.div`
  background-color: ${(props) => props.theme.colors.primary};
  min-width: 94px;
  padding: 1px 8px;
  border-radius: 0px 6px 6px 0px;
`;

const DateText = styled.p`
  font-family: ${(props) => props.theme.fonts.semiBold};
  color: white;
  font-size: 14px;
`;

const ImageContainer = styled.div`
  height: 72px;
  width: 72px;
  border-radius: 10px;
  overflow: hidden;
  background-color: ${(props) => props.theme.colors.lightSkyBlue};
  margin-left: 9px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 860px) {
    height: 90px;
    margin-left: 0px;
  }
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const InfoContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  @media screen and (max-width: 860px) {
    padding-top: 15px;
  }
`;

const MainContainer = styled.div`
  padding-left: 12px;
  flex: 1;
`;

const Title = styled.p`
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.darkGray};
  font-size: 16px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;

  @media screen and (max-width: 860px) {
    -webkit-line-clamp: 3;
    line-clamp: 3;
  }
`;

const Categories = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  margin: 3px 0px;

  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const MobileCategories = styled.div`
  display: none;
  @media screen and (max-width: 860px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    margin-top: 10px;
    flex-wrap: wrap;
  }
`;

const Footer = styled.footer`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 10px;
`;

const MobileDate = styled.p`
  font-family: ${(props) => props.theme.fonts.semiBold};
  color: ${(props) => props.theme.colors.accentBlue};
  font-size: 13px;
  flex: 1;
`;

const Category = styled.span`
  font-family: ${(props) => props.theme.fonts.medium};
  color: ${(props) => props.theme.colors.skyBlue};
  font-size: 13px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const MainContainerFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;

  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const Date = styled.p`
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.gray2};
  font-size: 13px;
`;

const AuthorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 50px;
  margin-left: 20px;

  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const AvatarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  border-radius: 100%;
  background-color: ${(props) => props.theme.colors.accentBlue};
`;

const Avatar = styled.p`
  color: white;
  font-family: ${(props) => props.theme.fonts.regular};
  font-size: 13px;
  text-transform: uppercase;
`;

const AuthorName = styled.p`
  text-transform: capitalize;
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.darkGray};
  margin-left: 12px;
  max-width: 150px;
`;

const ButtonsContainer = styled.div`
  flex-direction: row;
  align-items: center;

  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const ButtonText = styled.p`
  font-family: ${(props) => props.theme.fonts.medium};
  color: white;
  text-transform: none;
`;

const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 40px;
  gap: 10px;
  margin-right: 40px;
  margin-left: 30px;

  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const CategoryContainer = styled.div`
  padding: 2px 10px;
  border-radius: 11px;
`;

const MobileContainerFooter = styled.div`
  display: none;
  @media screen and (max-width: 860px) {
    display: block;
  }
`;
