import React, { createRef, useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useDispatch } from 'react-redux';
import ClickOutsideDetector from './ClickOutsideDetector';
import ResultItem from './ResultItem';
import GeneralSearchMenuFooter from './GeneralSearchMenuFooter';
import { closeSearchBar } from '../../context/reducers/generalSearch';
import NotFoundIcon from '../../icons/NotFoundIcon';
import { CircularProgress } from '@mui/material';

interface Props {
  posts: any[] | null;
  searchInput: string;
}

export default function GeneralSearchMenu({ posts, searchInput }: Props) {
  const dispatch = useDispatch();
  const modalRef = createRef<HTMLDivElement>();

  const handleClickOutside = () => {
    dispatch(closeSearchBar({}));
  };

  return (
    <>
      {searchInput !== '' && (
        <ClickOutsideDetector
          onClickOutside={handleClickOutside}
          listen={true}
          ref={modalRef}
          wrapperId='main-layout'
          isGeneralSearchBar={true}
        >
          <Container>
            {posts == null ? (
              <CircularProgressContainer>
                <CircularProgress size={20} />
              </CircularProgressContainer>
            ) : searchInput !== '' && posts?.length === 0 ? (
              <MessageContainer>
                <IconContainer>
                  <NotFoundIcon width={30} height={30} />
                </IconContainer>
                <Message>
                  ¡Lo sentimos! No encontramos ningún artículo relacionado a tu búsqueda, prueba con
                  otra palabra.
                </Message>
              </MessageContainer>
            ) : (
              <>
                {posts?.map((post, index) => (
                  <ResultItem key={`${post.id}_${index}`} post={post} />
                ))}
                {posts?.length > 0 && <GeneralSearchMenuFooter />}
              </>
            )}
          </Container>
        </ClickOutsideDetector>
      )}
    </>
  );
}

const Container = styled(motion.div)`
  position: fixed;
  margin-top: 5px;
  z-index: 999;
  box-shadow: 1px 1px 10px 0px rgba(113, 112, 112, 0.15);
  width: 100%;
  max-width: 500px;
  background-color: white;
  border-radius: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
`;
const Title = styled.p``;

const CircularProgressContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: calc(100% - 60px);
`;

const IconContainer = styled.div`
  margin-top: 13px;
  margin-bottom: 13px;
`;

const Message = styled.p`
  max-width: 350px;
  text-align: center;
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.black};
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 15px;
`;
