import React from 'react';
import styled from 'styled-components';

interface Props {
  title: string;
}

export default function SectionHeader({ title }: Props): JSX.Element {
  return (
    <Container>
      <Title>{title}</Title>
    </Container>
  );
}

const Container = styled.header`
  background-color: ${(props) => props.theme.colors.lightSkyBlue};
  border-radius: 10px 10px 0px 0px;
  height: 65px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 30px;
  margin-top: 14px;

  @media screen and (max-width: 860px) {
    padding-left: 14px;
  }
`;

const Title = styled.p`
  font-family: ${(props) => props.theme.fonts.medium};
  color: ${(props) => props.theme.colors.darkGray};
  font-size: 20px;
`;
