import React from 'react';
import styled from 'styled-components';
import getAvatar from '../../utils/getAvatar';
import type PreviewInformation from '../../interfaces/PreviewInformation';
import AuthorItem from './AuthorItem';

interface Author {
  authorId: number;
  names: string;
  username?: string;
}

interface Props {
  type: string;
  title: string;
  list: Author[];
  previewInformation: PreviewInformation | null;
}

export default function AuthorsList({ type = 'author', title, list, previewInformation }: Props) {
  return (
    <Container>
      <Title>{title}</Title>
      <List>
        {list.map((item, index) => (
          <AuthorItem
            key={type === 'author' ? `${item.authorId}_${index}` : index}
            previewInformation={previewInformation}
            type={type}
            {...item}
          />
        ))}
      </List>
    </Container>
  );
}

const Container = styled.div`
  padding-top: 8px;
  margin-bottom: 15px;
`;

const Title = styled.p`
  font-family: ${(props) => props.theme.fonts.medium};
  color: white;
  font-size: 16px;
  margin-bottom: 10px;
`;

const List = styled.div`
  position: relative;
  display: flex;
  gap: 10px;
  flex-direction: column;
`;
