import React from 'react';
import type Icon from '../interfaces/Icon';

export default function EmailIcon({ width = 21, height = 21, color = 'white' }: Icon) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 21 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.5 0.75C4.98598 0.75 0.5 5.23598 0.5 10.75C0.5 16.264 4.98598 20.75 10.5 20.75C16.014 20.75 20.5 16.264 20.5 10.75C20.5 5.23598 16.014 0.75 10.5 0.75ZM10.5 19.8125C5.50293 19.8125 1.4375 15.7471 1.4375 10.75C1.4375 5.75293 5.50293 1.6875 10.5 1.6875C15.4971 1.6875 19.5625 5.75293 19.5625 10.75C19.5625 15.7471 15.4971 19.8125 10.5 19.8125ZM15.1997 6.62891H5.80027C5.1716 6.62891 4.66012 7.14039 4.66012 7.76906V13.731C4.66012 14.3596 5.17156 14.8711 5.80027 14.8711H15.1997C15.8284 14.8711 16.3398 14.3596 16.3398 13.731V7.76906C16.3398 7.14039 15.8284 6.62891 15.1997 6.62891ZM14.4931 7.56641L10.5 10.7374L6.50688 7.56641H14.4931ZM15.1997 13.9336H5.80027C5.68855 13.9336 5.59762 13.8427 5.59762 13.7309V8.04148L10.2085 11.703C10.2938 11.7708 10.3969 11.8047 10.5 11.8047C10.603 11.8047 10.7061 11.7708 10.7914 11.703L15.4023 8.04148V13.7309C15.4023 13.8427 15.3114 13.9336 15.1997 13.9336Z'
        fill={color}
      />
    </svg>
  );
}
