import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import HeaderButtonWithText from '../components/globals/HeaderButtonWithText';
import TopTabs from '../components/entries/TopTabs';
import EntriesList from '../components/entries/EntriesList';
import { type RootState } from '../context/store';
import { getCategories } from '../api/categories';
import { setCategoriesList } from '../context/reducers/categories';
import { openSnackbar } from '../context/reducers/generalSnackbar';
import { CATEGORIES_ERRORS, HOME_ERRORS } from '../constants/errorMessages';
import { checkIfSomeFilterIsSelected } from '../utils/entries';
import ComboBoxCategoriesWrapped from '../components/globals/ComboBoxCategoriesWrapped';
import SearchBar from '../components/entries/SearchBar';
import ComboBoxAuthorsWrapped from '../components/globals/ComboBoxAuthorsWrapped';
import ComboBox from '../components/globals/ComboBox';
import RESOURCES_TYPES from '../constants/resourcesTypes';
import DraftsList from '../components/entries/DraftsList';
import MobileNewPublicationButton from '../components/globals/MobileNewPublicationButton';

const Entries: React.FC = () => {
  const categoriesList = useSelector((state: RootState) => state.categories.categoriesList);
  const selectedStatus = useSelector((state: RootState) => state.entries.selectedStatus);
  const [selectAll, setSelectAll] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [resource, setResource] = useState<string | null>(null);
  const [selectedAuthors, setSelectedAuthors] = useState<any[]>([]);
  const [categoriesIDs, setCategoriesIDs] = useState<string[]>([]);
  const navigate = useNavigate();
  const isMounted = useRef(false);
  const dispatch = useDispatch();

  const callApiToGetCategories = (): void => {
    getCategories()
      .then((res) => {
        if (isMounted.current) {
          dispatch(
            setCategoriesList({
              categoriesList: res,
            }),
          );
        }
      })
      .catch((error: unknown) => {
        let errorStatus;
        if (error instanceof AxiosError) {
          errorStatus = error?.response?.status;
        }
        if (errorStatus !== 401) {
          dispatch(
            openSnackbar({
              type: 'error',
              message: CATEGORIES_ERRORS.CATEGORIES_LIST,
            }),
          );
        }
      });
  };

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (categoriesList === null) {
      callApiToGetCategories();
    }
  }, []);

  const handleInputChange = (value: string) => {};
  const handleChangeResources = (values: any[] | string | null | any): void => {};
  return (
    <Container>
      <HeaderButtonWithText title='Entradas' type='publication' buttonText='Nueva publicación' />
      <TopTabsContainer>
        <TopTabs />
      </TopTabsContainer>
      <EntriesList
        selectAll={selectAll}
        searchInput={searchInput}
        resource={resource}
        selectedAuthors={selectedAuthors}
        categoriesIDs={categoriesIDs}
        someFilterIsSelected={checkIfSomeFilterIsSelected(
          resource,
          selectedAuthors,
          categoriesIDs,
          searchInput,
        )}
      />
      <MobileNewPublicationButton />
    </Container>
  );
};

const Container = styled.section`
  padding-left: 24px;
  padding-right: 24px;
  margin-left: 192px;
  margin-top: 72px;
  width: calc(100vw - 192px);

  @media screen and (max-width: 860px) {
    margin-left: 0px;
    width: 100vw;
    margin-top: 80px;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const TopTabsContainer = styled.div`
  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const FiltersContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  flex: 1;
`;

const AuxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 65px;
  background-color: ${(props) => props.theme.colors.lightSkyBlue};
  border-radius: 10px 10px 0px 0px;
  margin-top: 20px;
  padding-right: 20px;
  padding-left: 20px;
`;

const Title = styled.p`
  font-size: 17px;
  color: transparent;
  font-family: ${(props) => props.theme.fonts.regular};
  margin-left: 12px;
  margin-right: 20px;
  white-space: nowrap;
  user-select: none;
`;

export default Entries;
