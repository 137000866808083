import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CAMPAIGNS_STATUS } from '../../constants/status';
import ScheduledList from './ScheduledList';
import ProcessedList from './ProcessedList';
import styled from 'styled-components';

export default function CampaignsList(): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams();
  const [paramType, setParamType] = useState(CAMPAIGNS_STATUS.SCHEDULED);

  useEffect(() => {
    setParamType(searchParams.get('type')?.toUpperCase() ?? CAMPAIGNS_STATUS.SCHEDULED);
  }, [searchParams]);

  return (
    <Container>
      {paramType === CAMPAIGNS_STATUS.SCHEDULED ? <ScheduledList /> : <ProcessedList />}
    </Container>
  );
}

const Container = styled.ul`
  padding-bottom: 80px;
  flex: 1;
`;
