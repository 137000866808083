import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
} from 'date-fns';
import { formatLessThan10 } from './formatLessThan10';

const getLastModifiedDate = (lastModifiedDate: string): string => {
  const utcDate = new Date(lastModifiedDate);
  const offset = -5;
  const auxLastModifiedDate = new Date(utcDate.getTime() + offset * 60 * 60 * 1000);
  const today = new Date();
  const seconds = differenceInSeconds(today, auxLastModifiedDate);
  const minutes = differenceInMinutes(today, auxLastModifiedDate);
  const hours = differenceInHours(today, auxLastModifiedDate);
  const days = differenceInDays(today, auxLastModifiedDate);

  if (Math.abs(seconds) < 60) {
    return `Editado hace ${formatLessThan10(Math.abs(seconds))} ${
      Math.abs(seconds) > 1 ? 'segundos' : 'segundo'
    }`;
  } else if (Math.abs(minutes) < 60) {
    return `Editado hace ${formatLessThan10(Math.abs(minutes))} ${
      Math.abs(minutes) > 1 ? 'minutos' : 'minuto'
    }`;
  } else if (Math.abs(hours) < 24) {
    return `Editado hace ${formatLessThan10(Math.abs(hours))} ${
      Math.abs(hours) > 1 ? 'horas' : 'hora'
    }`;
  } else if (Math.abs(days) < 2) {
    return 'Editado el día de ayer';
  } else {
    return `Editado hace ${Math.abs(days)} días`;
  }
};

export default getLastModifiedDate;
