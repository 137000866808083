import React, { type SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import PlayArrow from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import ButtonBase from '@mui/material/ButtonBase';
import IconButton from '@mui/material/IconButton';
import Slider from '@mui/material/Slider';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { RiMenuLine, RiDownload2Fill, RiDeleteBin6Line } from 'react-icons/ri';
import AudioPlayerIcon from '../../icons/AudioPlayerIcon';
import CustomMenu from '../globals/CustomMenu';
import { toogleAudioDrawer } from '../../context/reducers/toolBar';
import { setElements, setPreviewElements, setUnsavedChanges } from '../../context/reducers/editor';
import { type RootState } from '../../context/store';
import { CircularProgress } from '@mui/material';
import POSTS_STATUS from '../../constants/status';
import TrashIcon from '../../icons/TrashIcon';

interface Props {
  file: File | null;
  audioSource: string;
}

export default function AudioPlayer({ file, audioSource }: Props): JSX.Element {
  const isPreviewModeActive = useSelector((state: RootState) => state.toolBar.isPreviewModeActive);
  const currentPostStatus = useSelector((state: RootState) => state.editor.currentPostStatus);
  const previewElements = useSelector((state: RootState) => state.editor.previewElements);
  const elements = useSelector((state: RootState) => state.editor.elements);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorVelocity, setAnchorVelocity] = useState<null | HTMLElement>(null);
  const [duration, setDuration] = useState<string>('0:00');
  const [durationInSeconds, setDurationInSeconds] = useState<number | undefined>(0);
  const [currentTime, setCurrentTime] = useState<string>('0:00');
  const [velocity, setVelocity] = useState('x1');
  const [isAudioPlaying, setIsAudioPlaying] = useState<boolean>(false);
  const [sliderValue, setSliderValue] = useState<number | number[]>(0);
  const [isSliderActive, setIsSliderActive] = useState<boolean>(false);
  const [auxAudioSource, setAuxAudioSource] = useState(audioSource);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const audioRef = useRef<HTMLAudioElement>(null);
  const aRef = useRef<HTMLAnchorElement>(null);

  const calculateTime = (secs: number | undefined): string => {
    if (secs !== undefined) {
      const minutes = Math.floor(secs / 60);
      const seconds = Math.floor(secs % 60);
      const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
      return `${minutes}:${returnedSeconds}`;
    }
    return '';
  };

  const deleteAudio = () => {
    const newElement = {
      key: 'audio',
      element: <div></div>,
    };
    const newElements = [...elements];
    const newPreviewElements = [...previewElements];
    const index = newElements.findIndex((item) => item.key === 'audio');
    newElements[index] = newElement;
    newPreviewElements[index] = newElement;
    dispatch(
      setElements({
        elements: newElements,
      }),
    );
    dispatch(
      setPreviewElements({
        previewElements: newPreviewElements,
      }),
    );
    dispatch(
      setUnsavedChanges({
        unsavedChanges: true,
      }),
    );
  };

  const options = [
    {
      label: 'Descargar',
      icon: <RiDownload2Fill style={{ fontSize: '20px' }} />,
      action: () => {
        setAnchorEl(null);
        aRef?.current?.click();
      },
    },
  ];

  if (currentPostStatus !== POSTS_STATUS.PUBLISHED) {
    options.unshift({
      label: 'Editar',
      icon: <CreateOutlinedIcon />,
      action: () => {
        setAnchorEl(null);
        dispatch(
          toogleAudioDrawer({
            isAudioDrawerOpen: true,
          }),
        );
      },
    });
    options.push({
      label: 'Eliminar',
      icon: <RiDeleteBin6Line style={{ fontSize: '20px' }} />,
      action: () => {
        deleteAudio();
      },
    });
  }

  const velocityOptions = [
    {
      label: 'x0.25',
      action: () => {
        changeAudioVelocity(0.25);
      },
    },
    {
      label: 'x0.5',
      action: () => {
        changeAudioVelocity(0.5);
      },
    },
    {
      label: 'x0.75',
      action: () => {
        changeAudioVelocity(0.75);
      },
    },
    {
      label: 'x1',
      action: () => {
        changeAudioVelocity(1);
      },
    },
    {
      label: 'x1.25',
      action: () => {
        changeAudioVelocity(1.25);
      },
    },
    {
      label: 'x1.5',
      action: () => {
        changeAudioVelocity(1.5);
      },
    },
    {
      label: 'x1.75',
      action: () => {
        changeAudioVelocity(1.75);
      },
    },
    {
      label: 'x2',
      action: () => {
        changeAudioVelocity(2);
      },
    },
  ];

  useEffect(() => {
    const currentAudioSource =
      elements[elements.findIndex((item) => item.key === 'audio')].element.props?.audioSource;
    setAuxAudioSource(currentAudioSource ?? '');
  }, [elements]);

  useEffect(() => {
    if (audioRef.current !== null && auxAudioSource !== '') {
      setIsAudioPlaying(false);
      setSliderValue(0);
      setCurrentTime('0:00');
      audioRef.current.src = auxAudioSource;
      audioRef.current.load();
      setIsLoading(true);
      if (audioRef.current.readyState > 0) {
        setDuration(calculateTime(audioRef?.current?.duration));
        setDurationInSeconds(audioRef?.current?.duration);
      } else {
        audioRef.current.addEventListener('loadedmetadata', (information) => {
          setDuration(calculateTime(audioRef?.current?.duration));
          setDurationInSeconds(audioRef?.current?.duration);
        });
      }
    }
  }, [auxAudioSource]);

  useEffect(() => {
    const currentTimeInSeconds = audioRef?.current?.currentTime;
    if (
      currentTimeInSeconds !== undefined &&
      durationInSeconds !== undefined &&
      durationInSeconds > 0 &&
      !isSliderActive
    ) {
      setSliderValue((currentTimeInSeconds * 100) / durationInSeconds);
    }
  }, [currentTime]);

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickPlayButton = (): void => {
    if (!isAudioPlaying) {
      audioRef?.current
        ?.play()
        .then(() => {
          setIsAudioPlaying(true);
        })
        .catch(() => {
          setIsAudioPlaying(false);
        });
    } else {
      audioRef?.current?.pause();
      setIsAudioPlaying(false);
    }
  };

  const handleTimeUpdate = (): void => {
    setCurrentTime(calculateTime(audioRef?.current?.currentTime));
  };

  const handleClickVelocity = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorVelocity(event.currentTarget);
  };

  const changeAudioVelocity = (newValue: number): void => {
    if (audioRef.current !== null) {
      audioRef.current.playbackRate = newValue;
      setVelocity(`x${newValue}`);
      setAnchorVelocity(null);
    }
  };

  const valueLabelFormat = (value: number): string => {
    if (durationInSeconds !== undefined) {
      const newTime = (durationInSeconds * value) / 100;
      return calculateTime(newTime);
    }
    return `${value}`;
  };

  const handleChangeCommitted = (
    event: Event | SyntheticEvent<Element, Event>,
    value: number | number[],
  ): void => {
    if (audioRef.current !== null && durationInSeconds !== undefined && typeof value === 'number') {
      const newTime = (durationInSeconds * value) / 100;
      audioRef.current.currentTime = newTime;
    }
  };

  const handleAudioEnded = () => {
    if (audioRef.current) {
      setIsAudioPlaying(false);
      setSliderValue(0);
      setCurrentTime('0:00');
      audioRef.current.currentTime = 0;
    }
  };

  if (isPreviewModeActive) {
    return <></>;
  }

  return (
    <Container>
      {auxAudioSource !== '' && (
        <>
          <a ref={aRef} href={auxAudioSource} style={{ display: 'none' }} download>
            Download Audio
          </a>
          <audio
            ref={audioRef}
            controls
            preload='metadata'
            style={{ display: 'none' }}
            onTimeUpdate={handleTimeUpdate}
            onLoadedData={() => {
              setIsLoading(false);
            }}
            onEnded={handleAudioEnded}
          >
            <source src={auxAudioSource} type='audio/mpeg' />
            Your browser does not support the audio element.
          </audio>
        </>
      )}
      {isLoading ? (
        <CircularProgressContainer>
          <CircularProgress size={30} />
        </CircularProgressContainer>
      ) : (
        <>
          <IconContainer>
            <AudioPlayerIcon color='white' />
          </IconContainer>
          <Player>
            <LeftPlayer>
              <Title>AUDIO</Title>
              <VelocityAndSliderContainer>
                <VelocityButton onClick={handleClickVelocity}>
                  <Velocity>{velocity}</Velocity>
                </VelocityButton>
                <Time>{currentTime}</Time>
                <SliderContainer>
                  <CustomSlider
                    size='small'
                    defaultValue={0}
                    style={{ color: 'white' }}
                    valueLabelDisplay='auto'
                    valueLabelFormat={valueLabelFormat}
                    onChange={(event, value) => {
                      setIsSliderActive(true);
                      setSliderValue(value);
                    }}
                    onChangeCommitted={(event, value) => {
                      handleChangeCommitted(event, value);
                      setIsSliderActive(false);
                    }}
                    value={sliderValue}
                  />
                </SliderContainer>
                <Time>{duration}</Time>
              </VelocityAndSliderContainer>
            </LeftPlayer>
            <PlayButton onClick={handleClickPlayButton}>
              {isAudioPlaying ? (
                <PauseIcon
                  fontSize='large'
                  style={{ height: '30px', width: '30px', color: 'white' }}
                />
              ) : (
                <PlayArrow
                  fontSize='large'
                  style={{ height: '30px', width: '30px', color: 'white' }}
                />
              )}
            </PlayButton>
          </Player>
          {!isPreviewModeActive && (
            <>
              {currentPostStatus !== POSTS_STATUS.PUBLISHED && (
                <DeleteButton onClick={deleteAudio}>
                  <TrashIcon color={'#CD4F4F'} width={22} height={22} />
                </DeleteButton>
              )}
              <MenuButton onClick={handleClick}>
                <RiMenuLine style={{ color: 'white', fontSize: '20px' }} />
              </MenuButton>
              <CustomMenu
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                options={options}
              />
            </>
          )}
          <CustomMenu
            anchorEl={anchorVelocity}
            setAnchorEl={setAnchorVelocity}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            options={velocityOptions}
          />
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 80px;
  margin-top: 12px;
  gap: 4px;

  @media screen and (max-width: 860px) {
    gap: 0px;
  }
`;

const CircularProgressContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const IconContainer = styled.div`
  height: 100%;
  width: 80px;
  background-color: ${(props) => props.theme.colors.secondary};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 5px;

  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const Player = styled.div`
  height: 100%;
  background-color: ${(props) => props.theme.colors.secondary};
  border-radius: 5px 100px 100px 5px;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  @media screen and (max-width: 860px) {
    border-radius: 10px;
  }
`;

const LeftPlayer = styled.div`
  flex: 1;
  padding-left: 19px;
`;

const Title = styled.p`
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  font-weight: bold;
  font-size: 18px;

  @media screen and (max-width: 860px) {
    font-size: 15px;
    margin-top: 13px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 8px;
`;

const VelocityAndSliderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  margin-top: 8px;
`;

const VelocityButton = styled(ButtonBase)`
  width: 54px;
  height: 24px;
  border: 1px solid white;
  border-radius: 8px;
  margin-right: 16px;

  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const Velocity = styled.p`
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: white;

  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const SliderContainer = styled.div`
  flex: 1;
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media screen and (max-width: 860px) {
    margin-left: 0px;
  }
`;

const CustomSlider = styled(Slider)`
  width: 100%;
`;

const Time = styled.p`
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;

  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const PlayButton = styled(IconButton)`
  height: 60px;
  width: 60px;
  background-color: ${(props) => props.theme.colors.accentBlue};
  margin-right: 11px;
  margin-left: 19px;

  @media screen and (max-width: 860px) {
    margin-left: 8px;
    height: 40px;
    width: 40px;
    border: none;
  }
`;

const MenuButton = styled(ButtonBase)`
  background-color: ${(props) => props.theme.colors.secondary};
  width: 32px;
  height: 32px;
  margin-left: 18px;
  border-radius: 5px;

  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const DeleteButton = styled(ButtonBase)`
  display: none;
  width: 32px;
  height: 32px;
  margin-left: 10px;

  @media screen and (max-width: 860px) {
    display: inline-flex;
  }
`;
