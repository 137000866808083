import { createSlice } from '@reduxjs/toolkit';
import type PublicationInterface from '../../interfaces/Publication';

interface InitialState {
  publications: PublicationInterface[] | null;
  currentPage: number;
  isLoadingMoreData: boolean;
  canLoadMoreData: boolean;
  isDrawerOpen: boolean;
}

const initialState: InitialState = {
  publications: null,
  currentPage: 0,
  isLoadingMoreData: false,
  canLoadMoreData: false,
  isDrawerOpen: false,
};

const entriesPublishedSlice = createSlice({
  name: 'entriesPublished',
  initialState,
  reducers: {
    setEntriesPublishedResetValues: (state, action) => {
      state.publications = null;
      state.currentPage = 0;
      state.isLoadingMoreData = false;
      state.canLoadMoreData = false;
    },
    setEntriesPublished: (state, action) => {
      state.publications = action.payload.publications;
    },
    setEntriesPublishedCurrentPage: (state, action) => {
      state.currentPage = action.payload.currentPage;
    },
    setEntriesPublishedIsLoadingMoreData: (state, action) => {
      state.isLoadingMoreData = action.payload.isLoadingMoreData;
    },
    setEntriesPublishedCanLoadMoreData: (state, action) => {
      state.canLoadMoreData = action.payload.canLoadMoreData;
    },
    setEntriesPublishedIsDrawerOpen: (state, action) => {
      state.isDrawerOpen = action.payload.isDrawerOpen;
    },
  },
});

export const {
  setEntriesPublished,
  setEntriesPublishedCanLoadMoreData,
  setEntriesPublishedCurrentPage,
  setEntriesPublishedIsLoadingMoreData,
  setEntriesPublishedResetValues,
  setEntriesPublishedIsDrawerOpen,
} = entriesPublishedSlice.actions;
export default entriesPublishedSlice.reducer;
