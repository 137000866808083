import { apiClient } from './config';

const config = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

export const uploadImage = async (formData: FormData) => {
  const { data } = await apiClient.post(`multimedia/upload/images`, formData, config);
  return data;
};

export const uploadFiles = async (formData: FormData) => {
  const { data } = await apiClient.post(`multimedia/upload/files`, formData, config);
  return data;
};
