import React from 'react';
import { Drawer, IconButton } from '@mui/material';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { Close } from '@mui/icons-material';
import { type RootState } from '../../context/store';
import { setIsMainDrawerOpen } from '../../context/reducers/layout';
import HomeIcon from '../../icons/HomeIcon';
import PublicationsIcon from '../../icons/PublicationsIcon';
import POSTS_STATUS, { CAMPAIGNS_STATUS } from '../../constants/status';
import MenuDrawerOptionItem from './MenuDrawerOptionItem';
import AuthorIcon from '../../icons/AuthorIcon';
import TagIcon from '../../icons/TagIcon';
import HeaderProfile from './HeaderProfile';
import PresentationIcon from '../../icons/PresentationIcon';
import CampaignsIcon from '../../icons/CampaignsIcon';

const options = [
  {
    title: 'Inicio',
    icon: <HomeIcon color='#183582' />,
    path: '/',
  },
  {
    title: 'Publicaciones',
    icon: <PublicationsIcon color='#183582' />,
    path: '/',
    subOptions: [
      {
        title: 'Borradores',
        path: `/entries?type=${POSTS_STATUS.DRAFT.toLowerCase()}`,
      },
      {
        title: 'Por revisar',
        path: `/entries?type=${POSTS_STATUS.PENDING_REVIEW.toLowerCase()}`,
      },
      {
        title: 'Publicadas',
        path: `/entries?type=${POSTS_STATUS.PUBLISHED.toLowerCase()}`,
      },
    ],
  },
  {
    title: 'PPTs',
    icon: <PresentationIcon />,
    path: '/',
    subOptions: [
      {
        title: 'Borradores',
        path: `/presentations?type=${POSTS_STATUS.DRAFT.toLowerCase()}`,
      },
      {
        title: 'Por revisar',
        path: `/presentations?type=${POSTS_STATUS.PENDING_REVIEW.toLowerCase()}`,
      },
      {
        title: 'Publicadas',
        path: `/presentations?type=${POSTS_STATUS.PUBLISHED.toLowerCase()}`,
      },
    ],
  },
  {
    title: 'Campañas',
    icon: <CampaignsIcon color='#183582' />,
    path: '/',
    subOptions: [
      {
        title: 'Programadas',
        path: `/campaigns?type=${CAMPAIGNS_STATUS.SCHEDULED}`,
      },
      {
        title: 'Completadas',
        path: `/campaigns?type=${CAMPAIGNS_STATUS.PROCESSED}`,
      },
    ],
  },
  {
    title: 'Autores',
    icon: <AuthorIcon color='#183582' />,
    path: '/authors',
  },
  {
    title: 'Etiquetas',
    icon: <TagIcon color='#183582' />,
    path: '/tags',
  },
];

export default function MenuDrawer() {
  const userProfile = useSelector((state: RootState) => state.userProfile.userProfile);
  const isMainDrawerOpen = useSelector((state: RootState) => state.layout.isMainDrawerOpen);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(
      setIsMainDrawerOpen({
        isMainDrawerOpen: false,
      }),
    );
  };

  const getFullName = () => {
    const fullName = userProfile?.fullName;
    return fullName ?? '';
  };

  return (
    <StyledDrawer
      anchor='left'
      ModalProps={{
        keepMounted: true,
      }}
      open={isMainDrawerOpen}
      onClose={handleClose}
    >
      <DrawerContent>
        <Header>
          <IconButton onClick={handleClose}>
            <Close style={{ color: 'black' }} fontSize='large' />
          </IconButton>
        </Header>
        <Row>
          <HeaderProfile parentComponent='menu' />
          <Name>{getFullName()}</Name>
        </Row>
        <div style={{ flex: 1 }}>
          {options.map((opt, index) => (
            <MenuDrawerOptionItem
              key={index}
              title={opt?.title}
              path={opt?.path}
              icon={opt?.icon}
              subOptions={opt?.subOptions}
            />
          ))}
        </div>
      </DrawerContent>
    </StyledDrawer>
  );
}

const StyledDrawer = styled(Drawer)``;

const DrawerContent = styled.div`
  background-color: white;
  width: 100vw;
  padding-top: 20px;
  padding-left: 22px;
  padding-right: 20px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 12px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
`;

const Name = styled.p`
  flex: 1;
  font-size: 16px;
  text-transform: capitalize;
  font-family: ${(props) => props.theme.fonts.semiBold};
  margin-right: 10px;
`;
