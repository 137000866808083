import React from 'react';
import styled from 'styled-components';
import { RiSearchLine } from 'react-icons/ri';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { closeSearchBar } from '../../context/reducers/generalSearch';

export default function GeneralSearchMenuFooter() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = () => {
    if (location.pathname !== '/search') {
      navigate('/search');
      dispatch(closeSearchBar({}));
    }
  };

  return (
    <Container to={'/search'} onClick={handleClick}>
      <RiSearchLine color='#183582' size={20} />
      <Text>Visualizar más resultados de búsqueda</Text>
    </Container>
  );
}

const Container = styled(Link)`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 20px;
  padding-right: 16px;
  :hover {
    background-color: rgba(0, 0, 0, 0.02);
  }
`;

const Text = styled.p`
  font-family: ${(props) => props.theme.fonts.semiBold};
  color: ${(props) => props.theme.colors.primary};
  flex: 1;
  font-size: 14px;
  margin-left: 5px;
  margin-right: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
`;
