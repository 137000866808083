/* eslint-disable @typescript-eslint/prefer-optional-chain */
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { MdUndo, MdRedo } from 'react-icons/md';
import { RiParagraph } from 'react-icons/ri';
import { AiOutlineLine } from 'react-icons/ai';
import BoldIcon from '../../icons/BoldIcon';
import CursiveIcon from '../../icons/CursiveIcon';
import UnderlineIcon from '../../icons/UnderlineIcon';
import AlignLeftIcon from '../../icons/AlignLeftIcon';
import AlignCenterIcon from '../../icons/AlignCenterIcon';
import AlignRightIcon from '../../icons/AlignRightIcon';
import NumbersListIcon from '../../icons/NumbersListIcon';
import BulletListIcon from '../../icons/BulletListIcon';
import BlockquoteIcon from '../../icons/BlockquoteIcon';
import HeadPhones from '../../icons/HeadPhones';
import VideoIcon from '../../icons/VideoIcon';
import CustomMenu from '../globals/CustomMenu';
import {
  toogleAudioDrawer,
  tooglePreviewMode,
  toogleVideoDrawer,
} from '../../context/reducers/toolBar';
import {
  setCurrentSelectedEditorState,
  setUnsavedChanges,
  toggleIsCustomLinkModalVisible,
  toogleEditSomeStyleOfCurrentSelectedEditor,
} from '../../context/reducers/editor';
import { type ToolBarItem } from '../../interfaces/ToolBar';
import ImageOption from './toolBar/ImageOption';
import { type RootState } from '../../context/store';
import {
  type ContentBlock,
  ContentState,
  EditorState,
  RichUtils,
  Modifier,
  convertToRaw,
} from 'draft-js';
import {
  KEY_IDEA_ITEM,
  KEY_PARAGRAPH,
  WYSIWYG_MAIN_SUBTITLE,
  WYSIWYG_MAIN_TITLE,
} from '../../constants/editorKeys';
import { addCustomElement } from '../../utils/wysiwyg';
import LinkOption from './toolBar/LinkOption';
import POSTS_STATUS from '../../constants/status';
import { Button } from '@mui/material';
import EyeIcon from '../../icons/EyeIcon';
import JustifyIcon from '../../icons/JustifyIcon';

interface Props {
  type?: string;
  showToolBar?: boolean;
}

interface CustomIconButtonI {
  isSelected: boolean;
}

export default function ToolBar({ type = 'main', showToolBar }: Props): JSX.Element {
  const isPreviewModeActive = useSelector((state: RootState) => state.toolBar.isPreviewModeActive);
  const currentSelectedEditorState = useSelector(
    (state: RootState) => state.editor.currentSelectedEditorState,
  );
  const currentPostStatus = useSelector((state: RootState) => state.editor.currentPostStatus);
  const selectedEditorKey = useSelector((state: RootState) => state.editor.selectedEditorKey);
  const isThereAnyFocusedEditor = useSelector(
    (state: RootState) => state.editor.isThereAnyFocusedEditor,
  );
  const [anchorTextOptions, setAnchorTextOptions] = useState<null | HTMLElement>(null);
  const [anchorMoreOptions, setAnchorMoreOptions] = useState<null | HTMLElement>(null);
  const dispatch = useDispatch();
  const currentSelectionRef = useRef<any>(null);

  const handleAddBlockquote = () => {
    if (
      currentSelectedEditorState !== null &&
      selectedEditorKey !== WYSIWYG_MAIN_TITLE &&
      selectedEditorKey !== WYSIWYG_MAIN_SUBTITLE &&
      selectedEditorKey !== KEY_IDEA_ITEM &&
      selectedEditorKey !== null
    ) {
      const selectionState = currentSelectedEditorState.getSelection();
      if (!selectionState.isCollapsed()) {
        const currentSyle = currentSelectedEditorState.getCurrentInlineStyle();
        const isBold = currentSyle.has('BOLD');
        let auxEditorState = currentSelectedEditorState;
        if (!isBold) {
          auxEditorState = RichUtils.toggleInlineStyle(currentSelectedEditorState, 'BOLD');
        }
        const newEditorState = RichUtils.toggleBlockType(auxEditorState, 'blockquote');
        const contentState = newEditorState.getCurrentContent();

        const customClass = 'custom-blockquote';
        const contentStateWithCustomStyle = Modifier.applyInlineStyle(
          contentState,
          selectionState,
          customClass,
        );

        const newEditorStateWithCustomStyle = EditorState.push(
          newEditorState,
          contentStateWithCustomStyle,
          'change-inline-style',
        );

        dispatch(
          setCurrentSelectedEditorState({
            currentSelectedEditorState: EditorState.forceSelection(
              newEditorStateWithCustomStyle,
              currentSelectedEditorState.getSelection(),
            ),
          }),
        );
      }
      dispatch(toogleEditSomeStyleOfCurrentSelectedEditor({}));
      dispatch(setUnsavedChanges({ unsavedChanges: true }));
    }
  };

  const handleUndoRedo = (type: string) => {
    if (
      currentSelectedEditorState !== null &&
      selectedEditorKey !== WYSIWYG_MAIN_TITLE &&
      selectedEditorKey !== WYSIWYG_MAIN_SUBTITLE &&
      selectedEditorKey !== KEY_IDEA_ITEM
    ) {
      let newEditorState = null;
      if (type === 'undo') {
        newEditorState = EditorState.undo(currentSelectedEditorState);
      } else {
        newEditorState = EditorState.redo(currentSelectedEditorState);
      }
      dispatch(
        setCurrentSelectedEditorState({
          currentSelectedEditorState: EditorState.forceSelection(
            newEditorState,
            newEditorState.getSelection(),
          ),
        }),
      );
      dispatch(toogleEditSomeStyleOfCurrentSelectedEditor({}));
    }
  };

  const checkIfButtonIsDisabled = (style: string | undefined, id: string | undefined) => {
    if (currentPostStatus === POSTS_STATUS.PUBLISHED || currentPostStatus === null) {
      return true;
    }
    if (
      (currentSelectedEditorState == null ||
        selectedEditorKey == null ||
        selectedEditorKey !== KEY_PARAGRAPH) &&
      (style !== undefined || (id !== 'audio' && id !== 'video'))
    ) {
      return true;
    }
    return false;
  };

  const getBtnColor = (style: string | undefined, id: string | undefined) => {
    return checkIfButtonIsDisabled(style, id) ? 'rgba(0, 0, 0, 0.26)' : '#3C3C3B';
  };

  const tools: ToolBarItem[] = [
    {
      icon: <BoldIcon color={getBtnColor('BOLD', undefined)} />,
      style: 'BOLD',
      action: () => {},
    },
    {
      icon: <CursiveIcon color={getBtnColor('ITALIC', undefined)} />,
      style: 'ITALIC',
      action: () => {},
    },
    {
      icon: <UnderlineIcon color={getBtnColor('UNDERLINE', undefined)} />,
      style: 'UNDERLINE',
      action: () => {},
    },
    {
      icon: <AlignLeftIcon color={getBtnColor('left', undefined)} />,
      style: 'left',
      action: () => {},
    },
    {
      icon: <AlignCenterIcon color={getBtnColor('center', undefined)} />,
      style: 'center',
      action: () => {},
    },
    {
      icon: <AlignRightIcon color={getBtnColor('right', undefined)} />,
      style: 'right',
      action: () => {},
    },
    {
      icon: <JustifyIcon color={getBtnColor('justify', undefined)} />,
      style: 'justify',
      action: () => {},
    },
    {
      icon: <NumbersListIcon color={getBtnColor('ordered-list-item', undefined)} />,
      style: 'ordered-list-item',
      action: () => {},
    },
    {
      icon: <BulletListIcon color={getBtnColor('unordered-list-item', undefined)} />,
      style: 'unordered-list-item',
      action: () => {},
    },
    {
      element: <LinkOption type={type} showToolBar={showToolBar} />,
      id: 'link',
      action: () => {},
    },
    {
      icon: <BlockquoteIcon color={getBtnColor(undefined, 'blockquote')} />,
      id: 'blockquote',
      action: () => {
        handleAddBlockquote();
      },
    },
    {
      element: <ImageOption />,
      id: 'image',
      action: () => {},
    },
    {
      icon: <HeadPhones color={getBtnColor(undefined, 'audio')} />,
      id: 'audio',
      action: () => {
        dispatch(
          toogleAudioDrawer({
            isAudioDrawerOpen: true,
          }),
        );
      },
    },
    {
      icon: <VideoIcon color={getBtnColor(undefined, 'video')} />,
      id: 'video',
      action: () => {
        dispatch(
          toogleVideoDrawer({
            isVideoDrawerOpen: true,
          }),
        );
      },
    },
    {
      icon: <MdUndo />,
      id: 'undo',
      action: () => {
        handleUndoRedo('undo');
      },
    },
    {
      icon: <MdRedo />,
      id: 'redo',
      action: () => {
        handleUndoRedo('redo');
      },
    },
    {
      icon: <BiDotsVerticalRounded />,
      id: 'divider',
      action: (event: React.MouseEvent<HTMLElement>) => {
        setAnchorMoreOptions(event.currentTarget);
      },
    },
  ];

  const textMoreOptions = [
    {
      label: 'Texto normal',
      icon: <RiParagraph style={{ fontSize: '25px' }} />,
      fontSize: '16px',
      action: () => {},
    },
    {
      label: 'Título 1',
      icon: <p>T1</p>,
      fontSize: '32px',
      action: () => {},
    },
    {
      label: 'Título 2',
      icon: <p>T2</p>,
      fontSize: '24px',
      action: () => {},
    },
    {
      label: 'Título 3',
      icon: <p>T3</p>,
      fontSize: '18px',
      action: () => {},
    },
  ];

  const moreOptions = [
    {
      label: 'Línea divisora',
      icon: <AiOutlineLine />,
      action: () => {
        if (
          currentSelectedEditorState !== null &&
          selectedEditorKey !== WYSIWYG_MAIN_TITLE &&
          selectedEditorKey !== WYSIWYG_MAIN_SUBTITLE &&
          selectedEditorKey !== KEY_IDEA_ITEM &&
          selectedEditorKey !== null
        ) {
          const newEditorState = addCustomElement(currentSelectedEditorState, 'custom-divider');
          dispatch(
            setCurrentSelectedEditorState({
              currentSelectedEditorState: EditorState.forceSelection(
                newEditorState,
                currentSelectedEditorState.getSelection(),
              ),
            }),
          );
          dispatch(toogleEditSomeStyleOfCurrentSelectedEditor({}));
          dispatch(setUnsavedChanges({ unsavedChanges: true }));
          setAnchorMoreOptions(null);
        }
      },
    },
  ];

  const handleAlignText = (style: string | undefined): void => {
    if (
      selectedEditorKey !== WYSIWYG_MAIN_TITLE &&
      selectedEditorKey !== WYSIWYG_MAIN_SUBTITLE &&
      selectedEditorKey !== KEY_IDEA_ITEM &&
      selectedEditorKey !== null
    ) {
      const contentState = currentSelectedEditorState.getCurrentContent();
      const selectionState = currentSelectedEditorState.getSelection();
      const startKey = selectionState.getStartKey();
      const endKey = selectionState.getEndKey();
      const blocks = contentState.getBlocksAsArray();
      let startIndex: number, endIndex: number;

      blocks.forEach((block, index) => {
        if (block.getKey() === startKey) {
          startIndex = index;
        }
        if (block.getKey() === endKey) {
          endIndex = index;
        }
      });

      const newBlocks = blocks.map((block, index) => {
        if (index >= startIndex && index <= endIndex) {
          const blockData = block.getData();
          let newData = blockData;
          if (blockData.get('text-align') === style) {
            newData = newData.remove('text-align');
          } else {
            newData = newData.set('text-align', style);
          }
          return block.merge({ data: newData }) as ContentBlock;
        }
        return block;
      });

      const newContentState = ContentState.createFromBlockArray(newBlocks);

      const newEditorState = EditorState.push(
        currentSelectedEditorState,
        newContentState,
        'change-block-data',
      );

      dispatch(
        setCurrentSelectedEditorState({
          currentSelectedEditorState: EditorState.forceSelection(
            newEditorState,
            currentSelectedEditorState.getSelection(),
          ),
        }),
      );
      dispatch(setUnsavedChanges({ unsavedChanges: true }));
    }
  };

  const handleOrderedList = (style: string | undefined): void => {
    if (style !== undefined) {
      const newEditorState = RichUtils.toggleBlockType(currentSelectedEditorState, style);
      dispatch(
        setCurrentSelectedEditorState({
          currentSelectedEditorState: EditorState.forceSelection(
            newEditorState,
            currentSelectedEditorState.getSelection(),
          ),
        }),
      );
      dispatch(setUnsavedChanges({ unsavedChanges: true }));
    }
  };

  const handleToolBarButtonClick = (style: string | undefined): void => {
    if (
      currentSelectedEditorState !== null &&
      style !== undefined &&
      selectedEditorKey !== WYSIWYG_MAIN_TITLE &&
      selectedEditorKey !== WYSIWYG_MAIN_SUBTITLE &&
      selectedEditorKey !== KEY_IDEA_ITEM &&
      selectedEditorKey !== null
    ) {
      if (style === 'center' || style === 'left' || style === 'right' || style === 'justify') {
        handleAlignText(style);
      } else if (style.includes('list')) {
        handleOrderedList(style);
      } else {
        const newEditorState = RichUtils.toggleInlineStyle(currentSelectedEditorState, style);
        dispatch(
          setCurrentSelectedEditorState({
            currentSelectedEditorState: EditorState.forceSelection(
              newEditorState,
              currentSelectedEditorState.getSelection(),
            ),
          }),
        );
        dispatch(setUnsavedChanges({ unsavedChanges: true }));
      }
      dispatch(toogleEditSomeStyleOfCurrentSelectedEditor({}));
    }
  };

  const checkIfStyleIsSelected = (style: string | undefined, id: string | undefined): boolean => {
    if (
      currentSelectedEditorState !== null &&
      style !== undefined &&
      selectedEditorKey !== WYSIWYG_MAIN_TITLE &&
      selectedEditorKey !== WYSIWYG_MAIN_SUBTITLE &&
      selectedEditorKey !== KEY_IDEA_ITEM
    ) {
      if (style === undefined && id === undefined) {
        return false;
      }
      const contentState = currentSelectedEditorState.getCurrentContent();
      const selection = currentSelectedEditorState.getSelection();
      const blockKey = selection.getStartKey();
      const block = contentState.getBlockForKey(blockKey);

      if (block) {
        const startOffset = selection.getStartOffset();
        const endOffset = selection.getEndOffset();
        const selectedStyles = block.getInlineStyleAt(startOffset);
        const alignment = block.getData()?.get('text-align');
        const listStyle = block.getData()?.get('list-style');
        const blockType = block.getType();
        if (style === 'BOLD' || style === 'ITALIC' || style === 'UNDERLINE') {
          return selectedStyles.toArray().includes(style);
        } else if (
          style === 'left' ||
          style === 'center' ||
          style === 'right' ||
          style === 'justify'
        ) {
          return alignment === style;
        } else if (id === 'blockquote') {
          return blockType === id;
        } else {
          return style === blockType;
        }
      }
    }
    return false;
  };

  const handleClickPreviewMode = () => {
    dispatch(
      tooglePreviewMode({
        isPreviewModeActive: !isPreviewModeActive,
      }),
    );
  };

  return (
    <>
      <Container
        style={{
          justifyContent:
            currentPostStatus === POSTS_STATUS.PENDING_REVIEW ||
            currentPostStatus === POSTS_STATUS.REVIEW_IN_PROGRESS
              ? 'space-between'
              : 'center',
        }}
      >
        {currentPostStatus === POSTS_STATUS.PENDING_REVIEW ||
          (currentPostStatus === POSTS_STATUS.REVIEW_IN_PROGRESS && (
            <CustomButton disabled={true}>
              <ButtonText style={{ color: 'transparent' }}>Vista previa de artículo</ButtonText>
            </CustomButton>
          ))}
        <MiddleContainer>
          {tools.map((tool, index) => {
            if (tool.element !== undefined) {
              return <div key={index}>{tool?.element}</div>;
            } else {
              return (
                <CustomIconButton
                  disabled={checkIfButtonIsDisabled(tool.style, tool.id)}
                  isSelected={checkIfStyleIsSelected(tool.style, tool.id)}
                  onClick={(e) => {
                    if (tool.style === undefined) {
                      tool?.action(e);
                    } else {
                      handleToolBarButtonClick(tool?.style);
                    }
                  }}
                  className='h-8 w-8'
                  style={{
                    borderRadius: '3px',
                  }}
                  key={index}
                >
                  {tool.icon}
                </CustomIconButton>
              );
            }
          })}
        </MiddleContainer>
        <CustomMenu
          anchorEl={anchorTextOptions}
          setAnchorEl={setAnchorTextOptions}
          options={textMoreOptions}
        />
        <CustomMenu
          anchorEl={anchorMoreOptions}
          setAnchorEl={setAnchorMoreOptions}
          options={moreOptions}
        />
        {currentPostStatus === POSTS_STATUS.PENDING_REVIEW ||
          (currentPostStatus === POSTS_STATUS.REVIEW_IN_PROGRESS && (
            <CustomButton
              className='px-3'
              startIcon={<EyeIcon width={20} height={12} color={'#033BCF'} />}
              onClick={handleClickPreviewMode}
            >
              <ButtonText>Vista previa de artículo</ButtonText>
            </CustomButton>
          ))}
      </Container>
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: 1px solid ${(props) => props.theme.colors.lightGray};
  border-bottom: 1px solid ${(props) => props.theme.colors.lightGray};
  height: 50px;

  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const MiddleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  height: 100%;
`;

const CustomIconButton = styled(IconButton)<CustomIconButtonI>`
  background-color: ${(props) =>
    props.isSelected ? props.theme.colors.lightSkyBlue : 'transparent'};
`;

const CustomButton = styled(Button)`
  margin-right: 25px;
`;

const ButtonText = styled.p`
  text-transform: none;
  font-size: 14px;
  font-family: ${(props) => props.theme.fonts.semiBold};
  white-space: nowrap;
  color: ${(props) => props.theme.colors.accentBlue};
`;
