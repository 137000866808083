import React from 'react';
import type Icon from '../interfaces/Icon';

export default function CommentsIcon({ width = 36, height = 36, color = 'white' }: Icon) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_2627_2124)'>
        <path
          d='M26.9765 19.4061C26.9765 20.1828 26.3469 20.8124 25.5702 20.8124H24.2108C23.4342 20.8124 22.8046 20.1828 22.8046 19.4061C22.8046 18.6295 23.4342 17.9999 24.2108 17.9999H25.5702C26.3469 17.9999 26.9765 18.6295 26.9765 19.4061ZM19.3125 17.9999H9.70313C8.92646 17.9999 8.29689 18.6295 8.29689 19.4061C8.29689 20.1828 8.92646 20.8124 9.70313 20.8124H19.3125C20.0891 20.8124 20.7187 20.1828 20.7187 19.4061C20.7187 18.6295 20.0891 17.9999 19.3125 17.9999ZM26.2968 9.70307H9.70313C8.92646 9.70307 8.29689 10.3326 8.29689 11.1093C8.29689 11.886 8.92646 12.5156 9.70313 12.5156H26.2968C27.0735 12.5156 27.703 11.886 27.703 11.1093C27.703 10.3326 27.0735 9.70307 26.2968 9.70307ZM35.9998 5.62497V24.8787C35.9998 27.9803 33.4765 30.5037 30.3749 30.5037H15.7817L11.2703 35.0151C9.15828 37.1271 5.5313 35.6144 5.5313 32.638V30.5029C2.47266 30.4529 6.10352e-05 27.9491 6.10352e-05 24.8787V5.62497C6.10352e-05 2.52336 2.52342 0 5.62503 0H30.3749C33.4766 0 35.9998 2.52336 35.9998 5.62497ZM33.1874 5.62497C33.1874 4.07416 31.9257 2.81248 30.3749 2.81248H5.62503C4.07422 2.81248 2.81254 4.07416 2.81254 5.62497V24.8787C2.81254 26.4295 4.07422 27.6912 5.62503 27.6912C7.12415 27.6912 8.34379 28.9109 8.34379 30.41V32.638C8.34379 33.1244 8.93652 33.3715 9.28154 33.0264L13.8204 28.4876C14.334 27.9741 15.0167 27.6913 15.7429 27.6913H30.375C31.9258 27.6913 33.1874 26.4296 33.1874 24.8788V5.62497H33.1874Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_2627_2124'>
          <rect width='36' height='36' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
