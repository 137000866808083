import React from 'react';
import type Campaign from '../../interfaces/Campaigns';
import styled from 'styled-components';
import { Checkbox } from '@mui/material';

export default function ProcessedListHeader() {
  return (
    <Row>
      <LeftContainer>
        <Title>Artículo</Title>
      </LeftContainer>
      <MiddleLabel>Notificación programada</MiddleLabel>
      <RightLabel>Audiencia</RightLabel>
    </Row>
  );
}

const Row = styled.div`
  padding-top: 12px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 20px;
  padding-left: 20px;
  display: grid;
  grid-template-columns: 1.5fr 1fr 0.7fr;

  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`;

const Title = styled.p`
  flex: 1;
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.primary};
  font-size: 16px;
  margin-left: 10px;
`;

const MiddleLabel = styled.p`
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.primary};
`;

const RightLabel = styled.p`
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.primary};
`;
