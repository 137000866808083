import React from 'react';
import ModalContainer from '../globals/ModalContainer';
import styled from 'styled-components';
import { Button, CircularProgress } from '@mui/material';

interface Props {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  message: string;
  sending: boolean;
  setSending: React.Dispatch<React.SetStateAction<boolean>>;
  confirmSendToReview: () => Promise<void>;
}

interface CustomButtonI {
  withBackground: boolean;
  borderColor?: string;
}

interface CustomButtonTextI {
  white: boolean;
}

export default function ConfirmSendToReviewModal({
  title,
  message = '',
  isVisible,
  setIsVisible,
  sending,
  setSending,
  confirmSendToReview,
}: Props) {
  const handleClose = (): void => {
    setIsVisible(false);
  };

  return (
    <ModalContainer isVisible={isVisible} setIsVisible={setIsVisible}>
      <Container>
        <Title>{title}</Title>
        <Message>{message}</Message>
        <ButtonsContainer>
          <CustomButton disabled={sending} onClick={handleClose} withBackground={false}>
            <ButtonText white={false}>Cancelar</ButtonText>
          </CustomButton>
          <CustomButton
            borderColor='#0094CF'
            disabled={sending}
            onClick={confirmSendToReview}
            withBackground={true}
          >
            {sending ? (
              <CircularProgress size={22} style={{ color: 'white' }} />
            ) : (
              <ButtonText white={true}>Confirmar revisión</ButtonText>
            )}
          </CustomButton>
        </ButtonsContainer>
      </Container>
    </ModalContainer>
  );
}

const Container = styled.div``;

const Title = styled.p`
  font-family: ${(props) => props.theme.fonts.semiBold};
  color: ${(props) => props.theme.colors.primary};
  font-size: 20px;
  margin-bottom: 15px;
`;

const Message = styled.p`
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.black};
  margin-bottom: 20px;
  font-size: 19px;
  @media screen and (max-width: 660px) {
    font-size: 16px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
  @media screen and (max-width: 660px) {
    flex-direction: column-reverse;
  }
`;

const CustomButton = styled(Button)<CustomButtonI>`
  &&& {
    border: 1px solid;
    border-radius: 20px;
    background-color: ${(props) =>
      props?.withBackground ? props.theme.colors.primary : 'transparent'};
    @media screen and (max-width: 660px) {
      width: 100%;
    }
  }
`;

const ButtonText = styled.p<CustomButtonTextI>`
  text-transform: none;
  font-family: ${(props) => props.theme.fonts.medium};
  color: ${(props) => (props.white ? 'white' : props.theme.colors.primary)};
  font-size: 14px;
  padding: 0px 15px;
  white-space: nowrap;
`;
