import React from 'react';
import type Icon from '../interfaces/Icon';

export default function NotFoundIcon({ width = 49, height = 48 }: Icon): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 49 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M29 7.5C22.3775 7.5 17 12.8775 17 19.5C17 26.1225 22.3775 31.5 29 31.5C35.6225 31.5 41 26.1225 41 19.5C41 12.8775 35.6225 7.5 29 7.5ZM29 10.5C33.9665 10.5 38 14.5335 38 19.5C38 24.4665 33.9665 28.5 29 28.5C24.0335 28.5 20 24.4665 20 19.5C20 14.5335 24.0335 10.5 29 10.5Z'
        fill='#0F1F41'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.3469 27.7905L3.31737 38.8185C1.56087 40.575 1.56087 43.4265 3.31737 45.183C5.07387 46.9395 7.92537 46.9395 9.68187 45.183L20.7099 34.1535C21.2964 33.5685 21.2964 32.6175 20.7099 32.0325C20.1249 31.4475 19.1739 31.4475 18.5889 32.0325L7.56087 43.0605C6.97437 43.647 6.02488 43.647 5.43988 43.0605C4.85338 42.4755 4.85338 41.526 5.43988 40.9395L16.4679 29.9115C17.0529 29.3265 17.0529 28.3755 16.4679 27.7905C15.8829 27.204 14.9319 27.204 14.3469 27.7905Z'
        fill='#0F1F41'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M30.9397 15.4397L24.9397 21.4397C24.3547 22.0247 24.3547 22.9757 24.9397 23.5607C25.5247 24.1457 26.4757 24.1457 27.0607 23.5607L33.0607 17.5607C33.6457 16.9757 33.6457 16.0247 33.0607 15.4397C32.4757 14.8547 31.5247 14.8547 30.9397 15.4397Z'
        fill='#024EF4'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M33.0607 21.4397L27.0607 15.4397C26.4757 14.8547 25.5247 14.8547 24.9397 15.4397C24.3547 16.0247 24.3547 16.9757 24.9397 17.5607L30.9397 23.5607C31.5247 24.1457 32.4757 24.1457 33.0607 23.5607C33.6457 22.9757 33.6457 22.0247 33.0607 21.4397Z'
        fill='#024EF4'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M29 1.5C19.0655 1.5 11 9.5655 11 19.5C11 29.4345 19.0655 37.5 29 37.5C38.9345 37.5 47 29.4345 47 19.5C47 9.5655 38.9345 1.5 29 1.5ZM29 4.5C37.2785 4.5 44 11.2215 44 19.5C44 27.7785 37.2785 34.5 29 34.5C20.7215 34.5 14 27.7785 14 19.5C14 11.2215 20.7215 4.5 29 4.5Z'
        fill='#024EF4'
      />
    </svg>
  );
}
