import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { CircularProgress, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { motion } from 'framer-motion';
import DrawerHeader from './DrawerHeader';
import AudioPlayer from './AudioPlayer';
import UploadIcon from '../../icons/UploadIcon';
import {
  addElement,
  setElements,
  setPreviewElements,
  setUnsavedChanges,
} from '../../context/reducers/editor';
import { type RootState } from '../../context/store';
import { uploadFiles } from '../../api/files';
import { openSnackbar } from '../../context/reducers/generalSnackbar';
import { FILES_ERRORS } from '../../constants/errorMessages';
import { getFileFormat, isAudioFileFormatValid } from '../../utils/files';
import { AxiosError } from 'axios';

interface Props {
  setIsOpen: (v: boolean) => void;
}

interface ButtonBaseI {
  error: boolean;
}

export default function AudioDrawer({ setIsOpen }: Props): JSX.Element {
  const elements = useSelector((state: RootState) => state.editor.elements);
  const previewElements = useSelector((state: RootState) => state.editor.previewElements);
  const [isMouseOverButton, setIsMouseOverButton] = useState(false);
  const [selectedFile, setSelectedFile] = useState<null | File>(null);
  const [audioSource, setAudioSource] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [uploading, setUploading] = useState(false);
  const dispatch = useDispatch();
  const inputFile = useRef<HTMLInputElement>(null);

  const selectFile = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e?.target?.files != null && e?.target?.files?.length !== 0) {
      const file = e.target.files[0] || null;

      if (e.target.value) {
        e.target.value = '';
      }

      if (!isAudioFileFormatValid(file)) {
        setErrorMessage(
          'El archivo seleccionado es inválido. Solo se permiten archivos con los formatos .mp3, .wav, .ogg y .flac',
        );
      } else if (file && file?.size <= 5 * 1024 * 1024) {
        setSelectedFile(file);
      } else {
        setErrorMessage('El archivo excede el tamaño máximo permitido (Máx 5MB)');
      }
    }
  };

  const openInputFile = (): void => {
    setErrorMessage('');
    inputFile?.current?.click();
  };

  const getButtonText = (): string => {
    if (selectedFile !== null) {
      return selectedFile?.name;
    }
    return 'Subir archivo';
  };

  const callApiToUploadFile = async () => {
    try {
      setUploading(true);
      const formData = new FormData();
      if (selectedFile) {
        formData.append('file', selectedFile);
      }
      const response = await uploadFiles(formData);
      const { location } = response;
      const newElement = {
        key: 'audio',
        element: <AudioPlayer file={selectedFile} audioSource={location} />,
      };
      const newElements = [...elements];
      const newPreviewElements = [...previewElements];
      const index = newElements.findIndex((item) => item.key === newElement.key);
      newElements[index] = newElement;
      newPreviewElements[index] = newElement;
      dispatch(
        setElements({
          elements: newElements,
        }),
      );
      dispatch(
        setPreviewElements({
          previewElements: newPreviewElements,
        }),
      );
      dispatch(
        setUnsavedChanges({
          unsavedChanges: true,
        }),
      );
      setIsOpen(false);
      setUploading(false);
      setSelectedFile(null);
    } catch (error: unknown) {
      let errorStatus;
      if (error instanceof AxiosError) {
        errorStatus = error?.response?.status;
      }
      if (errorStatus !== 401) {
        dispatch(
          openSnackbar({
            type: 'error',
            message: FILES_ERRORS.AUDIO,
          }),
        );
        setUploading(false);
      }
    }
  };

  const handleClickSaveButton = (): void => {
    if (selectedFile == null) {
      setErrorMessage('Selecciona un archivo de audio');
    } else {
      callApiToUploadFile();
    }
  };

  return (
    <Container>
      <DrawerHeader
        title='Añadir Audio'
        subtitle='Añade un audio para este artículo. Los usuarios sabrán cuando un contenido tiene esta opción.'
        setIsOpen={setIsOpen}
      />
      <Content>
        <input
          type='file'
          onChange={selectFile}
          ref={inputFile}
          style={{ display: 'none' }}
          accept='.mp3, .wav, .ogg, .flac'
        />
        <Card
          onMouseOver={() => {
            setIsMouseOverButton(true);
          }}
          onMouseLeave={() => {
            setIsMouseOverButton(false);
          }}
        >
          <Title>Audio</Title>
          <Row>
            <CustomButtonBase onClick={openInputFile} error={errorMessage !== ''}>
              {selectedFile !== null ? (
                <CheckCircleOutlineIcon
                  style={{ color: errorMessage !== '' ? '#CD4F4F' : '#033BCF' }}
                />
              ) : (
                <UploadIcon color={errorMessage !== '' ? '#CD4F4F' : '#033BCF'} />
              )}
              <ButtonText error={errorMessage !== ''}>{getButtonText()}</ButtonText>
            </CustomButtonBase>
            {isMouseOverButton && selectedFile !== null && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.1 }}
                exit={{ opacity: 0 }}
              >
                <DeleteButton
                  onClick={() => {
                    setSelectedFile(null);
                    setErrorMessage('');
                  }}
                >
                  <CloseIcon style={{ color: 'white', fontSize: '14px' }} />
                </DeleteButton>
              </motion.div>
            )}
          </Row>
          {errorMessage !== '' && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </Card>
      </Content>
      <Footer>
        <SaveButton onClick={handleClickSaveButton} disabled={uploading}>
          {uploading ? (
            <CircularProgress style={{ color: 'white' }} size={20} />
          ) : (
            <SaveBtnText>Guardar</SaveBtnText>
          )}
        </SaveButton>
      </Footer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Content = styled.div`
  flex: 1;
`;

const Card = styled.div`
  margin: 12px 20px;
  border: 1px solid ${(props) => props.theme.colors.lightSkyBlue};
  border-radius: 5px;
  padding-top: 21px;
  padding-left: 13px;
  padding-bottom: 28px;
`;

const ErrorMessage = styled.p`
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.red};
  font-size: 10px;
  margin-top: 8px;
`;

const Title = styled.p`
  font-family: ${(props) => props.theme.fonts.bold};
  color: ${(props) => props.theme.colors.darkGray};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
`;

const CustomButtonBase = styled(ButtonBase)<ButtonBaseI>`
  height: 40px;
  border: 1px solid
    ${(props) => (props.error ? props.theme.colors.red : props.theme.colors.lightSkyBlue)};
  padding: 0px 14px;
  margin-top: 8px;
  width: calc(100% - 40px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: 3px;
`;

const DeleteButton = styled(IconButton)`
  &&& {
    background-color: ${(props) => props.theme.colors.red};
  }
  height: 18px;
  width: 18px;
`;

const ButtonText = styled.p<ButtonBaseI>`
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => (props.error ? props.theme.colors.red : props.theme.colors.accentBlue)};
  margin-left: 7px;
`;

const Footer = styled.footer`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 17px 20px;
  border: 1px solid ${(props) => props.theme.colors.gray2};
`;

const SaveButton = styled(Button)`
  &&& {
    background-color: ${(props) => props.theme.colors.primary};
  }
  padding: 8px 40px;
  border-radius: 5px;
  width: 90px;
  height: 40px;
`;

const SaveBtnText = styled.p`
  font-family: ${(props) => props.theme.fonts.bold};
  text-transform: none;
  color: white;
`;
