import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import ProfileInformation from '../components/authors/authorDetails/ProfileInformation';
import AdditionalInformation from '../components/authors/authorDetails/AdditionalInformation';
import CustomDivider from '../components/globals/CustomDivider';
import type AuthorDetailsInterface from '../interfaces/AuthorDetails';
import PageDetailsHeader from '../components/globals/PageDetailsHeader';
import { getAuthor, updateAuthor, createAuthor } from '../api/authors';
import { type FormErrorI } from '../interfaces/AuthorDetails';
import { validationEmail } from '../utils/validationEmail';
import { useDispatch, useSelector } from 'react-redux';
import { openSnackbar } from '../context/reducers/generalSnackbar';
import { AUTHORS_ERRORS, DEFAULT_ERROR_MESSAGE } from '../constants/errorMessages';
import { AxiosError } from 'axios';
import { setAuthorUpdated, setNewAuthorAdded } from '../context/reducers/authors';
import { type RootState } from '../context/store';
import { Button } from '@mui/material';

interface CustomButtonI {
  withBackground: boolean;
  unsavedChanges: boolean;
}

interface CustomButtonTextI {
  white: boolean;
}

const defaultFormError: FormErrorI = {
  names: false,
  namesMessage: '',
  job: false,
  jobMessage: '',
  organization: false,
  organizationMessage: '',
  email: false,
  emailMessage: '',
  phone: false,
  phoneMessage: '',
};

export default function AuthorDetails(): JSX.Element {
  const navigate = useNavigate();
  const { authorId } = useParams();
  const newAuthorAdded = useSelector((state: RootState) => state.authors.newAuthorAdded);
  const [author, setAuthor] = useState<AuthorDetailsInterface | null>(null);
  const [formData, setFormData] = useState<AuthorDetailsInterface>({
    id: null,
    names: '',
    job: '',
    organization: '',
    email: '',
    phone: '',
  });
  const [formError, setFormError] = useState<FormErrorI>(defaultFormError);
  const [loading, setLoading] = useState<boolean>(false);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const dispatch = useDispatch();
  const isMounted = useRef<boolean>(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (authorId !== undefined) {
      getAuthor(parseInt(authorId))
        .then((res: AuthorDetailsInterface) => {
          setAuthor(res);
        })
        .catch((error: unknown) => {
          let errorStatus;
          if (error instanceof AxiosError) {
            errorStatus = error?.response?.status;
          }
          if (errorStatus !== 401) {
            dispatch(
              openSnackbar({
                type: 'error',
                message: DEFAULT_ERROR_MESSAGE,
              }),
            );
          }
        });
    }
  }, []);

  useEffect(() => {
    if (author !== null) {
      setFormData({
        id: author?.id,
        names: author?.names || '',
        job: author?.job || '',
        organization: author?.organization || '',
        email: author?.email || '',
        phone: author?.phone || '',
      });
    }
  }, [author]);

  const callApiToUpdateAuthor = (authorInformation: AuthorDetailsInterface): void => {
    if (authorId !== undefined) {
      updateAuthor(parseInt(authorId), authorInformation)
        .then((res) => {
          if (isMounted.current) {
            dispatch(
              openSnackbar({
                type: 'success',
                message: 'Información del autor actualizada correctamente',
              }),
            );
            dispatch(
              setAuthorUpdated({
                authorUpdated: true,
              }),
            );
            navigate(-1);
          }
        })
        .catch((error: unknown) => {
          let errorStatus;
          if (error instanceof AxiosError) {
            errorStatus = error?.response?.status;
          }
          if (errorStatus !== 401) {
            if (isMounted.current) {
              dispatch(
                openSnackbar({
                  type: 'error',
                  message: AUTHORS_ERRORS.UPDATE_AUTHOR,
                }),
              );
              setLoading(false);
            }
          }
        });
    }
  };

  const callApiToCreateAuthor = (authorInformation: AuthorDetailsInterface): void => {
    createAuthor(authorInformation)
      .then((res) => {
        if (isMounted.current) {
          dispatch(
            openSnackbar({
              type: 'success',
              message: 'Autor creado correctamente',
            }),
          );
          dispatch(
            setNewAuthorAdded({
              newAuthorAdded: true,
            }),
          );
          navigate(-1);
        }
      })
      .catch((error: unknown) => {
        let errorStatus;
        let errorMessage = AUTHORS_ERRORS.CREATE_AUTHOR;
        if (error instanceof AxiosError) {
          errorStatus = error?.response?.status;
          const errorData = error?.response?.data;
          const errorCode = errorData?.errorCode;
          errorMessage =
            errorCode === 'AUTHOR_EMAIL_ALREADY_IN_USE'
              ? errorData?.message
              : AUTHORS_ERRORS.CREATE_AUTHOR;
        }
        if (isMounted.current && errorStatus !== 401) {
          dispatch(
            openSnackbar({
              type: 'error',
              message: errorMessage,
            }),
          );
          setLoading(false);
        }
      });
  };

  const handleClickSave = (): void => {
    const errors: any = {};
    if (formData.names === '') {
      errors.names = true;
      errors.namesMessage = 'El nombre es obligatorio';
    } else if (formData.job === '') {
      errors.job = true;
      errors.jobMessage = 'El cargo es obligatorio';
    } else if (formData.organization === '') {
      errors.organization = true;
      errors.organizationMessage = 'La organización es obligatoria';
    } else if (formData.email === '') {
      errors.email = true;
      errors.emailMessage = 'El correo es obligatorio';
    } else if (!validationEmail(formData.email)) {
      errors.email = true;
      errors.emailMessage = 'Ingresa un correo válido';
    } else if (formData.phone === '') {
      errors.phone = true;
      errors.phoneMessage = 'El teléfono es obligatorio';
    } else {
      setLoading(true);
      const authorInformation: AuthorDetailsInterface = { ...formData };
      if (authorId === undefined) {
        callApiToCreateAuthor(authorInformation);
      } else {
        callApiToUpdateAuthor(authorInformation);
      }
    }
    setFormError(errors);
  };

  const getTitle = (): string => {
    if (authorId === undefined) {
      return 'Nuevo perfil de Autor';
    }
    if (author?.names) {
      return `Perfil de Autor de ${author?.names}`;
    }
    return `Perfil del Autor`;
  };

  if (authorId !== undefined && author == null) {
    return (
      <CircularProgressContainer>
        <CircularProgress size={50} />
      </CircularProgressContainer>
    );
  }

  return (
    <Container>
      <PageDetailsHeader
        title={getTitle()}
        subtitle='Esta información es visible para los lectores y acompañará las entradas publicadas'
        handleClickSave={handleClickSave}
        loading={loading}
        unsavedChanges={unsavedChanges}
        setUnsavedChanges={setUnsavedChanges}
      />
      <ProfileInformation
        formData={formData}
        setFormData={setFormData}
        formError={formError}
        setFormError={setFormError}
        unsavedChanges={unsavedChanges}
        setUnsavedChanges={setUnsavedChanges}
      />
      <DividerContainer>
        <CustomDivider marginTop={33} marginBottom={24} />
      </DividerContainer>
      <AdditionalInformation
        formData={formData}
        setFormData={setFormData}
        formError={formError}
        setFormError={setFormError}
        unsavedChanges={unsavedChanges}
        setUnsavedChanges={setUnsavedChanges}
      />
      <DividerContainer>
        <CustomDivider marginTop={27} />
      </DividerContainer>
      <CustomButtonContainer>
        <CustomButton
          unsavedChanges={unsavedChanges}
          disabled={loading || !unsavedChanges}
          onClick={handleClickSave}
          withBackground={true}
          className='rounded-2xl'
        >
          {loading ? (
            <CircularProgress style={{ color: 'white' }} size={20} />
          ) : (
            <ButtonText white={true}>Guardar</ButtonText>
          )}
        </CustomButton>
      </CustomButtonContainer>
    </Container>
  );
}

const CircularProgressContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 72px);
  padding-right: 24px;
  margin-left: 192px;
  margin-top: 72px;
  width: calc(100vw - 192px);
  padding-bottom: 50px;

  @media screen and (max-width: 860px) {
    width: 100vw;
    margin-left: 0px;
    padding-right: 24px;
  }
`;

const Container = styled.section`
  padding-right: 24px;
  margin-left: 192px;
  margin-top: 72px;
  width: calc(100vw - 192px);
  padding-bottom: 50px;

  @media screen and (max-width: 860px) {
    width: 100vw;
    margin-left: 0px;
    padding-right: 24px;
  }
`;

const DividerContainer = styled.div`
  padding-left: 24px;
  margin-top: 33px;
`;

const CustomButtonContainer = styled.div`
  display: none;
  position: fixed;
  z-index: 999;
  bottom: 10px;
  background-color: white;
  width: 100vw;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 860px) {
    display: flex;
  }
`;

const CustomButton = styled(Button)<CustomButtonI>`
  &&& {
    border: 1px solid;
    border-color: ${(props) =>
      !props?.unsavedChanges ? 'transparent' : props.theme.colors.primary};
    border-radius: 5px;
    background-color: ${(props) =>
      !props?.unsavedChanges
        ? props.theme.colors.btnPrimaryDisabled
        : props?.withBackground
        ? props.theme.colors.primary
        : 'transparent'};
    width: calc(100% - 48px);
    height: 48px;
  }
`;

const ButtonText = styled.p<CustomButtonTextI>`
  text-transform: none;
  font-family: ${(props) => props.theme.fonts.medium};
  color: ${(props) => (props.white ? 'white' : props.theme.colors.primary)};
  font-size: 17px;
  padding: 0px 15px;
`;
