import { createSlice } from '@reduxjs/toolkit';

interface InitialState {
  userProfile: any;
}

const initialState: InitialState = {
  userProfile: null,
};

const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {
    setUserProfile: (state, action) => {
      state.userProfile = action.payload.userProfile;
    },
  },
});

export const { setUserProfile } = userProfileSlice.actions;
export default userProfileSlice.reducer;
