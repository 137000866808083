import { apiClient } from './config';

export const getAllStats = async (from: string) => {
  const { data } = await apiClient.get(`stats/all?from=${from}`);
  return data;
};

export const getPublicationsStats = async (publicationsIDs: number[]) => {
  const { data } = await apiClient.get(`stats/of?publications=${publicationsIDs}`);
  return data;
};
