import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import axios, { AxiosError } from 'axios';
import Filters from '../../components/entries/Filters';
import type DraftInterface from '../../interfaces/Draft';
import DraftItem from './DraftItem';
import ListingIcon from '../../icons/ListingIcon';
import POSTS_STATUS from '../../constants/status';
import { getPosts } from '../../api/posts';
import { PAGE_SIZE } from '../../constants/pagination';
import { HOME_ERRORS } from '../../constants/errorMessages';
import { openSnackbar } from '../../context/reducers/generalSnackbar';
import PublicationSkeleton from '../home/PublicationSkeleton';
import { type RootState } from '../../context/store';
import DraftPlaceholderIcon from '../../icons/DraftPlaceholderIcon';
import { checkIfSomeFilterIsSelected } from '../../utils/entries';
import NotFoundIcon from '../../icons/NotFoundIcon';
import { type AuthorItem } from '../../interfaces/AuthorDetails';
import customDebounce from '../../utils/debounce';
import {
  setDraftPostAdded,
  setDraftPostUpdated,
  setDraftsQuantity,
} from '../../context/reducers/entries';
import {
  setEntriesDrafts,
  setEntriesDraftsCurrentPage,
  setEntriesDraftsIsLoadingMoreData,
  setEntriesDraftsCanLoadMoreData,
  setEntriesDraftsResetValues,
} from '../../context/reducers/entriesDrafts';
import FiltersButton from '../globals/FiltersButton';
import TitleWithFiltersButton from '../globals/TitleWithFiltersButton';
import FiltersDrawer from './FiltersDrawer';
import SearchBar from './filtersDrawer/SearchBar';
import { HTML_TEXT } from '../../constants/globals';

export default function DraftsList(): JSX.Element {
  const drafts = useSelector((state: RootState) => state.entriesDrafts.drafts);
  const draftsCurrentPage = useSelector((state: RootState) => state.entriesDrafts.currentPage);
  const draftsIsLoadingMoreData = useSelector(
    (state: RootState) => state.entriesDrafts.isLoadingMoreData,
  );
  const draftsCanLoadMoreData = useSelector(
    (state: RootState) => state.entriesDrafts.canLoadMoreData,
  );
  const draftPostAdded = useSelector((state: RootState) => state.entries.draftPostAdded);
  const draftPostUpdated = useSelector((state: RootState) => state.entries.draftPostUpdated);
  const draftsQuantity = useSelector((state: RootState) => state.entries.draftsQuantity);
  const isDrawerOpen = useSelector((state: RootState) => state.entriesDrafts.isDrawerOpen);
  const [searchParams, setSearchParams] = useSearchParams();
  const [paramType, setParamType] = useState(POSTS_STATUS.DRAFT);
  const [selectAll, setSelectAll] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [resource, setResource] = useState<string | null>(null);
  const [selectedAuthors, setSelectedAuthors] = useState<any[]>([]);
  const [categoriesIDs, setCategoriesIDs] = useState<string[]>([]);
  const isMounted = useRef(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getAuthorsIDs = () => {
    return selectedAuthors.map((item: AuthorItem) => item.authorId);
  };

  const getAudioParam = () => {
    return resource == null ? undefined : resource === 'AUDIO';
  };

  useEffect(() => {
    setParamType(searchParams.get('type')?.toUpperCase() ?? POSTS_STATUS.DRAFT);
  }, [searchParams]);

  useEffect(() => {
    setSearchInput('');
    setResource(null);
    setSelectedAuthors([]);
    setCategoriesIDs([]);
  }, [paramType]);

  const callApiToGetDrafts = (value: string, wantToCancel: boolean, cleanSearch = false): void => {
    const newCurrentPage =
      draftsCurrentPage > 0
        ? draftsCanLoadMoreData
          ? draftsCurrentPage
          : draftsCurrentPage + 1
        : 0;
    const auxValue = value.trim()?.length >= 2 ? value?.trim() : '';
    if (
      draftPostAdded ||
      draftPostUpdated ||
      auxValue?.length > 0 ||
      cleanSearch ||
      categoriesIDs?.length > 0 ||
      selectedAuthors?.length > 0 ||
      getAudioParam() !== undefined
    ) {
      getPosts(
        auxValue,
        [POSTS_STATUS.DRAFT],
        PAGE_SIZE,
        0,
        'lastModifiedDate,DESC',
        categoriesIDs,
        getAuthorsIDs(),
        getAudioParam(),
        HTML_TEXT,
        wantToCancel,
      )
        .then((res) => {
          isMounted.current = true;
          dispatch(
            setEntriesDrafts({
              drafts: res.content,
            }),
          );
          dispatch(
            setDraftsQuantity({
              draftsQuantity: res.totalElements,
            }),
          );
          if (!res.last) {
            dispatch(
              setEntriesDraftsCurrentPage({
                currentPage: 1,
              }),
            );
            dispatch(
              setEntriesDraftsCanLoadMoreData({
                canLoadMoreData: true,
              }),
            );
          } else {
            dispatch(
              setEntriesDraftsCurrentPage({
                currentPage: 0,
              }),
            );
            dispatch(
              setEntriesDraftsCanLoadMoreData({
                canLoadMoreData: false,
              }),
            );
          }
          dispatch(
            setDraftPostAdded({
              draftPostAdded: false,
            }),
          );
          dispatch(
            setDraftPostUpdated({
              draftPostUpdated: false,
            }),
          );
        })
        .catch((error: unknown) => {
          let errorStatus;
          if (error instanceof AxiosError) {
            errorStatus = error?.response?.status;
          }
          if (!axios.isCancel(error) && errorStatus !== 401) {
            dispatch(
              openSnackbar({
                type: 'error',
                message: HOME_ERRORS.DRAFTS_LIST,
              }),
            );
          }
        });
    } else {
      getPosts(
        auxValue,
        [POSTS_STATUS.DRAFT],
        PAGE_SIZE,
        newCurrentPage,
        'lastModifiedDate,DESC',
        categoriesIDs,
        getAuthorsIDs(),
        getAudioParam(),
        HTML_TEXT,
        wantToCancel,
      )
        .then((res) => {
          isMounted.current = true;
          if (drafts === null || newCurrentPage === 0) {
            dispatch(
              setEntriesDrafts({
                drafts: res.content,
              }),
            );
          } else {
            dispatch(
              setEntriesDrafts({
                drafts: [...drafts, ...res.content],
              }),
            );
          }
          dispatch(
            setDraftsQuantity({
              draftsQuantity: res.totalElements,
            }),
          );
          if (!res.last) {
            dispatch(
              setEntriesDraftsCurrentPage({
                currentPage: newCurrentPage + 1,
              }),
            );
            dispatch(
              setEntriesDraftsCanLoadMoreData({
                canLoadMoreData: true,
              }),
            );
          } else {
            dispatch(
              setEntriesDraftsCanLoadMoreData({
                canLoadMoreData: false,
              }),
            );
          }
        })
        .catch((error: unknown) => {
          let errorStatus;
          if (error instanceof AxiosError) {
            errorStatus = error?.response?.status;
          }
          if (!axios.isCancel(error) && errorStatus !== 401) {
            dispatch(
              openSnackbar({
                type: 'error',
                message: HOME_ERRORS.DRAFTS_LIST,
              }),
            );
          }
        });
    }
  };

  useEffect(() => {
    callApiToGetDrafts(searchInput, false);
  }, [categoriesIDs, selectedAuthors, resource]);

  const loadMoreData = async () => {
    if (draftsCanLoadMoreData && !draftsIsLoadingMoreData && drafts !== null) {
      dispatch(
        setEntriesDraftsIsLoadingMoreData({
          isLoadingMoreData: true,
        }),
      );
      try {
        const response = await getPosts(
          searchInput || '',
          [POSTS_STATUS.DRAFT],
          PAGE_SIZE,
          draftsCurrentPage,
          'lastModifiedDate,DESC',
          categoriesIDs,
          getAuthorsIDs(),
          getAudioParam(),
          HTML_TEXT,
        );
        const { last, content } = response;
        if (!last) {
          dispatch(
            setEntriesDraftsCurrentPage({
              currentPage: draftsCurrentPage + 1,
            }),
          );
          dispatch(
            setEntriesDraftsCanLoadMoreData({
              canLoadMoreData: true,
            }),
          );
        } else {
          dispatch(
            setEntriesDraftsCanLoadMoreData({
              canLoadMoreData: false,
            }),
          );
        }
        dispatch(
          setEntriesDraftsIsLoadingMoreData({
            isLoadingMoreData: false,
          }),
        );
        if (drafts != null) {
          dispatch(
            setEntriesDrafts({
              drafts: [...drafts, ...content],
            }),
          );
        }
      } catch (error: unknown) {
        let errorStatus;
        if (error instanceof AxiosError) {
          errorStatus = error?.response?.status;
        }
        if (errorStatus !== 401) {
          dispatch(
            openSnackbar({
              type: 'error',
              message: HOME_ERRORS.DRAFTS_LIST,
            }),
          );
        }
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
      const margin = 400;
      if (
        scrollTop + clientHeight + margin >= scrollHeight &&
        paramType.toUpperCase() === POSTS_STATUS.DRAFT
      ) {
        loadMoreData();
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [draftsCurrentPage, draftsCanLoadMoreData, draftsIsLoadingMoreData, paramType, searchInput]);

  const handleNewPost = () => {
    navigate('/new-post', {
      state: {
        prevRouteName: location.pathname,
      },
    });
  };

  const handleInputChange = (value: string): void => {
    setSearchInput(value);
    const debounceSearch = customDebounce(callApiToGetDrafts, 500);
    if (value?.length >= 2) {
      debounceSearch(value, true);
    } else {
      debounceSearch(value, true, true);
    }
  };

  return (
    <Container>
      {paramType.toUpperCase() === POSTS_STATUS.DRAFT && (
        <>
          <Filters
            selectAll={selectAll}
            setSelectAll={setSelectAll}
            resource={resource}
            setResource={setResource}
            authorsIDs={selectedAuthors}
            setAuthorsIDs={setSelectedAuthors}
            categoriesIDs={categoriesIDs}
            setCategoriesIDs={setCategoriesIDs}
            handleInputChange={handleInputChange}
          />
          <FiltersDrawer
            isOpen={isDrawerOpen}
            type={POSTS_STATUS.DRAFT}
            resource={resource}
            setResource={setResource}
            authorsIDs={selectedAuthors}
            setAuthorsIDs={setSelectedAuthors}
            categoriesIDs={categoriesIDs}
            setCategoriesIDs={setCategoriesIDs}
          />
          <TitleWithFiltersButton
            title='Borradores'
            quantity={draftsQuantity}
            type={POSTS_STATUS.DRAFT}
          />
          <SearchBar title={'Borradores'} handleInputChange={handleInputChange} />
          {drafts === null ? (
            <SkeletonsContainer>
              <PublicationSkeleton />
              <PublicationSkeleton />
              <PublicationSkeleton />
              <PublicationSkeleton />
              <PublicationSkeleton />
            </SkeletonsContainer>
          ) : drafts?.length === 0 ? (
            <MessageContainer>
              {checkIfSomeFilterIsSelected(
                resource,
                selectedAuthors,
                categoriesIDs,
                searchInput,
              ) ? (
                <>
                  <div style={{ marginBottom: '15px' }}>
                    <NotFoundIcon />
                  </div>
                  <Message style={{ maxWidth: '280px' }}>
                    ¡Lo sentimos! No encontramos ningún artículo relacionado a tu búsqueda, prueba
                    con otra palabra.
                  </Message>
                </>
              ) : (
                <>
                  <div style={{ marginBottom: '15px' }}>
                    <DraftPlaceholderIcon width={30} height={30} />
                  </div>
                  <Message>Aún no tienes borradores.</Message>
                  <Message>¿Te encuentras inspirado?</Message>
                  <CustomButton className='bg-primary px-8 rounded-2xl' onClick={handleNewPost}>
                    <ButtonText>Empezar un artículo nuevo</ButtonText>
                  </CustomButton>
                </>
              )}
            </MessageContainer>
          ) : (
            <>
              {drafts?.map((draft, index) => (
                <DraftItem key={`${draft.id}`} {...draft} drafts={drafts} />
              ))}
              {draftsCanLoadMoreData && (
                <SkeletonsContainer>
                  <PublicationSkeleton />
                  <PublicationSkeleton />
                  <PublicationSkeleton />
                  <PublicationSkeleton />
                  <PublicationSkeleton />
                </SkeletonsContainer>
              )}
            </>
          )}
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  flex: 1;

  @media screen and (max-width: 860px) {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding-top: 100px;
  height: calc(100vh - 420px);
`;

const Message = styled.p`
  font-family: ${(props) => props.theme.fonts.medium};
  color: ${(props) => props.theme.colors.black};
  font-size: 16px;
  text-align: center;
  margin-bottom: 3px;
`;

const CustomButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.skyBlue};
  border-radius: 8px;
  margin-top: 20px;
`;

const ButtonText = styled.p`
  text-transform: none;
  font-family: ${(props) => props.theme.fonts.regular};
  color: white;
  font-size: 13px;
`;

const SkeletonsContainer = styled.div`
  padding-top: 15px;
  padding-left: 29px;
  padding-right: 20px;
  @media screen and (max-width: 860px) {
    padding: 15px 0px;
  }
`;
