import React from 'react';

export default function PDFIcon() {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16.0041 8.98552H15.9394V5.056C15.9394 4.55512 15.7443 4.08426 15.3902 3.73012L13.2093 1.54922C12.8551 1.19504 12.3842 1 11.8834 1H5.19789C4.16393 1 3.32279 1.84114 3.32279 2.8751V8.98552H3.25804C2.56436 8.98552 2 9.54988 2 10.2436V14.4225C2 15.1162 2.56436 15.6806 3.25804 15.6806H3.32276V16.1249C3.32276 17.1589 4.1639 18 5.19785 18H14.0643C15.0982 18 15.9394 17.1589 15.9394 16.1249V15.6806H16.0041C16.6978 15.6806 17.2621 15.1162 17.2621 14.4225V10.2436C17.2621 9.54991 16.6978 8.98552 16.0041 8.98552ZM12.9185 2.66688L14.3721 4.12042H13.0299C12.9685 4.12042 12.9185 4.07045 12.9185 4.00909V2.66688ZM4.31863 2.8751C4.31863 2.39026 4.71302 1.99587 5.19785 1.99587H11.8834C11.8965 1.99587 11.9096 1.99627 11.9227 1.99683V4.00906C11.9227 4.6196 12.4194 5.11626 13.0299 5.11626H14.9435V8.98552H4.31863V2.8751ZM14.9435 16.125C14.9435 16.6099 14.5491 17.0042 14.0643 17.0042H5.19785C4.71305 17.0042 4.31863 16.6099 4.31863 16.125V15.6807H14.9435V16.125H14.9435ZM16.2663 14.4226C16.2663 14.5672 16.1487 14.6848 16.0041 14.6848H15.4418C15.4417 14.6848 15.4416 14.6848 15.4414 14.6848C15.4413 14.6848 15.4412 14.6848 15.4411 14.6848H3.82071H3.82038H3.25804C3.11348 14.6848 2.99584 14.5672 2.99584 14.4226V10.2436C2.99584 10.0991 3.11344 9.98145 3.25801 9.98145H16.0041C16.1486 9.98145 16.2663 10.0991 16.2663 10.2436L16.2663 14.4226Z'
        fill='#9A9A9A'
      />
      <path
        d='M7.87576 10.7597C7.65579 10.5704 7.3632 10.4757 6.99826 10.4757H5.93911C5.85039 10.4757 5.77873 10.4996 5.72418 10.5474C5.66956 10.5952 5.64233 10.6515 5.64233 10.7162V13.9705C5.64233 14.049 5.68151 14.1095 5.76001 14.1521C5.8384 14.1948 5.93223 14.2161 6.04144 14.2161C6.15055 14.2161 6.24435 14.1948 6.32284 14.1521C6.40126 14.1095 6.44051 14.049 6.44051 13.9705V12.8653H6.94706C7.32568 12.8653 7.63012 12.769 7.86039 12.5762C8.09065 12.3835 8.20577 12.0842 8.20577 11.6782V11.6526C8.2058 11.2467 8.09577 10.9491 7.87576 10.7597ZM7.40759 11.7396C7.40759 12.0808 7.25406 12.2513 6.94706 12.2513H6.44054V11.1716H6.9471C7.25409 11.1716 7.40763 11.3423 7.40763 11.6833L7.40759 11.7396Z'
        fill='#9A9A9A'
      />
      <path
        d='M10.7232 10.7674C10.4946 10.573 10.1893 10.4757 9.80731 10.4757H8.90165C8.79932 10.4757 8.71568 10.4996 8.65093 10.5474C8.58608 10.5952 8.55371 10.6515 8.55371 10.7162V13.9755C8.55371 14.0404 8.58612 14.0967 8.65093 14.1444C8.71571 14.1922 8.79932 14.216 8.90165 14.216H9.80731C10.1893 14.216 10.4946 14.1188 10.7232 13.9244C10.9517 13.7299 11.066 13.4247 11.066 13.0085V11.6833C11.0659 11.2672 10.9516 10.9619 10.7232 10.7674ZM10.2678 13.0085C10.2678 13.3497 10.1143 13.5202 9.80727 13.5202H9.35189V11.1716H9.80727C10.1143 11.1716 10.2678 11.3423 10.2678 11.6833V13.0085Z'
        fill='#9A9A9A'
      />
      <path
        d='M13.875 10.5781C13.834 10.5099 13.7743 10.4758 13.6959 10.4758H11.9204C11.8249 10.4758 11.743 10.4972 11.6748 10.5398C11.6065 10.5825 11.5725 10.643 11.5725 10.7215V13.9706C11.5725 14.049 11.6117 14.1096 11.6902 14.1522C11.7686 14.1949 11.8624 14.2162 11.9716 14.2162C12.0807 14.2162 12.1745 14.1949 12.253 14.1522C12.3314 14.1096 12.3707 14.0491 12.3707 13.9706V12.6504H13.1433C13.2217 12.6504 13.2815 12.6197 13.3224 12.5583C13.3633 12.4969 13.3838 12.4271 13.3838 12.3485C13.3838 12.2633 13.3625 12.1899 13.3198 12.1285C13.2771 12.0671 13.2183 12.0364 13.1433 12.0364H12.3707V11.1717H13.6959C13.7709 11.1717 13.8297 11.1376 13.8724 11.0694C13.915 11.0011 13.9364 10.9176 13.9364 10.8187C13.9364 10.7265 13.9159 10.6464 13.875 10.5781Z'
        fill='#9A9A9A'
      />
    </svg>
  );
}
