/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import React, {
  useEffect,
  useRef,
  forwardRef,
  type ForwardedRef,
  type MutableRefObject,
} from 'react';
import { type ClickOutside } from '../../interfaces/ClickOutside';

const ClickOutsideDetector = forwardRef(
  (props: ClickOutside, ref: ForwardedRef<HTMLDivElement>) => {
    const { children, listen, wrapperId, onClickOutside, isGeneralSearchBar = false } = props;
    const container = ref || useRef<HTMLDivElement>(null);
    const onKeyUp = (e: KeyboardEvent): void => {
      if (e.code === 'Escape') onClickOutside();
    };

    const handleEvent = (e: KeyboardEvent | MouseEvent | TouchEvent): void => {
      const generalSearchBar = document.getElementById('general-searchbar');
      if (
        (container as MutableRefObject<HTMLDivElement>).current.contains(e.target as Node) ||
        (isGeneralSearchBar &&
          generalSearchBar &&
          (e.target as HTMLElement).id === 'general-searchbar')
      ) {
        return;
      }
      const wrapperElm = document.getElementById(wrapperId);
      // if (wrapperElm && wrapperElm.contains(e.target as Node)) return;
      // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
      if (wrapperElm && wrapperElm.contains(e.target as Node)) {
        onClickOutside();
      }
    };

    useEffect(() => {
      if (listen) {
        document.addEventListener('mousedown', handleEvent, false);
        document.addEventListener('touchend', handleEvent, false);
        document.addEventListener('keyup', onKeyUp, false);
      }

      return () => {
        document.removeEventListener('mousedown', handleEvent, false);
        document.removeEventListener('touchend', handleEvent, false);
        document.removeEventListener('keyup', onKeyUp, false);
      };
    });

    return <div ref={container}>{children}</div>;
  },
);

export default ClickOutsideDetector;

// For debugging
ClickOutsideDetector.displayName = 'ClickOutsideDetector';
