const WYSIWYG_MAIN_TITLE = 'wysiwyg-main-title';
const WYSIWYG_MAIN_SUBTITLE = 'wysiwyg-main-subtitle';
const KEY_IDEAS = 'key-ideas';
const KEY_IDEA_ITEM = 'key-idea-item';
const KEY_PARAGRAPH = 'main-paragraph';
const HEADER_IMAGE = 'main-image';
const EDITOR_NOTES = 'editor-notes';
const POST_THUMBNAIL = 'post-thumbnail';
const PDF_URL = 'pdf-url';

export {
  WYSIWYG_MAIN_TITLE,
  WYSIWYG_MAIN_SUBTITLE,
  KEY_IDEAS,
  KEY_IDEA_ITEM,
  KEY_PARAGRAPH,
  HEADER_IMAGE,
  EDITOR_NOTES,
  POST_THUMBNAIL,
  PDF_URL,
};
