import React from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PublicationIcon from '../../icons/PublicationIcon';
import ResultStatusTagItem from './ResultStatusTagItem';
import POSTS_STATUS from '../../constants/status';
import type Publication from '../../interfaces/Publication';
import capitalizeFirstLetter from '../../utils/capitalizeFirstLetter';
import { closeSearchBar, setInputOfGeneralSearch } from '../../context/reducers/generalSearch';
import { checkIfUserHasRole } from '../../utils/checkIfUserHasRole';
import { ROLE_EDITOR } from '../../constants/roles';
import { type RootState } from '../../context/store';
import { HTML_TEXT } from '../../constants/globals';
import PDFIcon from '../../icons/PDFIcon';

interface Props {
  post: Publication;
}

interface ContainerI {
  blocked: boolean;
}

export default function ResultItem({ post }: Props) {
  const userProfile = useSelector((state: RootState) => state.userProfile.userProfile);
  const { title, status, id, editor, contentType } = post;
  const location = useLocation();
  const dispatch = useDispatch();

  const getTitle = () => {
    if (!title) return 'Sin título';
    return title;
  };

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (
      !checkIfUserHasRole(userProfile?.roles, ROLE_EDITOR) &&
      (status === POSTS_STATUS.PENDING_REVIEW || status === POSTS_STATUS.REVIEW_IN_PROGRESS)
    ) {
      event.preventDefault();
    } else {
      dispatch(closeSearchBar({}));
      dispatch(
        setInputOfGeneralSearch({
          searchInput: '',
        }),
      );
    }
  };

  const checkIfUserIsTheEditorOfThePost = (): boolean => {
    if (editor !== null && editor !== undefined) {
      const username = editor?.username;
      if (username == null) return true;
      if (username === userProfile?.username) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  };

  return (
    <Container
      target={
        location.pathname.startsWith('/post/') ||
        location.pathname.startsWith('/new-post') ||
        location.pathname.startsWith('/presentation/') ||
        location.pathname.startsWith('/new-presentation')
          ? '_blank'
          : '_self'
      }
      to={`${contentType === HTML_TEXT ? '/post/' : '/presentation/'}${id}`}
      onClick={handleClick}
      blocked={
        !checkIfUserHasRole(userProfile?.roles, ROLE_EDITOR) &&
        (status === POSTS_STATUS.PENDING_REVIEW || status === POSTS_STATUS.REVIEW_IN_PROGRESS)
      }
      state={{ prevRouteName: location.pathname }}
    >
      {contentType === HTML_TEXT ? <PublicationIcon /> : <PDFIcon />}
      <Title>{capitalizeFirstLetter(getTitle())}</Title>
      <ResultStatusTagItem status={status as string} />
    </Container>
  );
}

const Container = styled(Link)<ContainerI>`
  cursor: ${(props) => (props.blocked ? 'not-allowed' : 'pointer')};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 20px;
  padding-right: 16px;
  :hover {
    background-color: rgba(0, 0, 0, 0.02);
  }
`;

const Title = styled.p`
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.drakGray};
  flex: 1;
  font-size: 14px;
  margin-left: 8px;
  margin-right: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
`;
