import { apiClient } from './config';
import type TagDetails from '../interfaces/TagDetails';

export const getTags = async (q: string, pageSize: number, page: number) => {
  const { data } = await apiClient.get(
    `common/info/tags/find/all?q=${q}&page=${page}&size=${pageSize}&sort=tag,ASC`,
  );
  return data;
};

export const createTag = async (tag: string) => {
  const payload = { tag };
  const { data } = await apiClient.post(`common/info/tags`, payload);
  return data;
};

export const deleteTag = async (tagId: number) => {
  const { data } = await apiClient.delete(`common/info/tags/${tagId}`);
  return data;
};
