export const formatNumber = (number: number): string => {
  if (number === null || number === undefined) return '';
  if (number < 1000) {
    return number.toString();
  } else {
    const parts = number.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  }
};
