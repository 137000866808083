import React, { type FocusEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { RiSearchLine } from 'react-icons/ri';
import { useSelector, useDispatch } from 'react-redux';
import { type RootState } from '../../context/store';
import {
  closeSearchBar,
  openSearchBar,
  setGeneralSearchPosts,
  setGeneralSearchPostsCanLoadMoreData,
  setGeneralSearchPostsCurrentPage,
  setGeneralSearchPostsTotal,
  setInputOfGeneralSearch,
} from '../../context/reducers/generalSearch';
import GeneralSearchMenu from '../globals/GeneralSearchMenu';
import POSTS_STATUS from '../../constants/status';
import { getPosts } from '../../api/posts';
import { openSnackbar } from '../../context/reducers/generalSnackbar';
import { POST_ERRORS } from '../../constants/errorMessages';
import { useLocation, useNavigate } from 'react-router-dom';
import { PAGE_SIZE } from '../../constants/pagination';
import customDebounce from '../../utils/debounce';
import axios, { AxiosError } from 'axios';
import { HTML_TEXT } from '../../constants/globals';

export default function SearchBar(): JSX.Element {
  const generalSearchbarIsVisible = useSelector(
    (state: RootState) => state.generalSearch.isVisible,
  );
  const isVisible = useSelector((state: RootState) => state.generalSearch.isVisible);
  const searchInput = useSelector((state: RootState) => state.generalSearch.searchInput);
  const [posts, setPosts] = useState<any[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (searchInput?.length > 0 && location.pathname !== '/search') {
      dispatch(openSearchBar({}));
    }
  }, [searchInput]);

  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (searchInput?.length > 0 && location.pathname !== '/search') {
      dispatch(openSearchBar({}));
    }
  };

  const callApiToGetPosts = async (): Promise<void> => {
    try {
      const res = await getPosts(
        searchInput.trim() || '',
        [
          POSTS_STATUS.DRAFT,
          POSTS_STATUS.PENDING_REVIEW,
          POSTS_STATUS.REVIEW_IN_PROGRESS,
          POSTS_STATUS.PUBLISHED,
        ],
        4,
        0,
        '',
        [],
        [],
        undefined,
        undefined,
        true,
      );
      setPosts(res.content);
    } catch (error: unknown) {
      let errorStatus;
      if (error instanceof AxiosError) {
        errorStatus = error?.response?.status;
      }
      if (!axios.isCancel(error) && errorStatus !== 401) {
        dispatch(
          openSnackbar({
            type: 'error',
            message: POST_ERRORS.SEARCH_GENERAL,
          }),
        );
      }
    }
  };

  const globalCallApiToGetPosts = async (): Promise<void> => {
    try {
      const res = await getPosts(
        searchInput.trim() || '',
        [
          POSTS_STATUS.DRAFT,
          POSTS_STATUS.PENDING_REVIEW,
          POSTS_STATUS.REVIEW_IN_PROGRESS,
          POSTS_STATUS.PUBLISHED,
        ],
        PAGE_SIZE,
        0,
        '',
        [],
        [],
        undefined,
        undefined,
        true,
      );
      dispatch(
        setGeneralSearchPosts({
          generalSearchPosts: res.content,
        }),
      );
      dispatch(
        setGeneralSearchPostsTotal({
          generalSearchPostsTotal: res.totalElements,
        }),
      );
      if (!res.last) {
        dispatch(
          setGeneralSearchPostsCurrentPage({
            generalSearchPostsCurrentPage: 1,
          }),
        );
        dispatch(
          setGeneralSearchPostsCanLoadMoreData({
            generalSearchPostsCanLoadMoreData: true,
          }),
        );
      } else {
        dispatch(
          setGeneralSearchPostsCurrentPage({
            generalSearchPostsCurrentPage: 0,
          }),
        );
        dispatch(
          setGeneralSearchPostsCanLoadMoreData({
            generalSearchPostsCanLoadMoreData: false,
          }),
        );
      }
    } catch (error: unknown) {
      let errorStatus;
      if (error instanceof AxiosError) {
        errorStatus = error?.response?.status;
      }
      if (!axios.isCancel(error) && errorStatus !== 401) {
        dispatch(
          openSnackbar({
            type: 'error',
            message: POST_ERRORS.SEARCH_GENERAL,
          }),
        );
      }
    }
  };

  useEffect(() => {
    if (generalSearchbarIsVisible) {
      if (location.pathname !== '/search') {
        const debounceSearch = customDebounce(callApiToGetPosts, 500);
        debounceSearch();
      } else {
        const debounceSearch = customDebounce(globalCallApiToGetPosts, 300);
        debounceSearch();
      }
    }
  }, [searchInput, generalSearchbarIsVisible]);

  const handleChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    dispatch(
      setInputOfGeneralSearch({
        searchInput: event.currentTarget?.value ?? '',
      }),
    );
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && location.pathname !== '/search') {
      navigate('/search');
      dispatch(closeSearchBar({}));
    }
  };

  return (
    <Container>
      <InputContainer>
        <RiSearchLine color='#183582' size={25} />
        <Input
          id='general-searchbar'
          value={searchInput}
          placeholder='Buscar publicaciones'
          size={1}
          onChange={handleChange}
          onFocus={handleFocus}
          onKeyDown={handleKeyDown}
          autoComplete='off'
        />
      </InputContainer>
      {generalSearchbarIsVisible && <GeneralSearchMenu posts={posts} searchInput={searchInput} />}
    </Container>
  );
}

const Container = styled.div`
  flex: 1;
  padding-left: 28px;
  @media screen and (max-width: 860px) {
    padding-left: 0px;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border: 1.4px solid;
  border-color: ${(props) => props.theme.colors.primary};
  height: 36px;
  border-radius: 20px;
  padding-left: 12px;
  max-width: 500px;
  background-color: white;
  @media screen and (max-width: 860px) {
    max-width: 100vw;
    height: 45px;
  }
`;

const Input = styled.input`
  height: 100%;
  outline: none;
  border: none;
  border-radius: 20px;
  flex: 1;
  margin-left: 10px;
  background-color: transparent;
  height: 100%;
  font-size: 15px;
  font-family: ${(props) => props.theme.fonts.medium};
  color: ${(props) => props.theme.colors.primary};

  ::placeholder {
    color: ${(props) => props.theme.colors.primary};
    font-size: 15px;
  }
`;
