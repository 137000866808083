import { createSlice } from '@reduxjs/toolkit';
import type PublicationInterface from '../../interfaces/Publication';
import type PublicationStats from '../../interfaces/PublicationStats';

interface InitialState {
  publications: PublicationInterface[] | null;
  currentPage: 0;
  publicationsTotal: 0;
  isLoadingMoreData: boolean;
  canLoadMoreData: boolean;
  publicationsStats: PublicationStats[] | null;
}

const initialState: InitialState = {
  publications: null,
  currentPage: 0,
  publicationsTotal: 0,
  canLoadMoreData: false,
  isLoadingMoreData: false,
  publicationsStats: null,
};

const publicationsSlice = createSlice({
  name: 'publications',
  initialState,
  reducers: {
    setPublications: (state, actions) => {
      state.publications = actions.payload.publications;
    },
    setPublicationsTotal: (state, action) => {
      state.publicationsTotal = action.payload.publicationsTotal;
    },
    setPublicationsCurrentPage: (state, action) => {
      state.currentPage = action.payload.currentPage;
    },
    setPublicationsCanLoadMoreData: (state, action) => {
      state.canLoadMoreData = action.payload.canLoadMoreData;
    },
    setPublicationsIsLoadingMoreData: (state, action) => {
      state.isLoadingMoreData = action.payload.isLoadingMoreData;
    },
    setPublicationsStats: (state, action) => {
      state.publicationsStats = action.payload.publicationsStats;
    },
  },
});

export const {
  setPublications,
  setPublicationsCanLoadMoreData,
  setPublicationsCurrentPage,
  setPublicationsIsLoadingMoreData,
  setPublicationsTotal,
  setPublicationsStats,
} = publicationsSlice.actions;
export default publicationsSlice.reducer;
