import React from 'react';
import ModalContainer from './ModalContainer';
import styled, { css } from 'styled-components';
import { Button, CircularProgress } from '@mui/material';
import { HTML_TEXT } from '../../constants/globals';

interface Props {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  publishing: boolean;
  setPublishing: React.Dispatch<React.SetStateAction<boolean>>;
  callApiToPublishAPost: (overwritePriorPublication: boolean) => void;
  contentType?: string | null;
}

interface CustomButtonI {
  withBackground: boolean;
  borderColor?: string;
  customType?: string;
}

interface CustomButtonTextI {
  white: boolean;
}

export default function ConfirmPublicationModal({
  isVisible,
  setIsVisible,
  publishing,
  setPublishing,
  callApiToPublishAPost,
  contentType = HTML_TEXT,
}: Props) {
  const handleCancel = () => {
    setIsVisible(false);
  };

  const handlePublish = () => {
    callApiToPublishAPost(true);
  };

  return (
    <ModalContainer isVisible={isVisible} setIsVisible={setIsVisible}>
      <Container>
        <Title>
          {contentType === HTML_TEXT ? 'Esta entrada' : 'Este PPT'} ya ha sido publicada
          anteriormente.{' '}
        </Title>
        <Message>¿Estás seguro de que deseas publicar una nueva versión y sobrescribirla?</Message>
        <ButtonsContainer>
          <CustomButton disabled={publishing} onClick={handleCancel} withBackground={false}>
            <ButtonText white={false}>Cancelar</ButtonText>
          </CustomButton>
          <CustomButton
            borderColor='#0094CF'
            disabled={publishing}
            onClick={handlePublish}
            withBackground={true}
          >
            {publishing ? (
              <CircularProgress size={22} style={{ color: 'white' }} />
            ) : (
              <ButtonText white={true}>Sí, publicar</ButtonText>
            )}
          </CustomButton>
        </ButtonsContainer>
      </Container>
    </ModalContainer>
  );
}

const Container = styled.div``;

const Title = styled.p`
  font-family: ${(props) => props.theme.fonts.semiBold};
  color: ${(props) => props.theme.colors.primary};
  font-size: 20px;
  margin-bottom: 15px;
`;

const Message = styled.p`
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.black};
  margin-bottom: 20px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
  @media screen and (max-width: 660px) {
    flex-direction: column-reverse;
  }
`;

const CustomButton = styled(Button)<CustomButtonI>`
  &&& {
    border: 1px solid;
    border-radius: 20px;
    background-color: ${(props) =>
      props?.withBackground ? props.theme.colors.primary : 'transparent'};
    ${(props) =>
      props.customType === 'confirm' &&
      css`
        width: 160px;
      `}
    ${(props) =>
      props.customType === 'cancel' &&
      css`
        width: 80px;
      `}
    @media screen and (max-width: 660px) {
      width: 100%;
    }
  }
`;

const ButtonText = styled.p<CustomButtonTextI>`
  text-transform: none;
  font-family: ${(props) => props.theme.fonts.medium};
  color: ${(props) => (props.white ? 'white' : props.theme.colors.primary)};
  font-size: 13px;
  padding: 0px 15px;
`;
