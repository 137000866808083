import React from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import type DraftInterface from '../../interfaces/Draft';
import WithoutImageIcon from '../../icons/WithoutImageIcon';
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
} from 'date-fns';
import { formatLessThan10 } from '../../utils/formatLessThan10';
import getLastModifiedDate from '../../utils/getLastModifiedDate';
import capitalizeFirstLetter from '../../utils/capitalizeFirstLetter';
import PublicationIcon from '../../icons/PublicationIcon';
import AudioIcon from '../../icons/AudioIcon';
import { getLastPublicationDate } from '../../utils/getLastPublicationDate';
import { useSelector } from 'react-redux';
import { type RootState } from '../../context/store';
import { HTML_TEXT } from '../../constants/globals';
import PDFIcon from '../../icons/PDFIcon';

export default function DraftItem({
  id,
  title,
  lastModifiedDate,
  images,
  hasAudio,
  createdDate,
  categories,
  contentType,
}: DraftInterface): JSX.Element {
  const categoriesList = useSelector((state: RootState) => state.categories.categoriesList);
  const location = useLocation();
  const navigate = useNavigate();
  const basePath = `${contentType === HTML_TEXT ? '/post/' : '/presentation/'}`;

  const handleClickEdit = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    navigate(`${basePath}${id}`, {
      state: { prevRouteName: location.pathname },
    });
  };

  const getTitle = (): string => {
    return title || 'Sin título';
  };

  const getCategories = (): any[] => {
    if (categories === null || categories?.length === 0) {
      return [];
    }
    if (categoriesList !== null) {
      const categoriesNames: any[] = [];
      const auxCategoriesList: any[] = [...categoriesList];
      categories?.forEach((category) => {
        auxCategoriesList?.forEach((auxCategory) => {
          if (category === auxCategory?.id) {
            categoriesNames.push({
              name: auxCategory?.name,
              color: auxCategory?.color,
            });
          }
        });
      });
      return categoriesNames;
    }
    return [];
  };

  return (
    <Container to={`${basePath}${id}`} state={{ prevRouteName: location.pathname }}>
      <Row>
        <AuxDateContainer>
          <DateContainer>
            <DateText>{getLastPublicationDate(createdDate)}</DateText>
          </DateContainer>
          <MobileIconsContainer>
            {contentType === HTML_TEXT ? (
              <>
                <PublicationIcon />
                {hasAudio && <AudioIcon />}
              </>
            ) : (
              <PDFIcon />
            )}
          </MobileIconsContainer>
        </AuxDateContainer>
        <ImageContainer>
          {images?.principalImage ? (
            <Image src={images?.principalImage} alt={title} />
          ) : (
            <WithoutImageIcon />
          )}
        </ImageContainer>
        <InfoContainer>
          <Title>{capitalizeFirstLetter(getTitle())}</Title>
          <LastEdition>{getLastModifiedDate(lastModifiedDate)}</LastEdition>
        </InfoContainer>
        <ButtonsContainer>
          <Button onClick={handleClickEdit} className='bg-primary-blue px-5 rounded-2xl'>
            <ButtonText>Editar</ButtonText>
          </Button>
        </ButtonsContainer>
      </Row>
      <MobileContainerFooter>
        <MobileCategories>
          {getCategories().map((category, index) => (
            <CategoryContainer style={{ backgroundColor: category?.color }} key={index}>
              <Category key={index} style={{ color: 'white' }}>
                {category.name}
              </Category>
            </CategoryContainer>
          ))}
        </MobileCategories>
        <Footer>
          <MobileDate>{getLastModifiedDate(lastModifiedDate)}</MobileDate>
          <Button onClick={handleClickEdit} className='bg-primary-blue px-8 py-2 rounded-2xl mr-2'>
            <ButtonText>Editar</ButtonText>
          </Button>
        </Footer>
      </MobileContainerFooter>
    </Container>
  );
}

const Container = styled(Link)`
  position: relative;
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  height: 94px;
  border: 1px solid ${(props) => props.theme.colors.lightSkyBlue};
  border-radius: 8px;
  padding-left: 16px;
  margin-bottom: 10px;
  padding-right: 23px;
  transition: background-color 0.1s ease-out;

  :hover {
    background-color: rgba(0, 0, 0, 0.02);
  }

  @media screen and (max-width: 860px) {
    display: block;
    height: inherit;
    padding-top: 15px;
    padding-right: 10px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 94px;
  width: 100%;

  @media screen and (max-width: 860px) {
    height: inherit;
    align-items: flex-start;
  }
`;

const AuxDateContainer = styled.div`
  display: none;
  position: absolute;
  left: 0px;
  z-index: 100;
  top: 9px;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  @media screen and (max-width: 860px) {
    display: flex;
  }
`;

const DateContainer = styled.div`
  background-color: ${(props) => props.theme.colors.primary};
  min-width: 94px;
  padding: 1px 8px;
  border-radius: 0px 6px 6px 0px;
`;

const DateText = styled.p`
  font-family: ${(props) => props.theme.fonts.semiBold};
  color: white;
  font-size: 14px;
`;

const MobileIconsContainer = styled.div`
  display: none;
  @media screen and (max-width: 860px) {
    display: inline-flex;
    gap: 4px;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  height: 72px;
  width: 72px;
  border-radius: 10px;
  overflow: hidden;
  background-color: ${(props) => props.theme.colors.lightSkyBlue};

  @media screen and (max-width: 860px) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90px;
  }
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const InfoContainer = styled.div`
  flex: 1;
  padding-left: 13px;
  padding-right: 10px;

  @media screen and (max-width: 860px) {
    padding-top: 20px;
  }
`;

const ButtonsContainer = styled.div`
  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const Title = styled.p`
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.darkGray};
  font-size: 17px;
  line-height: 21px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;

  @media screen and (max-width: 860px) {
    -webkit-line-clamp: 3;
    line-clamp: 3;
  }
`;

const LastEdition = styled.p`
  font-family: ${(props) => props.theme.fonts.regular};
  font-size: 14px;
  color: ${(props) => props.theme.colors.gray2};

  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const ButtonText = styled.p`
  font-family: ${(props) => props.theme.fonts.medium};
  color: white;
  text-transform: none;
  font-size: 13px;
`;

const MobileContainerFooter = styled.div`
  display: none;
  @media screen and (max-width: 860px) {
    display: block;
  }
`;

const MobileCategories = styled.div`
  display: none;
  @media screen and (max-width: 860px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    margin-top: 10px;
  }
`;

const CategoryContainer = styled.div`
  padding: 2px 10px;
  border-radius: 11px;
`;

const Category = styled.span`
  font-family: ${(props) => props.theme.fonts.medium};
  color: ${(props) => props.theme.colors.skyBlue};
  font-size: 13px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const Footer = styled.footer`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 10px;
`;

const MobileDate = styled.p`
  font-family: ${(props) => props.theme.fonts.semiBold};
  color: ${(props) => props.theme.colors.accentBlue};
  font-size: 13px;
  flex: 1;
`;
