import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, MenuItem } from '@mui/material';
import { EditorState } from 'draft-js';
import styled from 'styled-components';
import ImageIcon from '../../../icons/ImageIcon';
import {
  addElement,
  setAddedNewElement,
  setCurrentResizableImageURL,
  setCurrentSelectedEditorState,
  setUnsavedChanges,
  toogleEditSomeStyleOfCurrentSelectedEditor,
} from '../../../context/reducers/editor';
import ResizableImage from '../ResizableImage';
import { type RootState } from '../../../context/store';
import {
  KEY_IDEA_ITEM,
  KEY_PARAGRAPH,
  WYSIWYG_MAIN_SUBTITLE,
  WYSIWYG_MAIN_TITLE,
} from '../../../constants/editorKeys';
import { addCustomElement } from '../../../utils/wysiwyg';
import POSTS_STATUS from '../../../constants/status';
import { openSnackbar } from '../../../context/reducers/generalSnackbar';
import { FILES_ERRORS } from '../../../constants/errorMessages';

interface Props {
  type?: string;
}

export default function ImageOption({ type = 'desktop' }: Props): JSX.Element {
  const currentSelectedEditorState = useSelector(
    (state: RootState) => state.editor.currentSelectedEditorState,
  );
  const currentPostStatus = useSelector((state: RootState) => state.editor.currentPostStatus);
  const selectedEditorKey = useSelector((state: RootState) => state.editor.selectedEditorKey);
  const elements = useSelector((state: RootState) => state.editor.elements);
  const [uploading, setUploading] = useState(false);
  const dispatch = useDispatch();
  const inputFile = useRef<HTMLInputElement>(null);

  const selectFile = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e?.target?.files != null && e?.target?.files?.length !== 0) {
      const file = e.target.files[0] || null;

      if (e.target.value) {
        e.target.value = '';
      }

      if (file && file?.size <= 5 * 1024 * 1024) {
        if (
          currentSelectedEditorState !== null &&
          selectedEditorKey !== WYSIWYG_MAIN_TITLE &&
          selectedEditorKey !== WYSIWYG_MAIN_SUBTITLE &&
          selectedEditorKey !== KEY_IDEA_ITEM
        ) {
          const newEditorState = addCustomElement(currentSelectedEditorState, 'custom-image', file);
          dispatch(
            setCurrentSelectedEditorState({
              currentSelectedEditorState: EditorState.forceSelection(
                newEditorState,
                currentSelectedEditorState.getSelection(),
              ),
            }),
          );
          dispatch(toogleEditSomeStyleOfCurrentSelectedEditor({}));
          dispatch(setUnsavedChanges({ unsavedChanges: true }));
        }
      } else {
        dispatch(
          openSnackbar({
            type: 'error',
            message: FILES_ERRORS.IMAGE_MAX_SIZE,
          }),
        );
      }
    }
  };

  const handleClick = (): void => {
    inputFile?.current?.click();
  };

  const checkIfButtonIsDisabled = (): boolean => {
    if (
      currentSelectedEditorState == null ||
      selectedEditorKey == null ||
      selectedEditorKey !== KEY_PARAGRAPH
    ) {
      return true;
    }
    return false;
  };

  const getBtnColor = () => {
    return checkIfButtonIsDisabled() ? 'rgba(0, 0, 0, 0.26)' : '#3C3C3B';
  };

  return (
    <>
      <input
        type='file'
        onChange={selectFile}
        ref={inputFile}
        style={{ display: 'none' }}
        accept='.jpg, .jpeg, .bmp, .png'
      />
      {type === 'desktop' ? (
        <IconButton
          disabled={checkIfButtonIsDisabled()}
          onClick={handleClick}
          className='h-8 w-8'
          style={{ borderRadius: '3px' }}
        >
          <ImageIcon color={checkIfButtonIsDisabled() ? 'rgba(0, 0, 0, 0.26)' : '#3C3C3B'} />
        </IconButton>
      ) : (
        <StyledMenuItem onClick={handleClick} disabled={checkIfButtonIsDisabled()}>
          <IconContainer>
            <ImageIcon color={checkIfButtonIsDisabled() ? 'rgba(0, 0, 0, 0.26)' : '#3C3C3B'} />
          </IconContainer>
          <Label style={{ color: getBtnColor() }}>Imagen</Label>
        </StyledMenuItem>
      )}
    </>
  );
}

const StyledMenuItem = styled(MenuItem)`
  padding: 10px 20px;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 8px;
`;

const Label = styled.p`
  font-size: 15px;
  font-family: ${(props) => props.theme.fonts.medium};
  margin-left: 9px;
`;
