import { Drawer, IconButton, TextareaAutosize } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Close } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import CommentsIcon from '../../icons/CommentsIcon';
import { type RootState } from '../../context/store';
import { EDITOR_NOTES } from '../../constants/editorKeys';
import { setElementsContent, setUnsavedChanges } from '../../context/reducers/editor';
import POSTS_STATUS from '../../constants/status';
import { checkIfPostHasEditorNotes } from '../../utils/wysiwyg';

interface Props {
  currentStatus: string | null;
}

interface ContainerI {
  isPreviewModeActive: boolean;
}

export default function EditorNotesModal({ currentStatus }: Props) {
  const elementsContent = useSelector((state: RootState) => state.editor.elementsContent);
  const isPreviewModeActive = useSelector((state: RootState) => state.toolBar.isPreviewModeActive);
  const [showModal, setShowModal] = useState(false);
  const [editorNotes, setEditorNotes] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    const content =
      elementsContent[elementsContent.findIndex((item) => item.customKey === EDITOR_NOTES)].content;
    setEditorNotes(typeof content === 'string' ? content : '');
  }, []);

  useEffect(() => {
    if (editorNotes) {
      dispatch(
        setElementsContent({
          customKey: EDITOR_NOTES,
          content: editorNotes.trim(),
        }),
      );
    }
  }, [editorNotes]);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(
      setUnsavedChanges({
        unsavedChanges: true,
      }),
    );
    setEditorNotes(e.target.value);
    if (editorNotes === '') {
      dispatch(
        setElementsContent({
          customKey: EDITOR_NOTES,
          content: '',
        }),
      );
    }
  };

  return (
    <>
      {!isPreviewModeActive &&
        (checkIfPostHasEditorNotes(elementsContent) ||
          currentStatus === POSTS_STATUS.REVIEW_IN_PROGRESS) && (
          <CustomIconButton onClick={openModal} id='editor-words-counter'>
            <CommentsIcon color='white' width={28} height={28} />
          </CustomIconButton>
        )}
      <StyledDrawer
        anchor='bottom'
        ModalProps={{
          keepMounted: true,
        }}
        PaperProps={{
          style: {
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
          },
        }}
        open={showModal}
        onClose={closeModal}
      >
        <DrawerContent>
          <Header>
            <Title>Comentarios de editor</Title>
            <IconButton onClick={closeModal}>
              <Close />
            </IconButton>
          </Header>
          {currentStatus === POSTS_STATUS.REVIEW_IN_PROGRESS ? (
            <Container isPreviewModeActive={isPreviewModeActive}>
              <TextArea
                disabled={
                  currentStatus == null || currentStatus !== POSTS_STATUS.REVIEW_IN_PROGRESS
                }
                value={editorNotes}
                onChange={handleChange}
                style={{ resize: 'none' }}
                placeholder='Escribe aquí...'
              />
            </Container>
          ) : (
            checkIfPostHasEditorNotes(elementsContent) &&
            currentStatus !== POSTS_STATUS.PUBLISHED && (
              <Container isPreviewModeActive={isPreviewModeActive}>
                <TextArea
                  disabled={
                    currentStatus == null || currentStatus !== POSTS_STATUS.REVIEW_IN_PROGRESS
                  }
                  value={editorNotes}
                  onChange={handleChange}
                  style={{ resize: 'none' }}
                  placeholder='Escribe aquí...'
                />
              </Container>
            )
          )}
        </DrawerContent>
      </StyledDrawer>
    </>
  );
}

const CustomIconButton = styled.div`
  display: none;
  cursor: pointer;
  position: fixed;
  bottom: 80px;
  right: 20px;
  height: 70px;
  width: 70px;
  border-radius: 100%;
  background-color: ${(props) => props.theme.colors.accentBlue};
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  z-index: 999;

  @media screen and (max-width: 860px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const StyledDrawer = styled(Drawer)``;

const DrawerContent = styled.div`
  background-color: white;
  width: 100vw;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 22px;
  padding-right: 20px;
  padding-bottom: 15px;
`;

const Title = styled.p`
  flex: 1;
  font-size: 18px;
  font-family: ${(props) => props.theme.fonts.semiBold};
`;

const Container = styled.div<ContainerI>`
  padding: 0px 20px;
`;

const TextArea = styled(TextareaAutosize)`
  min-height: 120px;
  width: 100%;
  padding: 20px 10px;
  border-radius: 15px;
  background-color: transparent;
  outline: none;
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.accentBlue};
  font-size: 18px;
  border: 1px solid #f0f6fa;

  ::placeholder {
    color: ${(props) => props.theme.colors.accentBlue};
  }
`;
