export const base = {
  colors: {
    primary: '#183582',
    secondary: '#283B76',
    accentBlue: '#033BCF',
    disabledPrimary: '#83BDD5',
    matizBlue: '#F0F5FF',
    black: '#001117',
    red: '#CD4F4F',
    skyBlue: '#0094CF',
    lightSkyBlue: '#F0F5FF',
    btnPrimaryDisabled: 'rgba(24, 53, 130, 0.5)',
    btnPrimaryDisabledGray: '#B8D3DD',
    microsoftBtn: '#2F2F2F',
    gray1: '#66737C',
    gray2: '#9A9A9A',
    grey: '#F8F8F8',
    darkGray: '#3C3C3B',
    lightGray: '#D9D9D9',
    secondaries: {
      purple: '#6E1242',
      green: '#1D7D54',
    },
    app: {
      primary: '#183582',
    },
  },
  fonts: {
    primary: 'Figtree, Segoe UI, Roboto, system-ui, sans-serif',
    bold: 'Figtree-Bold, Segoe UI, Roboto, system-ui, sans-serif',
    semiBold: 'Figtree-SemiBold, Segoe UI, Roboto, system-ui, sans-serif',
    medium: 'Figtree-Medium, Segoe UI, Roboto, system-ui, sans-serif',
    regular: 'Figtree-Regular, system-ui, sans-serif',
    light: 'Figtree-Regular, system-ui, sans-serif',
    saeLogo: 'Georgia, Times New Roman, Times, serif',
    microsoftBtn: 'Segoe-UI, Tahoma, Geneva, Verdana, sans-serif',
  },
  breakpoints: {
    xs: 'screen and (max-width: 330px)',
    sd: 'screen and (max-width: 400px)',
    xm: 'screen and (max-width: 540px)',
    sm: 'screen and (max-width: 640px)',
    md: 'screen and (max-width: 768px)',
    ml: 'screen and (max-width: 860px)',
    lg: 'screen and (max-width: 1024px)',
    xl: 'screen and (max-width: 1280px)',
    xxl: 'screen and (max-width: 1400px)',
  },
};
