import React from 'react';
import styled from 'styled-components';
import type SurveyDetailsInterface from '../../../interfaces/SurveyDetails';
import InputWithLabel from '../../authors/authorDetails/InputWithLabel';
import { Button } from '@mui/material';

interface Props {
  formData: SurveyDetailsInterface;
  setFormData: React.Dispatch<React.SetStateAction<SurveyDetailsInterface>>;
}

export default function OptionsList({ formData, setFormData }: Props): JSX.Element {
  const setValue = (newValue: string, type: string, index: number): void => {
    const newOptions = formData.options;
    newOptions[index].content = newValue;
    setFormData({ ...formData, [type]: newOptions });
  };

  const addNewOption = (): void => {
    const newOptions = [...formData.options, { content: '' }];
    setFormData({ ...formData, options: newOptions });
  };

  const deleteOption = (index: number): void => {
    const newOptions = formData.options;
    setFormData({ ...formData, options: newOptions.filter((ite, i) => i !== index) });
  };

  return (
    <Container>
      <Title>
        Opciones
        <Span>*</Span>
      </Title>
      {formData.options.map((opt, index) => (
        <InputWithLabel
          key={index}
          label=''
          showLabel={false}
          placeholder='Escribe aquí'
          value={opt.content}
          setValue={setValue}
          index={index}
          type='options'
          withDeleteBtn
          handleClickDeleteBtn={deleteOption}
          optionsQuantity={formData.options.length}
        />
      ))}
      <ButtonContainer>
        <CustomButton onClick={addNewOption}>
          <CustomButtonText>Añadir otra opción</CustomButtonText>
        </CustomButton>
      </ButtonContainer>
    </Container>
  );
}

const Container = styled.div``;

const Title = styled.p`
  font-family: ${(props) => props.theme.fonts.bold};
  color: ${(props) => props.theme.colors.darkGray};
  font-size: 14px;
  margin-bottom: 10px;
`;

const Span = styled.span`
  font-family: ${(props) => props.theme.fonts.bold};
  color: ${(props) => props.theme.colors.primary};
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const CustomButton = styled(Button)``;

const CustomButtonText = styled.p`
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.primary};
  font-size: 14px;
  text-transform: none;
  text-decoration: underline;
`;
