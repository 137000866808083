/* eslint-disable @typescript-eslint/return-await */
/* eslint-disable @typescript-eslint/no-var-requires */
import { isEmpty, isNil } from 'lodash';
import { ACCESS_TOKEN_KEY, ID_TOKEN_KEY } from '../utils/localStorageKeys';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { PublicClientApplication } from '@azure/msal-browser';
import { type AxiosResponse, type AxiosError } from 'axios';
import { checkTokenExpiration } from '../utils/token';
import { msalConfig } from '../auth/authConfiguration';

const msalInstance = new PublicClientApplication(msalConfig);

let idToken: string | null = null;
let accessToken: string | null = null;

const axios = require('axios').default;
const apiClientConfig: any = {
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

export const apiClient = axios.create(apiClientConfig);
const refreshClient = axios.create(apiClientConfig);

export const getTokensAndSetHeaders = () => {
  idToken = localStorage.getItem(ID_TOKEN_KEY);
  accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
  if (idToken !== null && accessToken !== null) {
    apiClient.defaults.headers.Authorization = 'Bearer ' + idToken;
  }
  return { idToken, accessToken };
};

export const setTokensInHeader = (idToken: string, accessToken: string) => {
  if (idToken !== null && accessToken !== null) {
    apiClient.defaults.headers.Authorization = 'Bearer ' + idToken;
  }
};

export const getTokens = () => {
  idToken = localStorage.getItem(ID_TOKEN_KEY);
  accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
  return { idToken, accessToken };
};

export const removeTokens = async () => {
  try {
    delete apiClient.defaults.headers.Authorization;
    localStorage.clear();
    idToken = null;
    accessToken = null;
  } catch (e) {}
};

const onRefreshTokenFail = () => {
  localStorage.clear();
  idToken = null;
  accessToken = null;
  window.location.href = '/login';
};

async function handleUnauthorizedRequest() {
  try {
    onRefreshTokenFail();
  } catch (error) {
    localStorage.clear();
    idToken = null;
    accessToken = null;
    onRefreshTokenFail();
    return await Promise.reject(error);
  }
}

createAuthRefreshInterceptor(apiClient, handleUnauthorizedRequest);

apiClient.interceptors.response.use(
  function (response: AxiosResponse) {
    return response;
  },
  async function (error: AxiosError) {
    if (!isEmpty(idToken) && error?.response?.status === 401) {
      localStorage.clear();
      idToken = null;
      accessToken = null;
      window.location.href = '/login';
    } else {
      return Promise.reject(error);
    }
  },
);
