import React from 'react';
import type Icon from '../interfaces/Icon';

export default function BlockIcon({ width = 20, height = 20, color = '#CD4F4F' }: Icon) {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_2625_3877)'>
        <mask id='mask0_2625_3877' maskUnits='userSpaceOnUse' x='0' y='0' width='20' height='20'>
          <rect width='20' height='20' fill='#D9D9D9' />
        </mask>
        <g mask='url(#mask0_2625_3877)'>
          <path
            d='M10.0002 18.3334C8.84738 18.3334 7.76405 18.1147 6.75016 17.6772C5.73627 17.2397 4.85432 16.6459 4.10432 15.8959C3.35432 15.1459 2.76057 14.264 2.32307 13.2501C1.88557 12.2362 1.66682 11.1529 1.66682 10.0001C1.66682 8.8473 1.88557 7.76397 2.32307 6.75008C2.76057 5.73619 3.35432 4.85425 4.10432 4.10425C4.85432 3.35425 5.73627 2.7605 6.75016 2.323C7.76405 1.8855 8.84738 1.66675 10.0002 1.66675C11.1529 1.66675 12.2363 1.8855 13.2502 2.323C14.264 2.7605 15.146 3.35425 15.896 4.10425C16.646 4.85425 17.2397 5.73619 17.6772 6.75008C18.1147 7.76397 18.3335 8.8473 18.3335 10.0001C18.3335 11.1529 18.1147 12.2362 17.6772 13.2501C17.2397 14.264 16.646 15.1459 15.896 15.8959C15.146 16.6459 14.264 17.2397 13.2502 17.6772C12.2363 18.1147 11.1529 18.3334 10.0002 18.3334ZM10.0002 16.6667C11.8613 16.6667 13.4377 16.0209 14.7293 14.7292C16.021 13.4376 16.6668 11.8612 16.6668 10.0001C16.6668 9.25008 16.5453 8.52786 16.3022 7.83341C16.0592 7.13897 15.7085 6.50008 15.2502 5.91675L5.91682 15.2501C6.50016 15.7084 7.13905 16.0591 7.83349 16.3022C8.52794 16.5452 9.25016 16.6667 10.0002 16.6667ZM4.75016 14.0834L14.0835 4.75008C13.5002 4.29175 12.8613 3.94105 12.1668 3.698C11.4724 3.45494 10.7502 3.33341 10.0002 3.33341C8.13905 3.33341 6.56266 3.97925 5.27099 5.27091C3.97932 6.56258 3.33349 8.13897 3.33349 10.0001C3.33349 10.7501 3.45502 11.4723 3.69807 12.1667C3.94113 12.8612 4.29182 13.5001 4.75016 14.0834Z'
            fill={color}
          />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_2625_3877'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
