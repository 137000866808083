import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { motion } from 'framer-motion';

interface Props {
  label: string;
  value: string;
  setValue: (newValue: string, type: string, index: number) => void;
  placeholder: string;
  type: string;
  showLabel?: boolean;
  index?: number;
  withDeleteBtn?: boolean;
  handleClickDeleteBtn?: (n: number) => void;
  optionsQuantity?: number;
  error?: boolean;
  errorMessage?: string;
}

interface ContainerI {
  marginTop?: number;
  marginBottom?: number;
}

interface InputI {
  isFocused: boolean;
  error?: boolean;
}

export default function InputWithLabel({
  label,
  value,
  setValue,
  placeholder,
  type,
  showLabel = true,
  index = 0,
  withDeleteBtn = false,
  handleClickDeleteBtn,
  optionsQuantity = 0,
  error = false,
  errorMessage = '',
}: Props): JSX.Element {
  const [isMouseOverInput, setIsMouseOverInput] = useState(false);
  const [inputIsFocused, setInputIsFocused] = useState(false);
  const [auxError, setAuxError] = useState(error);

  useEffect(() => {
    setAuxError(error);
  }, [error]);

  return (
    <Container>
      {showLabel && (
        <Label>
          {label}
          <Span>*</Span>
        </Label>
      )}
      <InputContainer
        error={auxError}
        isFocused={inputIsFocused}
        onMouseOver={() => {
          setIsMouseOverInput(true);
        }}
        onMouseLeave={() => {
          setIsMouseOverInput(false);
        }}
      >
        <Input
          isFocused={inputIsFocused}
          value={value}
          placeholder={placeholder}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setValue(e.target.value, type, index);
          }}
          onFocus={() => {
            setInputIsFocused(true);
          }}
          onBlur={() => {
            setInputIsFocused(false);
          }}
        />
        {isMouseOverInput && withDeleteBtn && optionsQuantity > 1 && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.1 }}
            exit={{ opacity: 0 }}
          >
            <CustomIconButton
              onClick={() => {
                if (handleClickDeleteBtn !== undefined) {
                  handleClickDeleteBtn(index);
                }
              }}
            >
              <CloseIcon style={{ color: 'white', fontSize: '14px' }} />
            </CustomIconButton>
          </motion.div>
        )}
      </InputContainer>
      {error && errorMessage !== '' && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Container>
  );
}

const Container = styled.div<ContainerI>`
  margin-top: ${(props) => (props.marginTop !== undefined ? `${props.marginTop}px` : `0px`)};
  margin-bottom: 18px;
`;

const Label = styled.p`
  font-family: ${(props) => props.theme.fonts.medium};
  color: ${(props) => props.theme.colors.primary};
  font-size: 14px;
  margin-bottom: 10px;
`;

const Span = styled.span`
  font-family: ${(props) => props.theme.fonts.medium};
  color: ${(props) => props.theme.colors.primary};
`;

const InputContainer = styled.div<InputI>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-right: 12px;
  width: 100%;
  max-width: 420px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid
    ${(props) =>
      props.error
        ? props.theme.colors.red
        : props.isFocused
        ? props.theme.colors.primary
        : props.theme.colors.lightSkyBlue};
  @media screen and (max-width: 860px) {
    max-width: none;
    padding-right: 0px;
  }
`;

const ErrorMessage = styled.p`
  font-family: ${(props) => props.theme.fonts.regular};
  font-size: 12px;
  color: ${(props) => props.theme.colors.red};
  margin-top: 12px;
`;

const Input = styled.input<InputI>`
  flex: 1;
  outline: none;
  border: none;
  height: 100%;
  padding: 0px 12px;
  border-radius: 5px;
  font-size: 13px;
  font-family: ${(props) => props.theme.fonts.regular};
  ::placeholder {
    color: ${(props) => props.theme.colors.gray2};
  }
`;

const CustomIconButton = styled(IconButton)`
  &&& {
    background-color: ${(props) => props.theme.colors.red};
  }
  height: 18px;
  width: 18px;
`;
