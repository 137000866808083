import React, { type SyntheticEvent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrow from '@mui/icons-material/PlayArrow';
import { CircularProgress, IconButton, Slider } from '@mui/material';
import { useSelector } from 'react-redux';
import VolumenIcon from '../../icons/VolumenIcon';
import { type RootState } from '../../context/store';

export default function AudioPlayer() {
  const elements = useSelector((state: RootState) => state.editor.elements);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [sliderValue, setSliderValue] = useState<number | number[]>(0);
  const [currentTime, setCurrentTime] = useState<string>('0:00');
  const [isSliderActive, setIsSliderActive] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [audioSource, setAudioSource] = useState('');
  const [durationInSeconds, setDurationInSeconds] = useState<number | undefined>(0);
  const audioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    const currentAudioSource =
      elements[elements.findIndex((item) => item.key === 'audio')].element.props?.audioSource;
    setAudioSource(currentAudioSource ?? '');
  }, [elements]);

  useEffect(() => {
    if (audioRef.current !== null && audioSource !== '') {
      setIsAudioPlaying(false);
      setSliderValue(0);
      setCurrentTime('0:00');
      audioRef.current.src = audioSource;
      audioRef.current.load();
      setIsLoading(true);
      if (audioRef.current.readyState > 0) {
        setDurationInSeconds(audioRef?.current?.duration);
      } else {
        audioRef.current.addEventListener('loadedmetadata', (information) => {
          setDurationInSeconds(audioRef?.current?.duration);
        });
      }
    }
  }, [audioSource]);

  useEffect(() => {
    const currentTimeInSeconds = audioRef?.current?.currentTime;
    if (
      currentTimeInSeconds !== undefined &&
      durationInSeconds !== undefined &&
      durationInSeconds > 0 &&
      !isSliderActive
    ) {
      setSliderValue((currentTimeInSeconds * 100) / durationInSeconds);
    }
  }, [currentTime]);

  const calculateTime = (secs: number | undefined): string => {
    if (secs !== undefined) {
      const minutes = Math.floor(secs / 60);
      const seconds = Math.floor(secs % 60);
      const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
      return `${minutes}:${returnedSeconds}`;
    }
    return '';
  };

  const handleTimeUpdate = (): void => {
    setCurrentTime(calculateTime(audioRef?.current?.currentTime));
  };

  const handleClickPlayButton = () => {
    if (!isAudioPlaying) {
      audioRef?.current
        ?.play()
        .then(() => {
          setIsAudioPlaying(true);
        })
        .catch(() => {
          setIsAudioPlaying(false);
        });
    } else {
      audioRef?.current?.pause();
      setIsAudioPlaying(false);
    }
  };

  const handleChangeCommitted = (
    event: Event | SyntheticEvent<Element, Event>,
    value: number | number[],
  ): void => {
    if (audioRef.current !== null && durationInSeconds !== undefined && typeof value === 'number') {
      const newTime = (durationInSeconds * value) / 100;
      audioRef.current.currentTime = newTime;
    }
  };

  const handleAudioEnded = () => {
    if (audioRef.current) {
      setIsAudioPlaying(false);
      setSliderValue(0);
      setCurrentTime('0:00');
      audioRef.current.currentTime = 0;
    }
  };

  if (audioSource === '') return <></>;

  return (
    <Container>
      {audioSource !== '' && (
        <>
          <audio
            ref={audioRef}
            controls
            preload='metadata'
            style={{ display: 'none' }}
            onTimeUpdate={handleTimeUpdate}
            onLoadedData={() => {
              setIsLoading(false);
            }}
            onEnded={handleAudioEnded}
          >
            <source src={audioSource} type='audio/mpeg' />
            Your browser does not support the audio element.
          </audio>
        </>
      )}
      <Title>Archivo de audio</Title>
      <Player>
        {isLoading ? (
          <CircularProgressContainer>
            <CircularProgress size={30} />
          </CircularProgressContainer>
        ) : (
          <>
            <PlayButton onClick={handleClickPlayButton}>
              {isAudioPlaying ? (
                <PauseIcon
                  color='secondary'
                  fontSize='large'
                  style={{ height: '28px', width: '28px', color: 'white' }}
                />
              ) : (
                <PlayArrow
                  color='secondary'
                  fontSize='large'
                  style={{ height: '28px', width: '28px', color: 'white' }}
                />
              )}
            </PlayButton>
            <SliderContainer>
              <CustomSlider
                size='small'
                defaultValue={0}
                color='secondary'
                valueLabelDisplay='off'
                onChange={(event, value) => {
                  setIsSliderActive(true);
                  setSliderValue(value);
                }}
                onChangeCommitted={(event, value) => {
                  handleChangeCommitted(event, value);
                  setIsSliderActive(false);
                }}
                value={sliderValue}
              />
            </SliderContainer>
            <IconButton>
              <VolumenIcon />
            </IconButton>
          </>
        )}
      </Player>
    </Container>
  );
}

const Container = styled.div`
  position: absolute;
  z-index: 999;
  bottom: 0px;
  background-color: white;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.3);
  padding-top: 20px;
  padding-bottom: 30px;
  width: calc(100% - 20px);
`;

const Title = styled.p`
  text-transform: uppercase;
  color: #0f1f41;
  font-family: ${(props) => props.theme.fonts.bold};
  margin-bottom: 4px;
  padding-left: 23px;
  padding-right: 23px;
`;

const CircularProgressContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Player = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #283b76;
  margin-left: 23px;
  margin-right: 23px;
  margin-top: 10px;
  border-radius: 34px;
  height: 68px;
  padding-right: 10px;
`;

const PlayButton = styled(IconButton)`
  height: 45px;
  width: 45px;
  background-color: #033bcf;
  margin-right: 11px;
  margin-left: 15px;
`;

const SliderContainer = styled.div`
  flex: 1;
`;

const CustomSlider = styled(Slider)`
  width: 100%;
`;
