import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import AuthorsList from './AuthorsList';
import { Button, CircularProgress } from '@mui/material';
import EmailIcon from '../../icons/EmailIcon';
import { getPostPreview } from '../../api/posts';
import type PreviewInformation from '../../interfaces/PreviewInformation';
import { useSelector } from 'react-redux';
import { type RootState } from '../../context/store';

interface Props {
  selectedAuthors: any[];
  postInformation: any;
  currentStatus: string | null;
}

export default function AuthorsAndEditor({
  selectedAuthors,
  postInformation,
  currentStatus,
}: Props) {
  const isPreviewModeActive = useSelector((state: RootState) => state.toolBar.isPreviewModeActive);
  const [previewInformation, setPreviewInformation] = useState<PreviewInformation | null>(null);

  useEffect(() => {
    if (isPreviewModeActive) {
      setPreviewInformation(null);
      getPostPreview(postInformation?.id)
        .then((res) => {
          setPreviewInformation(res);
        })
        .catch(() => {});
    }
  }, [currentStatus, isPreviewModeActive]);

  const editors: any = [];

  if (previewInformation?.editor?.username) {
    editors.push({
      names: previewInformation?.editor?.names,
      username: previewInformation?.editor?.username,
    });
  }

  const handleClickEmail = () => {
    if (editors?.length > 0) {
      const editor = editors[0];
      const email = editor.username;
      const emailHref = `mailto:${email}`;
      window.location.href = emailHref;
    }
  };

  if (selectedAuthors?.length === 0) return <></>;

  return (
    <Container>
      <LeftContainer>
        <AuthorsList
          title='Autores'
          list={selectedAuthors}
          previewInformation={previewInformation}
          type='author'
        />
        {editors?.length > 0 && (
          <AuthorsList
            title='Editor'
            list={editors}
            previewInformation={previewInformation}
            type='editor'
          />
        )}
      </LeftContainer>
      <LineContainer>
        <Line />
      </LineContainer>
      {editors === null ? (
        <CircularProgressContainer>
          <CircularProgress />
        </CircularProgressContainer>
      ) : editors?.length > 0 ? (
        <>
          <RightContainer>
            <MessageContainer>
              <MessageTitle>Sigamos la conversación</MessageTitle>
              <MessageContent>Envíanos tus comentarios o dudas sobre el artículo</MessageContent>
            </MessageContainer>
            <CustomButton
              className='bg-app-btn-send-comment px-3'
              startIcon={<EmailIcon />}
              onClick={handleClickEmail}
            >
              <CustomButtonTitle>Escribir al editor</CustomButtonTitle>
            </CustomButton>
          </RightContainer>
        </>
      ) : (
        <></>
      )}
    </Container>
  );
}

const Container = styled.div`
  background-color: #0f1f41;
  margin-bottom: 140px;
  padding-left: 23px;
  padding-right: 23px;
  padding-top: 25px;
  padding-bottom: 30px;
  display: grid;
  grid-template-columns: 1fr 2px 1.5fr;
  column-gap: 8px;
`;

const LeftContainer = styled.div``;

const LineContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

const Line = styled.div`
  background-color: ${(props) => props.theme.colors.lightGray};
  width: 2px;
  height: 100%;
`;

const CircularProgressContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
`;

const RightContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
`;

const MessageContainer = styled.div`
  padding-left: 10px;
  padding-right: 10px;
`;

const MessageTitle = styled.p`
  text-align: center;
  color: white;
  font-family: ${(props) => props.theme.fonts.semiBold};
  margin-bottom: 8px;
  font-size: 16px;
`;

const MessageContent = styled.p`
  text-align: center;
  color: ${(props) => props.theme.colors.gray2};
  font-family: ${(props) => props.theme.fonts.regular};
  margin-left: 10px;
  margin-right: 10px;
  font-size: 14px;
`;

const CustomButton = styled(Button)`
  background-color: #3357ec;
  margin-top: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  border-radius: 20px;
`;

const CustomButtonTitle = styled.p`
  font-family: ${(props) => props.theme.fonts.medium};
  color: white;
  text-transform: none;
  font-size: 16px;
`;
