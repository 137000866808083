import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, type Location } from 'react-router-dom';
import { ListItemIcon, MenuItem } from '@mui/material';
import classNames from 'classnames';
import styled from 'styled-components';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import type SideBarOptionItemInterface from '../../interfaces/SideBarOptionItem';
import { setInputOfGeneralSearch } from '../../context/reducers/generalSearch';
import { setIsMainDrawerOpen } from '../../context/reducers/layout';
import MenuDrawerSubOptionItem from './MenuDrawerSubOptionItem';

const isPathSelected = (path: string, location: Location): boolean => {
  return path === '/' ? location.pathname === path : location.pathname.startsWith(path);
};

export default function MenuDrawerOptionItem({
  title,
  icon,
  path,
  subOptions,
}: SideBarOptionItemInterface): JSX.Element {
  const navigation = useNavigate();
  const location = useLocation();
  const [isSelected, setIsSelected] = useState(
    isPathSelected(path, location) || (path === '/' && location.pathname === '/search'),
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setIsSelected(
      isPathSelected(path, location) || (path === '/' && location.pathname === '/search'),
    );
  }, [location]);

  const handleOnClick = (): void => {
    dispatch(
      setIsMainDrawerOpen({
        isMainDrawerOpen: false,
      }),
    );
    dispatch(
      setInputOfGeneralSearch({
        searchInput: '',
      }),
    );
    if (subOptions === undefined) {
      navigation(path);
    } else {
      navigation(subOptions[0].path);
    }
  };

  const checkIfSomeOfSuptionIsSelected = (): boolean => {
    if (subOptions === undefined) {
      return false;
    }

    return subOptions?.some(
      (subOpt, index) => `${location.pathname}${location.search}` === subOpt.path,
    );
  };

  return (
    <>
      <MenuItem
        className={classNames('', {
          'bg-accent-blue': isSelected && subOptions === undefined,
        })}
        style={{ padding: '15px 12px' }}
        onClick={handleOnClick}
      >
        <ListItemIcon className='flex items-center justify-center'>{icon}</ListItemIcon>
        <Title>{title}</Title>
        {subOptions !== undefined &&
          (checkIfSomeOfSuptionIsSelected() ? <KeyboardArrowDownIcon /> : <ChevronRightIcon />)}
      </MenuItem>
      {subOptions !== undefined && checkIfSomeOfSuptionIsSelected() && (
        <SubOptionsList>
          {subOptions.map((sOpt, index) => (
            <MenuDrawerSubOptionItem key={index} title={sOpt.title} path={sOpt.path} />
          ))}
        </SubOptionsList>
      )}
    </>
  );
}

const Title = styled.p`
  flex: 1;
  font-family: ${(props) => props.theme.fonts.regular};
  color: #183582;
`;

const SubOptionsList = styled.ul``;
