import React from 'react';
import type Icon from '../interfaces/Icon';

export default function TrashIcon({
  color = '#CD4F4F',
  width = 18,
  height = 18,
}: Icon): JSX.Element {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.1667 4.00001H17.3334V5.66667H15.6667V16.5C15.6667 16.9603 15.2936 17.3333 14.8334 17.3333H3.16669C2.70645 17.3333 2.33335 16.9603 2.33335 16.5V5.66667H0.666687V4.00001H4.83335V1.50001C4.83335 1.03977 5.20645 0.666672 5.66669 0.666672H12.3334C12.7936 0.666672 13.1667 1.03977 13.1667 1.50001V4.00001ZM14 5.66667H4.00002V15.6667H14V5.66667ZM6.50002 8.16667H8.16669V13.1667H6.50002V8.16667ZM9.83335 8.16667H11.5V13.1667H9.83335V8.16667ZM6.50002 2.33334V4.00001H11.5V2.33334H6.50002Z'
        fill={color}
      />
    </svg>
  );
}
