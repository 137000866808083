import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError } from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import { type EditorState } from 'draft-js';
import DrawerHeader from './DrawerHeader';
import UploadIcon from '../../icons/UploadIcon';
import ComboBox from '../globals/ComboBox';
import TrashIcon from '../../icons/TrashIcon';
import SettingDrawerFooter from './SettingDrawerFooter';
import ComboBoxAuthorsWrapped from '../globals/ComboBoxAuthorsWrapped';
import readersTypes from '../../constants/readersTypes';
import ComboBoxCategoriesWrapped from '../globals/ComboBoxCategoriesWrapped';
import type PostInformation from '../../interfaces/PostInformation';
import ComboBoxTagsWrapped from '../globals/ComboBoxTagsWrapped';
import type TagDetails from '../../interfaces/TagDetails';
import {
  addAuthorsToPost,
  addCategoriesToPost,
  addTagsToPost,
  deleteAuthorFromPost,
  deleteCategoryFromPost,
  deleteTagFromPost,
} from '../../api/posts';
import { openSnackbar } from '../../context/reducers/generalSnackbar';
import { POST_ERRORS } from '../../constants/errorMessages';
import { type AuthorItem } from '../../interfaces/AuthorDetails';
import {
  MAX_AUTHORS_QUANTITY,
  MAX_CATEGORIES_QUANTITY,
  MAX_IMAGES_QUANTITY,
  MAX_TAGS_QUANTITY,
} from '../../constants/globals';
import { callPostDetails, savePost, saveSettingsOfPost } from '../../utils/editor';
import { type RootState } from '../../context/store';
import { getImagesQuantity } from '../../utils/wysiwyg';
import { setHomeDraftPostCreated } from '../../context/reducers/homeDrafts';
import { setDraftPostAdded, setPendingReviewPostUpdated } from '../../context/reducers/entries';
import POSTS_STATUS from '../../constants/status';
import ComboBoxSubscriptionsWrapped from '../globals/ComboBoxSubscriptionsWrapped';
import type Subscription from '../../interfaces/Subscription';
import { checkIfIsPresentationPage } from '../../utils/checkIfIsPresentationPage';

interface Props {
  postInformation: PostInformation | null;
  setIsOpen: (v: boolean) => void;
  selectedCategories: any[];
  setSelectedCategories: React.Dispatch<React.SetStateAction<any[]>>;
  currentCategories: any[];
  setCurrentCategories: React.Dispatch<React.SetStateAction<any[]>>;
  selectedAuthors: AuthorItem[];
  setSelectedAuthors: React.Dispatch<React.SetStateAction<AuthorItem[]>>;
  currentAuthors: AuthorItem[];
  setCurrentAuthors: React.Dispatch<React.SetStateAction<AuthorItem[]>>;
  readersIDs: any[] | null;
  setReadersIDs: React.Dispatch<React.SetStateAction<any[]>>;
  selectedTags: TagDetails[];
  setSelectedTags: React.Dispatch<React.SetStateAction<TagDetails[]>>;
  currentTags: TagDetails[];
  setCurrentTags: React.Dispatch<React.SetStateAction<TagDetails[]>>;
  currentSubscriptions: any[];
  setCurrentSubscriptions: React.Dispatch<React.SetStateAction<any[]>>;
  selectedSubscriptions: any[];
  setSelectedSubscriptions: React.Dispatch<React.SetStateAction<any[]>>;
  postId: number | string | undefined;
  currentStatus: string | null;
  setConfirmDeleteModalIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setIsErrorConcurrenceModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  currentVersion: number;
  setCurrentVersion: React.Dispatch<React.SetStateAction<number>>;
  setIsArticleWasReturnedToDraftModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setIsArticleHasBeenPublishedModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isPresentationPage?: boolean;
  isNotificationModalVisible: boolean;
  setIsNotificationModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

interface DeleteButtonTextI {
  disabled: boolean;
}

export default function SettingDrawer({
  postInformation,
  setIsOpen,
  selectedCategories,
  setSelectedCategories,
  currentCategories,
  setCurrentCategories,
  selectedAuthors,
  setSelectedAuthors,
  readersIDs,
  setReadersIDs,
  selectedTags,
  setSelectedTags,
  currentTags,
  setCurrentTags,
  postId,
  currentAuthors,
  setCurrentAuthors,
  currentStatus,
  setConfirmDeleteModalIsVisible,
  selectedSubscriptions,
  setSelectedSubscriptions,
  currentSubscriptions,
  setCurrentSubscriptions,
  setIsErrorConcurrenceModalVisible,
  currentVersion,
  setCurrentVersion,
  setIsArticleHasBeenPublishedModalVisible,
  setIsArticleWasReturnedToDraftModalVisible,
  isPresentationPage,
  isNotificationModalVisible,
  setIsNotificationModalVisible,
}: Props): JSX.Element {
  const isRemovingSubscription = useSelector(
    (state: RootState) => state.subscriptions.isRemovingSubscription,
  );
  const isRemovingCategory = useSelector((state: RootState) => state.categories.isRemovingCategory);
  const isRemovingTag = useSelector((state: RootState) => state.tags.isRemovingTag);
  const isRemovingAuthor = useSelector((state: RootState) => state.authors.isRemovingAuthor);
  const userProfile = useSelector((state: RootState) => state.userProfile.userProfile);
  const elements = useSelector((state: RootState) => state.editor.elements);
  const elementsContent = useSelector((state: RootState) => state.editor.elementsContent);
  const [savingChanges, setSavingChanges] = useState(false);
  const [changingState, setChangingState] = useState(false);
  const [authorsIDs, setAuthorsIDs] = useState<any[]>([]);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getDefaultTags = (postInformation: PostInformation | null | undefined) => {
    const tags = postInformation?.tags;
    const auxTags = tags?.map((item, index) => {
      return {
        id: index,
        tag: item,
      };
    });
    if (auxTags !== undefined) {
      return auxTags;
    }
    return [];
  };

  const getDefaultAuthors = (postInformation: PostInformation | null | undefined) => {
    const authors = postInformation?.authors;
    return authors || [];
  };

  const getDefaultCategories = (postInformation: PostInformation | null | undefined) => {
    return postInformation?.categories ?? [];
  };

  const getDefaultSubscriptions = (postInformation: PostInformation | null | undefined) => {
    return postInformation?.availableFor ?? [];
  };

  const checkImagesQuantityOfPost = (): boolean => {
    const mainParagraph = elementsContent[3].content as EditorState;
    return getImagesQuantity(mainParagraph) <= MAX_IMAGES_QUANTITY;
  };

  const handleClickSaveDraft = async () => {
    try {
      if (!checkImagesQuantityOfPost()) {
        dispatch(
          openSnackbar({
            type: 'error',
            message: POST_ERRORS.MAX_IMAGES_QUANTITY,
          }),
        );
        return;
      }
      await savePost(
        setSavingChanges,
        postInformation,
        elementsContent,
        elements,
        dispatch,
        savingChanges,
        navigate,
        setIsErrorConcurrenceModalVisible,
        currentVersion,
        setCurrentVersion,
        setIsArticleHasBeenPublishedModalVisible,
        setIsArticleWasReturnedToDraftModalVisible,
        isPresentationPage,
      );
      if (
        currentStatus === POSTS_STATUS.PENDING_REVIEW ||
        currentStatus === POSTS_STATUS.REVIEW_IN_PROGRESS
      ) {
        dispatch(
          setPendingReviewPostUpdated({
            pendingReviewPostUpdated: true,
          }),
        );
      } else {
        dispatch(
          setHomeDraftPostCreated({
            draftPostCreated: true,
          }),
        );
        dispatch(
          setDraftPostAdded({
            draftPostAdded: true,
          }),
        );
      }
      if (postId !== undefined) {
        await callPostDetails(postId as string, setCurrentVersion);
      }
    } catch (err) {}
  };

  const handleDeletePost = () => {
    setIsOpen(false);
    setConfirmDeleteModalIsVisible(true);
  };

  return (
    <Container>
      <ContainerDrawerHeader>
        <DrawerHeader
          title='Configuración'
          subtitle={`Organiza y programa tu ${
            isPresentationPage ? 'PPT para publicarlo' : 'artículo para publicarlo'
          } en la app.`}
          setIsOpen={setIsOpen}
        />
      </ContainerDrawerHeader>
      <MobileContainerDrawerHeader>
        <DrawerHeader title='Detalles del artículo' subtitle='' setIsOpen={setIsOpen} />
      </MobileContainerDrawerHeader>
      <Content>
        <ComboBoxCategoriesWrapped
          parent='drawer'
          categoriesIDs={getDefaultCategories(postInformation)}
          setCategoriesIDs={setSelectedCategories}
          label='Categoría'
          page='editor'
          placeholder='Escribe o selecciona las categorías'
          setCurrentVersion={setCurrentVersion}
        />
        <ComboBoxTagsWrapped
          parent='drawer'
          selectedTags={getDefaultTags(postInformation)}
          setSelectedTags={setSelectedTags}
          label='Etiquetas'
          placeholder='Escribe o selecciona las etiquetas'
          postInformation={postInformation}
          page='editor'
          setCurrentVersion={setCurrentVersion}
        />
        <ComboBoxSubscriptionsWrapped
          selectedSubscriptions={getDefaultSubscriptions(postInformation)}
          setSelectedSubscriptions={setSelectedSubscriptions}
          setCurrentVersion={setCurrentVersion}
        />
        {!isPresentationPage && (
          <ComboBoxAuthorsWrapped
            parent='drawer'
            authorsIDs={authorsIDs}
            setAuthorsIDs={setAuthorsIDs}
            selectedAuthors={getDefaultAuthors(postInformation)}
            setSelectedAuthors={setSelectedAuthors}
            label='Autores'
            placeholder='Escribe o selecciona al autor/a'
            page='editor'
            setCurrentVersion={setCurrentVersion}
          />
        )}
        <DeleteButton
          disabled={
            isRemovingAuthor || isRemovingCategory || isRemovingSubscription || isRemovingTag
          }
          startIcon={<TrashIcon color={'#CD4F4F'} />}
          onClick={handleDeletePost}
        >
          <DeleteButtonText disabled={false}>
            Eliminar {isPresentationPage ? 'PPT' : 'artículo'}
          </DeleteButtonText>
        </DeleteButton>
      </Content>
      <SettingDrawerFooter
        postId={postId}
        savingChanges={savingChanges}
        setSavingChange={setSavingChanges}
        changingState={changingState}
        setChangingState={setChangingState}
        setIsOpen={setIsOpen}
        handleClickSaveDraft={handleClickSaveDraft}
        categories={selectedCategories}
        authors={selectedAuthors}
        currentStatus={currentStatus}
        selectedCategories={selectedCategories}
        selectedAuthors={selectedAuthors}
        selectedTags={selectedTags}
        isPresentationPage={isPresentationPage}
        isNotificationModalVisible={isNotificationModalVisible}
        setIsNotificationModalVisible={setIsNotificationModalVisible}
      />
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const ContainerDrawerHeader = styled.div`
  display: block;

  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const MobileContainerDrawerHeader = styled.div`
  display: none;

  @media screen and (max-width: 860px) {
    display: block;
  }
`;

const Content = styled.div`
  flex: 1;
  padding-left: 8px;
  padding-bottom: 120px;
`;

const DeleteButton = styled(Button)`
  margin-top: 32px;
  margin-left: 12px;
  .MuiTouchRipple-child {
    background-color: ${(props) => props.theme.colors.red};
  }
`;

const DeleteButtonText = styled.p<DeleteButtonTextI>`
  color: ${(props) => (props.disabled ? '#c1c1c1' : props.theme.colors.red)};
  font-family: ${(props) => props.theme.fonts.semiBold};
  text-transform: none;
  margin-left: 8px;
  margin-right: 8px;
`;
