import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { TextareaAutosize } from '@mui/material';
import {
  KEY_IDEAS,
  KEY_IDEA_ITEM,
  WYSIWYG_MAIN_SUBTITLE,
  WYSIWYG_MAIN_TITLE,
} from '../../constants/editorKeys';
import { type RootState } from '../../context/store';
import {
  blurAnEditor,
  focusAnEditor,
  setCurrentSelectedEditorState,
  setElementsContent,
  setSelectedEditorId,
  setSelectedEditorKey,
  setUnsavedChanges,
} from '../../context/reducers/editor';
import generateRandomoUUID from '../../utils/generateRandomUUID';
import { EditorState, type Editor, ContentState } from 'draft-js';
import POSTS_STATUS from '../../constants/status';

interface Props {
  customKey: string;
  placeholder: string;
  fontSize?: string;
  color?: string;
  fontFamily?: string;
  fontWeight?: number;
  fontStyle?: string;
  maximumOfCharacters?: number;
  marginTop?: number;
  keyNumber?: number | null;
  defaultValue?: string;
  placeholderColor?: string;
}

interface TextAreaI {
  placeholderColor?: string;
}

export default function EditorInput({
  customKey,
  placeholder,
  fontSize = '16px',
  color = 'black',
  fontFamily = 'Arial',
  fontWeight = 400,
  fontStyle = 'normal',
  maximumOfCharacters,
  marginTop = 0,
  keyNumber = null,
  defaultValue = '',
  placeholderColor = '',
}: Props) {
  const isPreviewModeActive = useSelector((state: RootState) => state.toolBar.isPreviewModeActive);
  const elementsContent = useSelector((state: RootState) => state.editor.elementsContent);
  const currentPostStatus = useSelector((state: RootState) => state.editor.currentPostStatus);
  const [auxEditorState, setAuxEditorState] = useState(defaultValue);
  const [isFocus, setIsFocus] = useState(false);
  const [isMouseOverEditor, setIsMouseOverEditor] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const editorRef = useRef<HTMLTextAreaElement>(null);
  const editorWrapperRef = useRef<HTMLDivElement>(null);
  const editorId = useRef(generateRandomoUUID());
  const dispatch = useDispatch();

  const handleFocus = (): void => {
    if (
      currentPostStatus === POSTS_STATUS.REVIEW_IN_PROGRESS ||
      currentPostStatus === POSTS_STATUS.PUBLISHED
    ) {
      return;
    }
    setReadOnly(false);
    dispatch(focusAnEditor({}));
    dispatch(setSelectedEditorKey({ selectedEditorKey: customKey }));
    dispatch(setSelectedEditorId({ selectedEditorId: editorId.current }));
    setIsFocus(true);
  };

  const handleBlur = (): void => {
    if (
      currentPostStatus === POSTS_STATUS.REVIEW_IN_PROGRESS ||
      currentPostStatus === POSTS_STATUS.PUBLISHED
    ) {
      return;
    }
    dispatch(blurAnEditor({}));
    setIsFocus(false);
  };

  useEffect(() => {
    if (
      (customKey === WYSIWYG_MAIN_TITLE && elementsContent[0].content) ||
      (customKey === WYSIWYG_MAIN_SUBTITLE && elementsContent[1].content)
    ) {
      if (typeof elementsContent[0].content === 'string' && customKey === WYSIWYG_MAIN_TITLE) {
        setAuxEditorState(elementsContent[0].content);
      }
      if (typeof elementsContent[1].content === 'string' && customKey === WYSIWYG_MAIN_SUBTITLE) {
        setAuxEditorState(elementsContent[1].content);
      }
    }
  }, []);

  useEffect(() => {
    if (customKey === WYSIWYG_MAIN_TITLE || customKey === WYSIWYG_MAIN_SUBTITLE) {
      dispatch(
        setElementsContent({
          customKey,
          content: auxEditorState?.trim(),
        }),
      );
    } else if (customKey === KEY_IDEA_ITEM) {
      const keyIdeasIndex = elementsContent.findIndex((item) => item.customKey === KEY_IDEAS);
      if (keyIdeasIndex !== -1 && keyNumber !== null) {
        const newKeyIdeas = [...(elementsContent[keyIdeasIndex].content as EditorState[])];
        if (newKeyIdeas !== null && newKeyIdeas !== undefined) {
          newKeyIdeas[keyNumber] = EditorState.createWithContent(
            ContentState.createFromText(auxEditorState),
          );
          dispatch(
            setElementsContent({
              customKey: KEY_IDEAS,
              content: newKeyIdeas,
            }),
          );
        }
      }
    }
  }, [auxEditorState]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setAuxEditorState(e.target.value);
    dispatch(
      setUnsavedChanges({
        unsavedChanges: true,
      }),
    );
  };

  const handleMouseOver = (): void => {
    if (
      currentPostStatus === POSTS_STATUS.REVIEW_IN_PROGRESS ||
      currentPostStatus === POSTS_STATUS.PUBLISHED ||
      currentPostStatus === null
    ) {
      return;
    }
    setIsMouseOverEditor(true);
  };

  const handleMouseOut = (): void => {
    if (
      currentPostStatus === POSTS_STATUS.REVIEW_IN_PROGRESS ||
      currentPostStatus === POSTS_STATUS.PUBLISHED ||
      currentPostStatus === null
    ) {
      return;
    }
    setIsMouseOverEditor(false);
  };

  return (
    <Container
      ref={editorWrapperRef}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      style={{
        marginTop: `${marginTop}px`,
        borderColor: isPreviewModeActive
          ? 'transparent'
          : isFocus
          ? '#0094CF'
          : isMouseOverEditor
          ? '#b7b6b6'
          : 'transparent',
      }}
    >
      <TextArea
        ref={editorRef}
        value={auxEditorState}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        readOnly={isPreviewModeActive || currentPostStatus === POSTS_STATUS.PUBLISHED}
        placeholder={placeholder}
        placeholderColor={placeholderColor}
        maxLength={maximumOfCharacters}
        style={{
          fontFamily,
          fontSize,
          fontStyle,
          color,
          fontWeight,
          width: '100%',
          resize: 'none',
        }}
        disabled={isPreviewModeActive}
      />
    </Container>
  );
}

const Container = styled.div`
  border: 1px solid transparent;
  padding-left: 8px;
  padding-right: 8px;
  width: 100%;
  transition: border 0.1s ease-in;
  border-radius: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  :hover {
    border-color: ${(props) => props.theme.colors.gray2};
  }
`;

const Input = styled.input`
  width: 100%;
`;

const TextArea = styled(TextareaAutosize)<TextAreaI>`
  background-color: transparent;
  border: none;
  outline: none;
  ::placeholder {
    color: ${(props) =>
      props.placeholderColor ? props.placeholderColor : props.theme.colors.gray2};
  }
`;
