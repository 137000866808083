import React, { createRef, useState } from 'react';
import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { EditorState, convertToRaw } from 'draft-js';
import CustomDivider from '../globals/CustomDivider';
import ClickOutsideDetector from '../globals/ClickOutsideDetector';
import { type RootState } from '../../context/store';
import { formatLessThan10 } from '../../utils/formatLessThan10';
import { HEADER_IMAGE, KEY_PARAGRAPH } from '../../constants/editorKeys';
import { Close } from '@mui/icons-material';

interface LabelI {
  warning?: boolean;
}

export default function WordCounter(): JSX.Element {
  const elementsContent = useSelector((state: RootState) => state.editor.elementsContent);
  const [showModal, setShowModal] = useState(false);
  const modalRef = createRef<HTMLDivElement>();

  const handleClickInfoButton = (): void => {
    setShowModal(!showModal);
  };

  const getPlainText = (editorState: EditorState): string => {
    const currentContentState = editorState.getCurrentContent();
    const plainText = currentContentState
      .getBlockMap()
      .filter((block) => block?.getType() !== 'custom-divider')
      .map((block) => block?.getText().trim())
      .join('\n');
    return plainText;
  };

  const joinAllCharacteres = (): string => {
    let allCharacteres = '';
    elementsContent.forEach((element) => {
      if (element.customKey !== HEADER_IMAGE) {
        if (Array.isArray(element.content)) {
          element.content.forEach((subElement) => {
            allCharacteres += `${getPlainText(subElement)} `;
          });
        } else {
          if (element.content !== null) {
            if (element.content instanceof EditorState) {
              allCharacteres += `${getPlainText(element.content)} `;
            } else {
              allCharacteres += `${element?.content} `;
            }
          }
        }
      }
    });
    return allCharacteres;
  };

  const getCharacteresOfContent = (): string => {
    const content =
      elementsContent[elementsContent.findIndex((item) => item.customKey === KEY_PARAGRAPH)]
        .content;
    return content !== null ? getPlainText(content as EditorState) : '';
  };

  const getWordsQuantity = (): number => {
    const allCharacteres = getCharacteresOfContent().trim().replace(/\s+/g, ' ').split(' ');
    const words = allCharacteres.filter((word) => word !== '');
    return words?.length;
  };

  const getParagraphQuantity = (): number => {
    const allCharacteres = getCharacteresOfContent().split('\n');
    const paragraphs = allCharacteres.filter((paragraph) => paragraph.trim() !== '');
    return paragraphs.length;
  };

  const deleteBlankSpaces = (str: string) => {
    const regex = /\s/g;
    return str.replace(regex, '');
  };

  const getCharacteresQuantity = (): number => {
    return deleteBlankSpaces(getCharacteresOfContent()).length;
  };

  const getTime = (): string => {
    const wordsQuantity = getWordsQuantity();
    const time = wordsQuantity / 250;
    const seconds = Math.round(time * 60);
    let final = time;
    if (time < 1) final = seconds;

    return `${formatLessThan10(Math.round(final))} ${
      time < 1 ? (time === 0 ? 'Minutos' : 'Segundos') : 'Minutos'
    }`;
  };

  return (
    <>
      <CustomIconButton
        className='bg-matiz-blue'
        onClick={handleClickInfoButton}
        id='editor-words-counter'
      >
        <InfoOutlinedIcon style={{ color: '#183582' }} />
      </CustomIconButton>
      {showModal && (
        <ClickOutsideDetector
          onClickOutside={handleClickInfoButton}
          listen={true}
          ref={modalRef}
          wrapperId='editor-page'
        >
          <Container
            layout
            initial={{ opacity: 0.5, x: -10 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.2 }}
            exit={{ opacity: 0.5, x: -10 }}
          >
            <Header>
              <Title>Información del artículo</Title>
              <CloseIconButton
                onClick={() => {
                  setShowModal(false);
                }}
              >
                <Close />
              </CloseIconButton>
            </Header>
            <Row>
              <Label warning={getWordsQuantity() > 1500}>Palabras</Label>
              <Quantity warning={getWordsQuantity() > 1500}>{getWordsQuantity()}</Quantity>
            </Row>
            <Row>
              <Label>Párrafos</Label>
              <Quantity>{getParagraphQuantity()}</Quantity>
            </Row>
            <CustomDivider color='#183582' marginTop={18} marginBottom={14} />
            <Row>
              <Label>Tiempo de lectura</Label>
              <Quantity>{getTime()}</Quantity>
            </Row>
          </Container>
        </ClickOutsideDetector>
      )}
    </>
  );
}

const CustomIconButton = styled(IconButton)`
  position: fixed;
  bottom: 30px;
  left: 30px;
  height: 40px;
  width: 40px;
  background-color: ${(props) => props.theme.colors.matizBlue};
  margin-right: 13px;
  z-index: 999;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);

  @media screen and (max-width: 860px) {
    bottom: 80px;
    left: 20px;
  }
`;

const Container = styled(motion.div)`
  position: fixed;
  bottom: 100px;
  left: 30px;
  box-shadow: 1px 1px 10px rgba(113, 112, 112, 0.15);
  border-radius: 10px;
  padding-top: 32px;
  padding-bottom: 25px;
  padding-left: 26px;
  background-color: white;
  z-index: 100;

  @media screen and (max-width: 860px) {
    bottom: 130px;
  }
  @media screen and (max-width: 560px) {
    left: 5px;
    right: 5px;
  }
`;

const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
  padding-right: 16px;
`;

const CloseIconButton = styled(IconButton)`
  display: none;
  @media screen and (max-width: 860px) {
    display: inherit;
  }
`;

const Title = styled.p`
  flex: 1;
  font-family: ${(props) => props.theme.fonts.bold};
  color: ${(props) => props.theme.colors.primary};
  font-size: 18px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-right: 26px;
`;

const Label = styled.p<LabelI>`
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => (props.warning ? props.theme.colors.red : props.theme.colors.darkGray)};
  margin-right: 80px;
`;

const Quantity = styled.p<LabelI>`
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => (props.warning ? props.theme.colors.red : props.theme.colors.darkGray)};
`;
