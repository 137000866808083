import React from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { type SlideProps } from '@mui/material';

interface Props {
  children: React.ReactNode;
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  paddingTop?: number;
  paddingBottom?: number;
  paddingLeft?: number;
  paddingRight?: number;
  setIsMainValue?: React.Dispatch<React.SetStateAction<string>>;
  enabled?: boolean;
}

const Transition = React.forwardRef(function Transition(props: SlideProps, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export default function ModalContainer({
  children,
  isVisible,
  setIsVisible,
  paddingTop = 35,
  paddingBottom = 25,
  paddingLeft = 25,
  paddingRight = 25,
  setIsMainValue,
  enabled = true,
}: Props): JSX.Element {
  const handleClose = (): void => {
    if (!enabled) return;
    setIsVisible(false);
    if (setIsMainValue) {
      setIsMainValue('');
    }
  };

  return (
    <Dialog
      open={isVisible}
      onClose={handleClose}
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          backgroundColor: 'white',
          borderRadius: '15px',
          paddingTop: `${paddingTop}px`,
          paddingBottom: `${paddingBottom}px`,
          paddingLeft: `${paddingLeft}px`,
          paddingRight: `${paddingRight}px`,
        },
      }}
    >
      {children}
    </Dialog>
  );
}
