import React from 'react';
import styled from 'styled-components';
import capitalizeFirstLetter from '../../utils/capitalizeFirstLetter';

interface Props {
  tag: string;
}

export default function TagItem({ tag }: Props) {
  return (
    <Container>
      <Tag>{capitalizeFirstLetter(tag)}</Tag>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dae1ff;
  height: 32px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 10px;
`;

const Tag = styled.p`
  font-weight: 500;
  font-size: 15px;
`;
