import React from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import SAELogo from '../globals/SAELogo';
import { setInputOfGeneralSearch } from '../../context/reducers/generalSearch';
import { useDispatch } from 'react-redux';

export default function HeaderLogo(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = (): void => {
    dispatch(
      setInputOfGeneralSearch({
        searchInput: '',
      }),
    );
  };

  return (
    <Container to={'/'} onClick={handleClick}>
      <SAELogoContainer>
        <SAELogo width={120} height={60} />
      </SAELogoContainer>
      <LineContainer>
        <Line />
      </LineContainer>
    </Container>
  );
}

const Container = styled(Link)`
  width: 192px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SAELogoContainer = styled.div`
  cursor: pointer;
  flex: 1;
`;

const LineContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;

  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const Line = styled.div`
  background-color: ${(props) => props.theme.colors.lightGray};
  width: 1px;
  height: 54px;
`;
