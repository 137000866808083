/* eslint-disable react/prop-types */
/* eslint-disable array-callback-return */
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './index.css';
import React, { useEffect, useRef, useState } from 'react';
import { Editor, type SyntheticKeyboardEvent } from 'react-draft-wysiwyg';
import { useDispatch, useSelector } from 'react-redux';
import {
  ContentState,
  EditorState,
  KeyBindingUtil,
  Modifier,
  convertToRaw,
  getDefaultKeyBinding,
  ContentBlock,
  RichUtils,
  CompositeDecorator,
  Entity,
  type DraftHandleValue,
  DefaultDraftBlockRenderMap,
  SelectionState,
} from 'draft-js';
import styled from 'styled-components';
import Immutable from 'immutable';
import {
  blurAnEditor,
  focusAnEditor,
  setBlockKeyToDelete,
  setCurrentSelectedEditorState,
  setCurrentSelectedLink,
  setElementsContent,
  setSelectedEditorId,
  setSelectedEditorKey,
  setUnsavedChanges,
  setWysiwygCurrentSelectionHeight,
  toggleIsCustomLinkModalVisible,
} from '../../context/reducers/editor';
import { type RootState } from '../../context/store';
import generateRandomoUUID from '../../utils/generateRandomUUID';
import { ARTICLE_TITLE_MAX_LENGTH } from '../../constants/globals';
import {
  KEY_IDEAS,
  KEY_IDEA_ITEM,
  KEY_PARAGRAPH,
  WYSIWYG_MAIN_SUBTITLE,
  WYSIWYG_MAIN_TITLE,
} from '../../constants/editorKeys';
import CustomDivider from '../globals/CustomDivider';
import ResizableImage from './ResizableImage';
import { getPlainText, selectEntityByKey } from '../../utils/wysiwyg';
import POSTS_STATUS from '../../constants/status';

interface Props {
  customKey: string;
  placeholder: string;
  fontSize?: string;
  color?: string;
  fontFamily?: string;
  fontWeight?: number;
  fontStyle?: string;
  editorState?: EditorState;
  setEditorState?: React.Dispatch<React.SetStateAction<EditorState>>;
  isFocus?: boolean;
  setIsFocus?: React.Dispatch<React.SetStateAction<boolean>>;
  handleFocusWysiwyg?: () => void;
  handleBlurWysiwyg?: () => void;
  maximumOfCharacters?: number | null;
  keyNumber?: number | null;
}

export default function CustomDraftWysiwyg({
  customKey = '',
  placeholder,
  fontSize = '16px',
  color = 'black',
  fontFamily = 'Arial',
  fontWeight = 400,
  fontStyle = 'normal',
  editorState,
  setEditorState,
  handleFocusWysiwyg,
  handleBlurWysiwyg,
  maximumOfCharacters = null,
  keyNumber = null,
}: Props): JSX.Element {
  const selectedEditorKey = useSelector((state: RootState) => state.editor.selectedEditorKey);
  const currentPostStatus = useSelector((state: RootState) => state.editor.currentPostStatus);
  const isPreviewModeActive = useSelector((state: RootState) => state.toolBar.isPreviewModeActive);
  const elementsContent = useSelector((state: RootState) => state.editor.elementsContent);
  const currentSelectedEditorState = useSelector(
    (state: RootState) => state.editor.currentSelectedEditorState,
  );
  const editSomeStyleOfCurrentSelectedEditor = useSelector(
    (state: RootState) => state.editor.editSomeStyleOfCurrentSelectedEditor,
  );
  const selectedEditorId = useSelector((state: RootState) => state.editor.selectedEditorId);
  const blockKeyToDelete = useSelector((state: RootState) => state.editor.blockKeyToDelete);

  const [auxEditorState, setAuxEditorState] = useState(
    editorState !== undefined ? editorState : EditorState.createEmpty(),
  );
  const [isFocus, setIsFocus] = useState(false);
  const [isMouseOverEditor, setIsMouseOverEditor] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const editorRef = useRef<Editor>(null);
  const editorWrapperRef = useRef<HTMLDivElement>(null);
  const editorId = useRef(generateRandomoUUID());
  const dispatch = useDispatch();

  const handleFocus = (): void => {
    setReadOnly(false);
    dispatch(focusAnEditor({}));
    dispatch(setSelectedEditorKey({ selectedEditorKey: customKey }));
    dispatch(setSelectedEditorId({ selectedEditorId: editorId.current }));
    dispatch(setCurrentSelectedEditorState({ currentSelectedEditorState: auxEditorState }));
    if (setIsFocus !== undefined) {
      setIsFocus(true);
    }
    if (handleFocusWysiwyg !== undefined) {
      handleFocusWysiwyg();
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
    const clickedElement =
      (event.relatedTarget as HTMLElement) || (document.activeElement as HTMLElement);
    const tagName = clickedElement?.tagName;
    const clickedElementId = clickedElement?.id;

    if (
      clickedElement === null ||
      (tagName === 'INPUT' &&
        clickedElementId !== 'custom-link-modal-text' &&
        clickedElementId !== 'custom-link-modal-url') ||
      clickedElementId === 'editor-save-button' ||
      clickedElementId === 'editor-preview-mode-button' ||
      clickedElementId === 'editor-setting-button' ||
      clickedElementId === 'editor-back-button' ||
      clickedElementId === 'editor-words-counter'
    ) {
      dispatch(setSelectedEditorKey({ selectedEditorKey: null }));
    }
    dispatch(blurAnEditor({}));
    if (setIsFocus !== undefined) {
      setIsFocus(false);
    }
    if (handleBlurWysiwyg !== undefined) {
      handleBlurWysiwyg();
    }
  };

  const selection = auxEditorState.getSelection();

  useEffect(() => {
    if (
      (customKey === WYSIWYG_MAIN_TITLE && elementsContent[0].content) ||
      (customKey === WYSIWYG_MAIN_SUBTITLE && elementsContent[1].content) ||
      (customKey === KEY_PARAGRAPH && elementsContent[3].content)
    ) {
      if (typeof elementsContent[0].content === 'string' && customKey === WYSIWYG_MAIN_TITLE) {
        setAuxEditorState(
          EditorState.createWithContent(ContentState.createFromText(elementsContent[0].content)),
        );
      }
      if (typeof elementsContent[1].content === 'string' && customKey === WYSIWYG_MAIN_SUBTITLE) {
        setAuxEditorState(
          EditorState.createWithContent(ContentState.createFromText(elementsContent[1].content)),
        );
      }
      if (
        customKey === KEY_PARAGRAPH &&
        elementsContent[3].content !== undefined &&
        elementsContent[3].content instanceof EditorState
      ) {
        setAuxEditorState(elementsContent[3].content);
      }
    }
  }, []);

  useEffect(() => {
    const isCollapsed = editorState?.getSelection().isCollapsed();
    const isFocus = editorState?.getSelection().getHasFocus();
    if (!isCollapsed && isFocus) {
      setAuxEditorState(EditorState.forceSelection(auxEditorState, auxEditorState.getSelection()));
    }
  }, [selection.isCollapsed(), selection.getHasFocus()]);

  useEffect(() => {
    if (auxEditorState !== null && editorId.current === selectedEditorId) {
      dispatch(
        setCurrentSelectedEditorState({
          currentSelectedEditorState: auxEditorState,
        }),
      );
    }
  }, [auxEditorState]);

  useEffect(() => {
    if (editorId.current === selectedEditorId) {
      setAuxEditorState(currentSelectedEditorState);
    }
  }, [editSomeStyleOfCurrentSelectedEditor]);

  useEffect(() => {
    if (customKey === WYSIWYG_MAIN_TITLE || customKey === WYSIWYG_MAIN_SUBTITLE) {
      dispatch(
        setElementsContent({
          customKey,
          content: getPlainText(auxEditorState)?.trim(),
        }),
      );
    } else if (customKey === KEY_PARAGRAPH) {
      dispatch(
        setElementsContent({
          customKey,
          content: auxEditorState,
        }),
      );
    } else if (customKey === KEY_IDEA_ITEM) {
      const keyIdeasIndex = elementsContent.findIndex((item) => item.customKey === KEY_IDEAS);
      if (keyIdeasIndex !== -1 && keyNumber !== null) {
        const newKeyIdeas = [...(elementsContent[keyIdeasIndex].content as EditorState[])];
        if (newKeyIdeas !== null && newKeyIdeas !== undefined) {
          newKeyIdeas[keyNumber] = auxEditorState;
          dispatch(
            setElementsContent({
              customKey: KEY_IDEAS,
              content: newKeyIdeas,
            }),
          );
        }
      }
    }
  }, [auxEditorState]);

  const determineChangeType = (
    currentContentState: ContentState,
    previousContentState: ContentState,
  ) => {
    const currentBlockMap = currentContentState.getBlockMap();
    const previousBlockMap = previousContentState.getBlockMap();
    if (
      currentBlockMap.size > previousBlockMap.size ||
      currentBlockMap.size < previousBlockMap.size ||
      currentContentState !== previousContentState
    ) {
      dispatch(
        setUnsavedChanges({
          unsavedChanges: true,
        }),
      );
    }
  };

  const isCustomImageBlock = (contentBlock: ContentBlock) => {
    return contentBlock.getType() === 'custom-image';
  };

  const onEditorStateChange = (newEditorState: EditorState): void => {
    const currentState = auxEditorState;
    const newCurrentContent = newEditorState.getCurrentContent();
    const selection = newEditorState.getSelection();
    const blockKey = selection.getStartKey();
    const contentBlock = newCurrentContent.getBlockForKey(blockKey);
    determineChangeType(newCurrentContent, currentState.getCurrentContent());

    if (isCustomImageBlock(contentBlock)) {
      setAuxEditorState(EditorState.forceSelection(currentState, selection));
    } else {
      if (setEditorState !== undefined) {
        setEditorState(newEditorState);
      }
      setAuxEditorState(newEditorState);
    }
  };

  const handleMouseOver = (): void => {
    setIsMouseOverEditor(true);
  };

  const handleMouseOut = (): void => {
    setIsMouseOverEditor(false);
  };

  const handleKeyCommand = (
    command: string,
    newEditorState: EditorState,
  ): 'handled' | 'not-handled' => {
    if (command === 'split-block' || command === 'backspace') {
      dispatch(
        setUnsavedChanges({
          unsavedChanges: true,
        }),
      );
      if (command === 'split-block') {
        const selectionState = newEditorState.getSelection();
        const contentState = newEditorState.getCurrentContent();
        const currentBlock = contentState.getBlockForKey(selectionState.getStartKey());
        const blockType = currentBlock.getType();
        if (blockType === 'custom-divider' || blockType === 'custom-image') {
          return 'handled';
        }
      }
      if (command === 'backspace') {
        const selection = newEditorState.getSelection();
        const currentContent = newEditorState.getCurrentContent();
        const blockKey = selection.getStartKey();
        const contentBlock = currentContent.getBlockForKey(blockKey);

        if (isCustomImageBlock(contentBlock)) {
          const newContent = Modifier.removeRange(currentContent, selection, 'backward');
          newEditorState = EditorState.push(newEditorState, newContent, 'remove-range');
          setAuxEditorState(
            EditorState.forceSelection(newEditorState, newContent.getSelectionAfter()),
          );
          return 'not-handled';
        } else if (
          selection.isCollapsed() &&
          contentBlock.getLength() === 0 &&
          !contentBlock.getText()
        ) {
          const blockBefore = currentContent.getBlockBefore(blockKey);
          const newContentState = Modifier.removeRange(
            currentContent,
            new SelectionState({
              anchorKey: blockBefore?.getKey(),
              anchorOffset: blockBefore?.getLength(),
              focusKey: blockKey,
              focusOffset: contentBlock.getLength(),
            }),
            'backward',
          );
          newEditorState = EditorState.push(newEditorState, newContentState, 'remove-range');
          setAuxEditorState(
            EditorState.forceSelection(newEditorState, newContentState.getSelectionAfter()),
          );
          return 'handled';
        }
      }
    }

    return 'not-handled';
  };

  const addCustomElement = (type: string) => {
    const currentContentState = auxEditorState.getCurrentContent();
    const selectionState = auxEditorState.getSelection();
    const currentBlockKey = selectionState.getStartKey();
    const currentBlock = currentContentState.getBlockForKey(currentBlockKey);

    const componentBlock = new ContentBlock({
      key: Math.random().toString(36).substr(2, 9),
      type,
      text: '',
    });

    let newContentState;
    let newEditorState;

    if (currentBlockKey === currentContentState.getLastBlock().getKey()) {
      newContentState = ContentState.createFromBlockArray([
        ...currentContentState.getBlocksAsArray(),
        componentBlock,
      ]);
      newEditorState = EditorState.createWithContent(newContentState);
    } else {
      const blockArray = currentContentState.getBlocksAsArray();
      const currentIndex = blockArray.findIndex((block) => block.getKey() === currentBlockKey);
      const blocksAfter = blockArray.slice(currentIndex + 1);
      const newBlocks = [...blockArray.slice(0, currentIndex + 1), componentBlock, ...blocksAfter];

      newContentState = ContentState.createFromBlockArray(newBlocks);
      newEditorState = EditorState.createWithContent(newContentState);
    }

    const emptyBlock = new ContentBlock({
      key: Math.random().toString(36).substr(2, 9),
      type: 'unstyled',
      text: '',
    });
    const newContentStateWithEmptyBlock = ContentState.createFromBlockArray([
      ...newContentState.getBlocksAsArray(),
      emptyBlock,
    ]);
    const newEditorStateWithEmptyBlock = EditorState.createWithContent(
      newContentStateWithEmptyBlock,
    );

    const selectionStateWithFocus = newEditorStateWithEmptyBlock.getSelection().merge({
      anchorKey: emptyBlock.getKey(),
      anchorOffset: 0,
      focusKey: emptyBlock.getKey(),
      focusOffset: 0,
    });
    const editorStateWithFocus = EditorState.forceSelection(
      newEditorStateWithEmptyBlock,
      selectionStateWithFocus,
    );

    setAuxEditorState(editorStateWithFocus);
  };

  const renderResizableImage = (props: any) => {
    return <ResizableImage customKey='image' index={props?.block?.getKey()} {...props} />;
  };

  const renderCustomDivider = (props: any) => {
    return <CustomDivider {...props} readOnly={true} />;
  };

  const handleDeleteCustomComponent = (componentKey: string) => {
    const contentState = auxEditorState.getCurrentContent();
    const blockMap = contentState.getBlockMap();
    const newBlockMap = blockMap.delete(componentKey);
    const newContentState = ContentState.createFromBlockArray(newBlockMap.toArray());

    const newEditorState = EditorState.push(auxEditorState, newContentState, 'remove-range');
    setAuxEditorState(newEditorState);
    dispatch(setUnsavedChanges({ unsavedChanges: true }));
  };

  useEffect(() => {
    if (blockKeyToDelete !== null) {
      handleDeleteCustomComponent(blockKeyToDelete);
      dispatch(setBlockKeyToDelete({ blockKeyToDelete: null }));
    }
  }, [blockKeyToDelete]);

  const customBlockRenderer = (contentBlock: ContentBlock) => {
    const blockType = contentBlock.getType();
    if (blockType === 'custom-divider') {
      return {
        component: renderCustomDivider,
        editable: true,
      };
    } else if (blockType === 'custom-image') {
      return {
        component: renderResizableImage,
        editable: currentPostStatus === POSTS_STATUS.DRAFT,
        props: {
          key: contentBlock.getKey(),
        },
      };
    }
  };

  const handleClickEditorWrapper = () => {
    if (
      currentSelectedEditorState !== null &&
      selectedEditorKey !== WYSIWYG_MAIN_TITLE &&
      selectedEditorKey !== WYSIWYG_MAIN_SUBTITLE &&
      selectedEditorKey !== KEY_IDEA_ITEM &&
      selectedEditorKey !== null
    ) {
      const selection = auxEditorState.getSelection();
      const blockKey = selection.getStartKey();
      const block = auxEditorState.getCurrentContent().getBlockForKey(blockKey);
      const entityKey = block.getEntityAt(selection.getStartOffset());

      if (entityKey) {
        const entity = auxEditorState.getCurrentContent().getEntity(entityKey);
        const entityType = entity.getType();
        if (entityType === 'LINK') {
          const newEditorState = selectEntityByKey(auxEditorState, entityKey);
          if (newEditorState !== null) {
            const newSelection = newEditorState.getSelection();
            const startOffset = newSelection.getStartOffset();
            const endOffset = newSelection.getEndOffset();
            const block = newEditorState.getCurrentContent().getBlockForKey(blockKey);
            const linkText = block.getText().slice(startOffset, endOffset);
            const linkData = entity?.getData();
            const linkUrl = linkData.url;
            setAuxEditorState(newEditorState);
            dispatch(
              setCurrentSelectedLink({
                url: linkUrl,
                title: linkText,
              }),
            );

            dispatch(
              toggleIsCustomLinkModalVisible({
                isCustomLinkModalVisible: true,
              }),
            );
          }
        }
      }
    }
  };

  return (
    <EditorWrapper
      onClick={handleClickEditorWrapper}
      ref={editorWrapperRef}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      style={{
        borderColor:
          isPreviewModeActive || customKey === KEY_PARAGRAPH
            ? 'transparent'
            : isFocus
            ? '#0094CF'
            : isMouseOverEditor
            ? '#b7b6b6'
            : 'transparent',
        paddingBottom: customKey === KEY_PARAGRAPH ? '8px' : '0px',
      }}
    >
      <Editor
        ref={editorRef}
        readOnly={
          readOnly ||
          isPreviewModeActive ||
          currentPostStatus === null ||
          currentPostStatus === POSTS_STATUS.PUBLISHED
        }
        onFocus={handleFocus}
        onBlur={handleBlur}
        editorState={auxEditorState}
        onEditorStateChange={onEditorStateChange}
        customBlockRenderFunc={customBlockRenderer}
        toolbarHidden
        editorStyle={{
          fontFamily,
          fontSize,
          fontStyle,
          color,
          fontWeight,
          padding: '0px',
          paddingTop: '0px',
          boxSizing: 'border-box',
        }}
        placeholder={placeholder}
        wrapperStyle={{
          boxSizing: 'border-box',
          paddingTop: '0px',
          paddingBottom: '0px',
        }}
        handleKeyCommand={handleKeyCommand}
      />
    </EditorWrapper>
  );
}

const EditorWrapper = styled.div`
  border: 1px solid transparent;
  transition: border 0.1s ease-in;
  border-radius: 10px;
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 20px;
  :hover {
    border-color: ${(props) => props.theme.colors.gray2};
  }
`;
