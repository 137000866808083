import React, { type MutableRefObject } from 'react';
import styled, { css } from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useSelector, useDispatch } from 'react-redux';
import { type RootState } from '../../context/store';
import type Category from '../../interfaces/Category';
import { CircularProgress } from '@mui/material';
import type Subscription from '../../interfaces/Subscription';
import {
  deleteAuthorFromPost,
  deleteCategoryFromPost,
  deleteSubscriptionsFromPost,
  deleteTagFromPost,
} from '../../api/posts';
import { useParams } from 'react-router-dom';
import { setIsRemovingSubscription, setSubscriptionId } from '../../context/reducers/subscriptions';
import { openSnackbar } from '../../context/reducers/generalSnackbar';
import { AxiosError } from 'axios';
import { setCategoryId, setIsRemovingCategory } from '../../context/reducers/categories';
import { setIsRemovingTag, setTagId } from '../../context/reducers/tags';
import { setAuthorId, setIsRemovingAuthor } from '../../context/reducers/authors';
import { SETTINGS_REMOVE_SUCCESFUL_MESSAGES } from '../../constants/successfulMessages';
import { callPostDetails } from '../../utils/editor';

interface Props {
  page?: string;
  handleDelete: (tag: string) => void;
  type: string;
  item: any;
  hiddenOptionsCount?: number;
  finalComboBoxWidth: number;
  visibleWidth: number;
  lastOptionWidth: number;
  totalRealVisibleWidth: number;
  isTheLast?: boolean;
  setCurrentVersion?: React.Dispatch<React.SetStateAction<number>>;
}

interface NameI {
  type: string;
  color?: string;
  page?: string;
}

interface ContainerI {
  page?: string;
  type: string;
  bgColor: string;
}

export default function TagChip({
  page = 'editor',
  type,
  item,
  handleDelete,
  hiddenOptionsCount,
  finalComboBoxWidth,
  visibleWidth,
  lastOptionWidth,
  totalRealVisibleWidth,
  isTheLast = false,
  setCurrentVersion,
}: Props): JSX.Element {
  const categoriesList = useSelector((state: RootState) => state.categories.categoriesList);
  const subscriptionsList = useSelector((state: RootState) => state.subscriptions.subscriptions);
  const isRemovingSubscription = useSelector(
    (state: RootState) => state.subscriptions.isRemovingSubscription,
  );
  const isRemovingCategory = useSelector((state: RootState) => state.categories.isRemovingCategory);
  const isRemovingTag = useSelector((state: RootState) => state.tags.isRemovingTag);
  const isRemovingAuthor = useSelector((state: RootState) => state.authors.isRemovingAuthor);
  const subscriptionId = useSelector((state: RootState) => state.subscriptions.subscriptionId);
  const categoryId = useSelector((state: RootState) => state.categories.categoryId);
  const authorId = useSelector((state: RootState) => state.authors.authorId);
  const tagId = useSelector((state: RootState) => state.tags.tagId);
  const { postId } = useParams();
  const dispatch = useDispatch();

  const determineContentToRemoveLength = (): number => {
    if (!isTheLast) return 0;
    const contentToRemoveLenght = totalRealVisibleWidth - finalComboBoxWidth;
    if (contentToRemoveLenght <= 0) return 0;
    const newDifference = lastOptionWidth - contentToRemoveLenght;
    return newDifference <= 0 ? 0 : newDifference;
  };

  const getCategoryName = () => {
    if (categoriesList !== null) {
      const auxCategoriesList: Category[] = [...categoriesList];
      const index = auxCategoriesList?.findIndex((category) => category.id === item);
      return index === -1 ? '' : auxCategoriesList[index].name;
    }
    return '';
  };

  const getSubscriptionName = () => {
    if (subscriptionsList !== null) {
      const auxSubscriptionsList: Subscription[] = [...subscriptionsList];
      const index = auxSubscriptionsList?.findIndex((subscription) => subscription.id === item);
      return index === -1 ? '' : auxSubscriptionsList[index].name;
    }
    return '';
  };

  const removeCharactersFromEnd = (str: string, count: number) => {
    let quantityToRemove = str.length - count;
    if (count > str.length || str.length - count === 1) {
      quantityToRemove = 4;
    }
    let finalString = str.substring(0, quantityToRemove);
    if (count > 0 && count !== str.length) {
      finalString += '...';
    }
    return finalString;
  };

  const getCategoryColor = () => {
    if (categoriesList !== null) {
      const auxCategoriesList: Category[] = [...categoriesList];
      const index = auxCategoriesList?.findIndex((category) =>
        item.id ? category.id === item.id : category.id === item,
      );
      return index === -1 ? 'transparent' : auxCategoriesList[index]?.color;
    }
    return 'transparent';
  };

  const getCharacteresToRemoveQuantity = (length: number): number => {
    return length / 6;
  };

  const getCutName = (name: string): string => {
    if (page === 'editor') return name;
    const contentToRemoveLength = determineContentToRemoveLength();
    const characteresToRemoveQuantity = getCharacteresToRemoveQuantity(contentToRemoveLength);
    if (!isTheLast) return name;
    return removeCharactersFromEnd(name, Math.floor(characteresToRemoveQuantity));
  };

  const getName = (): string => {
    if (type === 'tag') {
      return item.tag;
    } else if (type === 'author') {
      return getCutName(item.names);
    } else if (type === 'categories') {
      return getCutName(item.name ?? getCategoryName());
    } else if (type === 'subscription') {
      return item.name ?? getSubscriptionName();
    }
    return '';
  };

  const getId = (): string => {
    if (type === 'tag') {
      return item.tag;
    } else if (type === 'author') {
      return item.authorId ?? item.id;
    } else if (type === 'categories' || type === 'subscription') {
      return item.id ?? item;
    }
    return '';
  };

  const handleRemoveSubscription = async () => {
    try {
      dispatch(
        setIsRemovingSubscription({
          isRemovingSubscription: true,
        }),
      );
      dispatch(
        setSubscriptionId({
          subscriptionId: getId(),
        }),
      );
      await deleteSubscriptionsFromPost(postId, getId());
      handleDelete(getId());
      dispatch(
        openSnackbar({
          type: 'success',
          message: SETTINGS_REMOVE_SUCCESFUL_MESSAGES.REMOVE_SUBSCRIPTION,
        }),
      );
      dispatch(
        setIsRemovingSubscription({
          isRemovingSubscription: false,
        }),
      );
      dispatch(
        setSubscriptionId({
          subscriptionId: null,
        }),
      );
      if (postId !== undefined && setCurrentVersion !== undefined) {
        await callPostDetails(postId, setCurrentVersion);
      }
    } catch (error: unknown) {
      dispatch(
        setIsRemovingSubscription({
          isRemovingSubscription: false,
        }),
      );
      handleDelete(getId());
    }
  };

  const handleRemoveCategory = async () => {
    try {
      dispatch(
        setIsRemovingCategory({
          isRemovingCategory: true,
        }),
      );
      dispatch(
        setCategoryId({
          categoryId: getId(),
        }),
      );
      await deleteCategoryFromPost(postId, getId());
      handleDelete(getId());
      dispatch(
        openSnackbar({
          type: 'success',
          message: SETTINGS_REMOVE_SUCCESFUL_MESSAGES.REMOVE_CATEGORY,
        }),
      );
      dispatch(
        setIsRemovingCategory({
          isRemovingCategory: false,
        }),
      );
      dispatch(
        setCategoryId({
          categoryId: null,
        }),
      );
      if (postId !== undefined && setCurrentVersion !== undefined) {
        await callPostDetails(postId, setCurrentVersion);
      }
    } catch (error: unknown) {
      dispatch(
        setIsRemovingCategory({
          isRemovingCategory: false,
        }),
      );
      handleDelete(getId());
    }
  };

  const handleRemoveTag = async () => {
    try {
      dispatch(
        setIsRemovingTag({
          isRemovingTag: true,
        }),
      );
      dispatch(
        setTagId({
          tagId: getId(),
        }),
      );
      await deleteTagFromPost(postId, getId());
      handleDelete(getId());
      dispatch(
        openSnackbar({
          type: 'success',
          message: SETTINGS_REMOVE_SUCCESFUL_MESSAGES.REMOVE_TAG,
        }),
      );
      dispatch(
        setIsRemovingTag({
          isRemovingTag: false,
        }),
      );
      dispatch(
        setTagId({
          tagId: null,
        }),
      );
      if (postId !== undefined && setCurrentVersion !== undefined) {
        await callPostDetails(postId, setCurrentVersion);
      }
    } catch (error: unknown) {
      dispatch(
        setIsRemovingTag({
          isRemovingTag: false,
        }),
      );
      handleDelete(getId());
    }
  };

  const handleRemoveAuthor = async () => {
    try {
      dispatch(
        setIsRemovingAuthor({
          isRemovingAuthor: true,
        }),
      );
      dispatch(
        setAuthorId({
          authorId: getId(),
        }),
      );
      await deleteAuthorFromPost(postId, parseInt(getId()));
      handleDelete(getId());
      dispatch(
        openSnackbar({
          type: 'success',
          message: SETTINGS_REMOVE_SUCCESFUL_MESSAGES.REMOVE_AUTHOR,
        }),
      );
      dispatch(
        setIsRemovingAuthor({
          isRemovingAuthor: false,
        }),
      );
      dispatch(
        setAuthorId({
          authorId: null,
        }),
      );
      if (postId !== undefined && setCurrentVersion !== undefined) {
        await callPostDetails(postId, setCurrentVersion);
      }
    } catch (error: unknown) {
      dispatch(
        setIsRemovingAuthor({
          isRemovingAuthor: false,
        }),
      );
      handleDelete(getId());
    }
  };

  const handleClick = () => {
    switch (type) {
      case 'subscription':
        handleRemoveSubscription();
        break;
      case 'categories':
        handleRemoveCategory();
        break;
      case 'tag':
        handleRemoveTag();
        break;
      case 'author':
        handleRemoveAuthor();
        break;
      default:
    }
  };

  return (
    <Container
      page={page}
      type={type}
      bgColor={getCategoryColor()}
      style={{
        display: page === 'entries' && getName().length === 0 ? 'none' : 'inherit',
      }}
    >
      {(type === 'categories' && categoriesList === null) ||
      (type === 'subscription' && subscriptionsList === null) ? (
        <CircularProgress size={15} />
      ) : (
        <Name type={type} page={page}>
          {getName()}
        </Name>
      )}
      {page === 'editor' &&
      (type === 'subscription' || type === 'categories' || type === 'tag' || type === 'author') ? (
        (isRemovingSubscription && subscriptionId === getId()) ||
        (isRemovingCategory && categoryId === getId()) ||
        (isRemovingTag && tagId === getId()) ||
        (isRemovingAuthor && authorId === getId()) ? (
          <div
            style={{
              paddingLeft: '10px',
              paddingRight: '10px',
              marginTop: '7px',
              marginBottom: '7px',
            }}
          >
            <CircularProgress size={14} />
          </div>
        ) : (
          <StyledIconButton
            disabled={
              isRemovingSubscription || isRemovingCategory || isRemovingTag || isRemovingAuthor
            }
          >
            <CloseIcon
              onClick={handleClick}
              style={{ fontSize: '15px', color: type === 'categories' ? 'white' : 'black' }}
            />
          </StyledIconButton>
        )
      ) : (
        <StyledIconButton
          disabled={
            isRemovingSubscription || isRemovingCategory || isRemovingTag || isRemovingAuthor
          }
        >
          <CloseIcon
            onClick={() => {
              handleDelete(getId());
            }}
            style={{ fontSize: '15px', color: type === 'categories' ? 'white' : 'black' }}
          />
        </StyledIconButton>
      )}
    </Container>
  );
}

const Container = styled.div<ContainerI>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${(props) => (props.type === 'categories' ? props.bgColor : '#F0F5FF')};
  border-radius: 20px;
  padding-left: 13px;
  min-height: 25px;

  ${(props) =>
    props.page === 'entries' &&
    css`
      height: 25px;
    `}
`;

const StyledIconButton = styled(IconButton)`
  width: 30px;
`;

const Name = styled.p<NameI>`
  font-family: ${(props) => props.theme.fonts.regular};
  font-size: 13px;
  color: ${(props) => (props.type === 'categories' ? 'white' : 'black')};
  ${(props) =>
    props.page === 'entries' &&
    css`
      white-space: nowrap;
    `}
`;
