import { createSlice } from '@reduxjs/toolkit';
import type PublicationInterface from '../../interfaces/Publication';

interface InitialState {
  publications: PublicationInterface[] | null;
  currentPage: number;
  isLoadingMoreData: boolean;
  canLoadMoreData: boolean;
  isDrawerOpen: boolean;
}

const initialState: InitialState = {
  publications: null,
  currentPage: 0,
  isLoadingMoreData: false,
  canLoadMoreData: false,
  isDrawerOpen: false,
};

const presentationsPublishedSlice = createSlice({
  name: 'presentationsPublished',
  initialState,
  reducers: {
    setPresentationsPublishedResetValues: (state, action) => {
      state.publications = null;
      state.currentPage = 0;
      state.isLoadingMoreData = false;
      state.canLoadMoreData = false;
    },
    setPresentationsPublished: (state, action) => {
      state.publications = action.payload.publications;
    },
    setPresentationsPublishedCurrentPage: (state, action) => {
      state.currentPage = action.payload.currentPage;
    },
    setPresentationsPublishedIsLoadingMoreData: (state, action) => {
      state.isLoadingMoreData = action.payload.isLoadingMoreData;
    },
    setPresentationsPublishedCanLoadMoreData: (state, action) => {
      state.canLoadMoreData = action.payload.canLoadMoreData;
    },
    setPresentationsPublishedIsDrawerOpen: (state, action) => {
      state.isDrawerOpen = action.payload.isDrawerOpen;
    },
  },
});

export const {
  setPresentationsPublished,
  setPresentationsPublishedCanLoadMoreData,
  setPresentationsPublishedCurrentPage,
  setPresentationsPublishedIsLoadingMoreData,
  setPresentationsPublishedResetValues,
  setPresentationsPublishedIsDrawerOpen,
} = presentationsPublishedSlice.actions;
export default presentationsPublishedSlice.reducer;
