import React from 'react';

export default function ScheduledCampaignIcon() {
  return (
    <svg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_4547_5015)'>
        <path
          d='M10.6367 1.94809V3.4735'
          stroke='#9A9A9A'
          strokeWidth='1.875'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M10.637 24.0625H10.6369C9.34291 24.0625 8.29395 23.0135 8.29395 21.7195V20.3402H12.98V21.7195C12.98 23.0135 11.931 24.0625 10.637 24.0625Z'
          stroke='#9A9A9A'
          strokeWidth='1.875'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M13.0047 4.29217C12.2731 3.99822 11.4741 3.8365 10.6373 3.8365C7.1283 3.8365 4.28369 6.68092 4.28369 10.1898V16.5012H16.991V13.4202'
          stroke='#9A9A9A'
          strokeWidth='1.875'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M1.93848 18.4207C1.93848 19.4808 2.79793 20.3402 3.85815 20.3402H17.4161C18.4764 20.3402 19.3358 19.4808 19.3358 18.4207C19.3358 17.3605 18.4764 16.5011 17.4161 16.5011H3.85815C2.79793 16.5011 1.93848 17.3605 1.93848 18.4207Z'
          stroke='#9A9A9A'
          strokeWidth='1.875'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M23.7887 9.43052C23.9655 8.87823 24.0613 8.28977 24.0613 7.67884C24.0613 4.50798 21.4909 1.9375 18.3202 1.9375C15.1494 1.9375 12.5791 4.50798 12.5791 7.67884C12.5791 10.8497 15.1495 13.4202 18.3202 13.4202C19.1514 13.4202 19.9413 13.2433 20.6545 12.9254'
          stroke='#9A9A9A'
          strokeWidth='1.875'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M22.1841 3.85408L14.4565 11.5817'
          stroke='#9A9A9A'
          strokeWidth='1.875'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_4547_5015'>
          <rect width='24' height='24' fill='white' transform='translate(1 1)' />
        </clipPath>
      </defs>
    </svg>
  );
}
