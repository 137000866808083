import React from 'react';
import type Icon from '../interfaces/Icon';

export default function InformationIcon({ color = '#1D7D54' }: Icon): JSX.Element {
  return (
    <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.00001 13.6667C3.31811 13.6667 0.333344 10.6819 0.333344 6.99999C0.333344 3.31809 3.31811 0.333328 7.00001 0.333328C10.6819 0.333328 13.6667 3.31809 13.6667 6.99999C13.6667 10.6819 10.6819 13.6667 7.00001 13.6667ZM7.00001 12.3333C9.94554 12.3333 12.3333 9.94553 12.3333 6.99999C12.3333 4.05447 9.94554 1.66666 7.00001 1.66666C4.05449 1.66666 1.66668 4.05447 1.66668 6.99999C1.66668 9.94553 4.05449 12.3333 7.00001 12.3333ZM6.33334 3.66666H7.66668V4.99999H6.33334V3.66666ZM6.33334 6.33333H7.66668V10.3333H6.33334V6.33333Z'
        fill={color}
      />
    </svg>
  );
}
