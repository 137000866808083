import React from 'react';
import type Icon from '../interfaces/Icon';

export default function ListingIcon({ color = '#9A9A9A' }: Icon) {
  return (
    <svg width='26' height='25' viewBox='0 0 26 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_641_1892)'>
        <path
          d='M6.18184 0H1.63638C1.00879 0 0.5 0.508789 0.5 1.13638V5.68184C0.5 6.30942 1.00879 6.81821 1.63638 6.81821H6.18184C6.80942 6.81821 7.31821 6.30942 7.31821 5.68184V1.13638C7.31816 0.508789 6.80937 0 6.18184 0Z'
          fill={color}
        />
        <path
          d='M6.18184 9.09094H1.63638C1.00879 9.09094 0.5 9.59973 0.5 10.2273V14.7728C0.5 15.4004 1.00879 15.9092 1.63638 15.9092H6.18184C6.80942 15.9092 7.31821 15.4004 7.31821 14.7728V10.2273C7.31816 9.59973 6.80937 9.09094 6.18184 9.09094Z'
          fill={color}
        />
        <path
          d='M6.18184 18.1819H1.63638C1.00879 18.1819 0.5 18.6907 0.5 19.3182V23.8637C0.5 24.4913 1.00879 25 1.63638 25H6.18184C6.80942 25 7.31821 24.4913 7.31821 23.8637V19.3182C7.31816 18.6907 6.80937 18.1819 6.18184 18.1819ZM5.04546 22.7273H2.77271V20.4546H5.04541V22.7273H5.04546Z'
          fill={color}
        />
        <path
          d='M24.3636 2.27271H10.7273C10.0997 2.27271 9.59094 2.78149 9.59094 3.40908C9.59094 4.03667 10.0997 4.54546 10.7273 4.54546H24.3637C24.9913 4.54546 25.5001 4.03667 25.5001 3.40908C25.5 2.78149 24.9912 2.27271 24.3636 2.27271Z'
          fill={color}
        />
        <path
          d='M24.3636 20.4546H10.7273C10.0997 20.4546 9.59094 20.9634 9.59094 21.591C9.59094 22.2186 10.0997 22.7273 10.7273 22.7273H24.3637C24.9913 22.7273 25.5001 22.2186 25.5001 21.591C25.5001 20.9634 24.9912 20.4546 24.3636 20.4546Z'
          fill={color}
        />
        <path
          d='M24.3636 11.3636H10.7273C10.0997 11.3636 9.59094 11.8724 9.59094 12.5C9.59094 13.1276 10.0997 13.6364 10.7273 13.6364H24.3637C24.9912 13.6364 25.5 13.1276 25.5 12.5C25.5 11.8724 24.9912 11.3636 24.3636 11.3636Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_641_1892'>
          <rect width='25' height='25' fill='white' transform='translate(0.5)' />
        </clipPath>
      </defs>
    </svg>
  );
}
