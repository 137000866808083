import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate, type Location } from 'react-router-dom';
import { ListItemIcon } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import classNames from 'classnames';

import type SideBarOptionItemInterface from '../../interfaces/SideBarOptionItem';
import SideBarSubOptionItem from './SideBarSubOptionItem';
import { setInputOfGeneralSearch } from '../../context/reducers/generalSearch';
import { useDispatch } from 'react-redux';
import { setSelectedState } from '../../context/reducers/entries';
import POSTS_STATUS from '../../constants/status';

const isPathSelected = (path: string, location: Location): boolean => {
  return path === '/'
    ? location.pathname === path
    : location.pathname.startsWith(path) ||
        (path.startsWith(location.pathname) && location.search !== null && location.search !== '');
};

export default function SideBarOptionItem({
  title,
  icon,
  path,
  subOptions,
}: SideBarOptionItemInterface): JSX.Element {
  const navigation = useNavigate();
  const location = useLocation();
  const [isSelected, setIsSelected] = useState(
    isPathSelected(path, location) || (path === '/' && location.pathname === '/search'),
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setIsSelected(
      isPathSelected(path, location) || (path === '/' && location.pathname === '/search'),
    );
  }, [location]);

  const handleOnClick = (): void => {
    dispatch(
      setInputOfGeneralSearch({
        searchInput: '',
      }),
    );
    if (subOptions === undefined) {
      navigation(path);
    } else {
      navigation(subOptions[0].path);
    }
  };

  const checkIfSomeOfSuptionIsSelected = (): boolean => {
    if (subOptions === undefined) {
      return false;
    }
    return subOptions?.some(
      (subOpt, index) =>
        location.pathname.startsWith(subOpt.path) ||
        (location.search !== null && location.search !== '' && location.pathname !== '/campaigns'),
    );
  };

  return (
    <>
      <MenuItem
        className={classNames('h-14 pl-5', {
          'bg-accent-blue': isSelected && subOptions === undefined,
        })}
        onClick={handleOnClick}
      >
        <ListItemIcon className='flex items-center justify-center'>{icon}</ListItemIcon>
        <Title>{title}</Title>
      </MenuItem>
      {subOptions !== undefined && checkIfSomeOfSuptionIsSelected() && (
        <SubOptionsList>
          {subOptions.map((sOpt, index) => (
            <SideBarSubOptionItem key={index} title={sOpt.title} path={sOpt.path} />
          ))}
        </SubOptionsList>
      )}
    </>
  );
}

const Title = styled.p`
  color: white;
  font-family: ${(props) => props.theme.fonts.regular};
  font-size: 16px;
  flex: 1;
`;

const SubOptionsList = styled.ul``;
