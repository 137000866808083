import { createSlice } from '@reduxjs/toolkit';
import type Subscription from '../../interfaces/Subscription';

interface InitialState {
  subscriptions: Subscription[] | null;
  isRemovingSubscription: boolean;
  subscriptionId: string | null;
}

const initialState: InitialState = {
  subscriptions: null,
  isRemovingSubscription: false,
  subscriptionId: null,
};

const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    setSubscriptionsList: (state, action) => {
      state.subscriptions = action.payload.subscriptions;
    },
    setIsRemovingSubscription: (state, action) => {
      state.isRemovingSubscription = action.payload.isRemovingSubscription;
    },
    setSubscriptionId: (state, action) => {
      state.subscriptionId = action.payload.subscriptionId;
    },
  },
});

export const { setSubscriptionsList, setIsRemovingSubscription, setSubscriptionId } =
  subscriptionsSlice.actions;
export default subscriptionsSlice.reducer;
