import React from 'react';
import { Button } from '@mui/material';
import FiltersIcon from '../../icons/FiltersIcon';
import styled from 'styled-components';
import POSTS_STATUS from '../../constants/status';
import { useDispatch } from 'react-redux';
import { setEntriesDraftsIsDrawerOpen } from '../../context/reducers/entriesDrafts';
import { setEntriesPendingReviewsIsDrawerOpen } from '../../context/reducers/entriesPendingReviews';
import { setEntriesPublishedIsDrawerOpen } from '../../context/reducers/entriesPublished';
import { setPresentationsDraftsIsDrawerOpen } from '../../context/reducers/presentationsDrafts';
import { setPresentationsPendingReviewsIsDrawerOpen } from '../../context/reducers/presentationsPendingReview';
import { setPresentationsPublishedIsDrawerOpen } from '../../context/reducers/presentationsPublished';

interface Props {
  type: string;
  contentType?: 'ARTICLE' | 'PRESENTATION';
}

export default function FiltersButton({ type, contentType = 'ARTICLE' }: Props) {
  const dispatch = useDispatch();

  const handleClick = () => {
    if (contentType === 'ARTICLE') {
      switch (type) {
        case POSTS_STATUS.DRAFT:
          dispatch(
            setEntriesDraftsIsDrawerOpen({
              isDrawerOpen: true,
            }),
          );
          break;
        case POSTS_STATUS.PENDING_REVIEW:
          dispatch(
            setEntriesPendingReviewsIsDrawerOpen({
              isDrawerOpen: true,
            }),
          );
          break;
        case POSTS_STATUS.PUBLISHED:
          dispatch(
            setEntriesPublishedIsDrawerOpen({
              isDrawerOpen: true,
            }),
          );
          break;
        default:
          break;
      }
    } else if (contentType === 'PRESENTATION') {
      switch (type) {
        case POSTS_STATUS.DRAFT:
          dispatch(
            setPresentationsDraftsIsDrawerOpen({
              isDrawerOpen: true,
            }),
          );
          break;
        case POSTS_STATUS.PENDING_REVIEW:
          dispatch(
            setPresentationsPendingReviewsIsDrawerOpen({
              isDrawerOpen: true,
            }),
          );
          break;
        case POSTS_STATUS.PUBLISHED:
          dispatch(
            setPresentationsPublishedIsDrawerOpen({
              isDrawerOpen: true,
            }),
          );
          break;
        default:
          break;
      }
    }
  };

  return (
    <StyledButton
      onClick={handleClick}
      className='bg-accent-blue px-5 py-2 rounded-2xl'
      endIcon={<FiltersIcon />}
    >
      <Text>Filtros</Text>
    </StyledButton>
  );
}

const StyledButton = styled(Button)``;

const Text = styled.p`
  text-transform: none;
  color: white;
`;
