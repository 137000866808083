import React from 'react';
import type Icon from '../interfaces/Icon';

export default function BulletListIcon({ color = '#3C3C3B' }: Icon): JSX.Element {
  return (
    <svg width='18' height='12' viewBox='0 0 18 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2.21345 4.71418C1.52773 4.71418 0.927734 5.31418 0.927734 5.9999C0.927734 6.68561 1.52773 7.28561 2.21345 7.28561C2.89916 7.28561 3.49916 6.68561 3.49916 5.9999C3.49916 5.31418 2.89916 4.71418 2.21345 4.71418ZM2.21345 0.428467C1.52773 0.428467 0.927734 1.02847 0.927734 1.71418C0.927734 2.3999 1.52773 2.9999 2.21345 2.9999C2.89916 2.9999 3.49916 2.3999 3.49916 1.71418C3.49916 1.02847 2.89916 0.428467 2.21345 0.428467ZM2.21345 8.9999C1.52773 8.9999 0.927734 9.5999 0.927734 10.2856C0.927734 10.9713 1.52773 11.5713 2.21345 11.5713C2.89916 11.5713 3.49916 10.9713 3.49916 10.2856C3.49916 9.5999 2.89916 8.9999 2.21345 8.9999ZM5.21345 0.857038V2.57132H17.2134V0.857038H5.21345ZM5.21345 11.1428H17.2134V9.42847H5.21345V11.1428ZM5.21345 6.85704H17.2134V5.14275H5.21345V6.85704Z'
        fill={color}
      />
    </svg>
  );
}
