import { createSlice } from '@reduxjs/toolkit';

interface InitialState {
  scheduledDeleted: boolean;
  scheduledAdded: boolean;
  processedDeleted: boolean;
  processedAdded: boolean;
  scheduledQuantity: undefined | number;
  processedQuantity: undefined | number;
}

const initialState: InitialState = {
  scheduledDeleted: false,
  scheduledAdded: false,
  processedDeleted: false,
  processedAdded: false,
  scheduledQuantity: undefined,
  processedQuantity: undefined,
};

const campaignsSlice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    setScheduledDeleted: (state, action) => {
      state.scheduledDeleted = action.payload.scheduledDeleted;
    },
    setScheduledAdded: (state, action) => {
      state.scheduledAdded = action.payload.scheduledAdded;
    },
    setProcessedDeleted: (state, action) => {
      state.processedDeleted = action.payload.processedDeleted;
    },
    setProcessedAdded: (state, action) => {
      state.processedAdded = action.payload.processedAdded;
    },
    setScheduledQuantity: (state, action) => {
      state.scheduledQuantity = action.payload.scheduledQuantity;
    },
    setProcessedQuantity: (state, action) => {
      state.processedQuantity = action.payload.processedQuantity;
    },
  },
});

export const {
  setProcessedAdded,
  setProcessedDeleted,
  setProcessedQuantity,
  setScheduledAdded,
  setScheduledDeleted,
  setScheduledQuantity,
} = campaignsSlice.actions;
export default campaignsSlice.reducer;
