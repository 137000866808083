import { apiClient } from './config';
import axios, { type CancelToken } from 'axios';

export const createCampaign = async (
  title: string,
  body: string,
  scheduledAt: Date | null,
  thumbnailUrl: string,
  publicationId: string,
  contentType?: string,
  publicationTitle?: string,
) => {
  const payload = {
    title,
    body,
    scheduledAt,
    thumbnailUrl,
    type: 'PUSH',
    channel: 'PUSH',
    params: {
      publication_id: publicationId,
      publication_content_type: contentType,
      publication_title: publicationTitle,
    },
  };
  const { data } = await apiClient.post(`campaigns`, payload);
  return data;
};

export const getCampaignDetails = async (id: number) => {
  const { data } = await apiClient.get(`campaigns/${id}`);
  return data;
};

export const deleteCampaign = async (id: number) => {
  const { data } = await apiClient.delete(`campaigns/${id}`);
  return data;
};

export const deleteCampaigns = async (ids: number[]) => {
  const payload = { ids };
  const { data } = await apiClient.post(`campaigns/batch-delete`, payload);
  return data;
};

let cancelRequest: any;

export const getCampaigns = async (
  q: string,
  statuses: string[],
  pageSize: number,
  page: number,
  sort: string,
  wantCancel = false,
) => {
  if (cancelRequest) {
    cancelRequest();
  }

  const { CancelToken } = axios;
  const source = CancelToken.source();

  if (wantCancel) {
    cancelRequest = source.cancel;
  }

  const params: any = {
    statuses: statuses.join(','),
    page,
    size: pageSize,
    sort: sort !== '' ? sort : 'scheduledAt,DESC',
  };

  if (q !== '') {
    params.q = q;
  }

  const { data } = await apiClient.get(`campaigns/find/all`, { params, cancelToken: source.token });
  return data;
};
