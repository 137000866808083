import { Drawer, ListItemIcon, MenuItem } from '@mui/material';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { type RootState } from '../../context/store';
import { setIsEditorMobileSettingsDrawerOpen } from '../../context/reducers/editor';
import SettingIcon from '../../icons/SettingIcon';
import SaveArticleIcon from '../../icons/SaveArticleIcon';
import PreviewIcon from '../../icons/PreviewIcon';
import { tooglePreviewMode } from '../../context/reducers/toolBar';
import POSTS_STATUS from '../../constants/status';
import ReturnToDraft from '../../icons/ReturnToDraft';
import PublishIcon from '../../icons/PublishIcon';
import TrashIcon from '../../icons/TrashIcon';

interface Props {
  postInformation: any;
  setIsSettingDrawerOpen: (v: boolean) => void;
  saveChanges: () => void;
  returnToDraft: () => void;
  handlePublishPost: () => void;
  currentStatus: string | null;
  confirmSendToReviewModallIsVisible: boolean;
  setConfirmSendToReviewModalIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setConfirmDeleteModalIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  confirmDeleteModalIsVisible: boolean;
}

export default function MobileSettingsDrawer({
  setIsSettingDrawerOpen,
  saveChanges,
  returnToDraft,
  handlePublishPost,
  currentStatus,
  postInformation,
  confirmSendToReviewModallIsVisible,
  setConfirmSendToReviewModalIsVisible,
  setConfirmDeleteModalIsVisible,
}: Props) {
  const isOpen = useSelector((state: RootState) => state.editor.isMobileSettingsDrawerOpen);
  const isPreviewModeActive = useSelector((state: RootState) => state.toolBar.isPreviewModeActive);
  const currentPostStatus = useSelector((state: RootState) => state.editor.currentPostStatus);
  const unsavedChanges = useSelector((state: RootState) => state.editor.unsavedChanges);
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(
      setIsEditorMobileSettingsDrawerOpen({
        isDrawerOpen: false,
      }),
    );
  };

  let options: any[] = [];

  if (currentPostStatus !== POSTS_STATUS.PUBLISHED) {
    options = [
      {
        id: 'details',
        label: 'Detalles del artículo',
        icon: <SettingIcon />,
        handleClick: () => {
          setIsSettingDrawerOpen(true);
          closeModal();
        },
      },
      {
        id: 'save',
        label: 'Guardar',
        icon: <SaveArticleIcon />,
        handleClick: () => {
          closeModal();
          saveChanges();
        },
      },
      /*  {
        label: isPreviewModeActive ? 'Vista edición' : 'Previsualizar',
        icon: <PreviewIcon />,
        handleClick: () => {
          dispatch(tooglePreviewMode({ isPreviewModeActive: !isPreviewModeActive }));
          closeModal();
        },
      }, */
    ];
  } else {
    options = [
      {
        label: 'Revisar artículo',
        icon: <PreviewIcon />,
        handleClick: () => {
          setConfirmSendToReviewModalIsVisible(true);
          closeModal();
        },
      },
      {
        label: 'Eliminar',
        icon: <TrashIcon color='black' />,
        handleClick: () => {
          setConfirmDeleteModalIsVisible(true);
          closeModal();
        },
      },
    ];
  }

  if (currentPostStatus === POSTS_STATUS.REVIEW_IN_PROGRESS) {
    options.unshift({
      label: 'Publicar',
      icon: <PublishIcon />,
      handleClick: () => {
        closeModal();
        handlePublishPost();
      },
    });
    options.splice(3, 0, {
      label: 'Devolver a redactor',
      icon: <ReturnToDraft />,
      handleClick: () => {
        closeModal();
        returnToDraft();
      },
    });
  }

  return (
    <StyledDrawer
      anchor='bottom'
      ModalProps={{
        keepMounted: true,
      }}
      PaperProps={{
        style: {
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
        },
      }}
      open={isOpen}
      onClose={closeModal}
    >
      <DrawerContent>
        <Title>Configurar</Title>
        {options.map((option, index) => (
          <StyledMenuItem
            disabled={
              (option.id === 'save' && !unsavedChanges) ||
              (option.id === 'details' && postInformation == null) ||
              currentStatus === null
            }
            onClick={option.handleClick}
            key={index}
          >
            {option.icon}
            <Label>{option.label}</Label>
          </StyledMenuItem>
        ))}
      </DrawerContent>
    </StyledDrawer>
  );
}

const StyledDrawer = styled(Drawer)``;

const DrawerContent = styled.div`
  background-color: white;
  width: 100vw;
  padding-top: 20px;
  padding-bottom: 80px;
`;

const Title = styled.p`
  font-size: 18px;
  font-family: ${(props) => props.theme.fonts.semiBold};
  border-bottom: 1px solid #c0d4ff;
  padding-left: 22px;
  padding-right: 20px;
  padding-bottom: 15px;
`;

const StyledMenuItem = styled(MenuItem)`
  padding: 10px 20px;
`;

const Label = styled.p`
  font-size: 15px;
  font-family: ${(props) => props.theme.fonts.medium};
  margin-left: 9px;
`;
