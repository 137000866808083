import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import MenuItem from '@mui/material/MenuItem';
import classNames from 'classnames';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { type SubOption as SubOptionInterface } from '../../interfaces/SideBarOptionItem';
import { setInputOfGeneralSearch } from '../../context/reducers/generalSearch';
import { setIsMainDrawerOpen } from '../../context/reducers/layout';

export default function MenuDrawerSubOptionItem({ title, path }: SubOptionInterface): JSX.Element {
  const navigation = useNavigate();
  const location = useLocation();
  const [isSelected, setIsSelected] = useState(location.pathname.startsWith(path));
  const dispatch = useDispatch();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const paramValue = queryParams.get('type');
    setIsSelected(
      location.pathname.startsWith(path) || (paramValue !== null && path.includes('type')),
    );
  }, [location]);

  const handleOnClick = (): void => {
    dispatch(
      setIsMainDrawerOpen({
        isMainDrawerOpen: false,
      }),
    );
    dispatch(
      setInputOfGeneralSearch({
        searchInput: '',
      }),
    );
  };

  return (
    <Link to={path} onClick={handleOnClick}>
      <StyledMenuItem>
        <>
          <Circle />
          <SubOptionTitle>{title}</SubOptionTitle>
        </>
      </StyledMenuItem>
    </Link>
  );
}

const Circle = styled.div`
  height: 4px;
  width: 4px;
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 100%;
`;

const SubOptionTitle = styled.p`
  color: ${(props) => props.theme.colors.primary};
  font-family: ${(props) => props.theme.fonts.regular};
  margin-left: 10px;
`;

const StyledMenuItem = styled(MenuItem)`
  padding-left: 50px;
  padding-top: 15px;
  padding-bottom: 15px;
`;
