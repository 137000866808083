import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import styled from 'styled-components';

export default function AuthorSkeleton(): JSX.Element {
  return (
    <Container>
      <Skeleton variant='circular' width={30} height={30} />
      <Skeleton variant='rectangular' height={50} style={{ flex: 1 }} />
    </Container>
  );
}

const Container = styled.div`
  width: calc(100vw - 192px - 48px);
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  padding-left: 30px;
  padding-right: 30px;

  @media screen and (max-width: 860px) {
    width: 100%;
  }
`;
