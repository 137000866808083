import React from 'react';
import type Icon from '../interfaces/Icon';

export default function PresentationIcon({ color = '#183582', width = 24, height = 24 }: Icon) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6 21C5.71667 21 5.47917 20.9042 5.2875 20.7125C5.09583 20.5208 5 20.2833 5 20V19H3C2.45 19 1.97917 18.8042 1.5875 18.4125C1.19583 18.0208 1 17.55 1 17V6C1 5.45 1.19583 4.97917 1.5875 4.5875C1.97917 4.19583 2.45 4 3 4H21C21.55 4 22.0208 4.19583 22.4125 4.5875C22.8042 4.97917 23 5.45 23 6V17C23 17.55 22.8042 18.0208 22.4125 18.4125C22.0208 18.8042 21.55 19 21 19H19V20C19 20.2833 18.9042 20.5208 18.7125 20.7125C18.5208 20.9042 18.2833 21 18 21H6ZM3 17H21V6H3V17ZM5 15H19L14.5 9L11 13.5L8.5 10.5L5 15Z'
        fill={color}
      />
    </svg>
  );
}
