import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { TextareaAutosize } from '@mui/material';
import { type RootState } from '../../context/store';
import { setElementsContent, setUnsavedChanges } from '../../context/reducers/editor';
import { EDITOR_NOTES } from '../../constants/editorKeys';
import { checkIfPostHasEditorNotes } from '../../utils/wysiwyg';
import POSTS_STATUS from '../../constants/status';

interface Props {
  currentStatus: string | null;
}

interface ContainerI {
  isPreviewModeActive: boolean;
}

export default function EditorNotes({ currentStatus }: Props) {
  const elementsContent = useSelector((state: RootState) => state.editor.elementsContent);
  const isPreviewModeActive = useSelector((state: RootState) => state.toolBar.isPreviewModeActive);
  const [editorNotes, setEditorNotes] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    const content =
      elementsContent[elementsContent.findIndex((item) => item.customKey === EDITOR_NOTES)].content;
    setEditorNotes(typeof content === 'string' ? content : '');
  }, []);

  useEffect(() => {
    dispatch(
      setElementsContent({
        customKey: EDITOR_NOTES,
        content: editorNotes.trim(),
      }),
    );
  }, [editorNotes]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(
      setUnsavedChanges({
        unsavedChanges: true,
      }),
    );
    setEditorNotes(e.target.value);
  };

  return (
    <>
      {currentStatus === POSTS_STATUS.REVIEW_IN_PROGRESS ? (
        <Container isPreviewModeActive={isPreviewModeActive}>
          <Content>
            <Title>Notas del Editor</Title>
            <TextArea
              disabled={currentStatus == null || currentStatus !== POSTS_STATUS.REVIEW_IN_PROGRESS}
              value={editorNotes}
              onChange={handleChange}
              style={{ width: '920px', resize: 'none' }}
              placeholder='Escribe aquí...'
            />
          </Content>
        </Container>
      ) : (
        checkIfPostHasEditorNotes(elementsContent) &&
        currentStatus !== POSTS_STATUS.PUBLISHED && (
          <Container isPreviewModeActive={isPreviewModeActive}>
            <Content>
              <Title>Notas del Editor</Title>
              <TextArea
                disabled={
                  currentStatus == null || currentStatus !== POSTS_STATUS.REVIEW_IN_PROGRESS
                }
                value={editorNotes}
                onChange={handleChange}
                style={{ width: '920px', resize: 'none' }}
                placeholder='Escribe aquí...'
              />
            </Content>
          </Container>
        )
      )}
    </>
  );
}

const Container = styled.div<ContainerI>`
  padding-bottom: 100px;
  border-top: 1px dashed ${(props) => props.theme.gray2};
  margin-top: 25px;
  padding-top: 70px;
  margin-bottom: 200px;
  ${(props) =>
    props.isPreviewModeActive &&
    css`
      height: 10px;
      padding-bottom: 0px;
      overflow: hidden;
      border-top: none;
    `}

  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const Content = styled.div`
  width: 920px;
  margin: 0px auto;
`;

const Title = styled.div`
  font-family: ${(props) => props.theme.fonts.regular};
  font-size: 20px;
  color: ${(props) => props.theme.colors.accentBlue};
`;

const TextArea = styled(TextareaAutosize)`
  margin-top: 20px;
  min-height: 120px;
  background-color: transparent;
  border: none;
  outline: none;
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.accentBlue};
  font-size: 18px;
  ::placeholder {
    color: ${(props) => props.theme.colors.accentBlue};
  }
`;
