import { createSlice } from '@reduxjs/toolkit';
import type TagDetails from '../../interfaces/TagDetails';

interface InitialState {
  tagDeleted: boolean;
  newTagAdded: boolean;
  tagUpdated: boolean;
  tags: TagDetails[] | null;
  currentPage: number;
  tagsTotal: number;
  isLoadingMoreData: boolean;
  canLoadMoreData: boolean;
  isRemovingTag: boolean;
  tagId: string | null;
}

const initialState: InitialState = {
  tagDeleted: false,
  newTagAdded: false,
  tagUpdated: false,
  tags: null,
  currentPage: 0,
  tagsTotal: 0,
  isLoadingMoreData: false,
  canLoadMoreData: false,
  isRemovingTag: false,
  tagId: null,
};

const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    setTagsResetValues: (state, action) => {
      state.tags = null;
      state.currentPage = 0;
      state.tagsTotal = 0;
      state.isLoadingMoreData = false;
      state.canLoadMoreData = false;
    },
    setTagDeleted: (state, action) => {
      state.tagDeleted = action.payload.tagDeleted;
    },
    setNewTagAdded: (state, action) => {
      state.newTagAdded = action.payload.newTagAdded;
    },
    setTagUpdated: (state, action) => {
      state.tagUpdated = action.payload.tagUpdated;
    },
    setTags: (state, action) => {
      state.tags = action.payload.tags;
    },
    setTagsCurrentPage: (state, action) => {
      state.currentPage = action.payload.currentPage;
    },
    setTagsTotal: (state, action) => {
      state.tagsTotal = action.payload.tagsTotal;
    },
    setTagsIsLoadingMoreData: (state, action) => {
      state.isLoadingMoreData = action.payload.isLoadingMoreData;
    },
    setTagsCanLoadMoreData: (state, action) => {
      state.canLoadMoreData = action.payload.canLoadMoreData;
    },
    setIsRemovingTag: (state, action) => {
      state.isRemovingTag = action.payload.isRemovingTag;
    },
    setTagId: (state, action) => {
      state.tagId = action.payload.tagId;
    },
  },
});

export const {
  setTagDeleted,
  setNewTagAdded,
  setTagUpdated,
  setTags,
  setTagsCanLoadMoreData,
  setTagsCurrentPage,
  setTagsIsLoadingMoreData,
  setTagsTotal,
  setTagsResetValues,
  setIsRemovingTag,
  setTagId,
} = tagsSlice.actions;
export default tagsSlice.reducer;
