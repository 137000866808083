import { combineReducers } from '@reduxjs/toolkit';
import toolBarSlice from './reducers/toolBar';
import editorSlice from './reducers/editor';
import generalSnackbarSlice from './reducers/generalSnackbar';
import authorsSlice from './reducers/authors';
import generalSearchSlice from './reducers/generalSearch';
import tagsSlice from './reducers/tags';
import categoriesSlice from './reducers/categories';
import entriesSlice from './reducers/entries';
import userProfileSlice from './reducers/user';
import statsSlice from './reducers/stats';
import homeDraftsSlice from './reducers/homeDrafts';
import publicationsSlice from './reducers/publications';
import entriesDraftsSlice from './reducers/entriesDrafts';
import entriesPendingReviewsSlice from './reducers/entriesPendingReviews';
import entriesPublishedSlice from './reducers/entriesPublished';
import presentationsDraftsSlice from './reducers/presentationsDrafts';
import presentationsPendingReviewSlice from './reducers/presentationsPendingReview';
import presentationsPublishedSlice from './reducers/presentationsPublished';
import presentationsSlice from './reducers/presentations';
import subscriptionsSlice from './reducers/subscriptions';
import layoutSlice from './reducers/layout';
import campaignsSlice from './reducers/campaigns';
import processedCampaignsSlice from './reducers/processedCampaigns';
import scheduledCampaignsSlice from './reducers/scheduledCampaigns';

const rootReducer = combineReducers({
  toolBar: toolBarSlice,
  editor: editorSlice,
  generalSnackbar: generalSnackbarSlice,
  authors: authorsSlice,
  generalSearch: generalSearchSlice,
  tags: tagsSlice,
  categories: categoriesSlice,
  entries: entriesSlice,
  userProfile: userProfileSlice,
  stats: statsSlice,
  homeDrafts: homeDraftsSlice,
  publications: publicationsSlice,
  entriesDrafts: entriesDraftsSlice,
  entriesPendingReviews: entriesPendingReviewsSlice,
  entriesPublished: entriesPublishedSlice,
  presentationsDrafts: presentationsDraftsSlice,
  presentationsPendingReview: presentationsPendingReviewSlice,
  presentationsPublished: presentationsPublishedSlice,
  subscriptions: subscriptionsSlice,
  presentations: presentationsSlice,
  layout: layoutSlice,
  campaigns: campaignsSlice,
  processedCampaigns: processedCampaignsSlice,
  scheduledCampaigns: scheduledCampaignsSlice,
});

export default rootReducer;
