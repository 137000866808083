import React from 'react';
import type Icon from '../interfaces/Icon';

export default function AlignRightIcon({ color = '#3C3C3B' }: Icon): JSX.Element {
  return (
    <svg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.357 11.1428H15.4999V9.42848H10.357V11.1428ZM5.21415 5.14277V6.85706H15.4999V5.14277H5.21415ZM0.0712891 0.857056V2.57134H15.4999V0.857056H0.0712891Z'
        fill={color}
      />
    </svg>
  );
}
