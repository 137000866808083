import React from 'react';
import type Icon from '../interfaces/Icon';

export default function EditNotesIcon({ width = 27, height = 30, color = '#0094CF' }: Icon) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 27 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M19.0947 12.8906C19.0947 12.2434 18.5701 11.7188 17.9229 11.7188H5.85254C5.20531 11.7188 4.68066 12.2434 4.68066 12.8906C4.68066 13.5379 5.20531 14.0625 5.85254 14.0625H17.9229C18.5701 14.0625 19.0947 13.5379 19.0947 12.8906Z'
        fill={color}
      />
      <path
        d='M5.85254 16.4062C5.20531 16.4062 4.68066 16.9309 4.68066 17.5781C4.68066 18.2254 5.20531 18.75 5.85254 18.75H13.1833C13.8305 18.75 14.3551 18.2254 14.3551 17.5781C14.3551 16.9309 13.8305 16.4062 13.1833 16.4062H5.85254Z'
        fill={color}
      />
      <path
        d='M8.60678 27.6562H4.6875C3.39516 27.6562 2.34375 26.6048 2.34375 25.3125V4.6875C2.34375 3.39516 3.39516 2.34375 4.6875 2.34375H19.0951C20.3874 2.34375 21.4388 3.39516 21.4388 4.6875V11.8945C21.4388 12.5418 21.9635 13.0664 22.6107 13.0664C23.2579 13.0664 23.7826 12.5418 23.7826 11.8945V4.6875C23.7826 2.10281 21.6797 0 19.0951 0H4.6875C2.10281 0 0 2.10281 0 4.6875V25.3125C0 27.8972 2.10281 30 4.6875 30H8.60678C9.254 30 9.77865 29.4754 9.77865 28.8281C9.77865 28.1809 9.254 27.6562 8.60678 27.6562Z'
        fill={color}
      />
      <path
        d='M25.858 16.9672C24.4873 15.5964 22.257 15.5964 20.8871 16.9662L14.4533 23.3858C14.3167 23.5221 14.2159 23.69 14.1598 23.8747L12.7586 28.4875C12.6344 28.8965 12.7426 29.3406 13.0411 29.6465C13.2647 29.8757 13.5685 30.0001 13.88 30.0001C13.9842 30.0001 14.0895 29.9861 14.1928 29.9575L18.9228 28.6472C19.1174 28.5933 19.2948 28.49 19.4378 28.3473L25.8581 21.9391C27.2288 20.5683 27.2288 18.338 25.858 16.9672ZM18.0001 26.4708L15.6205 27.13L16.3169 24.8373L20.6581 20.5058L22.3157 22.1634L18.0001 26.4708ZM24.2016 20.2811L23.9746 20.5077L22.3173 18.8504L22.5436 18.6245C23.0005 18.1676 23.7439 18.1676 24.2008 18.6245C24.6577 19.0815 24.6577 19.8249 24.2016 20.2811Z'
        fill={color}
      />
      <path
        d='M17.9229 7.03125H5.85254C5.20531 7.03125 4.68066 7.5559 4.68066 8.20312C4.68066 8.85035 5.20531 9.375 5.85254 9.375H17.9229C18.5701 9.375 19.0947 8.85035 19.0947 8.20312C19.0947 7.5559 18.5701 7.03125 17.9229 7.03125Z'
        fill={color}
      />
    </svg>
  );
}
