import React from 'react';
import styled from 'styled-components';
import SAE from '../../icons/SAE';
import WhiteSAELogo from '../../icons/WhiteSAELogo';

interface Props {
  width?: number;
  height?: number;
}

interface AcronymI {
  fontSize: number;
}

interface NameI {
  fontSize: number;
}

export default function SAELogo({ width = 113, height = 40 }: Props): JSX.Element {
  return (
    <Container>
      <WhiteSAELogo width={width} height={height} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Acronym = styled.h2<AcronymI>`
  color: ${(props) => props.theme.colors.primary};
  font-family: ${(props) => props.theme.fonts.saeLogo};
  font-size: ${(props) => `${props.fontSize}px`};
  font-weight: 400;
`;

const Name = styled.p<NameI>`
  color: ${(props) => props.theme.colors.primary};
  font-family: ${(props) => props.theme.fonts.saeLogo};
  font-size: ${(props) => `${props.fontSize}px`};
  font-weight: 400;
  text-align: center;
`;
