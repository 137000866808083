import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Drawer, MenuItem } from '@mui/material';
import styled from 'styled-components';
import { type RootState } from '../../context/store';
import {
  setCurrentSelectedEditorState,
  setIsAddNewElementDrawerOpen,
  setIsMobileAudioDrawerOpen,
  setIsMobileVideoDrawerOpen,
  setUnsavedChanges,
  toogleEditSomeStyleOfCurrentSelectedEditor,
} from '../../context/reducers/editor';
import VideoIcon from '../../icons/VideoIcon';
import POSTS_STATUS from '../../constants/status';
import {
  KEY_IDEA_ITEM,
  KEY_PARAGRAPH,
  WYSIWYG_MAIN_SUBTITLE,
  WYSIWYG_MAIN_TITLE,
} from '../../constants/editorKeys';
import HeadPhones from '../../icons/HeadPhones';
import ImageOption from './toolBar/ImageOption';
import { AiOutlineLine } from 'react-icons/ai';
import { addCustomElement } from '../../utils/wysiwyg';
import { EditorState } from 'draft-js';

interface Props {
  currentStatus: string | null;
}

export default function AddNewElementDrawer({ currentStatus }: Props) {
  const currentSelectedEditorState = useSelector(
    (state: RootState) => state.editor.currentSelectedEditorState,
  );
  const selectedEditorKey = useSelector((state: RootState) => state.editor.selectedEditorKey);
  const isOpen = useSelector((state: RootState) => state.editor.isAddNewElementDrawerOpen);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(
      setIsAddNewElementDrawerOpen({
        isDrawerOpen: false,
      }),
    );
  };

  const checkIfButtonIsDisabled = (style: string | undefined, id: string | undefined) => {
    if (currentStatus === POSTS_STATUS.PUBLISHED || currentStatus === null) {
      return true;
    }
    if (
      (currentSelectedEditorState == null ||
        selectedEditorKey == null ||
        selectedEditorKey !== KEY_PARAGRAPH) &&
      (style !== undefined || (id !== 'audio' && id !== 'video'))
    ) {
      return true;
    }
    return false;
  };

  const getBtnColor = (style: string | undefined, id: string | undefined) => {
    return checkIfButtonIsDisabled(style, id) ? 'rgba(0, 0, 0, 0.26)' : '#3C3C3B';
  };

  const options = [
    {
      label: 'Audio',
      id: 'audio',
      isDisabled: currentStatus === POSTS_STATUS.PUBLISHED,
      icon: <HeadPhones color={getBtnColor(undefined, 'audio')} />,
      handleClick: () => {
        dispatch(
          setIsMobileAudioDrawerOpen({
            isDrawerOpen: true,
          }),
        );
        handleClose();
      },
    },
    {
      label: 'Video',
      id: 'video',
      isDisabled: currentStatus === POSTS_STATUS.PUBLISHED,
      icon: <VideoIcon color={getBtnColor(undefined, 'video')} />,
      handleClick: () => {
        dispatch(
          setIsMobileVideoDrawerOpen({
            isDrawerOpen: true,
          }),
        );
        handleClose();
      },
    },
    {
      label: 'Imagen',
      id: 'image',
      isDisabled: currentStatus === POSTS_STATUS.PUBLISHED,
      element: <ImageOption type='mobile' />,
      handleClick: () => {},
    },
    {
      label: 'Línea Divisora',
      id: 'divider',
      isDisabled: currentStatus === POSTS_STATUS.PUBLISHED,
      icon: <AiOutlineLine color={getBtnColor(undefined, 'divider')} />,
      handleClick: () => {
        if (
          currentSelectedEditorState !== null &&
          selectedEditorKey !== WYSIWYG_MAIN_TITLE &&
          selectedEditorKey !== WYSIWYG_MAIN_SUBTITLE &&
          selectedEditorKey !== KEY_IDEA_ITEM &&
          selectedEditorKey !== null
        ) {
          const newEditorState = addCustomElement(currentSelectedEditorState, 'custom-divider');
          dispatch(
            setCurrentSelectedEditorState({
              currentSelectedEditorState: EditorState.forceSelection(
                newEditorState,
                currentSelectedEditorState.getSelection(),
              ),
            }),
          );
          dispatch(toogleEditSomeStyleOfCurrentSelectedEditor({}));
          dispatch(setUnsavedChanges({ unsavedChanges: true }));
          handleClose();
        }
      },
    },
  ];

  return (
    <StyledDrawer
      anchor='bottom'
      ModalProps={{
        keepMounted: true,
      }}
      PaperProps={{
        style: {
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
        },
      }}
      open={isOpen}
      onClose={handleClose}
    >
      <DrawerContent>
        <Title>Añadir un elemento</Title>
        {options.map((option, index) => {
          if (option.element) {
            return option.element;
          }
          return (
            <StyledMenuItem onClick={option.handleClick} key={index} disabled={option.isDisabled}>
              <IconContainer>{option.icon}</IconContainer>
              <Label style={{ color: getBtnColor(undefined, option.id) }}>{option.label}</Label>
            </StyledMenuItem>
          );
        })}
      </DrawerContent>
    </StyledDrawer>
  );
}

const StyledDrawer = styled(Drawer)``;

const DrawerContent = styled.div`
  background-color: white;
  width: 100vw;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const Title = styled.p`
  font-size: 18px;
  font-family: ${(props) => props.theme.fonts.semiBold};
  border-bottom: 1px solid #c0d4ff;
  padding-left: 22px;
  padding-right: 20px;
  padding-bottom: 15px;
`;

const StyledMenuItem = styled(MenuItem)`
  padding: 10px 20px;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 8px;
`;

const Label = styled.p`
  font-size: 15px;
  font-family: ${(props) => props.theme.fonts.medium};
  margin-left: 9px;
`;
