import React from 'react';
import SectionHeader from '../../globals/SectionHeader';
import styled from 'styled-components';
import InputWithLabel from './InputWithLabel';
import type AuthorDetailsInterface from '../../../interfaces/AuthorDetails';
import { type FormErrorI } from '../../../interfaces/AuthorDetails';

interface Props {
  formData: AuthorDetailsInterface;
  setFormData: React.Dispatch<React.SetStateAction<AuthorDetailsInterface>>;
  formError: FormErrorI;
  setFormError: React.Dispatch<React.SetStateAction<FormErrorI>>;
  unsavedChanges: boolean;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function AdditionalInformation({
  formData,
  setFormData,
  formError,
  setFormError,
  unsavedChanges,
  setUnsavedChanges,
}: Props): JSX.Element {
  const setValue = (newValue: string, type: string): void => {
    if (!unsavedChanges) {
      setUnsavedChanges(true);
    }
    setFormData({ ...formData, [type]: newValue });
  };

  return (
    <Container>
      <SectionHeader title='Información adicional' />
      <Content>
        <Column>
          <InputWithLabel
            label='Cargo'
            placeholder='Escribe aquí'
            setValue={setValue}
            type={'job'}
            value={formData.job}
            error={formError.job}
            errorMessage={formError.jobMessage}
          />
          <InputWithLabel
            label='Correo de Contacto'
            placeholder='Escribe aquí'
            setValue={setValue}
            type={'email'}
            value={formData.email}
            error={formError.email}
            errorMessage={formError.emailMessage}
          />
        </Column>
        <Column>
          <InputWithLabel
            label='Organización'
            placeholder='Escribe aquí'
            setValue={setValue}
            type={'organization'}
            value={formData.organization}
            error={formError.organization}
            errorMessage={formError.organizationMessage}
          />
          <InputWithLabel
            label='Teléfono de Contacto'
            placeholder='Escribe aquí'
            setValue={setValue}
            type={'phone'}
            value={formData.phone}
            error={formError.phone}
            errorMessage={formError.phoneMessage}
          />
        </Column>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  padding-left: 24px;
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 24px;
  gap: 30px;

  @media screen and (max-width: 860px) {
    display: block;
    padding-left: 0px;
    padding-left: 14px;
  }
`;

const Column = styled.div``;
