import React from 'react';
import styled from 'styled-components';
import Skeleton from '@mui/material/Skeleton';
import { motion } from 'framer-motion';
import type OverviewCardInterface from '../../interfaces/OverviewCard';
import { formatNumber } from '../../utils/formatNumber';

export default function OverviewCard({ icon, quantity, type }: OverviewCardInterface): JSX.Element {
  return (
    <Container>
      <IconContainer>{icon}</IconContainer>
      <QuantityContainer>
        {quantity === undefined || quantity === null ? (
          <SkeletonContainer>
            <Skeleton variant='rounded' width={100} height={30} />
          </SkeletonContainer>
        ) : (
          <motion.div
            initial={{ opacity: 0.5, translateY: -10 }}
            animate={{ opacity: 1, translateY: 0 }}
            transition={{ duration: 0.4 }}
            exit={{ opacity: 0.8 }}
          >
            <Quantity>
              {formatNumber(quantity)} {type}
            </Quantity>
          </motion.div>
        )}
      </QuantityContainer>
      <LineContainer>
        <Line />
      </LineContainer>
      <PeriodContainer>
        <Text>Últimos</Text>
        <Days>07 días</Days>
      </PeriodContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 72px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.matizBlue};
  margin-right: 10px;
  padding-left: 25px;

  @media screen and (max-width: 860px) {
    width: 100%;
    margin-right: 0px;
    border-radius: 15px;
  }
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const QuantityContainer = styled.div`
  @media screen and (max-width: 860px) {
    flex: 1;
  }
`;

const SkeletonContainer = styled.div`
  padding: 0px 18px;
`;

const Quantity = styled.p`
  font-family: ${(props) => props.theme.fonts.medium};
  color: ${(props) => props.theme.colors.accentBlue};
  font-size: 28px;
  margin-left: 13px;
  margin-right: 18px;

  @media screen and (max-width: 860px) {
    flex: 1;
    font-size: 22px;
  }
`;

const LineContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

const Line = styled.div`
  background-color: ${(props) => props.theme.colors.lightGray};
  width: 2px;
  height: 54px;
`;

const PeriodContainer = styled.div`
  margin-left: 16px;
  margin-right: 26px;
`;

const Text = styled.p`
  font-family: ${(props) => props.theme.fonts.medium};
`;

const Days = styled.p`
  font-family: ${(props) => props.theme.fonts.medium};
`;
