import React from 'react';
import type Icon from '../interfaces/Icon';

export default function SaveArticleIcon({ width = 21, height = 21, color = 'black' }: Icon) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 21 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.49967 19.2849H16.0703C16.5817 19.2849 17.0722 19.0817 17.4339 18.7201C17.7956 18.3584 17.9987 17.8679 17.9987 17.3564V3.21457C17.9987 2.70312 17.7956 2.21261 17.4339 1.85096C17.0722 1.48931 16.5817 1.28613 16.0703 1.28613H9.53289C9.02161 1.28658 8.53143 1.49005 8.17013 1.85181L3.1369 6.88503C2.77515 7.24633 2.57168 7.73651 2.57123 8.24779V17.3564C2.57123 17.8679 2.7744 18.3584 3.13605 18.7201C3.49771 19.0817 3.98821 19.2849 4.49967 19.2849ZM16.7131 3.21457V17.3564C16.7131 17.5269 16.6454 17.6904 16.5248 17.811C16.4043 17.9315 16.2408 17.9993 16.0703 17.9993H4.49967C4.32918 17.9993 4.16568 17.9315 4.04513 17.811C3.92458 17.6904 3.85685 17.5269 3.85685 17.3564V8.35707H7.71373C8.22518 8.35707 8.71569 8.1539 9.07734 7.79224C9.43899 7.43059 9.64217 6.94009 9.64217 6.42863V2.57176H16.0703C16.2408 2.57176 16.4043 2.63948 16.5248 2.76003C16.6454 2.88058 16.7131 3.04409 16.7131 3.21457ZM8.35654 3.47812V6.42863C8.35654 6.59912 8.28882 6.76262 8.16826 6.88317C8.04771 7.00372 7.88421 7.07144 7.71373 7.07144H4.76322L8.35654 3.47812Z'
        fill={color}
      />
      <path
        d='M5.78533 10.2856C5.61485 10.2856 5.45134 10.3534 5.33079 10.4739C5.21024 10.5945 5.14252 10.758 5.14252 10.9285C5.14252 11.0989 5.21024 11.2624 5.33079 11.383C5.45134 11.5035 5.61485 11.5713 5.78533 11.5713H14.7847C14.9552 11.5713 15.1187 11.5035 15.2392 11.383C15.3598 11.2624 15.4275 11.0989 15.4275 10.9285C15.4275 10.758 15.3598 10.5945 15.2392 10.4739C15.1187 10.3534 14.9552 10.2856 14.7847 10.2856H5.78533Z'
        fill={color}
      />
      <path
        d='M14.7847 13.4995H5.78533C5.61485 13.4995 5.45134 13.5672 5.33079 13.6878C5.21024 13.8083 5.14252 13.9718 5.14252 14.1423C5.14252 14.3128 5.21024 14.4763 5.33079 14.5969C5.45134 14.7174 5.61485 14.7851 5.78533 14.7851H14.7847C14.9552 14.7851 15.1187 14.7174 15.2392 14.5969C15.3598 14.4763 15.4275 14.3128 15.4275 14.1423C15.4275 13.9718 15.3598 13.8083 15.2392 13.6878C15.1187 13.5672 14.9552 13.4995 14.7847 13.4995Z'
        fill={color}
      />
    </svg>
  );
}
