import React from 'react';
import styled from 'styled-components';
import { formatLessThan10 } from '../../utils/formatLessThan10';
import FiltersButton from './FiltersButton';

interface Props {
  title: string;
  quantity: number | undefined;
  type: string;
  contentType?: 'ARTICLE' | 'PRESENTATION';
  page?: string;
}

export default function TitleWithFiltersButton({
  title,
  quantity,
  type,
  contentType = 'ARTICLE',
  page = 'articles',
}: Props) {
  return (
    <Container>
      <Title>
        {title} {quantity === undefined ? '' : `(${formatLessThan10(quantity)})`}
      </Title>
      {page !== 'campaigns' && <FiltersButton type={type} contentType={contentType} />}
    </Container>
  );
}

const Container = styled.div`
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 30px;
  margin-bottom: 25px;

  @media screen and (max-width: 860px) {
    display: flex;
  }
`;

const Title = styled.p`
  flex: 1;
  font-size: 20px;
  font-family: ${(props) => props.theme.fonts.semiBold};
`;
