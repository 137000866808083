import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import HeaderButtonWithText from '../components/globals/HeaderButtonWithText';
import NewTagModal from '../components/tags/NewTagModal';
import SearchBarFilter from '../components/globals/SearchBarFilter';
import TagsList from '../components/tags/TagsList';
import { type RootState } from '../context/store';
import type TagDetails from '../interfaces/TagDetails';
import { debounce } from 'lodash';
import AuthorSkeleton from '../components/authors/AuthorSkeleton';
import TagItem from '../components/tags/TagItem';
import PenIcon from '../icons/PenIcon';
import { createTag, getTags } from '../api/tags';
import { PAGE_SIZE } from '../constants/pagination';
import { openSnackbar } from '../context/reducers/generalSnackbar';
import { TAGS_ERRORS } from '../constants/errorMessages';
import {
  setNewTagAdded,
  setTags,
  setTagsCanLoadMoreData,
  setTagsCurrentPage,
  setTagsIsLoadingMoreData,
  setTagsResetValues,
  setTagsTotal,
} from '../context/reducers/tags';
import { AxiosError } from 'axios';
import MobileNewPublicationButton from '../components/globals/MobileNewPublicationButton';

export default function Tags(): JSX.Element {
  const newTagAdded = useSelector((state: RootState) => state.tags.newTagAdded);
  const tags = useSelector((state: RootState) => state.tags.tags);
  const currentPage = useSelector((state: RootState) => state.tags.currentPage);
  const tagsTotal = useSelector((state: RootState) => state.tags.tagsTotal);
  const isLoadingMoreData = useSelector((state: RootState) => state.tags.isLoadingMoreData);
  const canLoadMoreData = useSelector((state: RootState) => state.tags.canLoadMoreData);
  const [showNewTagModal, setShowNewTagModal] = useState(false);
  const [creating, setCreating] = useState(false);
  const [newTag, setNewTag] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [formError, setFormError] = useState({});
  const dispatch = useDispatch();

  const loadMoreData = async () => {
    if (canLoadMoreData && !isLoadingMoreData && tags !== null) {
      dispatch(
        setTagsIsLoadingMoreData({
          isLoadingMoreData: true,
        }),
      );

      try {
        const response = await getTags(encodeURI(searchInput) || '', PAGE_SIZE, currentPage);
        const { last, content } = response;
        if (!last) {
          dispatch(
            setTagsCurrentPage({
              currentPage: currentPage + 1,
            }),
          );
          dispatch(
            setTagsCanLoadMoreData({
              canLoadMoreData: true,
            }),
          );
        } else {
          dispatch(
            setTagsCanLoadMoreData({
              canLoadMoreData: false,
            }),
          );
        }
        dispatch(
          setTagsIsLoadingMoreData({
            isLoadingMoreData: false,
          }),
        );
        if (tags != null) {
          dispatch(
            setTags({
              tags: [...tags, ...content],
            }),
          );
        }
      } catch (error: unknown) {
        let errorStatus;
        if (error instanceof AxiosError) {
          errorStatus = error?.response?.status;
        }
        if (errorStatus !== 401) {
          dispatch(
            openSnackbar({
              type: 'error',
              message: TAGS_ERRORS.TAGS_LIST,
            }),
          );
        }
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
      const margin = 400;
      if (scrollTop + clientHeight + margin >= scrollHeight) {
        loadMoreData();
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [currentPage, canLoadMoreData, isLoadingMoreData, searchInput]);

  const callApiToResetTags = () => {
    getTags('', PAGE_SIZE, 0)
      .then((res) => {
        dispatch(
          setTags({
            tags: res.content,
          }),
        );
        dispatch(
          setTagsTotal({
            tagsTotal: res.totalElements,
          }),
        );
        if (!res.last) {
          dispatch(
            setTagsCurrentPage({
              currentPage: 1,
            }),
          );
          dispatch(
            setTagsCanLoadMoreData({
              canLoadMoreData: true,
            }),
          );
        } else {
          dispatch(
            setTagsCanLoadMoreData({
              canLoadMoreData: false,
            }),
          );
        }
      })
      .catch((error: unknown) => {
        let errorStatus;
        if (error instanceof AxiosError) {
          errorStatus = error?.response?.status;
        }
        if (errorStatus !== 401) {
          dispatch(
            openSnackbar({
              type: 'error',
              message: TAGS_ERRORS.TAGS_LIST,
            }),
          );
        }
      });
  };

  const callApiToGetTags = (name: string, cleanSearch = false): void => {
    const newCurrentPage = currentPage > 0 ? (canLoadMoreData ? currentPage : currentPage + 1) : 0;
    if (name?.length > 0 || cleanSearch) {
      getTags(encodeURI(name) || '', PAGE_SIZE, 0)
        .then((res) => {
          dispatch(
            setTags({
              tags: res.content,
            }),
          );
          dispatch(
            setTagsTotal({
              tagsTotal: res.totalElements,
            }),
          );
          if (!res.last) {
            dispatch(
              setTagsCurrentPage({
                currentPage: 1,
              }),
            );
            dispatch(
              setTagsCanLoadMoreData({
                canLoadMoreData: true,
              }),
            );
          } else {
            dispatch(
              setTagsCanLoadMoreData({
                canLoadMoreData: false,
              }),
            );
          }
        })
        .catch((error: unknown) => {
          let errorStatus;
          if (error instanceof AxiosError) {
            errorStatus = error?.response?.status;
          }
          if (errorStatus !== 401) {
            dispatch(
              openSnackbar({
                type: 'error',
                message: TAGS_ERRORS.TAGS_LIST,
              }),
            );
          }
        });
    } else {
      getTags(encodeURI(name) || '', PAGE_SIZE, newCurrentPage)
        .then((res) => {
          if (tags == null || newCurrentPage === 0) {
            dispatch(
              setTags({
                tags: res.content,
              }),
            );
          } else {
            dispatch(
              setTags({
                tags: [...tags, ...res.content],
              }),
            );
          }
          dispatch(
            setTagsTotal({
              tagsTotal: res.totalElements,
            }),
          );
          if (!res.last) {
            dispatch(
              setTagsCurrentPage({
                currentPage: newCurrentPage + 1,
              }),
            );
            dispatch(
              setTagsCanLoadMoreData({
                canLoadMoreData: true,
              }),
            );
          } else {
            dispatch(
              setTagsCanLoadMoreData({
                canLoadMoreData: false,
              }),
            );
          }
        })
        .catch((error: unknown) => {
          let errorStatus;
          if (error instanceof AxiosError) {
            errorStatus = error?.response?.status;
          }
          if (errorStatus !== 401) {
            dispatch(
              openSnackbar({
                type: 'error',
                message: TAGS_ERRORS.TAGS_LIST,
              }),
            );
          }
        });
    }
  };

  const debounceSearch = debounce((name: string) => {
    if (name.length >= 2) {
      callApiToGetTags(name);
    } else {
      callApiToGetTags('', true);
    }
  }, 500);

  useEffect(() => {
    return () => {
      debounceSearch.cancel();
    };
  }, []);

  useEffect(() => {
    callApiToGetTags('');
    return () => {
      if (searchInput?.length >= 2) {
        dispatch(setTagsResetValues({}));
      }
    };
  }, []);

  const handleInputChange = (value: string): void => {
    setSearchInput(value);
    debounceSearch(value);
  };

  const saveTag = async (tag: string): Promise<void> => {
    const errors: any = {};
    if (tag?.length <= 2) {
      errors.message = 'La etiqueta debe tener más de 2 caractéres';
    } else if (!creating) {
      try {
        setCreating(true);
        await createTag(tag?.trim());
        setShowNewTagModal(false);
        dispatch(
          openSnackbar({
            type: 'success',
            message: 'Etiqueta creada correctamente',
          }),
        );
        callApiToResetTags();
        setNewTag('');
        setCreating(false);
      } catch (error: unknown) {
        if (error instanceof AxiosError) {
          const errorData = error?.response?.data;
          const errorCode = errorData?.errorCode;
          const errorMessage =
            errorCode === 'TAG_ALREADY_EXISTS' ? errorData?.message : TAGS_ERRORS.CREATE_TAG;
          setCreating(false);
          errors.message = errorMessage;
        }
      }
    }
    setFormError(errors);
  };

  return (
    <Container>
      <HeaderButtonWithText
        title='Etiquetas'
        type='tag'
        buttonText='Nueva etiqueta'
        setModalIsVisible={setShowNewTagModal}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        callApiToResetValues={callApiToResetTags}
      />
      <NewTagModal
        isVisible={showNewTagModal}
        setIsVisible={setShowNewTagModal}
        creating={creating}
        setCreating={setCreating}
        createTag={saveTag}
        newTag={newTag}
        setNewTag={setNewTag}
        formError={formError}
        setFormError={setFormError}
      />
      {tags == null ? (
        <List style={{ marginTop: '35px' }}>
          <AuthorSkeleton />
          <AuthorSkeleton />
          <AuthorSkeleton />
          <AuthorSkeleton />
          <AuthorSkeleton />
          <AuthorSkeleton />
          <AuthorSkeleton />
          <AuthorSkeleton />
        </List>
      ) : (
        <>
          <SearchBarFilter searchInput={searchInput} handleInputChange={handleInputChange} />
          <ListContainer>
            {tags?.length === 0 ? (
              <MessageContainer>
                <PenIcon color='#033BCF' />
                <Message>
                  {searchInput !== ''
                    ? 'No encontramos etiquetas relacionadas con tu búsqueda.'
                    : 'Aún no se han registrado etiquetas. Haz click en el botón de Nueva etiqueta.'}
                </Message>
              </MessageContainer>
            ) : (
              <>
                <ListHeader>
                  <ColumHeader>Nombre de la Etiqueta</ColumHeader>
                  <ColumHeader></ColumHeader>
                </ListHeader>
                <List>
                  {tags?.map((tag, index) => (
                    <TagItem key={`${tag.id}_${index}`} id={tag.id} tag={tag.tag} />
                  ))}
                  {canLoadMoreData && (
                    <>
                      <AuthorSkeleton />
                      <AuthorSkeleton />
                      <AuthorSkeleton />
                      <AuthorSkeleton />
                      <AuthorSkeleton />
                      <AuthorSkeleton />
                      <AuthorSkeleton />
                      <AuthorSkeleton />
                    </>
                  )}
                </List>
              </>
            )}
          </ListContainer>
        </>
      )}
      <MobileNewPublicationButton
        type='tag'
        setModalIsVisible={setShowNewTagModal}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        callApiToResetValues={callApiToResetTags}
      />
    </Container>
  );
}

const Container = styled.section`
  padding-left: 24px;
  padding-right: 24px;
  margin-left: 192px;
  margin-top: 72px;
  width: calc(100vw - 192px);

  @media screen and (max-width: 860px) {
    margin-left: 0px;
    width: 100vw;
    margin-top: 70px;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const ListContainer = styled.section`
  padding-bottom: 80px;
`;

const ListHeader = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 13px;
  padding-bottom: 13px;
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  justify-content: center;
  flex: 1;
  height: calc(100vh - 400px);
`;

const Message = styled.p`
  font-family: ${(props) => props.theme.fonts.medium};
  color: ${(props) => props.theme.colors.black};
  font-size: 16px;
  text-align: center;
  max-width: 300px;
`;

const ColumHeader = styled.p`
  font-family: ${(props) => props.theme.fonts.semiBold};
  color: ${(props) => props.theme.colors.primary};
`;

const List = styled.ul``;
