/* eslint-disable no-empty */
/* eslint-disable array-callback-return */
import React, { useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import draftToHtml from 'draftjs-to-html';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircleIcon from '@mui/icons-material/Circle';
import { CircularProgress } from '@mui/material';
import Immutable from 'immutable';
import { type RootState } from '../../context/store';
import { tooglePreviewMode, toogleSettingDrawer } from '../../context/reducers/toolBar';
import { createPost, returnToDraft, startReviewOfPost, updatePost } from '../../api/posts';
import { openSnackbar } from '../../context/reducers/generalSnackbar';
import { POST_ERRORS } from '../../constants/errorMessages';
import type PostInformation from '../../interfaces/PostInformation';
import POSTS_STATUS, { STATUS_NAMES } from '../../constants/status';
import {
  type EditorState,
  convertToRaw,
  convertFromRaw,
  ContentState,
  ContentBlock,
} from 'draft-js';
import {
  MAX_AUTHORS_QUANTITY,
  MAX_CATEGORIES_QUANTITY,
  MAX_IMAGES_QUANTITY,
  MAX_TAGS_QUANTITY,
  SEPARATOR,
} from '../../constants/globals';
import { EDITOR_NOTES, KEY_IDEAS, KEY_PARAGRAPH, PDF_URL } from '../../constants/editorKeys';
import { getImagesQuantity, getPlainText } from '../../utils/wysiwyg';
import {
  setIsEditorMobileSettingsDrawerOpen,
  setUnsavedChanges,
  setWantToReloadPostPage,
} from '../../context/reducers/editor';
import { callPostDetails, publishPost, savePost, saveSettingsOfPost } from '../../utils/editor';
import type TagDetails from '../../interfaces/TagDetails';
import { type AuthorItem } from '../../interfaces/AuthorDetails';
import ConfirmPublicationModal from '../globals/ConfirmPublicationModal';
import { AxiosError } from 'axios';
import { checkIfUserHasRole } from '../../utils/checkIfUserHasRole';
import { ROLE_EDITOR } from '../../constants/roles';
import ConfirmSendToReviewModal from '../entries/ConfirmSendToReviewModal';
import {
  setHomeDraftPostCreated,
  setHomeDraftPostUpdated,
} from '../../context/reducers/homeDrafts';
import {
  setDraftPostAdded,
  setDraftPostUpdated,
  setPendingReviewPostAdded,
  setPendingReviewPostUpdated,
  setPendingReviewQuantity,
  setPublishedPostAdded,
} from '../../context/reducers/entries';
import { setPendingReviewQuantity as setPresentationsPendingReviewQuantity } from '../../context/reducers/presentations';
import { ARTICLE_SUCCESFUL_MESSAGES } from '../../constants/successfulMessages';
import CreateNotificationModal from './CreateNotificationModal';

interface Props {
  postInformation: any;
  currentStatus: string | null;
  isDiscardChangesModalVisible: boolean;
  setIsDiscardChangesVisible: React.Dispatch<React.SetStateAction<boolean>>;
  selectedTags: TagDetails[];
  setSelectedTags: React.Dispatch<React.SetStateAction<TagDetails[]>>;
  currentTags: TagDetails[];
  setCurrentTags: React.Dispatch<React.SetStateAction<TagDetails[]>>;
  selectedCategories: any[];
  setSelectedCategories: React.Dispatch<React.SetStateAction<any[]>>;
  currentCategories: any[];
  setCurrentCategories: React.Dispatch<React.SetStateAction<any[]>>;
  selectedAuthors: AuthorItem[];
  setSelectedAuthors: React.Dispatch<React.SetStateAction<AuthorItem[]>>;
  currentAuthors: AuthorItem[];
  setCurrentAuthors: React.Dispatch<React.SetStateAction<AuthorItem[]>>;
  currentSubscriptions: any[];
  setCurrentSubscriptions: React.Dispatch<React.SetStateAction<any[]>>;
  selectedSubscriptions: any[];
  setSelectedSubscriptions: React.Dispatch<React.SetStateAction<any[]>>;
  postId: number | string | undefined;
  creating: boolean;
  setCreating: React.Dispatch<React.SetStateAction<boolean>>;
  isErrorConcurrenceModalVisible: boolean;
  setIsErrorConcurrenceModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  currentVersion: number;
  setCurrentVersion: React.Dispatch<React.SetStateAction<number>>;
  setIsArticleHasBeenPublishedModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setIsArticleWasReturnedToDraftModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  confirmSendToReviewModallIsVisible: boolean;
  setConfirmSendToReviewModalIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isPresentationPage?: boolean;
  isNotificationModalVisible: boolean;
  setIsNotificationModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ContainerI {
  isPreviewModeActive: boolean;
}

interface ButtonI {
  bgColor: string;
  withBorder: boolean;
}

export default function Header({
  postInformation,
  currentStatus,
  isDiscardChangesModalVisible,
  setIsDiscardChangesVisible,
  selectedTags,
  setSelectedTags,
  currentTags,
  setCurrentTags,
  selectedCategories,
  setSelectedCategories,
  currentCategories,
  setCurrentCategories,
  selectedAuthors,
  setSelectedAuthors,
  currentAuthors,
  setCurrentAuthors,
  postId,
  currentSubscriptions,
  setCurrentSubscriptions,
  selectedSubscriptions,
  setSelectedSubscriptions,
  creating,
  setCreating,
  isErrorConcurrenceModalVisible,
  setIsErrorConcurrenceModalVisible,
  currentVersion,
  setCurrentVersion,
  setIsArticleHasBeenPublishedModalVisible,
  setIsArticleWasReturnedToDraftModalVisible,
  confirmSendToReviewModallIsVisible,
  setConfirmSendToReviewModalIsVisible,
  isPresentationPage = false,
  isNotificationModalVisible,
  setIsNotificationModalVisible,
}: Props): JSX.Element {
  const isRemovingSubscription = useSelector(
    (state: RootState) => state.subscriptions.isRemovingSubscription,
  );
  const isRemovingCategory = useSelector((state: RootState) => state.categories.isRemovingCategory);
  const isRemovingTag = useSelector((state: RootState) => state.tags.isRemovingTag);
  const isRemovingAuthor = useSelector((state: RootState) => state.authors.isRemovingAuthor);
  const userProfile = useSelector((state: RootState) => state.userProfile.userProfile);
  const currentPostStatus = useSelector((state: RootState) => state.editor.currentPostStatus);
  const unsavedChanges = useSelector((state: RootState) => state.editor.unsavedChanges);
  const isPreviewModeActive = useSelector((state: RootState) => state.toolBar.isPreviewModeActive);
  const elements = useSelector((state: RootState) => state.editor.elements);
  const elementsContent = useSelector((state: RootState) => state.editor.elementsContent);
  const wantToReloadPostPage = useSelector((state: RootState) => state.editor.wantToReloadPostPage);
  const pendingReviewQuantity = useSelector(
    (state: RootState) => state.entries.pendingReviewQuantity,
  );
  const presentationsPendingReviewQuantity = useSelector(
    (state: RootState) => state.presentations.pendingReviewQuantity,
  );
  const [confirmPublicationModalIsVisible, setConfirmPublicationModalIsVisible] = useState(false);
  const [publishing, setPublishing] = useState(false);
  const [sendingToReview, setSendingToReview] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClickBackButton = (): void => {
    if (unsavedChanges) {
      setIsDiscardChangesVisible(true);
    } else {
      const prevRouteName = location.state?.prevRouteName;
      if (
        prevRouteName !== undefined &&
        prevRouteName !== null &&
        prevRouteName !== '' &&
        location.state !== null &&
        location.state !== undefined
      ) {
        if (prevRouteName === '/new-post' || prevRouteName === '/new-presentation') {
          navigate(
            `${
              isPresentationPage ? '/presentations' : '/entries'
            }?type=${POSTS_STATUS.DRAFT.toLowerCase()}`,
          );
        } else {
          navigate(-1);
        }
      } else {
        navigate('/');
      }
    }
  };

  const handleClickPreviewModeButton = async (): Promise<void> => {
    if (currentStatus === POSTS_STATUS.REVIEW_IN_PROGRESS) {
      if (!creating) {
        try {
          setCreating(true);
          if (unsavedChanges) {
            await handleSaveButton();
          }
          if (!checkPostInformation()) {
            setCreating(false);
            return;
          }
          const response = await publishPost(postInformation?.id, false);
          if (response === 200) {
            dispatch(
              openSnackbar({
                type: 'success',
                message: !isPresentationPage
                  ? ARTICLE_SUCCESFUL_MESSAGES.PUBLISH_ARTICLE
                  : ARTICLE_SUCCESFUL_MESSAGES.PUBLISH_PRESENTATION,
              }),
            );
            if (isPresentationPage) {
              if (
                presentationsPendingReviewQuantity !== null &&
                presentationsPendingReviewQuantity !== undefined
              ) {
                dispatch(
                  setPresentationsPendingReviewQuantity({
                    pendingReviewQuantity: presentationsPendingReviewQuantity - 1,
                  }),
                );
              }
            } else {
              if (pendingReviewQuantity !== null && pendingReviewQuantity !== undefined) {
                dispatch(
                  setPendingReviewQuantity({
                    pendingReviewQuantity: pendingReviewQuantity - 1,
                  }),
                );
              }
            }
            dispatch(
              setPendingReviewPostAdded({
                pendingReviewPostAdded: true,
              }),
            );
            dispatch(
              setPublishedPostAdded({
                publishedPostAdded: true,
              }),
            );
            setCreating(false);
            setIsNotificationModalVisible(true);
          } else {
            setCreating(false);
          }
        } catch (error: unknown) {
          setCreating(false);
          let errorMessage = POST_ERRORS.PUBLISH;
          let errorStatus;
          if (error instanceof AxiosError) {
            const errorData = error?.response?.data;
            errorStatus = error?.response?.status;
            if (errorData?.message) {
              errorMessage = errorData?.message;
            }
          }
          if (errorStatus === 428) {
            setConfirmPublicationModalIsVisible(true);
          } else if (errorStatus !== 401) {
            dispatch(
              openSnackbar({
                type: 'error',
                message: errorMessage,
              }),
            );
          }
        }
      }
    } else {
      dispatch(
        tooglePreviewMode({
          isPreviewModeActive: !isPreviewModeActive,
        }),
      );
    }
  };

  const checkIfPostHasMainImage = (): boolean => {
    const mainImage = elementsContent[4].content;
    return mainImage !== null;
  };

  const checkIfPostHasTitle = (): boolean => {
    const title = elementsContent[0].content;
    return title !== '';
  };

  const checkIfPostHasPDF = (): boolean => {
    const index = elementsContent.findIndex((item) => item.customKey === PDF_URL);
    if (index === -1) return false;
    const pdfUrl = elementsContent[index].content;
    return pdfUrl !== null && pdfUrl !== '';
  };

  const checkIfPostHasKeyIdeas = (): boolean => {
    const keyIdeas = elementsContent[2].content as EditorState[];
    let band = true;
    keyIdeas.forEach((keyIdea) => {
      const currentContent = getPlainText(keyIdea);
      if (currentContent === '') {
        band = false;
      }
    });
    return band;
  };

  const checkPostInformation = (): boolean => {
    if (selectedCategories.length <= 0 || selectedAuthors.length <= 0) {
      if (selectedCategories.length <= 0) {
        dispatch(
          openSnackbar({
            type: 'error',
            message: !isPresentationPage
              ? POST_ERRORS.PUBLISH_MIN_CATEGORIES_QUANTITY
              : POST_ERRORS.PUBLISH_PRESENTATION_MIN_CATEGORIES_QUANTITY,
          }),
        );
        return false;
      } else if (selectedAuthors.length <= 0 && !isPresentationPage) {
        dispatch(
          openSnackbar({
            type: 'error',
            message: !isPresentationPage
              ? POST_ERRORS.PUBLISH_MIN_AUTHORS_QUANTITY
              : POST_ERRORS.PUBLISH_PRESENTATION_MIN_AUTHORS_QUANTITY,
          }),
        );
        return false;
      }
    } else if (!checkIfPostHasTitle()) {
      dispatch(
        openSnackbar({
          type: 'error',
          message: POST_ERRORS.PUBLISH_EMPTY_TITLE,
        }),
      );
      return false;
    } else if (!checkIfPostHasKeyIdeas() && !isPresentationPage) {
      dispatch(
        openSnackbar({
          type: 'error',
          message: POST_ERRORS.PUBLISH_KEY_IDEAS,
        }),
      );
      return false;
    } else if (!checkIfPostHasMainImage()) {
      dispatch(
        openSnackbar({
          type: 'error',
          message: !isPresentationPage
            ? POST_ERRORS.PUBLISH_HEADER_IMAGE
            : POST_ERRORS.PUBLISH_PRESENTATION_HEADER_IMAGE,
        }),
      );
      return false;
    } else if (!checkImagesQuantityOfPost()) {
      dispatch(
        openSnackbar({
          type: 'error',
          message: POST_ERRORS.MAX_IMAGES_QUANTITY,
        }),
      );
      return false;
    } else if (!checkIfPostHasPDF() && isPresentationPage) {
      dispatch(
        openSnackbar({
          type: 'error',
          message: POST_ERRORS.PUBLISH_NO_PDF,
        }),
      );
      return false;
    } else if (
      selectedCategories?.length > MAX_CATEGORIES_QUANTITY ||
      selectedAuthors?.length > MAX_AUTHORS_QUANTITY ||
      selectedTags?.length > MAX_TAGS_QUANTITY
    ) {
      return false;
    }
    return true;
  };

  const checkPostInformationReturnToDraft = () => {
    if (!checkIfPostHasTitle()) {
      dispatch(
        openSnackbar({
          type: 'error',
          message: POST_ERRORS.EMPTY_TITLE,
        }),
      );
      return false;
    } else if (!checkIfPostHasMainImage()) {
      dispatch(
        openSnackbar({
          type: 'error',
          message: POST_ERRORS.PUBLISH_HEADER_IMAGE,
        }),
      );
      return false;
    } else if (!checkImagesQuantityOfPost()) {
      dispatch(
        openSnackbar({
          type: 'error',
          message: POST_ERRORS.MAX_IMAGES_QUANTITY,
        }),
      );
      return false;
    } else if (
      selectedCategories?.length > MAX_CATEGORIES_QUANTITY ||
      selectedAuthors?.length > MAX_AUTHORS_QUANTITY ||
      selectedTags?.length > MAX_TAGS_QUANTITY
    ) {
      return false;
    }
    return true;
  };

  const handleClickSettingButton = async (): Promise<void> => {
    if (currentStatus === POSTS_STATUS.REVIEW_IN_PROGRESS) {
      if (!creating) {
        try {
          setCreating(true);
          if (unsavedChanges) {
            await handleSaveButton();
          }
          if (!checkPostInformationReturnToDraft()) {
            setCreating(false);
            return;
          }
          await returnToDraft(postInformation?.id);
          dispatch(
            openSnackbar({
              type: 'success',
              message: !isPresentationPage
                ? ARTICLE_SUCCESFUL_MESSAGES.RETURN_TO_DRAFT_ARTICLE
                : ARTICLE_SUCCESFUL_MESSAGES.RETURN_TO_DRAFT_PRESENTATION,
            }),
          );
          dispatch(
            setHomeDraftPostUpdated({
              draftPostUpdated: true,
            }),
          );
          dispatch(
            setDraftPostUpdated({
              draftPostUpdated: true,
            }),
          );
          if (isPresentationPage) {
            if (
              presentationsPendingReviewQuantity !== null &&
              presentationsPendingReviewQuantity !== undefined
            ) {
              dispatch(
                setPresentationsPendingReviewQuantity({
                  pendingReviewQuantity: presentationsPendingReviewQuantity - 1,
                }),
              );
            }
          } else {
            if (pendingReviewQuantity !== null && pendingReviewQuantity !== undefined) {
              dispatch(
                setPendingReviewQuantity({
                  pendingReviewQuantity: pendingReviewQuantity - 1,
                }),
              );
            }
          }
          navigate(
            `${
              isPresentationPage ? '/presentations' : '/entries'
            }?type=${POSTS_STATUS.DRAFT.toLowerCase()}`,
          );
        } catch (error: unknown) {
          let errorStatus;
          if (error instanceof AxiosError) {
            errorStatus = error?.response?.status;
          }
          if (errorStatus !== 401) {
            setCreating(false);
            dispatch(
              openSnackbar({
                type: 'error',
                message: POST_ERRORS.RETURN_TO_DRAFT,
              }),
            );
          }
        }
      }
    } else if (currentStatus === POSTS_STATUS.PUBLISHED) {
      setConfirmSendToReviewModalIsVisible(true);
    } else {
      dispatch(
        toogleSettingDrawer({
          isSettingDrawerOpen: true,
        }),
      );
    }
  };

  const handleClickMobileSettingButton = () => {
    dispatch(
      setIsEditorMobileSettingsDrawerOpen({
        isDrawerOpen: true,
      }),
    );
  };

  const getArticleState = (): string => {
    const auxCurrentStatus =
      currentStatus === POSTS_STATUS.PENDING_REVIEW
        ? POSTS_STATUS.REVIEW_IN_PROGRESS
        : currentStatus;
    const index = STATUS_NAMES.findIndex((item) => item.status === auxCurrentStatus);
    if (index === -1) return 'Borrador';
    return STATUS_NAMES[index].name;
  };

  const getPublishButtonText = () => {
    return currentStatus === POSTS_STATUS.REVIEW_IN_PROGRESS
      ? 'Publicar'
      : currentStatus === POSTS_STATUS.PUBLISHED
      ? 'Cancelar'
      : isPreviewModeActive
      ? 'Vista edición'
      : 'Previsualizar';
  };

  const getSettingButtonText = () => {
    return currentStatus === POSTS_STATUS.REVIEW_IN_PROGRESS
      ? 'Devolver a Redactor'
      : currentStatus === POSTS_STATUS.PUBLISHED
      ? `Revisar ${isPresentationPage ? 'PPT' : 'artículo'}`
      : 'Configurar';
  };

  const checkImagesQuantityOfPost = (): boolean => {
    const mainParagraph = elementsContent[3].content as EditorState;
    return getImagesQuantity(mainParagraph) <= MAX_IMAGES_QUANTITY;
  };

  const handleSaveButton = async () => {
    try {
      if (!checkImagesQuantityOfPost()) {
        dispatch(
          openSnackbar({
            type: 'error',
            message: POST_ERRORS.MAX_IMAGES_QUANTITY,
          }),
        );
        return;
      }
      await savePost(
        setCreating,
        postInformation,
        elementsContent,
        elements,
        dispatch,
        creating,
        navigate,
        setIsErrorConcurrenceModalVisible,
        currentVersion,
        setCurrentVersion,
        setIsArticleHasBeenPublishedModalVisible,
        setIsArticleWasReturnedToDraftModalVisible,
        isPresentationPage,
      );
      const title = elementsContent[0].content;
      if (
        currentStatus === POSTS_STATUS.PENDING_REVIEW ||
        currentStatus === POSTS_STATUS.REVIEW_IN_PROGRESS
      ) {
        dispatch(
          setPendingReviewPostUpdated({
            pendingReviewPostUpdated: true,
          }),
        );
      } else {
        dispatch(
          setHomeDraftPostCreated({
            draftPostCreated: true,
          }),
        );
        dispatch(
          setDraftPostAdded({
            draftPostAdded: true,
          }),
        );
      }
      if (postId !== undefined) {
        await callPostDetails(postId as string, setCurrentVersion);
      }
    } catch (err) {}
  };

  const callApiToPublishAPost = async (overwritePriorPublication: boolean) => {
    if (!publishing) {
      try {
        setPublishing(true);
        await publishPost(postInformation?.id, overwritePriorPublication);
        dispatch(
          openSnackbar({
            type: 'success',
            message: !isPresentationPage
              ? ARTICLE_SUCCESFUL_MESSAGES.PUBLISH_ARTICLE
              : ARTICLE_SUCCESFUL_MESSAGES.PUBLISH_PRESENTATION,
          }),
        );
        setIsNotificationModalVisible(true);
        /*  navigate(
          `${
            isPresentationPage ? '/presentations' : '/entries'
          }?type=${POSTS_STATUS.PUBLISHED.toLowerCase()}`,
        ); */
      } catch (error: unknown) {
        setPublishing(false);
        let errorMessage = POST_ERRORS.PUBLISH;
        let errorStatus;
        if (error instanceof AxiosError) {
          const errorData = error?.response?.data;
          errorStatus = error?.response?.status;
          if (errorData?.message) {
            errorMessage = errorData?.message;
          }
        }
        if (errorStatus === 428) {
          setConfirmPublicationModalIsVisible(true);
        } else if (errorStatus !== 401) {
          dispatch(
            openSnackbar({
              type: 'error',
              message: errorMessage,
            }),
          );
          setConfirmPublicationModalIsVisible(false);
        }
      }
    }
  };

  const confirmSendToReview = async () => {
    if (!sendingToReview) {
      try {
        setSendingToReview(true);
        await startReviewOfPost(postId);
        setConfirmSendToReviewModalIsVisible(false);
        dispatch(
          openSnackbar({
            type: 'success',
            message: !isPresentationPage
              ? ARTICLE_SUCCESFUL_MESSAGES.SEND_TO_REVIEW_ARTICLE
              : ARTICLE_SUCCESFUL_MESSAGES.SEND_TO_REVIEW_PRESENTATION,
          }),
        );
        setSendingToReview(false);
        dispatch(
          setWantToReloadPostPage({
            wantToReloadPostPage: !wantToReloadPostPage,
          }),
        );
        dispatch(
          setPendingReviewPostAdded({
            pendingReviewPostAdded: true,
          }),
        );
        dispatch(
          setPublishedPostAdded({
            publishedPostAdded: true,
          }),
        );
      } catch (error: unknown) {
        setSendingToReview(false);
        let errorMessage = POST_ERRORS.REQUEST_REVIEW;
        let errorStatus;
        if (error instanceof AxiosError) {
          const errorData = error?.response?.data;
          errorStatus = error?.response?.status;
          if (errorData?.message) {
            errorMessage = errorData?.message;
          }
        }
        if (errorStatus !== 401) {
          dispatch(
            openSnackbar({
              type: 'error',
              message: errorMessage,
            }),
          );
        }
      }
    }
  };

  return (
    <>
      <Container isPreviewModeActive={isPreviewModeActive}>
        <LeftContainer>
          <BackButton
            id='editor-back-button'
            onClick={handleClickBackButton}
            disabled={creating || sendingToReview || publishing}
          >
            <ArrowBackIcon style={{ color: '#183582' }} />
          </BackButton>
          <CircleIcon style={{ color: '#1D7D54', height: '12px', width: '12px' }} />
          {currentStatus === null ? (
            <CircularProgress color='primary' size={20} style={{ marginLeft: '20px' }} />
          ) : (
            <Status>{getArticleState()}</Status>
          )}
        </LeftContainer>
        {isPreviewModeActive && <Title></Title>}
        <RightContainer>
          {currentStatus !== POSTS_STATUS.PUBLISHED && (
            <>
              <CustomButton
                id='editor-save-button'
                disabled={
                  creating ||
                  !unsavedChanges ||
                  isRemovingSubscription ||
                  isRemovingCategory ||
                  isRemovingTag ||
                  isRemovingAuthor
                }
                bgColor='white'
                withBorder={false}
                onClick={handleSaveButton}
                style={{ paddingLeft: '30px', paddingRight: '30px' }}
              >
                {creating ? (
                  <CircularProgress size={20} />
                ) : (
                  <ButtonText color={creating || !unsavedChanges ? '#c1c1c1' : '#183582'}>
                    Guardar
                  </ButtonText>
                )}
              </CustomButton>
              <LineContainer>
                <Line />
              </LineContainer>
            </>
          )}
          <CustomButton
            id='editor-preview-mode-button'
            disabled={creating || currentStatus === null}
            onClick={handleClickPreviewModeButton}
            bgColor='#F6F6F6'
            withBorder={false}
            style={{
              width: '140px',
              display: currentStatus === POSTS_STATUS.PUBLISHED ? 'none' : 'inherit',
            }}
          >
            {currentStatus === null ? (
              <CircularProgress color='primary' size={20} />
            ) : (
              <ButtonText color='#3C3C3B'>{getPublishButtonText()}</ButtonText>
            )}
          </CustomButton>
          {currentStatus === POSTS_STATUS.PUBLISHED ? (
            checkIfUserHasRole(userProfile?.roles, ROLE_EDITOR) && (
              <CustomButton
                id='editor-setting-button'
                disabled={creating || postInformation == null || currentStatus === null}
                onClick={handleClickSettingButton}
                bgColor={postInformation === null ? '#c1c1c1' : '#183582'}
                withBorder={false}
                style={{ minWidth: '140px', paddingLeft: '15px', paddingRight: '15px' }}
              >
                <ButtonText color='white'>{getSettingButtonText()}</ButtonText>
              </CustomButton>
            )
          ) : (
            <CustomButton
              id='editor-setting-button'
              disabled={creating || postInformation == null || currentStatus === null}
              onClick={handleClickSettingButton}
              bgColor={postInformation === null ? '#c1c1c1' : '#183582'}
              withBorder={false}
              style={{ minWidth: '140px', paddingLeft: '15px', paddingRight: '15px' }}
            >
              <ButtonText color='white'>{getSettingButtonText()}</ButtonText>
            </CustomButton>
          )}
        </RightContainer>
        <MobileSettingButtonContainer>
          <CustomButton
            id='editor-setting-button'
            disabled={creating /*  || postInformation == null || currentStatus === null */}
            onClick={handleClickMobileSettingButton}
            bgColor={postInformation === null ? 'transparent' : 'white'}
            withBorder={false}
          >
            {creating ? (
              <CircularProgress color='primary' size={20} />
            ) : (
              <ButtonText color={'#183582'} style={{ fontWeight: 'bold' }}>
                Configurar
              </ButtonText>
            )}
          </CustomButton>
        </MobileSettingButtonContainer>
      </Container>
      <ConfirmPublicationModal
        isVisible={confirmPublicationModalIsVisible}
        setIsVisible={setConfirmPublicationModalIsVisible}
        publishing={publishing}
        setPublishing={setPublishing}
        callApiToPublishAPost={callApiToPublishAPost}
        contentType={postInformation?.contentType}
      />
      <ConfirmSendToReviewModal
        isVisible={confirmSendToReviewModallIsVisible}
        setIsVisible={setConfirmSendToReviewModalIsVisible}
        title={`¿Enviar ${isPresentationPage ? 'PPT' : 'artículo'} a revisión?`}
        message={`Estás a punto de enviar ${
          isPresentationPage ? 'un PPT publicado' : 'un artículo publicado'
        } a revisión, eso te permitirá modificarlo y por tanto actualizar la publicación actual.`}
        confirmSendToReview={confirmSendToReview}
        sending={sendingToReview}
        setSending={setSendingToReview}
      />
      <CreateNotificationModal
        isVisible={isNotificationModalVisible}
        setIsVisible={setIsNotificationModalVisible}
        isPresentationPage={isPresentationPage}
        thumbnailUrl={postInformation?.images?.principalImage}
        postId={postInformation?.id}
        publicationId={postInformation?.postPublicationId}
        contentType={postInformation?.contentType}
        title={postInformation?.title}
      />
    </>
  );
}

const Container = styled.header<ContainerI>`
  display: ${(props) => (props.isPreviewModeActive ? 'grid' : 'flex')};
  grid-template-columns: repeat(3, 1fr);
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 90px;
  padding-left: 24px;
  padding-right: 24px;
  ${(props) =>
    props.isPreviewModeActive && {
      borderBottom: `1px solid #D9D9D9`,
    }}

  @media screen and (max-width: 860px) {
    margin-top: 10px;
    padding-right: ${(props) => (props.isPreviewModeActive ? '0px' : '24px')};
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const Title = styled.p`
  font-family: ${(props) => props.theme.fonts.bold};
  font-size: 20px;
  text-align: center;
`;

const BackButton = styled(IconButton)`
  height: 40px;
  width: 40px;
  background-color: #f0f6fa;
  margin-right: 13px;
`;

const Status = styled.p`
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.darkGray};
  font-size: 14px;
  margin-left: 8px;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;

  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const MobileSettingButtonContainer = styled.div`
  display: none;
  @media screen and (max-width: 860px) {
    display: inherit;
  }
`;

const CustomButton = styled(Button)<ButtonI>`
  &&& {
    background-color: ${(props) => props.bgColor};
    border-radius: 20px;
  }
  min-width: 80px;
  height: 44px;
  border: 1px solid ${(props) => (props.withBorder ? props.theme.colors.lightGray : 'white')};
`;

const ButtonText = styled.p`
  font-family: ${(props) => props.theme.fonts.medium};
  color: ${(props) => props.color};
  text-transform: none;
  font-size: 16px;
  white-space: nowrap;
`;

const LineContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

const Line = styled.div`
  background-color: ${(props) => props.theme.colors.lightGray};
  width: 2px;
  height: 54px;
`;
