import React from 'react';
import Drawer from '@mui/material/Drawer';
import styled from 'styled-components';

interface Props {
  children: React.ReactNode;
  isOpen: boolean;
  setIsOpen: (v: boolean) => void;
  resetValues?: () => void;
}

export default function CustomDrawer({
  children,
  isOpen,
  setIsOpen,
  resetValues,
}: Props): JSX.Element {
  const handleClose = (): void => {
    setIsOpen(false);
    if (resetValues !== undefined) {
      resetValues();
    }
  };

  return (
    <Drawer
      open={isOpen}
      onClose={handleClose}
      anchor='right'
      variant='temporary'
      ModalProps={{
        keepMounted: true,
      }}
    >
      <Container>{children}</Container>
    </Drawer>
  );
}

const Container = styled.div`
  width: 385px;

  @media screen and (max-width: 860px) {
    width: 100vw;
  }
`;
