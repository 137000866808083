import React, { useState } from 'react';
import styled from 'styled-components';
import HeaderButtonWithText from '../components/globals/HeaderButtonWithText';
import TopTabs from '../components/campaings/TopTabs';
import CampaignsList from '../components/campaings/CampaignsList';

export default function Campaigns() {
  const [searchInput, setSearchInput] = useState('');

  return (
    <Container>
      <TitleContainer>
        <Title>Campañas</Title>
      </TitleContainer>
      <TopTabsContainer>
        <TopTabs />
      </TopTabsContainer>
      <CampaignsList />
    </Container>
  );
}

const Container = styled.section`
  padding-left: 24px;
  padding-right: 24px;
  margin-left: 192px;
  margin-top: 72px;
  width: calc(100vw - 192px);

  @media screen and (max-width: 860px) {
    margin-left: 0px;
    width: 100vw;
    margin-top: 70px;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 42px;

  @media screen and (max-width: 860px) {
    padding: 0px 24px;
  }
`;

const Title = styled.p`
  font-size: 28px;
  font-family: ${(props) => props.theme.fonts.semiBold};
  color: ${(props) => props.theme.colors.primary};
  flex: 1;
`;

const TopTabsContainer = styled.div`
  @media screen and (max-width: 860px) {
    display: none;
  }
`;
