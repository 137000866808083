import React from 'react';
import { MenuItem } from '@mui/material';
import classNames from 'classnames';
import styled from 'styled-components';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

interface Props {
  title: string;
  handleClick: () => void;
}

export default function OptionItem({ title, handleClick }: Props) {
  return (
    <MenuItem style={{ padding: '15px 12px' }} onClick={handleClick}>
      <Title>{title}</Title>
      <ChevronRightIcon />
    </MenuItem>
  );
}

const Title = styled.p`
  flex: 1;
  font-family: ${(props) => props.theme.fonts.regular};
  color: #183582;
`;
