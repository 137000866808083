import React from 'react';
import { Button } from '@mui/material';
import styled from 'styled-components';
import { FiSettings } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { toogleSettingDrawer } from '../../context/reducers/toolBar';

export default function ConfigurationButton() {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(
      toogleSettingDrawer({
        isSettingDrawerOpen: true,
      }),
    );
  };

  return (
    <CustomIconButton
      onClick={handleClick}
      endIcon={<FiSettings style={{ fontSize: '16px', color: '#183582' }} />}
    >
      <Title>Configuración</Title>
    </CustomIconButton>
  );
}

const CustomIconButton = styled(Button)`
  position: fixed;
  z-index: 999;
  bottom: 30px;
  right: 8px;
  height: 40px;
  width: 180px;
  background-color: ${(props) => props.theme.colors.matizBlue};
  margin-right: 13px;
  border-radius: 30px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);

  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const Title = styled.p`
  font-family: ${(props) => props.theme.fonts.regular};
  font-size: 15px;
  text-transform: none;
  color: ${(props) => props.theme.colors.primary};
`;
