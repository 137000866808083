import React, { useEffect, useRef, useState } from 'react';
import ComboBox from './ComboBox';
import { getSubscriptions } from '../../api/subscriptions';
import { AxiosError } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { openSnackbar } from '../../context/reducers/generalSnackbar';
import { SETTINGS_ERRORS, SUBSCRIPTIONS_ERRORS } from '../../constants/errorMessages';
import type Subscription from '../../interfaces/Subscription';
import { type RootState } from '../../context/store';
import {
  setIsRemovingSubscription,
  setSubscriptionId,
  setSubscriptionsList,
} from '../../context/reducers/subscriptions';
import { useParams } from 'react-router-dom';
import { addSubscriptionsToPost, deleteSubscriptionsFromPost } from '../../api/posts';
import { callPostDetails } from '../../utils/editor';
import {
  SETTINGS_ADD_SUCCESFUL_MESSAGES,
  SETTINGS_REMOVE_SUCCESFUL_MESSAGES,
} from '../../constants/successfulMessages';

interface Props {
  selectedSubscriptions: any[];
  setSelectedSubscriptions: React.Dispatch<React.SetStateAction<any>>;
  setCurrentVersion: React.Dispatch<React.SetStateAction<number>>;
}

export default function ComboBoxSubscriptionsWrapped({
  selectedSubscriptions,
  setSelectedSubscriptions,
  setCurrentVersion,
}: Props): JSX.Element {
  const subscriptions = useSelector((state: RootState) => state.subscriptions.subscriptions);
  const [auxSelectedSuscriptions, setAuxSelectedSuscriptions] = useState(selectedSubscriptions);
  const dispatch = useDispatch();
  const { postId } = useParams();
  const isMounted = useRef(false);

  const callApiToGetSubscriptions = () => {
    getSubscriptions()
      .then((res) => {
        dispatch(
          setSubscriptionsList({
            subscriptions: res,
          }),
        );
      })
      .catch((error: unknown) => {
        let errorStatus;
        if (error instanceof AxiosError) {
          errorStatus = error?.response?.status;
        }
        if (errorStatus !== 401) {
          dispatch(
            openSnackbar({
              type: 'error',
              message: SUBSCRIPTIONS_ERRORS.SUBSCRIPTIONS_LIST,
            }),
          );
        }
      });
  };

  useEffect(() => {
    callApiToGetSubscriptions();
  }, []);

  const handleRemoveSubscription = async (deletedSubscription: string) => {
    try {
      dispatch(
        setIsRemovingSubscription({
          isRemovingSubscription: true,
        }),
      );
      dispatch(
        setSubscriptionId({
          subscriptionId: deletedSubscription,
        }),
      );
      await deleteSubscriptionsFromPost(postId, deletedSubscription);
      dispatch(
        openSnackbar({
          type: 'success',
          message: SETTINGS_REMOVE_SUCCESFUL_MESSAGES.REMOVE_SUBSCRIPTION,
        }),
      );
      dispatch(
        setIsRemovingSubscription({
          isRemovingSubscription: false,
        }),
      );
      dispatch(
        setSubscriptionId({
          subscriptionId: null,
        }),
      );
    } catch (error: unknown) {
      dispatch(
        setIsRemovingSubscription({
          isRemovingSubscription: false,
        }),
      );
    }
  };

  const handleAddNewSubscription = async (newSubscription: string) => {
    try {
      dispatch(
        setIsRemovingSubscription({
          isRemovingSubscription: true,
        }),
      );
      dispatch(
        setSubscriptionId({
          subscriptionId: newSubscription,
        }),
      );
      await addSubscriptionsToPost(postId, [newSubscription]);
      dispatch(
        openSnackbar({
          type: 'success',
          message: SETTINGS_ADD_SUCCESFUL_MESSAGES.ADD_SUBSCRIPTION,
        }),
      );
      dispatch(
        setIsRemovingSubscription({
          isRemovingSubscription: false,
        }),
      );
      dispatch(
        setSubscriptionId({
          subscriptionId: null,
        }),
      );
    } catch (error: unknown) {
      dispatch(
        openSnackbar({
          type: 'error',
          message: SETTINGS_ERRORS.ADD_SUBSCRIPTION,
        }),
      );
      dispatch(
        setIsRemovingSubscription({
          isRemovingSubscription: false,
        }),
      );
    }
  };

  const getNewSubscription = (
    newSelectedSubscriptions: any[],
    currentSubscriptions: any[],
  ): string => {
    const addedSubscriptions: any[] = [];
    let band = false;
    newSelectedSubscriptions.forEach((selectedSubscription) => {
      currentSubscriptions.forEach((currentSubscription) => {
        if ((selectedSubscription?.id ?? selectedSubscription) === currentSubscription) {
          band = true;
        }
      });
      if (!band) {
        addedSubscriptions.push(selectedSubscription?.id ?? selectedSubscription);
      }
      band = false;
    });
    return addedSubscriptions[0]?.id ?? addedSubscriptions[0];
  };

  const getDeletedSubscription = (
    currentSubscriptions: any[],
    newSelectedSubscriptions: any[],
  ): string => {
    const deletedSubscriptions: any[] = [];
    let band = false;
    currentSubscriptions.forEach((currentSubscription) => {
      newSelectedSubscriptions.forEach((selectedSubscription) => {
        if (currentSubscription === (selectedSubscription?.id ?? selectedSubscription)) {
          band = true;
        }
      });
      if (!band) {
        deletedSubscriptions.push(currentSubscription);
      }
      band = false;
    });
    return deletedSubscriptions[0]?.id ?? deletedSubscriptions[0];
  };

  const handleChange = async (
    values: any[] | string | null,
    deleteFromTagChip: React.MutableRefObject<boolean>,
  ): Promise<void> => {
    try {
      const previousSelectedSubscriptions = [...auxSelectedSuscriptions];
      if (isMounted.current) {
        if (values !== undefined && values !== null && !deleteFromTagChip.current) {
          if (previousSelectedSubscriptions.length < values?.length) {
            const newSubscription = getNewSubscription(
              values as any[],
              previousSelectedSubscriptions,
            );
            await handleAddNewSubscription(newSubscription);
          } else {
            const deletedSubscription = getDeletedSubscription(
              previousSelectedSubscriptions,
              values as any[],
            );
            await handleRemoveSubscription(deletedSubscription);
          }
          if (postId !== undefined) {
            await callPostDetails(postId, setCurrentVersion);
          }
        }
      }
      if (Array.isArray(values)) {
        setAuxSelectedSuscriptions(
          values?.map((item) => {
            return item?.id ?? item;
          }),
        );
      }
      isMounted.current = true;
    } catch (err) {}
  };
  return (
    <ComboBox
      parent='drawer'
      label='Disponible para'
      placeholder='Selecciona las suscripciones'
      multiple={true}
      page='editor'
      values={selectedSubscriptions}
      handleChange={handleChange}
      auxOptions={subscriptions}
      type='subscription'
      setCurrentVersion={setCurrentVersion}
    />
  );
}
