import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import type TagDetails from '../../interfaces/TagDetails';
import ComboBox from './ComboBox';
import { getTags } from '../../api/tags';
import { PAGE_SIZE } from '../../constants/pagination';
import { openSnackbar } from '../../context/reducers/generalSnackbar';
import { SETTINGS_ERRORS, TAGS_ERRORS } from '../../constants/errorMessages';
import type PostInformation from '../../interfaces/PostInformation';
import { AxiosError } from 'axios';
import { callPostDetails } from '../../utils/editor';
import { useParams } from 'react-router-dom';
import { setIsRemovingTag, setTagId } from '../../context/reducers/tags';
import { addTagsToPost, deleteTagFromPost } from '../../api/posts';
import { MAX_TAGS_QUANTITY } from '../../constants/globals';
import {
  SETTINGS_ADD_SUCCESFUL_MESSAGES,
  SETTINGS_REMOVE_SUCCESFUL_MESSAGES,
} from '../../constants/successfulMessages';

interface Props {
  page?: string;
  postInformation: PostInformation | null;
  selectedTags: TagDetails[];
  setSelectedTags: React.Dispatch<React.SetStateAction<TagDetails[]>>;
  label?: string;
  parent?: string;
  placeholder?: string;
  setCurrentVersion?: React.Dispatch<React.SetStateAction<number>>;
}

export default function ComboBoxTagsWrapped({
  page = 'entries',
  postInformation,
  selectedTags,
  setSelectedTags,
  label = '',
  parent,
  placeholder = 'Etiquetas',
  setCurrentVersion,
}: Props): JSX.Element {
  const [tags, setTags] = useState<TagDetails[] | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [tagsTotal, setTagsTotal] = useState(0);
  const [isLoadingMoreData, setIsLoadingMoreData] = useState(false);
  const [canLoadMoreData, setCanLoadMoreData] = useState(false);
  const [auxSelectedTags, setAuxSelectedTags] = useState(selectedTags);
  const dispatch = useDispatch();
  const { postId } = useParams();
  const isMounted = useRef(false);

  const callApiToGetTags = (name: string): void => {
    getTags(name || '', 25, 0)
      .then((res) => {
        setTags(res.content);
        setTagsTotal(res.totalElements);
        if (!res.last) {
          setCurrentPage(1);
          setCanLoadMoreData(true);
        } else {
          setCurrentPage(0);
          setCanLoadMoreData(false);
        }
      })
      .catch((error: unknown) => {
        let errorStatus;
        if (error instanceof AxiosError) {
          errorStatus = error?.response?.status;
        }
        if (errorStatus !== 401) {
          dispatch(
            openSnackbar({
              type: 'error',
              message: TAGS_ERRORS.TAGS_LIST,
            }),
          );
        }
      });
  };

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    callApiToGetTags('');
  }, []);

  const getDeletedTag = (currentSelectedTags: any[], newCurrentSelectedTags: any[]): string => {
    const deletedTags: any[] = [];
    let band = false;
    currentSelectedTags.forEach((currentTag) => {
      newCurrentSelectedTags.forEach((selectedTag) => {
        if (currentTag.tag === selectedTag.tag) {
          band = true;
        }
      });
      if (!band) {
        deletedTags.push(currentTag);
      }
      band = false;
    });
    return deletedTags[0]?.tag ?? deletedTags[0].id;
  };

  const handleRemoveTag = async (deletedTag: string) => {
    try {
      dispatch(
        setIsRemovingTag({
          isRemovingTag: true,
        }),
      );
      dispatch(
        setTagId({
          tagId: deletedTag,
        }),
      );
      await deleteTagFromPost(postId, deletedTag);
      dispatch(
        openSnackbar({
          type: 'success',
          message: SETTINGS_REMOVE_SUCCESFUL_MESSAGES.REMOVE_TAG,
        }),
      );
      dispatch(
        setIsRemovingTag({
          isRemovingTag: false,
        }),
      );
      dispatch(
        setTagId({
          tagId: null,
        }),
      );
    } catch (err: unknown) {
      dispatch(
        setIsRemovingTag({
          isRemovingTag: false,
        }),
      );
    }
  };

  const getAddedTag = (currentSelectedTags: any[], newSelectedTags: any[]): string[] => {
    const addedTags: any[] = [];
    let band = false;
    newSelectedTags.forEach((selectedTag) => {
      currentSelectedTags.forEach((currentTag) => {
        if ((selectedTag?.tag ?? selectedTag) === (currentTag?.tag ?? currentTag)) {
          band = true;
        }
      });
      if (!band) {
        addedTags.push(selectedTag);
      }
      band = false;
    });
    const formattedTags = addedTags.map((item) => item.tag);
    return formattedTags.slice(0, 1);
  };

  const handleAddTag = async (addedTag: string[]) => {
    try {
      dispatch(
        setIsRemovingTag({
          isRemovingTag: true,
        }),
      );
      dispatch(
        setTagId({
          tagId: addedTag[0],
        }),
      );
      await addTagsToPost(postId, addedTag);
      dispatch(
        openSnackbar({
          type: 'success',
          message: SETTINGS_ADD_SUCCESFUL_MESSAGES.ADD_TAG,
        }),
      );
      dispatch(
        setIsRemovingTag({
          isRemovingTag: false,
        }),
      );
      dispatch(
        setTagId({
          tagId: null,
        }),
      );
    } catch (error: unknown) {
      dispatch(
        openSnackbar({
          type: 'error',
          message: SETTINGS_ERRORS.ADD_TAG,
        }),
      );
      dispatch(
        setIsRemovingTag({
          isRemovingTag: false,
        }),
      );
    }
  };

  const handleChange = async (
    values: any[] | string | null,
    deleteFromTagChip: React.MutableRefObject<boolean>,
  ): Promise<void> => {
    try {
      const previousSelectedTags = [...auxSelectedTags];
      if (isMounted.current) {
        if (values !== undefined && values !== null && !deleteFromTagChip.current) {
          if (previousSelectedTags.length < values?.length && values?.length <= MAX_TAGS_QUANTITY) {
            const addedTag = getAddedTag(previousSelectedTags, values as any[]);
            await handleAddTag(addedTag);
          } else {
            const deletedTag = getDeletedTag(previousSelectedTags, values as any[]);
            await handleRemoveTag(deletedTag);
          }
          if (postId !== undefined && setCurrentVersion !== undefined) {
            await callPostDetails(postId, setCurrentVersion);
          }
        }
      }
      if (Array.isArray(values) && values.length <= MAX_TAGS_QUANTITY) {
        setAuxSelectedTags(values?.map((item) => item));
      }
    } catch (err) {}
  };

  return (
    <ComboBox
      parent={parent ?? undefined}
      label={label}
      placeholder={placeholder}
      multiple={true}
      values={selectedTags}
      setValues={setSelectedTags}
      handleChange={handleChange}
      auxOptions={tags}
      type='tag'
      page={page}
      setCurrentVersion={setCurrentVersion}
    />
  );
}
