import React from 'react';
import styled from 'styled-components';
import POSTS_STATUS, { STATUS_NAMES } from '../../constants/status';

interface Props {
  status: string;
}

export default function ResultStatusTagItem({ status }: Props) {
  const getStatus = (): string => {
    if (status === POSTS_STATUS.PENDING_REVIEW) {
      return 'Pendiente';
    }
    return STATUS_NAMES[STATUS_NAMES.findIndex((item) => item.status === status)]?.name || '';
  };

  const getBackgroundColor = () => {
    return STATUS_NAMES[STATUS_NAMES.findIndex((item) => item.status === status)]?.bgColor || '';
  };

  const getColor = () => {
    return STATUS_NAMES[STATUS_NAMES.findIndex((item) => item.status === status)]?.color || '';
  };

  return (
    <Container style={{ backgroundColor: getBackgroundColor() }}>
      <Status style={{ color: getColor() }}>{getStatus()}</Status>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  border-radius: 10px;
  width: 80px;
`;

const Status = styled.p`
  font-family: ${(props) => props.theme.fonts.regular};
  font-size: 14px;
`;
