/* eslint-disable @typescript-eslint/prefer-optional-chain */
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { StyledEngineProvider, ThemeProvider, createTheme } from '@mui/material/styles';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import { Auth0Provider, type Auth0ProviderOptions, type AppState } from '@auth0/auth0-react';
import history from './utils/history';
import { base } from './styles/StylesComponentsTheme';
import GlobalStyle from './styles/GlobalStyles';
import store from './context/store';

const rootElement = document.getElementById('root');

const root = ReactDOM.createRoot(rootElement as HTMLElement);

const muiTheme = createTheme({
  components: {
    MuiPopover: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiPopper: {
      defaultProps: {
        container: rootElement,
      },
    },
  },
  palette: {
    primary: {
      main: '#006F9B',
    },
    secondary: {
      main: '#0094CF',
    },
  },
});

const styledComponetsTheme = {
  ...base,
};

const onRedirectCallback = (appState: AppState | undefined) => {
  history.push(appState && appState?.returnTo ? appState?.returnTo : window.location.pathname);
};

const providerConfig: Auth0ProviderOptions = {
  domain: process.env.REACT_APP_LOGIN_DOMAIN as string,
  clientId: process.env.REACT_APP_LOGIN_CLIENT_ID as string,
  onRedirectCallback,
  authorizationParams: {
    prompt: 'login',
    redirect_uri: window.location.origin,
    ...(process.env.REACT_APP_AUDIENCE ? { audience: process.env.REACT_APP_AUDIENCE } : null),
  },
};

root.render(
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <StyledComponentsThemeProvider theme={styledComponetsTheme}>
        <ThemeProvider theme={muiTheme}>
          <GlobalStyle />
          <Auth0Provider {...providerConfig}>
            <App />
          </Auth0Provider>
        </ThemeProvider>
      </StyledComponentsThemeProvider>
    </StyledEngineProvider>
  </Provider>,
);
