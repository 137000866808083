import React from 'react';
import type Icon from '../interfaces/Icon';

export default function UnderlineIcon({ color = '#3C3C3B' }: Icon): JSX.Element {
  return (
    <svg width='13' height='15' viewBox='0 0 13 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.7861 12.8571V14.5714H0.786133V12.8571H12.7861ZM10.2147 7.04143C10.1865 7.60713 10.0185 8.15703 9.72591 8.64198C9.43327 9.12693 9.02505 9.53183 8.53772 9.82049C8.05039 10.1092 7.49914 10.2726 6.93323 10.2962C6.36732 10.3198 5.80437 10.2028 5.2947 9.95571C4.70725 9.70157 4.20905 9.27783 3.86393 8.73877C3.51882 8.19971 3.34249 7.56989 3.35756 6.93V0.00428581H1.64328V7.04143C1.67227 7.84835 1.89086 8.63711 2.28135 9.34385C2.67183 10.0506 3.22324 10.6555 3.89094 11.1095C4.55863 11.5635 5.32385 11.854 6.12465 11.9573C6.92546 12.0606 7.73934 11.974 8.50042 11.7043C9.5128 11.3668 10.3912 10.7153 11.0078 9.84431C11.6244 8.97335 11.9471 7.92841 11.929 6.86143V0.00428581H10.2147V7.04143ZM10.2147 0H11.929H10.2147ZM3.35756 0H1.64328H3.35756Z'
        fill={color}
      />
    </svg>
  );
}
