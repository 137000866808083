import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import type Campaign from '../../interfaces/Campaigns';
import WithoutImageIcon from '../../icons/WithoutImageIcon';
import capitalizeFirstLetter from '../../utils/capitalizeFirstLetter';
import ScheduledCalendarIcon from '../../icons/ScheduledCalendarIcon';
import TrashIcon from '../../icons/TrashIcon';
import EyeIcon from '../../icons/EyeIcon';
import { Checkbox } from '@mui/material';
import { CAMPAIGNS_STATUS } from '../../constants/status';
import { days, months } from '../../utils/date';
import { formatLessThan10 } from '../../utils/formatLessThan10';

export default function ProcessedItem({
  title,
  body,
  scheduledAt,
  status,
  id,
  thumbnailUrl,
}: Campaign): JSX.Element {
  const [deleting, setDeleting] = useState(false);

  const getTitle = () => {
    if (!title) return 'Sin título';
    return title;
  };

  const getFormattedScheduledAt = () => {
    if (!scheduledAt) return '';
    const utcDate = new Date(scheduledAt);
    const offset = -5;
    const auxScheduledAt = new Date(utcDate.getTime() + offset * 60 * 60 * 1000);
    return `${capitalizeFirstLetter(days[auxScheduledAt.getDay()])}, ${formatLessThan10(
      auxScheduledAt.getDate(),
    )} de ${capitalizeFirstLetter(months[auxScheduledAt.getMonth()])}  ${formatLessThan10(
      auxScheduledAt.getHours(),
    )}:${auxScheduledAt.getMinutes() === 0 ? '00' : formatLessThan10(auxScheduledAt.getMinutes())}`;
  };
  return (
    <>
      <Container>
        <Row>
          <LeftContainer>
            <ImageContainer>
              {thumbnailUrl ? <Image src={thumbnailUrl} alt={title} /> : <WithoutImageIcon />}
            </ImageContainer>
            <Title>{capitalizeFirstLetter(getTitle())}</Title>
          </LeftContainer>
          <ScheduledAtContainer>
            <ScheduledCalendarIcon />
            <ScheduledAt>{getFormattedScheduledAt()}</ScheduledAt>
          </ScheduledAtContainer>
          <ButtonsContainer>
            <Button
              disabled={true}
              className='px-5 rounded-2xl mr-2'
              style={{
                border: '1px solid #183582',
              }}
            >
              <ButtonText style={{ color: '#183582' }}>Todos</ButtonText>
            </Button>
          </ButtonsContainer>
        </Row>
      </Container>
    </>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid;
  border-bottom-color: ${(props) => props.theme.colors.gray2};
  height: 97px;
  padding-right: 20px;
  padding-left: 20px;

  @media screen and (max-width: 860px) {
    display: block;
    height: inherit;
    padding-top: 15px;
    border: 1px solid ${(props) => props.theme.colors.lightSkyBlue};
    border-radius: 8px;
    margin-bottom: 8px;
    padding-right: 10px;
    margin-left: 24px;
    margin-right: 24px;
  }
`;

const Row = styled.div`
  height: 97px;
  width: 100%;
  display: grid;
  grid-template-columns: 1.5fr 1fr 0.7fr;

  @media screen and (max-width: 860px) {
    grid-template-columns: 1fr;
    height: inherit;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`;

const ImageContainer = styled.div`
  height: 72px;
  width: 72px;
  border-radius: 10px;
  overflow: hidden;
  background-color: ${(props) => props.theme.colors.lightSkyBlue};
  margin-left: 9px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 860px) {
    height: 90px;
    margin-left: 0px;
  }
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const Title = styled.p`
  margin-left: 12px;
  flex: 1;
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.darkGray};
  font-size: 16px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;

  @media screen and (max-width: 860px) {
    -webkit-line-clamp: 3;
    line-clamp: 3;
  }
`;

const ScheduledAtContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  @media screen and (max-width: 860px) {
    margin-top: 15px;
    margin-bottom: 10px;
  }
`;

const ScheduledAt = styled.p`
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.darkGray};
  font-size: 16px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const VisualizationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const ButtonText = styled.p`
  font-family: ${(props) => props.theme.fonts.medium};
  color: white;
  text-transform: none;
`;
