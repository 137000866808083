import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import MenuItem from '@mui/material/MenuItem';
import classNames from 'classnames';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { type SubOption as SubOptionInterface } from '../../interfaces/SideBarOptionItem';
import { setInputOfGeneralSearch } from '../../context/reducers/generalSearch';

export default function SideBarSubOptionItem({ title, path }: SubOptionInterface): JSX.Element {
  const navigation = useNavigate();
  const location = useLocation();
  const [isSelected, setIsSelected] = useState(location.pathname.startsWith(path));
  const dispatch = useDispatch();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const paramValue = queryParams.get('type');

    setIsSelected(
      location.pathname.startsWith(path) ||
        (paramValue !== null && path.includes('type') && path.startsWith(location.pathname)),
    );
  }, [location]);

  const handleOnClick = (): void => {
    dispatch(
      setInputOfGeneralSearch({
        searchInput: '',
      }),
    );
  };

  return (
    <Link to={path} onClick={handleOnClick}>
      <MenuItem
        className={classNames('h-10 pl-9', {
          'bg-accent-blue': isSelected,
        })}
      >
        <SubOptionTitle>{title}</SubOptionTitle>
      </MenuItem>
    </Link>
  );
}

const SubOptionTitle = styled.p`
  color: white;
  font-family: ${(props) => props.theme.fonts.regular};
  font-size: 14px;
`;
