import { createSlice } from '@reduxjs/toolkit';
import type PendingReviewInterface from '../../interfaces/PendingReview';

interface InitialState {
  pendingReviews: PendingReviewInterface[] | null;
  currentPage: number;
  isLoadingMoreData: boolean;
  canLoadMoreData: boolean;
  isDrawerOpen: boolean;
}

const initialState: InitialState = {
  pendingReviews: null,
  currentPage: 0,
  isLoadingMoreData: false,
  canLoadMoreData: false,
  isDrawerOpen: false,
};

const entriesPendingReviewSlice = createSlice({
  name: 'entriesPendingReviews',
  initialState,
  reducers: {
    setEntriesPendingReviewsResetValues: (state, action) => {
      state.pendingReviews = null;
      state.currentPage = 0;
      state.isLoadingMoreData = false;
      state.canLoadMoreData = false;
    },
    setEntriesPendingReviews: (state, action) => {
      state.pendingReviews = action.payload.pendingReviews;
    },
    setEntriesPendingReviewsCurrentPage: (state, action) => {
      state.currentPage = action.payload.currentPage;
    },
    setEntriesPendingReviewsIsLoadingMoreData: (state, action) => {
      state.isLoadingMoreData = action.payload.isLoadingMoreData;
    },
    setEntriesPendingReviewsCanLoadMoreData: (state, action) => {
      state.canLoadMoreData = action.payload.canLoadMoreData;
    },
    setEntriesPendingReviewsIsDrawerOpen: (state, action) => {
      state.isDrawerOpen = action.payload.isDrawerOpen;
    },
  },
});

export const {
  setEntriesPendingReviews,
  setEntriesPendingReviewsCanLoadMoreData,
  setEntriesPendingReviewsCurrentPage,
  setEntriesPendingReviewsIsLoadingMoreData,
  setEntriesPendingReviewsResetValues,
  setEntriesPendingReviewsIsDrawerOpen,
} = entriesPendingReviewSlice.actions;
export default entriesPendingReviewSlice.reducer;
