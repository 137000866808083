import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useSelector, useDispatch } from 'react-redux';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HeaderLogo from './HeaderLogo';
import SearchBar from './SearchBar';
import HeaderProfile from './HeaderProfile';
import ToolBar from '../editor/ToolBar';
import { type RootState } from '../../context/store';
import { toogleIsSecondaryToolBarVisible } from '../../context/reducers/editor';
import { getUserProfle } from '../../api/users';
import { setUserProfile } from '../../context/reducers/user';
import MenuDrawer from './MenuDrawer';
import { setIsMainDrawerOpen } from '../../context/reducers/layout';
import MobileToolBar from '../editor/MobileToolBar';

interface MobileSearchBarContainerI {
  showMobileSearchBarContainer: boolean;
}

export default function Header(): JSX.Element {
  const isPreviewModeActive = useSelector((state: RootState) => state.toolBar.isPreviewModeActive);
  const isSecondaryToolBarVisible = useSelector(
    (state: RootState) => state.editor.isSecondaryToolBarVisible,
  );
  const location = useLocation();
  const dispatch = useDispatch();

  const handleScroll = (): void => {
    const scrollPosition = window.scrollY;
    dispatch(
      toogleIsSecondaryToolBarVisible({
        isSecondaryToolBarVisible: scrollPosition >= 135,
      }),
    );
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    getUserProfle()
      .then((res) => {
        dispatch(
          setUserProfile({
            userProfile: res,
          }),
        );
      })
      .catch(() => {});
  }, []);

  const handleClickOpenMenu = () => {
    dispatch(
      setIsMainDrawerOpen({
        isMainDrawerOpen: true,
      }),
    );
  };

  return (
    <>
      <CustomHeader showMobileSearchBarContainer={location.pathname === '/'}>
        <Row>
          <MenuBtnContainer>
            <IconButton onClick={handleClickOpenMenu}>
              <MenuIcon style={{ color: 'white' }} fontSize='large' />
            </IconButton>
          </MenuBtnContainer>
          <HeaderLogoContainer>
            <HeaderLogo />
          </HeaderLogoContainer>
          <SearchBarContainer>
            <SearchBar />
          </SearchBarContainer>
          <HeaderProfile />
        </Row>
        {location.pathname === '/' && (
          <MobileSearchBarContainer>
            <SearchBar />
          </MobileSearchBarContainer>
        )}
      </CustomHeader>
      {(location.pathname === '/new-post' || location.pathname.includes('/post/')) &&
        !isPreviewModeActive && (
          <ToolBarContainer
            id='tool-bar-with-animation'
            initial={{ y: 0, opacity: 0 }}
            animate={{ y: isSecondaryToolBarVisible ? 72 : 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
            exit={{ y: 0, opacity: 0 }}
          >
            <ToolBar type='secondary' showToolBar={isSecondaryToolBarVisible} />
          </ToolBarContainer>
        )}
      <MenuDrawer />
    </>
  );
}

const CustomHeader = styled.header<MobileSearchBarContainerI>`
  position: fixed;
  top: 0px;
  z-index: 999;
  width: 100vw;
  height: 72px;
  background-color: ${(props) => props.theme.colors.primary};

  @media screen and (max-width: 860px) {
    padding-top: 15px;
    padding-bottom: ${(props) => (!props.showMobileSearchBarContainer ? '15px' : '0px')};
    height: ${(props) => (props.showMobileSearchBarContainer ? '150px' : 'inherit')};
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;

  @media screen and (max-width: 860px) {
    height: 60px;
  }
`;

const MobileSearchBarContainer = styled.div`
  display: none;
  @media screen and (max-width: 860px) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    margin-top: 10px;
    background-color: ${(props) => props.theme.colors.primary};
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const MenuBtnContainer = styled.div`
  display: none;
  @media screen and (max-width: 860px) {
    display: flex;
    padding-left: 10px;
  }
`;

const HeaderLogoContainer = styled.div`
  @media screen and (max-width: 860px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }
`;

const SearchBarContainer = styled.div`
  flex: 1;
  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const ToolBarContainer = styled(motion.div)`
  position: fixed;
  top: 0px;
  z-index: 100;
  width: 100vw;
  background-color: white;

  @media screen and (max-width: 860px) {
    display: none;
  }
`;
