import React from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import ModalContainer from '../globals/ModalContainer';
import { CircularProgress } from '@mui/material';

interface Props {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  message?: string;
  deleting: boolean;
  setDeleting: React.Dispatch<React.SetStateAction<boolean>>;
  confirmDeletePost: () => Promise<void>;
}

interface CustomButtonI {
  withBackground: boolean;
}

interface CustomButtonTextI {
  white: boolean;
}

export default function ConfirmForceDeleteModal({
  message = '',
  isVisible,
  setIsVisible,
  deleting,
  setDeleting,
  confirmDeletePost,
}: Props): JSX.Element {
  const handleClose = (): void => {
    setIsVisible(false);
  };

  return (
    <ModalContainer isVisible={isVisible} setIsVisible={setIsVisible}>
      <Container>
        <Message>{message}</Message>
        <ButtonsContainer>
          <CustomButton disabled={deleting} onClick={handleClose} withBackground={false}>
            <ButtonText white={false}>Cancelar</ButtonText>
          </CustomButton>
          <CustomButton disabled={deleting} onClick={confirmDeletePost} withBackground={true}>
            {deleting ? (
              <CircularProgress size={22} style={{ color: 'white' }} />
            ) : (
              <ButtonText white={true}>Sí, eliminar</ButtonText>
            )}
          </CustomButton>
        </ButtonsContainer>
      </Container>
    </ModalContainer>
  );
}

const Container = styled.div``;

const Title = styled.p`
  font-family: ${(props) => props.theme.fonts.semiBold};
  color: ${(props) => props.theme.colors.black};
  font-size: 20px;
  margin-bottom: 15px;
`;

const Message = styled.p`
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.black};
  margin-bottom: 20px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
`;

const CustomButton = styled(Button)<CustomButtonI>`
  &&& {
    border: 1px solid;
    border-color: ${(props) => props.theme.colors.primary};
    border-radius: 20px;
    background-color: ${(props) =>
      props?.withBackground ? props.theme.colors.primary : 'transparent'};
  }
`;

const ButtonText = styled.p<CustomButtonTextI>`
  text-transform: none;
  font-family: ${(props) => props.theme.fonts.bold};
  color: ${(props) => (props.white ? 'white' : props.theme.colors.primary)};
  font-size: 13px;
  padding: 0px 15px;
`;
