import { createSlice } from '@reduxjs/toolkit';
import { type Stats } from '../../interfaces/Stats';

interface InitialState {
  stats: Stats | null;
}

const initialState: InitialState = {
  stats: null,
};

const statsSlice = createSlice({
  name: 'stats',
  initialState,
  reducers: {
    setStats: (state, action) => {
      state.stats = action.payload.stats;
    },
  },
});

export const { setStats } = statsSlice.actions;
export default statsSlice.reducer;
