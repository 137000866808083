import React, { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { useDispatch } from 'react-redux';
import OptionWithList from './OptionWithList';
import type AuthorDetailsI from '../../../interfaces/AuthorDetails';
import { getAuthors } from '../../../api/authors';
import { AUTHORS_ERRORS } from '../../../constants/errorMessages';
import { openSnackbar } from '../../../context/reducers/generalSnackbar';
import { PAGE_SIZE } from '../../../constants/pagination';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
  selectedAuthors: any[];
  setSelectedAuthors: React.Dispatch<React.SetStateAction<any[]>>;
}

export default function OptionWithListAuthorsWrapper({
  isOpen,
  setIsOpen,
  setUnsavedChanges,
  selectedAuthors,
  setSelectedAuthors,
}: Props) {
  const [authors, setAuthors] = useState<AuthorDetailsI[] | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [authorsTotal, setAuthorsTotal] = useState(0);
  const [isLoadingMoreData, setIsLoadingMoreData] = useState(false);
  const [canLoadMoreData, setCanLoadMoreData] = useState(false);
  const dispatch = useDispatch();

  const callApiToGetAuthors = (name: string): void => {
    getAuthors(name || '', PAGE_SIZE, 0)
      .then((res) => {
        setAuthors(res.content);
        setAuthorsTotal(res.totalElements);
        if (!res.last) {
          setCurrentPage(1);
          setCanLoadMoreData(true);
        } else {
          setCurrentPage(0);
          setCanLoadMoreData(false);
        }
      })
      .catch((error: unknown) => {
        let errorStatus;
        if (error instanceof AxiosError) {
          errorStatus = error?.response?.status;
        }
        if (errorStatus !== 401) {
          dispatch(
            openSnackbar({
              type: 'error',
              message: AUTHORS_ERRORS.AUTHORS_LIST,
            }),
          );
        }
      });
  };

  const loadMoreData = async () => {
    if (canLoadMoreData && !isLoadingMoreData && authors !== null) {
      setIsLoadingMoreData(true);
      try {
        const response = await getAuthors(encodeURI('') || '', PAGE_SIZE, currentPage);
        const { last, content } = response;
        if (!last) {
          setCurrentPage(currentPage + 1);
          setCanLoadMoreData(true);
        } else {
          setCanLoadMoreData(false);
        }
        setIsLoadingMoreData(false);
        if (authors != null) {
          setAuthors([...authors, ...content]);
        }
      } catch (error: unknown) {
        let errorStatus;
        if (error instanceof AxiosError) {
          errorStatus = error?.response?.status;
        }
        if (errorStatus !== 401) {
          dispatch(
            openSnackbar({
              type: 'error',
              message: AUTHORS_ERRORS.AUTHORS_LIST,
            }),
          );
        }
      }
    }
  };

  useEffect(() => {
    callApiToGetAuthors('');
  }, []);

  return (
    <OptionWithList
      setIsOpen={setIsOpen}
      isOpen={isOpen}
      title='Autor'
      options={authors}
      withInfiniteScroll={true}
      canLoadMoreData={canLoadMoreData}
      isLoadingMoreData={isLoadingMoreData}
      loadMoreData={loadMoreData}
      setUnsavedChanges={setUnsavedChanges}
      type='author'
      selectedAuthors={selectedAuthors}
      setSelectedAuthors={setSelectedAuthors}
    />
  );
}
