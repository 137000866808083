import getMonthName from './getMonthName';

export const getLastPublicationDateInPreviewPage = (lastPublicationDate: string) => {
  const utcDate = new Date(lastPublicationDate);
  const offset = -5;
  const auxLastPublicationDate = new Date(utcDate.getTime() + offset * 60 * 60 * 1000);
  return `${getMonthName(
    auxLastPublicationDate.getMonth(),
  )} ${auxLastPublicationDate.getDate()}, ${auxLastPublicationDate.getFullYear()}`;
};
