import { createSlice } from '@reduxjs/toolkit';
import type PendingReviewInterface from '../../interfaces/PendingReview';

interface InitialState {
  pendingReviews: PendingReviewInterface[] | null;
  currentPage: number;
  isLoadingMoreData: boolean;
  canLoadMoreData: boolean;
  isDrawerOpen: boolean;
}

const initialState: InitialState = {
  pendingReviews: null,
  currentPage: 0,
  isLoadingMoreData: false,
  canLoadMoreData: false,
  isDrawerOpen: false,
};

const presentationsPendingReviewSlice = createSlice({
  name: 'presentationsPendingReviews',
  initialState,
  reducers: {
    setPresentationsPendingReviewsResetValues: (state, action) => {
      state.pendingReviews = null;
      state.currentPage = 0;
      state.isLoadingMoreData = false;
      state.canLoadMoreData = false;
    },
    setPresentationsPendingReviews: (state, action) => {
      state.pendingReviews = action.payload.pendingReviews;
    },
    setPresentationsPendingReviewsCurrentPage: (state, action) => {
      state.currentPage = action.payload.currentPage;
    },
    setPresentationsPendingReviewsIsLoadingMoreData: (state, action) => {
      state.isLoadingMoreData = action.payload.isLoadingMoreData;
    },
    setPresentationsPendingReviewsCanLoadMoreData: (state, action) => {
      state.canLoadMoreData = action.payload.canLoadMoreData;
    },
    setPresentationsPendingReviewsIsDrawerOpen: (state, action) => {
      state.isDrawerOpen = action.payload.isDrawerOpen;
    },
  },
});

export const {
  setPresentationsPendingReviews,
  setPresentationsPendingReviewsCanLoadMoreData,
  setPresentationsPendingReviewsCurrentPage,
  setPresentationsPendingReviewsIsLoadingMoreData,
  setPresentationsPendingReviewsResetValues,
  setPresentationsPendingReviewsIsDrawerOpen,
} = presentationsPendingReviewSlice.actions;
export default presentationsPendingReviewSlice.reducer;
