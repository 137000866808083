import React from 'react';
import type Icon from '../interfaces/Icon';

export default function IdeaIcon({
  height = 24,
  width = 24,
  color = '#0094CF',
}: Icon): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_142_1982)'>
        <path
          d='M11.999 2.81201C11.6107 2.81201 11.2959 3.12682 11.2959 3.51514C11.2959 3.90345 11.6107 4.21826 11.999 4.21826C14.3256 4.21826 16.2185 6.11079 16.2185 8.43701C16.2185 8.82532 16.5333 9.14014 16.9216 9.14014C17.3099 9.14014 17.6247 8.82532 17.6247 8.43701C17.6247 5.33539 15.1011 2.81201 11.999 2.81201Z'
          fill={color}
        />
        <path
          d='M10.1871 0.188902C7.01041 0.852698 4.43463 3.40382 3.75686 6.60028C3.18456 9.29943 3.8898 12.0093 5.69172 14.035C6.57255 15.0251 7.07767 16.3321 7.07767 17.6245V19.0308C7.07767 19.9664 7.69019 20.7613 8.53516 21.0366C8.81303 22.6784 10.238 23.9995 11.9995 23.9995C13.7606 23.9995 15.186 22.6788 15.4639 21.0366C16.3089 20.7613 16.9214 19.9664 16.9214 19.0307V17.6245C16.9214 16.3296 17.4282 15.0382 18.3484 13.9884C19.6953 12.4519 20.437 10.4803 20.437 8.43703C20.437 3.11367 15.5644 -0.934505 10.1871 0.188902ZM11.9995 22.5933C11.0957 22.5933 10.3032 21.9713 10.0106 21.1355H13.9885C13.6959 21.9713 12.9034 22.5933 11.9995 22.5933ZM15.5152 19.0308C15.5152 19.4185 15.1998 19.7339 14.812 19.7339H9.18705C8.79935 19.7339 8.48392 19.4185 8.48392 19.0308V18.3277H15.5152V19.0308ZM17.2909 13.0616C16.3103 14.1803 15.7069 15.5294 15.5538 16.9214H8.44539C8.29249 15.5287 7.69071 14.1663 6.74252 13.1003C5.24074 11.4121 4.65391 9.1492 5.13255 6.89198C5.69125 4.25695 7.83813 2.1164 10.4748 1.56548C14.9759 0.624839 19.0308 4.00509 19.0308 8.43703C19.0308 10.1392 18.4129 11.7815 17.2909 13.0616Z'
          fill={color}
        />
        <path
          d='M2.10938 8.43701H0.703125C0.314812 8.43701 0 8.75182 0 9.14014C0 9.52845 0.314812 9.84326 0.703125 9.84326H2.10938C2.49769 9.84326 2.8125 9.52845 2.8125 9.14014C2.8125 8.75182 2.49769 8.43701 2.10938 8.43701Z'
          fill={color}
        />
        <path
          d='M2.40072 4.92143L1.40636 3.92708C1.13181 3.65248 0.686594 3.65248 0.412 3.92708C0.137406 4.20167 0.137406 4.64684 0.412 4.92143L1.40636 5.91579C1.68091 6.19039 2.12612 6.19044 2.40072 5.91579C2.67531 5.6412 2.67531 5.19603 2.40072 4.92143Z'
          fill={color}
        />
        <path
          d='M2.40072 12.3646C2.12612 12.09 1.68091 12.09 1.40636 12.3646L0.412 13.3589C0.137406 13.6335 0.137406 14.0787 0.412 14.3533C0.686547 14.6279 1.13177 14.6279 1.40636 14.3533L2.40072 13.3589C2.67531 13.0843 2.67531 12.6392 2.40072 12.3646Z'
          fill={color}
        />
        <path
          d='M23.2969 8.43701H21.8906C21.5023 8.43701 21.1875 8.75182 21.1875 9.14014C21.1875 9.52845 21.5023 9.84326 21.8906 9.84326H23.2969C23.6852 9.84326 24 9.52845 24 9.14014C24 8.75182 23.6852 8.43701 23.2969 8.43701Z'
          fill={color}
        />
        <path
          d='M23.5882 3.92708C23.3137 3.65248 22.8685 3.65248 22.5939 3.92708L21.5995 4.92143C21.3249 5.19603 21.3249 5.6412 21.5995 5.91579C21.874 6.19034 22.3193 6.19039 22.5939 5.91579L23.5882 4.92143C23.8628 4.64684 23.8628 4.20167 23.5882 3.92708Z'
          fill={color}
        />
        <path
          d='M23.5882 13.3589L22.5939 12.3646C22.3193 12.09 21.8741 12.09 21.5995 12.3646C21.3249 12.6392 21.3249 13.0843 21.5995 13.3589L22.5939 14.3533C22.8684 14.6278 23.3136 14.6279 23.5882 14.3533C23.8628 14.0787 23.8628 13.6335 23.5882 13.3589Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_142_1982'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
