/* eslint-disable @typescript-eslint/promise-function-async */
/* eslint-disable n/handle-callback-err */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useSelector, useDispatch } from 'react-redux';
import {
  useNavigate,
  useParams,
  useNavigation,
  unstable_useBlocker,
  useLocation,
} from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { useBeforeUnload } from 'react-use';
import Header from '../components/editor/Header';
import ToolBar from '../components/editor/ToolBar';
import WordCounter from '../components/editor/WordCounter';
import CustomDrawer from '../components/globals/CustomDrawer';
import AudioDrawer from '../components/editor/AudioDrawer';
import VideoDrawer from '../components/editor/VideoDrawer';
import SettingDrawer from '../components/editor/SettingDrawer';
import EditingArea from '../components/editor/EditingArea';
import PreviewMode from '../components/editor/PreviewMode';
import { type RootState } from '../context/store';
import {
  toogleAudioDrawer,
  tooglePreviewMode,
  toogleSettingDrawer,
  toogleVideoDrawer,
} from '../context/reducers/toolBar';
import { getPost, publishPost, returnToDraft, startReviewOfPost } from '../api/posts';
import { openSnackbar } from '../context/reducers/generalSnackbar';
import { POST_ERRORS, UNSAVED_CHANGES } from '../constants/errorMessages';
import type TagDetails from '../interfaces/TagDetails';
import type PostInformation from '../interfaces/PostInformation';
import POSTS_STATUS from '../constants/status';
import {
  setCurrentPostStatus,
  setEmptyElementsContent,
  setPostInformationInEditor,
  setUnsavedChanges,
} from '../context/reducers/editor';
import { type AuthorItem } from '../interfaces/AuthorDetails';
import ConfigurationButton from '../components/editor/ConfigurationButton';
import ConfirmDiscardChanges from '../components/editor/ConfirmDiscardChanges';
import { callPostDetails, savePost, saveSettingsOfPost } from '../utils/editor';
import ConfirmDeleteModal from '../components/entries/ConfirmDeleteModal';
import { deleteEntry } from '../utils/entries';
import ConfirmForceDeleteModal from '../components/entries/ConfirmForceDeleteModal';
import { AxiosError } from 'axios';
import {
  setHomeDraftPostCreated,
  setHomeDraftPostDeleted,
  setHomeDraftPostUpdated,
} from '../context/reducers/homeDrafts';
import {
  setDraftPostAdded,
  setDraftPostUpdated,
  setPendingReviewPostAdded,
  setPendingReviewQuantity,
  setPublishedPostAdded,
} from '../context/reducers/entries';
import type Subscription from '../interfaces/Subscription';
import MobileSettingsDrawer from '../components/editor/MobileSettingsDrawer';
import MobileToolBar from '../components/editor/MobileToolBar';
import AddNewElementDrawer from '../components/editor/AddNewElementDrawer';
import MobileVideoDrawer from '../components/editor/MobileVideoDrawer';
import MobileAudioDrawer from '../components/editor/MobileAudioDrawer';
import EditorNotesModal from '../components/editor/EditorNotesModal';
import { getImagesQuantity } from '../utils/wysiwyg';
import {
  FILE_TYPE,
  HTML_TEXT,
  MAX_AUTHORS_QUANTITY,
  MAX_CATEGORIES_QUANTITY,
  MAX_IMAGES_QUANTITY,
  MAX_TAGS_QUANTITY,
} from '../constants/globals';
import { type EditorState } from 'draft-js';
import ConfirmPublicationModal from '../components/globals/ConfirmPublicationModal';
import ErrorConcurrenceModal from '../components/editor/ErrorConcurrenceModal';
import ArticleHasBeenPublishedModal from '../components/editor/ArticleHasBeenPublishedModal';
import ArticleWasReturnedToDraft from '../components/editor/ArticleWasReturnedToDraft';
import { checkIfIsPresentationPage } from '../utils/checkIfIsPresentationPage';
import PresentationPreviewMode from '../components/editor/PresentationPreviewMode';
import EyeIcon from '../icons/EyeIcon';
import { Button } from '@mui/material';
import { ARTICLE_SUCCESFUL_MESSAGES } from '../constants/successfulMessages';

const getDefaultTags = (postInformation: PostInformation | null | undefined) => {
  const tags = postInformation?.tags;
  const auxTags = tags?.map((item, index) => {
    return {
      id: index,
      tag: item,
    };
  });
  if (auxTags !== undefined) {
    return auxTags;
  }
  return [];
};

const getDefaultCategories = (postInformation: PostInformation | null | undefined) => {
  return postInformation?.categories ?? [];
};

const getDefaultSubscriptions = (postInformation: PostInformation | null | undefined) => {
  return postInformation?.availableFor ?? [];
};

export default function Editor(): JSX.Element {
  const currentSelectedEditorState = useSelector(
    (state: RootState) => state.editor.currentSelectedEditorState,
  );
  const currentPostStatus = useSelector((state: RootState) => state.editor.currentPostStatus);
  const elements = useSelector((state: RootState) => state.editor.elements);
  const elementsContent = useSelector((state: RootState) => state.editor.elementsContent);
  const isPreviewModeActive = useSelector((state: RootState) => state.toolBar.isPreviewModeActive);
  const isAudioDrawerOpen = useSelector((state: RootState) => state.toolBar.isAudioDrawerOpen);
  const isSettingDrawerOpen = useSelector((state: RootState) => state.toolBar.isSettingDrawerOpen);
  const isVideoDrawerOpen = useSelector((state: RootState) => state.toolBar.isVideoDrawerOpen);
  const wantToReloadPostPage = useSelector((state: RootState) => state.editor.wantToReloadPostPage);
  const unsavedChanges = useSelector((state: RootState) => state.editor.unsavedChanges);
  const wantToDownloadPDF = useSelector((state: RootState) => state.editor.wantToDownloadPDF);
  const pendingReviewQuantity = useSelector(
    (state: RootState) => state.entries.pendingReviewQuantity,
  );
  const [readersIDs, setReadersIDs] = useState<any[]>([]);
  const [postInformation, setPostInformation] = useState<PostInformation | null>(null);
  const [currentCategories, setCurrentCategories] = useState<string[]>(
    getDefaultCategories(postInformation),
  );
  const [selectedCategories, setSelectedCategories] = useState<string[]>(
    getDefaultCategories(postInformation),
  );
  const [currentTags, setCurrentTags] = useState<TagDetails[]>(getDefaultTags(postInformation));
  const [selectedTags, setSelectedTags] = useState<TagDetails[]>(getDefaultTags(postInformation));
  const [currentAuthors, setCurrentAuthors] = useState<AuthorItem[]>([]);
  const [selectedAuthors, setSelectedAuthors] = useState<any[]>([]);
  const [currentSubscriptions, setCurrentSubscriptions] = useState<string[]>(
    getDefaultSubscriptions(postInformation),
  );
  const [selectedSubscriptions, setSelectedSubscriptions] = useState<string[]>(
    getDefaultSubscriptions(postInformation),
  );
  const [currentStatus, setCurrentStatus] = useState<null | string>(null);
  const [contentType, setContentType] = useState<null | string>(null);
  const [isDiscardChangesModalVisible, setIsDiscardChangesVisible] = useState<boolean>(false);
  const [confirmDeleteModalIsVisible, setConfirmDeleteModalIsVisible] = useState<boolean>(false);
  const [confirmForceDeleteModalIsVisible, setConfirmForceDeleteModallIsVisible] = useState(false);
  const [isErrorConcurrenceModalVisible, setIsErrorConcurrenceModalVisible] = useState(false);
  const [isArticleHasBeenPublishedModalVisible, setIsArticleHasBeenPublishedModalVisible] =
    useState(false);
  const [isArticleWasReturnedToDraftModalVisible, setIsArticleWasReturnedToDraftModalVisible] =
    useState(false);
  const [savingChanges, setSavingChanges] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [link, setLink] = useState('');
  const [errorDeleteMessage, setErrorDeleteMessage] = useState('');
  const [creating, setCreating] = useState(false);
  const [confirmPublicationModalIsVisible, setConfirmPublicationModalIsVisible] = useState(false);
  const [currentVersion, setCurrentVersion] = useState(0);
  const [confirmSendToReviewModallIsVisible, setConfirmSendToReviewModalIsVisible] =
    useState(false);
  const [isNotificationModalVisible, setIsNotificationModalVisible] = useState(false);
  const navigate = useNavigate();
  const navigation = useNavigation();
  const location = useLocation();
  const { postId } = useParams();
  const dispatch = useDispatch();

  const blocker = unstable_useBlocker(unsavedChanges && !wantToDownloadPDF && postId !== undefined);

  const resetAllValues = () => {
    dispatch(setEmptyElementsContent({}));
    dispatch(tooglePreviewMode({ isPreviewModeActive: false }));
    dispatch(setUnsavedChanges({ unsavedChanges: false }));
    setSavingChanges(false);
    setDeleting(false);
    setPostInformation(null);
    dispatch(
      setCurrentPostStatus({
        currentPostStatus: null,
      }),
    );
    dispatch(
      setPostInformationInEditor({
        postInformation: null,
      }),
    );
  };

  useBeforeUnload(unsavedChanges && !wantToDownloadPDF && postId !== undefined, UNSAVED_CHANGES);

  useEffect(() => {
    if (postId !== undefined) {
      resetAllValues();
      getPost(parseInt(postId))
        .then((res) => {
          setPostInformation(res);
          dispatch(
            setPostInformationInEditor({
              postInformation: res,
            }),
          );
        })
        .catch((error: unknown) => {
          let errorStatus;
          if (error instanceof AxiosError) {
            errorStatus = error?.response?.status;
          }
          if (errorStatus !== 401) {
            dispatch(
              openSnackbar({
                type: 'error',
                message: POST_ERRORS.GET_POST,
              }),
            );
          }
        });
    } else {
      setCurrentStatus(POSTS_STATUS.DRAFT);
      setContentType(checkIfIsPresentationPage(location.pathname) ? FILE_TYPE : HTML_TEXT);
      dispatch(
        setCurrentPostStatus({
          currentPostStatus: POSTS_STATUS.DRAFT,
        }),
      );
    }
  }, [postId, wantToReloadPostPage]);

  useEffect(() => {
    if (postInformation !== null && postInformation !== undefined) {
      setCurrentVersion(postInformation?.version);
      const auxCurrentStatus = postInformation.status;
      setCurrentAuthors(postInformation?.authors ?? []);
      setSelectedAuthors(postInformation?.authors ?? []);
      setContentType(postInformation?.contentType ?? HTML_TEXT);
      if (
        auxCurrentStatus === POSTS_STATUS.PENDING_REVIEW &&
        currentStatus !== POSTS_STATUS.REVIEW_IN_PROGRESS
      ) {
        startReviewOfPost(postInformation.id)
          .then(() => {
            setCurrentStatus(POSTS_STATUS.REVIEW_IN_PROGRESS);
            dispatch(
              setCurrentPostStatus({
                currentPostStatus: POSTS_STATUS.REVIEW_IN_PROGRESS,
              }),
            );
            if (postId !== undefined) {
              callPostDetails(postId, setCurrentVersion);
            }
          })
          .catch(() => {});
      } else {
        if (auxCurrentStatus !== POSTS_STATUS.PENDING_REVIEW) {
          setCurrentStatus(auxCurrentStatus as string);
          dispatch(
            setCurrentPostStatus({
              currentPostStatus: auxCurrentStatus,
            }),
          );
        }
      }
    }
  }, [postInformation]);

  useEffect(() => {
    if (postInformation !== undefined && postInformation !== null) {
      setCurrentTags(getDefaultTags(postInformation));
      setSelectedTags(getDefaultTags(postInformation));
      setCurrentCategories(getDefaultCategories(postInformation));
      setSelectedCategories(getDefaultCategories(postInformation));
      setCurrentSubscriptions(getDefaultSubscriptions(postInformation));
    }
  }, [postInformation]);

  useEffect(() => {
    setIsDiscardChangesVisible(blocker.state === 'blocked');
  }, [blocker]);

  const setIsAudioDrawerOpen = (v: boolean): void => {
    dispatch(
      toogleAudioDrawer({
        isAudioDrawerOpen: v,
      }),
    );
  };

  const setIsVideoDrawerOpen = (v: boolean): void => {
    dispatch(
      toogleVideoDrawer({
        isVideoDrawerOpen: v,
      }),
    );
  };

  const setIsSettingDrawerOpen = (v: boolean): void => {
    dispatch(
      toogleSettingDrawer({
        isSettingDrawerOpen: v,
      }),
    );
  };

  const checkImagesQuantityOfPost = (): boolean => {
    const mainParagraph = elementsContent[3].content as EditorState;
    return getImagesQuantity(mainParagraph) <= MAX_IMAGES_QUANTITY;
  };

  const saveChanges = async () => {
    const isPresentationPage = checkIfIsPresentationPage(location.pathname);
    if (blocker !== undefined && blocker.state === 'blocked') {
      blocker?.reset();
    }
    try {
      if (!checkImagesQuantityOfPost()) {
        dispatch(
          openSnackbar({
            type: 'error',
            message: POST_ERRORS.MAX_IMAGES_QUANTITY,
          }),
        );
        return;
      }
      await savePost(
        setSavingChanges,
        postInformation,
        elementsContent,
        elements,
        dispatch,
        savingChanges,
        navigate,
        setIsErrorConcurrenceModalVisible,
        currentVersion,
        setCurrentVersion,
        setIsArticleHasBeenPublishedModalVisible,
        setIsArticleWasReturnedToDraftModalVisible,
        isPresentationPage,
      );
      setIsDiscardChangesVisible(false);
      dispatch(
        setHomeDraftPostCreated({
          draftPostCreated: true,
        }),
      );
      dispatch(
        setDraftPostAdded({
          draftPostAdded: true,
        }),
      );
      if (postId !== undefined) {
        await callPostDetails(postId, setCurrentVersion);
      }
    } catch (err) {}
  };

  const confirmDeletePost = async (forceDelete: boolean): Promise<void> => {
    const auxContentType = postInformation?.contentType ?? contentType;
    try {
      await deleteEntry(
        setConfirmDeleteModalIsVisible,
        deleting,
        setDeleting,
        parseInt(postId ?? ''),
        dispatch,
        currentStatus as string,
        false,
        false,
        false,
        'editor',
        setConfirmForceDeleteModallIsVisible,
        navigate,
        undefined,
        undefined,
        undefined,
        undefined,
        forceDelete,
        setErrorDeleteMessage,
        auxContentType as string,
      );
      dispatch(
        setHomeDraftPostDeleted({
          draftPostDeleted: true,
        }),
      );
    } catch (err) {}
  };

  const getConfirmDeleteModalMessage = (): string => {
    return currentStatus === POSTS_STATUS.PUBLISHED
      ? '¿Seguro que quieres eliminar definitivamente esta publicación? Se eliminarán sus métricas y desaparecerá de la biblioteca de los lectores sin poder recuperarse.'
      : `¿Seguro que quieres eliminar ${
          contentType === HTML_TEXT ? 'este artículo' : 'este PPT'
        }? Recuerda que esta acción no es reversible. `;
  };

  const resetLink = () => {
    setLink('');
  };

  const checkPostInformation = (): boolean => {
    if (
      selectedCategories?.length > MAX_CATEGORIES_QUANTITY ||
      selectedAuthors?.length > MAX_AUTHORS_QUANTITY ||
      selectedTags?.length > MAX_TAGS_QUANTITY
    ) {
      return false;
    }
    return true;
  };

  const returnToWriter = async () => {
    const isPresentationPage = checkIfIsPresentationPage(location.pathname);
    if (!savingChanges) {
      try {
        setSavingChanges(true);
        if (unsavedChanges) {
          await saveChanges();
        }
        if (!checkPostInformation()) {
          setSavingChanges(false);
          return;
        }
        await returnToDraft(postInformation?.id as number);
        dispatch(
          openSnackbar({
            type: 'success',
            message: !isPresentationPage
              ? ARTICLE_SUCCESFUL_MESSAGES.RETURN_TO_DRAFT_ARTICLE
              : ARTICLE_SUCCESFUL_MESSAGES.RETURN_TO_DRAFT_PRESENTATION,
          }),
        );
        dispatch(
          setHomeDraftPostUpdated({
            draftPostUpdated: true,
          }),
        );
        dispatch(
          setDraftPostUpdated({
            draftPostUpdated: true,
          }),
        );
        if (pendingReviewQuantity !== null && pendingReviewQuantity !== undefined) {
          dispatch(
            setPendingReviewQuantity({
              pendingReviewQuantity: pendingReviewQuantity - 1,
            }),
          );
        }
        navigate(
          `${
            isPresentationPage ? '/presentations' : '/entries'
          }?type=${POSTS_STATUS.DRAFT.toLowerCase()}`,
        );
      } catch (error: unknown) {
        let errorStatus;
        if (error instanceof AxiosError) {
          errorStatus = error?.response?.status;
        }
        if (errorStatus !== 401) {
          setSavingChanges(false);
          dispatch(
            openSnackbar({
              type: 'error',
              message: POST_ERRORS.RETURN_TO_DRAFT,
            }),
          );
        }
      }
    }
  };

  const handlePublishPost = async () => {
    const isPresentationPage = checkIfIsPresentationPage(location.pathname);
    if (!savingChanges) {
      try {
        setSavingChanges(true);
        if (unsavedChanges) {
          await saveChanges();
        }
        if (!checkPostInformation()) {
          setSavingChanges(false);
          return;
        }
        await publishPost(postInformation?.id as number, false);
        dispatch(
          openSnackbar({
            type: 'success',
            message: !isPresentationPage
              ? ARTICLE_SUCCESFUL_MESSAGES.PUBLISH_ARTICLE
              : ARTICLE_SUCCESFUL_MESSAGES.PUBLISH_PRESENTATION,
          }),
        );
        if (pendingReviewQuantity !== null && pendingReviewQuantity !== undefined) {
          dispatch(
            setPendingReviewQuantity({
              pendingReviewQuantity: pendingReviewQuantity - 1,
            }),
          );
        }
        dispatch(
          setPendingReviewPostAdded({
            pendingReviewPostAdded: true,
          }),
        );
        dispatch(
          setPublishedPostAdded({
            publishedPostAdded: true,
          }),
        );
        navigate(
          `${
            !isPresentationPage ? '/entries' : '/presentations'
          }?type=${POSTS_STATUS.PUBLISHED.toLowerCase()}`,
        );
      } catch (error: unknown) {
        setSavingChanges(false);
        let errorMessage = POST_ERRORS.PUBLISH;
        let errorStatus;
        if (error instanceof AxiosError) {
          const errorData = error?.response?.data;
          errorStatus = error?.response?.status;
          if (errorData?.message) {
            errorMessage = errorData?.message;
          }
        }
        if (errorStatus === 428) {
          setConfirmPublicationModalIsVisible(true);
        } else if (errorStatus !== 401) {
          dispatch(
            openSnackbar({
              type: 'error',
              message: errorMessage,
            }),
          );
        }
      }
    }
  };

  const callApiToPublishAPost = async (overwritePriorPublication: boolean) => {
    const isPresentationPage = checkIfIsPresentationPage(location.pathname);
    if (!savingChanges) {
      try {
        setSavingChanges(true);
        await publishPost(postInformation?.id as number, overwritePriorPublication);
        dispatch(
          openSnackbar({
            type: 'success',
            message: !isPresentationPage
              ? ARTICLE_SUCCESFUL_MESSAGES.PUBLISH_ARTICLE
              : ARTICLE_SUCCESFUL_MESSAGES.PUBLISH_PRESENTATION,
          }),
        );
        navigate(
          `${
            isPresentationPage ? '/presentations' : '/entries'
          }?type=${POSTS_STATUS.PUBLISHED.toLowerCase()}`,
        );
      } catch (error: unknown) {
        setSavingChanges(false);
        let errorMessage = POST_ERRORS.PUBLISH;
        let errorStatus;
        if (error instanceof AxiosError) {
          const errorData = error?.response?.data;
          errorStatus = error?.response?.status;
          if (errorData?.message) {
            errorMessage = errorData?.message;
          }
        }
        if (errorStatus === 428) {
          setConfirmPublicationModalIsVisible(true);
        } else if (errorStatus !== 401) {
          dispatch(
            openSnackbar({
              type: 'error',
              message: errorMessage,
            }),
          );
          setConfirmPublicationModalIsVisible(false);
        }
      }
    }
  };

  const blockerHandleCancel = () => {
    if (blocker !== undefined && blocker.state === 'blocked') {
      blocker?.reset();
    }
  };

  const blockerHandleDiscardChanges = () => {
    if (blocker !== undefined && blocker.state === 'blocked') {
      blocker?.proceed();
    }
  };

  const handleClickPreviewMode = () => {
    dispatch(
      tooglePreviewMode({
        isPreviewModeActive: !isPreviewModeActive,
      }),
    );
  };

  if (postInformation == null && postId !== undefined) {
    return (
      <CircularProgressContainer>
        <CircularProgress />
      </CircularProgressContainer>
    );
  }

  return (
    <>
      <Container
        initial={{ opacity: 0, x: 30 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.2 }}
        exit={{ opacity: 0, x: 30 }}
        id='editor-page'
      >
        <Header
          postInformation={postInformation}
          currentStatus={currentStatus}
          isDiscardChangesModalVisible={isDiscardChangesModalVisible}
          setIsDiscardChangesVisible={setIsDiscardChangesVisible}
          currentAuthors={currentAuthors}
          setCurrentAuthors={setCurrentAuthors}
          currentCategories={currentCategories}
          setCurrentCategories={setCurrentCategories}
          currentTags={currentTags}
          postId={postId}
          selectedAuthors={selectedAuthors}
          selectedCategories={selectedCategories}
          selectedTags={selectedTags}
          setCurrentTags={setCurrentTags}
          setSelectedAuthors={setSelectedAuthors}
          setSelectedCategories={setSelectedCategories}
          setSelectedTags={setSelectedTags}
          currentSubscriptions={currentSubscriptions}
          setCurrentSubscriptions={setCurrentSubscriptions}
          selectedSubscriptions={selectedSubscriptions}
          setSelectedSubscriptions={setSelectedSubscriptions}
          creating={savingChanges}
          setCreating={setSavingChanges}
          isErrorConcurrenceModalVisible={isErrorConcurrenceModalVisible}
          setIsErrorConcurrenceModalVisible={setIsErrorConcurrenceModalVisible}
          setIsArticleHasBeenPublishedModalVisible={setIsArticleHasBeenPublishedModalVisible}
          setIsArticleWasReturnedToDraftModalVisible={setIsArticleWasReturnedToDraftModalVisible}
          currentVersion={currentVersion}
          setCurrentVersion={setCurrentVersion}
          confirmSendToReviewModallIsVisible={confirmSendToReviewModallIsVisible}
          setConfirmSendToReviewModalIsVisible={setConfirmSendToReviewModalIsVisible}
          isPresentationPage={checkIfIsPresentationPage(location.pathname)}
          isNotificationModalVisible={isNotificationModalVisible}
          setIsNotificationModalVisible={setIsNotificationModalVisible}
        />
        {!checkIfIsPresentationPage(location.pathname) ? (
          <PreviewMode
            postInformation={postInformation}
            selectedTags={selectedTags}
            selectedAuthors={selectedAuthors}
            selectedCategories={selectedCategories}
            currentStatus={currentStatus}
          />
        ) : (
          <PresentationPreviewMode
            postInformation={postInformation}
            currentStatus={currentStatus}
          />
        )}
        {!isPreviewModeActive && !checkIfIsPresentationPage(location.pathname) && <ToolBar />}
        {(currentPostStatus === POSTS_STATUS.PENDING_REVIEW ||
          currentPostStatus === POSTS_STATUS.REVIEW_IN_PROGRESS) &&
          !isPreviewModeActive &&
          checkIfIsPresentationPage(location.pathname) && (
            <CustomButtonContainer>
              <CustomButton
                className='px-3'
                startIcon={<EyeIcon width={20} height={12} color={'#033BCF'} />}
                onClick={handleClickPreviewMode}
              >
                <ButtonText>Vista previa de artículo</ButtonText>
              </CustomButton>
            </CustomButtonContainer>
          )}
        <EditingArea
          postInformation={postInformation}
          currentStatus={currentStatus}
          contentType={contentType}
        />
        {!isPreviewModeActive && !checkIfIsPresentationPage(location.pathname) && <WordCounter />}
        {!isPreviewModeActive && !checkIfIsPresentationPage(location.pathname) && <MobileToolBar />}
        {!isPreviewModeActive && currentStatus === POSTS_STATUS.REVIEW_IN_PROGRESS && (
          <ConfigurationButton />
        )}
      </Container>
      <ConfirmDiscardChanges
        isVisible={isDiscardChangesModalVisible}
        setIsVisible={setIsDiscardChangesVisible}
        savingChanges={savingChanges}
        saveChanges={saveChanges}
        blockerHandleCancel={blockerHandleCancel}
        blockerHandleDiscardChanges={blockerHandleDiscardChanges}
        contentType={contentType}
      />
      <ConfirmDeleteModal
        title={
          contentType === HTML_TEXT
            ? '¿Eliminar artículo definitivamente?'
            : '¿Eliminar PPT definitivamente?'
        }
        message={getConfirmDeleteModalMessage()}
        isVisible={confirmDeleteModalIsVisible}
        setIsVisible={setConfirmDeleteModalIsVisible}
        deleting={deleting}
        setDeleting={setDeleting}
        confirmDeletePost={() => confirmDeletePost(false)}
      />
      <ConfirmForceDeleteModal
        isVisible={confirmForceDeleteModalIsVisible}
        setIsVisible={setConfirmForceDeleteModallIsVisible}
        message={errorDeleteMessage}
        deleting={deleting}
        setDeleting={setDeleting}
        confirmDeletePost={() => confirmDeletePost(true)}
      />
      <ErrorConcurrenceModal
        isVisible={isErrorConcurrenceModalVisible}
        setIsVisible={setIsErrorConcurrenceModalVisible}
      />
      <ArticleHasBeenPublishedModal
        isVisible={isArticleHasBeenPublishedModalVisible}
        setIsVisible={setIsArticleHasBeenPublishedModalVisible}
      />
      <ArticleWasReturnedToDraft
        isVisible={isArticleWasReturnedToDraftModalVisible}
        setIsVisible={setIsArticleWasReturnedToDraftModalVisible}
      />
      <CustomDrawer isOpen={isAudioDrawerOpen} setIsOpen={setIsAudioDrawerOpen}>
        <AudioDrawer setIsOpen={setIsAudioDrawerOpen} />
      </CustomDrawer>
      <CustomDrawer
        isOpen={isVideoDrawerOpen}
        setIsOpen={setIsVideoDrawerOpen}
        resetValues={resetLink}
      >
        <VideoDrawer
          isOpen={isVideoDrawerOpen}
          setIsOpen={setIsVideoDrawerOpen}
          link={link}
          setLink={setLink}
        />
      </CustomDrawer>
      <CustomDrawer isOpen={isSettingDrawerOpen} setIsOpen={setIsSettingDrawerOpen}>
        <SettingDrawer
          postInformation={postInformation}
          setIsOpen={setIsSettingDrawerOpen}
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
          selectedAuthors={selectedAuthors}
          setSelectedAuthors={setSelectedAuthors}
          readersIDs={readersIDs}
          setReadersIDs={setReadersIDs}
          currentTags={currentTags}
          setCurrentTags={setCurrentTags}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
          currentSubscriptions={currentSubscriptions}
          setCurrentSubscriptions={setCurrentSubscriptions}
          selectedSubscriptions={selectedSubscriptions}
          setSelectedSubscriptions={setSelectedSubscriptions}
          postId={postId}
          currentAuthors={currentAuthors}
          setCurrentAuthors={setCurrentAuthors}
          currentCategories={currentCategories}
          setCurrentCategories={setCurrentCategories}
          currentStatus={currentStatus}
          setConfirmDeleteModalIsVisible={setConfirmDeleteModalIsVisible}
          setIsErrorConcurrenceModalVisible={setIsErrorConcurrenceModalVisible}
          setIsArticleHasBeenPublishedModalVisible={setIsArticleHasBeenPublishedModalVisible}
          setIsArticleWasReturnedToDraftModalVisible={setIsArticleWasReturnedToDraftModalVisible}
          currentVersion={currentVersion}
          setCurrentVersion={setCurrentVersion}
          isPresentationPage={checkIfIsPresentationPage(location.pathname)}
          isNotificationModalVisible={isNotificationModalVisible}
          setIsNotificationModalVisible={setIsNotificationModalVisible}
        />
      </CustomDrawer>
      <MobileSettingsDrawer
        postInformation={postInformation}
        currentStatus={currentStatus}
        setIsSettingDrawerOpen={setIsSettingDrawerOpen}
        saveChanges={saveChanges}
        returnToDraft={returnToWriter}
        handlePublishPost={handlePublishPost}
        confirmSendToReviewModallIsVisible={confirmSendToReviewModallIsVisible}
        setConfirmSendToReviewModalIsVisible={setConfirmSendToReviewModalIsVisible}
        setConfirmDeleteModalIsVisible={setConfirmDeleteModalIsVisible}
        confirmDeleteModalIsVisible={confirmDeleteModalIsVisible}
      />
      <AddNewElementDrawer currentStatus={currentStatus} />
      <MobileVideoDrawer link={link} setLink={setLink} />
      <MobileAudioDrawer />
      <ConfirmPublicationModal
        isVisible={confirmPublicationModalIsVisible}
        setIsVisible={setConfirmPublicationModalIsVisible}
        publishing={savingChanges}
        setPublishing={setSavingChanges}
        callApiToPublishAPost={callApiToPublishAPost}
        contentType={contentType}
      />
    </>
  );
}

const CircularProgressContainer = styled.div`
  width: 100vw;
  margin-top: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 72px);
`;

const Container = styled(motion.div)`
  position: relative;
  width: 100vw;
  margin-top: 72px;

  @media screen and (max-width: 860px) {
    padding-bottom: 100px;
  }
`;

const CustomButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid ${(props) => props.theme.colors.lightGray};
  border-bottom: 1px solid ${(props) => props.theme.colors.lightGray};
  height: 50px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const CustomButton = styled(Button)`
  margin-right: 25px;
`;

const ButtonText = styled.p`
  text-transform: none;
  font-size: 14px;
  font-family: ${(props) => props.theme.fonts.semiBold};
  white-space: nowrap;
  color: ${(props) => props.theme.colors.accentBlue};
`;
