import React, { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import classNames from 'classnames';
import { styled } from '@mui/material';
import { formatLessThan10 } from '../../utils/formatLessThan10';
import { useDispatch, useSelector } from 'react-redux';
import { setProcessedQuantity, setScheduledQuantity } from '../../context/reducers/campaigns';
import { type RootState } from '../../context/store';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CAMPAIGNS_STATUS } from '../../constants/status';
import { getCampaigns } from '../../api/campaings';

export default function TopTabs() {
  const scheduledQuantity = useSelector((state: RootState) => state.campaigns.scheduledQuantity);
  const processedQuantity = useSelector((state: RootState) => state.campaigns.processedQuantity);
  const [selectedStatus, setSelectedStatus] = useState(CAMPAIGNS_STATUS.SCHEDULED);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const queryParamType = searchParams.get('type')?.toUpperCase();
    if (
      queryParamType === CAMPAIGNS_STATUS.SCHEDULED ||
      queryParamType === CAMPAIGNS_STATUS.PROCESSED
    ) {
      setSelectedStatus(queryParamType ?? CAMPAIGNS_STATUS.SCHEDULED);
    } else {
      navigate('/404');
    }
  }, [searchParams]);

  const callApiToGetCampaigns = (statuses: string[]) => {
    getCampaigns('', statuses, 1, 0, 'scheduledAt,ASC', false)
      .then((res) => {
        if (statuses.includes(CAMPAIGNS_STATUS.SCHEDULED)) {
          dispatch(
            setScheduledQuantity({
              scheduledQuantity: res.totalElements,
            }),
          );
        } else if (statuses.includes(CAMPAIGNS_STATUS.PROCESSED)) {
          dispatch(
            setProcessedQuantity({
              processedQuantity: res.totalElements,
            }),
          );
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (scheduledQuantity === undefined) {
      callApiToGetCampaigns([
        CAMPAIGNS_STATUS.SCHEDULED,
        CAMPAIGNS_STATUS.IN_PROGRESS,
        CAMPAIGNS_STATUS.ERROR,
      ]);
    }
  }, []);

  useEffect(() => {
    if (processedQuantity === undefined) {
      callApiToGetCampaigns([CAMPAIGNS_STATUS.PROCESSED]);
    }
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: string): void => {
    navigate({
      pathname: '/campaigns',
      search: `?type=${newValue}`,
    });
    setSelectedStatus(newValue);
  };

  return (
    <Tabs value={selectedStatus} onChange={handleChange} className={classNames('mt-9')}>
      <Tab
        label={`Programadas ${
          scheduledQuantity === undefined ? '' : `(${formatLessThan10(scheduledQuantity)})`
        }`}
        value={CAMPAIGNS_STATUS.SCHEDULED}
        className={classNames('font-semiBold normal-case text-base px-10', {
          'text-primary': selectedStatus === CAMPAIGNS_STATUS.SCHEDULED,
        })}
      />
      <Tab
        label={`Completadas ${
          processedQuantity === undefined ? '' : `(${formatLessThan10(processedQuantity)})`
        }`}
        value={CAMPAIGNS_STATUS.PROCESSED}
        className={classNames('font-semiBold normal-case text-base px-10', {
          'text-primary': selectedStatus === CAMPAIGNS_STATUS.PROCESSED,
        })}
      />
    </Tabs>
  );
}
