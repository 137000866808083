import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CircularProgress } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import HeaderButtonWithText from '../components/globals/HeaderButtonWithText';
import SearchBarFilter from '../components/globals/SearchBarFilter';
import AuthorItem from '../components/authors/AuthorItem';
import type AuthorDetailsI from '../interfaces/AuthorDetails';
import AuthorSkeleton from '../components/authors/AuthorSkeleton';
import { getAuthors } from '../api/authors';
import PenIcon from '../icons/PenIcon';
import { type RootState } from '../context/store';
import { openSnackbar } from '../context/reducers/generalSnackbar';
import { PAGE_SIZE } from '../constants/pagination';
import { AUTHORS_ERRORS } from '../constants/errorMessages';
import { AxiosError } from 'axios';
import {
  setAuthorDeleted,
  setAuthorUpdated,
  setAuthors,
  setAuthorsCanLoadMoreData,
  setAuthorsCurrentPage,
  setAuthorsIsLoadingMoreData,
  setAuthorsResetValues,
  setAuthorsTotal,
  setNewAuthorAdded,
} from '../context/reducers/authors';
import MobileNewPublicationButton from '../components/globals/MobileNewPublicationButton';

export default function Authors(): JSX.Element {
  const newAuthorAdded = useSelector((state: RootState) => state.authors.newAuthorAdded);
  const authorUpdated = useSelector((state: RootState) => state.authors.authorUpdated);
  const authors = useSelector((state: RootState) => state.authors.authors);
  const currentPage = useSelector((state: RootState) => state.authors.currentPage);
  const authorsTotal = useSelector((state: RootState) => state.authors.authorsTotal);
  const isLoadingMoreData = useSelector((state: RootState) => state.authors.isLoadingMoreData);
  const canLoadMoreData = useSelector((state: RootState) => state.authors.canLoadMoreData);
  const [searchInput, setSearchInput] = useState('');
  const dispatch = useDispatch();

  const loadMoreData = async () => {
    if (canLoadMoreData && !isLoadingMoreData && authors !== null) {
      dispatch(
        setAuthorsIsLoadingMoreData({
          isLoadingMoreData: true,
        }),
      );

      try {
        const response = await getAuthors(encodeURI(searchInput) || '', PAGE_SIZE, currentPage);
        const { last, content } = response;
        if (!last) {
          dispatch(
            setAuthorsCurrentPage({
              currentPage: currentPage + 1,
            }),
          );
          dispatch(
            setAuthorsCanLoadMoreData({
              canLoadMoreData: true,
            }),
          );
        } else {
          dispatch(
            setAuthorsCanLoadMoreData({
              canLoadMoreData: false,
            }),
          );
        }
        dispatch(
          setAuthorsIsLoadingMoreData({
            isLoadingMoreData: false,
          }),
        );
        if (authors != null) {
          dispatch(
            setAuthors({
              authors: [...authors, ...content],
            }),
          );
        }
      } catch (error: unknown) {
        let errorStatus;
        if (error instanceof AxiosError) {
          errorStatus = error?.response?.status;
        }
        if (errorStatus !== 401) {
          dispatch(
            openSnackbar({
              type: 'error',
              message: AUTHORS_ERRORS.AUTHORS_LIST,
            }),
          );
        }
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
      const margin = 400;
      if (scrollTop + clientHeight + margin >= scrollHeight) {
        loadMoreData();
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [currentPage, canLoadMoreData, isLoadingMoreData, searchInput]);

  const callApiToGetAuthors = (name: string, cleanSearch = false): void => {
    const newCurrentPage = currentPage > 0 ? (canLoadMoreData ? currentPage : currentPage + 1) : 0;
    if (newAuthorAdded || authorUpdated || name?.length > 0 || cleanSearch) {
      getAuthors(encodeURI(name) || '', PAGE_SIZE, 0)
        .then((res) => {
          dispatch(
            setAuthors({
              authors: res.content,
            }),
          );
          dispatch(
            setAuthorsTotal({
              authorsTotal: res.totalElements,
            }),
          );
          if (!res.last) {
            dispatch(
              setAuthorsCurrentPage({
                currentPage: 1,
              }),
            );
            dispatch(
              setAuthorsCanLoadMoreData({
                canLoadMoreData: true,
              }),
            );
          } else {
            dispatch(
              setAuthorsCurrentPage({
                currentPage: 0,
              }),
            );
            dispatch(
              setAuthorsCanLoadMoreData({
                canLoadMoreData: false,
              }),
            );
          }
          dispatch(
            setNewAuthorAdded({
              newAuthorAdded: false,
            }),
          );
          dispatch(
            setAuthorUpdated({
              authorUpdated: false,
            }),
          );
        })
        .catch((error: unknown) => {
          let errorStatus;
          if (error instanceof AxiosError) {
            errorStatus = error?.response?.status;
          }
          if (errorStatus !== 401) {
            dispatch(
              openSnackbar({
                type: 'error',
                message: AUTHORS_ERRORS.AUTHORS_LIST,
              }),
            );
          }
        });
    } else {
      getAuthors(encodeURI(name) || '', PAGE_SIZE, newCurrentPage)
        .then((res) => {
          if (authors == null || newCurrentPage === 0) {
            dispatch(
              setAuthors({
                authors: res.content,
              }),
            );
          } else {
            dispatch(
              setAuthors({
                authors: [...authors, ...res.content],
              }),
            );
          }
          dispatch(
            setAuthorsTotal({
              authorsTotal: res.totalElements,
            }),
          );
          if (!res.last) {
            dispatch(
              setAuthorsCurrentPage({
                currentPage: newCurrentPage + 1,
              }),
            );
            dispatch(
              setAuthorsCanLoadMoreData({
                canLoadMoreData: true,
              }),
            );
          } else {
            dispatch(
              setAuthorsCanLoadMoreData({
                canLoadMoreData: false,
              }),
            );
          }
        })
        .catch((error: unknown) => {
          let errorStatus;
          if (error instanceof AxiosError) {
            errorStatus = error?.response?.status;
          }
          if (errorStatus !== 401) {
            dispatch(
              openSnackbar({
                type: 'error',
                message: AUTHORS_ERRORS.AUTHORS_LIST,
              }),
            );
          }
        });
    }
  };

  const debounceSearch = debounce((name: string) => {
    if (name.length >= 2) {
      callApiToGetAuthors(name);
    } else {
      callApiToGetAuthors('', true);
    }
  }, 500);

  useEffect(() => {
    return () => {
      debounceSearch.cancel();
    };
  }, []);

  useEffect(() => {
    callApiToGetAuthors('');
    if (searchInput?.length >= 2) {
      dispatch(setAuthorsResetValues({}));
    }
  }, []);

  const handleInputChange = (value: string): void => {
    setSearchInput(value);
    debounceSearch(value);
  };

  return (
    <Container>
      <HeaderButtonWithText
        title='Autores'
        type='author'
        buttonText='Nuevo autor'
        handleInputChange={handleInputChange}
        searchInput={searchInput}
      />
      {authors == null ? (
        <List style={{ marginTop: '35px' }}>
          <AuthorSkeleton />
          <AuthorSkeleton />
          <AuthorSkeleton />
          <AuthorSkeleton />
          <AuthorSkeleton />
          <AuthorSkeleton />
        </List>
      ) : (
        <>
          <SearchBarFilter searchInput={searchInput} handleInputChange={handleInputChange} />
          {authors?.length === 0 ? (
            <MessageContainer>
              <PenIcon color='#033BCF' />
              <Message>
                {searchInput !== ''
                  ? 'No encontramos autores relacionados con tu búsqueda.'
                  : 'Aún no se han registrado autores. Haz click en el botón de Nuevo Autor.'}
              </Message>
            </MessageContainer>
          ) : (
            <>
              <ListHeaderContainer>
                <ListHeader>Nombre</ListHeader>
              </ListHeaderContainer>
              <List>
                {authors?.map((author, index) => (
                  <AuthorItem
                    key={`${author.id}_${index}`}
                    {...author}
                    /*   authors={authors}
                    setAuthors={setAuthors} */
                  />
                ))}
                {canLoadMoreData && (
                  <>
                    <AuthorSkeleton />
                    <AuthorSkeleton />
                    <AuthorSkeleton />
                    <AuthorSkeleton />
                    <AuthorSkeleton />
                  </>
                )}
              </List>
            </>
          )}
        </>
      )}
      <MobileNewPublicationButton
        type='author'
        handleInputChange={handleInputChange}
        searchInput={searchInput}
      />
    </Container>
  );
}

const Container = styled.section`
  padding-left: 24px;
  padding-right: 24px;
  margin-left: 192px;
  margin-top: 72px;
  width: calc(100vw - 192px);

  @media screen and (max-width: 860px) {
    margin-left: 0px;
    width: 100vw;
    margin-top: 70px;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const ListHeaderContainer = styled.header`
  padding-right: 30px;
  padding-left: 30px;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const ListHeader = styled.p`
  font-family: ${(props) => props.theme.fonts.semiBold};
  color: ${(props) => props.theme.colors.primary};
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  justify-content: center;
  flex: 1;
  height: calc(100vh - 500px);
`;

const Message = styled.p`
  font-family: ${(props) => props.theme.fonts.medium};
  color: ${(props) => props.theme.colors.black};
  font-size: 16px;
  text-align: center;
  max-width: 300px;
`;

const List = styled.ul`
  padding-bottom: 80px;
`;
