export const getFileFormat = (file: File): string | undefined | null => {
  if (file) {
    const fileName = file.name;
    const fileFormat = fileName.split('.').pop();
    return fileFormat;
  }
  return null;
};

export const isAudioFileFormatValid = (file: File): boolean => {
  const allowedFormats = ['mp3', 'wav', 'ogg', 'flac'];
  const fileFormat = getFileFormat(file);
  if (fileFormat !== null && fileFormat !== undefined) {
    return allowedFormats.includes(fileFormat);
  }
  return false;
};

export const isPDFfileFormatValid = (file: File): boolean => {
  const allowedFormats = ['pdf'];
  const fileFormat = getFileFormat(file);
  if (fileFormat !== null && fileFormat !== undefined) {
    return allowedFormats.includes(fileFormat);
  }
  return false;
};
