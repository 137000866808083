const POSTS_STATUS = {
  DRAFT: 'DRAFT',
  PENDING_REVIEW: 'PENDING_REVIEW',
  REVIEW_IN_PROGRESS: 'REVIEW_IN_PROGRESS',
  PUBLISHED: 'PUBLISHED',
};

export const STATUS_NAMES = [
  {
    status: POSTS_STATUS.DRAFT,
    name: 'Borrador',
    bgColor: '#FFEFC2',
    color: '#DC9D43',
  },
  {
    status: POSTS_STATUS.PENDING_REVIEW,
    name: 'Pendiente de revisión',
    bgColor: '#FDDBB4',
    color: '#C2813E',
  },
  {
    status: POSTS_STATUS.REVIEW_IN_PROGRESS,
    name: 'En revisión',
    bgColor: '#FDDBB4',
    color: '#C2813E',
  },
  {
    status: POSTS_STATUS.PUBLISHED,
    name: 'Publicado',
    bgColor: '#D0F1EB',
    color: '#61A597',
  },
];

export const CAMPAIGNS_STATUS = {
  SCHEDULED: 'SCHEDULED',
  IN_PROGRESS: 'IN_PROGRESS',
  PROCESSED: 'PROCESSED',
  ERROR: 'ERROR',
};

export default POSTS_STATUS;
