import React from 'react';
import type IconInterface from '../interfaces/Icon';

export default function PublicationsIcon({ color = '#F8F8F8' }: IconInterface): JSX.Element {
  return (
    <svg width='18' height='21' viewBox='0 0 18 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M17 20.5H1C0.44772 20.5 0 20.0523 0 19.5V1.5C0 0.94772 0.44772 0.5 1 0.5H17C17.5523 0.5 18 0.94772 18 1.5V19.5C18 20.0523 17.5523 20.5 17 20.5ZM16 18.5V2.5H2V18.5H16ZM4 4.5H8V8.5H4V4.5ZM4 10.5H14V12.5H4V10.5ZM4 14.5H14V16.5H4V14.5ZM10 5.5H14V7.5H10V5.5Z'
        fill={color}
      />
    </svg>
  );
}
