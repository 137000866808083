import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Button, CircularProgress, TextareaAutosize } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { MultiSectionDigitalClock } from '@mui/x-date-pickers/MultiSectionDigitalClock';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import ModalContainer from '../globals/ModalContainer';
import POSTS_STATUS, { CAMPAIGNS_STATUS } from '../../constants/status';
import { formatLessThan10 } from '../../utils/formatLessThan10';
import dayjs, { type Dayjs } from 'dayjs';
import Calendar from '../../icons/Calendar';
import { BiChevronDown } from 'react-icons/bi';
import { es } from 'date-fns/locale';
import { BalanceSharp } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../../context/reducers/generalSnackbar';
import { CAMPAIGNS_ERRORS } from '../../constants/errorMessages';
import { createCampaign } from '../../api/campaings';
import { CAMPAINGS_SUCCESFUL_MESSAGES } from '../../constants/successfulMessages';
import { differenceInMinutes } from 'date-fns';
import { setScheduledAdded } from '../../context/reducers/campaigns';
import { FILE_TYPE, HTML_TEXT } from '../../constants/globals';
import { getPost } from '../../api/posts';

interface Props {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isPresentationPage: boolean;
  thumbnailUrl: string;
  postId: number;
  publicationId: number | null;
  page?: string;
  contentType?: 'HTML_TEXT' | 'FILE';
  title: string;
}

interface CustomButtonI {
  withBackground: boolean;
  borderColor?: string;
  customType?: string;
}

interface CustomButtonTextI {
  white?: boolean;
}

interface InputContainerI {
  focused: boolean;
  error: boolean;
}

interface TextAreaI {
  placeholderColor?: string;
}

const TITLE_MAX_QUANTITY_OF_CHARACTERS = 100;
const BODY_MAX_QUANTITY_OF_CHARACTERS = 160;

export default function CreateNotificationModal({
  isVisible,
  setIsVisible,
  isPresentationPage,
  thumbnailUrl,
  postId,
  publicationId,
  page = 'post-details',
  contentType,
  title,
}: Props): JSX.Element {
  const [creating, setCreating] = useState(false);
  const [details, setDetails] = useState({
    title: '',
    body: '',
  });
  const [titleIsFocused, setTitleIsFocused] = useState(false);
  const [textAreaIsFocused, setTextAreaIsFocused] = useState(false);
  const [scheduledAt, setScheduledAt] = useState<Date | null>(null);
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs(new Date()));
  const [selectedHours, setSelectedHours] = useState<Dayjs | null>(dayjs(new Date()));
  const [showDateSelector, setShowDateSelector] = useState(false);
  const [formError, setFormError] = useState({
    scheduledAt: false,
    title: false,
    body: false,
  });
  const [finalPublicationId, setFinalPublicationId] = useState(publicationId);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getPublicationId = () => {
    getPost(postId)
      .then((res) => {
        setFinalPublicationId(res?.postPublicationId ?? null);
      })
      .catch((err) => {
        console.log('Err: ', err);
      });
  };

  useEffect(() => {
    if (isVisible && finalPublicationId === null && page === 'post-details') {
      getPublicationId();
    }
  }, [isVisible]);

  const handleCancel = () => {
    setIsVisible(false);
  };

  const resetValues = () => {
    setDetails({
      title: '',
      body: '',
    });
    setTitleIsFocused(false);
    setTextAreaIsFocused(false);
    setScheduledAt(null);
    setFormError({
      scheduledAt: false,
      title: false,
      body: false,
    });
  };

  const continueWithoutNotification = () => {
    if (page === 'entries') {
      setIsVisible(false);
      resetValues();
    } else {
      navigate(
        `${
          isPresentationPage ? '/presentations' : '/entries'
        }?type=${POSTS_STATUS.PUBLISHED.toLowerCase()}`,
      );
    }
  };

  const handleChange = (type: string, newValue: string) => {
    setDetails({ ...details, [type]: newValue });
  };

  const confirmDate = () => {
    setShowDateSelector(false);
    const auxSelectedDate = dayjs(selectedDate);
    const auxSelectedHours = dayjs(selectedHours);
    const year = auxSelectedDate.year();
    const month = auxSelectedDate.month();
    const date = auxSelectedDate.date();
    const hours = auxSelectedHours.hour();
    const minutes = auxSelectedHours.minute();
    setScheduledAt(new Date(year, month, date, hours, minutes));
    setFormError({ ...formError, scheduledAt: false });
  };

  const getScheduledAt = () => {
    if (!scheduledAt) {
      return 'Selecciona Fecha y Hora de notificación';
    }
    return `${scheduledAt.getDate()}/${formatLessThan10(
      scheduledAt.getMonth() + 1,
    )}/${scheduledAt.getFullYear()} ${
      scheduledAt.getHours() === 0 ? '00' : formatLessThan10(scheduledAt.getHours())
    }:${scheduledAt.getMinutes() === 0 ? '00' : formatLessThan10(scheduledAt.getMinutes())}`;
  };

  const handleCreateCampaign = async () => {
    const errors = {
      scheduledAt: false,
      title: false,
      body: false,
    };
    if (!scheduledAt) {
      errors.scheduledAt = true;
      dispatch(
        openSnackbar({
          type: 'error',
          message: CAMPAIGNS_ERRORS.EMPTY_SCHEDULED_AT,
        }),
      );
    } else if (!details.title) {
      errors.title = true;
      dispatch(
        openSnackbar({
          type: 'error',
          message: CAMPAIGNS_ERRORS.EMPTY_TITLE,
        }),
      );
    } else if (!details.body) {
      errors.body = true;
      dispatch(
        openSnackbar({
          type: 'error',
          message: CAMPAIGNS_ERRORS.EMPTY_BODY,
        }),
      );
    } else if (differenceInMinutes(scheduledAt, new Date()) <= 0) {
      errors.scheduledAt = true;
      dispatch(
        openSnackbar({
          type: 'error',
          message: CAMPAIGNS_ERRORS.OLD_SCHEDULED_AT,
        }),
      );
    } else {
      if (!creating) {
        try {
          setCreating(true);
          await createCampaign(
            details?.title,
            details?.body,
            scheduledAt,
            thumbnailUrl,
            `${finalPublicationId}`,
            contentType,
            title,
          );
          dispatch(
            openSnackbar({
              type: 'success',
              message: CAMPAINGS_SUCCESFUL_MESSAGES.CREATE_CAMPAIGN,
            }),
          );
          dispatch(
            setScheduledAdded({
              scheduledAdded: true,
            }),
          );
          navigate(`/campaigns?type=${CAMPAIGNS_STATUS.SCHEDULED}`);
        } catch (err) {
          dispatch(
            openSnackbar({
              type: 'error',
              message: CAMPAIGNS_ERRORS.CREATE_CAMPAING,
            }),
          );
        } finally {
          setCreating(false);
        }
      }
    }
    setFormError(errors);
  };

  return (
    <ModalContainer isVisible={isVisible} setIsVisible={setIsVisible} enabled={page === 'entries'}>
      <Container>
        <Title>
          {page === 'entries'
            ? 'Programar campaña'
            : '¡El artículo ha sido publicado exitosamente!'}
        </Title>
        {finalPublicationId === null ? (
          <CircularProgressContainer>
            <CircularProgress />
          </CircularProgressContainer>
        ) : (
          <Content>
            <Message>
              Programa una notificación para avisarle a los lectores o continúa sin notificar:
            </Message>
            <Label>Programar notificación</Label>
            <DateSelector
              focused={showDateSelector}
              error={formError.scheduledAt}
              onClick={() => {
                if (!creating) {
                  setShowDateSelector(!showDateSelector);
                }
              }}
            >
              <DateSelectorText>{getScheduledAt()}</DateSelectorText>
              {showDateSelector ? <Calendar /> : <BiChevronDown />}
            </DateSelector>
            {showDateSelector && (
              <>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateCalendarContainer>
                    <DateCalendar
                      minDate={dayjs(new Date())}
                      value={selectedDate}
                      onChange={(newValue) => {
                        setSelectedDate(newValue);
                      }}
                    />
                    <DigitalClockContainer>
                      <MultiSectionDigitalClock
                        ampm={false}
                        value={selectedHours}
                        onChange={(newValue) => {
                          setSelectedHours(newValue);
                        }}
                      />
                    </DigitalClockContainer>
                  </DateCalendarContainer>
                  <Row>
                    <Button onClick={confirmDate}>
                      <ButtonText>OK</ButtonText>
                    </Button>
                  </Row>
                </LocalizationProvider>
              </>
            )}
            <Label>Detalle de la notificación</Label>
            <InputContainer focused={titleIsFocused} error={formError.title}>
              <Input
                maxLength={TITLE_MAX_QUANTITY_OF_CHARACTERS}
                placeholder='Título de la notificación'
                value={details.title}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange('title', event.target.value);
                }}
                onFocus={() => {
                  setTitleIsFocused(true);
                }}
                onBlur={() => {
                  setTitleIsFocused(false);
                }}
              />
            </InputContainer>
            <TextAreaContainer focused={textAreaIsFocused} error={formError.body}>
              <TextArea
                maxLength={BODY_MAX_QUANTITY_OF_CHARACTERS}
                value={details.body}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                  handleChange('body', event.target.value);
                }}
                style={{ resize: 'none' }}
                placeholder='Cuerpo de la notificación'
                onFocus={() => {
                  setTextAreaIsFocused(true);
                }}
                onBlur={() => {
                  setTextAreaIsFocused(false);
                }}
              />
              <CharacterQuantityContainer>
                <CharacterQuantity>
                  {formatLessThan10(details.body.length)}/{BODY_MAX_QUANTITY_OF_CHARACTERS}{' '}
                  caracteres
                </CharacterQuantity>
              </CharacterQuantityContainer>
            </TextAreaContainer>
            <Footer>
              <CustomButton
                disabled={creating}
                borderColor='#183582'
                withBackground={false}
                onClick={continueWithoutNotification}
              >
                <ButtonText>
                  {page === 'entries' ? 'Cancelar' : 'Continuar sin notificación'}
                </ButtonText>
              </CustomButton>
              <CustomButton
                disabled={creating}
                borderColor='#183582'
                withBackground={true}
                onClick={handleCreateCampaign}
              >
                {creating ? (
                  <CircularProgress size={22} style={{ color: 'white' }} />
                ) : (
                  <ButtonText white={true}>Programar campaña</ButtonText>
                )}
              </CustomButton>
            </Footer>
          </Content>
        )}
      </Container>
    </ModalContainer>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  font-family: ${(props) => props.theme.fonts.semiBold};
  color: ${(props) => props.theme.colors.primary};
  margin-bottom: 15px;
  font-size: 20px;
`;

const CircularProgressContainer = styled.div`
  min-width: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px;
`;

const Content = styled.div`
  flex: 1;
  position: relative;
`;

const Message = styled.p`
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.black};
  margin-bottom: 18px;
  font-size: 1rem;
`;

const Label = styled.p`
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.black};
  font-size: 1rem;
`;

const DateSelector = styled.div<InputContainerI>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  margin-top: 10px;
  border: 1px solid #f0f6fa;
  border-radius: 5px;
  padding-right: 10px;
  padding-left: 10px;
  margin-bottom: 15px;
  font-family: ${(props) => props.theme.fonts.regular};
  border-color: ${(props) =>
    props.error
      ? props.theme.colors.red
      : props.focused
      ? props.theme.colors.primary
      : props.theme.colors.gray};

  @media screen and (max-width: 860px) {
    width: 100%;
  }
`;

const DateSelectorText = styled.p`
  user-select: none;
  flex: 1;
  font-family: ${(props) => props.theme.fonts.regular};
  font-size: 0.9rem;
  color: ${(props) => props.theme.colors.gray2};
`;

const Input = styled.input`
  flex: 1;
  height: 100%;
  margin-right: 5px;
  border: none;
  outline: none;
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.black};
  ::placeholder {
    color: ${(props) => props.theme.colors.gray2};
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
  margin-top: 20px;
`;

const CustomButton = styled(Button)<CustomButtonI>`
  &&& {
    border: 1px solid ${(props) => props.borderColor};
    border-radius: 20px;
    background-color: ${(props) =>
      props?.withBackground ? props.theme.colors.primary : 'transparent'};
  }
`;

const ButtonText = styled.p<CustomButtonTextI>`
  text-transform: none;
  font-family: ${(props) => props.theme.fonts.medium};
  color: ${(props) => (props.white ? 'white' : props.theme.colors.primary)};
  font-size: 13px;
  padding: 0px 15px;
`;

const InputContainer = styled.div<InputContainerI>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  margin-top: 10px;
  border: 1px solid #f0f6fa;
  border-radius: 5px;
  padding-right: 10px;
  padding-left: 10px;
  font-family: ${(props) => props.theme.fonts.regular};
  border-color: ${(props) =>
    props.error
      ? props.theme.colors.red
      : props.focused
      ? props.theme.colors.primary
      : props.theme.colors.gray};

  @media screen and (max-width: 860px) {
    width: 100%;
  }
`;

const TextAreaContainer = styled.div<InputContainerI>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
  border: 1px solid #f0f6fa;
  border-radius: 5px;
  padding-top: 15px;
  padding-right: 10px;
  padding-left: 10px;
  height: 120px;
  font-family: ${(props) => props.theme.fonts.regular};
  border-color: ${(props) =>
    props.error
      ? props.theme.colors.red
      : props.focused
      ? props.theme.colors.primary
      : props.theme.colors.gray};
`;

const TextArea = styled(TextareaAutosize)<TextAreaI>`
  background-color: transparent;
  border: none;
  outline: none;
  width: 100%;
  font-family: ${(props) => props.theme.fonts.regular};
  flex: 1;
  ::placeholder {
    color: ${(props) =>
      props.placeholderColor ? props.placeholderColor : props.theme.colors.gray2};
  }
`;

const CharacterQuantityContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 10px;
`;

const CharacterQuantity = styled.p`
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.gray2};
  white-space: nowrap;
  font-size: 0.9rem;
`;

const DateCalendarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-bottom: 0.6px solid ${(props) => props.theme.colors.gray2};
  margin-bottom: 12px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const DigitalClockContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  flex: 1;
  padding-top: 12px;
`;
