import React from 'react';
import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { ArrowBack, ArrowLeft } from '@mui/icons-material';

interface Props {
  title: string;
  subtitle: string;
  setIsOpen: (v: boolean) => void;
}

export default function DrawerHeader({ title, subtitle, setIsOpen }: Props): JSX.Element {
  const handleClickClose = (): void => {
    setIsOpen(false);
  };

  return (
    <Container>
      <TitleContainer>
        <ArrowIconButton onClick={handleClickClose}>
          <ArrowBack color='primary' />
        </ArrowIconButton>
        <Title>{title}</Title>
        <CloseIconButton onClick={handleClickClose}>
          <CloseIcon />
        </CloseIconButton>
      </TitleContainer>
      <Subtitle>{subtitle}</Subtitle>
    </Container>
  );
}

const Container = styled.header`
  padding-top: 33px;
  padding-left: 20px;
  padding-right: 20px;

  @media screen and (max-width: 860px) {
    padding-left: 12px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  @media screen and (max-width: 860px) {
    margin-bottom: 0px;
  }
`;

const ArrowIconButton = styled(IconButton)`
  display: none;

  &&& {
    background-color: #f0f6fa;
  }

  @media screen and (max-width: 860px) {
    display: flex;
    margin-right: 12px;
  }
`;

const CloseIconButton = styled(IconButton)`
  display: inherit;

  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const Title = styled.p`
  flex: 1;
  font-size: 20px;
  font-family: ${(props) => props.theme.fonts.semiBold};
  color: ${(props) => props.theme.colors.primary};
`;

const Subtitle = styled.p`
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.gray2};
  font-size: 14px;
  margin-right: 30px;
`;
