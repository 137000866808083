import React from 'react';
import type Campaign from '../../interfaces/Campaigns';
import ModalContainer from '../globals/ModalContainer';
import styled from 'styled-components';
import capitalizeFirstLetter from '../../utils/capitalizeFirstLetter';
import { formatLessThan10 } from '../../utils/formatLessThan10';
import Calendar from '../../icons/Calendar';

interface Props extends Campaign {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const BODY_MAX_QUANTITY_OF_CHARACTERS = 160;

export default function CampaignDetails({
  title,
  body,
  scheduledAt,
  isVisible,
  setIsVisible,
}: Props) {
  const utcDate = new Date(scheduledAt);
  const offset = -5;
  const auxScheduledAt = new Date(utcDate.getTime() + offset * 60 * 60 * 1000);

  const getScheduledAt = () => {
    if (!scheduledAt) return '';

    if (!scheduledAt) {
      return 'Selecciona Fecha y Hora de notificación';
    }
    return `${auxScheduledAt.getDate()}/${formatLessThan10(
      auxScheduledAt.getMonth() + 1,
    )}/${auxScheduledAt.getFullYear()} ${
      auxScheduledAt.getHours() === 0 ? '00' : formatLessThan10(auxScheduledAt.getHours())
    }:${auxScheduledAt.getMinutes() === 0 ? '00' : formatLessThan10(auxScheduledAt.getMinutes())}`;
  };

  return (
    <ModalContainer isVisible={isVisible} setIsVisible={setIsVisible}>
      <Container>
        <Title>Campaña programada</Title>
        <Label>Fecha y horario</Label>
        <DateContainer>
          <Text style={{ flex: 1 }}>{getScheduledAt()}</Text>
          <Calendar />
        </DateContainer>
        <Label>Detalle de la notificación</Label>
        <TitleContainer>
          <Text>{capitalizeFirstLetter(title ?? '')}</Text>
        </TitleContainer>
        <BodyContainer>
          <Text>{capitalizeFirstLetter(body ?? '')}</Text>
          <CharacterQuantityContainer>
            <CharacterQuantity>
              {formatLessThan10(body?.length ?? '0')}/{BODY_MAX_QUANTITY_OF_CHARACTERS} caracteres
            </CharacterQuantity>
          </CharacterQuantityContainer>
        </BodyContainer>
      </Container>
    </ModalContainer>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
`;

const Title = styled.p`
  font-family: ${(props) => props.theme.fonts.semiBold};
  color: ${(props) => props.theme.colors.primary};
  margin-bottom: 15px;
  font-size: 20px;
`;

const Label = styled.p`
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.black};
  font-size: 1rem;
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: ${(props) => props.theme.colors.grey};
  padding: 12px 15px;
  margin-top: 10px;
  margin-bottom: 24px;
`;

const TitleContainer = styled.div`
  margin-top: 24px;
  background-color: ${(props) => props.theme.colors.grey};
  padding: 12px 15px;
  margin-bottom: 24px;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${(props) => props.theme.colors.grey};
  padding: 12px 15px;
  min-height: 160px;
`;

const Text = styled.p`
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.black};
  font-size: 1rem;
`;

const CharacterQuantityContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 4px;
`;

const CharacterQuantity = styled.p`
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.gray2};
  white-space: nowrap;
  font-size: 0.9rem;
`;
