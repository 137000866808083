import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import WithoutImageIcon from '../../icons/WithoutImageIcon';
import { type RootState } from '../../context/store';
import { setElementsContent, setUnsavedChanges } from '../../context/reducers/editor';
import { HEADER_IMAGE, POST_THUMBNAIL } from '../../constants/editorKeys';
import { uploadImage } from '../../api/files';
import { CircularProgress } from '@mui/material';
import { openSnackbar } from '../../context/reducers/generalSnackbar';
import { FILES_ERRORS, POST_ERRORS } from '../../constants/errorMessages';
import POSTS_STATUS from '../../constants/status';
import { AxiosError } from 'axios';

interface ContainerI {
  isPreviewModeActive: boolean;
}

export default function HeaderImage(): JSX.Element {
  const isPreviewModeActive = useSelector((state: RootState) => state.toolBar.isPreviewModeActive);
  const elementsContent = useSelector((state: RootState) => state.editor.elementsContent);
  const currentPostStatus = useSelector((state: RootState) => state.editor.currentPostStatus);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [previewImage, setPreviewImage] = useState<string | null | undefined>('');
  const [thumbnailImage, setThumbnailImage] = useState<string | null | undefined>('');
  const [uploading, setUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isImageLoading, setIsImageLoading] = useState(true);
  const inputFile = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const index = elementsContent.findIndex((item) => item.customKey === HEADER_IMAGE);
    if (index !== -1 && elementsContent[index].content !== null) {
      setPreviewImage(elementsContent[index].content as string);
    }
  }, []);

  const callApiToUploadImage = async () => {
    try {
      setUploading(true);
      setIsImageLoading(true);
      const formData = new FormData();
      if (selectedImage) {
        formData.append('file', selectedImage);
      }
      const response = await uploadImage(formData);
      setPreviewImage(response?.imageLocation);
      setThumbnailImage(response?.thumbLocation);
      setUploading(false);
    } catch (error: unknown) {
      let errorStatus;
      if (error instanceof AxiosError) {
        errorStatus = error?.response?.status;
      }
      if (errorStatus !== 401) {
        dispatch(
          openSnackbar({
            type: 'error',
            message: FILES_ERRORS.IMAGE,
          }),
        );
        setUploading(false);
        setIsImageLoading(false);
      }
    }
  };
  useEffect(() => {
    if (selectedImage !== null) {
      callApiToUploadImage();
    }
  }, [selectedImage]);

  useEffect(() => {
    dispatch(
      setElementsContent({
        customKey: HEADER_IMAGE,
        content: previewImage || null,
      }),
    );
  }, [previewImage]);

  useEffect(() => {
    dispatch(
      setElementsContent({
        customKey: POST_THUMBNAIL,
        content: thumbnailImage || null,
      }),
    );
  }, [thumbnailImage]);

  const selectFile = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e?.target?.files != null && e?.target?.files?.length !== 0) {
      const file = e.target.files[0] || null;
      if (file && file?.size <= 2 * 1024 * 1024) {
        setSelectedImage(e.target.files[0]);
        dispatch(
          setUnsavedChanges({
            unsavedChanges: true,
          }),
        );
      } else {
        setErrorMessage('El archivo excede el tamaño máximo permitido (Máx 2MB)');
      }
    }
  };

  const openInputFile = (): void => {
    if (currentPostStatus === POSTS_STATUS.PUBLISHED || currentPostStatus === null) {
      return;
    }
    setErrorMessage('');
    inputFile?.current?.click();
  };

  return (
    <Container
      onClick={openInputFile}
      isPreviewModeActive={
        isPreviewModeActive ||
        currentPostStatus === POSTS_STATUS.PUBLISHED ||
        currentPostStatus === null
      }
    >
      <input
        disabled={
          isPreviewModeActive ||
          uploading ||
          currentPostStatus === POSTS_STATUS.PUBLISHED ||
          currentPostStatus === null
        }
        type='file'
        onChange={selectFile}
        ref={inputFile}
        style={{ display: 'none' }}
        accept='image/*'
      />
      {uploading ? (
        <CircularProgress />
      ) : previewImage === '' || previewImage == null ? (
        <>
          <WithoutImageIcon />
          <Text>Añade una Imagen de cabecera</Text>
        </>
      ) : (
        <>
          {isImageLoading && (
            <CircularProgressContainer>
              <CircularProgress />
            </CircularProgressContainer>
          )}
          <Image
            src={previewImage}
            onLoad={() => {
              setIsImageLoading(false);
            }}
          />
        </>
      )}
      {errorMessage !== '' && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Container>
  );
}

const Container = styled.div<ContainerI>`
  position: relative;
  cursor: ${(props) => (props.isPreviewModeActive ? 'auto' : 'pointer')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 26px;
  height: 273px;
  background-color: ${(props) => props.theme.colors.matizBlue};
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid transparent;
  transition: border 0.1s ease-in, background-color 0.2s linear;

  ${(props) =>
    !props.isPreviewModeActive &&
    css`
      &:hover {
        background-color: #5c9cb52a;
        border-color: ${(props) => props.theme.colors.primary};
      }
    `}

  @media screen and (max-width: 860px) {
    margin-top: 0px;
  }
`;

const Text = styled.p`
  text-align: center;
  font-family: ${(props) => props.theme.fonts.semiBold};
  color: ${(props) => props.theme.colors.darkGray};
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  margin-top: 25px;
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const ErrorMessage = styled.p`
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.red};
  font-size: 12px;
  margin-top: 8px;
`;

const CircularProgressContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
`;
