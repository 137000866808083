import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { type AlertProps } from '@mui/material/Alert';
import Slide, { SlideProps } from '@mui/material/Slide';

interface Props {
  type?: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  message: string;
  vertical?: 'bottom' | 'top';
  horizontal?: 'center' | 'left' | 'right';
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

export default function CustomSnackbar({
  type = 'success',
  open,
  setOpen,
  message,
  vertical = 'bottom',
  horizontal = 'right',
}: Props): JSX.Element {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={2500}
      onClose={handleClose}
      anchorOrigin={{ vertical, horizontal }}
      key={vertical + horizontal}
      TransitionComponent={Slide}
      style={{ marginTop: '90px' }}
    >
      <Alert
        style={{ backgroundColor: type === 'success' ? '#183582' : '#CD4F4F' }}
        onClose={handleClose}
        severity={type === 'success' ? 'success' : 'error'}
        sx={{ width: '100%' }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}
