import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { useNavigate, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import TrashIcon from '../../icons/TrashIcon';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import type AuthorDetailsI from '../../interfaces/AuthorDetails';
import getAvatar from '../../utils/getAvatar';
import { deleteAuthor } from '../../api/authors';
import { useDispatch, useSelector } from 'react-redux';
import { openSnackbar } from '../../context/reducers/generalSnackbar';
import { setAuthorDeleted, setAuthors } from '../../context/reducers/authors';
import { type RootState } from '../../context/store';
import { AUTHORS_ERRORS } from '../../constants/errorMessages';
import { AxiosError } from 'axios';

interface Props extends AuthorDetailsI {}

export default function AuthorItem({ names, id }: Props): JSX.Element {
  const authors = useSelector((state: RootState) => state.authors.authors);
  const [confirmDeleteModalIsVisible, setConfirmDeleteModalIsVisible] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const dispatch = useDispatch();

  const onClickTrashBtn = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setConfirmDeleteModalIsVisible(true);
  };

  const confirmDeleteAuthor = async (): Promise<void> => {
    setConfirmDeleteModalIsVisible(false);
    if (!deleting) {
      try {
        setDeleting(true);
        await deleteAuthor(id);
        if (authors !== null) {
          dispatch(
            setAuthors({
              authors: authors?.filter((item) => item.id !== id),
            }),
          );
        }
        dispatch(
          openSnackbar({
            type: 'success',
            message: 'Autor eliminado correctamente',
          }),
        );
      } catch (error: unknown) {
        let errorStatus;
        if (error instanceof AxiosError) {
          errorStatus = error?.response?.status;
        }
        if (errorStatus !== 401) {
          setDeleting(false);
          dispatch(
            openSnackbar({
              type: 'error',
              message: AUTHORS_ERRORS.DELETE_AUTHOR,
            }),
          );
        }
      }
    }
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0.5 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.1 }}
        exit={{ opacity: 0.8 }}
      >
        <Container to={`${id}`}>
          <InfoContainer>
            <AvatarContainer>
              <Avatar>{getAvatar(names)}</Avatar>
            </AvatarContainer>
            <Name>{names}</Name>
          </InfoContainer>
          <ButtonsContainer>
            <CustomButton disabled={deleting} className='bg-primary-blue px-5 rounded-2xl mr-2'>
              <ButtonText>Editar</ButtonText>
            </CustomButton>
            <IconButton onClick={onClickTrashBtn}>
              <TrashIcon />
            </IconButton>
          </ButtonsContainer>
        </Container>
      </motion.div>
      <ConfirmDeleteModal
        isVisible={confirmDeleteModalIsVisible}
        setIsVisible={setConfirmDeleteModalIsVisible}
        authorId={id}
        authorName={names}
        deleting={deleting}
        setDeleting={setDeleting}
        confirmDeleteAuthor={confirmDeleteAuthor}
      />
    </>
  );
}

const Container = styled(Link)`
  cursor: pointer;
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 60px;
  border-top: 1px solid ${(props) => props.theme.colors.gray2};
  padding-right: 30px;
  padding-left: 30px;
  transition: background-color 0.1s ease-out;

  :hover {
    background-color: rgba(0, 0, 0, 0.09);
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`;

const AvatarContainer = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background-color: ${(props) => props.theme.colors.accentBlue};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 9px;
`;

const Avatar = styled.div`
  text-transform: uppercase;
  color: white;
  font-family: ${(props) => props.theme.fonts.regukar};
  font-size: 13px;
`;

const Name = styled.p`
  text-transform: capitalize;
  font-family: ${(props) => props.theme.fonts.regular};
`;

const ButtonsContainer = styled.div``;

const CustomButton = styled(Button)`
  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const ButtonText = styled.p`
  font-family: ${(props) => props.theme.fonts.medium};
  color: white;
  text-transform: none;
  font-size: 13px;
`;
