/* eslint-disable @typescript-eslint/prefer-optional-chain */
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { MdUndo, MdRedo } from 'react-icons/md';
import { RiParagraph } from 'react-icons/ri';
import { AiOutlineLine } from 'react-icons/ai';
import {
  type ContentBlock,
  ContentState,
  EditorState,
  RichUtils,
  Modifier,
  convertToRaw,
} from 'draft-js';
import { Button } from '@mui/material';
import { Add } from '@mui/icons-material';
import BoldIcon from '../../icons/BoldIcon';
import CursiveIcon from '../../icons/CursiveIcon';
import UnderlineIcon from '../../icons/UnderlineIcon';
import AlignLeftIcon from '../../icons/AlignLeftIcon';
import AlignCenterIcon from '../../icons/AlignCenterIcon';
import AlignRightIcon from '../../icons/AlignRightIcon';
import NumbersListIcon from '../../icons/NumbersListIcon';
import BulletListIcon from '../../icons/BulletListIcon';
import BlockquoteIcon from '../../icons/BlockquoteIcon';
import HeadPhones from '../../icons/HeadPhones';
import VideoIcon from '../../icons/VideoIcon';
import CustomMenu from '../globals/CustomMenu';
import {
  toogleAudioDrawer,
  tooglePreviewMode,
  toogleVideoDrawer,
} from '../../context/reducers/toolBar';
import {
  setCurrentSelectedEditorState,
  setIsAddNewElementDrawerOpen,
  setUnsavedChanges,
  toggleIsCustomLinkModalVisible,
  toogleEditSomeStyleOfCurrentSelectedEditor,
} from '../../context/reducers/editor';
import { type ToolBarItem } from '../../interfaces/ToolBar';
import ImageOption from './toolBar/ImageOption';
import { type RootState } from '../../context/store';
import {
  KEY_IDEA_ITEM,
  KEY_PARAGRAPH,
  WYSIWYG_MAIN_SUBTITLE,
  WYSIWYG_MAIN_TITLE,
} from '../../constants/editorKeys';
import { addCustomElement } from '../../utils/wysiwyg';
import LinkOption from './toolBar/LinkOption';
import POSTS_STATUS from '../../constants/status';
import EyeIcon from '../../icons/EyeIcon';
import JustifyIcon from '../../icons/JustifyIcon';

interface Props {
  type?: string;
  showToolBar?: boolean;
}

interface CustomIconButtonI {
  isSelected: boolean;
}

export default function MobileToolBar({ type = 'main', showToolBar }: Props) {
  const currentSelectedEditorState = useSelector(
    (state: RootState) => state.editor.currentSelectedEditorState,
  );
  const currentPostStatus = useSelector((state: RootState) => state.editor.currentPostStatus);
  const selectedEditorKey = useSelector((state: RootState) => state.editor.selectedEditorKey);
  const dispatch = useDispatch();

  const handleAddBlockquote = () => {
    if (
      currentSelectedEditorState !== null &&
      selectedEditorKey !== WYSIWYG_MAIN_TITLE &&
      selectedEditorKey !== WYSIWYG_MAIN_SUBTITLE &&
      selectedEditorKey !== KEY_IDEA_ITEM &&
      selectedEditorKey !== null
    ) {
      const selectionState = currentSelectedEditorState.getSelection();
      if (!selectionState.isCollapsed()) {
        const currentSyle = currentSelectedEditorState.getCurrentInlineStyle();
        const isBold = currentSyle.has('BOLD');
        let auxEditorState = currentSelectedEditorState;
        if (!isBold) {
          auxEditorState = RichUtils.toggleInlineStyle(currentSelectedEditorState, 'BOLD');
        }
        const newEditorState = RichUtils.toggleBlockType(auxEditorState, 'blockquote');
        const contentState = newEditorState.getCurrentContent();

        const customClass = 'custom-blockquote';
        const contentStateWithCustomStyle = Modifier.applyInlineStyle(
          contentState,
          selectionState,
          customClass,
        );

        const newEditorStateWithCustomStyle = EditorState.push(
          newEditorState,
          contentStateWithCustomStyle,
          'change-inline-style',
        );

        dispatch(
          setCurrentSelectedEditorState({
            currentSelectedEditorState: EditorState.forceSelection(
              newEditorStateWithCustomStyle,
              currentSelectedEditorState.getSelection(),
            ),
          }),
        );
      }
      dispatch(toogleEditSomeStyleOfCurrentSelectedEditor({}));
      dispatch(setUnsavedChanges({ unsavedChanges: true }));
    }
  };

  const handleUndoRedo = (type: string) => {
    if (
      currentSelectedEditorState !== null &&
      selectedEditorKey !== WYSIWYG_MAIN_TITLE &&
      selectedEditorKey !== WYSIWYG_MAIN_SUBTITLE &&
      selectedEditorKey !== KEY_IDEA_ITEM
    ) {
      let newEditorState = null;
      if (type === 'undo') {
        newEditorState = EditorState.undo(currentSelectedEditorState);
      } else {
        newEditorState = EditorState.redo(currentSelectedEditorState);
      }
      dispatch(
        setCurrentSelectedEditorState({
          currentSelectedEditorState: EditorState.forceSelection(
            newEditorState,
            newEditorState.getSelection(),
          ),
        }),
      );
      dispatch(toogleEditSomeStyleOfCurrentSelectedEditor({}));
    }
  };

  const checkIfButtonIsDisabled = (style: string | undefined, id: string | undefined) => {
    if (currentPostStatus === POSTS_STATUS.PUBLISHED || currentPostStatus === null) {
      return true;
    }
    if (
      (currentSelectedEditorState == null ||
        selectedEditorKey == null ||
        selectedEditorKey !== KEY_PARAGRAPH) &&
      (style !== undefined || (id !== 'audio' && id !== 'video'))
    ) {
      return true;
    }
    return false;
  };

  const getBtnColor = (style: string | undefined, id: string | undefined) => {
    return checkIfButtonIsDisabled(style, id) ? 'rgba(0, 0, 0, 0.26)' : '#3C3C3B';
  };

  const openNewElementDrawer = () => {
    dispatch(
      setIsAddNewElementDrawerOpen({
        isDrawerOpen: true,
      }),
    );
  };

  const tools: ToolBarItem[] = [
    {
      icon: <BoldIcon color={getBtnColor('BOLD', undefined)} />,
      style: 'BOLD',
      action: () => {},
    },
    {
      icon: <CursiveIcon color={getBtnColor('ITALIC', undefined)} />,
      style: 'ITALIC',
      action: () => {},
    },
    {
      icon: <UnderlineIcon color={getBtnColor('UNDERLINE', undefined)} />,
      style: 'UNDERLINE',
      action: () => {},
    },
    {
      icon: <AlignLeftIcon color={getBtnColor('left', undefined)} />,
      style: 'left',
      action: () => {},
    },
    {
      icon: <AlignCenterIcon color={getBtnColor('center', undefined)} />,
      style: 'center',
      action: () => {},
    },
    {
      icon: <AlignRightIcon color={getBtnColor('right', undefined)} />,
      style: 'right',
      action: () => {},
    },
    {
      icon: <JustifyIcon color={getBtnColor('justify', undefined)} />,
      style: 'justify',
      action: () => {},
    },
    {
      icon: <NumbersListIcon color={getBtnColor('ordered-list-item', undefined)} />,
      style: 'ordered-list-item',
      action: () => {},
    },
    {
      icon: <BulletListIcon color={getBtnColor('unordered-list-item', undefined)} />,
      style: 'unordered-list-item',
      action: () => {},
    },
    {
      element: <LinkOption type={type} showToolBar={showToolBar} />,
      id: 'link',
      action: () => {},
    },
    {
      icon: <BlockquoteIcon color={getBtnColor(undefined, 'blockquote')} />,
      id: 'blockquote',
      action: () => {
        handleAddBlockquote();
      },
    },
    {
      icon: <MdUndo />,
      id: 'undo',
      action: () => {
        handleUndoRedo('undo');
      },
    },
    {
      icon: <MdRedo />,
      id: 'redo',
      action: () => {
        handleUndoRedo('redo');
      },
    },
  ];

  const handleAlignText = (style: string | undefined): void => {
    if (
      selectedEditorKey !== WYSIWYG_MAIN_TITLE &&
      selectedEditorKey !== WYSIWYG_MAIN_SUBTITLE &&
      selectedEditorKey !== KEY_IDEA_ITEM &&
      selectedEditorKey !== null
    ) {
      const contentState = currentSelectedEditorState.getCurrentContent();
      const selectionState = currentSelectedEditorState.getSelection();
      const startKey = selectionState.getStartKey();
      const endKey = selectionState.getEndKey();
      const blocks = contentState.getBlocksAsArray();
      let startIndex: number, endIndex: number;

      blocks.forEach((block, index) => {
        if (block.getKey() === startKey) {
          startIndex = index;
        }
        if (block.getKey() === endKey) {
          endIndex = index;
        }
      });

      const newBlocks = blocks.map((block, index) => {
        if (index >= startIndex && index <= endIndex) {
          const blockData = block.getData();
          let newData = blockData;
          if (blockData.get('text-align') === style) {
            newData = newData.remove('text-align');
          } else {
            newData = newData.set('text-align', style);
          }
          return block.merge({ data: newData }) as ContentBlock;
        }
        return block;
      });

      const newContentState = ContentState.createFromBlockArray(newBlocks);

      const newEditorState = EditorState.push(
        currentSelectedEditorState,
        newContentState,
        'change-block-data',
      );

      dispatch(
        setCurrentSelectedEditorState({
          currentSelectedEditorState: EditorState.forceSelection(
            newEditorState,
            currentSelectedEditorState.getSelection(),
          ),
        }),
      );
      dispatch(setUnsavedChanges({ unsavedChanges: true }));
    }
  };

  const handleOrderedList = (style: string | undefined): void => {
    if (style !== undefined) {
      const newEditorState = RichUtils.toggleBlockType(currentSelectedEditorState, style);
      dispatch(
        setCurrentSelectedEditorState({
          currentSelectedEditorState: EditorState.forceSelection(
            newEditorState,
            currentSelectedEditorState.getSelection(),
          ),
        }),
      );
      dispatch(setUnsavedChanges({ unsavedChanges: true }));
    }
  };

  const handleToolBarButtonClick = (style: string | undefined): void => {
    if (
      currentSelectedEditorState !== null &&
      style !== undefined &&
      selectedEditorKey !== WYSIWYG_MAIN_TITLE &&
      selectedEditorKey !== WYSIWYG_MAIN_SUBTITLE &&
      selectedEditorKey !== KEY_IDEA_ITEM &&
      selectedEditorKey !== null
    ) {
      if (style === 'center' || style === 'left' || style === 'right' || style === 'justify') {
        handleAlignText(style);
      } else if (style.includes('list')) {
        handleOrderedList(style);
      } else {
        const newEditorState = RichUtils.toggleInlineStyle(currentSelectedEditorState, style);
        dispatch(
          setCurrentSelectedEditorState({
            currentSelectedEditorState: EditorState.forceSelection(
              newEditorState,
              currentSelectedEditorState.getSelection(),
            ),
          }),
        );
        dispatch(setUnsavedChanges({ unsavedChanges: true }));
      }
      dispatch(toogleEditSomeStyleOfCurrentSelectedEditor({}));
    }
  };

  const checkIfStyleIsSelected = (style: string | undefined, id: string | undefined): boolean => {
    if (
      currentSelectedEditorState !== null &&
      style !== undefined &&
      selectedEditorKey !== WYSIWYG_MAIN_TITLE &&
      selectedEditorKey !== WYSIWYG_MAIN_SUBTITLE &&
      selectedEditorKey !== KEY_IDEA_ITEM
    ) {
      if (style === undefined && id === undefined) {
        return false;
      }
      const contentState = currentSelectedEditorState.getCurrentContent();
      const selection = currentSelectedEditorState.getSelection();
      const blockKey = selection.getStartKey();
      const block = contentState.getBlockForKey(blockKey);

      if (block) {
        const startOffset = selection.getStartOffset();
        const endOffset = selection.getEndOffset();
        const selectedStyles = block.getInlineStyleAt(startOffset);
        const alignment = block.getData()?.get('text-align');
        const listStyle = block.getData()?.get('list-style');
        const blockType = block.getType();
        if (style === 'BOLD' || style === 'ITALIC' || style === 'UNDERLINE') {
          return selectedStyles.toArray().includes(style);
        } else if (
          style === 'left' ||
          style === 'center' ||
          style === 'right' ||
          style === 'justify'
        ) {
          return alignment === style;
        } else if (id === 'blockquote') {
          return blockType === id;
        } else {
          return style === blockType;
        }
      }
    }
    return false;
  };

  return (
    <Container>
      <IconButton className='bg-primary' onClick={openNewElementDrawer}>
        <Add style={{ color: 'white' }} />
      </IconButton>
      <List>
        {tools.map((tool, index) => {
          if (tool.element !== undefined) {
            return <div key={index}>{tool?.element}</div>;
          } else {
            return (
              <CustomIconButton
                disabled={checkIfButtonIsDisabled(tool.style, tool.id)}
                isSelected={checkIfStyleIsSelected(tool.style, tool.id)}
                onClick={(e) => {
                  if (tool.style === undefined) {
                    tool?.action(e);
                  } else {
                    handleToolBarButtonClick(tool?.style);
                  }
                }}
                className='h-8 w-8'
                style={{
                  borderRadius: '3px',
                }}
                key={index}
              >
                {tool.icon}
              </CustomIconButton>
            );
          }
        })}
      </List>
    </Container>
  );
}

const Container = styled.div`
  position: fixed;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  bottom: 0px;
  left: 0px;
  background-color: white;
  width: 100vw;
  overflow-x: scroll;
  padding-left: 15px;
  border-top: 1px solid #c0d4ff;
  z-index: 999;

  @media screen and (max-width: 860px) {
    display: flex;
  }
`;

const CustomIconButton = styled(IconButton)<CustomIconButtonI>`
  background-color: ${(props) =>
    props.isSelected ? props.theme.colors.lightSkyBlue : 'transparent'};
`;

const List = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: scroll;
  gap: 12px;
  flex: 1;
  padding: 12px;
  height: 100%;

  @media screen and (max-width: 860px) {
    overflow-y: visible;
    justify-content: space-between;
  }

  @media screen and (max-width: 768px) {
    justify-content: inherit;
  }
`;
