import React from 'react';
import type Icon from '../interfaces/Icon';

export default function AlignCenterIcon({ color = '#3C3C3B' }: Icon): JSX.Element {
  return (
    <svg width='17' height='12' viewBox='0 0 17 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.07059 11.1428H11.2134V9.42848H6.07059V11.1428ZM3.49916 5.14277V6.85706H13.7849V5.14277H3.49916ZM0.927734 0.857056V2.57134H16.3563V0.857056H0.927734Z'
        fill={color}
      />
    </svg>
  );
}
