import React, { useState } from 'react';
import styled from 'styled-components';
import { RiSearchLine } from 'react-icons/ri';

interface Props {
  handleInputChange: (value: string) => void;
}

interface InputI {
  inputIsFocus: boolean;
}

export default function SearchBar({ handleInputChange }: Props): JSX.Element {
  const [inputIsFocus, setInputIsFocus] = useState(false);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleInputChange(e.target.value);
  };

  return (
    <Container inputIsFocus={inputIsFocus}>
      <RiSearchLine color='#183582' size={25} />
      <Input
        onChange={onChange}
        onFocus={() => {
          setInputIsFocus(true);
        }}
        onBlur={() => {
          setInputIsFocus(false);
        }}
        placeholder='Buscar'
        size={1}
      />
    </Container>
  );
}

const Container = styled.div<InputI>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  height: 34px;
  border-radius: 20px;
  padding-left: 12px;
  border: 1px solid ${(props) => (props.inputIsFocus ? props.theme.colors.primary : 'transparent')};
`;

const Input = styled.input`
  flex: 1;
  color: ${(props) => props.theme.colors.darkGray};
  font-family: ${(props) => props.theme.fonts.regular};
  height: 100%;
  font-size: 16px;
  border: none;
  outline: none;
  margin-left: 10px;
  &&& {
    border-radius: 20px;
    min-width: 20px;
  }
  ::placeholder {
    color: ${(props) => props.theme.colors.darkGray};
    font-family: ${(props) => props.theme.fonts.regular};
  }
`;
