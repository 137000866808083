import jwtDecode from 'jwt-decode';

interface TokenData {
  exp: number;
}

export const checkTokenExpiration = (token: string | null): boolean => {
  if (token == null) {
    return true;
  }
  const tokenData: TokenData = jwtDecode(token);

  const currentTimestamp = Math.floor(Date.now() / 1000);
  return tokenData.exp < currentTimestamp;
};
