import React from 'react';
import type Icon from '../interfaces/Icon';

export default function HeadPhones({ color = '#3C3C3B' }: Icon): JSX.Element {
  return (
    <svg width='18' height='17' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.21401 2.14275C7.39538 2.14275 5.65124 2.8652 4.36527 4.15116C3.07931 5.43713 2.35686 7.18127 2.35686 8.9999H4.92829C5.38295 8.9999 5.81898 9.18051 6.14047 9.502C6.46197 9.82349 6.64258 10.2595 6.64258 10.7142V14.9999C6.64258 15.4546 6.46197 15.8906 6.14047 16.2121C5.81898 16.5336 5.38295 16.7142 4.92829 16.7142H2.35686C1.90221 16.7142 1.46617 16.5336 1.14468 16.2121C0.82319 15.8906 0.642578 15.4546 0.642578 14.9999V8.9999C0.642578 4.2659 4.48001 0.428467 9.21401 0.428467C13.948 0.428467 17.7854 4.2659 17.7854 8.9999V14.9999C17.7854 15.4546 17.6048 15.8906 17.2833 16.2121C16.9618 16.5336 16.5258 16.7142 16.0711 16.7142H13.4997C13.0451 16.7142 12.609 16.5336 12.2875 16.2121C11.966 15.8906 11.7854 15.4546 11.7854 14.9999V10.7142C11.7854 10.2595 11.966 9.82349 12.2875 9.502C12.609 9.18051 13.0451 8.9999 13.4997 8.9999H16.0711C16.0711 7.18127 15.3487 5.43713 14.0627 4.15116C12.7768 2.8652 11.0326 2.14275 9.21401 2.14275ZM2.35686 10.7142V14.9999H4.92829V10.7142H2.35686ZM13.4997 10.7142V14.9999H16.0711V10.7142H13.4997Z'
        fill={color}
      />
    </svg>
  );
}
