import React, { useState } from 'react';
import { Button, Drawer, IconButton } from '@mui/material';
import styled from 'styled-components';
import POSTS_STATUS from '../../constants/status';
import { useDispatch, useSelector } from 'react-redux';
import { setEntriesDraftsIsDrawerOpen } from '../../context/reducers/entriesDrafts';
import { setEntriesPendingReviewsIsDrawerOpen } from '../../context/reducers/entriesPendingReviews';
import { setEntriesPublishedIsDrawerOpen } from '../../context/reducers/entriesPublished';
import { Close } from '@mui/icons-material';
import OptionItem from './filtersDrawer/OptionItem';
import OptionWithList from './filtersDrawer/OptionWithList';
import { useAnimate } from 'framer-motion';
import RESOURCES_TYPES from '../../constants/resourcesTypes';
import { type RootState } from '../../context/store';
import OptionWithListAuthorsWrapper from './filtersDrawer/OptionWithListAuthorsWrapper';
import SearchBar from './filtersDrawer/SearchBar';
import { setPresentationsDraftsIsDrawerOpen } from '../../context/reducers/presentationsDrafts';
import { setPresentationsPendingReviewsIsDrawerOpen } from '../../context/reducers/presentationsPendingReview';
import { setPresentationsPublishedIsDrawerOpen } from '../../context/reducers/presentationsPublished';

interface Props {
  isOpen: boolean;
  type?: string;
  resource: string | null;
  setResource: React.Dispatch<React.SetStateAction<string | null>>;
  authorsIDs: any[];
  setAuthorsIDs: React.Dispatch<React.SetStateAction<any[]>>;
  categoriesIDs: any[];
  setCategoriesIDs: React.Dispatch<React.SetStateAction<any[]>>;
  contentType?: 'ARTICLE' | 'PRESENTATION';
}

interface ButtonI {
  unsavedChanges: boolean;
}

export default function FiltersDrawer({
  isOpen,
  type,
  resource,
  setResource,
  authorsIDs,
  setAuthorsIDs,
  categoriesIDs,
  setCategoriesIDs,
  contentType = 'ARTICLE',
}: Props) {
  const categoriesList = useSelector((state: RootState) => state.categories.categoriesList);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [isCategoriesListDrawerOpen, setIsCategoriesListDrawerOpen] = useState(false);
  const [isAuthorsListDrawerOpen, setIsAuthorsListDrawerOpen] = useState(false);
  const [isResourcesListDrawerOpen, setIsResourcesListDrawerOpen] = useState(false);
  const [auxResource, setAuxResource] = useState<string | null>(null);
  const [auxSelectedAuthors, setAuxSelectedAuthors] = useState<any[]>([]);
  const [auxCategoriesIDs, setAuxCategoriesIDs] = useState<string[]>([]);
  const [auxSearchInput, setAuxSearchInput] = useState('');
  const dispatch = useDispatch();

  const closeAllDrawers = () => {
    setIsAuthorsListDrawerOpen(false);
    setIsCategoriesListDrawerOpen(false);
    setIsResourcesListDrawerOpen(false);
  };

  const cleanFilters = () => {
    setAuxResource(resource);
    setAuxSelectedAuthors(authorsIDs);
    setAuxCategoriesIDs(categoriesIDs);
  };

  const handleClose = () => {
    if (contentType === 'ARTICLE') {
      switch (type) {
        case POSTS_STATUS.DRAFT:
          dispatch(
            setEntriesDraftsIsDrawerOpen({
              isDrawerOpen: false,
            }),
          );
          break;
        case POSTS_STATUS.PENDING_REVIEW:
          dispatch(
            setEntriesPendingReviewsIsDrawerOpen({
              isDrawerOpen: false,
            }),
          );
          break;
        case POSTS_STATUS.PUBLISHED:
          dispatch(
            setEntriesPublishedIsDrawerOpen({
              isDrawerOpen: false,
            }),
          );
          break;
        default:
          break;
      }
    } else if (contentType === 'PRESENTATION') {
      switch (type) {
        case POSTS_STATUS.DRAFT:
          dispatch(
            setPresentationsDraftsIsDrawerOpen({
              isDrawerOpen: false,
            }),
          );
          break;
        case POSTS_STATUS.PENDING_REVIEW:
          dispatch(
            setPresentationsPendingReviewsIsDrawerOpen({
              isDrawerOpen: false,
            }),
          );
          break;
        case POSTS_STATUS.PUBLISHED:
          dispatch(
            setPresentationsPublishedIsDrawerOpen({
              isDrawerOpen: false,
            }),
          );
          break;
        default:
          break;
      }
    }
    closeAllDrawers();
    setUnsavedChanges(false);
  };

  const applyChanges = () => {
    setAuthorsIDs(auxSelectedAuthors);
    setCategoriesIDs(auxCategoriesIDs);
    setResource(auxResource);
    handleClose();
    closeAllDrawers();
  };

  const openAuthorsList = () => {
    setIsAuthorsListDrawerOpen(true);
  };

  const openCategoriesList = () => {
    setIsCategoriesListDrawerOpen(true);
  };

  const openResourcesList = () => {
    setIsResourcesListDrawerOpen(true);
  };

  const getTitle = () => {
    switch (type) {
      case POSTS_STATUS.DRAFT:
        return 'Borradores';
      case POSTS_STATUS.PENDING_REVIEW:
        return 'Por revisar';
      case POSTS_STATUS.PUBLISHED:
        return 'Publicados';
      default:
        return '';
    }
  };

  const handleInputChange = (value: string) => {
    setAuxSearchInput(value);
    setUnsavedChanges(true);
  };

  return (
    <StyledDrawer
      anchor='left'
      ModalProps={{
        keepMounted: true,
      }}
      open={isOpen}
      onClose={handleClose}
    >
      <DrawerContent>
        <Header>
          <Title>Filtros</Title>
          <IconButton
            onClick={() => {
              handleClose();
              cleanFilters();
            }}
          >
            <Close style={{ color: 'black' }} fontSize='large' />
          </IconButton>
        </Header>
        <Container>
          {/* <SearchBar
            title={getTitle()}
            handleInputChange={handleInputChange}
            searchInput={auxSearchInput}
          /> */}
          {contentType !== 'PRESENTATION' && (
            <>
              <OptionItem title='Autor' handleClick={openAuthorsList} />
              <OptionItem title='Tipo de recurso' handleClick={openResourcesList} />
            </>
          )}
          <OptionItem title='Categoría' handleClick={openCategoriesList} />
          <OptionWithListAuthorsWrapper
            setIsOpen={setIsAuthorsListDrawerOpen}
            isOpen={isAuthorsListDrawerOpen}
            setUnsavedChanges={setUnsavedChanges}
            selectedAuthors={auxSelectedAuthors}
            setSelectedAuthors={setAuxSelectedAuthors}
          />
          <OptionWithList
            setIsOpen={setIsCategoriesListDrawerOpen}
            isOpen={isCategoriesListDrawerOpen}
            title='Categoría'
            options={categoriesList}
            setUnsavedChanges={setUnsavedChanges}
            type='category'
            categoriesIDs={auxCategoriesIDs}
            setCategoriesIDs={setAuxCategoriesIDs}
          />
          <OptionWithList
            setIsOpen={setIsResourcesListDrawerOpen}
            isOpen={isResourcesListDrawerOpen}
            title='Tipo de Recurso'
            options={RESOURCES_TYPES}
            setUnsavedChanges={setUnsavedChanges}
            type='resource'
            resource={auxResource}
            setResource={setAuxResource}
          />
        </Container>
        <Footer>
          <StyledButton
            disabled={!unsavedChanges}
            onClick={applyChanges}
            unsavedChanges={unsavedChanges}
            className='bg-accent-blue px-5 py-2 rounded-2xl'
          >
            <ButtonText>Aplicar</ButtonText>
          </StyledButton>
        </Footer>
      </DrawerContent>
    </StyledDrawer>
  );
}

const StyledDrawer = styled(Drawer)``;

const DrawerContent = styled.div`
  background-color: white;
  width: 100vw;
  padding-top: 20px;
  padding-left: 22px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 12px;
`;

const Title = styled.p`
  font-size: 20px;
  font-family: ${(props) => props.theme.fonts.regular};
  flex: 1;
`;

const Container = styled.div`
  position: relative;
  flex: 1;
`;

const Footer = styled.footer`
  padding-bottom: 30px;
`;

const StyledButton = styled(Button)<ButtonI>`
  &&& {
    width: 100%;
    background-color: ${(props) =>
      !props.unsavedChanges
        ? props.theme.colors.btnPrimaryDisabledGray
        : props.theme.colors.primary};
    padding: 7px;
    border-radius: 12px;
  }
`;

const ButtonText = styled.p`
  color: white;
  font-family: ${(props) => props.theme.fonts.medium};
  font-size: 16px;
  text-transform: none;
`;
