const SETTINGS_ADD_SUCCESFUL_MESSAGES = {
  ADD_CATEGORY: 'Categoría agregada correctamente',
  ADD_TAG: 'Etiqueta agregada correctamente',
  ADD_AUTHOR: 'Autor agregado correctamente',
  ADD_SUBSCRIPTION: 'Suscripción agregada correctamente',
};

const SETTINGS_REMOVE_SUCCESFUL_MESSAGES = {
  REMOVE_CATEGORY: 'Categoría eliminada correctamente',
  REMOVE_TAG: 'Etiqueta eliminada correctamente',
  REMOVE_AUTHOR: 'Autor eliminado correctamente',
  REMOVE_SUBSCRIPTION: 'Suscripción eliminada correctamente',
};

const ARTICLE_SUCCESFUL_MESSAGES = {
  CREATE_ARTICLE: 'Artículo creado correctamente en estado borrador',
  UPDATE_ARTICLE: 'Artículo actualizado correctamente',
  SEND_TO_REVIEW_ARTICLE: 'Artículo pasado a revisión correctamente',
  PUBLISH_ARTICLE: 'Artículo publicado correctamente',
  DELETE_ARTICLE: 'Artículo eliminado correctamente',
  RETURN_TO_DRAFT_ARTICLE: 'El artículo fue devuelto al redactor',
  UPDATE_PRESENTATION: 'PPT actualizado correctamente',
  CREATE_PRESENTATION: 'PPT creado correctamente en estado borrador',
  SEND_TO_REVIEW_PRESENTATION: 'PPT pasado a revisión correctamente',
  PUBLISH_PRESENTATION: 'PPT publicado correctamente',
  RETURN_TO_DRAFT_PRESENTATION: 'El PPT fue devuelto al redactor',
  DELETE_PRESENTATION: 'PPT eliminado correctamente',
};

const CAMPAINGS_SUCCESFUL_MESSAGES = {
  CREATE_CAMPAIGN: 'Campaña programada exitosamente',
  DELETE_CAMPAIGN: 'Campaña eliminada correctamente',
  MULTIPLE_DELETE_CAMPAIGN: 'Campañas eliminadas correctamente',
};

export {
  SETTINGS_ADD_SUCCESFUL_MESSAGES,
  SETTINGS_REMOVE_SUCCESFUL_MESSAGES,
  ARTICLE_SUCCESFUL_MESSAGES,
  CAMPAINGS_SUCCESFUL_MESSAGES,
};
