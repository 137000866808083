import React from 'react';
import type Icon from '../interfaces/Icon';

export default function PlusIcon({ width = 24, height = 24, color = 'white' }: Icon) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M22 13.6666H13.6667V21.9999C13.6667 22.9166 12.9167 23.6666 12 23.6666C11.0833 23.6666 10.3333 22.9166 10.3333 21.9999V13.6666H2C1.08334 13.6666 0.333336 12.9166 0.333336 11.9999C0.333336 11.0833 1.08334 10.3333 2 10.3333H10.3333V1.99992C10.3333 1.08325 11.0833 0.333252 12 0.333252C12.9167 0.333252 13.6667 1.08325 13.6667 1.99992V10.3333H22C22.9167 10.3333 23.6667 11.0833 23.6667 11.9999C23.6667 12.9166 22.9167 13.6666 22 13.6666Z'
        fill={color}
      />
    </svg>
  );
}
