import React from 'react';
import type Icon from '../interfaces/Icon';

export default function PublishedPlaceholderIcon({
  width = 26,
  height = 25,
  color = '#9A9A9A',
}: Icon) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 26 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask id='mask0_1468_2692' maskUnits='userSpaceOnUse' x='0' y='0' width='26' height='25'>
        <path d='M0.5 1.90735e-06H25.5V25H0.5V1.90735e-06Z' fill='white' />
      </mask>
      <g mask='url(#mask0_1468_2692)'>
        <path
          d='M13 18.4082C13 21.6442 10.3767 24.2676 7.14062 24.2676C3.90459 24.2676 1.28125 21.6442 1.28125 18.4082C1.28125 15.1722 3.90459 12.5488 7.14062 12.5488C10.3767 12.5488 13 15.1722 13 18.4082Z'
          stroke={color}
          strokeWidth='1.46484'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7.14062 24.2676H23.2539C24.0629 24.2676 24.7188 23.6117 24.7188 22.8027V5.61538L19.8361 0.732424H8.60547C7.79648 0.732424 7.14062 1.38828 7.14062 2.19727V12.5488'
          stroke={color}
          strokeWidth='1.46484'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M19.8359 0.732422V5.61523H24.7187L19.8359 0.732422Z'
          stroke={color}
          strokeWidth='1.46484'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M11.0156 14.0137H21.3007'
          stroke={color}
          strokeWidth='1.46484'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M10.5586 9.61914H21.3008'
          stroke={color}
          strokeWidth='1.46484'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M13 18.4082H21.3008'
          stroke={color}
          strokeWidth='1.46484'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M6.11523 20.5078L4.65039 19.043'
          stroke={color}
          strokeWidth='1.46484'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M6.11523 20.5078L9.5332 17.0898'
          stroke={color}
          strokeWidth='1.46484'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  );
}
