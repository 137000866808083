import { createSlice } from '@reduxjs/toolkit';

interface InitialState {
  processed: [] | null;
  currentPage: number;
  total: number;
  isLoadingMoreData: boolean;
  canLoadMoreData: boolean;
}

const initialState: InitialState = {
  processed: null,
  currentPage: 0,
  total: 0,
  isLoadingMoreData: false,
  canLoadMoreData: false,
};

const processedCampaignsSlice = createSlice({
  name: 'processedCampaigns',
  initialState,
  reducers: {
    setProcessedCampaigns: (state, action) => {
      state.processed = action.payload.processed;
    },
    setProcessedCampaignsCurrentPage: (state, action) => {
      state.currentPage = action.payload.currentPage;
    },
    setProcessedCampaignsTotal: (state, action) => {
      state.total = action.payload.total;
    },
    setProcessedCampaignsIsLoadingMoreData: (state, action) => {
      state.isLoadingMoreData = action.payload.isLoadingMoreData;
    },
    setProcessedCampaignsCanLoadMoreData: (state, action) => {
      state.canLoadMoreData = action.payload.canLoadMoreData;
    },
  },
});

export const {
  setProcessedCampaigns,
  setProcessedCampaignsCanLoadMoreData,
  setProcessedCampaignsCurrentPage,
  setProcessedCampaignsIsLoadingMoreData,
  setProcessedCampaignsTotal,
} = processedCampaignsSlice.actions;
export default processedCampaignsSlice.reducer;
