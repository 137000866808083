import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import axios, { AxiosError } from 'axios';
import PendingReviewItem from './PendingReviewItem';
import ListingIcon from '../../icons/ListingIcon';
import Filters from '../../components/entries/Filters';
import POSTS_STATUS from '../../constants/status';
import { getPosts } from '../../api/posts';
import { PAGE_SIZE } from '../../constants/pagination';
import { HOME_ERRORS } from '../../constants/errorMessages';
import { openSnackbar } from '../../context/reducers/generalSnackbar';
import PublicationSkeleton from '../home/PublicationSkeleton';
import type PendingReviewInterface from '../../interfaces/PendingReview';
import { type RootState } from '../../context/store';
import PendingReviewPlaceholderIcon from '../../icons/PendingReviewPlaceholderIcon';
import NotFoundIcon from '../../icons/NotFoundIcon';
import { type AuthorItem } from '../../interfaces/AuthorDetails';
import { checkIfSomeFilterIsSelected } from '../../utils/entries';
import customDebounce from '../../utils/debounce';
import { useSearchParams } from 'react-router-dom';
import {
  setPendingReviewPostAdded,
  setPendingReviewPostUpdated,
  setPendingReviewQuantity,
} from '../../context/reducers/entries';
import {
  setEntriesPendingReviews,
  setEntriesPendingReviewsCanLoadMoreData,
  setEntriesPendingReviewsCurrentPage,
  setEntriesPendingReviewsIsLoadingMoreData,
  setEntriesPendingReviewsResetValues,
} from '../../context/reducers/entriesPendingReviews';
import TitleWithFiltersButton from '../globals/TitleWithFiltersButton';
import FiltersDrawer from './FiltersDrawer';
import SearchBar from './filtersDrawer/SearchBar';
import { HTML_TEXT } from '../../constants/globals';

export default function PendingReviewList(): JSX.Element {
  const pendingReviews = useSelector(
    (state: RootState) => state.entriesPendingReviews.pendingReviews,
  );
  const pendingReviewsCurrentPage = useSelector(
    (state: RootState) => state.entriesPendingReviews.currentPage,
  );
  const pendingReviewsIsLoadingMoreData = useSelector(
    (state: RootState) => state.entriesPendingReviews.isLoadingMoreData,
  );
  const pendingReviewsCanLoadMoreData = useSelector(
    (state: RootState) => state.entriesPendingReviews.canLoadMoreData,
  );
  const pendingReviewPostAdded = useSelector(
    (state: RootState) => state.entries.pendingReviewPostAdded,
  );
  const pendingReviewPostUpdated = useSelector(
    (state: RootState) => state.entries.pendingReviewPostUpdated,
  );
  const pendingReviewQuantity = useSelector(
    (state: RootState) => state.entries.pendingReviewQuantity,
  );
  const postPublished = useSelector((state: RootState) => state.entries.postPublished);
  const isDrawerOpen = useSelector((state: RootState) => state.entriesPendingReviews.isDrawerOpen);
  const [searchParams, setSearchParams] = useSearchParams();
  const [paramType, setParamType] = useState(POSTS_STATUS.DRAFT);
  const [selectAll, setSelectAll] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [resource, setResource] = useState<string | null>(null);
  const [selectedAuthors, setSelectedAuthors] = useState<any[]>([]);
  const [categoriesIDs, setCategoriesIDs] = useState<string[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setParamType(searchParams.get('type')?.toUpperCase() ?? POSTS_STATUS.DRAFT);
  }, [searchParams]);

  useEffect(() => {
    setSearchInput('');
    setResource(null);
    setSelectedAuthors([]);
    setCategoriesIDs([]);
  }, [paramType]);

  const getAuthorsIDs = () => {
    return selectedAuthors.map((item: AuthorItem) => item.authorId);
  };

  const getAudioParam = () => {
    return resource == null ? undefined : resource === 'AUDIO';
  };

  const callApiToGetPendingReviews = (
    value: string,
    wantToCancel: boolean,
    cleanSearch = false,
  ): void => {
    const newCurrentPage =
      pendingReviewsCurrentPage > 0
        ? pendingReviewsCanLoadMoreData
          ? pendingReviewsCurrentPage
          : pendingReviewsCurrentPage + 1
        : 0;
    const auxValue = value.trim()?.length >= 2 ? value?.trim() : '';
    if (
      pendingReviewPostAdded ||
      pendingReviewPostUpdated ||
      auxValue?.length > 0 ||
      cleanSearch ||
      categoriesIDs?.length > 0 ||
      selectedAuthors?.length > 0 ||
      getAudioParam() !== undefined
    ) {
      getPosts(
        auxValue,
        [POSTS_STATUS.PENDING_REVIEW, POSTS_STATUS.REVIEW_IN_PROGRESS],
        PAGE_SIZE,
        0,
        'createdDate,DESC',
        categoriesIDs,
        getAuthorsIDs(),
        getAudioParam(),
        HTML_TEXT,
        wantToCancel,
      )
        .then((res) => {
          dispatch(
            setEntriesPendingReviews({
              pendingReviews: res.content,
            }),
          );
          dispatch(
            setPendingReviewQuantity({
              pendingReviewQuantity: res.totalElements,
            }),
          );
          if (!res.last) {
            dispatch(
              setEntriesPendingReviewsCurrentPage({
                currentPage: 1,
              }),
            );
            dispatch(
              setEntriesPendingReviewsCanLoadMoreData({
                canLoadMoreData: true,
              }),
            );
          } else {
            dispatch(
              setEntriesPendingReviewsCurrentPage({
                currentPage: 0,
              }),
            );
            dispatch(
              setEntriesPendingReviewsCanLoadMoreData({
                canLoadMoreData: false,
              }),
            );
          }
          dispatch(
            setPendingReviewPostAdded({
              pendingReviewPostAdded: false,
            }),
          );
          dispatch(
            setPendingReviewPostUpdated({
              pendingReviewPostUpdated: false,
            }),
          );
        })
        .catch((error: unknown) => {
          let errorStatus;
          if (error instanceof AxiosError) {
            errorStatus = error?.response?.status;
          }
          if (!axios.isCancel(error) && errorStatus !== 401) {
            dispatch(
              openSnackbar({
                type: 'error',
                message: HOME_ERRORS.PENDING_REVIEWS_LIST,
              }),
            );
          }
        });
    } else {
      getPosts(
        auxValue,
        [POSTS_STATUS.PENDING_REVIEW, POSTS_STATUS.REVIEW_IN_PROGRESS],
        PAGE_SIZE,
        newCurrentPage,
        'createdDate,DESC',
        categoriesIDs,
        getAuthorsIDs(),
        getAudioParam(),
        HTML_TEXT,
        wantToCancel,
      )
        .then((res) => {
          if (pendingReviews === null || newCurrentPage === 0) {
            dispatch(
              setEntriesPendingReviews({
                pendingReviews: res.content,
              }),
            );
          } else {
            dispatch(
              setEntriesPendingReviews({
                pendingReviews: [...pendingReviews, ...res.content],
              }),
            );
          }
          dispatch(
            setPendingReviewQuantity({
              pendingReviewQuantity: res.totalElements,
            }),
          );
          if (!res.last) {
            dispatch(
              setEntriesPendingReviewsCurrentPage({
                currentPage: newCurrentPage + 1,
              }),
            );
            dispatch(
              setEntriesPendingReviewsCanLoadMoreData({
                canLoadMoreData: true,
              }),
            );
          } else {
            dispatch(
              setEntriesPendingReviewsCanLoadMoreData({
                canLoadMoreData: false,
              }),
            );
          }
        })
        .catch((error: unknown) => {
          let errorStatus;
          if (error instanceof AxiosError) {
            errorStatus = error?.response?.status;
          }
          if (!axios.isCancel(error) && errorStatus !== 401) {
            dispatch(
              openSnackbar({
                type: 'error',
                message: HOME_ERRORS.PENDING_REVIEWS_LIST,
              }),
            );
          }
        });
    }
  };

  useEffect(() => {
    callApiToGetPendingReviews(searchInput, false);
  }, [categoriesIDs, selectedAuthors, resource]);

  const loadMoreData = async () => {
    if (
      pendingReviewsCanLoadMoreData &&
      !pendingReviewsIsLoadingMoreData &&
      pendingReviews !== null
    ) {
      dispatch(
        setEntriesPendingReviewsIsLoadingMoreData({
          isLoadingMoreData: true,
        }),
      );
      try {
        const response = await getPosts(
          searchInput || '',
          [POSTS_STATUS.PENDING_REVIEW, POSTS_STATUS.REVIEW_IN_PROGRESS],
          PAGE_SIZE,
          pendingReviewsCurrentPage,
          'createdDate,DESC',
          categoriesIDs,
          getAuthorsIDs(),
          getAudioParam(),
          HTML_TEXT,
        );
        const { last, content } = response;
        if (!last) {
          dispatch(
            setEntriesPendingReviewsCurrentPage({
              currentPage: pendingReviewsCurrentPage + 1,
            }),
          );
          dispatch(
            setEntriesPendingReviewsCanLoadMoreData({
              canLoadMoreData: true,
            }),
          );
        } else {
          dispatch(
            setEntriesPendingReviewsCanLoadMoreData({
              canLoadMoreData: false,
            }),
          );
        }
        dispatch(
          setEntriesPendingReviewsIsLoadingMoreData({
            isLoadingMoreData: false,
          }),
        );
        if (pendingReviews != null) {
          dispatch(
            setEntriesPendingReviews({
              pendingReviews: [...pendingReviews, ...content],
            }),
          );
        }
      } catch (error: unknown) {
        let errorStatus;
        if (error instanceof AxiosError) {
          errorStatus = error?.response?.status;
        }
        if (errorStatus !== 401) {
          dispatch(
            openSnackbar({
              type: 'error',
              message: HOME_ERRORS.PENDING_REVIEWS_LIST,
            }),
          );
        }
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
      const margin = 400;
      if (
        scrollTop + clientHeight + margin >= scrollHeight &&
        paramType.toUpperCase() === POSTS_STATUS.PENDING_REVIEW
      ) {
        loadMoreData();
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [
    pendingReviewsCurrentPage,
    pendingReviewsCanLoadMoreData,
    pendingReviewsIsLoadingMoreData,
    paramType,
    searchInput,
  ]);

  const handleInputChange = (value: string): void => {
    setSearchInput(value);
    const debounceSearch = customDebounce(callApiToGetPendingReviews, 500);
    if (value?.length >= 2) {
      debounceSearch(value, true);
    } else {
      debounceSearch(value, true, true);
    }
  };

  return (
    <Container>
      {paramType.toUpperCase() === POSTS_STATUS.PENDING_REVIEW && (
        <>
          <Filters
            selectAll={selectAll}
            setSelectAll={setSelectAll}
            resource={resource}
            setResource={setResource}
            authorsIDs={selectedAuthors}
            setAuthorsIDs={setSelectedAuthors}
            categoriesIDs={categoriesIDs}
            setCategoriesIDs={setCategoriesIDs}
            handleInputChange={handleInputChange}
          />
          <FiltersDrawer
            isOpen={isDrawerOpen}
            type={POSTS_STATUS.PENDING_REVIEW}
            resource={resource}
            setResource={setResource}
            authorsIDs={selectedAuthors}
            setAuthorsIDs={setSelectedAuthors}
            categoriesIDs={categoriesIDs}
            setCategoriesIDs={setCategoriesIDs}
          />
          <TitleWithFiltersButton
            title='Por revisar'
            quantity={pendingReviewQuantity}
            type={POSTS_STATUS.PENDING_REVIEW}
          />
          <SearchBar title={'Por revisar'} handleInputChange={handleInputChange} />
          {pendingReviews == null ? (
            <SkeletonsContainer>
              <PublicationSkeleton />
              <PublicationSkeleton />
              <PublicationSkeleton />
              <PublicationSkeleton />
              <PublicationSkeleton />
            </SkeletonsContainer>
          ) : pendingReviews?.length === 0 ? (
            <MessageContainer>
              {checkIfSomeFilterIsSelected(
                resource,
                selectedAuthors,
                categoriesIDs,
                searchInput,
              ) ? (
                <>
                  <div style={{ marginBottom: '15px' }}>
                    <NotFoundIcon />
                  </div>
                  <Message style={{ maxWidth: '280px' }}>
                    ¡Lo sentimos! No encontramos ningún artículo relacionado a tu búsqueda, prueba
                    con otra palabra.
                  </Message>
                </>
              ) : (
                <>
                  <div style={{ marginBottom: '15px' }}>
                    <PendingReviewPlaceholderIcon />
                  </div>
                  <Message>¡Hurra!</Message>
                  <Message>
                    No hay artículos pendientes de revisión. Cuando un escritor envíe su redacción,
                    aparecerá aquí.
                  </Message>
                </>
              )}
            </MessageContainer>
          ) : (
            <>
              {pendingReviews?.map((post, index) => (
                <PendingReviewItem
                  key={`${post.id}_${index}`}
                  {...post}
                  pendingReviews={pendingReviews}
                />
              ))}
              {pendingReviewsCanLoadMoreData && (
                <SkeletonsContainer>
                  <PublicationSkeleton />
                  <PublicationSkeleton />
                  <PublicationSkeleton />
                  <PublicationSkeleton />
                  <PublicationSkeleton />
                </SkeletonsContainer>
              )}
            </>
          )}
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  flex: 1;

  @media screen and (max-width: 860px) {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding-top: 100px;
  height: calc(100vh - 420px);
`;

const Message = styled.p`
  font-family: ${(props) => props.theme.fonts.medium};
  color: ${(props) => props.theme.colors.black};
  font-size: 16px;
  text-align: center;
  margin-bottom: 8px;
  max-width: 300px;
`;

const SkeletonsContainer = styled.div`
  padding-top: 15px;
  padding-left: 29px;
  padding-right: 20px;
  @media screen and (max-width: 860px) {
    padding: 15px 0px;
  }
`;
