import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import axios, { AxiosError } from 'axios';
import ProcessedItem from './ProcessedItem';
import POSTS_STATUS, { CAMPAIGNS_STATUS } from '../../constants/status';
import { PAGE_SIZE } from '../../constants/pagination';
import { openSnackbar } from '../../context/reducers/generalSnackbar';
import PublicationSkeleton from '../home/PublicationSkeleton';
import { type RootState } from '../../context/store';
import NotFoundIcon from '../../icons/NotFoundIcon';
import customDebounce from '../../utils/debounce';
import {
  setProcessedAdded,
  setProcessedDeleted,
  setProcessedQuantity,
} from '../../context/reducers/campaigns';
import {
  setProcessedCampaigns,
  setProcessedCampaignsCanLoadMoreData,
  setProcessedCampaignsCurrentPage,
  setProcessedCampaignsIsLoadingMoreData,
  setProcessedCampaignsTotal,
} from '../../context/reducers/processedCampaigns';
import SearchBarFilter from '../globals/SearchBarFilter';
import ScheduledCampaignIcon from '../../icons/ScheduledCampaignIcon';
import { getCampaigns } from '../../api/campaings';
import { CAMPAIGNS_ERRORS } from '../../constants/errorMessages';
import ProcessedListHeader from './ProcessedListHeader';
import TitleWithFiltersButton from '../globals/TitleWithFiltersButton';

export default function ProcessedList(): JSX.Element {
  const processedList = useSelector((state: RootState) => state.processedCampaigns.processed);
  const currentPage = useSelector((state: RootState) => state.processedCampaigns.currentPage);
  const isLoadingMoreData = useSelector(
    (state: RootState) => state.processedCampaigns.isLoadingMoreData,
  );
  const canLoadMoreData = useSelector(
    (state: RootState) => state.processedCampaigns.canLoadMoreData,
  );
  const processedAdded = useSelector((state: RootState) => state.campaigns.processedAdded);
  const processedDeleted = useSelector((state: RootState) => state.campaigns.processedDeleted);
  const processedQuantity = useSelector((state: RootState) => state.campaigns.processedQuantity);
  const [searchParams, setSearchParams] = useSearchParams();
  const [paramType, setParamType] = useState(CAMPAIGNS_STATUS.SCHEDULED);
  const [selectAll, setSelectAll] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const isMounted = useRef(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setParamType(searchParams.get('type')?.toUpperCase() ?? CAMPAIGNS_STATUS.SCHEDULED);
  }, [searchParams]);

  useEffect(() => {
    setSearchInput('');
  }, [paramType]);

  const callApiToGetProcessedList = (
    value: string,
    wantToCancel: boolean,
    cleanSearch = false,
  ): void => {
    const newCurrentPage = currentPage > 0 ? (canLoadMoreData ? currentPage : currentPage + 1) : 0;
    const auxValue = value.trim()?.length >= 2 ? value?.trim() : '';
    if (processedAdded || processedDeleted || auxValue?.length > 0 || cleanSearch) {
      getCampaigns(
        auxValue,
        [CAMPAIGNS_STATUS.PROCESSED],
        PAGE_SIZE,
        0,
        'scheduledAt,DESC',
        wantToCancel,
      )
        .then((res) => {
          isMounted.current = true;
          dispatch(
            setProcessedCampaigns({
              processed: res.content,
            }),
          );
          dispatch(
            setProcessedQuantity({
              processedQuantity: res.totalElements,
            }),
          );
          if (!res.last) {
            dispatch(
              setProcessedCampaignsCurrentPage({
                currentPage: 1,
              }),
            );
            dispatch(
              setProcessedCampaignsCanLoadMoreData({
                canLoadMoreData: true,
              }),
            );
          } else {
            dispatch(
              setProcessedCampaignsCurrentPage({
                currentPage: 0,
              }),
            );
            dispatch(
              setProcessedCampaignsCanLoadMoreData({
                canLoadMoreData: false,
              }),
            );
          }
          dispatch(
            setProcessedAdded({
              processedAdded: false,
            }),
          );
          dispatch(
            setProcessedDeleted({
              processedDeleted: false,
            }),
          );
        })
        .catch((error: unknown) => {
          let errorStatus;
          if (error instanceof AxiosError) {
            errorStatus = error?.response?.status;
          }
          if (!axios.isCancel(error) && errorStatus !== 401) {
            dispatch(
              openSnackbar({
                type: 'error',
                message: CAMPAIGNS_ERRORS.PROCESSED_LIST,
              }),
            );
          }
        });
    } else {
      getCampaigns(
        auxValue,
        [CAMPAIGNS_STATUS.PROCESSED],
        PAGE_SIZE,
        newCurrentPage,
        'scheduledAt,DESC',
        wantToCancel,
      )
        .then((res) => {
          isMounted.current = true;
          if (processedList === null || newCurrentPage === 0) {
            dispatch(
              setProcessedCampaigns({
                processed: res.content,
              }),
            );
          } else {
            dispatch(
              setProcessedCampaigns({
                processed: [...processedList, ...res.content],
              }),
            );
          }
          dispatch(
            setProcessedQuantity({
              processedQuantity: res.totalElements,
            }),
          );
          if (!res.last) {
            dispatch(
              setProcessedCampaignsCurrentPage({
                currentPage: newCurrentPage + 1,
              }),
            );
            dispatch(
              setProcessedCampaignsCanLoadMoreData({
                canLoadMoreData: true,
              }),
            );
          } else {
            dispatch(
              setProcessedCampaignsCanLoadMoreData({
                canLoadMoreData: false,
              }),
            );
          }
        })
        .catch((error: unknown) => {
          let errorStatus;
          if (error instanceof AxiosError) {
            errorStatus = error?.response?.status;
          }
          if (!axios.isCancel(error) && errorStatus !== 401) {
            dispatch(
              openSnackbar({
                type: 'error',
                message: CAMPAIGNS_ERRORS.PROCESSED_LIST,
              }),
            );
          }
        });
    }
  };

  useEffect(() => {
    callApiToGetProcessedList(searchInput, false);
  }, []);

  const loadMoreData = async () => {
    if (canLoadMoreData && !isLoadingMoreData && processedList !== null) {
      dispatch(
        setProcessedCampaignsIsLoadingMoreData({
          isLoadingMoreData: true,
        }),
      );
      try {
        const response = await getCampaigns(
          searchInput || '',
          [CAMPAIGNS_STATUS.PROCESSED],
          PAGE_SIZE,
          currentPage,
          'scheduledAt,DESC',
        );
        const { last, content } = response;
        if (!last) {
          dispatch(
            setProcessedCampaignsCurrentPage({
              currentPage: currentPage + 1,
            }),
          );
          dispatch(
            setProcessedCampaignsCanLoadMoreData({
              canLoadMoreData: true,
            }),
          );
        } else {
          dispatch(
            setProcessedCampaignsCanLoadMoreData({
              canLoadMoreData: false,
            }),
          );
        }
        dispatch(
          setProcessedCampaignsIsLoadingMoreData({
            isLoadingMoreData: false,
          }),
        );
        if (processedList != null) {
          dispatch(
            setProcessedCampaigns({
              processed: [...processedList, ...content],
            }),
          );
        }
      } catch (error: unknown) {
        let errorStatus;
        if (error instanceof AxiosError) {
          errorStatus = error?.response?.status;
        }
        if (errorStatus !== 401) {
          dispatch(
            openSnackbar({
              type: 'error',
              message: CAMPAIGNS_ERRORS.PROCESSED_LIST,
            }),
          );
        }
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
      const margin = 400;
      if (
        scrollTop + clientHeight + margin >= scrollHeight &&
        paramType.toUpperCase() === CAMPAIGNS_STATUS.PROCESSED
      ) {
        loadMoreData();
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [paramType, searchInput, currentPage, canLoadMoreData, isLoadingMoreData]);

  const handleInputChange = (value: string): void => {
    setSearchInput(value);
    const debounceSearch = customDebounce(callApiToGetProcessedList, 500);
    if (value?.length >= 2) {
      debounceSearch(value, true);
    } else {
      debounceSearch(value, true, true);
    }
  };

  const goPublishPosts = () => {
    navigate(`/entries?type=${POSTS_STATUS.PUBLISHED.toLowerCase()}`);
  };

  return (
    <Container>
      {paramType.toUpperCase() === CAMPAIGNS_STATUS.PROCESSED && (
        <>
          <TitleWithFiltersButtonContainer>
            <TitleWithFiltersButton
              title='Completadas'
              quantity={processedQuantity}
              type={CAMPAIGNS_STATUS.PROCESSED}
              page='campaigns'
            />
          </TitleWithFiltersButtonContainer>
          <SearchBarFilter searchInput={searchInput} handleInputChange={handleInputChange} />
          {processedList === null ? (
            <SkeletonsContainer>
              <PublicationSkeleton />
              <PublicationSkeleton />
              <PublicationSkeleton />
              <PublicationSkeleton />
              <PublicationSkeleton />
            </SkeletonsContainer>
          ) : processedList.length === 0 ? (
            <MessageContainer>
              {searchInput !== '' ? (
                <>
                  <div style={{ marginBottom: '15px' }}>
                    <NotFoundIcon />
                  </div>
                  <Message style={{ maxWidth: '280px' }}>
                    ¡Lo sentimos! No encontramos ninguna campaña completada relacionada a tu
                    búsqueda, prueba con otra palabra.
                  </Message>
                </>
              ) : (
                <>
                  <div style={{ marginBottom: '15px' }}>
                    <ScheduledCampaignIcon />
                  </div>
                  <Message>No existen campañas completadas</Message>
                  <Message>para ningún artículo. Puedes crear una notificación</Message>
                  <Message>desde un artículo ya publicado</Message>
                  <CustomButton className='bg-primary px-8 rounded-2xl' onClick={goPublishPosts}>
                    <ButtonText>Ver artículos publicados</ButtonText>
                  </CustomButton>
                </>
              )}
            </MessageContainer>
          ) : (
            <>
              <ProcessedListHeader />
              {processedList?.map((processedCampaign: any, index) => (
                <ProcessedItem
                  key={`${processedCampaign.id}`}
                  {...processedCampaign}
                  processedList={processedList}
                />
              ))}
              {canLoadMoreData && (
                <SkeletonsContainer>
                  <PublicationSkeleton />
                  <PublicationSkeleton />
                  <PublicationSkeleton />
                  <PublicationSkeleton />
                  <PublicationSkeleton />
                </SkeletonsContainer>
              )}
            </>
          )}
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  flex: 1;
`;

const TitleWithFiltersButtonContainer = styled.div`
  padding-left: 24px;
  padding-right: 24px;
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding-top: 100px;
  height: calc(100vh - 420px);
`;

const Message = styled.p`
  font-family: ${(props) => props.theme.fonts.medium};
  color: ${(props) => props.theme.colors.black};
  font-size: 16px;
  text-align: center;
  margin-bottom: 3px;
`;

const CustomButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.skyBlue};
  border-radius: 8px;
  margin-top: 20px;
`;

const ButtonText = styled.p`
  text-transform: none;
  font-family: ${(props) => props.theme.fonts.regular};
  color: white;
  font-size: 13px;
`;

const SkeletonsContainer = styled.div`
  padding-top: 15px;
  padding-left: 29px;
  padding-right: 20px;
  @media screen and (max-width: 860px) {
    padding: 15px 0px;
  }
`;
