import React from 'react';
import type Icon from '../interfaces/Icon';

export default function TagIcon({ width = 24, height = 24, color = '#183582' }: Icon) {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <g clipPath='url(#clip0_2717_3512)'>
        <path
          d='M10.4063 23.2383C10.4057 23.2383 10.405 23.2383 10.4044 23.2383C9.70026 23.2377 9.0382 22.9631 8.54052 22.4646L1.53069 15.4447C0.505394 14.4175 0.505394 12.7461 1.53069 11.719L10.98 2.25151C11.9538 1.27579 13.2495 0.738281 14.6283 0.738281H20.6234C22.0772 0.738281 23.2601 1.92096 23.2601 3.375V9.3517C23.2601 10.7295 22.7232 12.0247 21.7484 12.9983L12.2697 22.4672C11.7717 22.9644 11.1099 23.2383 10.4063 23.2383ZM14.6283 2.49609C13.7198 2.49609 12.866 2.85027 12.2242 3.49324L2.77476 12.9607C2.43295 13.3032 2.43295 13.8602 2.77476 14.2027L9.78458 21.2226C9.95034 21.3886 10.1709 21.4803 10.4057 21.4805H10.4064C10.641 21.4805 10.8615 21.3891 11.0274 21.2235L20.5061 11.7548C21.1486 11.1132 21.5023 10.2596 21.5023 9.3517V3.375C21.5023 2.89037 21.1081 2.49609 20.6234 2.49609H14.6283ZM16.8879 9.65918C15.434 9.65918 14.2512 8.4765 14.2512 7.02246C14.2512 5.56842 15.434 4.38574 16.8879 4.38574C18.3418 4.38574 19.5247 5.56842 19.5247 7.02246C19.5247 8.4765 18.3418 9.65918 16.8879 9.65918ZM16.8879 6.14355C16.4033 6.14355 16.009 6.53783 16.009 7.02246C16.009 7.5071 16.4033 7.90137 16.8879 7.90137C17.3727 7.90137 17.7668 7.5071 17.7668 7.02246C17.7668 6.53783 17.3727 6.14355 16.8879 6.14355Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_2717_3512'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
