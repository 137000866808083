import { createSlice } from '@reduxjs/toolkit';
import type ToolBarState from '../../interfaces/ToolBarState';

const initialState: ToolBarState = {
  isPreviewModeActive: false,
  isSettingDrawerOpen: false,
  isAudioDrawerOpen: false,
  isVideoDrawerOpen: false,
};

const toolBarSlice = createSlice({
  name: 'toolBar',
  initialState,
  reducers: {
    tooglePreviewMode: (state, action) => {
      state.isPreviewModeActive = action.payload.isPreviewModeActive;
    },
    toogleSettingDrawer: (state, action) => {
      state.isSettingDrawerOpen = action.payload.isSettingDrawerOpen;
    },
    toogleAudioDrawer: (state, action) => {
      state.isAudioDrawerOpen = action.payload.isAudioDrawerOpen;
    },
    toogleVideoDrawer: (state, action) => {
      state.isVideoDrawerOpen = action.payload.isVideoDrawerOpen;
    },
  },
});

export const { tooglePreviewMode, toogleSettingDrawer, toogleAudioDrawer, toogleVideoDrawer } =
  toolBarSlice.actions;

export default toolBarSlice.reducer;
