import { PublicClientApplication, type Configuration } from '@azure/msal-browser';

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_LOGIN_CLIENT_ID as string,
    authority: process.env.REACT_APP_LOGIN_AUTHORITY,
    redirectUri: window.location.origin,
    postLogoutRedirectUri: process.env.REACT_APP_LOGIN_POST_LOGOUT_REDIRECT_URI,
  },
  system: {
    pollIntervalMilliseconds: 0,
  },
};

export const pca = new PublicClientApplication(msalConfig);
