import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { useLocation, useNavigate } from 'react-router-dom';
import PresentationIcon from '../../icons/PresentationIcon';
import PublicationsIcon from '../../icons/PublicationsIcon';

const actions = [
  { icon: <PublicationsIcon color='white' />, name: 'Artículo', path: '/new-post' },
  { icon: <PresentationIcon color='white' />, name: 'PPT', path: '/new-presentation' },
];

export default function MobileHomeNewButton() {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = (path: string) => {
    setOpen(false);
    navigate(path, {
      state: {
        prevRouteName: location.pathname,
      },
    });
  };

  return (
    <Box>
      <Backdrop open={open} />
      <SpeedDial
        ariaLabel=''
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        FabProps={{
          sx: {
            bgcolor: '#033BCF',
            height: '65px',
            width: '65px',
            '&:hover': {
              bgcolor: '#033BCF',
            },
          },
        }}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={() => {
              handleClick(action.path);
            }}
            FabProps={{
              sx: {
                bgcolor: '#3C3C3B',
                height: '42px',
                width: '42px',
                '&:hover': {
                  bgcolor: '#3C3C3B',
                },
              },
            }}
          />
        ))}
      </SpeedDial>
    </Box>
  );
}
