import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { type RootState } from '../../context/store';
import { Skeleton } from '@mui/material';

export default function WelcomeMessage(): JSX.Element {
  const userProfile = useSelector((state: RootState) => state.userProfile.userProfile);

  const getFullName = () => {
    const fullName = userProfile?.fullName;
    return fullName ?? '';
  };

  return (
    <>
      {userProfile == null ? (
        <SkeletonContainer>
          <Skeleton
            variant='rectangular'
            style={{
              width: '50%',
              height: '50px',
              borderRadius: '8px',
            }}
          />
        </SkeletonContainer>
      ) : (
        <>
          <Title>
            Hola de nuevo, <Name>{getFullName()}</Name>
          </Title>
          <MobileTitle>
            ¡Hola <Name>{getFullName()}</Name>!
          </MobileTitle>
        </>
      )}
    </>
  );
}

const SkeletonContainer = styled.div`
  flex: 1;
`;

const Title = styled.p`
  font-size: 28px;
  font-family: ${(props) => props.theme.fonts.semiBold};
  color: ${(props) => props.theme.colors.primary};
  flex: 1;

  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const MobileTitle = styled.p`
  display: none;
  font-size: 28px;
  font-family: ${(props) => props.theme.fonts.semiBold};
  color: ${(props) => props.theme.colors.primary};
  flex: 1;
  @media screen and (max-width: 860px) {
    display: block;
  }
`;

const Name = styled.span`
  text-transform: capitalize;
`;
