import React, { createRef, useRef } from 'react';
import { createSlice } from '@reduxjs/toolkit';
import { EditorState } from 'draft-js';
import HeaderImage from '../../components/editor/HeaderImage';
import type Editor from '../../interfaces/Editor';
import { type ElementContent, type EditorElement } from '../../interfaces/Editor';
import CustomDraftWysiwyg from '../../components/editor/CustomDraftWysiwyg';
import KeyIdeas from '../../components/editor/KeyIdeas';
import {
  EDITOR_NOTES,
  HEADER_IMAGE,
  KEY_IDEAS,
  KEY_PARAGRAPH,
  PDF_URL,
  POST_THUMBNAIL,
  WYSIWYG_MAIN_SUBTITLE,
  WYSIWYG_MAIN_TITLE,
} from '../../constants/editorKeys';
import PreviewCustomDraftWysiwyg from '../../components/editor/PreviewCustomDraftWysiwyg';
import PreviewKeyIdeas from '../../components/editor/PreviewKeyIdeas';
import EditorInput from '../../components/editor/EditorInput';
import HeaderImagePreviewMode from '../../components/previewMode/HeaderImagePreviewMode';

const elements: EditorElement[] = [
  {
    key: 'header-image',
    element: <HeaderImage />,
  },
  {
    key: WYSIWYG_MAIN_TITLE,
    element: (
      <EditorInput
        customKey={WYSIWYG_MAIN_TITLE}
        placeholder='Escribe un título...'
        fontSize='40px'
        color='#0F1F41'
        fontFamily='Arial'
        fontWeight={700}
        maximumOfCharacters={100}
        marginTop={10}
        placeholderColor='#0F1F41'
      />
    ),
  },
  {
    key: WYSIWYG_MAIN_SUBTITLE,
    element: (
      <EditorInput
        customKey={WYSIWYG_MAIN_SUBTITLE}
        placeholder='Escribe un subtítulo...'
        fontSize='20px'
        color='#0F1F41'
        fontFamily='Arial'
        fontWeight={700}
        marginTop={10}
        maximumOfCharacters={200}
        placeholderColor='#0F1F41'
      />
    ),
  },
  {
    key: 'audio',
    element: <div></div>,
  },
  {
    key: 'video',
    element: <div></div>,
  },
  {
    key: 'keys-ideas',
    element: <KeyIdeas />,
  },
  {
    key: KEY_PARAGRAPH,
    element: (
      <CustomDraftWysiwyg
        customKey={KEY_PARAGRAPH}
        placeholder='Escribe aquí...'
        fontSize='18px'
        color='#3C3C3B'
        fontFamily='Arial'
      />
    ),
  },
];

const previewElements: EditorElement[] = [
  {
    key: 'header-image',
    element: <></>,
  },
  {
    key: WYSIWYG_MAIN_TITLE,
    element: (
      <PreviewCustomDraftWysiwyg
        isVisible={false}
        customKey={WYSIWYG_MAIN_TITLE}
        placeholder='Escribe un título...'
        fontSize='20px'
        color='#6E1242'
        fontFamily='Georgia'
        fontWeight={400}
        maximumOfCharacters={100}
      />
    ),
  },
  {
    key: WYSIWYG_MAIN_SUBTITLE,
    element: (
      <PreviewCustomDraftWysiwyg
        customKey={WYSIWYG_MAIN_SUBTITLE}
        placeholder='Escribe un subtítulo...'
        fontSize='18px'
        color='#3C3C3B'
        fontFamily='Figtree'
        fontWeight={600}
        marginTop={-80}
      />
    ),
  },
  {
    key: 'audio',
    element: <div></div>,
  },
  {
    key: 'video',
    element: <div></div>,
  },
  {
    key: 'keys-ideas',
    element: <PreviewKeyIdeas />,
  },
  {
    key: KEY_PARAGRAPH,
    element: (
      <PreviewCustomDraftWysiwyg
        customKey={KEY_PARAGRAPH}
        placeholder='Escribe aquí...'
        fontSize='18px'
        color='#3C3C3B'
        fontFamily='Figtree'
        fontWeight={400}
      />
    ),
  },
];

const elementsContent: ElementContent[] = [
  {
    customKey: WYSIWYG_MAIN_TITLE,
    content: null,
  },
  {
    customKey: WYSIWYG_MAIN_SUBTITLE,
    content: null,
  },
  {
    customKey: KEY_IDEAS,
    content: new Array(3).fill(EditorState.createEmpty()),
  },
  {
    customKey: KEY_PARAGRAPH,
    content: null,
  },
  {
    customKey: HEADER_IMAGE,
    content: null,
  },
  {
    customKey: EDITOR_NOTES,
    content: null,
  },
  {
    customKey: POST_THUMBNAIL,
    content: null,
  },
  {
    customKey: PDF_URL,
    content: null,
  },
];

const initialState: Editor = {
  elements,
  elementsContent,
  previewElements,
  isThereAnyFocusedEditor: false,
  currentSelectedEditorState: EditorState.createEmpty(),
  editSomeStyleOfCurrentSelectedEditor: false,
  selectedEditorId: null,
  selectedEditorKey: null,
  addedNewElement: false,
  postInformation: null,
  isCustomLinkModalVisible: false,
  wysiwygCurrentSelectionHeight: 0,
  currentResizableImageURL: null,
  isSecondaryToolBarVisible: false,
  currentSelectedLink: {
    title: '',
    url: '',
  },
  unsavedChanges: false,
  currentPostStatus: null,
  blockKeyToDelete: null,
  wantToReloadPostPage: false,
  isMobileSettingsDrawerOpen: false,
  isAddNewElementDrawerOpen: false,
  isMobileAudioDrawerOpen: false,
  isMobileVideoDrawerOpen: false,
  wantToDownloadPDF: false,
};

const editorSlice = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    addElement: (state, action) => {
      state.elements = [...state.elements, action.payload.newElement];
    },
    setElements: (state, action) => {
      state.elements = action.payload.elements;
    },
    setPreviewElements: (state, action) => {
      state.previewElements = action.payload.previewElements;
    },
    focusAnEditor: (state, action) => {
      state.isThereAnyFocusedEditor = true;
    },
    blurAnEditor: (state, action) => {
      state.isThereAnyFocusedEditor = false;
    },
    setCurrentSelectedEditorState: (state, action) => {
      state.currentSelectedEditorState = action.payload.currentSelectedEditorState;
    },
    toogleEditSomeStyleOfCurrentSelectedEditor: (state, action) => {
      state.editSomeStyleOfCurrentSelectedEditor = !state.editSomeStyleOfCurrentSelectedEditor;
    },
    setSelectedEditorId: (state, action) => {
      state.selectedEditorId = action.payload.selectedEditorId;
    },
    setSelectedEditorKey: (state, action) => {
      state.selectedEditorKey = action.payload.selectedEditorKey;
    },
    toogleAddedNewElement: (state, action) => {
      state.addedNewElement = !state.addedNewElement;
    },
    setAddedNewElement: (state, action) => {
      state.addedNewElement = action.payload.addedNewElement;
    },
    setEmptyElementsContent: (state, action) => {
      state.elementsContent = elementsContent;
      state.elements[state.elements.findIndex((item) => item.key === 'video')].element = (
        <div></div>
      );
      state.elements[state.elements.findIndex((item) => item.key === 'audio')].element = (
        <div></div>
      );
      state.previewElements[
        state.previewElements.findIndex((item) => item.key === 'video')
      ].element = <div></div>;
      state.previewElements[
        state.previewElements.findIndex((item) => item.key === 'audio')
      ].element = <div></div>;
      state.postInformation = null;
    },
    setElementsContent: (state, action) => {
      const customKey = action.payload.customKey;
      const index = state.elementsContent.findIndex((item) => item.customKey === customKey);
      if (index === -1) return;
      const auxElementsContent = [...state.elementsContent];
      auxElementsContent[index] = {
        customKey,
        content: action.payload.content,
      };
      state.elementsContent = auxElementsContent;
    },
    setPostInformationInEditor: (state, action) => {
      state.postInformation = action.payload.postInformation;
    },
    toggleIsCustomLinkModalVisible: (state, action) => {
      state.isCustomLinkModalVisible = action.payload.isCustomLinkModalVisible;
    },
    setWysiwygCurrentSelectionHeight: (state, action) => {
      state.wysiwygCurrentSelectionHeight = action.payload.wysiwygCurrentSelectionHeight;
    },
    setCurrentResizableImageURL: (state, action) => {
      state.currentResizableImageURL = action.payload.currentResizableImageURL;
    },
    toogleIsSecondaryToolBarVisible: (state, action) => {
      state.isSecondaryToolBarVisible = action.payload.isSecondaryToolBarVisible;
    },
    setCurrentSelectedLink: (state, action) => {
      state.currentSelectedLink = {
        url: action.payload.url,
        title: action.payload.title,
      };
    },
    setUnsavedChanges: (state, action) => {
      state.unsavedChanges = action.payload.unsavedChanges;
    },
    setWantToDownloadPDF: (state, action) => {
      state.wantToDownloadPDF = action.payload.wantToDownloadPDF;
    },
    setCurrentPostStatus: (state, action) => {
      state.currentPostStatus = action.payload.currentPostStatus;
    },
    setBlockKeyToDelete: (state, action) => {
      state.blockKeyToDelete = action.payload.blockKeyToDelete;
    },
    setWantToReloadPostPage: (state, action) => {
      state.wantToReloadPostPage = action.payload.wantToReloadPostPage;
    },
    setIsEditorMobileSettingsDrawerOpen: (state, action) => {
      state.isMobileSettingsDrawerOpen = action.payload.isDrawerOpen;
    },
    setIsAddNewElementDrawerOpen: (state, action) => {
      state.isAddNewElementDrawerOpen = action.payload.isDrawerOpen;
    },
    setIsMobileAudioDrawerOpen: (state, action) => {
      state.isMobileAudioDrawerOpen = action.payload.isDrawerOpen;
    },
    setIsMobileVideoDrawerOpen: (state, action) => {
      state.isMobileVideoDrawerOpen = action.payload.isDrawerOpen;
    },
  },
});

export const {
  addElement,
  setElements,
  focusAnEditor,
  blurAnEditor,
  setCurrentSelectedEditorState,
  toogleEditSomeStyleOfCurrentSelectedEditor,
  setSelectedEditorId,
  toogleAddedNewElement,
  setAddedNewElement,
  setElementsContent,
  setEmptyElementsContent,
  setSelectedEditorKey,
  setPostInformationInEditor,
  toggleIsCustomLinkModalVisible,
  setWysiwygCurrentSelectionHeight,
  setCurrentResizableImageURL,
  toogleIsSecondaryToolBarVisible,
  setCurrentSelectedLink,
  setPreviewElements,
  setUnsavedChanges,
  setCurrentPostStatus,
  setBlockKeyToDelete,
  setWantToReloadPostPage,
  setIsEditorMobileSettingsDrawerOpen,
  setIsAddNewElementDrawerOpen,
  setIsMobileAudioDrawerOpen,
  setIsMobileVideoDrawerOpen,
  setWantToDownloadPDF,
} = editorSlice.actions;

export default editorSlice.reducer;
