import React from 'react';
import type Icon from '../interfaces/Icon';

export default function PublicationIcon({ color = '#9A9A9A' }: Icon): JSX.Element {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2.16667 15.5C1.70833 15.5 1.31597 15.3368 0.989583 15.0104C0.663194 14.684 0.5 14.2917 0.5 13.8333V2.16667C0.5 1.70833 0.663194 1.31597 0.989583 0.989583C1.31597 0.663194 1.70833 0.5 2.16667 0.5H11.3333L15.5 4.66667V13.8333C15.5 14.2917 15.3368 14.684 15.0104 15.0104C14.684 15.3368 14.2917 15.5 13.8333 15.5H2.16667ZM2.16667 13.8333H13.8333V5.5H10.5V2.16667H2.16667V13.8333ZM3.83333 12.1667H12.1667V10.5H3.83333V12.1667ZM3.83333 5.5H8V3.83333H3.83333V5.5ZM3.83333 8.83333H12.1667V7.16667H3.83333V8.83333Z'
        fill={color}
      />
    </svg>
  );
}
