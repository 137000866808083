import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import styled from 'styled-components';

export default function PublicationSkeleton(): JSX.Element {
  return (
    <Container>
      <Skeleton variant='rounded' width={72} height={72} />
      <Skeleton variant='rectangular' style={{ flex: 1, height: '100%', borderRadius: '8px' }} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 13px;
  margin-bottom: 10px;
  height: 94px;
`;
