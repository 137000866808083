import React from 'react';
import type Icon from '../interfaces/Icon';

export default function BlockquoteIcon({ color = '#3C3C3B' }: Icon): JSX.Element {
  return (
    <svg width='14' height='13' viewBox='0 0 14 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.48619 0L6.29034 1.13366C5.26367 1.85627 4.36776 2.74868 3.64112 3.7725C3.09979 4.54742 2.80195 5.46622 2.7857 6.41135V6.70049C2.99192 6.67322 3.18068 6.64922 3.35198 6.62849C3.51269 6.60833 3.67446 6.59777 3.83643 6.59685C4.17217 6.58702 4.50636 6.64604 4.81843 6.77026C5.13049 6.89449 5.41381 7.08129 5.65094 7.31917C5.88743 7.55301 6.07352 7.8328 6.19774 8.1413C6.32197 8.44981 6.3817 8.78048 6.37326 9.11295C6.3922 9.4965 6.3345 9.87999 6.20355 10.241C6.0726 10.602 5.87101 10.9333 5.61057 11.2155C5.34644 11.4791 5.03044 11.6849 4.68259 11.82C4.33474 11.955 3.96263 12.0163 3.58984 12C3.142 12.0096 2.69796 11.9159 2.29211 11.7264C1.88627 11.5368 1.5295 11.2563 1.24944 10.9067C0.615306 10.1133 0.289396 9.11738 0.331798 8.10257C0.329402 6.54355 0.780944 5.01755 1.6313 3.71086C2.63694 2.21316 3.95131 0.947908 5.48619 0ZM12.6797 0L13.4828 1.13366C12.4561 1.85627 11.5602 2.74868 10.8335 3.7725C10.2922 4.54742 9.99437 5.46622 9.97812 6.41135V6.70049C10.1702 6.67322 10.3524 6.64922 10.5248 6.62849C10.6924 6.60784 10.8611 6.59727 11.0299 6.59685C11.3635 6.5837 11.6961 6.64017 12.0067 6.76265C12.3172 6.88514 12.5988 7.07096 12.8335 7.30828C13.0633 7.54774 13.2428 7.8308 13.3615 8.14076C13.4801 8.45071 13.5354 8.78128 13.5242 9.11297C13.5632 9.88415 13.2966 10.6396 12.7823 11.2155C12.5231 11.4799 12.2109 11.6865 11.8662 11.8217C11.5215 11.9569 11.1521 12.0177 10.7823 12C10.3317 12.008 9.88514 11.9138 9.47619 11.7244C9.06724 11.5351 8.70653 11.2555 8.42115 10.9067C7.77497 10.1188 7.441 9.12075 7.48276 8.10257C7.48797 6.55941 7.94298 5.05125 8.79209 3.76269C9.81158 2.25124 11.1358 0.969602 12.6797 0Z'
        fill={color}
      />
    </svg>
  );
}
