import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import axios, { AxiosError } from 'axios';
import Filters from '../../components/entries/Filters';
import PublishedItem from './PublishedItem';
import ListingIcon from '../../icons/ListingIcon';
import POSTS_STATUS from '../../constants/status';
import { getPosts } from '../../api/posts';
import { PAGE_SIZE } from '../../constants/pagination';
import { HOME_ERRORS } from '../../constants/errorMessages';
import { openSnackbar } from '../../context/reducers/generalSnackbar';
import PublicationSkeleton from '../home/PublicationSkeleton';
import type PublicationInterface from '../../interfaces/Publication';
import { type RootState } from '../../context/store';
import PublishedPlaceholderIcon from '../../icons/PublishedPlaceholderIcon';
import NotFoundIcon from '../../icons/NotFoundIcon';
import { type AuthorItem } from '../../interfaces/AuthorDetails';
import { checkIfSomeFilterIsSelected } from '../../utils/entries';
import customDebounce from '../../utils/debounce';
import { useSearchParams } from 'react-router-dom';
import {
  setPublishedPostAdded,
  setPublishedPostUpdated,
  setPublishedQuantity,
} from '../../context/reducers/entries';
import {
  setEntriesPublished,
  setEntriesPublishedCanLoadMoreData,
  setEntriesPublishedCurrentPage,
  setEntriesPublishedIsLoadingMoreData,
} from '../../context/reducers/entriesPublished';
import TitleWithFiltersButton from '../globals/TitleWithFiltersButton';
import FiltersDrawer from './FiltersDrawer';
import SearchBar from './filtersDrawer/SearchBar';
import { HTML_TEXT } from '../../constants/globals';

export default function PublishedList(): JSX.Element {
  const publications = useSelector((state: RootState) => state.entriesPublished.publications);
  const publicationsCurrentPage = useSelector(
    (state: RootState) => state.entriesPublished.currentPage,
  );
  const publicationsIsLoadingMoreData = useSelector(
    (state: RootState) => state.entriesPublished.isLoadingMoreData,
  );
  const publicationsCanLoadMoreData = useSelector(
    (state: RootState) => state.entriesPublished.canLoadMoreData,
  );
  const publishedPostUpdated = useSelector(
    (state: RootState) => state.entries.publishedPostUpdated,
  );
  const publishedPostAdded = useSelector((state: RootState) => state.entries.publishedPostAdded);
  const publishedQuantity = useSelector((state: RootState) => state.entries.publishedQuantity);
  const isDrawerOpen = useSelector((state: RootState) => state.entriesPublished.isDrawerOpen);
  const [searchParams, setSearchParams] = useSearchParams();
  const [paramType, setParamType] = useState(POSTS_STATUS.DRAFT);
  const [selectAll, setSelectAll] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [resource, setResource] = useState<string | null>(null);
  const [selectedAuthors, setSelectedAuthors] = useState<any[]>([]);
  const [categoriesIDs, setCategoriesIDs] = useState<string[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setParamType(searchParams.get('type')?.toUpperCase() ?? POSTS_STATUS.DRAFT);
  }, [searchParams]);

  useEffect(() => {
    setSearchInput('');
    setResource(null);
    setSelectedAuthors([]);
    setCategoriesIDs([]);
  }, [paramType]);

  const getAuthorsIDs = () => {
    return selectedAuthors.map((item: AuthorItem) => item.authorId);
  };

  const getAudioParam = () => {
    return resource == null ? undefined : resource === 'AUDIO';
  };

  const callApiToGetPublications = (
    value: string,
    wantToCancel: boolean,
    cleanSearch = false,
  ): void => {
    const newCurrentPage =
      publicationsCurrentPage > 0
        ? publicationsCanLoadMoreData
          ? publicationsCurrentPage
          : publicationsCurrentPage + 1
        : 0;
    const auxValue = value.trim()?.length >= 2 ? value?.trim() : '';
    if (
      publishedPostAdded ||
      publishedPostUpdated ||
      auxValue?.length > 0 ||
      cleanSearch ||
      categoriesIDs?.length > 0 ||
      selectedAuthors?.length > 0 ||
      getAudioParam() !== undefined
    ) {
      getPosts(
        auxValue,
        [POSTS_STATUS.PUBLISHED],
        PAGE_SIZE,
        0,
        'lastPublicationDate,DESC',
        categoriesIDs,
        getAuthorsIDs(),
        getAudioParam(),
        HTML_TEXT,
        wantToCancel,
      )
        .then((res) => {
          dispatch(
            setEntriesPublished({
              publications: res.content,
            }),
          );
          dispatch(
            setPublishedQuantity({
              publishedQuantity: res.totalElements,
            }),
          );
          if (!res.last) {
            dispatch(
              setEntriesPublishedCurrentPage({
                currentPage: 1,
              }),
            );
            dispatch(
              setEntriesPublishedCanLoadMoreData({
                canLoadMoreData: true,
              }),
            );
          } else {
            dispatch(
              setEntriesPublishedCurrentPage({
                currentPage: 0,
              }),
            );
            dispatch(
              setEntriesPublishedCanLoadMoreData({
                canLoadMoreData: false,
              }),
            );
          }
          dispatch(
            setPublishedPostAdded({
              publishedPostAdded: false,
            }),
          );
          dispatch(
            setPublishedPostUpdated({
              publishedPostUpdated: false,
            }),
          );
        })
        .catch((error: unknown) => {
          let errorStatus;
          if (error instanceof AxiosError) {
            errorStatus = error?.response?.status;
          }
          if (!axios.isCancel(error) && errorStatus !== 401) {
            dispatch(
              openSnackbar({
                type: 'error',
                message: HOME_ERRORS.PUBLICATIONS_LIST,
              }),
            );
          }
        });
    } else {
      getPosts(
        auxValue,
        [POSTS_STATUS.PUBLISHED],
        PAGE_SIZE,
        newCurrentPage,
        'lastPublicationDate,DESC',
        categoriesIDs,
        getAuthorsIDs(),
        getAudioParam(),
        HTML_TEXT,
        wantToCancel,
      )
        .then((res) => {
          if (publications === null || newCurrentPage === 0) {
            dispatch(
              setEntriesPublished({
                publications: res.content,
              }),
            );
          } else {
            dispatch(
              setEntriesPublished({
                publications: [...publications, ...res.content],
              }),
            );
          }
          dispatch(
            setPublishedQuantity({
              publishedQuantity: res.totalElements,
            }),
          );
          if (!res.last) {
            dispatch(
              setEntriesPublishedCurrentPage({
                currentPage: newCurrentPage + 1,
              }),
            );
            dispatch(
              setEntriesPublishedCanLoadMoreData({
                canLoadMoreData: true,
              }),
            );
          } else {
            dispatch(
              setEntriesPublishedCanLoadMoreData({
                canLoadMoreData: false,
              }),
            );
          }
        })
        .catch((error: unknown) => {
          let errorStatus;
          if (error instanceof AxiosError) {
            errorStatus = error?.response?.status;
          }
          if (!axios.isCancel(error) && errorStatus !== 401) {
            dispatch(
              openSnackbar({
                type: 'error',
                message: HOME_ERRORS.PUBLICATIONS_LIST,
              }),
            );
          }
        });
    }
  };

  useEffect(() => {
    callApiToGetPublications(searchInput, false);
  }, [categoriesIDs, selectedAuthors, resource]);

  const loadMoreData = async () => {
    if (publicationsCanLoadMoreData && !publicationsIsLoadingMoreData && publications !== null) {
      dispatch(
        setEntriesPublishedIsLoadingMoreData({
          isLoadingMoreData: true,
        }),
      );
      try {
        const response = await getPosts(
          searchInput || '',
          [POSTS_STATUS.PUBLISHED],
          PAGE_SIZE,
          publicationsCurrentPage,
          'lastPublicationDate,DESC',
          categoriesIDs,
          getAuthorsIDs(),
          getAudioParam(),
          HTML_TEXT,
        );
        const { last, content } = response;
        if (!last) {
          dispatch(
            setEntriesPublishedCurrentPage({
              currentPage: publicationsCurrentPage + 1,
            }),
          );
          dispatch(
            setEntriesPublishedCanLoadMoreData({
              canLoadMoreData: true,
            }),
          );
        } else {
          dispatch(
            setEntriesPublishedCanLoadMoreData({
              canLoadMoreData: false,
            }),
          );
        }
        dispatch(
          setEntriesPublishedIsLoadingMoreData({
            isLoadingMoreData: false,
          }),
        );
        if (publications != null) {
          dispatch(
            setEntriesPublished({
              publications: [...publications, ...content],
            }),
          );
        }
      } catch (error: unknown) {
        let errorStatus;
        if (error instanceof AxiosError) {
          errorStatus = error?.response?.status;
        }
        if (errorStatus !== 401) {
          dispatch(
            openSnackbar({
              type: 'error',
              message: HOME_ERRORS.PUBLICATIONS_LIST,
            }),
          );
        }
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
      const margin = 400;
      if (
        scrollTop + clientHeight + margin >= scrollHeight &&
        paramType.toUpperCase() === POSTS_STATUS.PUBLISHED
      ) {
        loadMoreData();
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [
    publicationsCurrentPage,
    publicationsCanLoadMoreData,
    publicationsIsLoadingMoreData,
    paramType,
    searchInput,
  ]);

  const handleInputChange = (value: string): void => {
    setSearchInput(value);
    const debounceSearch = customDebounce(callApiToGetPublications, 500);
    if (value?.length >= 2) {
      debounceSearch(value, true);
    } else {
      debounceSearch(value, true, true);
    }
  };

  return (
    <Container>
      {paramType.toUpperCase() === POSTS_STATUS.PUBLISHED && (
        <>
          <Filters
            selectAll={selectAll}
            setSelectAll={setSelectAll}
            resource={resource}
            setResource={setResource}
            authorsIDs={selectedAuthors}
            setAuthorsIDs={setSelectedAuthors}
            categoriesIDs={categoriesIDs}
            setCategoriesIDs={setCategoriesIDs}
            handleInputChange={handleInputChange}
          />
          <FiltersDrawer
            isOpen={isDrawerOpen}
            type={POSTS_STATUS.PUBLISHED}
            resource={resource}
            setResource={setResource}
            authorsIDs={selectedAuthors}
            setAuthorsIDs={setSelectedAuthors}
            categoriesIDs={categoriesIDs}
            setCategoriesIDs={setCategoriesIDs}
          />
          <TitleWithFiltersButton
            title='Publicadas'
            quantity={publishedQuantity}
            type={POSTS_STATUS.PUBLISHED}
          />
          <SearchBar title={'Publicados'} handleInputChange={handleInputChange} />
          {publications === null ? (
            <SkeletonsContainer style={{ paddingTop: '15px' }}>
              <PublicationSkeleton />
              <PublicationSkeleton />
              <PublicationSkeleton />
              <PublicationSkeleton />
              <PublicationSkeleton />
            </SkeletonsContainer>
          ) : publications?.length === 0 ? (
            <MessageContainer>
              {checkIfSomeFilterIsSelected(
                resource,
                selectedAuthors,
                categoriesIDs,
                searchInput,
              ) ? (
                <>
                  <div style={{ marginBottom: '15px' }}>
                    <NotFoundIcon />
                  </div>
                  <Message style={{ maxWidth: '280px' }}>
                    ¡Lo sentimos! No encontramos ningún artículo relacionado a tu búsqueda, prueba
                    con otra palabra.
                  </Message>
                </>
              ) : (
                <>
                  <div style={{ marginBottom: '15px' }}>
                    <PublishedPlaceholderIcon />
                  </div>
                  <Message>
                    Aquí se visualizarán todos los artículos publicados una vez validados por un
                    editor.
                  </Message>
                </>
              )}
            </MessageContainer>
          ) : (
            <>
              {publications?.map((publication, index) => (
                <PublishedItem
                  key={`${publication.id}_${index}`}
                  {...publication}
                  postPublicationId={publication?.postPublicationId}
                  publications={publications}
                />
              ))}
              {publicationsCanLoadMoreData && (
                <SkeletonsContainer>
                  <PublicationSkeleton />
                  <PublicationSkeleton />
                  <PublicationSkeleton />
                  <PublicationSkeleton />
                  <PublicationSkeleton />
                </SkeletonsContainer>
              )}
            </>
          )}
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  padding-bottom: 80px;
  flex: 1;

  @media screen and (max-width: 860px) {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding-top: 100px;
  height: calc(100vh - 420px);
`;

const Message = styled.p`
  font-family: ${(props) => props.theme.fonts.medium};
  color: ${(props) => props.theme.colors.black};
  font-size: 16px;
  text-align: center;
  margin-bottom: 8px;
  max-width: 250px;
`;

const SkeletonsContainer = styled.div`
  padding-top: 15px;
  padding-left: 29px;
  padding-right: 20px;
`;
