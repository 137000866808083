import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import axios, { AxiosError } from 'axios';
import ScheduledItem from './ScheduledItem';
import POSTS_STATUS, { CAMPAIGNS_STATUS } from '../../constants/status';
import { PAGE_SIZE } from '../../constants/pagination';
import { openSnackbar } from '../../context/reducers/generalSnackbar';
import PublicationSkeleton from '../home/PublicationSkeleton';
import { type RootState } from '../../context/store';
import NotFoundIcon from '../../icons/NotFoundIcon';
import customDebounce from '../../utils/debounce';
import {
  setScheduledAdded,
  setScheduledDeleted,
  setScheduledQuantity,
} from '../../context/reducers/campaigns';
import {
  setScheduledCampaigns,
  setScheduledCampaignsCanLoadMoreData,
  setScheduledCampaignsCurrentPage,
  setScheduledCampaignsIsLoadingMoreData,
  setScheduledCampaignsTotal,
} from '../../context/reducers/scheduledCampaigns';
import SearchBarFilter from '../globals/SearchBarFilter';
import ScheduledCampaignIcon from '../../icons/ScheduledCampaignIcon';
import { deleteCampaigns, getCampaigns } from '../../api/campaings';
import { CAMPAIGNS_ERRORS } from '../../constants/errorMessages';
import ScheduledListHeader from './ScheduledListHeader';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import { CAMPAINGS_SUCCESFUL_MESSAGES } from '../../constants/successfulMessages';
import TitleWithFiltersButton from '../globals/TitleWithFiltersButton';
import ProcessedItem from './ProcessedItem';

export default function ScheduledList(): JSX.Element {
  const scheduledList = useSelector((state: RootState) => state.scheduledCampaigns.scheduled);
  const currentPage = useSelector((state: RootState) => state.scheduledCampaigns.currentPage);
  const isLoadingMoreData = useSelector(
    (state: RootState) => state.scheduledCampaigns.isLoadingMoreData,
  );
  const canLoadMoreData = useSelector(
    (state: RootState) => state.scheduledCampaigns.canLoadMoreData,
  );
  const scheduledAdded = useSelector((state: RootState) => state.campaigns.scheduledAdded);
  const scheduledDeleted = useSelector((state: RootState) => state.campaigns.scheduledDeleted);
  const scheduledQuantity = useSelector((state: RootState) => state.campaigns.scheduledQuantity);
  const [searchParams, setSearchParams] = useSearchParams();
  const [paramType, setParamType] = useState(CAMPAIGNS_STATUS.SCHEDULED);
  const [selectAll, setSelectAll] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [selectedAll, setSelectedAll] = useState(false);
  const [selectedCampaigns, setSelectedCampaigns] = useState<number[]>([]);
  const [confirmDeleteModalIsVisible, setConfirmDeleteModalIsVisible] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deletedCampaign, setDeletedCampaign] = useState(false);
  const [isDeletingMultiple, setIsDeletingMultiple] = useState(false);
  const isMounted = useRef(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setParamType(searchParams.get('type')?.toUpperCase() ?? CAMPAIGNS_STATUS.SCHEDULED);
  }, [searchParams]);

  useEffect(() => {
    setSearchInput('');
  }, [paramType]);

  const callApiToGetScheduledList = (
    value: string,
    wantToCancel: boolean,
    cleanSearch = false,
  ): void => {
    const newCurrentPage = currentPage > 0 ? (canLoadMoreData ? currentPage : currentPage + 1) : 0;
    const auxValue = value.trim()?.length >= 2 ? value?.trim() : '';
    if (scheduledAdded || scheduledDeleted || auxValue?.length > 0 || cleanSearch) {
      getCampaigns(
        auxValue,
        [CAMPAIGNS_STATUS.SCHEDULED, CAMPAIGNS_STATUS.IN_PROGRESS, CAMPAIGNS_STATUS.ERROR],
        PAGE_SIZE,
        0,
        'scheduledAt,ASC',
        wantToCancel,
      )
        .then((res) => {
          isMounted.current = true;
          dispatch(
            setScheduledCampaigns({
              scheduled: res.content,
            }),
          );
          dispatch(
            setScheduledQuantity({
              scheduledQuantity: res.totalElements,
            }),
          );
          if (!res.last) {
            dispatch(
              setScheduledCampaignsCurrentPage({
                currentPage: 1,
              }),
            );
            dispatch(
              setScheduledCampaignsCanLoadMoreData({
                canLoadMoreData: true,
              }),
            );
          } else {
            dispatch(
              setScheduledCampaignsCurrentPage({
                currentPage: 0,
              }),
            );
            dispatch(
              setScheduledCampaignsCanLoadMoreData({
                canLoadMoreData: false,
              }),
            );
          }
          dispatch(
            setScheduledAdded({
              scheduledAdded: false,
            }),
          );
          dispatch(
            setScheduledDeleted({
              scheduledDeleted: false,
            }),
          );
        })
        .catch((error: unknown) => {
          let errorStatus;
          if (error instanceof AxiosError) {
            errorStatus = error?.response?.status;
          }
          if (!axios.isCancel(error) && errorStatus !== 401) {
            dispatch(
              openSnackbar({
                type: 'error',
                message: CAMPAIGNS_ERRORS.SCHEDULED_LIST,
              }),
            );
          }
        });
    } else {
      getCampaigns(
        auxValue,
        [CAMPAIGNS_STATUS.SCHEDULED, CAMPAIGNS_STATUS.IN_PROGRESS, CAMPAIGNS_STATUS.ERROR],
        PAGE_SIZE,
        newCurrentPage,
        'scheduledAt,ASC',
        wantToCancel,
      )
        .then((res) => {
          isMounted.current = true;
          if (scheduledList === null || newCurrentPage === 0) {
            dispatch(
              setScheduledCampaigns({
                scheduled: res.content,
              }),
            );
          } else {
            dispatch(
              setScheduledCampaigns({
                scheduled: [...scheduledList, ...res.content],
              }),
            );
          }
          dispatch(
            setScheduledQuantity({
              scheduledQuantity: res.totalElements,
            }),
          );
          if (!res.last) {
            dispatch(
              setScheduledCampaignsCurrentPage({
                currentPage: newCurrentPage + 1,
              }),
            );
            dispatch(
              setScheduledCampaignsCanLoadMoreData({
                canLoadMoreData: true,
              }),
            );
          } else {
            dispatch(
              setScheduledCampaignsCanLoadMoreData({
                canLoadMoreData: false,
              }),
            );
          }
        })
        .catch((error: unknown) => {
          let errorStatus;
          if (error instanceof AxiosError) {
            errorStatus = error?.response?.status;
          }
          if (!axios.isCancel(error) && errorStatus !== 401) {
            dispatch(
              openSnackbar({
                type: 'error',
                message: CAMPAIGNS_ERRORS.SCHEDULED_LIST,
              }),
            );
          }
        });
    }
  };

  useEffect(() => {
    callApiToGetScheduledList(searchInput, false);
  }, []);

  const loadMoreData = async () => {
    if (canLoadMoreData && !isLoadingMoreData && scheduledList !== null) {
      dispatch(
        setScheduledCampaignsIsLoadingMoreData({
          isLoadingMoreData: true,
        }),
      );
      try {
        const response = await getCampaigns(
          searchInput || '',
          [CAMPAIGNS_STATUS.SCHEDULED, CAMPAIGNS_STATUS.IN_PROGRESS, CAMPAIGNS_STATUS.ERROR],
          PAGE_SIZE,
          currentPage,
          'scheduledAt,ASC',
        );
        const { last, content } = response;
        if (!last) {
          dispatch(
            setScheduledCampaignsCurrentPage({
              currentPage: currentPage + 1,
            }),
          );
          dispatch(
            setScheduledCampaignsCanLoadMoreData({
              canLoadMoreData: true,
            }),
          );
        } else {
          dispatch(
            setScheduledCampaignsCanLoadMoreData({
              canLoadMoreData: false,
            }),
          );
        }
        dispatch(
          setScheduledCampaignsIsLoadingMoreData({
            isLoadingMoreData: false,
          }),
        );
        if (scheduledList != null) {
          dispatch(
            setScheduledCampaigns({
              scheduled: [...scheduledList, ...content],
            }),
          );
        }
      } catch (error: unknown) {
        let errorStatus;
        if (error instanceof AxiosError) {
          errorStatus = error?.response?.status;
        }
        if (errorStatus !== 401) {
          dispatch(
            openSnackbar({
              type: 'error',
              message: CAMPAIGNS_ERRORS.SCHEDULED_LIST,
            }),
          );
        }
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
      const margin = 500;
      if (
        scrollTop + clientHeight + margin >= scrollHeight &&
        paramType.toUpperCase() === CAMPAIGNS_STATUS.SCHEDULED
      ) {
        loadMoreData();
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [paramType, searchInput, currentPage, canLoadMoreData, isLoadingMoreData]);

  useEffect(() => {
    setSelectedCampaigns([]);
  }, [searchInput, deletedCampaign]);

  const handleInputChange = (value: string): void => {
    setSearchInput(value);
    const debounceSearch = customDebounce(callApiToGetScheduledList, 500);
    if (value?.length >= 2) {
      debounceSearch(value, true);
    } else {
      debounceSearch(value, true, true);
    }
  };

  const goPublishPosts = () => {
    navigate(`/entries?type=${POSTS_STATUS.PUBLISHED.toLowerCase()}`);
  };

  const showConfirmMultipleEliminationModal = () => {
    setConfirmDeleteModalIsVisible(true);
  };

  const confirmDeleteCampaign = async () => {
    if (isDeletingMultiple || selectedCampaigns?.length === 0) return;
    try {
      setConfirmDeleteModalIsVisible(false);
      setIsDeletingMultiple(true);
      await deleteCampaigns(selectedCampaigns);
      dispatch(
        setScheduledCampaigns({
          scheduled: scheduledList?.filter((item) => !selectedCampaigns.includes(item.id)),
        }),
      );
      if (scheduledQuantity !== undefined) {
        dispatch(
          setScheduledQuantity({
            scheduledQuantity:
              scheduledQuantity >= selectedCampaigns?.length
                ? scheduledQuantity - selectedCampaigns?.length
                : scheduledQuantity,
          }),
        );
      }
      dispatch(
        openSnackbar({
          type: 'success',
          message:
            selectedCampaigns.length > 1
              ? CAMPAINGS_SUCCESFUL_MESSAGES.MULTIPLE_DELETE_CAMPAIGN
              : CAMPAINGS_SUCCESFUL_MESSAGES.DELETE_CAMPAIGN,
        }),
      );
      setSelectedCampaigns([]);
    } catch (error: unknown) {
      let errorStatus;
      if (error instanceof AxiosError) {
        errorStatus = error?.response?.status;
      }
      if (!axios.isCancel(error) && errorStatus !== 401) {
        dispatch(
          openSnackbar({
            type: 'error',
            message: CAMPAIGNS_ERRORS.MULTIPLE_DELETE,
          }),
        );
      }
    } finally {
      setIsDeletingMultiple(false);
    }
  };

  return (
    <Container>
      {paramType.toUpperCase() === CAMPAIGNS_STATUS.SCHEDULED && (
        <>
          <TitleWithFiltersButtonContainer>
            <TitleWithFiltersButton
              title='Programadas'
              quantity={scheduledQuantity}
              type={CAMPAIGNS_STATUS.SCHEDULED}
              page='campaigns'
            />
          </TitleWithFiltersButtonContainer>
          <SearchBarFilter
            searchInput={searchInput}
            handleInputChange={handleInputChange}
            selectedCampaigns={selectedCampaigns}
            page='campaigns'
            showModal={showConfirmMultipleEliminationModal}
            loading={isDeletingMultiple}
          />
          {scheduledList === null ? (
            <SkeletonsContainer>
              <PublicationSkeleton />
              <PublicationSkeleton />
              <PublicationSkeleton />
              <PublicationSkeleton />
              <PublicationSkeleton />
            </SkeletonsContainer>
          ) : scheduledList.length === 0 ? (
            <MessageContainer>
              {searchInput !== '' ? (
                <>
                  <div style={{ marginBottom: '15px' }}>
                    <NotFoundIcon />
                  </div>
                  <Message style={{ maxWidth: '280px' }}>
                    ¡Lo sentimos! No encontramos ninguna campaña programada relacionada a tu
                    búsqueda, prueba con otra palabra.
                  </Message>
                </>
              ) : (
                <>
                  <div style={{ marginBottom: '15px' }}>
                    <ScheduledCampaignIcon />
                  </div>
                  <Message>No existen campañas programadas</Message>
                  <Message>para ningún artículo. Puedes hacerlo desde</Message>
                  <Message>un artículo ya publicaco</Message>
                  <CustomButton className='bg-primary px-8 rounded-2xl' onClick={goPublishPosts}>
                    <ButtonText>Ver artículos publicados</ButtonText>
                  </CustomButton>
                </>
              )}
            </MessageContainer>
          ) : (
            <>
              <ScheduledListHeader
                selectedAll={selectedAll}
                setSelectedAll={setSelectedAll}
                list={scheduledList}
                deleting={isDeletingMultiple}
                selectedCampains={selectedCampaigns}
                setSelectedCampaigns={setSelectedCampaigns}
              />
              {scheduledList?.map((scheduledCampaign: any, index) => (
                <>
                  <ScheduledItem
                    key={`${scheduledCampaign.id}`}
                    {...scheduledCampaign}
                    campaigns={scheduledList}
                    campaignsQuantity={scheduledQuantity}
                    selectedCampaigns={selectedCampaigns}
                    setSelectedCampaigns={setSelectedCampaigns}
                    deletedCampaign={deletedCampaign}
                    setDeletedCampaign={setDeletedCampaign}
                    isDeletingMultiple={isDeletingMultiple}
                  />
                </>
              ))}
              {canLoadMoreData && (
                <SkeletonsContainer>
                  <PublicationSkeleton />
                  <PublicationSkeleton />
                  <PublicationSkeleton />
                  <PublicationSkeleton />
                  <PublicationSkeleton />
                </SkeletonsContainer>
              )}
            </>
          )}
        </>
      )}
      <ConfirmDeleteModal
        isVisible={confirmDeleteModalIsVisible}
        setIsVisible={setConfirmDeleteModalIsVisible}
        deleting={deleting}
        setDeleting={setDeleting}
        confirmDeleteCampaign={confirmDeleteCampaign}
        title={
          selectedCampaigns?.length > 1
            ? `¿Eliminar ${selectedCampaigns?.length} campañas?`
            : '¿Eliminar campaña seleccionada?'
        }
        description={
          selectedCampaigns?.length > 1
            ? `Se eliminarán las ${selectedCampaigns?.length} campañas seleccionadas masivamente sin poder recuperarse.`
            : 'Se eliminará la campaña seleccionada sin poder recuperarse.'
        }
      />
    </Container>
  );
}

const Container = styled.div`
  flex: 1;
`;

const TitleWithFiltersButtonContainer = styled.div`
  padding-left: 24px;
  padding-right: 24px;
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding-top: 100px;
  height: calc(100vh - 420px);
`;

const Message = styled.p`
  font-family: ${(props) => props.theme.fonts.medium};
  color: ${(props) => props.theme.colors.black};
  font-size: 16px;
  text-align: center;
  margin-bottom: 3px;
`;

const CustomButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.skyBlue};
  border-radius: 8px;
  margin-top: 20px;
`;

const ButtonText = styled.p`
  text-transform: none;
  font-family: ${(props) => props.theme.fonts.regular};
  color: white;
  font-size: 13px;
`;

const SkeletonsContainer = styled.div`
  padding-top: 15px;
  padding-left: 29px;
  padding-right: 20px;
  @media screen and (max-width: 860px) {
    padding: 15px 0px;
  }
`;
