import React from 'react';
import { Button, IconButton } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Add } from '@mui/icons-material';
import { AiOutlinePlus } from 'react-icons/ai';
import PlusIcon from '../../icons/PlusIcon';
import { useDispatch } from 'react-redux';
import { setTagsResetValues } from '../../context/reducers/tags';
import { setAuthorsResetValues } from '../../context/reducers/authors';

interface Props {
  type?: 'publication' | 'tag' | 'author' | 'survey' | 'presentation';
  setModalIsVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  searchInput?: string;
  setSearchInput?: React.Dispatch<React.SetStateAction<string>>;
  handleInputChange?: (n: string) => void;
  callApiToResetValues?: () => void;
}

export default function MobileNewPublicationButton({
  type = 'publication',
  setModalIsVisible,
  searchInput = '',
  setSearchInput,
  callApiToResetValues,
}: Props) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = () => {
    if (type === 'tag' && setModalIsVisible !== undefined) {
      if (searchInput?.length >= 2) {
        dispatch(setTagsResetValues({}));
        if (setSearchInput !== undefined) {
          setSearchInput('');
        }
        if (callApiToResetValues !== undefined) {
          callApiToResetValues();
        }
      }
      setModalIsVisible(true);
    } else if (type === 'author') {
      if (searchInput?.length >= 2) {
        dispatch(setAuthorsResetValues({}));
      }
      navigate('/authors/new-author');
    } else if (type === 'publication') {
      navigate('/new-post', {
        state: {
          prevRouteName: location.pathname,
        },
      });
    } else if (type === 'presentation') {
      navigate('/new-presentation', {
        state: {
          prevRouteName: location.pathname,
        },
      });
    }
  };

  return (
    <CustomButton onClick={handleClick}>
      <PlusIcon />
    </CustomButton>
  );
}

const CustomButton = styled.div`
  cursor: pointer;
  display: none;
  position: fixed;
  z-index: 999;
  bottom: 30px;
  right: 8px;
  background-color: ${(props) => props.theme.colors.accentBlue};
  margin-right: 13px;
  height: 75px;
  width: 75px;
  border-radius: 100%;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);

  @media screen and (max-width: 860px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`;

const Title = styled.p``;
