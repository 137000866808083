import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import type DraftInterface from '../../interfaces/Draft';
import type PendingReviewInterface from '../../interfaces/PendingReview';
import type PublicationInterface from '../../interfaces/Publication';
import DraftsList from './DraftsList';
import PendingReviewList from './PendingReviewList';
import PublishedList from './PublishedList';
import { type AuthorItem } from '../../interfaces/AuthorDetails';
import { RootState } from '../../context/store';
import POSTS_STATUS from '../../constants/status';

export default function PresentationsList(): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams();
  const [paramType, setParamType] = useState(POSTS_STATUS.DRAFT);

  useEffect(() => {
    setParamType(searchParams.get('type')?.toUpperCase() ?? POSTS_STATUS.DRAFT);
  }, [searchParams]);

  return (
    <Container>
      {paramType === POSTS_STATUS.DRAFT ? (
        <DraftsList />
      ) : paramType === POSTS_STATUS.PENDING_REVIEW ? (
        <PendingReviewList />
      ) : (
        <PublishedList />
      )}
    </Container>
  );
}

const Container = styled.ul`
  padding-bottom: 80px;
  flex: 1;
`;
