/* eslint-disable @typescript-eslint/promise-function-async */
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { CircularProgress, Skeleton, Tooltip } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { useDispatch, useSelector } from 'react-redux';
import TrashIcon from '../../icons/TrashIcon';
import MovieIcon from '../../icons/MovieIcon';
import AudioIcon from '../../icons/AudioIcon';
import PublicationIcon from '../../icons/PublicationIcon';
import InformationIcon from '../../icons/InformationIcon';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import WithoutImageIcon from '../../icons/WithoutImageIcon';
import type PendingReviewInterface from '../../interfaces/PendingReview';
import getAvatar from '../../utils/getAvatar';
import { openSnackbar } from '../../context/reducers/generalSnackbar';
import { POST_ERRORS } from '../../constants/errorMessages';
import { publishPost } from '../../api/posts';
import POSTS_STATUS from '../../constants/status';
import { type RootState } from '../../context/store';
import capitalizeFirstLetter from '../../utils/capitalizeFirstLetter';
import {
  setPendingReviewQuantity,
  setPostPublished,
  setPublishedPostAdded,
} from '../../context/reducers/entries';
import { setPendingReviewQuantity as setPresentationsPendingReviewQuantity } from '../../context/reducers/presentations';
import { AxiosError } from 'axios';
import { deleteEntry } from '../../utils/entries';
import { checkIfUserHasRole } from '../../utils/checkIfUserHasRole';
import { ROLE_EDITOR, ROLE_WRITER } from '../../constants/roles';
import ConfirmPublicationModal from '../globals/ConfirmPublicationModal';
import ConfirmForceDeleteModal from './ConfirmForceDeleteModal';
import { setEntriesPendingReviews } from '../../context/reducers/entriesPendingReviews';
import { getLastPublicationDate } from '../../utils/getLastPublicationDate';
import BlockIcon from '../../icons/BlockIcon';
import { FILE_TYPE, HTML_TEXT } from '../../constants/globals';
import PDFIcon from '../../icons/PDFIcon';
import { ARTICLE_SUCCESFUL_MESSAGES } from '../../constants/successfulMessages';
import { ARTCILE_MESSAGES, PRESENTATION_MESSAGES } from '../../constants/generalMessages';
import { setPresentationsPendingReviews } from '../../context/reducers/presentationsPendingReview';

interface Props extends PendingReviewInterface {
  pendingReviews: PendingReviewInterface[] | null;
}

interface ContainerI {
  blocked: boolean;
}

interface ButtonsContainerI {
  pendingReview: boolean;
}

export default function PendingReviewItem({
  id,
  title,
  images,
  categories,
  writer,
  editor,
  lastModifiedDate,
  lastModifiedBy,
  createdDate,
  hasAudio,
  status,
  pendingReviews,
  contentType,
}: Props): JSX.Element {
  const pendingReviewQuantity = useSelector(
    (state: RootState) => state.entries.pendingReviewQuantity,
  );
  const presentationsPendingReviewQuantity = useSelector(
    (state: RootState) => state.presentations.pendingReviewQuantity,
  );
  const userProfile = useSelector((state: RootState) => state.userProfile.userProfile);
  const pendingReviewPostDeleted = useSelector(
    (state: RootState) => state.entries.pendingReviewPostDeleted,
  );
  const postPublished = useSelector((state: RootState) => state.entries.postPublished);
  const categoriesList = useSelector((state: RootState) => state.categories.categoriesList);
  const [publishing, setPublishing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [checked, setChecked] = useState(false);
  const [confirmDeleteModalIsVisible, setConfirmDeleteModalIsVisible] = useState(false);
  const [confirmPublicationModalIsVisible, setConfirmPublicationModalIsVisible] = useState(false);
  const [confirmForceDeleteModalIsVisible, setConfirmForceDeleteModallIsVisible] = useState(false);
  const [errorDeleteMessage, setErrorDeleteMessage] = useState('');
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pathBase = `${contentType === HTML_TEXT ? '/post/' : '/presentation/'}`;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setChecked(event.target.checked);
  };

  const onClickTrashBtn = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setConfirmDeleteModalIsVisible(true);
  };

  const getTitle = () => {
    if (!title) return 'Sin título';
    return title;
  };

  const getCategories = (): any[] => {
    if (categories === null || categories?.length === 0) {
      return [];
    }
    if (categoriesList !== null) {
      const categoriesNames: any[] = [];
      const auxCategoriesList: any[] = [...categoriesList];
      categories?.forEach((category) => {
        auxCategoriesList?.forEach((auxCategory) => {
          if (category === auxCategory?.id) {
            categoriesNames.push({
              name: auxCategory?.name,
              color: auxCategory?.color,
            });
          }
        });
      });
      return categoriesNames;
    }
    return [];
  };

  const navigateToReviewerPage = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    navigate(`${pathBase}${id}`, {
      state: { prevRouteName: location.pathname },
    });
  };

  const callApiToPublishAPost = async (overwritePriorPublication = false) => {
    if (!publishing) {
      try {
        setPublishing(true);
        await publishPost(id, overwritePriorPublication);
        setConfirmPublicationModalIsVisible(false);
        if (pendingReviews !== null && pendingReviews !== undefined) {
          if (contentType === HTML_TEXT) {
            dispatch(
              setEntriesPendingReviews({
                pendingReviews: pendingReviews.filter((item) => item.id !== id),
              }),
            );
          } else {
            dispatch(
              setPresentationsPendingReviews({
                pendingReviews: pendingReviews.filter((item) => item.id !== id),
              }),
            );
          }
        }
        if (contentType === HTML_TEXT) {
          if (pendingReviewQuantity !== null && pendingReviewQuantity !== undefined) {
            dispatch(
              setPendingReviewQuantity({
                pendingReviewQuantity: pendingReviewQuantity - 1,
              }),
            );
          }
        } else if (
          contentType === FILE_TYPE &&
          presentationsPendingReviewQuantity !== null &&
          presentationsPendingReviewQuantity !== undefined
        ) {
          dispatch(
            setPresentationsPendingReviewQuantity({
              pendingReviewQuantity: presentationsPendingReviewQuantity - 1,
            }),
          );
        }

        dispatch(
          openSnackbar({
            type: 'success',
            message:
              contentType === HTML_TEXT
                ? ARTICLE_SUCCESFUL_MESSAGES.PUBLISH_ARTICLE
                : ARTICLE_SUCCESFUL_MESSAGES.PUBLISH_PRESENTATION,
          }),
        );
        dispatch(
          setPostPublished({
            postPublished: !postPublished,
          }),
        );
        dispatch(
          setPublishedPostAdded({
            publishedPostAdded: true,
          }),
        );
        setPublishing(false);
      } catch (error: unknown) {
        setPublishing(false);
        let errorMessage = POST_ERRORS.PUBLISH;
        let errorStatus;
        if (error instanceof AxiosError) {
          const errorData = error?.response?.data;
          errorStatus = error?.response?.status;
          if (errorData?.message) {
            errorMessage = errorData?.message;
          }
        }
        if (errorStatus === 428) {
          setConfirmPublicationModalIsVisible(true);
        } else if (errorStatus !== 401) {
          dispatch(
            openSnackbar({
              type: 'error',
              message: errorMessage,
            }),
          );
          setConfirmPublicationModalIsVisible(false);
        }
      }
    }
  };

  const handlePublishPost = async (event: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    event.preventDefault();
    if (!publishing && status !== POSTS_STATUS.PENDING_REVIEW) {
      callApiToPublishAPost(false);
    }
  };

  const getStatusLabel = () => {
    return status === POSTS_STATUS.PENDING_REVIEW
      ? ARTCILE_MESSAGES.PENDING_REVIEW
      : contentType === HTML_TEXT
      ? ARTCILE_MESSAGES.IN_REVIEW
      : PRESENTATION_MESSAGES.IN_REVIEW;
  };

  const confirmDeletePost = async (forceDelete: boolean): Promise<void> => {
    try {
      await deleteEntry(
        setConfirmDeleteModalIsVisible,
        deleting,
        setDeleting,
        id,
        dispatch,
        POSTS_STATUS.PENDING_REVIEW,
        false,
        pendingReviewPostDeleted,
        false,
        'entries',
        setConfirmForceDeleteModallIsVisible,
        undefined,
        pendingReviews,
        undefined,
        pendingReviewQuantity,
        undefined,
        forceDelete,
        setErrorDeleteMessage,
        contentType,
      );
      setDeleting(false);
    } catch (err) {}
  };

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (
      !checkIfUserHasRole(userProfile?.roles, ROLE_EDITOR) &&
      (status === POSTS_STATUS.PENDING_REVIEW || status === POSTS_STATUS.REVIEW_IN_PROGRESS)
    ) {
      event.preventDefault();
    }
  };

  const checkIfUserIsTheEditorOfThePost = (): boolean => {
    if (editor !== null) {
      const username = editor?.username;
      if (username == null) return true;
      if (username === userProfile?.username) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  };

  return (
    <>
      <Container
        to={`${pathBase}${id}`}
        state={{ prevRouteName: location.pathname }}
        onClick={handleClick}
        blocked={
          !checkIfUserHasRole(userProfile?.roles, ROLE_EDITOR) &&
          (status === POSTS_STATUS.PENDING_REVIEW || status === POSTS_STATUS.REVIEW_IN_PROGRESS)
        }
      >
        <Row>
          <AuxDateContainer>
            <DateContainer>
              <DateText>{getLastPublicationDate(createdDate)}</DateText>
            </DateContainer>
            <MobileIconsContainer>
              {contentType === HTML_TEXT ? (
                <>
                  <PublicationIcon />
                  {hasAudio && <AudioIcon />}
                </>
              ) : (
                <PDFIcon />
              )}
            </MobileIconsContainer>
          </AuxDateContainer>
          <ImageContainer>
            {images?.principalImage ? (
              <Image src={images?.principalImage} alt={title} />
            ) : (
              <WithoutImageIcon />
            )}
          </ImageContainer>
          <InfoContainer>
            <MainContainer>
              <Title>{capitalizeFirstLetter(getTitle())}</Title>
              {categoriesList == null ? (
                <Skeleton
                  variant='rectangular'
                  style={{
                    width: '50px',
                    height: '10px',
                    borderRadius: '8px',
                    marginTop: '3px',
                    marginBottom: '3px',
                  }}
                />
              ) : (
                <Categories>
                  {getCategories()?.length === 0 ? (
                    <Category style={{ color: '#183582' }}>Sin categorías</Category>
                  ) : (
                    getCategories().map((category, index) => (
                      <CategoryContainer style={{ backgroundColor: category?.color }} key={index}>
                        <Category key={index} style={{ color: 'white' }}>
                          {category.name}
                        </Category>
                      </CategoryContainer>
                    ))
                  )}
                </Categories>
              )}
              <MainContainerFooter>
                <InformationIcon color='#033BCF' />
                <Status>{getStatusLabel()}</Status>
              </MainContainerFooter>
            </MainContainer>
            <IconsContainer>
              {contentType === HTML_TEXT ? (
                <>
                  <PublicationIcon />
                  {hasAudio && <AudioIcon />}
                </>
              ) : (
                <PDFIcon />
              )}
            </IconsContainer>
            <AuthorContainer>
              <AvatarContainer>
                <Avatar>{getAvatar(writer?.names)}</Avatar>
              </AvatarContainer>
              <AuthorName>{writer?.names}</AuthorName>
            </AuthorContainer>
          </InfoContainer>
          <ButtonsContainer pendingReview={status === POSTS_STATUS.PENDING_REVIEW}>
            <CustomButton
              disabled={publishing || !checkIfUserHasRole(userProfile?.roles, ROLE_EDITOR)}
              className='bg-transparent px-5 rounded-2xl'
              onClick={navigateToReviewerPage}
            >
              <ButtonText className='text-primary'>Revisar</ButtonText>
            </CustomButton>
            {status === POSTS_STATUS.REVIEW_IN_PROGRESS && (
              <CustomButton
                disabled={
                  publishing ||
                  status === POSTS_STATUS.PENDING_REVIEW ||
                  !checkIfUserHasRole(userProfile?.roles, ROLE_EDITOR)
                }
                className='bg-primary-blue px-5 rounded-2xl'
                onClick={handlePublishPost}
              >
                {publishing ? (
                  <CircularProgress size={20} style={{ color: 'white' }} />
                ) : (
                  <ButtonText>Publicar</ButtonText>
                )}
              </CustomButton>
            )}
            <IconButton
              onClick={onClickTrashBtn}
              disabled={publishing || !checkIfUserHasRole(userProfile?.roles, ROLE_EDITOR)}
            >
              <TrashIcon
                color={
                  checkIfUserHasRole(userProfile?.roles, ROLE_EDITOR)
                    ? '#CD4F4F'
                    : 'rgba(0, 0, 0, 0.26)'
                }
              />
            </IconButton>
          </ButtonsContainer>
        </Row>
        <MobileContainerFooter>
          <MobileCategories>
            {getCategories().map((category, index) => (
              <CategoryContainer style={{ backgroundColor: category?.color }} key={index}>
                <Category key={index} style={{ color: 'white' }}>
                  {category.name}
                </Category>
              </CategoryContainer>
            ))}
          </MobileCategories>
          <Footer>
            <StatusContainer>
              {status === POSTS_STATUS.PENDING_REVIEW ? (
                <InformationIcon color='#1D7D54' />
              ) : status === POSTS_STATUS.REVIEW_IN_PROGRESS ? (
                <BlockIcon color='#CD4F4F' />
              ) : (
                <></>
              )}
              <Status className='ml-2'>{getStatusLabel()}</Status>
            </StatusContainer>
            <Button
              onClick={navigateToReviewerPage}
              className='bg-primary-blue px-8 py-2 rounded-2xl mr-2'
            >
              <ButtonText>Editar</ButtonText>
            </Button>
          </Footer>
        </MobileContainerFooter>
      </Container>
      <ConfirmDeleteModal
        title={contentType === HTML_TEXT ? '¿Eliminar artículo?' : '¿Eliminar PPT?'}
        message={`¿Seguro que quieres eliminar ${
          contentType === HTML_TEXT ? 'este artículo' : 'este PPT'
        }? Recuerda que esta acción no es reversible. `}
        isVisible={confirmDeleteModalIsVisible}
        setIsVisible={setConfirmDeleteModalIsVisible}
        deleting={deleting}
        setDeleting={setDeleting}
        confirmDeletePost={() => confirmDeletePost(false)}
      />
      <ConfirmForceDeleteModal
        isVisible={confirmForceDeleteModalIsVisible}
        setIsVisible={setConfirmForceDeleteModallIsVisible}
        message={errorDeleteMessage}
        deleting={deleting}
        setDeleting={setDeleting}
        confirmDeletePost={() => confirmDeletePost(true)}
      />
      <ConfirmPublicationModal
        isVisible={confirmPublicationModalIsVisible}
        setIsVisible={setConfirmPublicationModalIsVisible}
        publishing={publishing}
        setPublishing={setPublishing}
        callApiToPublishAPost={callApiToPublishAPost}
        contentType={contentType}
      />
    </>
  );
}

const Container = styled(Link)<ContainerI>`
  position: relative;
  cursor: ${(props) => (props.blocked ? 'not-allowed' : 'pointer')};
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid;
  border-bottom-color: ${(props) => props.theme.colors.gray2};
  height: 97px;
  padding-right: 20px;
  padding-left: 20px;
  transition: background-color 0.1s ease-out;

  :hover {
    background-color: rgba(0, 0, 0, 0.09);
  }

  @media screen and (max-width: 860px) {
    display: block;
    height: inherit;
    padding-top: 15px;
    border: 1px solid;
    border-color: ${(props) => props.theme.colors.lightSkyBlue};
    border-radius: 8px;
    margin-bottom: 8px;
    padding-right: 10px;
  }
`;

const Row = styled.div`
  height: 97px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media screen and (max-width: 860px) {
    height: inherit;
    align-items: flex-start;
  }
`;

const AuxDateContainer = styled.div`
  display: none;
  position: absolute;
  left: 0px;
  z-index: 100;
  top: 9px;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  @media screen and (max-width: 860px) {
    display: flex;
  }
`;

const MobileIconsContainer = styled.div`
  display: none;
  @media screen and (max-width: 860px) {
    display: inline-flex;
    gap: 4px;
  }
`;

const DateContainer = styled.div`
  background-color: ${(props) => props.theme.colors.primary};
  min-width: 94px;
  padding: 1px 8px;
  border-radius: 0px 6px 6px 0px;
`;

const DateText = styled.p`
  font-family: ${(props) => props.theme.fonts.semiBold};
  color: white;
  font-size: 14px;
`;

const ImageContainer = styled.div`
  position: relative;
  height: 72px;
  width: 72px;
  border-radius: 10px;
  overflow: hidden;
  background-color: ${(props) => props.theme.colors.lightSkyBlue};
  margin-left: 9px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 860px) {
    height: 90px;
    margin-left: 0px;
  }
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const InfoContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  @media screen and (max-width: 860px) {
    padding-top: 15px;
  }
`;

const MainContainer = styled.div`
  padding-left: 12px;
  flex: 1;
`;

const Title = styled.p`
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.darkGray};
  font-size: 16px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;

  @media screen and (max-width: 860px) {
    -webkit-line-clamp: 3;
    line-clamp: 3;
  }
`;

const Categories = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  margin-bottom: 3px;

  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const Category = styled.span`
  font-family: ${(props) => props.theme.fonts.medium};
  color: ${(props) => props.theme.colors.skyBlue};
  font-size: 13px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const MainContainerFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;

  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const Status = styled.p`
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.accentBlue};
  font-size: 13px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;

  @media screen and (max-width: 860px) {
    color: black;
    font-family: ${(props) => props.theme.fonts.medium};
  }
`;

const Date = styled.p`
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.gray2};
  font-size: 13px;
`;

const AuthorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 25px;

  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const AvatarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  border-radius: 100%;
  background-color: ${(props) => props.theme.colors.accentBlue};
`;

const Avatar = styled.p`
  color: white;
  font-family: ${(props) => props.theme.fonts.regular};
  font-size: 13px;
  text-transform: uppercase;
`;

const AuthorName = styled.p`
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.darkGray};
  margin-left: 12px;
  max-width: 150px;
  text-transform: capitalize;
`;

const ButtonsContainer = styled.div<ButtonsContainerI>`
  display: grid;
  grid-template-columns: 90px 90px 40px;
  justify-content: flex-end;
  gap: 18px;
  ${(props) =>
    props.pendingReview &&
    css`
      grid-template-columns: 198px 40px;
    `}

  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const CustomButton = styled(Button)`
  &&& {
    border: 1px solid;
    border-color: ${(props) => props.theme.colors.primary};
    height: 40px;
    min-width: 90px;
  }
`;

const ButtonText = styled.p`
  font-family: ${(props) => props.theme.fonts.semiBold};
  color: white;
  text-transform: none;
`;

const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 40px;
  gap: 9px;
  margin-top: 5px;
  margin-right: 30px;
  margin-left: 16px;

  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const CategoryContainer = styled.div`
  padding: 2px 10px;
  border-radius: 11px;
`;

const MobileContainerFooter = styled.div`
  display: none;

  @media screen and (max-width: 860px) {
    display: block;
  }
`;

const MobileCategories = styled.div`
  display: none;

  @media screen and (max-width: 860px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    margin-top: 10px;
    flex-wrap: wrap;
  }
`;

const Footer = styled.footer`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 10px;
`;

const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
`;
