import React from 'react';
import type Icon from '../interfaces/Icon';

export default function AlignLeftIcon({ color = '#3C3C3B' }: Icon): JSX.Element {
  return (
    <svg width='17' height='12' viewBox='0 0 17 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.785156 11.1428H5.92801V9.42848H0.785156V11.1428ZM0.785156 5.14277V6.85706H11.0709V5.14277H0.785156ZM0.785156 0.857056V2.57134H16.2137V0.857056H0.785156Z'
        fill={color}
      />
    </svg>
  );
}
