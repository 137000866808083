import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { sub } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import OverviewCard from './OverviewCard';
import EyeIcon from '../../icons/EyeIcon';
import ReaderIcon from '../../icons/ReaderIcon';
import { getAllStats } from '../../api/stats';
import { formatLessThan10 } from '../../utils/formatLessThan10';
import { openSnackbar } from '../../context/reducers/generalSnackbar';
import { type Stats } from '../../interfaces/Stats';
import { type RootState } from '../../context/store';
import { setStats } from '../../context/reducers/stats';

export default function PublicationsOverview(): JSX.Element {
  const stats = useSelector((state: RootState) => state.stats.stats);
  const [auxStats, setAuxStats] = useState<Stats | null>(stats);
  const dispatch = useDispatch();

  const getFormattedDate = (date: Date): string => {
    const fullYear = date.getFullYear();
    const month = formatLessThan10(date.getMonth() + 1);
    const day = formatLessThan10(date.getDate());
    return `${fullYear}-${month}-${day}`;
  };

  useEffect(() => {
    setAuxStats(stats);
  }, [stats]);

  useEffect(() => {
    if (stats == null) {
      const today = new Date();
      const sixDaysAgo = sub(today, { days: 6 });
      const from = getFormattedDate(sixDaysAgo);
      getAllStats(from)
        .then((res) => {
          dispatch(
            setStats({
              stats: res?.stats,
            }),
          );
        })
        .catch(() => {
          dispatch(
            openSnackbar({
              type: 'error',
              message: 'Ocurrió un error al obtener el resumen general de publicaciones',
            }),
          );
        });
    }
  }, []);

  return (
    <Container>
      <Title>Resumen general de publicaciones</Title>
      <CardsContainer>
        <OverviewCard
          icon={<EyeIcon color='#033BCF' />}
          quantity={auxStats?.publicationsOpened}
          type='aperturas'
        />
        <OverviewCard
          icon={<ReaderIcon color='#033BCF' />}
          quantity={auxStats?.publicationsRead}
          type='lecturas'
        />
      </CardsContainer>
    </Container>
  );
}

const Container = styled.div`
  margin-top: 12px;

  @media screen and (max-width: 860px) {
    padding-left: 24px;
  }
`;

const Title = styled.p`
  font-family: ${(props) => props.theme.fonts.medium};
  color: ${(props) => props.theme.colors.darkGray};
  font-size: 18px;
  margin-bottom: 18px;

  @media screen and (max-width: 640px) {
    display: none;
  }
`;

const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  @media screen and (max-width: 860px) {
    flex-direction: column;
    gap: 20px;
    padding-right: 24px;
  }
`;
