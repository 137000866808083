import { apiClient } from './config';
import { type EditorState } from 'draft-js';
import type PostInformation from '../interfaces/PostInformation';
import AuthorDetails, { type AuthorItem } from '../interfaces/AuthorDetails';
import axios, { type CancelToken } from 'axios';
import { HTML_TEXT } from '../constants/globals';

export const createPost = async (title: string | EditorState | null, contentType?: string) => {
  const payload = { title, contentType: contentType ?? HTML_TEXT };
  const { data } = await apiClient.post(`posts`, payload);
  return data;
};

export const getPost = async (postId: number) => {
  const { data } = await apiClient.get(`posts/${postId}`);
  return data;
};

export const updatePost = async (postId: number, postInformation: any) => {
  const { data } = await apiClient.patch(`posts/${postId}`, postInformation);
  return data;
};

export const deletePost = async (postId: number, forceDelete = false) => {
  const { data } = await apiClient.delete(`posts/${postId}?forceDelete=${forceDelete}`);
  return data;
};

export const addImagesToPost = async (
  postId: number,
  principalImage: string,
  thumbnailImage: string,
) => {
  const payload = {
    principalImage,
    thumbnailImage,
  };
  const { data } = await apiClient.put(`posts/${postId}/images`, payload);
  return data;
};

export const addAudioToPost = async (postId: number, file: string) => {
  const payload = {
    file,
  };
  const { data } = await apiClient.put(`posts/${postId}/audio`, payload);
  return data;
};

export const deleteAudioOfPost = async (postId: number) => {
  const { data } = await apiClient.delete(`posts/${postId}/audio`);
  return data;
};

export const requestReviewForPost = async (postId: number | string | undefined) => {
  const { data } = await apiClient.post(`posts/${postId}/request-review`);
  return data;
};

export const startReviewOfPost = async (postId: number | string | undefined) => {
  const { data } = await apiClient.post(`posts/${postId}/start-review`);
  return data;
};

export const publishPost = async (postId: number, overwritePriorPublication: boolean) => {
  const payload = { overwritePriorPublication };
  const { data } = await apiClient.post(`posts/${postId}/publish`, payload);
  return data;
};

export const returnToDraft = async (postId: number) => {
  const { data } = await apiClient.post(`posts/${postId}/return-to-draft`);
  return data;
};

let cancelRequest: any;

export const getPosts = async (
  q: string,
  status: string[],
  pageSize: number,
  page: number,
  sort: string,
  categoriesIDs?: string[],
  authorsIDs?: number[],
  audio?: boolean,
  contentType?: string,
  wantCancel = false,
) => {
  if (cancelRequest) {
    cancelRequest();
  }

  const { CancelToken } = axios;
  const source = CancelToken.source();

  if (wantCancel) {
    cancelRequest = source.cancel;
  }

  const params: any = {
    statuses: status.join(','),
    page,
    size: pageSize,
    sort: sort !== '' ? sort : 'createdDate,DESC',
  };
  if (q !== '') {
    params.q = q;
  }
  if (categoriesIDs !== undefined && categoriesIDs?.length > 0) {
    params.categories = categoriesIDs.join(',');
  }

  if (authorsIDs !== undefined && authorsIDs?.length > 0) {
    params.authors = authorsIDs.join(',');
  }

  if (audio !== undefined) {
    params.audio = audio;
  }

  if (contentType !== undefined) {
    params.contentType = contentType;
  }

  const { data } = await apiClient.get(`posts/find/all`, { params, cancelToken: source.token });
  return data;
};

export const addTagsToPost = async (postId: string | number | undefined, tags: any[]) => {
  const { data } = await apiClient.post(`posts/${postId}/tags`, tags);
  return data;
};

export const deleteTagFromPost = async (postId: string | number | undefined, tag: string) => {
  const { data } = await apiClient.delete(`posts/${postId}/tags/${tag}`);
  return data;
};

export const addAuthorsToPost = async (
  postId: number | string | undefined,
  authors: AuthorItem[],
) => {
  const { data } = await apiClient.put(`posts/${postId}/authors`, authors);
  return data;
};

export const deleteAuthorFromPost = async (
  postId: string | number | undefined,
  authorId: number,
) => {
  const { data } = await apiClient.delete(`posts/${postId}/authors/${authorId}`);
  return data;
};

export const getPostsInDraftState = async (pageSize: number, page: number) => {
  const { data } = await apiClient.get(`posts/find/all/drafts?page=${page}&size=${pageSize}`);
  return data;
};

export const getPostsInPublishedState = async (pageSize: number, page: number) => {
  const { data } = await apiClient.get(`posts/find/all/published?page=${page}&size=${pageSize}`);
  return data;
};

export const addCategoriesToPost = async (
  postId: string | number | undefined,
  categories: string[],
) => {
  const { data } = await apiClient.put(`posts/${postId}/categories`, categories);
  return data;
};

export const deleteCategoryFromPost = async (
  postId: string | number | undefined,
  categoryId: string,
) => {
  const { data } = await apiClient.delete(`posts/${postId}/categories/${categoryId}`);
  return data;
};

export const addSubscriptionsToPost = async (
  postId: string | number | undefined,
  subscriptions: string[],
) => {
  const { data } = await apiClient.put(`posts/${postId}/subscriptions-allowed`, subscriptions);
  return data;
};

export const deleteSubscriptionsFromPost = async (
  postId: string | number | undefined,
  subscriptionId: string,
) => {
  const { data } = await apiClient.delete(
    `posts/${postId}/subscriptions-allowed/${subscriptionId}`,
  );
  return data;
};

export const getPostPreview = async (postId: number) => {
  const { data } = await apiClient.get(`feed/publications/preview/${postId}`);
  return data;
};
