import { apiClient } from './config';

export const getPublications = async (q: string, pageSize: number, page: number) => {
  if (q) {
    const { data } = await apiClient.get(
      `publications/find/all?q=${q}&page=${page}&size=${pageSize}`,
    );
    return data;
  }
  const { data } = await apiClient.get(`publications/find/all?page=${page}&size=${pageSize}`);
  return data;
};
