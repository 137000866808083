import React from 'react';
import type Icon from '../interfaces/Icon';

export default function CursiveIcon({ color = '#3C3C3B' }: Icon): JSX.Element {
  return (
    <svg width='5' height='12' viewBox='0 0 5 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2.43747 3.42857H4.15176L2.26604 12H0.551758L2.43747 3.42857ZM3.87747 0C3.70794 0 3.54223 0.0502706 3.40127 0.144455C3.26031 0.238639 3.15045 0.372506 3.08558 0.529129C3.0207 0.685751 3.00373 0.858094 3.0368 1.02436C3.06987 1.19063 3.15151 1.34336 3.27138 1.46323C3.39125 1.58311 3.54398 1.66474 3.71025 1.69782C3.87652 1.73089 4.04886 1.71391 4.20549 1.64904C4.36211 1.58416 4.49598 1.4743 4.59016 1.33335C4.68434 1.19239 4.73461 1.02667 4.73461 0.857143C4.73461 0.629814 4.64431 0.411797 4.48356 0.251051C4.32282 0.090306 4.1048 0 3.87747 0Z'
        fill={color}
      />
    </svg>
  );
}
