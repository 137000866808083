import React from 'react';
import type Icon from '../interfaces/Icon';

export default function BoldIcon({ color = '#3C3C3B' }: Icon): JSX.Element {
  return (
    <svg width='11' height='12' viewBox='0 0 11 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.28549 5.82857C8.70517 5.57301 9.05935 5.22293 9.31977 4.80625C9.5802 4.38957 9.73968 3.9178 9.78549 3.42857C9.79344 2.98619 9.71416 2.54657 9.55218 2.13483C9.3902 1.72309 9.14869 1.34729 8.84144 1.0289C8.5342 0.710518 8.16725 0.455778 7.76153 0.279237C7.35582 0.102695 6.91931 0.00780949 6.47692 0H0.914062V12H6.91406C7.33505 11.9955 7.75103 11.9082 8.13825 11.7429C8.52548 11.5777 8.87636 11.3377 9.17086 11.0369C9.46536 10.736 9.69771 10.3801 9.85464 9.98941C10.0116 9.59874 10.09 9.18099 10.0855 8.76V8.65714C10.0858 8.06324 9.91657 7.48159 9.59771 6.98053C9.27886 6.47948 8.82362 6.07984 8.28549 5.82857V5.82857ZM2.62835 1.71429H6.22835C6.59364 1.70298 6.95365 1.80367 7.26007 2.00285C7.56649 2.20202 7.80467 2.49015 7.94263 2.82857C8.08224 3.28096 8.03709 3.77022 7.81705 4.18942C7.59701 4.60862 7.21998 4.92367 6.76835 5.06571C6.59296 5.11711 6.41111 5.14309 6.22835 5.14286H2.62835V1.71429ZM6.5712 10.2857H2.62835V6.85714H6.5712C6.9365 6.84584 7.29651 6.94653 7.60293 7.1457C7.90935 7.34488 8.14752 7.63301 8.28549 7.97143C8.4251 8.42382 8.37995 8.91308 8.15991 9.33227C7.93987 9.75147 7.56283 10.0665 7.11121 10.2086C6.93582 10.26 6.75397 10.2859 6.5712 10.2857V10.2857Z'
        fill={color}
      />
    </svg>
  );
}
