import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import IdeaIcon from '../../icons/IdeaIcon';
import { useSelector } from 'react-redux';
import KeyIdeaItem from './KeyIdeaItem';
import { type RootState } from '../../context/store';
import { KEY_IDEAS } from '../../constants/editorKeys';
import { SEPARATOR } from '../../constants/globals';
import PreviewKeyIdeaItem from './PreviewIdeaItem';

export default function PreviewKeyIdeas(): JSX.Element {
  const elementsContent = useSelector((state: RootState) => state.editor.elementsContent);
  const [keyIdeas, setKeyIdeas] = useState<any>([]);

  useEffect(() => {
    const keyIdeasIndex = elementsContent.findIndex((item) => item.customKey === KEY_IDEAS);
    if (keyIdeasIndex !== -1) {
      const content = elementsContent[keyIdeasIndex].content;
      setKeyIdeas(content);
    }
  }, []);

  return (
    <Container>
      <Header>
        <IdeaIcon color='white' />
        <Title>IDEAS CLAVE:</Title>
      </Header>
      <List>
        {keyIdeas.map((item: any, index: number) => (
          <PreviewKeyIdeaItem
            key={index}
            placeholder={`Idea ${index + 1}`}
            keyNumber={index}
            editorState={item}
          />
        ))}
      </List>
    </Container>
  );
}

const Container = styled.section`
  margin-top: 5px;
  background-color: ${(props) => props.theme.colors.app.primary};
  border: 1px solid ${(props) => props.theme.colors.skyBlue};
  border-radius: 10px;
  padding-bottom: 5px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
`;

const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 25px;
`;

const Title = styled.p`
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  color: white;
  flex: 1;
  margin-left: 7px;
`;

const List = styled.ul`
  margin-top: 10px;
  padding-left: 30px;
  padding-right: 30px;
`;
