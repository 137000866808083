import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import getAvatar from '../../utils/getAvatar';
import type PreviewInformation from '../../interfaces/PreviewInformation';
import { Skeleton } from '@mui/material';
import { getAuthor } from '../../api/authors';
import type AuthorDetails from '../../interfaces/AuthorDetails';
import { AxiosError } from 'axios';
import capitalizeFirstLetter from '../../utils/capitalizeFirstLetter';

interface Props {
  previewInformation: PreviewInformation | null;
  authorId: number;
  names: string;
  username?: string;
  type: string;
}

export default function AuthorItem({ previewInformation, authorId, names, username, type }: Props) {
  const [job, setJob] = useState<string | null>(null);

  const getAuthorDetailsFromPreviewInformation = () => {
    const authorsList = previewInformation?.authors;
    if (authorsList !== undefined) {
      const index: number = authorsList?.findIndex((item) => item.id === authorId);
      index !== -1 ? setJob(authorsList[index].job) : setJob('');
    }
  };

  const getEditorJobFromPreviewInformation = () => {
    const editor = previewInformation?.editor;
    const editorJob = editor?.job;
    setJob(editorJob ?? '');
  };

  useEffect(() => {
    if (type === 'author') {
      getAuthor(authorId)
        .then((res) => {
          setJob(res.job);
        })
        .catch((error: unknown) => {
          if (
            error instanceof AxiosError &&
            error.response?.data.errorCode === 'ENTITY_NOT_FOUND'
          ) {
            getAuthorDetailsFromPreviewInformation();
          }
        });
    } else {
      getEditorJobFromPreviewInformation();
    }
  }, [previewInformation]);

  const getJob = () => {
    return job ?? '';
  };

  return (
    <Container>
      <AvatarContainer>
        <Avatar>{getAvatar(names)}</Avatar>
      </AvatarContainer>
      <InformationContainer>
        <Names>{names}</Names>
        {job == null ? (
          <Skeleton
            variant='rectangular'
            style={{
              width: '60px',
              height: '10px',
              borderRadius: '2px',
              marginTop: '3px',
              marginBottom: '3px',
              backgroundColor: 'rgba(155,155,155,0.6)',
            }}
          />
        ) : (
          <Job>{capitalizeFirstLetter(getJob())}</Job>
        )}
      </InformationContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
`;

const AvatarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  border-radius: 100%;
  background-color: ${(props) => props.theme.colors.skyBlue};
`;

const Avatar = styled.p`
  color: white;
  font-family: ${(props) => props.theme.fonts.regular};
  font-size: 13px;
  text-transform: uppercase;
`;

const InformationContainer = styled.div`
  flex: 1;
`;

const Names = styled.p`
  color: white;
  font-size: 13px;
  font-family: ${(props) => props.theme.fonts.regular};
  text-transform: capitalize;
  margin-bottom: 3px;
`;

const Job = styled.p`
  color: white;
  font-size: 11px;
  font-family: ${(props) => props.theme.fonts.regular};
  text-transform: capitalize;
`;
