import type React from 'react';
import { ContentState, convertToRaw, type EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import Immutable from 'immutable';
import { AxiosError } from 'axios';
import { type NavigateFunction } from 'react-router-dom';
import { type ElementContent, type EditorElement } from '../interfaces/Editor';
import { POST_ERRORS } from '../constants/errorMessages';
import {
  FILE_TYPE,
  HTML_TEXT,
  MAX_AUTHORS_QUANTITY,
  MAX_CATEGORIES_QUANTITY,
  MAX_IMAGES_QUANTITY,
  MAX_TAGS_QUANTITY,
  SEPARATOR,
} from '../constants/globals';
import { getImagesQuantity } from './wysiwyg';
import { openSnackbar } from '../context/reducers/generalSnackbar';
import {
  EDITOR_NOTES,
  HEADER_IMAGE,
  KEY_IDEAS,
  KEY_PARAGRAPH,
  PDF_URL,
  POST_THUMBNAIL,
} from '../constants/editorKeys';
import {
  addAudioToPost,
  addAuthorsToPost,
  addCategoriesToPost,
  addImagesToPost,
  addSubscriptionsToPost,
  addTagsToPost,
  createPost,
  deleteAudioOfPost,
  deleteAuthorFromPost,
  deleteCategoryFromPost,
  deleteSubscriptionsFromPost,
  deleteTagFromPost,
  getPost,
  updatePost,
} from '../api/posts';
import { setUnsavedChanges } from '../context/reducers/editor';
import { type AuthorItem } from '../interfaces/AuthorDetails';
import type TagDetails from '../interfaces/TagDetails';
import type PostInformation from '../interfaces/PostInformation';
import {
  ARTICLE_HAS_BEEN_PUBLISHED,
  ARTICLE_WAS_RETURNED_TO_DRAFT,
  CONCURRENCE_ERROR,
} from './errorsConstants';
import { apiClient } from '../api/config';
import { ARTICLE_SUCCESFUL_MESSAGES } from '../constants/successfulMessages';

let concurrenceError: boolean = false;

export const convertMainParagraphToHtml = (elementsContent: ElementContent[]) => {
  const mainParagraph = elementsContent[3].content as EditorState;
  const currentContent = mainParagraph.getCurrentContent();
  let blockType = '';
  const blocks = currentContent.getBlocksAsArray().map((block, index) => {
    blockType = block.getType();
    if (blockType === 'custom-divider') {
      return {
        type: 'custom-divider',
        content: '',
      };
    } else if (blockType === 'custom-image') {
      return {
        type: 'custom-image',
        data: Immutable.Map({
          url: block.getData().get('url'),
          file: null,
          height: block.getData().get('height'),
          width: block.getData().get('width'),
        }),
      };
    } else {
      const blockHTML = draftToHtml(convertToRaw(ContentState.createFromBlockArray([block])));
      return {
        type: blockType,
        content: blockHTML,
      };
    }
  });
  const contentToSend = {
    blocks,
  };
  return contentToSend;
};

export const savePost = async (
  setCreating: React.Dispatch<React.SetStateAction<boolean>>,
  postInformation: any,
  elementsContent: ElementContent[],
  elements: EditorElement[],
  dispatch: any,
  creating: boolean,
  navigate: NavigateFunction,
  setIsErrorConcurrenceModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  currentVersion: number,
  setCurrentVersion: React.Dispatch<React.SetStateAction<number>>,
  setIsArticleHasBeenPublishedModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  setIsArticleWasReturnedToDraftModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  isPresentationPage?: boolean,
): Promise<void> => {
  concurrenceError = false;
  setCreating(true);
  if (postInformation !== null) {
    const title = elementsContent[0].content;
    const mainParagraph = elementsContent[3].content as EditorState;
    if (title === '') {
      dispatch(
        openSnackbar({
          type: 'error',
          message: POST_ERRORS.EMPTY_TITLE,
        }),
      );
      setCreating(false);
    } else if (getImagesQuantity(mainParagraph) > MAX_IMAGES_QUANTITY) {
      dispatch(
        openSnackbar({
          type: 'error',
          message: POST_ERRORS.MAX_IMAGES_QUANTITY,
        }),
      );
      setCreating(false);
    } else {
      const summary = elementsContent[1].content;
      const keyIdeas = elementsContent[2].content as EditorState[];
      const video =
        elements[elements.findIndex((item) => item.key === 'video')].element.props?.link;
      const audioSource =
        elements[elements.findIndex((item) => item.key === 'audio')].element.props?.audioSource;
      const editorNotes =
        elementsContent[elementsContent.findIndex((item) => item.customKey === EDITOR_NOTES)]
          .content;
      const headerImageUrl =
        elementsContent[elementsContent.findIndex((item) => item.customKey === HEADER_IMAGE)]
          .content;
      const pdfUrl =
        elementsContent[elementsContent.findIndex((item) => item.customKey === PDF_URL)].content;
      const thumbnailImageUrl =
        elementsContent[elementsContent.findIndex((item) => item.customKey === POST_THUMBNAIL)]
          .content;

      const newContent = [];
      let keyIdeasHTML = null;
      if (video !== null && video !== undefined) {
        newContent.push({
          id: 'video',
          content: video,
        });
      }

      if (keyIdeas !== null) {
        keyIdeasHTML = keyIdeas
          .map((item) => item.getCurrentContent())
          .map((item) => convertToRaw(item))
          .map((item) => draftToHtml(item))
          .join(SEPARATOR);
        newContent.push({
          id: KEY_IDEAS,
          content: keyIdeasHTML,
        });
      }

      if (mainParagraph !== null) {
        const mainParagraphHTML = convertMainParagraphToHtml(elementsContent);
        newContent.push({
          id: KEY_PARAGRAPH,
          content: mainParagraphHTML,
        });
      }

      if (editorNotes !== null && editorNotes !== '') {
        newContent.push({
          id: EDITOR_NOTES,
          content: editorNotes,
        });
      }

      const updatedPostInformation: any = {
        title: title as string,
        content: isPresentationPage ? (pdfUrl !== null ? pdfUrl : '') : JSON.stringify(newContent),
        currentVersion,
      };

      if (!isPresentationPage) {
        updatedPostInformation.summary = summary as string;
      }

      if (!creating) {
        try {
          setCreating(true);
          await updatePost(postInformation?.id, updatedPostInformation);
          if (headerImageUrl != null && thumbnailImageUrl != null) {
            await addImagesToPost(
              postInformation?.id,
              headerImageUrl as string,
              thumbnailImageUrl as string,
            );
          }
          if (!isPresentationPage) {
            if (audioSource !== null && audioSource !== undefined) {
              await addAudioToPost(postInformation?.id, audioSource);
            } else {
              await deleteAudioOfPost(postInformation?.id);
            }
          }
          dispatch(
            openSnackbar({
              type: 'success',
              message: isPresentationPage
                ? ARTICLE_SUCCESFUL_MESSAGES.UPDATE_PRESENTATION
                : ARTICLE_SUCCESFUL_MESSAGES.UPDATE_ARTICLE,
            }),
          );
          setCreating(false);
          dispatch(
            setUnsavedChanges({
              unsavedChanges: false,
            }),
          );
        } catch (error: unknown) {
          let errorStatus;
          if (error instanceof AxiosError) {
            errorStatus = error?.response?.status;
          }
          if (errorStatus !== 401) {
            setCreating(false);
            if (errorStatus === CONCURRENCE_ERROR) {
              concurrenceError = true;
              setIsErrorConcurrenceModalVisible(true);
            } else if (errorStatus === ARTICLE_HAS_BEEN_PUBLISHED) {
              concurrenceError = true;
              setIsArticleHasBeenPublishedModalVisible(true);
            } else if (errorStatus === ARTICLE_WAS_RETURNED_TO_DRAFT) {
              concurrenceError = true;
              setIsArticleWasReturnedToDraftModalVisible(true);
            } else {
              dispatch(
                openSnackbar({
                  type: 'error',
                  message: POST_ERRORS.UPDATE_POST,
                }),
              );
            }
          }
        }
      }
    }
  } else {
    const title = elementsContent[0].content;
    const mainParagraph = elementsContent[3].content as EditorState;
    if (title === '') {
      dispatch(
        openSnackbar({
          type: 'error',
          message: POST_ERRORS.EMPTY_TITLE,
        }),
      );
      setCreating(false);
    } else if (getImagesQuantity(mainParagraph) > MAX_IMAGES_QUANTITY) {
      dispatch(
        openSnackbar({
          type: 'error',
          message: POST_ERRORS.MAX_IMAGES_QUANTITY,
        }),
      );
      setCreating(false);
    } else if (!creating) {
      try {
        setCreating(true);
        const contentType = isPresentationPage ? FILE_TYPE : HTML_TEXT;
        const response = await createPost(title as string, contentType);
        const postId = response?.id;
        const summary = elementsContent[1].content;
        const keyIdeas = elementsContent[2].content as EditorState[];
        const video =
          elements[elements.findIndex((item) => item.key === 'video')].element.props?.link;
        const audioSource =
          elements[elements.findIndex((item) => item.key === 'audio')].element.props?.audioSource;
        const headerImageUrl =
          elementsContent[elementsContent.findIndex((item) => item.customKey === HEADER_IMAGE)]
            .content;
        const pdfUrl =
          elementsContent[elementsContent.findIndex((item) => item.customKey === PDF_URL)].content;
        const thumbnailImageUrl =
          elementsContent[elementsContent.findIndex((item) => item.customKey === POST_THUMBNAIL)]
            .content;
        const newContent = [];
        let keyIdeasHTML = null;
        if (video !== null && video !== undefined) {
          newContent.push({
            id: 'video',
            content: video,
          });
        }

        if (keyIdeas !== null) {
          keyIdeasHTML = keyIdeas
            .map((item) => item.getCurrentContent())
            .map((item) => convertToRaw(item))
            .map((item) => draftToHtml(item))
            .join(SEPARATOR);
          newContent.push({
            id: KEY_IDEAS,
            content: keyIdeasHTML,
          });
        }

        if (mainParagraph !== null) {
          const mainParagraphHTML = convertMainParagraphToHtml(elementsContent);
          newContent.push({
            id: KEY_PARAGRAPH,
            content: mainParagraphHTML,
          });
        }

        const newPostInformation: any = {
          title,
          content: isPresentationPage
            ? pdfUrl !== null
              ? pdfUrl
              : ''
            : JSON.stringify(newContent),
          currentVersion: 0,
        };
        if (!isPresentationPage) {
          newPostInformation.summary = summary;
        }
        await updatePost(postId, newPostInformation);
        if (headerImageUrl != null && thumbnailImageUrl != null) {
          await addImagesToPost(postId, headerImageUrl as string, thumbnailImageUrl as string);
        }
        if (!isPresentationPage) {
          if (audioSource !== null && audioSource !== undefined) {
            await addAudioToPost(postId, audioSource);
          } else {
            await deleteAudioOfPost(postId);
          }
        }
        dispatch(
          openSnackbar({
            type: 'success',
            message: isPresentationPage
              ? ARTICLE_SUCCESFUL_MESSAGES.CREATE_PRESENTATION
              : ARTICLE_SUCCESFUL_MESSAGES.CREATE_ARTICLE,
          }),
        );
        navigate(`${isPresentationPage ? '/presentation/' : '/post/'}${postId}`, {
          replace: true,
          state: {
            prevRouteName: location.pathname,
          },
        });
      } catch (error: unknown) {
        let errorStatus;
        if (error instanceof AxiosError) {
          errorStatus = error?.response?.status;
        }
        setCreating(false);
        if (errorStatus !== 401) {
          dispatch(
            openSnackbar({
              type: 'error',
              message: isPresentationPage
                ? POST_ERRORS.CREATE_PRESENTATION
                : POST_ERRORS.CREATE_POST,
            }),
          );
        }
      }
    }
  }
};

const getAddedTags = (selectedTags: TagDetails[], currentTags: TagDetails[]) => {
  const addedTags: TagDetails[] = [];
  let band = false;
  selectedTags.forEach((selectedTag) => {
    currentTags.forEach((currentTag) => {
      if (selectedTag.tag === currentTag.tag) {
        band = true;
      }
    });
    if (!band) {
      addedTags.push(selectedTag);
    }
    band = false;
  });
  return addedTags;
};

const getDeletedTags = (currentTags: TagDetails[], selectedTags: TagDetails[]) => {
  const deletedTags: TagDetails[] = [];
  let band = false;
  currentTags.forEach((currentTag) => {
    selectedTags.forEach((selectedTag) => {
      if (currentTag.tag === selectedTag.tag) {
        band = true;
      }
    });
    if (!band) {
      deletedTags.push(currentTag);
    }
    band = false;
  });
  return deletedTags;
};

const getAddedCategories = (
  selectedCategories: string[],
  currentCategories: string[],
): string[] => {
  const addedCategories: string[] = [];
  let band = false;
  selectedCategories.forEach((selectedCategory) => {
    currentCategories.forEach((currentCategory) => {
      if (selectedCategory === currentCategory) {
        band = true;
      }
    });
    if (!band) {
      addedCategories.push(selectedCategory);
    }
    band = false;
  });
  return addedCategories;
};

const getDeletedCategories = (
  currentCategories: string[],
  selectedCategories: string[],
): string[] => {
  const deletedCategories: string[] = [];
  let band = false;
  currentCategories.forEach((currentCategory) => {
    selectedCategories.forEach((selectedCategory) => {
      if (currentCategory === selectedCategory) {
        band = true;
      }
    });
    if (!band) {
      deletedCategories.push(currentCategory);
    }
    band = false;
  });
  return deletedCategories;
};

const getAddedAuthors = (selectedAuthors: any[], currentAuthors: any[]): AuthorItem[] => {
  const addedAuthors: AuthorItem[] = [];
  let band = false;
  selectedAuthors.forEach((selectedAuthor) => {
    currentAuthors.forEach((currentAuthor) => {
      if (selectedAuthor.authorId === currentAuthor.authorId) {
        band = true;
      }
    });
    if (!band) {
      addedAuthors.push(selectedAuthor);
    }
    band = false;
  });
  return addedAuthors;
};

const getDeletedAuthors = (currentAuthors: AuthorItem[], selectedAuthors: any[]): AuthorItem[] => {
  const deletedAuthors: AuthorItem[] = [];
  let band = false;
  currentAuthors.forEach((currentAuthor) => {
    selectedAuthors.forEach((selectedAuthor) => {
      if (currentAuthor.authorId === selectedAuthor.authorId) {
        band = true;
      }
    });
    if (!band) {
      deletedAuthors.push(currentAuthor);
    }
    band = false;
  });
  return deletedAuthors;
};

const getAddedSubscriptions = (
  selectedSubscriptions: string[],
  currentSubscriptions: string[],
): string[] => {
  const addedSubscriptions: string[] = [];
  let band = false;
  selectedSubscriptions.forEach((selectedSubscription) => {
    currentSubscriptions.forEach((currentSubscription) => {
      if (selectedSubscription === currentSubscription) {
        band = true;
      }
    });
    if (!band) {
      addedSubscriptions.push(selectedSubscription);
    }
    band = false;
  });
  return addedSubscriptions;
};

const getDeletedSubscriptions = (
  currentSubscriptions: string[],
  selectedSubscriptions: string[],
): string[] => {
  const deletedSubscriptions: string[] = [];
  let band = false;
  currentSubscriptions.forEach((currentSubscription) => {
    selectedSubscriptions.forEach((selectedSubscription) => {
      if (currentSubscription === selectedSubscription) {
        band = true;
      }
    });
    if (!band) {
      deletedSubscriptions.push(currentSubscription);
    }
    band = false;
  });
  return deletedSubscriptions;
};

export const saveSettingsOfPost = async (
  savingChanges: boolean,
  setSavingChanges: React.Dispatch<React.SetStateAction<boolean>>,
  selectedTags: TagDetails[],
  currentTags: TagDetails[],
  selectedCategories: any[],
  currentCategories: any[],
  selectedAuthors: AuthorItem[],
  currentAuthors: AuthorItem[],
  dispatch: any,
  postId: number | string | undefined,
  setCurrentCategories: React.Dispatch<React.SetStateAction<any[]>>,
  setCurrentTags: React.Dispatch<React.SetStateAction<TagDetails[]>>,
  setCurrentAuthors: React.Dispatch<React.SetStateAction<AuthorItem[]>>,
  selectedSubscriptions: any[],
  currentSubscriptions: any[],
  setCurrentSubscriptions: React.Dispatch<React.SetStateAction<any[]>>,
): Promise<void> => {
  if (!concurrenceError) {
    try {
      const addedTags = getAddedTags(selectedTags, currentTags);
      const deletedTags = getDeletedTags(currentTags, selectedTags);
      const addedCategories = getAddedCategories(selectedCategories, currentCategories);
      const deletedCategories = getDeletedCategories(currentCategories, selectedCategories);
      const addedAuthors = getAddedAuthors(selectedAuthors, currentAuthors);
      const deletedAuthors = getDeletedAuthors(currentAuthors, selectedAuthors);
      if (
        selectedCategories?.length > MAX_CATEGORIES_QUANTITY ||
        selectedAuthors?.length > MAX_AUTHORS_QUANTITY ||
        selectedTags?.length > MAX_TAGS_QUANTITY
      ) {
        setSavingChanges(false);
        if (selectedCategories?.length > MAX_CATEGORIES_QUANTITY) {
          dispatch(
            openSnackbar({
              type: 'error',
              message: POST_ERRORS.MAX_CATEGORIES_QUANTITY,
            }),
          );
        }
        if (selectedTags?.length > MAX_TAGS_QUANTITY) {
          dispatch(
            openSnackbar({
              type: 'error',
              message: POST_ERRORS.MAX_TAGS_QUANTITY,
            }),
          );
        }
        if (selectedAuthors?.length > MAX_AUTHORS_QUANTITY) {
          dispatch(
            openSnackbar({
              type: 'error',
              message: POST_ERRORS.MAX_AUTHORS_QUANTITY,
            }),
          );
        }
        return;
      }
      await Promise.all(
        deletedTags.map(async (deletedTag) => {
          await deleteTagFromPost(postId, deletedTag.tag);
        }),
      );
      if (addedTags.length > 0) {
        await addTagsToPost(
          postId,
          addedTags.map((addedTag) => addedTag.tag),
        );
      }
      await Promise.all(
        deletedCategories.map(async (deletedCategory) => {
          await deleteCategoryFromPost(postId, deletedCategory);
        }),
      );
      if (addedCategories.length > 0) {
        await addCategoriesToPost(postId, addedCategories);
      }
      await Promise.all(
        deletedAuthors.map(async (deletedAuthor) => {
          await deleteAuthorFromPost(postId, deletedAuthor.authorId);
        }),
      );
      if (addedAuthors.length > 0) {
        await addAuthorsToPost(postId, addedAuthors);
      }
      dispatch(
        openSnackbar({
          type: 'success',
          message: 'Artículo actualizado correctamente',
        }),
      );
      setCurrentSubscriptions(selectedSubscriptions);
      setCurrentCategories(selectedCategories);
      setCurrentTags(selectedTags);
      setCurrentAuthors(selectedAuthors);
      setSavingChanges(false);
      dispatch(
        setUnsavedChanges({
          unsavedChanges: false,
        }),
      );
    } catch (error: unknown) {
      setSavingChanges(false);
      let errorMessage = POST_ERRORS.UPDATE_POST;
      let errorStatus;
      if (error instanceof AxiosError) {
        const errorData = error?.response?.data;
        errorStatus = error?.response?.status;
        errorMessage = errorData?.message;
      }
      if (errorStatus !== 401) {
        dispatch(
          openSnackbar({
            type: 'error',
            message: errorMessage,
          }),
        );
      }
    }
  }
};

export const callPostDetails = async (
  postId: string,
  setCurrentVersion: React.Dispatch<React.SetStateAction<number>>,
): Promise<void> => {
  if (postId !== undefined) {
    getPost(parseInt(postId))
      .then((res: PostInformation) => {
        const newVersion = res.version;
        setCurrentVersion(newVersion);
      })
      .catch(() => {});
  }
};

export const publishPost = async (postId: number, overwritePriorPublication: boolean) => {
  if (!concurrenceError) {
    const payload = { overwritePriorPublication };
    const { data } = await apiClient.post(`posts/${postId}/publish`, payload);
    return 200;
  }
  concurrenceError = false;
  return false;
};
