import React from 'react';
import type Icon from '../interfaces/Icon';

export default function AudioIcon({ color = '#9A9A9A' }: Icon): JSX.Element {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.5 15.5H2.16667C1.70833 15.5 1.31597 15.3368 0.989583 15.0104C0.663194 14.684 0.5 14.2917 0.5 13.8333V8C0.5 6.95833 0.697917 5.98264 1.09375 5.07292C1.48958 4.16319 2.02431 3.37153 2.69792 2.69792C3.37153 2.02431 4.16319 1.48958 5.07292 1.09375C5.98264 0.697917 6.95833 0.5 8 0.5C9.04167 0.5 10.0174 0.697917 10.9271 1.09375C11.8368 1.48958 12.6285 2.02431 13.3021 2.69792C13.9757 3.37153 14.5104 4.16319 14.9062 5.07292C15.3021 5.98264 15.5 6.95833 15.5 8V13.8333C15.5 14.2917 15.3368 14.684 15.0104 15.0104C14.684 15.3368 14.2917 15.5 13.8333 15.5H10.5V8.83333H13.8333V8C13.8333 6.375 13.2674 4.99653 12.1354 3.86458C11.0035 2.73264 9.625 2.16667 8 2.16667C6.375 2.16667 4.99653 2.73264 3.86458 3.86458C2.73264 4.99653 2.16667 6.375 2.16667 8V8.83333H5.5V15.5ZM3.83333 10.5H2.16667V13.8333H3.83333V10.5ZM12.1667 10.5V13.8333H13.8333V10.5H12.1667Z'
        fill='#9A9A9A'
      />
    </svg>
  );
}
