import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { type RootState } from '../../context/store';
import styled, { css } from 'styled-components';
import { ArrowBack } from '@mui/icons-material';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { tooglePreviewMode } from '../../context/reducers/toolBar';
import { Button } from '@mui/material';
import EditNotesIcon from '../../icons/EditNotesIcon';
import POSTS_STATUS from '../../constants/status';
import { PDF_URL } from '../../constants/editorKeys';

interface Props {
  postInformation: any;
  currentStatus: null | string;
}

interface ContainerI {
  isPreviewModeActive: boolean;
}

interface MockupI {
  withMarginTop: boolean;
}

export default function PresentationPreviewMode({ postInformation }: Props) {
  const currentPostStatus = useSelector((state: RootState) => state.editor.currentPostStatus);
  const elementsContent = useSelector((state: RootState) => state.editor.elementsContent);
  const isPreviewModeActive = useSelector((state: RootState) => state.toolBar.isPreviewModeActive);
  const [pdfUrl, setPdfUrl] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const dispatch = useDispatch();

  useEffect(() => {
    const index = elementsContent.findIndex((item) => item.customKey === PDF_URL);
    if (index !== -1 && elementsContent[index].content !== null) {
      setPdfUrl(elementsContent[index].content as string);
    }
  }, [elementsContent]);

  useEffect(() => {
    if (elementsContent[0].content !== null) {
      setTitle(elementsContent[0].content as string);
    }
  }, [elementsContent]);

  const handleClickPreviewMode = () => {
    dispatch(
      tooglePreviewMode({
        isPreviewModeActive: !isPreviewModeActive,
      }),
    );
  };

  return (
    <Container isPreviewModeActive={isPreviewModeActive}>
      <Mockup
        withMarginTop={
          currentPostStatus === POSTS_STATUS.PENDING_REVIEW ||
          currentPostStatus === POSTS_STATUS.REVIEW_IN_PROGRESS
        }
      >
        <Header>
          <BackButton>
            <ArrowBack />
          </BackButton>
          <Title>{title ?? ''}</Title>
        </Header>
        {pdfUrl !== '' && (
          <PDFContainer>
            <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js'>
              <Viewer fileUrl={pdfUrl} withCredentials={false} />
            </Worker>
          </PDFContainer>
        )}
      </Mockup>
      {currentPostStatus === POSTS_STATUS.PENDING_REVIEW ||
        (currentPostStatus === POSTS_STATUS.REVIEW_IN_PROGRESS && (
          <CustomButton
            className='px-3'
            startIcon={<EditNotesIcon width={21} height={24} color='#033BCF' />}
            onClick={handleClickPreviewMode}
          >
            <ButtonText>Volver a vista de edición</ButtonText>
          </CustomButton>
        ))}
    </Container>
  );
}

const Container = styled.section<ContainerI>`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.lightSkyBlue};
  height: calc(100vh - 72px - 90px - 20px - 18px);
  min-height: 937px;
  margin: 18px 24px;
  border-radius: 10px;
  padding-top: 18px;
  ${(props) =>
    !props.isPreviewModeActive &&
    css`
      height: 0px;
      width: 0px;
      min-height: 0px;
      margin: 0px;
      padding: 0px;
      overflow: hidden;
    `}

  @media screen and (max-width: 860px) {
    background-color: transparent;
  }
`;

const Mockup = styled.div<MockupI>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 95%;
  max-width: 1200px;
  height: 500px;
  box-shadow: 0 0 20px #e2e2e2;
  border-radius: 30px;
  padding: 10px;
  background-color: #001117;

  ${(props) =>
    props.withMarginTop &&
    css`
      margin-top: 60px;
    `}

  @media screen and (max-width: 860px) {
    width: 98%;
    max-width: 950px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: ${(props) => props.theme.colors.primary};
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  padding-left: 12px;
  padding-right: 12px;
  height: 60px;
`;

const BackButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  height: 35px;
  width: 35px;
  border-radius: 100%;
`;

const Title = styled.p`
  color: white;
  font-family: ${(props) => props.theme.fonts.regular};
  flex: 1;
  margin-left: 12px;
`;

const PDFContainer = styled.div`
  flex: 1;
  background-color: white;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  overflow: hidden;
`;

const CustomButton = styled(Button)`
  position: absolute;
  right: 5px;

  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const ButtonText = styled.p`
  text-transform: none;
  font-size: 15px;
  font-family: ${(props) => props.theme.fonts.semiBold};
  color: ${(props) => props.theme.colors.accentBlue};
`;
