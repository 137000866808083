import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError } from 'axios';
import DraftItem from './DraftItem';
import type DraftInterface from '../../interfaces/Draft';
import PublicationSkeleton from './PublicationSkeleton';
import ListingIcon from '../../icons/ListingIcon';
import { getPosts } from '../../api/posts';
import { openSnackbar } from '../../context/reducers/generalSnackbar';
import { PAGE_SIZE } from '../../constants/pagination';
import { HOME_ERRORS } from '../../constants/errorMessages';
import POSTS_STATUS from '../../constants/status';
import { type RootState } from '../../context/store';
import {
  setHomeDraftPostCreated,
  setHomeDraftPostDeleted,
  setHomeDraftPostUpdated,
  setHomeDrafts,
  setHomeDraftsCanLoadMoreData,
  setHomeDraftsCurrentPage,
  setHomeDraftsIsLoadingMoreData,
  setHomeDraftsTotal,
} from '../../context/reducers/homeDrafts';

export default function DraftsList(): JSX.Element {
  const drafts = useSelector((state: RootState) => state.homeDrafts.drafts);
  const draftPostDeleted = useSelector((state: RootState) => state.homeDrafts.draftPostDeleted);
  const draftPostCreated = useSelector((state: RootState) => state.homeDrafts.draftPostCreated);
  const draftPostUpdated = useSelector((state: RootState) => state.homeDrafts.draftPostUpdated);
  const currentPage = useSelector((state: RootState) => state.homeDrafts.currentPage);
  const draftsTotal = useSelector((state: RootState) => state.homeDrafts.draftsTotal);
  const isLoadingMoreData = useSelector((state: RootState) => state.homeDrafts.isLoadingMoreData);
  const canLoadMoreData = useSelector((state: RootState) => state.homeDrafts.canLoadMoreData);
  const dispatch = useDispatch();

  const loadMoreData = async () => {
    if (canLoadMoreData && !isLoadingMoreData && drafts !== null) {
      dispatch(
        setHomeDraftsIsLoadingMoreData({
          isLoadingMoreData: true,
        }),
      );
      try {
        const response = await getPosts(
          '',
          [POSTS_STATUS.DRAFT],
          PAGE_SIZE,
          currentPage,
          'lastModifiedDate,DESC',
        );
        const { last, content } = response;
        if (!last) {
          dispatch(
            setHomeDraftsCurrentPage({
              currentPage: currentPage + 1,
            }),
          );
          dispatch(
            setHomeDraftsCanLoadMoreData({
              canLoadMoreData: true,
            }),
          );
        } else {
          dispatch(
            setHomeDraftsCanLoadMoreData({
              canLoadMoreData: false,
            }),
          );
        }
        dispatch(
          setHomeDraftsIsLoadingMoreData({
            isLoadingMoreData: false,
          }),
        );
        if (drafts != null) {
          dispatch(
            setHomeDrafts({
              drafts: [...drafts, ...content],
            }),
          );
        }
      } catch (error: unknown) {
        let errorStatus;
        if (error instanceof AxiosError) {
          errorStatus = error?.response?.status;
        }
        if (errorStatus !== 401) {
          dispatch(
            openSnackbar({
              type: 'error',
              message: HOME_ERRORS.DRAFTS_LIST,
            }),
          );
        }
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
      const margin = 400;
      if (scrollTop + clientHeight + margin >= scrollHeight) {
        loadMoreData();
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [currentPage, canLoadMoreData, isLoadingMoreData]);

  const callApiToGetDrafts = () => {
    const newCurrentPage = currentPage > 0 ? (canLoadMoreData ? currentPage : currentPage + 1) : 0;
    if (draftPostDeleted || draftPostCreated || draftPostUpdated) {
      getPosts('', [POSTS_STATUS.DRAFT], PAGE_SIZE, 0, 'lastModifiedDate,DESC')
        .then((res) => {
          dispatch(
            setHomeDrafts({
              drafts: res.content,
            }),
          );
          dispatch(
            setHomeDraftsTotal({
              draftsTotal: res.totalElements,
            }),
          );
          if (!res.last) {
            dispatch(
              setHomeDraftsCurrentPage({
                currentPage: 1,
              }),
            );
            dispatch(
              setHomeDraftsCanLoadMoreData({
                canLoadMoreData: true,
              }),
            );
          } else {
            dispatch(
              setHomeDraftsCurrentPage({
                currentPage: 0,
              }),
            );
            dispatch(
              setHomeDraftsCanLoadMoreData({
                canLoadMoreData: false,
              }),
            );
          }
          dispatch(
            setHomeDraftPostCreated({
              draftPostCreated: false,
            }),
          );
          dispatch(
            setHomeDraftPostDeleted({
              draftPostDeleted: false,
            }),
          );
          dispatch(
            setHomeDraftPostUpdated({
              draftPostUpdated: false,
            }),
          );
        })
        .catch((error: unknown) => {
          let errorStatus;
          if (error instanceof AxiosError) {
            errorStatus = error?.response?.status;
          }
          if (errorStatus !== 401) {
            dispatch(
              openSnackbar({
                type: 'error',
                message: HOME_ERRORS.DRAFTS_LIST,
              }),
            );
          }
        });
    } else {
      getPosts('', [POSTS_STATUS.DRAFT], PAGE_SIZE, newCurrentPage, 'lastModifiedDate,DESC')
        .then((res) => {
          if (drafts === null || newCurrentPage === 0) {
            dispatch(
              setHomeDrafts({
                drafts: res.content,
              }),
            );
          } else {
            dispatch(
              setHomeDrafts({
                drafts: [...drafts, ...res.content],
              }),
            );
          }
          dispatch(
            setHomeDraftsTotal({
              draftsTotal: res.totalElements,
            }),
          );
          if (!res.last) {
            dispatch(
              setHomeDraftsCurrentPage({
                currentPage: newCurrentPage + 1,
              }),
            );
            dispatch(
              setHomeDraftsCanLoadMoreData({
                canLoadMoreData: true,
              }),
            );
          } else {
            dispatch(
              setHomeDraftsCanLoadMoreData({
                canLoadMoreData: false,
              }),
            );
          }
        })
        .catch((error: unknown) => {
          let errorStatus;
          if (error instanceof AxiosError) {
            errorStatus = error?.response?.status;
          }
          if (errorStatus !== 401) {
            dispatch(
              openSnackbar({
                type: 'error',
                message: HOME_ERRORS.DRAFTS_LIST,
              }),
            );
          }
        });
    }
  };

  useEffect(() => {
    callApiToGetDrafts();
  }, []);

  return (
    <Container>
      <Title>Borradores</Title>
      {drafts === null ? (
        <List>
          <PublicationSkeleton />
          <PublicationSkeleton />
          <PublicationSkeleton />
          <PublicationSkeleton />
          <PublicationSkeleton />
        </List>
      ) : drafts?.length === 0 ? (
        <MessageContainer>
          <div style={{ marginBottom: '20px' }}>
            <ListingIcon color='#033BCF' />
          </div>
          <Message>Sin borradores recientes.</Message>
          <Message>Tus redacciones sin revisar se mostrarán aquí</Message>
        </MessageContainer>
      ) : (
        <List>
          {drafts.map((draft, index) => (
            <DraftItem key={`${draft.id}_${index}`} {...draft} />
          ))}
        </List>
      )}
    </Container>
  );
}

const Container = styled.div``;

const Title = styled.p`
  font-family: ${(props) => props.theme.fonts.medium};
  font-size: 22px;
  margin-bottom: 20px;
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  flex: 1;
  padding-top: 130px;
  height: calc(100vh - 420px);
`;

const Message = styled.p`
  font-family: ${(props) => props.theme.fonts.medium};
  color: ${(props) => props.theme.colors.gray2};
  font-size: 16px;
  text-align: center;
`;

const List = styled.ul``;
