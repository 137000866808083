import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import Checkbox from '@mui/material/Checkbox';
import ComboBox from '../globals/ComboBox';
import SearchBar from './SearchBar';
import RESOURCES_TYPES from '../../constants/resourcesTypes';
import type AuthorDetailsI from '../../interfaces/AuthorDetails';
import ComboBoxAuthorsWrapped from '../globals/ComboBoxAuthorsWrapped';
import ComboBoxCategoriesWrapped from '../globals/ComboBoxCategoriesWrapped';
import { checkIfIsPresentationPage } from '../../utils/checkIfIsPresentationPage';
import { useLocation } from 'react-router-dom';

interface Props {
  selectAll: boolean;
  setSelectAll: React.Dispatch<React.SetStateAction<boolean>>;
  resource: string | null;
  setResource: React.Dispatch<React.SetStateAction<string | null>>;
  authorsIDs: any[];
  setAuthorsIDs: React.Dispatch<React.SetStateAction<any[]>>;
  categoriesIDs: any[];
  setCategoriesIDs: React.Dispatch<React.SetStateAction<any[]>>;
  handleInputChange: (value: string) => void;
}

interface FiltersContainerI {
  isPresentationPage: boolean;
}

export default function SearchFilters({
  resource,
  setResource,
  authorsIDs,
  setAuthorsIDs,
  categoriesIDs,
  setCategoriesIDs,
  handleInputChange,
}: Props): JSX.Element {
  const location = useLocation();

  const handleChangeResources = (values: any[] | string | null | any): void => {
    if (values !== null) {
      setResource(values?.value);
    } else {
      setResource(null);
    }
  };

  return (
    <Container>
      <Title>Seleccionar todo</Title>
      <FiltersContainer isPresentationPage={checkIfIsPresentationPage(location.pathname)}>
        <SearchBar handleInputChange={handleInputChange} />
        {!checkIfIsPresentationPage(location.pathname) && (
          <>
            <ComboBoxAuthorsWrapped
              selectedAuthors={authorsIDs}
              setSelectedAuthors={setAuthorsIDs}
              page='entries'
            />
            <ComboBox
              type='resources'
              placeholder='Tipo de Recurso'
              auxOptions={RESOURCES_TYPES}
              multiple={false}
              values={resource}
              handleChange={handleChangeResources}
              page='entries'
            />
          </>
        )}
        <ComboBoxCategoriesWrapped
          categoriesIDs={categoriesIDs}
          setCategoriesIDs={setCategoriesIDs}
          page='entries'
        />
      </FiltersContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 65px;
  background-color: ${(props) => props.theme.colors.lightSkyBlue};
  border-radius: 10px 10px 0px 0px;
  margin-top: 20px;
  padding-right: 20px;
  padding-left: 20px;

  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const Title = styled.p`
  font-size: 17px;
  color: transparent;
  font-family: ${(props) => props.theme.fonts.regular};
  margin-left: 12px;
  margin-right: 80px;
  white-space: nowrap;
`;

const FiltersContainer = styled.div<FiltersContainerI>`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  flex: 1;

  ${(props) =>
    props.isPresentationPage &&
    css`
      grid-template-columns: repeat(2, 1fr);
      padding-left: 120px;
      @media screen and (max-width: 1280px) {
        padding-left: 0px;
      }
    `}
`;
