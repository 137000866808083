import React, { useState } from 'react';
import styled from 'styled-components';
import HeaderButtonWithText from '../components/globals/HeaderButtonWithText';
import SearchBarFilter from '../components/globals/SearchBarFilter';
import SurveysList from './SurveysList';

export default function Surveys(): JSX.Element {
  const [searchInput, setSearchInput] = useState('');

  const handleInputChange = (value: string): void => {};

  return (
    <Container>
      <HeaderButtonWithText title='Encuestas' type='survey' buttonText='Nueva encuesta' />
      <SearchBarFilter searchInput={searchInput} handleInputChange={handleInputChange} />
      <SurveysList />
    </Container>
  );
}

const Container = styled.section`
  padding-left: 24px;
  padding-right: 24px;
  margin-left: 192px;
  margin-top: 72px;
  width: calc(100vw - 192px);
`;
