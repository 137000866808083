import React from 'react';
import type Icon from '../interfaces/Icon';

export default function NumbersListIcon({ color = '#3C3C3B' }: Icon): JSX.Element {
  return (
    <svg width='18' height='12' viewBox='0 0 18 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.785156 9.42857H2.49944V9.85714H1.6423V10.7143H2.49944V11.1429H0.785156V12H3.35659V8.57143H0.785156V9.42857ZM1.6423 3.42857H2.49944V0H0.785156V0.857143H1.6423V3.42857ZM0.785156 5.14286H2.32801L0.785156 6.94286V7.71429H3.35659V6.85714H1.81373L3.35659 5.05714V4.28571H0.785156V5.14286ZM5.07087 0.857143V2.57143H17.0709V0.857143H5.07087ZM5.07087 11.1429H17.0709V9.42857H5.07087V11.1429ZM5.07087 6.85714H17.0709V5.14286H5.07087V6.85714Z'
        fill={color}
      />
    </svg>
  );
}
