import React from 'react';
import type Icon from '../interfaces/Icon';

export default function VideoIcon({ color = '#3C3C3B' }: Icon): JSX.Element {
  return (
    <svg width='18' height='15' viewBox='0 0 18 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.5714 14.8571H3.57143C2.62457 14.856 1.71681 14.4794 1.04728 13.8098C0.377748 13.1403 0.00111532 12.2326 0 11.2857V4.14284C0.00111532 3.19598 0.377748 2.28822 1.04728 1.61869C1.71681 0.949159 2.62457 0.572526 3.57143 0.571411H13.5714C14.5183 0.572526 15.426 0.949159 16.0956 1.61869C16.7651 2.28822 17.1417 3.19598 17.1429 4.14284V11.2857C17.1417 12.2326 16.7651 13.1403 16.0956 13.8098C15.426 14.4794 14.5183 14.856 13.5714 14.8571ZM3.57143 1.99998C3.0033 2.00061 2.45862 2.22657 2.05689 2.6283C1.65516 3.03003 1.4292 3.57471 1.42857 4.14284V11.2857C1.4292 11.8538 1.65516 12.3985 2.05689 12.8002C2.45862 13.202 3.0033 13.4279 3.57143 13.4286H13.5714C14.1396 13.4279 14.6842 13.202 15.086 12.8002C15.4877 12.3985 15.7137 11.8538 15.7143 11.2857V4.14284C15.7137 3.57471 15.4877 3.03003 15.086 2.6283C14.6842 2.22657 14.1396 2.00061 13.5714 1.99998H3.57143ZM7.14286 11.1071C6.76408 11.1068 6.40092 10.9562 6.13308 10.6883C5.86525 10.4205 5.71463 10.0573 5.71429 9.67855V5.74998C5.71411 5.50157 5.77876 5.25741 5.90183 5.04163C6.02491 4.82584 6.20215 4.64591 6.41606 4.5196C6.62996 4.39329 6.87312 4.32498 7.12151 4.32141C7.3699 4.31785 7.61492 4.37915 7.83236 4.49927L11.4024 6.46284C11.6268 6.5861 11.8139 6.76742 11.9442 6.98782C12.0744 7.20821 12.1431 7.45957 12.1428 7.71558C12.1426 7.97159 12.0736 8.22283 11.9429 8.443C11.8123 8.66317 11.6249 8.84418 11.4003 8.96705L7.83136 10.93C7.62046 11.0461 7.38362 11.1071 7.14286 11.1071ZM7.14286 5.74998V9.67855L10.7143 7.71427L7.14286 5.74998Z'
        fill={color}
      />
    </svg>
  );
}
