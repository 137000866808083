import React from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import CircleIcon from '@mui/icons-material/Circle';
import { motion } from 'framer-motion';

export default function EntryItem(): JSX.Element {
  const removeTag = (): void => {};

  return (
    <motion.div
      layout
      initial={{ opacity: 0, x: -10 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.3 }}
      exit={{ opacity: 0, x: -10 }}
    >
      <Container>
        <CircleIcon style={{ color: '#0094CF', height: '12px', width: '12px' }} />
        <Date>10/02/23 | {'  '}</Date>
        <Title>
          {' '}
          📰 La agroexportación controlada, pero atentada por panorama político e importaciones
          factibles
        </Title>
        <CustomButton onClick={removeTag}>
          <ButtonText>Quitar etiqueta</ButtonText>
        </CustomButton>
      </Container>
    </motion.div>
  );
}

const Container = styled.li`
  cursor: pointer;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 58px;
  padding-right: 30px;
  height: 60px;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray2};
  transition: background-color 0.1s ease-out;

  :hover {
    background-color: rgba(0, 0, 0, 0.09);
  }
`;

const Date = styled.p`
  font-family: ${(props) => props.theme.fonts.medium};
  color: ${(props) => props.theme.colors.gray2};
  margin-left: 13px;
  font-size: 15px;
`;

const Title = styled.p`
  flex: 1;
  font-family: ${(props) => props.theme.fonts.medium};
  color: ${(props) => props.theme.colors.darkGray};
  font-size: 15px;
`;

const CustomButton = styled(Button)`
  &&& {
    border: 1px solid;
    border-color: ${(props) => props.theme.colors.red};
    border-radius: 5px;
    background-color: 'transparent';
  }
`;

const ButtonText = styled.p`
  text-transform: none;
  font-family: ${(props) => props.theme.fonts.medium};
  color: ${(props) => props.theme.colors.red};
  font-size: 13px;
  padding: 0px 15px;
`;
