import React from 'react';

export default function NotFound404Icon() {
  return (
    <svg width='429' height='251' viewBox='0 0 429 251' fill='none'>
      <g clipPath='url(#clip0_1942_13394)'>
        <path
          d='M184.323 235.324C176.083 225.009 171.405 209.579 170.29 189.04C169.182 168.618 172.164 152.832 179.241 141.685C186.317 130.537 197.106 124.568 211.612 123.779C226.116 122.993 237.491 127.76 245.73 138.074C253.97 148.393 258.646 163.762 259.755 184.184C260.863 204.608 257.881 220.42 250.808 231.627C243.736 242.834 232.945 248.83 218.443 249.619C203.937 250.406 192.567 245.643 184.325 235.324H184.323Z'
          fill='white'
        />
        <path
          d='M184.323 235.324C176.083 225.009 171.405 209.579 170.29 189.04C169.182 168.618 172.164 152.832 179.241 141.685C186.317 130.537 197.106 124.568 211.612 123.779C226.116 122.993 237.491 127.76 245.73 138.074C253.97 148.393 258.646 163.762 259.755 184.184C260.863 204.608 257.881 220.42 250.808 231.627C243.736 242.834 232.945 248.83 218.443 249.619C203.937 250.406 192.567 245.643 184.325 235.324H184.323Z'
          stroke='#183582'
          strokeWidth='2.11495'
          strokeMiterlimit='10'
        />
        <path
          d='M234.128 220.227C237.33 212.783 238.506 201.167 237.649 185.386C236.786 169.49 234.36 158.071 230.378 151.13C226.393 144.189 220.455 140.934 212.566 141.361C204.675 141.79 199.125 145.699 195.921 153.086C192.713 160.478 191.539 172.061 192.396 187.841C193.252 203.623 195.674 215.041 199.667 222.097C203.656 229.155 209.596 232.469 217.487 232.039C225.376 231.612 230.924 227.674 234.128 220.225V220.227Z'
          fill='#7E97DA'
        />
        <path
          d='M234.128 220.227C237.33 212.783 238.506 201.167 237.649 185.386C236.786 169.49 234.36 158.071 230.378 151.13C226.393 144.189 220.455 140.934 212.566 141.361C204.675 141.79 199.125 145.699 195.921 153.086C192.713 160.478 191.539 172.061 192.396 187.841C193.252 203.623 195.674 215.041 199.667 222.097C203.656 229.155 209.596 232.469 217.487 232.039C225.376 231.612 230.924 227.674 234.128 220.225V220.227Z'
          stroke='#183582'
          strokeWidth='2.11495'
          strokeMiterlimit='10'
        />
        <path d='M284.341 187.249H140.112V189.172H284.341V187.249Z' fill='#183582' />
        <path
          d='M381.104 187.738H363.506C363.418 187.738 363.347 187.809 363.347 187.896V189.022C363.347 189.109 363.418 189.18 363.506 189.18H381.104C381.192 189.18 381.263 189.109 381.263 189.022V187.896C381.263 187.809 381.192 187.738 381.104 187.738Z'
          fill='#183582'
        />
        <path
          d='M60.7971 187.738H43.1987C43.1111 187.738 43.04 187.809 43.04 187.896V189.022C43.04 189.109 43.1111 189.18 43.1987 189.18H60.7971C60.8848 189.18 60.9558 189.109 60.9558 189.022V187.896C60.9558 187.809 60.8848 187.738 60.7971 187.738Z'
          fill='#183582'
        />
        <path
          d='M365.699 217.622H347.995C347.936 217.622 347.889 217.669 347.889 217.728V218.479C347.889 218.537 347.936 218.584 347.995 218.584H365.699C365.757 218.584 365.805 218.537 365.805 218.479V217.728C365.805 217.669 365.757 217.622 365.699 217.622Z'
          fill='#C4CEFE'
        />
        <path
          d='M69.4177 64.358C72.7784 64.358 75.6991 61.9173 76.1538 58.5884C76.2342 57.992 76.2765 57.3808 76.2765 56.7632C76.2765 49.3503 70.2679 43.3417 62.855 43.3417C62.5505 43.3417 62.2501 43.3565 61.9498 43.3756C61.3365 36.0663 55.2137 30.3242 47.7458 30.3242C39.8719 30.3242 33.4868 36.7071 33.4868 44.5832C33.4868 44.9322 33.5038 45.2769 33.5291 45.6216C31.2936 44.3738 28.6901 43.7055 25.9196 43.8324C18.7583 44.1602 12.8724 49.8896 12.367 57.0403C12.3627 57.0974 12.3585 57.1545 12.3564 57.2094C12.1259 61.0988 15.2708 64.3601 19.1665 64.3601H69.4177V64.358Z'
          fill='#7E97DA'
        />
        <path
          d='M420.239 97.2726C430.852 97.2726 431.44 81.3005 420.84 80.7845C420.55 80.7697 420.258 80.7633 419.966 80.7633C418.073 80.7633 416.252 81.0636 414.543 81.6135C413.124 72.9676 405.623 66.3711 396.575 66.3711C387.527 66.3711 379.698 73.2637 378.524 82.1888C376.331 81.1377 373.873 80.5476 371.278 80.5476C369.829 80.5476 368.421 80.7316 367.08 81.0763C357.799 83.4662 359.747 97.2747 369.33 97.2747H420.243L420.239 97.2726Z'
          fill='#7E97DA'
        />
        <path
          d='M327.642 30.8932C338.253 30.8932 338.839 14.9254 328.243 14.4093C327.953 14.3945 327.661 14.3882 327.369 14.3882C325.479 14.3882 323.656 14.6885 321.947 15.2363C320.528 6.59246 313.028 -0.00195312 303.984 -0.00195312C294.941 -0.00195312 287.111 6.88855 285.938 15.8115C283.744 14.7604 281.287 14.1703 278.694 14.1703C277.245 14.1703 275.839 14.3543 274.496 14.6991C265.217 17.0889 267.165 30.8911 276.746 30.8911H327.642V30.8932Z'
          fill='#7E97DA'
        />
        <path
          d='M323.102 60.2339C328.668 66.1113 330.191 66.1515 336.068 60.585C330.191 66.1515 330.151 67.6743 335.717 73.5517C330.151 67.6743 328.628 67.6341 322.75 73.2006C328.628 67.6341 328.668 66.1113 323.102 60.2339Z'
          fill='#7E97DA'
        />
        <path
          d='M119.66 14.7095C125.227 20.5869 126.75 20.6271 132.627 15.0606C126.75 20.6271 126.71 22.1499 132.276 28.0273C126.71 22.1499 125.187 22.1097 119.309 27.6762C125.187 22.1097 125.227 20.5869 119.66 14.7095Z'
          fill='#7E97DA'
        />
        <path
          d='M64.5533 98.6772C70.1199 104.555 71.6427 104.595 77.5201 99.0283C71.6427 104.595 71.6025 106.118 77.169 111.995C71.6025 106.118 70.0797 106.077 64.2023 111.644C70.0797 106.077 70.1199 104.555 64.5533 98.6772Z'
          fill='#7E97DA'
        />
        <path
          d='M0.851081 83.771C6.41763 89.6484 7.94039 89.6886 13.8178 84.1221C7.94039 89.6886 7.9002 91.2114 13.4667 97.0888C7.9002 91.2114 6.37744 91.1712 0.5 96.7377C6.37744 91.1712 6.41763 89.6484 0.851081 83.771Z'
          fill='#7E97DA'
        />
        <path
          d='M388.045 127.673C393.612 133.551 395.135 133.591 401.012 128.024C395.135 133.591 395.094 135.114 400.661 140.991C395.094 135.114 393.572 135.074 387.694 140.64C393.572 135.074 393.612 133.551 388.045 127.673Z'
          fill='#7E97DA'
        />
        <path
          d='M187.478 108.797L238.307 93.8698C239.259 93.5906 239.804 92.5923 239.523 91.6406L218.778 20.9971C218.498 20.0454 217.5 19.4997 216.548 19.781L181.747 30.0004C181.303 30.1316 180.924 30.4277 180.694 30.8316L169.184 50.9279C168.941 51.353 168.882 51.8563 169.019 52.3258L185.245 107.581C185.524 108.533 186.522 109.078 187.474 108.797H187.478Z'
          fill='white'
        />
        <path
          d='M187.478 108.797L238.307 93.8698C239.259 93.5906 239.804 92.5923 239.523 91.6406L218.778 20.9971C218.498 20.0454 217.5 19.4997 216.548 19.781L181.747 30.0004C181.303 30.1316 180.924 30.4277 180.694 30.8316L169.184 50.9279C168.941 51.353 168.882 51.8563 169.019 52.3258L185.245 107.581C185.524 108.533 186.522 109.078 187.474 108.797H187.478Z'
          stroke='#183582'
          strokeWidth='1.69196'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M181.506 31.0962L185.748 45.5476C186.047 46.5628 185.467 47.6287 184.454 47.9333L169.349 52.4403'
          fill='white'
        />
        <path
          d='M181.506 31.0962L185.748 45.5476C186.047 46.5628 185.467 47.6287 184.454 47.9333L169.349 52.4403'
          stroke='#183582'
          strokeWidth='1.69196'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M191.433 84.3279L188.553 85.1738C188.329 85.2396 188.201 85.4747 188.266 85.6988L191.822 97.8031C191.888 98.0272 192.123 98.1555 192.347 98.0897L195.227 97.2439C195.451 97.178 195.579 96.9429 195.513 96.7188L191.958 84.6146C191.892 84.3904 191.657 84.2621 191.433 84.3279Z'
          fill='#033BCF'
        />
        <path
          d='M207.276 83.6824L204.396 84.5282C204.172 84.5941 204.044 84.8292 204.11 85.0533L206.581 93.4664C206.647 93.6906 206.882 93.8189 207.106 93.753L209.986 92.9072C210.21 92.8414 210.338 92.6063 210.272 92.3822L207.801 83.969C207.735 83.7449 207.5 83.6166 207.276 83.6824Z'
          fill='#033BCF'
        />
        <path
          d='M213.572 77.8226L210.692 78.6684C210.468 78.7342 210.34 78.9693 210.406 79.1934L213.961 91.2977C214.027 91.5218 214.262 91.6502 214.486 91.5843L217.366 90.7385C217.59 90.6726 217.718 90.4376 217.652 90.2134L214.097 78.1092C214.031 77.885 213.796 77.7567 213.572 77.8226Z'
          fill='#033BCF'
        />
        <path
          d='M197.728 78.4685L194.849 79.3144C194.625 79.3802 194.496 79.6153 194.562 79.8394L199.201 95.6328C199.267 95.8569 199.502 95.9853 199.727 95.9194L202.606 95.0736C202.83 95.0077 202.958 94.7727 202.893 94.5485L198.253 78.7552C198.188 78.531 197.952 78.4027 197.728 78.4685Z'
          fill='#033BCF'
        />
        <path
          d='M180.7 59.9588L214.835 49.936'
          stroke='#93A7DB'
          strokeWidth='1.26897'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M182.326 65.4956L216.462 55.4707'
          stroke='#93A7DB'
          strokeWidth='1.26897'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M183.951 71.0307L218.086 61.0059'
          stroke='#93A7DB'
          strokeWidth='1.26897'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M256.815 89.731L258.082 87.0387C258.323 86.5248 258.071 85.9157 257.536 85.7253L240.523 79.6152L239.777 80.9625L255.457 90.154C255.946 90.4395 256.574 90.245 256.813 89.7331L256.815 89.731Z'
          fill='#183582'
        />
        <path
          d='M227.758 89.3333C235.37 89.3333 241.541 83.1624 241.541 75.5502C241.541 67.938 235.37 61.7671 227.758 61.7671C220.145 61.7671 213.974 67.938 213.974 75.5502C213.974 83.1624 220.145 89.3333 227.758 89.3333Z'
          fill='#033BCF'
        />
        <path
          d='M227.758 86.4589C233.782 86.4589 238.666 81.5748 238.666 75.55C238.666 69.5252 233.782 64.6411 227.758 64.6411C221.733 64.6411 216.849 69.5252 216.849 75.55C216.849 81.5748 221.733 86.4589 227.758 86.4589Z'
          fill='white'
        />
        <path
          d='M227.758 89.3333C235.37 89.3333 241.541 83.1624 241.541 75.5502C241.541 67.938 235.37 61.7671 227.758 61.7671C220.145 61.7671 213.974 67.938 213.974 75.5502C213.974 83.1624 220.145 89.3333 227.758 89.3333Z'
          stroke='#183582'
          strokeWidth='1.69196'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M227.823 70.2607L227.741 76.9905'
          stroke='#183582'
          strokeWidth='1.69196'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M227.692 80.8404C227.695 80.8404 227.697 80.8383 227.697 80.8357C227.697 80.8331 227.695 80.8311 227.692 80.8311C227.69 80.8311 227.688 80.8331 227.688 80.8357C227.688 80.8383 227.69 80.8404 227.692 80.8404Z'
          stroke='#183582'
          strokeWidth='1.69196'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M374.159 185.599L360.6 188.324L366.048 215.443L337.016 221.277L331.568 194.157L278.373 204.845L274.147 183.81L306.658 100.773L341.599 93.7515L355.57 163.289L369.129 160.565L374.159 185.599ZM318.956 131.4L303.589 173.732L326.536 169.122L318.956 131.398V131.4Z'
          fill='#183582'
        />
        <path
          d='M150.414 199.638L136.772 197.365L132.225 224.65L103.016 219.781L107.563 192.496L54.042 183.575L57.5697 162.411L117.476 96.3569L152.63 102.217L140.968 172.18L154.61 174.454L150.412 199.64L150.414 199.638ZM118.087 129.354L88.6743 163.46L111.761 167.307L118.087 129.354Z'
          fill='#183582'
        />
        <path
          d='M66.3299 218.941H48.6256C48.5672 218.941 48.5199 218.989 48.5199 219.047V219.798C48.5199 219.856 48.5672 219.904 48.6256 219.904H66.3299C66.3883 219.904 66.4356 219.856 66.4356 219.798V219.047C66.4356 218.989 66.3883 218.941 66.3299 218.941Z'
          fill='#033BCF'
        />
        <path
          d='M114.944 218.941H97.2399C97.1815 218.941 97.1342 218.989 97.1342 219.047V219.798C97.1342 219.856 97.1815 219.904 97.2399 219.904H114.944C115.003 219.904 115.05 219.856 115.05 219.798V219.047C115.05 218.989 115.003 218.941 114.944 218.941Z'
          fill='#033BCF'
        />
        <path
          d='M159.189 207.523H141.485C141.426 207.523 141.379 207.57 141.379 207.629V208.38C141.379 208.438 141.426 208.485 141.485 208.485H159.189C159.247 208.485 159.295 208.438 159.295 208.38V207.629C159.295 207.57 159.247 207.523 159.189 207.523Z'
          fill='#033BCF'
        />
        <path
          d='M184.352 220.149H166.648C166.59 220.149 166.542 220.196 166.542 220.255V221.005C166.542 221.064 166.59 221.111 166.648 221.111H184.352C184.411 221.111 184.458 221.064 184.458 221.005V220.255C184.458 220.196 184.411 220.149 184.352 220.149Z'
          fill='#033BCF'
        />
        <path
          d='M246.439 220.149H228.735C228.676 220.149 228.629 220.196 228.629 220.255V221.005C228.629 221.064 228.676 221.111 228.735 221.111H246.439C246.497 221.111 246.545 221.064 246.545 221.005V220.255C246.545 220.196 246.497 220.149 246.439 220.149Z'
          fill='#033BCF'
        />
        <path
          d='M315.898 219.437H298.194C298.135 219.437 298.088 219.484 298.088 219.542V220.293C298.088 220.351 298.135 220.399 298.194 220.399H315.898C315.956 220.399 316.004 220.351 316.004 220.293V219.542C316.004 219.484 315.956 219.437 315.898 219.437Z'
          fill='#033BCF'
        />
        <path
          d='M95.2413 205.672H77.537C77.4786 205.672 77.4313 205.72 77.4313 205.778V206.529C77.4313 206.587 77.4786 206.635 77.537 206.635H95.2413C95.2997 206.635 95.347 206.587 95.347 206.529V205.778C95.347 205.72 95.2997 205.672 95.2413 205.672Z'
          fill='#033BCF'
        />
        <path
          d='M218.858 205.205H201.154C201.095 205.205 201.048 205.252 201.048 205.311V206.062C201.048 206.12 201.095 206.167 201.154 206.167H218.858C218.916 206.167 218.964 206.12 218.964 206.062V205.311C218.964 205.252 218.916 205.205 218.858 205.205Z'
          fill='#033BCF'
        />
        <path
          d='M344.807 205.129H327.103C327.045 205.129 326.997 205.176 326.997 205.235V205.985C326.997 206.044 327.045 206.091 327.103 206.091H344.807C344.866 206.091 344.913 206.044 344.913 205.985V205.235C344.913 205.176 344.866 205.129 344.807 205.129Z'
          fill='#033BCF'
        />
        <path
          d='M282.814 200.651H265.11C265.051 200.651 265.004 200.699 265.004 200.757V201.508C265.004 201.566 265.051 201.614 265.11 201.614H282.814C282.872 201.614 282.92 201.566 282.92 201.508V200.757C282.92 200.699 282.872 200.651 282.814 200.651Z'
          fill='#033BCF'
        />
      </g>
      <defs>
        <clipPath id='clip0_1942_13394'>
          <rect width='428' height='250.761' fill='white' transform='translate(0.5)' />
        </clipPath>
      </defs>
    </svg>
  );
}
