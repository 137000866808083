import {
  MAX_AUTHORS_QUANTITY,
  MAX_CATEGORIES_QUANTITY,
  MAX_IMAGES_QUANTITY,
  MAX_TAGS_QUANTITY,
} from './globals';

const AUTHORS_ERRORS = {
  AUTHORS_LIST: 'Ocurrió un error al intentar listar los autores',
  DELETE_AUTHOR: 'Ocurrió un error al intentar eliminar al autor',
  CREATE_AUTHOR: 'Ocurrió un error al intentar crear un nuevo autor',
  UPDATE_AUTHOR: 'Ocurrió un error al intentar actualizar los datos del autor',
};

const CATEGORIES_ERRORS = {
  CATEGORIES_LIST: 'Ocurrió un error al intentar listar las categorías',
};

const POST_ERRORS = {
  CREATE_POST: 'Ocurrió un error al intentar crear el artículo',
  CREATE_PRESENTATION: 'Ocurrió un error al intentar crear el PPT',
  DELETE_POST: 'Ocurrió un error al intentar eliminar el artículo',
  GET_POST: 'Ocurrió un error al intentar obtener la información del artículo seleccionado',
  SEARCH_GENERAL: 'Ocurrió un error al realizar la búsqueda',
  UPDATE_POST: 'Ocurrió un error al intentar actualizar el artículo',
  RETURN_TO_DRAFT: 'Ocurrió un error al intentar devolver el artículo al redactor',
  REQUEST_REVIEW: 'Ocurrió un error al solicitar la revisión del artículo',
  PUBLISH: 'Ocurrió un error al intentar publicar el artículo',
  START_REVIEW: 'Ocurrió un error al iniciar la revisión del artículo ',
  EMPTY_TITLE: 'El título no puede estar vacío',
  PUBLISH_EMPTY_TITLE: 'El título no puede estar vacío',
  EMPTY_SUMMARY: 'El subtítulo no puede estar vacío',
  PUBLISH_EMPTY_SUMMARY: 'El subtítulo no puede estar vacío',
  HEADER_IMAGE: 'El artículo debe tener una imagen de cabecera',
  PRESENTATION_HEADER_IMAGE: 'El PPT debe tener una imagen de cabecera',
  PUBLISH_HEADER_IMAGE: 'El artículo debe tener una imagen de cabecera',
  PUBLISH_PRESENTATION_HEADER_IMAGE: 'El PPT debe tener una imagen de cabecera',
  CONTENT: 'El contenido del artículo no puede estar vacío',
  MAX_AUTHORS_QUANTITY: `Máximo ${MAX_AUTHORS_QUANTITY} autores por artículo`,
  MIN_AUTHORS_QUANTITY: 'Para pasar un artículo a revisión, este debe tener por los menos un autor',
  REVIEW_PRESENTATION_MIN_AUTHORS_QUANTITY:
    'Para pasar un PPT a revisión, este debe tener por los menos un autor',
  PUBLISH_MIN_AUTHORS_QUANTITY: 'Para publicar un artículo, este debe tener por los menos un autor',
  PUBLISH_PRESENTATION_MIN_AUTHORS_QUANTITY:
    'Para publicar un PPT, este debe tener por los menos un autor',
  MAX_TAGS_QUANTITY: `Máximo ${MAX_TAGS_QUANTITY} etiquetas por artículo`,
  MAX_CATEGORIES_QUANTITY: `Máximo ${MAX_CATEGORIES_QUANTITY} categorías por artículo`,
  MIN_CATEGORIES_QUANTITY:
    'Para pasar un artículo a revisión, este debe tener por los menos una categoría',
  REVIEW_PRESENTATION_MIN_CATEGORIES_QUANTITY:
    'Para pasar un PPT a revisión, este debe tener por los menos una categoría',
  PUBLISH_MIN_CATEGORIES_QUANTITY:
    'Para publicar un artículo, este debe tener por los menos una categoría',
  PUBLISH_PRESENTATION_MIN_CATEGORIES_QUANTITY:
    'Para publicar un PPT, este debe tener por los menos una categoría',
  KEY_IDEAS: 'Para pasar un artículo a revisión, este debe tener las 3 ideas clave',
  PUBLISH_KEY_IDEAS: 'Para publicar un artículo, este debe tener las 3 ideas clave',
  MAX_IMAGES_QUANTITY: `Máximo ${MAX_IMAGES_QUANTITY} imágenes por artículo`,
  PUBLISH_NO_PDF: 'Para publicar un PPT, este debe tener un archivo PDF',
  NO_PDF: 'Falta agregar un archivo PDF',
};

const HOME_ERRORS = {
  LAST_PUBLICATIONS_LIST: 'Ocurrió un error al intentar listar las últimas publicaciones',
  DRAFTS_LIST: 'Ocurrió un error al intentar listar tus borradores',
  PENDING_REVIEWS_LIST: 'Ocurrió un error al intentar listar las entradas pendientes de revisión',
  PUBLICATIONS_LIST: 'Ocurrió un error al intentar listar las publicaciones',
  PUBLICATION_STATS: 'Ocurrió un error al obtener las estadísticas de cada publicación',
};

const TAGS_ERRORS = {
  TAGS_LIST: 'Ocurrió un error al intentar listar las etiquetas',
  DELETE_TAG: 'Ocurrió un error al intentar eliminar un etiqueta',
  CREATE_TAG: 'Ocurrió un error al intentar crear una etiqueta',
};

const FILES_ERRORS = {
  IMAGE: 'Ocurrió un error al intentar subir la imagen',
  AUDIO: 'Ocurrió un error al intentar subir el archivo de audio',
  IMAGE_MAX_SIZE: 'El archivo excede el tamaño máximo permitido (Máx 5MB)',
  PDF: 'Hubo un error al subir el archivo PDF. Inténtalo de nuevo',
};

const SUBSCRIPTIONS_ERRORS = {
  SUBSCRIPTIONS_LIST: 'Ocurrió un error al intentar listar las suscripciones',
};

const SETTINGS_ERRORS = {
  ADD_TAG: 'Ocurrió un error al intentar agregar la etiqueta',
  ADD_AUTHOR: 'Ocurrió un error al intentar agregar el autor',
  ADD_CATEGORY: 'Ocurrió un error al intentar agregar la categoría',
  ADD_SUBSCRIPTION: 'Ocurrió un error al intentar agregar la suscripción',
};

const DEFAULT_ERROR_MESSAGE = 'Ocurrió un error inesperado';

const UNSAVED_CHANGES = 'Tienes cambios sin guardar ¿Estás seguro que deseas salir?';

const CAMPAIGNS_ERRORS = {
  EMPTY_SCHEDULED_AT: 'La fecha y la hora de la notificación son obligatorias',
  EMPTY_TITLE: 'El título de la notificación es obligatorio',
  EMPTY_BODY: 'La descripción de la notificación es obligatoria',
  CREATE_CAMPAING: 'Ocurrió un error al intentar crear la notificación. Inténtalo de nuevo',
  OLD_SCHEDULED_AT: 'Debes elegir una fecha y hora posterior a la fecha y hora actual',
  SCHEDULED_LIST: 'Ocurrió un error al intentar listar las campañas programadas',
  PROCESSED_LIST: 'Ocurrió un error al intentar listar las campañas completadas',
  DELETE: 'Ocurrió un error al intentar eliminar la campaña',
  MULTIPLE_DELETE: 'Ocurrió un error al intentar eliminar las campañas',
};

export {
  AUTHORS_ERRORS,
  DEFAULT_ERROR_MESSAGE,
  HOME_ERRORS,
  TAGS_ERRORS,
  CATEGORIES_ERRORS,
  POST_ERRORS,
  FILES_ERRORS,
  SUBSCRIPTIONS_ERRORS,
  SETTINGS_ERRORS,
  UNSAVED_CHANGES,
  CAMPAIGNS_ERRORS,
};
