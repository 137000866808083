import React, { useState } from 'react';
import styled from 'styled-components';
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import Button from '@mui/material/Button';
import SideBarOptionItem from './SideBarOptionItem';
import HomeIcon from '../../icons/HomeIcon';
import PublicationsIcon from '../../icons/PublicationsIcon';
import ReportsIcon from '../../icons/ReportsIcon';
import { AnimatePresence, motion } from 'framer-motion';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { closeSearchBar, setInputOfGeneralSearch } from '../../context/reducers/generalSearch';
import POSTS_STATUS, { CAMPAIGNS_STATUS } from '../../constants/status';
import CampaignsIcon from '../../icons/CampaignsIcon';

const options = [
  {
    title: 'Inicio',
    icon: <HomeIcon />,
    path: '/',
  },
  {
    title: 'Publicaciones',
    icon: <PublicationsIcon />,
    path: '/',
    subOptions: [
      {
        title: 'Entradas',
        path: `/entries?type=${POSTS_STATUS.DRAFT.toLowerCase()}`,
      },
      {
        title: 'PPTs',
        path: `/presentations?type=${POSTS_STATUS.DRAFT.toLowerCase()}`,
      },
      {
        title: 'Etiquetas',
        path: '/tags',
      },
      {
        title: 'Autores',
        path: '/authors',
      },
    ],
  },
  {
    title: 'Campañas',
    icon: <CampaignsIcon />,
    path: `/campaigns?type=${CAMPAIGNS_STATUS.SCHEDULED}`,
  },
];

export default function Sidebar(): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClickNewPost = (): void => {
    dispatch(closeSearchBar({}));
    dispatch(
      setInputOfGeneralSearch({
        searchInput: '',
      }),
    );
    navigate('/new-post', {
      state: {
        prevRouteName: location.pathname,
      },
    });
  };

  return (
    <Container>
      <div style={{ flex: 1 }}>
        {options.map((opt, index) => (
          <SideBarOptionItem
            key={index}
            title={opt?.title}
            path={opt?.path}
            icon={opt?.icon}
            subOptions={opt?.subOptions}
          />
        ))}
      </div>
      <ButtonContainer>
        <motion.div whileHover={{ scale: 1.04 }}>
          <Button
            onClick={handleClickNewPost}
            className='bg-accent-blue px-3'
            startIcon={<ControlPointOutlinedIcon style={{ color: 'white' }} />}
          >
            <ButtonText>Nueva publicación</ButtonText>
          </Button>
        </motion.div>
      </ButtonContainer>
    </Container>
  );
}

const Container = styled.aside`
  position: fixed;
  top: 72px;
  z-index: 999;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 72px);
  width: 192px;
  background-color: ${(props) => props.theme.colors.primary};

  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 22px;
`;

const ButtonText = styled.p`
  color: white;
  font-family: ${(props) => props.theme.fonts.medium};
  font-size: 12px;
  text-transform: none;
`;
