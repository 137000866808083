import React from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import type PublicationInterface from '../../interfaces/Publication';
import EyeIcon from '../../icons/EyeIcon';
import ReaderIcon from '../../icons/ReaderIcon';
import PublicationIcon from '../../icons/PublicationIcon';
import MovieIcon from '../../icons/MovieIcon';
import AudioIcon from '../../icons/AudioIcon';
import WithoutImageIcon from '../../icons/WithoutImageIcon';
import getMonthName from '../../utils/getMonthName';
import { getLastPublicationDate } from '../../utils/getLastPublicationDate';
import capitalizeFirstLetter from '../../utils/capitalizeFirstLetter';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { closeSearchBar } from '../../context/reducers/generalSearch';
import { useDispatch, useSelector } from 'react-redux';
import ResultStatusTagItem from '../globals/ResultStatusTagItem';
import { checkIfUserHasRole } from '../../utils/checkIfUserHasRole';
import { ROLE_EDITOR } from '../../constants/roles';
import { type RootState } from '../../context/store';
import POSTS_STATUS from '../../constants/status';
import { Skeleton } from '@mui/material';
import PDFIcon from '../../icons/PDFIcon';
import { HTML_TEXT } from '../../constants/globals';

interface Stats {
  totalOpened: number;
  totalRead: number;
}

interface Props extends PublicationInterface {
  type?: string;
  stats?: Stats | null;
}

interface ContainerI {
  blocked: boolean;
}

export default function PublicationItem({
  type = 'home',
  id,
  lastPublicationDate,
  images,
  multimedia,
  openings,
  readings,
  title,
  hasAudio,
  status,
  createdDate,
  editor,
  stats,
  parentId,
  categories,
  contentType,
}: Props): JSX.Element {
  const categoriesList = useSelector((state: RootState) => state.categories.categoriesList);
  const userProfile = useSelector((state: RootState) => state.userProfile.userProfile);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pathBase = `${contentType === HTML_TEXT ? '/post/' : '/presentation/'}`;

  const getTitle = (): string => {
    return title || 'Sin título';
  };

  const checkIfUserIsTheEditorOfThePost = (): boolean => {
    if (editor !== null) {
      const username = editor?.username;
      if (username == null) return true;
      if (username === userProfile?.username) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  };

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (
      !checkIfUserHasRole(userProfile?.roles, ROLE_EDITOR) &&
      (status === POSTS_STATUS.PENDING_REVIEW || status === POSTS_STATUS.REVIEW_IN_PROGRESS)
    ) {
      event.preventDefault();
    }
  };

  const getTotalOpened = () => {
    if (stats === null || stats === undefined) return 0;
    return stats?.totalOpened;
  };

  const getTotalRead = () => {
    if (stats === null || stats === undefined) return 0;
    return stats?.totalRead;
  };

  const navigateToPublicationDetails = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (parentId === undefined) return;
    navigate(`${pathBase}${parentId}`, {
      state: { prevRouteName: location.pathname },
    });
  };

  const getCategories = (): any[] => {
    if (categories === null || categories?.length === 0) {
      return [];
    }
    if (categoriesList !== null) {
      const categoriesNames: any[] = [];
      const auxCategoriesList: any[] = [...categoriesList];
      categories?.forEach((category) => {
        auxCategoriesList?.forEach((auxCategory) => {
          if (category === auxCategory?.id) {
            categoriesNames.push({
              name: auxCategory?.name,
              color: auxCategory?.color,
            });
          }
        });
      });
      return categoriesNames;
    }
    return [];
  };

  return (
    <Container
      to={type === 'home' ? `${pathBase}${parentId}` : `${pathBase}${id}`}
      state={{ prevRouteName: location.pathname }}
      onClick={handleClick}
      blocked={
        !checkIfUserHasRole(userProfile?.roles, ROLE_EDITOR) &&
        (status === POSTS_STATUS.PENDING_REVIEW || status === POSTS_STATUS.REVIEW_IN_PROGRESS)
      }
    >
      <Row>
        <AuxDateContainer>
          <DateContainer>
            <DateText>
              {getLastPublicationDate(type === 'search' ? createdDate : lastPublicationDate)}
            </DateText>
          </DateContainer>
          <MobileIconsContainer>
            {contentType === HTML_TEXT ? (
              <>
                <PublicationIcon />
                {hasAudio && <AudioIcon />}
              </>
            ) : (
              <PDFIcon />
            )}
          </MobileIconsContainer>
        </AuxDateContainer>
        <ImageContainer>
          {images?.principalImage ? (
            <Image src={images?.principalImage} alt={title} />
          ) : (
            <WithoutImageIcon />
          )}
        </ImageContainer>
        <InfoContainer>
          <Title>{capitalizeFirstLetter(getTitle())}</Title>
          <IconsContainer>
            {contentType === HTML_TEXT ? (
              <>
                <PublicationIcon />
                {hasAudio && <AudioIcon />}
              </>
            ) : (
              <PDFIcon />
            )}
          </IconsContainer>
        </InfoContainer>
        {type === 'search' && <ResultStatusTagItem status={status as string} />}
        {type === 'home' && (
          <StatisticsContainer>
            <QuantityContainer>
              <EyeIcon color='#183582' />
              {stats == null || stats === undefined ? (
                <Skeleton style={{ marginLeft: '7px' }} variant='rounded' width={40} height={25} />
              ) : (
                <Quantity>{getTotalOpened()}</Quantity>
              )}
            </QuantityContainer>
            <QuantityContainer>
              <ReaderIcon color='#183582' />
              {stats == null || stats === undefined ? (
                <Skeleton style={{ marginLeft: '7px' }} variant='rounded' width={40} height={25} />
              ) : (
                <Quantity>{getTotalRead()}</Quantity>
              )}
            </QuantityContainer>
          </StatisticsContainer>
        )}
        {type === 'home' && (
          <CustomButton
            className='bg-primary-blue px-3 rounded-lg ml-2'
            onClick={navigateToPublicationDetails}
          >
            <SeeMoreButtonText>Ver artículo</SeeMoreButtonText>
          </CustomButton>
        )}
      </Row>
      <ContainerFooter>
        <Categories>
          {getCategories().map((category, index) => (
            <CategoryContainer style={{ backgroundColor: category?.color }} key={index}>
              <Category key={index} style={{ color: 'white' }}>
                {category.name}
              </Category>
            </CategoryContainer>
          ))}
        </Categories>
        {type === 'home' && (
          <Footer>
            <MobileStatisticsContainer>
              <>
                <QuantityContainer>
                  <EyeIcon color='#183582' />
                  {stats == null || stats === undefined ? (
                    <Skeleton
                      style={{ marginLeft: '7px' }}
                      variant='rounded'
                      width={40}
                      height={25}
                    />
                  ) : (
                    <Quantity>{getTotalOpened()}</Quantity>
                  )}
                </QuantityContainer>
                <QuantityContainer>
                  <ReaderIcon color='#183582' />
                  {stats == null || stats === undefined ? (
                    <Skeleton
                      style={{ marginLeft: '7px' }}
                      variant='rounded'
                      width={40}
                      height={25}
                    />
                  ) : (
                    <Quantity>{getTotalRead()}</Quantity>
                  )}
                </QuantityContainer>
              </>
            </MobileStatisticsContainer>
            <MobileCustomButton
              className='bg-primary-blue px-4 py-2 rounded-2xl ml-2'
              onClick={navigateToPublicationDetails}
            >
              <SeeMoreButtonText>Ver artículo</SeeMoreButtonText>
            </MobileCustomButton>
          </Footer>
        )}
      </ContainerFooter>
    </Container>
  );
}

const Container = styled(Link)<ContainerI>`
  position: relative;
  cursor: ${(props) => (props.blocked ? 'not-allowed' : 'pointer')};
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 94px;
  border-radius: 8px;
  margin-bottom: 10px;
  padding-right: 23px;
  transition: background-color 0.1s ease-out;

  :hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  @media screen and (max-width: 860px) {
    display: block;
    padding-left: 0px;
    padding-right: 0px;
    height: inherit;
    border: 1px solid;
    border-color: ${(props) => props.theme.colors.lightSkyBlue};
    padding-bottom: 10px;
  }
`;

const Row = styled.div`
  height: 94px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid;
  border-color: ${(props) => props.theme.colors.lightSkyBlue};
  padding-left: 16px;
  border-radius: 8px;

  @media screen and (max-width: 860px) {
    border: none;
    height: inherit;
    margin-top: 15px;
    align-items: flex-start;
  }
`;

const AuxDateContainer = styled.div`
  position: absolute;
  left: 0px;
  z-index: 100;
  top: 9px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const MobileIconsContainer = styled.div`
  display: none;
  @media screen and (max-width: 860px) {
    display: inline-flex;
    gap: 4px;
  }
`;

const ContainerFooter = styled.div`
  display: none;
  @media screen and (max-width: 860px) {
    display: block;
  }
`;

const Categories = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  padding-left: 16px;
  margin-top: 8px;
`;

const Category = styled.span`
  font-family: ${(props) => props.theme.fonts.medium};
  color: ${(props) => props.theme.colors.skyBlue};
  font-size: 13px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const CategoryContainer = styled.div`
  padding: 2px 10px;
  border-radius: 11px;
`;

const DateContainer = styled.div`
  background-color: ${(props) => props.theme.colors.primary};
  min-width: 94px;
  padding: 1px 8px;
  border-radius: 0px 6px 6px 0px;
`;

const DateText = styled.p`
  font-family: ${(props) => props.theme.fonts.semiBold};
  color: white;
  font-size: 14px;
`;

const ImageContainer = styled.div`
  position: relative;
  height: 72px;
  width: 72px;
  border-radius: 10px;
  overflow: hidden;
  background-color: ${(props) => props.theme.colors.lightSkyBlue};

  @media screen and (max-width: 860px) {
    height: 90px;
  }
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const InfoContainer = styled.div`
  flex: 1;
  padding-left: 13px;
`;

const Title = styled.p`
  font-family: ${(props) => props.theme.fonts.medium};
  color: ${(props) => props.theme.colors.darkGray};
  font-size: 16px;
  line-height: 21px;
  max-height: 44px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;

  @media screen and (max-width: 860px) {
    max-height: inherit;
    flex: 1;
    padding-top: 20px;
    -webkit-line-clamp: 3;
    line-clamp: 3;
  }
`;

const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 9px;
  margin-top: 5px;

  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const StatisticsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 40px;
  gap: 14px;

  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
  padding-right: 16px;
`;

const MobileStatisticsContainer = styled.div`
  display: none;
  @media screen and (max-width: 860px) {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding-left: 16px;
    margin-top: 8px;
  }
`;

const QuantityContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Quantity = styled.p`
  margin-left: 7px;
  min-width: 30px;
  text-align: left;
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.darkGray};
`;

const ButtonText = styled.p`
  font-family: ${(props) => props.theme.fonts.medium};
  color: white;
  text-transform: none;
`;

const CustomButton = styled(Button)`
  &&& {
    border: 1px solid;
    border-color: ${(props) => props.theme.colors.primary};
    @media screen and (max-width: 860px) {
      display: none;
    }
  }
`;

const MobileCustomButton = styled(Button)`
  &&& {
    border: 1px solid;
    border-color: ${(props) => props.theme.colors.primary};
  }
`;

const SeeMoreButtonText = styled.p`
  font-family: ${(props) => props.theme.fonts.medium};
  color: white;
  text-transform: none;
  font-size: 13px;
`;
