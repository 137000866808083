/* eslint-disable @typescript-eslint/promise-function-async */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CircleIcon from '@mui/icons-material/Circle';
import { useSelector, useDispatch } from 'react-redux';
import { Skeleton } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import type PublicationInterface from '../../interfaces/Publication';
import TrashIcon from '../../icons/TrashIcon';
import MovieIcon from '../../icons/MovieIcon';
import AudioIcon from '../../icons/AudioIcon';
import PublicationIcon from '../../icons/PublicationIcon';
import InformationIcon from '../../icons/InformationIcon';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import EyeIcon from '../../icons/EyeIcon';
import ReaderIcon from '../../icons/ReaderIcon';
import WithoutImageIcon from '../../icons/WithoutImageIcon';
import getAvatar from '../../utils/getAvatar';
import { type RootState } from '../../context/store';
import { getLastPublicationDate } from '../../utils/getLastPublicationDate';
import { deleteEntry } from '../../utils/entries';
import POSTS_STATUS from '../../constants/status';
import ConfirmForceDeleteModal from './ConfirmForceDeleteModal';
import CustomMenu from '../globals/CustomMenu';
import ConfirmSendToReviewModal from './ConfirmSendToReviewModal';
import { checkIfUserHasRole } from '../../utils/checkIfUserHasRole';
import { ROLE_EDITOR, ROLE_WRITER } from '../../constants/roles';
import { requestReviewForPost, startReviewOfPost } from '../../api/posts';
import { openSnackbar } from '../../context/reducers/generalSnackbar';
import { AxiosError } from 'axios';
import { POST_ERRORS } from '../../constants/errorMessages';
import {
  setPendingReviewPostAdded,
  setPublishedPostAdded,
  setPublishedQuantity,
} from '../../context/reducers/entries';
import { setPublishedQuantity as setPresentationsPublishedQuantity } from '../../context/reducers/presentations';
import { setEntriesPublished } from '../../context/reducers/entriesPublished';
import { FILE_TYPE, HTML_TEXT } from '../../constants/globals';
import PDFIcon from '../../icons/PDFIcon';
import { ARTICLE_SUCCESFUL_MESSAGES } from '../../constants/successfulMessages';
import { setPresentationsPublished } from '../../context/reducers/presentationsPublished';
import CreateNotificationModal from '../editor/CreateNotificationModal';

interface Props extends PublicationInterface {
  publications: PublicationInterface[] | null;
  postPublicationId?: number;
}

export default function PublishedItem({
  id,
  lastPublicationDate,
  images,
  multimedia,
  openings,
  readings,
  title,
  categories,
  writer,
  hasAudio,
  publications,
  contentType,
  postPublicationId,
}: Props): JSX.Element {
  const presentationsPublishedQuantity = useSelector(
    (state: RootState) => state.presentations.publishedQuantity,
  );
  const publishedQuantity = useSelector((state: RootState) => state.entries.publishedQuantity);
  const publishedPostDeleted = useSelector(
    (state: RootState) => state.entries.publishedPostDeleted,
  );
  const userProfile = useSelector((state: RootState) => state.userProfile.userProfile);
  const categoriesList = useSelector((state: RootState) => state.categories.categoriesList);
  const [confirmDeleteModalIsVisible, setConfirmDeleteModalIsVisible] = useState(false);
  const [confirmSendToReviewModallIsVisible, setConfirmSendToReviewModalIsVisible] =
    useState(false);
  const [confirmForceDeleteModalIsVisible, setConfirmForceDeleteModallIsVisible] = useState(false);
  const [errorDeleteMessage, setErrorDeleteMessage] = useState('');
  const [deleting, setDeleting] = useState(false);
  const [anchorMoreOptions, setAnchorMoreOptions] = useState<null | HTMLElement>(null);
  const [sendingToReview, setSendingToReview] = useState(false);
  const [createCampaignModalIsVisible, setCreateCampaignModalIsVisible] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pathBase = `${contentType === HTML_TEXT ? '/post/' : '/presentation/'}`;

  const options = [
    {
      id: 'create-campaign',
      isOwner: false,
      label: `Crear campaña`,
      action: () => {
        setAnchorMoreOptions(null);
        setCreateCampaignModalIsVisible(true);
      },
      color: '#0F1F41',
    },
    {
      id: 'delete-post',
      isOwner: !checkIfUserHasRole(userProfile?.roles, ROLE_WRITER),
      label: `Eliminar ${contentType === HTML_TEXT ? 'artículo' : 'PPT'}`,
      action: () => {
        setAnchorMoreOptions(null);
        setConfirmDeleteModalIsVisible(true);
      },
      color: '#CD4F4F',
    },
  ];

  if (checkIfUserHasRole(userProfile?.roles, ROLE_EDITOR)) {
    options.unshift({
      id: 'review-post',
      isOwner: false,
      label: `Revisar ${contentType === HTML_TEXT ? 'artículo' : 'PPT'}`,
      action: () => {
        reviewPost();
      },
      color: '#0F1F41',
    });
  }

  const handleClickMoreOptions = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    if (!sendingToReview || !deleting) {
      setAnchorMoreOptions(event.currentTarget);
    }
  };

  const getTitle = (): string => {
    return title || 'Sin título';
  };

  const getCategories = (): any[] => {
    if (categories === null || categories?.length === 0) {
      return [];
    }
    if (categoriesList !== null) {
      const categoriesNames: any[] = [];
      const auxCategoriesList: any[] = [...categoriesList];
      categories?.forEach((category) => {
        auxCategoriesList?.forEach((auxCategory) => {
          if (category === auxCategory?.id) {
            categoriesNames.push({
              name: auxCategory?.name,
              color: auxCategory?.color,
            });
          }
        });
      });
      return categoriesNames;
    }
    return [];
  };

  const confirmDeletePost = async (forceDelete: boolean): Promise<void> => {
    try {
      await deleteEntry(
        setConfirmDeleteModalIsVisible,
        deleting,
        setDeleting,
        id,
        dispatch,
        POSTS_STATUS.PUBLISHED,
        false,
        false,
        publishedPostDeleted,
        'entries',
        setConfirmForceDeleteModallIsVisible,
        undefined,
        publications,
        undefined,
        publishedQuantity,
        undefined,
        forceDelete,
        setErrorDeleteMessage,
        contentType,
      );
      setDeleting(false);
    } catch (err) {}
  };

  const reviewPost = () => {
    setAnchorMoreOptions(null);
    setConfirmSendToReviewModalIsVisible(true);
  };

  const confirmSendToReview = async () => {
    if (!sendingToReview) {
      try {
        setSendingToReview(true);
        await startReviewOfPost(id);
        setConfirmSendToReviewModalIsVisible(false);
        dispatch(
          openSnackbar({
            type: 'success',
            message:
              contentType === HTML_TEXT
                ? ARTICLE_SUCCESFUL_MESSAGES.SEND_TO_REVIEW_ARTICLE
                : ARTICLE_SUCCESFUL_MESSAGES.SEND_TO_REVIEW_PRESENTATION,
          }),
        );
        if (publications !== null) {
          if (contentType === HTML_TEXT) {
            dispatch(
              setEntriesPublished({
                publications: publications?.filter((publication) => publication.id !== id),
              }),
            );
          } else if (contentType === FILE_TYPE) {
            dispatch(
              setPresentationsPublished({
                publications: publications?.filter((publication) => publication.id !== id),
              }),
            );
          }
        }
        if (contentType === HTML_TEXT && publishedQuantity !== undefined) {
          dispatch(
            setPublishedQuantity({
              publishedQuantity: publishedQuantity - 1,
            }),
          );
        }
        if (contentType === FILE_TYPE && presentationsPublishedQuantity !== undefined) {
          dispatch(
            setPresentationsPublishedQuantity({
              publishedQuantity: presentationsPublishedQuantity - 1,
            }),
          );
        }
        setSendingToReview(false);
        dispatch(
          setPendingReviewPostAdded({
            pendingReviewPostAdded: true,
          }),
        );
        dispatch(
          setPublishedPostAdded({
            publishedPostAdded: true,
          }),
        );
        navigate(`${pathBase}${id}`, {
          state: { prevRouteName: location.pathname },
        });
      } catch (error: unknown) {
        setSendingToReview(false);
        let errorMessage = POST_ERRORS.REQUEST_REVIEW;
        let errorStatus;
        if (error instanceof AxiosError) {
          const errorData = error?.response?.data;
          errorStatus = error?.response?.status;
          if (errorData?.message) {
            errorMessage = errorData?.message;
          }
        }
        if (errorStatus !== 401) {
          dispatch(
            openSnackbar({
              type: 'error',
              message: errorMessage,
            }),
          );
        }
      }
    }
  };

  return (
    <>
      <Container to={`${pathBase}${id}`} state={{ prevRouteName: location.pathname }}>
        <Row>
          <AuxDateContainer>
            <DateContainer>
              <DateText>{getLastPublicationDate(lastPublicationDate)}</DateText>
            </DateContainer>
            <MobileIconsContainer>
              {contentType === HTML_TEXT ? (
                <>
                  <PublicationIcon />
                  {hasAudio && <AudioIcon />}
                </>
              ) : (
                <PDFIcon />
              )}
            </MobileIconsContainer>
          </AuxDateContainer>
          <ImageContainer>
            {images?.principalImage ? (
              <Image src={images?.principalImage} alt={title} />
            ) : (
              <WithoutImageIcon />
            )}
          </ImageContainer>
          <InfoContainer>
            <MainContainer>
              <Title>{getTitle()}</Title>
              {categoriesList == null ? (
                <Skeleton
                  variant='rectangular'
                  style={{
                    width: '50px',
                    height: '10px',
                    borderRadius: '8px',
                    marginTop: '3px',
                    marginBottom: '3px',
                  }}
                />
              ) : (
                <Categories>
                  {getCategories()?.length === 0 ? (
                    <Category style={{ color: '#183582' }}>Sin categorías</Category>
                  ) : (
                    getCategories().map((category, index) => (
                      <CategoryContainer style={{ backgroundColor: category?.color }} key={index}>
                        <Category key={index} style={{ color: 'white' }}>
                          {category.name}
                        </Category>
                      </CategoryContainer>
                    ))
                  )}
                </Categories>
              )}
              <MainContainerFooter>
                <Status>{getLastPublicationDate(lastPublicationDate)}</Status>
              </MainContainerFooter>
            </MainContainer>
            <IconsContainer>
              {contentType === HTML_TEXT ? (
                <>
                  <PublicationIcon />
                  {hasAudio && <AudioIcon />}
                </>
              ) : (
                <PDFIcon />
              )}
            </IconsContainer>
            <AuthorContainer>
              <AvatarContainer>
                <Avatar>{getAvatar(writer?.names)}</Avatar>
              </AvatarContainer>
              <AuthorName>{writer?.names}</AuthorName>
            </AuthorContainer>
            {/* <StatisticsContainer>
            <QuantityContainer>
              <EyeIcon />
              <Quantity>88</Quantity>
            </QuantityContainer>
            <QuantityContainer>
              <ReaderIcon />
              <Quantity>160</Quantity>
            </QuantityContainer>
          </StatisticsContainer> */}
          </InfoContainer>
          <ButtonsContainer>
            <CustomButton className='bg-primary-blue px-5 rounded-2xl mr-2'>
              <ButtonText>{contentType === HTML_TEXT ? 'Ver artículo' : 'Ver PPT'}</ButtonText>
            </CustomButton>
            <IconButton onClick={handleClickMoreOptions}>
              <MoreHorizIcon style={{ color: '#183582' }} />
            </IconButton>
          </ButtonsContainer>
        </Row>
        <MobileContainerFooter>
          <MobileCategories>
            {getCategories().map((category, index) => (
              <CategoryContainer style={{ backgroundColor: category?.color }} key={index}>
                <Category key={index} style={{ color: 'white' }}>
                  {category.name}
                </Category>
              </CategoryContainer>
            ))}
          </MobileCategories>
          <CustomButton className='bg-primary-blue px-5 rounded-2xl mr-2'>
            <ButtonText>{contentType === HTML_TEXT ? 'Ver artículo' : 'Ver PPT'}</ButtonText>
          </CustomButton>
        </MobileContainerFooter>
      </Container>
      <CustomMenu
        anchorEl={anchorMoreOptions}
        setAnchorEl={setAnchorMoreOptions}
        options={options}
        color={'#0F1F41'}
      />
      <ConfirmSendToReviewModal
        isVisible={confirmSendToReviewModallIsVisible}
        setIsVisible={setConfirmSendToReviewModalIsVisible}
        title={`¿Enviar ${contentType === HTML_TEXT ? 'artículo' : 'PPT'} a revisión?`}
        message={`Estás a punto de enviar ${
          contentType === HTML_TEXT ? 'un artículo publicado' : 'un PPT publicado'
        } a revisión, eso te permitirá modificarlo y por tanto actualizar la publicación actual.`}
        confirmSendToReview={confirmSendToReview}
        sending={sendingToReview}
        setSending={setSendingToReview}
      />
      <ConfirmDeleteModal
        isVisible={confirmDeleteModalIsVisible}
        setIsVisible={setConfirmDeleteModalIsVisible}
        title={
          contentType === HTML_TEXT
            ? '¿Eliminar publicación definitivamente?'
            : '¿Eliminar PPT definitivamente?'
        }
        message='Se eliminarán sus métricas y desaparecerá de la biblioteca de los lectores sin poder recuperarse.'
        deleting={deleting}
        setDeleting={setDeleting}
        confirmDeletePost={() => confirmDeletePost(false)}
      />
      <ConfirmForceDeleteModal
        isVisible={confirmForceDeleteModalIsVisible}
        setIsVisible={setConfirmForceDeleteModallIsVisible}
        message={errorDeleteMessage}
        deleting={deleting}
        setDeleting={setDeleting}
        confirmDeletePost={() => confirmDeletePost(true)}
      />
      <CreateNotificationModal
        isVisible={createCampaignModalIsVisible}
        setIsVisible={setCreateCampaignModalIsVisible}
        isPresentationPage={contentType === FILE_TYPE}
        thumbnailUrl={images?.principalImage ?? ''}
        postId={id}
        publicationId={postPublicationId as number}
        page='entries'
        contentType={contentType}
        title={title}
      />
    </>
  );
}

const Container = styled(Link)`
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid;
  border-bottom-color: ${(props) => props.theme.colors.gray2};
  height: 97px;
  padding-right: 20px;
  padding-left: 20px;
  transition: background-color 0.1s ease-out;

  :hover {
    background-color: rgba(0, 0, 0, 0.02);
  }

  @media screen and (max-width: 860px) {
    display: block;
    height: inherit;
    padding-top: 15px;
    border: 1px solid;
    border-color: ${(props) => props.theme.colors.lightSkyBlue};
    border-radius: 8px;
    margin-bottom: 8px;
    padding-right: 10px;
  }
`;

const Row = styled.div`
  height: 97px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media screen and (max-width: 860px) {
    height: inherit;
    align-items: flex-start;
  }
`;

const AuxDateContainer = styled.div`
  display: none;
  position: absolute;
  left: 0px;
  z-index: 100;
  top: 9px;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  @media screen and (max-width: 860px) {
    display: flex;
  }
`;

const MobileIconsContainer = styled.div`
  display: none;
  @media screen and (max-width: 860px) {
    display: inline-flex;
    gap: 4px;
  }
`;

const DateContainer = styled.div`
  background-color: ${(props) => props.theme.colors.primary};
  min-width: 94px;
  padding: 1px 8px;
  border-radius: 0px 6px 6px 0px;
`;

const DateText = styled.p`
  font-family: ${(props) => props.theme.fonts.semiBold};
  color: white;
  font-size: 14px;
`;

const ImageContainer = styled.div`
  position: relative;
  height: 72px;
  width: 72px;
  border-radius: 10px;
  overflow: hidden;
  background-color: ${(props) => props.theme.colors.lightSkyBlue};
  margin-left: 9px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 860px) {
    height: 90px;
    margin-left: 0px;
  }
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const InfoContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  @media screen and (max-width: 860px) {
    padding-top: 15px;
  }
`;

const MainContainer = styled.div`
  padding-left: 12px;
  flex: 1;
`;

const Title = styled.p`
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.darkGray};
  font-size: 16px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;

  @media screen and (max-width: 860px) {
    -webkit-line-clamp: 3;
    line-clamp: 3;
  }
`;

const Categories = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  margin-top: 2px;

  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const MobileCategories = styled.div`
  display: none;
  @media screen and (max-width: 860px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    flex: 1;
    flex-wrap: wrap;
  }
`;

const Category = styled.span`
  font-family: ${(props) => props.theme.fonts.medium};
  color: ${(props) => props.theme.colors.skyBlue};
  font-size: 13px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const MainContainerFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;

  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const Status = styled.p`
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.gray2};
  font-size: 13px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const Date = styled.p`
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.gray2};
  font-size: 13px;
`;

const AuthorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 25px;

  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const AvatarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  border-radius: 100%;
  background-color: ${(props) => props.theme.colors.accentBlue};
`;

const Avatar = styled.p`
  color: white;
  font-family: ${(props) => props.theme.fonts.regular};
  font-size: 13px;
  text-transform: uppercase;
`;

const AuthorName = styled.p`
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.darkGray};
  margin-left: 12px;
  max-width: 150px;
  text-transform: capitalize;
`;

const StatisticsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  margin-right: 38px;
  gap: 14px;
`;

const QuantityContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Quantity = styled.p`
  margin-left: 7px;
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.darkGray};
`;

const ButtonsContainer = styled.div`
  flex-direction: row;
  align-items: center;

  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const CustomButton = styled(Button)`
  margin-left: 5px;
`;

const ButtonText = styled.p`
  font-family: ${(props) => props.theme.fonts.medium};
  color: white;
  text-transform: none;
  font-size: 13px;
  white-space: nowrap;
`;

const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 40px;
  gap: 10px;
  margin-right: 40px;
  margin-left: 30px;

  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const CategoryContainer = styled.div`
  padding: 2px 10px;
  border-radius: 11px;
`;

const MobileContainerFooter = styled.div`
  display: none;
  @media screen and (max-width: 860px) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;
