import React from 'react';
import styled from 'styled-components';
import CircleIcon from '@mui/icons-material/Circle';

export default function PostItem(): JSX.Element {
  return (
    <Container>
      <InformationContainer>
        <CircleIcon style={{ color: '#0094CF', height: '12px', width: '12px', marginTop: '5px' }} />
        <Title>
          <Date>10/02/23 | </Date>
          📰 La agroexportación controlada, pero atentada por panorama político e importaciones
          factibles
        </Title>
      </InformationContainer>
      <Footer>
        <CircleIcon style={{ color: 'white', height: '12px', width: '12px', marginTop: '5px' }} />
        <QuantitiesContainer>
          <Quantity>01: 25 votos{'(30%)'}</Quantity>
          <Quantity>02: 40 votos{'(38%)'}</Quantity>
          <Quantity>02: 30 votos{'(32%)'}</Quantity>
        </QuantitiesContainer>
      </Footer>
    </Container>
  );
}

const Container = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors.gray2};
  padding-top: 15px;
`;

const InformationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 13px;
  padding-bottom: 15px;
`;

const Title = styled.p`
  flex: 1;
  font-family: ${(props) => props.theme.fonts.medium};
  color: ${(props) => props.theme.colors.darkGray};
`;

const Date = styled.span`
  color: ${(props) => props.theme.colors.gray2};
`;

const Footer = styled.footer`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
  border-top: 1px solid ${(props) => props.theme.colors.gray2};
  gap: 13px;
`;

const QuantitiesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 23px;
  flex: 1;
`;

const Quantity = styled.p`
  font-family: ${(props) => props.theme.fonts.medium};
  color: ${(props) => props.theme.colors.skyBlue};
  font-size: 14px;
`;
