import React, { useEffect, useRef, useState } from 'react';
import type AuthorDetailsI from '../../interfaces/AuthorDetails';
import ComboBox from './ComboBox';
import { getAuthors } from '../../api/authors';
import { PAGE_SIZE } from '../../constants/pagination';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../../context/reducers/generalSnackbar';
import { AUTHORS_ERRORS, SETTINGS_ERRORS } from '../../constants/errorMessages';
import { AxiosError } from 'axios';
import styled from 'styled-components';
import { callPostDetails } from '../../utils/editor';
import { useParams } from 'react-router-dom';
import { setAuthorId, setIsRemovingAuthor } from '../../context/reducers/authors';
import { addAuthorsToPost, deleteAuthorFromPost } from '../../api/posts';
import { MAX_AUTHORS_QUANTITY } from '../../constants/globals';
import { type AuthorItem } from '../../interfaces/AuthorDetails';
import {
  SETTINGS_ADD_SUCCESFUL_MESSAGES,
  SETTINGS_REMOVE_SUCCESFUL_MESSAGES,
} from '../../constants/successfulMessages';

interface Props {
  page?: string;
  authorsIDs?: any[];
  setAuthorsIDs?: React.Dispatch<React.SetStateAction<any[]>>;
  selectedAuthors?: any[];
  setSelectedAuthors?: React.Dispatch<React.SetStateAction<any[]>>;
  label?: string;
  parent?: string;
  placeholder?: string;
  setCurrentVersion?: React.Dispatch<React.SetStateAction<number>>;
}

export default function ComboBoxAuthorsWrapped({
  page = 'editor',
  authorsIDs,
  setAuthorsIDs,
  label = '',
  parent,
  placeholder = 'Autor',
  selectedAuthors,
  setSelectedAuthors,
  setCurrentVersion,
}: Props) {
  const [authors, setAuthors] = useState<AuthorDetailsI[] | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [authorsTotal, setAuthorsTotal] = useState(0);
  const [isLoadingMoreData, setIsLoadingMoreData] = useState(false);
  const [canLoadMoreData, setCanLoadMoreData] = useState(false);
  const [wantToLoadMoreData, setWantToLoadMoreData] = useState(false);
  const [auxSelectedAuthors, setAuxSelectedAuthors] = useState(selectedAuthors);
  const dispatch = useDispatch();
  const { postId } = useParams();
  const isMounted = useRef(false);

  const callApiToGetAuthors = (name: string): void => {
    getAuthors(name || '', 25, 0)
      .then((res) => {
        setAuthors(res.content);
        setAuthorsTotal(res.totalElements);
        if (!res.last) {
          setCurrentPage(1);
          setCanLoadMoreData(true);
        } else {
          setCurrentPage(0);
          setCanLoadMoreData(false);
        }
      })
      .catch((error: unknown) => {
        let errorStatus;
        if (error instanceof AxiosError) {
          errorStatus = error?.response?.status;
        }
        if (errorStatus !== 401) {
          dispatch(
            openSnackbar({
              type: 'error',
              message: AUTHORS_ERRORS.AUTHORS_LIST,
            }),
          );
        }
      });
  };

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    callApiToGetAuthors('');
  }, []);

  const getAddedAuthor = (selectedAuthors: any[], currentAuthors: any[]): any[] => {
    const addedAuthors: any[] = [];
    let band = false;
    selectedAuthors.forEach((selectedAuthor) => {
      currentAuthors.forEach((currentAuthor) => {
        if (
          (selectedAuthor.id ?? selectedAuthor.authorId) ===
          (currentAuthor.authorId ?? currentAuthor.id)
        ) {
          band = true;
        }
      });
      if (!band) {
        addedAuthors.push(selectedAuthor);
      }
      band = false;
    });
    const arrayWithFirstElement = addedAuthors.slice(0, 1);
    const formattedAuthors = arrayWithFirstElement.map((item) => {
      return {
        authorId: item.authorId ?? item?.id,
        names: item.names,
      };
    });
    return formattedAuthors;
  };

  const handleAddedAuthor = async (addedAuthor: AuthorItem[]) => {
    try {
      dispatch(
        setIsRemovingAuthor({
          isRemovingAuthor: true,
        }),
      );
      dispatch(
        setAuthorId({
          authorId: addedAuthor[0]?.authorId ?? addedAuthor[0].authorId,
        }),
      );
      await addAuthorsToPost(postId, addedAuthor);
      dispatch(
        openSnackbar({
          type: 'success',
          message: SETTINGS_ADD_SUCCESFUL_MESSAGES.ADD_AUTHOR,
        }),
      );
      dispatch(
        setIsRemovingAuthor({
          isRemovingAuthor: false,
        }),
      );
      dispatch(
        setAuthorId({
          authorId: null,
        }),
      );
    } catch (error: unknown) {
      dispatch(
        openSnackbar({
          type: 'error',
          message: SETTINGS_ERRORS.ADD_AUTHOR,
        }),
      );
      dispatch(
        setIsRemovingAuthor({
          isRemovingAuthor: false,
        }),
      );
    }
  };

  const getDeletedAuthor = (
    currentSelectedAuthors: any[],
    newCurrentSelectedAuthors: any[],
  ): number => {
    const deletedAuthors: any[] = [];
    let band = false;
    currentSelectedAuthors.forEach((currentAuthor) => {
      newCurrentSelectedAuthors.forEach((selectedAuthor) => {
        if (
          (currentAuthor.authorId ?? currentAuthor.id) ===
          (selectedAuthor.id ?? selectedAuthor.authorId)
        ) {
          band = true;
        }
      });
      if (!band) {
        deletedAuthors.push(currentAuthor);
      }
      band = false;
    });
    return deletedAuthors[0]?.authorId ?? deletedAuthors[0]?.id;
  };

  const handleRemoveAuthor = async (deletedAuthor: number) => {
    try {
      dispatch(
        setIsRemovingAuthor({
          isRemovingAuthor: true,
        }),
      );
      dispatch(
        setAuthorId({
          authorId: deletedAuthor,
        }),
      );
      await deleteAuthorFromPost(postId, deletedAuthor);
      dispatch(
        openSnackbar({
          type: 'success',
          message: SETTINGS_REMOVE_SUCCESFUL_MESSAGES.REMOVE_AUTHOR,
        }),
      );
      dispatch(
        setIsRemovingAuthor({
          isRemovingAuthor: false,
        }),
      );
      dispatch(
        setAuthorId({
          authorId: null,
        }),
      );
    } catch (err: unknown) {
      dispatch(
        setIsRemovingAuthor({
          isRemovingAuthor: false,
        }),
      );
    }
  };

  const handleChange = async (
    values: any[] | string | null,
    deleteFromTagChip: React.MutableRefObject<boolean>,
  ): Promise<void> => {
    try {
      if (page === 'editor' && auxSelectedAuthors !== undefined && !deleteFromTagChip.current) {
        const previousSelectedAuthors = [...auxSelectedAuthors];
        if (isMounted.current) {
          if (values !== undefined && values !== null) {
            if (
              previousSelectedAuthors.length < values?.length &&
              values?.length <= MAX_AUTHORS_QUANTITY
            ) {
              const addedAuthor = getAddedAuthor(values as any[], previousSelectedAuthors);
              await handleAddedAuthor(addedAuthor);
            } else {
              const deletedAuthor = getDeletedAuthor(previousSelectedAuthors, values as any[]);
              await handleRemoveAuthor(deletedAuthor);
            }
            if (postId !== undefined && setCurrentVersion !== undefined) {
              await callPostDetails(postId, setCurrentVersion);
            }
          }
        }
      }
      if (setSelectedAuthors !== undefined && Array.isArray(values)) {
        setSelectedAuthors(
          values?.map((item) => {
            return {
              authorId: item?.authorId ?? item.id,
              names: item?.names,
            };
          }),
        );
        if (page === 'editor' && values?.length <= MAX_AUTHORS_QUANTITY) {
          setAuxSelectedAuthors(
            values?.map((item) => {
              return {
                authorId: item?.authorId ?? item.id,
                names: item?.names,
              };
            }),
          );
        }
      }
    } catch (err) {}
  };

  const loadMoreData = async () => {
    if (canLoadMoreData && !isLoadingMoreData && authors !== null) {
      setIsLoadingMoreData(true);
      try {
        const response = await getAuthors(encodeURI('') || '', PAGE_SIZE, currentPage);
        const { last, content } = response;
        if (!last) {
          setCurrentPage(currentPage + 1);
          setCanLoadMoreData(true);
        } else {
          setCanLoadMoreData(false);
        }
        setIsLoadingMoreData(false);
        if (authors != null) {
          setAuthors([...authors, ...content]);
          setWantToLoadMoreData(false);
        }
      } catch (error: unknown) {
        let errorStatus;
        if (error instanceof AxiosError) {
          errorStatus = error?.response?.status;
        }
        if (errorStatus !== 401) {
          dispatch(
            openSnackbar({
              type: 'error',
              message: AUTHORS_ERRORS.AUTHORS_LIST,
            }),
          );
        }
      }
    }
  };

  useEffect(() => {
    if (wantToLoadMoreData) {
      loadMoreData();
    }
  }, [wantToLoadMoreData]);

  return (
    <ComboBox
      parent={parent ?? undefined}
      label={label}
      placeholder={placeholder}
      multiple={true}
      values={
        page === 'entries'
          ? selectedAuthors?.map((author) => author)
          : auxSelectedAuthors?.map((author) => author)
      }
      handleChange={handleChange}
      auxOptions={authors}
      setWantToLoadMoreData={setWantToLoadMoreData}
      type='author'
      canLoadMoreData={canLoadMoreData}
      page={page}
      setCurrentVersion={setCurrentVersion}
    />
  );
}

const ComboBoxContainer = styled.div``;
