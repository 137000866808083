import { createSlice } from '@reduxjs/toolkit';
import type DraftInterface from '../../interfaces/Draft';

interface InitialState {
  draftPostDeleted: boolean;
  draftPostCreated: boolean;
  draftPostUpdated: boolean;
  drafts: DraftInterface[] | null;
  currentPage: number;
  draftsTotal: number;
  isLoadingMoreData: boolean;
  canLoadMoreData: boolean;
}

const initialState: InitialState = {
  draftPostDeleted: false,
  draftPostCreated: false,
  draftPostUpdated: false,
  drafts: null,
  currentPage: 0,
  draftsTotal: 0,
  isLoadingMoreData: false,
  canLoadMoreData: false,
};

const homeDraftsSlice = createSlice({
  name: 'homeDrafts',
  initialState,
  reducers: {
    setHomeDraftPostDeleted: (state, action) => {
      state.draftPostDeleted = action.payload.draftPostDeleted;
    },
    setHomeDraftPostCreated: (state, action) => {
      state.draftPostCreated = action.payload.draftPostCreated;
    },
    setHomeDraftPostUpdated: (state, action) => {
      state.draftPostUpdated = action.payload.draftPostUpdated;
    },
    setHomeDrafts: (state, action) => {
      state.drafts = action.payload.drafts;
    },
    setHomeDraftsCurrentPage: (state, action) => {
      state.currentPage = action.payload.currentPage;
    },
    setHomeDraftsTotal: (state, action) => {
      state.draftsTotal = action.payload.draftsTotal;
    },
    setHomeDraftsIsLoadingMoreData: (state, action) => {
      state.isLoadingMoreData = action.payload.isLoadingMoreData;
    },
    setHomeDraftsCanLoadMoreData: (state, action) => {
      state.canLoadMoreData = action.payload.canLoadMoreData;
    },
  },
});

export const {
  setHomeDrafts,
  setHomeDraftsCanLoadMoreData,
  setHomeDraftsCurrentPage,
  setHomeDraftsIsLoadingMoreData,
  setHomeDraftsTotal,
  setHomeDraftPostDeleted,
  setHomeDraftPostCreated,
  setHomeDraftPostUpdated,
} = homeDraftsSlice.actions;
export default homeDraftsSlice.reducer;
