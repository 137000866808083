import React, { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import ModalContainer from '../globals/ModalContainer';
import CircularProgress from '@mui/material/CircularProgress';

interface Props {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  creating: boolean;
  setCreating: React.Dispatch<React.SetStateAction<boolean>>;
  createTag: (tag: string) => void;
  newTag: string;
  setNewTag: React.Dispatch<React.SetStateAction<string>>;
  formError: any;
  setFormError: React.Dispatch<React.SetStateAction<any>>;
}

interface CustomButtonI {
  withBackground: boolean;
  isDisabled: boolean;
}

interface CustomButtonTextI {
  white: boolean;
}

interface InputContainerI {
  focused: boolean;
  error: boolean;
}

const MAX_QUANTITY_OF_CHARACTERS = 100;

export default function NewTagModal({
  isVisible,
  setIsVisible,
  creating,
  setCreating,
  createTag,
  newTag,
  setNewTag,
  formError,
  setFormError,
}: Props): JSX.Element {
  const [inputIsFocused, setInputIsFocused] = useState(false);

  useEffect(() => {
    if (!isVisible) {
      setFormError({});
    }
  }, [isVisible]);

  const saveTag = (): void => {
    createTag(newTag?.trim());
  };

  const handleClose = (): void => {
    setIsVisible(false);
    if (!creating) {
      setFormError({});
      setNewTag('');
    }
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setNewTag('');
    setNewTag(event.target.value);
  };

  return (
    <ModalContainer
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      paddingTop={12}
      paddingRight={20}
      paddingLeft={25}
      setIsMainValue={setNewTag}
    >
      <Container>
        <Header>
          <IconButton onClick={handleClose}>
            <CloseIcon color='primary' />
          </IconButton>
        </Header>
        <Title>Crear etiqueta</Title>
        <Subtitle>Nombre de la etiqueta</Subtitle>
        <InputContainer focused={inputIsFocused} error={formError?.message !== undefined}>
          <Input
            maxLength={MAX_QUANTITY_OF_CHARACTERS}
            placeholder='Escribe aquí'
            value={newTag}
            onChange={handleOnChange}
            onFocus={() => {
              setInputIsFocused(true);
            }}
            onBlur={() => {
              setInputIsFocused(false);
            }}
          />
          <CharactersNumber>{MAX_QUANTITY_OF_CHARACTERS - newTag?.length}</CharactersNumber>
        </InputContainer>
        {formError?.message && <ErrorMessage>{formError?.message}</ErrorMessage>}
        <ButtonsContainer>
          <CustomButton
            disabled={creating}
            isDisabled={creating}
            onClick={handleClose}
            withBackground={false}
          >
            <ButtonText white={false}>Cancelar</ButtonText>
          </CustomButton>
          <CustomButton
            isDisabled={newTag === '' || creating}
            disabled={newTag === '' || creating}
            onClick={saveTag}
            withBackground={true}
          >
            {creating ? (
              <CircularProgress size={22} style={{ color: 'white' }} />
            ) : (
              <ButtonText white={true}>Guardar</ButtonText>
            )}
          </CustomButton>
        </ButtonsContainer>
      </Container>
    </ModalContainer>
  );
}

const Container = styled.div``;

const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const Title = styled.p`
  font-family: ${(props) => props.theme.fonts.semiBold};
  color: ${(props) => props.theme.colors.black};
  font-size: 20px;
  margin-bottom: 15px;
`;

const Subtitle = styled.p`
  font-family: ${(props) => props.theme.fonts.semiBold};
  color: ${(props) => props.theme.colors.darkGray};
  font-size: 14px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
  margin-top: 22px;
`;

const CustomButton = styled(Button)<CustomButtonI>`
  &&& {
    border: 1px solid;
    border-color: ${(props) => (props.isDisabled ? 'transparent' : props.theme.colors.primary)};
    border-radius: 20px;
    background-color: ${(props) =>
      !props?.withBackground
        ? 'transparent'
        : props.isDisabled
        ? props.theme.colors.btnPrimaryDisabled
        : props.theme.colors.primary};
  }
`;

const ButtonText = styled.p<CustomButtonTextI>`
  text-transform: none;
  font-family: ${(props) => props.theme.fonts.semiBold};
  color: ${(props) => (props.white ? 'white' : props.theme.colors.primary)};
  font-size: 13px;
  padding: 0px 15px;
`;

const InputContainer = styled.div<InputContainerI>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  margin-top: 10px;
  border: 1px solid #f0f6fa;
  border-radius: 5px;
  padding-right: 10px;
  padding-left: 10px;
  width: 450px;
  border-color: ${(props) =>
    props.error
      ? props.theme.colors.red
      : props.focused
      ? props.theme.colors.primary
      : props.theme.colors.gray};

  @media screen and (max-width: 860px) {
    width: 100%;
  }
`;

const Input = styled.input`
  flex: 1;
  height: 100%;
  margin-right: 5px;
  border: none;
  outline: none;
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.black};
`;

const CharactersNumber = styled.p`
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.gray2};
`;

const ErrorMessage = styled.p`
  font-family: ${(props) => props.theme.fonts.regular};
  font-size: 12px;
  color: ${(props) => props.theme.colors.red};
  margin-top: 8px;
  padding-left: 15px;
`;
