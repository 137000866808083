import React from 'react';
import type IconInterface from '../interfaces/Icon';

export default function HomeIcon({ color = '#F8F8F8' }: IconInterface): JSX.Element {
  return (
    <svg width='18' height='19' viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10 18.5V8.5H18V18.5H10ZM0 10.5V0.5H8V10.5H0ZM6 8.5V2.5H2V8.5H6ZM0 18.5V12.5H8V18.5H0ZM2 16.5H6V14.5H2V16.5ZM12 16.5H16V10.5H12V16.5ZM10 0.5H18V6.5H10V0.5ZM12 2.5V4.5H16V2.5H12Z'
        fill={color}
      />
    </svg>
  );
}
