import { createSlice } from '@reduxjs/toolkit';
import type Campaign from '../../interfaces/Campaigns';

interface InitialState {
  scheduled: Campaign[] | null;
  currentPage: number;
  total: number;
  isLoadingMoreData: boolean;
  canLoadMoreData: boolean;
}

const initialState: InitialState = {
  scheduled: null,
  currentPage: 0,
  total: 0,
  isLoadingMoreData: false,
  canLoadMoreData: false,
};

const scheduledCampaignsSlice = createSlice({
  name: 'scheduledCampaigns',
  initialState,
  reducers: {
    setScheduledCampaigns: (state, action) => {
      state.scheduled = action.payload.scheduled;
    },
    setScheduledCampaignsCurrentPage: (state, action) => {
      state.currentPage = action.payload.currentPage;
    },
    setScheduledCampaignsTotal: (state, action) => {
      state.total = action.payload.total;
    },
    setScheduledCampaignsIsLoadingMoreData: (state, action) => {
      state.isLoadingMoreData = action.payload.isLoadingMoreData;
    },
    setScheduledCampaignsCanLoadMoreData: (state, action) => {
      state.canLoadMoreData = action.payload.canLoadMoreData;
    },
  },
});

export const {
  setScheduledCampaigns,
  setScheduledCampaignsCanLoadMoreData,
  setScheduledCampaignsCurrentPage,
  setScheduledCampaignsIsLoadingMoreData,
  setScheduledCampaignsTotal,
} = scheduledCampaignsSlice.actions;
export default scheduledCampaignsSlice.reducer;
