import { apiClient } from './config';
import type AuthorDetails from '../interfaces/AuthorDetails';

export const getAuthors = async (name: string, pageSize: number, page: number) => {
  if (name) {
    const { data } = await apiClient.get(
      `authors/find/all?q=${name}&page=${page}&size=${pageSize}&sort=names,ASC`,
    );
    return data;
  } else {
    const { data } = await apiClient.get(
      `authors/find/all?page=${page}&size=${pageSize}&sort=names,ASC`,
    );
    return data;
  }
};

export const createAuthor = async (payload: AuthorDetails) => {
  const { data } = await apiClient.post('authors', payload);
  return data;
};

export const getAuthor = async (authorId: number) => {
  const { data } = await apiClient.get(`authors/${authorId}`);
  return data;
};

export const deleteAuthor = async (authorId: number | null | string) => {
  const { data } = await apiClient.delete(`authors/${authorId}`);
  return data;
};

export const updateAuthor = async (authorId: number, payload: AuthorDetails) => {
  const { data } = await apiClient.patch(`authors/${authorId}`, payload);
  return data;
};
