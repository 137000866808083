import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { type EditorState } from 'draft-js';
import { requestReviewForPost } from '../../api/posts';
import { openSnackbar } from '../../context/reducers/generalSnackbar';
import { POST_ERRORS } from '../../constants/errorMessages';
import type PostInformation from '../../interfaces/PostInformation';
import { type AuthorItem } from '../../interfaces/AuthorDetails';
import { type RootState } from '../../context/store';
import { getImagesQuantity, getPlainText } from '../../utils/wysiwyg';
import POSTS_STATUS from '../../constants/status';
import { KEY_PARAGRAPH, PDF_URL } from '../../constants/editorKeys';
import {
  FILE_TYPE,
  HTML_TEXT,
  MAX_AUTHORS_QUANTITY,
  MAX_CATEGORIES_QUANTITY,
  MAX_IMAGES_QUANTITY,
  MAX_TAGS_QUANTITY,
} from '../../constants/globals';
import type TagDetails from '../../interfaces/TagDetails';
import ConfirmPublicationModal from '../globals/ConfirmPublicationModal';
import { setHomeDraftPostUpdated } from '../../context/reducers/homeDrafts';
import {
  setDraftPostUpdated,
  setPendingReviewPostAdded,
  setPendingReviewQuantity,
  setPublishedPostAdded,
} from '../../context/reducers/entries';
import { setPendingReviewQuantity as setPresentationsPendingReviewQuantity } from '../../context/reducers/presentations';
import { publishPost } from '../../utils/editor';
import { ARTICLE_SUCCESFUL_MESSAGES } from '../../constants/successfulMessages';

interface Props {
  postId: number | string | undefined;
  savingChanges: boolean;
  setSavingChange: React.Dispatch<React.SetStateAction<boolean>>;
  changingState: boolean;
  setChangingState: React.Dispatch<React.SetStateAction<boolean>>;
  setIsOpen: (v: boolean) => void;
  handleClickSaveDraft: () => Promise<void>;
  categories: any[];
  authors: AuthorItem[];
  currentStatus: string | null;
  selectedCategories: any[];
  selectedAuthors: AuthorItem[];
  selectedTags: TagDetails[];
  isPresentationPage?: boolean;
  isNotificationModalVisible: boolean;
  setIsNotificationModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ButtonI {
  bgColor: string;
  withBorder: boolean;
}

export default function SettingDrawerFooter({
  postId,
  savingChanges,
  setSavingChange,
  changingState,
  setChangingState,
  setIsOpen,
  handleClickSaveDraft,
  categories,
  authors,
  currentStatus,
  selectedAuthors,
  selectedCategories,
  selectedTags,
  isPresentationPage,
  isNotificationModalVisible,
  setIsNotificationModalVisible,
}: Props): JSX.Element {
  const unsavedChanges = useSelector((state: RootState) => state.editor.unsavedChanges);
  const elementsContent = useSelector((state: RootState) => state.editor.elementsContent);
  const pendingReviewQuantity = useSelector(
    (state: RootState) => state.entries.pendingReviewQuantity,
  );
  const presentationsPendingReviewQuantity = useSelector(
    (state: RootState) => state.presentations.pendingReviewQuantity,
  );
  const isRemovingSubscription = useSelector(
    (state: RootState) => state.subscriptions.isRemovingSubscription,
  );
  const isRemovingCategory = useSelector((state: RootState) => state.categories.isRemovingCategory);
  const isRemovingTag = useSelector((state: RootState) => state.tags.isRemovingTag);
  const isRemovingAuthor = useSelector((state: RootState) => state.authors.isRemovingAuthor);
  const [confirmPublicationModalIsVisible, setConfirmPublicationModalIsVisible] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const checkIfPostHasSummary = (): boolean => {
    const summary = elementsContent[1].content;
    return summary !== '';
  };

  const checkIfPostHasTitle = (): boolean => {
    const title = elementsContent[0].content;
    return title !== '';
  };

  const checkIfPostHasPDF = (): boolean => {
    const index = elementsContent.findIndex((item) => item.customKey === PDF_URL);
    if (index === -1) return false;
    const pdfUrl = elementsContent[index].content;
    return pdfUrl !== null && pdfUrl !== '';
  };

  const checkIfPostHasKeyIdeas = (): boolean => {
    const keyIdeas = elementsContent[2].content as EditorState[];
    let band = true;
    keyIdeas.forEach((keyIdea) => {
      const currentContent = getPlainText(keyIdea);
      if (currentContent === '') {
        band = false;
      }
    });
    return band;
  };

  const checkIfPostHasContent = (): boolean => {
    const index = elementsContent.findIndex((item) => item.customKey === KEY_PARAGRAPH);
    if (index === -1) return false;
    const content = elementsContent[index].content;
    return content !== null;
  };

  const checkIfPostHasMainImage = (): boolean => {
    const mainImage = elementsContent[4].content;
    return mainImage !== null;
  };

  const checkImagesQuantityOfPost = (): boolean => {
    const mainParagraph = elementsContent[3].content as EditorState;
    return getImagesQuantity(mainParagraph) <= MAX_IMAGES_QUANTITY;
  };

  const checkPostInformation = (): boolean => {
    if (selectedCategories.length <= 0 || selectedAuthors.length <= 0) {
      if (selectedCategories.length <= 0) {
        dispatch(
          openSnackbar({
            type: 'error',
            message: !isPresentationPage
              ? POST_ERRORS.MIN_CATEGORIES_QUANTITY
              : POST_ERRORS.REVIEW_PRESENTATION_MIN_CATEGORIES_QUANTITY,
          }),
        );
        return false;
      } else if (selectedAuthors.length <= 0 && !isPresentationPage) {
        dispatch(
          openSnackbar({
            type: 'error',
            message: !isPresentationPage
              ? POST_ERRORS.MIN_AUTHORS_QUANTITY
              : POST_ERRORS.REVIEW_PRESENTATION_MIN_AUTHORS_QUANTITY,
          }),
        );
        return false;
      }
    } else if (!checkIfPostHasTitle()) {
      dispatch(
        openSnackbar({
          type: 'error',
          message: POST_ERRORS.EMPTY_TITLE,
        }),
      );
      return false;
    } else if (!checkIfPostHasKeyIdeas() && !isPresentationPage) {
      dispatch(
        openSnackbar({
          type: 'error',
          message: POST_ERRORS.KEY_IDEAS,
        }),
      );
      return false;
    } else if (!checkIfPostHasMainImage()) {
      dispatch(
        openSnackbar({
          type: 'error',
          message: !isPresentationPage
            ? POST_ERRORS.HEADER_IMAGE
            : POST_ERRORS.PRESENTATION_HEADER_IMAGE,
        }),
      );
      return false;
    } else if (!checkImagesQuantityOfPost()) {
      dispatch(
        openSnackbar({
          type: 'error',
          message: POST_ERRORS.MAX_IMAGES_QUANTITY,
        }),
      );
      return false;
    } else if (!checkIfPostHasPDF() && isPresentationPage) {
      dispatch(
        openSnackbar({
          type: 'error',
          message: POST_ERRORS.NO_PDF,
        }),
      );
      return false;
    } else if (
      selectedCategories?.length > MAX_CATEGORIES_QUANTITY ||
      selectedAuthors?.length > MAX_AUTHORS_QUANTITY ||
      selectedTags?.length > MAX_TAGS_QUANTITY
    ) {
      return false;
    }
    return true;
  };

  const checkPostToPublish = (): boolean => {
    if (selectedCategories.length <= 0 || selectedAuthors.length <= 0) {
      if (selectedCategories.length <= 0) {
        dispatch(
          openSnackbar({
            type: 'error',
            message: !isPresentationPage
              ? POST_ERRORS.PUBLISH_MIN_CATEGORIES_QUANTITY
              : POST_ERRORS.PUBLISH_PRESENTATION_MIN_CATEGORIES_QUANTITY,
          }),
        );
        return false;
      } else if (selectedAuthors.length <= 0 && !isPresentationPage) {
        dispatch(
          openSnackbar({
            type: 'error',
            message: !isPresentationPage
              ? POST_ERRORS.PUBLISH_MIN_AUTHORS_QUANTITY
              : POST_ERRORS.PUBLISH_PRESENTATION_MIN_AUTHORS_QUANTITY,
          }),
        );
        return false;
      }
    } else if (!checkIfPostHasTitle()) {
      dispatch(
        openSnackbar({
          type: 'error',
          message: POST_ERRORS.PUBLISH_EMPTY_TITLE,
        }),
      );
      return false;
    } else if (!checkIfPostHasKeyIdeas() && !isPresentationPage) {
      dispatch(
        openSnackbar({
          type: 'error',
          message: POST_ERRORS.PUBLISH_KEY_IDEAS,
        }),
      );
      return false;
    } else if (!checkIfPostHasMainImage()) {
      dispatch(
        openSnackbar({
          type: 'error',
          message: !isPresentationPage
            ? POST_ERRORS.PUBLISH_HEADER_IMAGE
            : POST_ERRORS.PUBLISH_PRESENTATION_HEADER_IMAGE,
        }),
      );
      return false;
    } else if (!checkImagesQuantityOfPost()) {
      dispatch(
        openSnackbar({
          type: 'error',
          message: POST_ERRORS.MAX_IMAGES_QUANTITY,
        }),
      );
      return false;
    } else if (!checkIfPostHasPDF() && isPresentationPage) {
      dispatch(
        openSnackbar({
          type: 'error',
          message: POST_ERRORS.PUBLISH_NO_PDF,
        }),
      );
      return false;
    } else if (
      selectedCategories?.length > MAX_CATEGORIES_QUANTITY ||
      selectedAuthors?.length > MAX_AUTHORS_QUANTITY ||
      selectedTags?.length > MAX_TAGS_QUANTITY
    ) {
      return false;
    }
    return true;
  };

  const sendToReview = async () => {
    if (!changingState) {
      try {
        setChangingState(true);
        if (!checkPostInformation()) {
          setChangingState(false);
          return;
        }
        if (unsavedChanges) {
          await handleClickSaveDraft();
        }
        await requestReviewForPost(postId);
        setIsOpen(false);
        dispatch(
          openSnackbar({
            type: 'success',
            message: isPresentationPage
              ? ARTICLE_SUCCESFUL_MESSAGES.SEND_TO_REVIEW_PRESENTATION
              : ARTICLE_SUCCESFUL_MESSAGES.SEND_TO_REVIEW_ARTICLE,
          }),
        );
        dispatch(
          setHomeDraftPostUpdated({
            draftPostUpdated: true,
          }),
        );
        dispatch(
          setDraftPostUpdated({
            draftPostUpdated: true,
          }),
        );
        dispatch(
          setPendingReviewPostAdded({
            pendingReviewPostAdded: true,
          }),
        );
        navigate(
          `${
            isPresentationPage ? '/presentations' : '/entries'
          }?type=${POSTS_STATUS.PENDING_REVIEW.toLowerCase()}`,
        );
      } catch (error: unknown) {
        setChangingState(false);
        let errorMessage = POST_ERRORS.REQUEST_REVIEW;
        let errorStatus;
        if (error instanceof AxiosError) {
          const errorData = error?.response?.data;
          errorStatus = error?.response?.status;
          if (errorData?.message) {
            errorMessage = errorData?.message;
          }
        }
        if (errorStatus !== 401) {
          dispatch(
            openSnackbar({
              type: 'error',
              message: errorMessage,
            }),
          );
        }
      }
    }
  };

  const handlePublishPost = async (overwritePriorPublication: boolean) => {
    if (!changingState) {
      try {
        if (postId !== undefined) {
          if (unsavedChanges) {
            await handleClickSaveDraft();
          }
          if (!checkPostToPublish()) {
            return;
          }
          setChangingState(true);
          const response = await publishPost(postId as number, overwritePriorPublication);
          if (response === 200) {
            setIsOpen(false);
            dispatch(
              openSnackbar({
                type: 'success',
                message: isPresentationPage
                  ? ARTICLE_SUCCESFUL_MESSAGES.PUBLISH_PRESENTATION
                  : ARTICLE_SUCCESFUL_MESSAGES.PUBLISH_ARTICLE,
              }),
            );
            if (isPresentationPage) {
              if (
                presentationsPendingReviewQuantity !== null &&
                presentationsPendingReviewQuantity !== undefined
              ) {
                dispatch(
                  setPresentationsPendingReviewQuantity({
                    pendingReviewQuantity: presentationsPendingReviewQuantity - 1,
                  }),
                );
              }
            } else {
              if (pendingReviewQuantity !== null && pendingReviewQuantity !== undefined) {
                dispatch(
                  setPendingReviewQuantity({
                    pendingReviewQuantity: pendingReviewQuantity - 1,
                  }),
                );
              }
            }
            dispatch(
              setPendingReviewPostAdded({
                pendingReviewPostAdded: true,
              }),
            );
            dispatch(
              setPublishedPostAdded({
                publishedPostAdded: true,
              }),
            );
            setIsNotificationModalVisible(true);
          } else {
            setChangingState(false);
            setIsOpen(false);
          }
        }
      } catch (error: unknown) {
        setChangingState(false);
        let errorStatus;
        if (error instanceof AxiosError) {
          const errorData = error?.response?.data;
          errorStatus = error?.response?.status;
        }
        if (errorStatus === 428) {
          setConfirmPublicationModalIsVisible(true);
        } else if (errorStatus !== 401) {
          dispatch(
            openSnackbar({
              type: 'error',
              message: POST_ERRORS.PUBLISH,
            }),
          );
        }
      }
    }
  };

  const handleClickSendToReview = async (): Promise<void> => {
    if (currentStatus === POSTS_STATUS.REVIEW_IN_PROGRESS) {
      handlePublishPost(false);
    } else {
      sendToReview();
    }
  };

  const getSaveButtonText = () => {
    return currentStatus === POSTS_STATUS.REVIEW_IN_PROGRESS ? 'Guardar' : 'Guardar borrador';
  };

  const getSendToReviewButtonText = () => {
    return currentStatus === POSTS_STATUS.REVIEW_IN_PROGRESS ? 'Publicar' : 'Pasar a revisión';
  };

  return (
    <>
      <Container>
        <CustomButton
          disabled={
            changingState ||
            savingChanges ||
            currentStatus == null ||
            isRemovingSubscription ||
            isRemovingCategory ||
            isRemovingTag ||
            isRemovingAuthor
          }
          onClick={handleClickSendToReview}
          bgColor='#183582'
          withBorder={false}
        >
          {changingState || currentStatus == null || savingChanges ? (
            <CircularProgress size={25} style={{ color: 'white' }} />
          ) : (
            <ButtonText color='white'>{getSendToReviewButtonText()}</ButtonText>
          )}
        </CustomButton>
      </Container>
      <ConfirmPublicationModal
        isVisible={confirmPublicationModalIsVisible}
        setIsVisible={setConfirmPublicationModalIsVisible}
        publishing={changingState}
        setPublishing={setChangingState}
        callApiToPublishAPost={handlePublishPost}
        contentType={isPresentationPage ? FILE_TYPE : HTML_TEXT}
      />
    </>
  );
}

const Container = styled.footer`
  position: fixed;
  bottom: 0px;
  z-index: 999;
  width: 385px;
  background-color: white;
  padding: 0px 20px;
  gap: 7px;
  padding-bottom: 17px;
  padding-top: 13px;
  border-top: 1px solid ${(props) => props.theme.colors.gray2};

  @media screen and (max-width: 860px) {
    width: 100vw;
    display: flex;
    flex-direction: column;
  }
`;

const CustomButton = styled(Button)<ButtonI>`
  &&& {
    background-color: ${(props) => props.bgColor};
    border-radius: 20px;
    width: 100%;
  }
  height: 44px;
  border: 1px solid ${(props) => (props.withBorder ? props.theme.colors.lightGray : 'white')};
`;

const ButtonText = styled.p`
  font-family: ${(props) => props.theme.fonts.semiBold};
  color: ${(props) => props.color};
  text-transform: none;
  font-size: 15px;
`;
