import React from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import ModalContainer from '../globals/ModalContainer';

interface Props {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  entriesQuantity: number;
}

interface CustomButtonI {
  withBackground: boolean;
}

interface CustomButtonTextI {
  white: boolean;
}

export default function ConfirmDeleteModal({
  isVisible,
  setIsVisible,
  entriesQuantity,
}: Props): JSX.Element {
  const handleClose = (): void => {
    setIsVisible(false);
  };

  const deleteTag = (): void => {};

  return (
    <ModalContainer isVisible={isVisible} setIsVisible={setIsVisible}>
      <Container>
        <Title>¿Eliminar encuesta?</Title>
        <Message>
          Si eliminas esta encuesta, se quitará de 25 entradas. No puedes deshacer esta acción
        </Message>
        <ButtonsContainer>
          <CustomButton onClick={handleClose} withBackground={false}>
            <ButtonText white={false}>Cancelar</ButtonText>
          </CustomButton>
          <CustomButton onClick={deleteTag} withBackground={true}>
            <ButtonText white={true}>Eliminar definitivamente</ButtonText>
          </CustomButton>
        </ButtonsContainer>
      </Container>
    </ModalContainer>
  );
}

const Container = styled.div``;

const Title = styled.p`
  font-family: ${(props) => props.theme.fonts.bold};
  color: ${(props) => props.theme.colors.black};
  font-size: 20px;
  margin-bottom: 15px;
`;

const Message = styled.p`
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => props.theme.colors.black};
  margin-bottom: 20px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
`;

const CustomButton = styled(Button)<CustomButtonI>`
  &&& {
    border: 1px solid;
    border-color: ${(props) => props.theme.colors.red};
    border-radius: 5px;
    background-color: ${(props) =>
      props?.withBackground ? props.theme.colors.red : 'transparent'};
  }
`;

const ButtonText = styled.p<CustomButtonTextI>`
  text-transform: none;
  font-family: ${(props) => props.theme.fonts.bold};
  color: ${(props) => (props.white ? 'white' : props.theme.colors.red)};
  font-size: 13px;
  padding: 0px 15px;
`;
