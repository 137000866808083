import { createSlice } from '@reduxjs/toolkit';
import type DraftInterface from '../../interfaces/Draft';

interface InitialState {
  drafts: DraftInterface[] | null;
  currentPage: number;
  draftsTotal: number;
  isLoadingMoreData: boolean;
  canLoadMoreData: boolean;
  isDrawerOpen: boolean;
}

const initialState: InitialState = {
  drafts: null,
  currentPage: 0,
  draftsTotal: 0,
  isLoadingMoreData: false,
  canLoadMoreData: false,
  isDrawerOpen: false,
};

const presentationsDraftsSlice = createSlice({
  name: 'presentationsDrafts',
  initialState,
  reducers: {
    setPresentationsDraftsResetValues: (state, action) => {
      state.drafts = null;
      state.currentPage = 0;
      state.draftsTotal = 0;
      state.isLoadingMoreData = false;
      state.canLoadMoreData = false;
    },
    setPresentationsDrafts: (state, action) => {
      state.drafts = action.payload.drafts;
    },
    setPresentationsDraftsCurrentPage: (state, action) => {
      state.currentPage = action.payload.currentPage;
    },
    setPresentationsDraftsTotal: (state, action) => {
      state.draftsTotal = action.payload.draftsTotal;
    },
    setPresentationsDraftsIsLoadingMoreData: (state, action) => {
      state.isLoadingMoreData = action.payload.isLoadingMoreData;
    },
    setPresentationsDraftsCanLoadMoreData: (state, action) => {
      state.canLoadMoreData = action.payload.canLoadMoreData;
    },
    setPresentationsDraftsIsDrawerOpen: (state, action) => {
      state.isDrawerOpen = action.payload.isDrawerOpen;
    },
  },
});

export const {
  setPresentationsDrafts,
  setPresentationsDraftsCanLoadMoreData,
  setPresentationsDraftsCurrentPage,
  setPresentationsDraftsIsLoadingMoreData,
  setPresentationsDraftsTotal,
  setPresentationsDraftsResetValues,
  setPresentationsDraftsIsDrawerOpen,
} = presentationsDraftsSlice.actions;
export default presentationsDraftsSlice.reducer;
