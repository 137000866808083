import React from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import NewPublicationBtn from '../home/NewPublicationBtn';
import { setAuthorsResetValues } from '../../context/reducers/authors';
import { setTagsResetValues } from '../../context/reducers/tags';

interface Props {
  title: string;
  type: 'publication' | 'tag' | 'author' | 'survey' | 'presentation';
  buttonText: string;
  setModalIsVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  searchInput?: string;
  setSearchInput?: React.Dispatch<React.SetStateAction<string>>;
  handleInputChange?: (n: string) => void;
  callApiToResetValues?: () => void;
}

export default function HeaderButtonWithText({
  title,
  type,
  buttonText,
  setModalIsVisible,
  searchInput = '',
  setSearchInput,
  handleInputChange,
  callApiToResetValues,
}: Props): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleOnClick = (): void => {
    if (type === 'tag' && setModalIsVisible !== undefined) {
      if (searchInput?.length >= 2) {
        dispatch(setTagsResetValues({}));
        if (setSearchInput !== undefined) {
          setSearchInput('');
        }
        if (callApiToResetValues !== undefined) {
          callApiToResetValues();
        }
      }
      setModalIsVisible(true);
    } else if (type === 'author') {
      if (searchInput?.length >= 2) {
        dispatch(setAuthorsResetValues({}));
      }
      navigate('/authors/new-author');
    } else if (type === 'survey') {
      navigate('/surveys/new-survey');
    } else if (type === 'publication') {
      navigate('/new-post', {
        state: {
          prevRouteName: location.pathname,
        },
      });
    } else if (type === 'presentation') {
      navigate('/new-presentation', {
        state: {
          prevRouteName: location.pathname,
        },
      });
    }
  };

  return (
    <Container>
      <Title>{title}</Title>
      <BtnContainer>
        <NewPublicationBtn buttonText={buttonText} handleOnClick={handleOnClick} />
      </BtnContainer>
    </Container>
  );
}

const Container = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 42px;

  @media screen and (max-width: 860px) {
    padding: 0px 24px;
  }
`;

const Title = styled.p`
  font-size: 28px;
  font-family: ${(props) => props.theme.fonts.semiBold};
  color: ${(props) => props.theme.colors.primary};
  flex: 1;
`;

const BtnContainer = styled.div`
  @media screen and (max-width: 860px) {
    display: none;
  }
`;
