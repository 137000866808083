import React from 'react';
import type IconInterface from '../interfaces/Icon';

export default function UploadIcon({ color = '#0094CF' }: IconInterface): JSX.Element {
  return (
    <svg width='21' height='19' viewBox='0 0 21 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2.83366 17H18.167V10H20.0837V18C20.0837 18.2652 19.9827 18.5196 19.803 18.7071C19.6232 18.8946 19.3795 19 19.1253 19H1.87533C1.62116 19 1.3774 18.8946 1.19768 18.7071C1.01796 18.5196 0.916992 18.2652 0.916992 18V10H2.83366V17ZM12.417 7V13H8.58366V7H3.79199L10.5003 0L17.2087 7H12.417Z'
        fill={color}
      />
    </svg>
  );
}
