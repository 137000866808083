import React, { useEffect } from 'react';
import { Link } from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import CustomLinkModal from '../CustomLinkModal';
import { IconButton } from '@mui/material';
import { type RootState } from '../../../context/store';
import {
  setCurrentSelectedEditorState,
  setCurrentSelectedLink,
  toggleIsCustomLinkModalVisible,
  toogleEditSomeStyleOfCurrentSelectedEditor,
} from '../../../context/reducers/editor';
import styled from 'styled-components';
import { EditorState, SelectionState, convertToRaw } from 'draft-js';
import {
  KEY_IDEA_ITEM,
  KEY_PARAGRAPH,
  WYSIWYG_MAIN_SUBTITLE,
  WYSIWYG_MAIN_TITLE,
} from '../../../constants/editorKeys';
import POSTS_STATUS from '../../../constants/status';
import MobileCustomLinkModal from '../MobileCustomLinkModal';

interface Props {
  type?: string;
  showToolBar?: boolean;
}

export default function LinkOption({ type = 'main', showToolBar }: Props) {
  const currentSelectedEditorState = useSelector(
    (state: RootState) => state.editor.currentSelectedEditorState,
  );
  const currentPostStatus = useSelector((state: RootState) => state.editor.currentPostStatus);
  const selectedEditorKey = useSelector((state: RootState) => state.editor.selectedEditorKey);
  const isSecondaryToolBarVisible = useSelector(
    (state: RootState) => state.editor.isSecondaryToolBarVisible,
  );
  const isLinkModalOpen = useSelector((state: RootState) => state.editor.isCustomLinkModalVisible);
  const dispatch = useDispatch();

  const getCurrentEntity = () => {
    const selection = currentSelectedEditorState.getSelection();
    const contentState = currentSelectedEditorState.getCurrentContent();
    const blockKey = selection.getStartKey();
    const block = contentState.getBlockForKey(blockKey);
    const entityKey = block.getEntityAt(selection.getStartOffset());
    return contentState.getEntity(entityKey);
  };

  const getCurrentEntityType = (): string | undefined => {
    const selection = currentSelectedEditorState.getSelection();
    const contentState = currentSelectedEditorState.getCurrentContent();
    const blockKey = selection.getStartKey();
    const block = contentState.getBlockForKey(blockKey);
    const entityKey = block.getEntityAt(selection.getStartOffset());

    if (entityKey) {
      const entity = contentState.getEntity(entityKey);
      return entity.getType();
    }

    return undefined;
  };

  const checkToShowLinkModal = () => {
    if (isSecondaryToolBarVisible && type === 'main') {
      return false;
    } else if (!isSecondaryToolBarVisible && type === 'secondary') {
      return false;
    }
    return true;
  };

  const openModal = () => {
    dispatch(
      toggleIsCustomLinkModalVisible({
        isCustomLinkModalVisible: true,
      }),
    );
  };

  const checkIfButtonIsDisabled = (): boolean => {
    if (
      currentSelectedEditorState == null ||
      selectedEditorKey == null ||
      selectedEditorKey !== KEY_PARAGRAPH
    ) {
      return true;
    }
    return false;
  };

  return (
    <Container>
      <IconButton onClick={openModal} disabled={checkIfButtonIsDisabled()}>
        <Link />
      </IconButton>
      {isLinkModalOpen && checkToShowLinkModal() && (
        <>
          <LinkModalContainer>
            <CustomLinkModal />
          </LinkModalContainer>
        </>
      )}
      {isLinkModalOpen && (
        <MobileLinkModalContainer>
          <MobileCustomLinkModal />
        </MobileLinkModalContainer>
      )}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
`;

const LinkModalContainer = styled.div`
  @media screen and (max-width: 860px) {
    display: none;
  }
`;

const MobileLinkModalContainer = styled.div`
  display: none;
  @media screen and (max-width: 860px) {
    display: block;
  }
`;
