import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ComboBox from './ComboBox';
import { openSnackbar } from '../../context/reducers/generalSnackbar';
import { CATEGORIES_ERRORS, POST_ERRORS, SETTINGS_ERRORS } from '../../constants/errorMessages';
import { getCategories } from '../../api/categories';
import type Category from '../../interfaces/Category';
import { type RootState } from '../../context/store';
import {
  setCategoriesList,
  setCategoryId,
  setIsRemovingCategory,
} from '../../context/reducers/categories';
import { AxiosError } from 'axios';
import { useParams } from 'react-router-dom';
import { callPostDetails } from '../../utils/editor';
import { addCategoriesToPost, deleteCategoryFromPost } from '../../api/posts';
import { MAX_CATEGORIES_QUANTITY } from '../../constants/globals';
import {
  SETTINGS_ADD_SUCCESFUL_MESSAGES,
  SETTINGS_REMOVE_SUCCESFUL_MESSAGES,
} from '../../constants/successfulMessages';

interface Props {
  page?: string;
  categoriesIDs: any[];
  setCategoriesIDs: React.Dispatch<React.SetStateAction<any[]>>;
  label?: string;
  parent?: string;
  placeholder?: string;
  setCurrentVersion?: React.Dispatch<React.SetStateAction<number>>;
}

export default function ComboBoxCategoriesWrapped({
  page = 'entries',
  categoriesIDs,
  setCategoriesIDs,
  label = '',
  parent,
  placeholder = 'Categoría',
  setCurrentVersion,
}: Props) {
  const categoriesList = useSelector((state: RootState) => state.categories.categoriesList);
  const [auxSelectedCategoriesIDs, setAuxSelectedCategoriesIDs] = useState(categoriesIDs);
  const dispatch = useDispatch();
  const { postId } = useParams();
  const isMounted = useRef(false);

  const callApiToGetCategories = (): void => {
    getCategories()
      .then((res) => {
        if (isMounted.current) {
          dispatch(
            setCategoriesList({
              categoriesList: res,
            }),
          );
        }
      })
      .catch((error: unknown) => {
        let errorStatus;
        if (error instanceof AxiosError) {
          errorStatus = error?.response?.status;
        }
        if (errorStatus !== 401) {
          dispatch(
            openSnackbar({
              type: 'error',
              message: CATEGORIES_ERRORS.CATEGORIES_LIST,
            }),
          );
        }
      });
  };

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (categoriesList === null) {
      callApiToGetCategories();
    }
  }, []);

  const getAddedCategory = (
    currentSelectedCategories: any[],
    newCurrentSelectedCategories: any[],
  ): string[] => {
    const addedCategories: any[] = [];
    let band = false;
    newCurrentSelectedCategories.forEach((selectedCategory) => {
      currentSelectedCategories.forEach((currentCategory) => {
        if ((selectedCategory?.id ?? selectedCategory) === currentCategory) {
          band = true;
        }
      });
      if (!band) {
        addedCategories.push(selectedCategory?.id ?? selectedCategory);
      }
      band = false;
    });
    return addedCategories.slice(0, 1);
  };

  const handleAddCategory = async (addedCategory: string[]) => {
    try {
      dispatch(
        setIsRemovingCategory({
          isRemovingCategory: true,
        }),
      );
      dispatch(
        setCategoryId({
          categoryId: addedCategory[0],
        }),
      );
      await addCategoriesToPost(postId, addedCategory);
      dispatch(
        openSnackbar({
          type: 'success',
          message: SETTINGS_ADD_SUCCESFUL_MESSAGES.ADD_CATEGORY,
        }),
      );
      dispatch(
        setIsRemovingCategory({
          isRemovingCategory: false,
        }),
      );
      dispatch(
        setCategoryId({
          categoryId: null,
        }),
      );
    } catch (error: unknown) {
      dispatch(
        openSnackbar({
          type: 'error',
          message: SETTINGS_ERRORS.ADD_CATEGORY,
        }),
      );
      dispatch(
        setIsRemovingCategory({
          isRemovingCategory: false,
        }),
      );
    }
  };

  const getDeletedCategory = (
    currentSelectedCategories: any[],
    newCurrentSelectedCategories: any[],
  ): string => {
    const deletedCategories: any[] = [];
    let band = false;
    currentSelectedCategories.forEach((currentCategory) => {
      newCurrentSelectedCategories.forEach((selectedCategory) => {
        if (currentCategory === (selectedCategory?.id ?? selectedCategory)) {
          band = true;
        }
      });
      if (!band) {
        deletedCategories.push(currentCategory);
      }
      band = false;
    });
    return deletedCategories[0]?.id ?? deletedCategories[0];
  };

  const handleRemoveCategory = async (deletedCategory: string) => {
    try {
      dispatch(
        setIsRemovingCategory({
          isRemovingCategory: true,
        }),
      );
      dispatch(
        setCategoryId({
          categoryId: deletedCategory,
        }),
      );
      await deleteCategoryFromPost(postId, deletedCategory);
      dispatch(
        openSnackbar({
          type: 'success',
          message: SETTINGS_REMOVE_SUCCESFUL_MESSAGES.REMOVE_CATEGORY,
        }),
      );
      dispatch(
        setIsRemovingCategory({
          isRemovingCategory: false,
        }),
      );
      dispatch(
        setCategoryId({
          categoryId: null,
        }),
      );
    } catch (err: unknown) {
      dispatch(
        setIsRemovingCategory({
          isRemovingCategory: false,
        }),
      );
    }
  };

  const handleChange = async (
    values: any[] | string | null,
    deleteFromTagChip: React.MutableRefObject<boolean>,
  ): Promise<void> => {
    try {
      if (page === 'editor' && !deleteFromTagChip.current) {
        const previousSelectedCategoriesIDs = [...auxSelectedCategoriesIDs];
        if (isMounted.current) {
          if (values !== undefined && values !== null) {
            if (
              previousSelectedCategoriesIDs.length < values?.length &&
              values?.length <= MAX_CATEGORIES_QUANTITY
            ) {
              const addedCategory = getAddedCategory(
                previousSelectedCategoriesIDs,
                values as any[],
              );
              await handleAddCategory(addedCategory);
            } else {
              const deletedCategory = getDeletedCategory(
                previousSelectedCategoriesIDs,
                values as any[],
              );
              await handleRemoveCategory(deletedCategory);
            }
            if (postId !== undefined && setCurrentVersion !== undefined) {
              await callPostDetails(postId, setCurrentVersion);
            }
          }
        }
      }
      if (Array.isArray(values)) {
        setCategoriesIDs(
          values?.map((item) => {
            return item?.id ?? item;
          }),
        );
        if (page === 'editor' && values?.length <= MAX_CATEGORIES_QUANTITY) {
          setAuxSelectedCategoriesIDs(
            values?.map((item) => {
              return item?.id ?? item;
            }),
          );
        }
      }
    } catch (err) {}
  };

  return (
    <ComboBox
      parent={parent ?? undefined}
      label={label}
      placeholder={placeholder}
      multiple={true}
      values={page === 'entries' ? categoriesIDs : auxSelectedCategoriesIDs}
      handleChange={handleChange}
      auxOptions={categoriesList}
      type='categories'
      page={page}
      setCurrentVersion={setCurrentVersion}
    />
  );
}
