import React, { useState } from 'react';
import styled from 'styled-components';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Tooltip, type PopoverOrigin } from '@mui/material';

interface Props {
  anchorEl: null | HTMLElement;
  setAnchorEl: React.Dispatch<React.SetStateAction<null | HTMLElement>>;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  options: Option[];
  color?: string | null;
}

interface StyledMenuI {
  bgColor?: string;
}

interface Option {
  id?: string;
  icon?: React.ReactNode;
  isOwner?: boolean;
  label: string;
  action: (e?: any) => void;
  color?: string;
}

interface MenuItemLabelI {
  customColor: string | null;
}

export default function CustomMenu({
  anchorEl,
  setAnchorEl,
  anchorOrigin = { vertical: 'bottom', horizontal: 'left' },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'left',
  },
  options,
  color = null,
}: Props): JSX.Element {
  const open = Boolean(anchorEl);

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <StyledMenu
      open={open}
      onClose={handleClose}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
    >
      {options.map((opt, index) => {
        if (opt?.id === 'delete-post' && !opt?.isOwner) {
          return (
            <>
              <StyleMenuItem bgColor={opt.color} key={index} onClick={() => {}}>
                {opt.icon !== undefined && <IconContainer>{opt.icon}</IconContainer>}
                <MenuItemLabel customColor={'#c1c1c1'}>{opt.label}</MenuItemLabel>
              </StyleMenuItem>
            </>
          );
        }
        return (
          <StyleMenuItem
            bgColor={opt.color}
            key={index}
            onClick={() => {
              opt.action();
            }}
          >
            {opt.icon !== undefined && <IconContainer>{opt.icon}</IconContainer>}
            <MenuItemLabel customColor={opt?.color ?? color}>{opt.label}</MenuItemLabel>
          </StyleMenuItem>
        );
      })}
    </StyledMenu>
  );
}

const StyledMenu = styled(Menu)`
  .MuiPaper-root {
    border-radius: 8px;
  }
`;

const StyleMenuItem = styled(MenuItem)<StyledMenuI>`
  margin: 0px 10px;
  border-radius: 5px;
  &:hover {
    background-color: ${(props) => props.theme.colors.lightSkyBlue};
  }
`;

const IconContainer = styled.div`
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.gray2};
  font-weight: bold;
  ${StyleMenuItem}:hover & {
    color: ${(props) => props.theme.colors.primary};
  }
`;

const MenuItemLabel = styled.p<MenuItemLabelI>`
  margin-left: 8px;
  font-family: ${(props) => props.theme.fonts.regular};
  color: ${(props) => (props.customColor ? props.customColor : props.theme.colors.gray2)};
  font-size: 14px;
  padding-right: 40px;
`;
