import { createSlice } from '@reduxjs/toolkit';

interface InitialStateI {
  isVisible: boolean;
  searchInput: string;
  generalSearchPosts: any[] | null;
  generalSearchPostsTotal: number;
  generalSearchPostsCurrentPage: number;
  generalSearchPostsCanLoadMoreData: boolean;
  generalSearchPostsIsLoadingMoreData: boolean;
}

const initialState: InitialStateI = {
  isVisible: false,
  searchInput: '',
  generalSearchPosts: null,
  generalSearchPostsTotal: 0,
  generalSearchPostsCurrentPage: 0,
  generalSearchPostsCanLoadMoreData: false,
  generalSearchPostsIsLoadingMoreData: false,
};

const generalSearchSlice = createSlice({
  name: 'generalSearch',
  initialState,
  reducers: {
    openSearchBar: (state, action) => {
      state.isVisible = true;
    },
    closeSearchBar: (state, action) => {
      state.isVisible = false;
    },
    setInputOfGeneralSearch: (state, action) => {
      state.searchInput = action.payload.searchInput;
    },
    setGeneralSearchPosts: (state, action) => {
      state.generalSearchPosts = action.payload.generalSearchPosts;
    },
    setGeneralSearchPostsCurrentPage: (state, action) => {
      state.generalSearchPostsCurrentPage = action.payload.generalSearchPostsCurrentPage;
    },
    setGeneralSearchPostsTotal: (state, action) => {
      state.generalSearchPostsTotal = action.payload.generalSearchPostsTotal;
    },
    setGeneralSearchPostsCanLoadMoreData: (state, action) => {
      state.generalSearchPostsCanLoadMoreData = action.payload.generalSearchPostsCanLoadMoreData;
    },
    setGeneralSearchPostsIsLoadingMoreData: (state, action) => {
      state.generalSearchPostsIsLoadingMoreData =
        action.payload.generalSearchPostsIsLoadingMoreData;
    },
  },
});

export const {
  openSearchBar,
  setInputOfGeneralSearch,
  closeSearchBar,
  setGeneralSearchPosts,
  setGeneralSearchPostsCurrentPage,
  setGeneralSearchPostsTotal,
  setGeneralSearchPostsCanLoadMoreData,
  setGeneralSearchPostsIsLoadingMoreData,
} = generalSearchSlice.actions;
export default generalSearchSlice.reducer;
