import React from 'react';
import type Icon from '../interfaces/Icon';

export default function ImageIcon({ color = '#3C3C3B' }: Icon): JSX.Element {
  return (
    <svg width='18' height='14' viewBox='0 0 18 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.9571 6.14282L14.2143 10.4285H3.92857L6.5 7.08568L8.3 9.39997L10.7857 6.14282H10.9571ZM6.07143 6.14282C6.75714 6.14282 7.35714 5.54282 7.35714 4.85711C7.35714 4.17139 6.75714 3.57139 6.07143 3.57139C5.38571 3.57139 4.78571 4.17139 4.78571 4.85711C4.78571 5.54282 5.38571 6.14282 6.07143 6.14282ZM17.6429 1.85711V12.1428C17.6429 13.0857 16.8714 13.8571 15.9286 13.8571H2.21429C1.27143 13.8571 0.5 13.0857 0.5 12.1428V1.85711C0.5 0.914251 1.27143 0.142822 2.21429 0.142822H15.9286C16.8714 0.142822 17.6429 0.914251 17.6429 1.85711ZM15.9286 4.25711V1.85711H2.21429V12.1428H15.9286V4.25711Z'
        fill={color}
      />
    </svg>
  );
}
