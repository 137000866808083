import React from 'react';
import type Icon from '../interfaces/Icon';

export default function PendingReviewPlaceholderIcon({
  width = 26,
  height = 25,
  color = '#9A9A9A',
}: Icon) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 26 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_1468_2714)'>
        <path
          d='M21.0988 19.811H12.718'
          stroke={color}
          strokeWidth='1.46708'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M21.0988 16.877H13.6034'
          stroke={color}
          strokeWidth='1.46708'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M21.0988 13.9429H17.2663'
          stroke={color}
          strokeWidth='1.46708'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M21.0988 11.0085H18.0697'
          stroke={color}
          strokeWidth='1.46708'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M21.0988 5.14038H14.3796'
          stroke={color}
          strokeWidth='1.46708'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M21.0988 8.07446H17.4199'
          stroke={color}
          strokeWidth='1.46708'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M9.0503 16.5138V24.261H24.7665V0.739014H9.0503V5.21283'
          stroke={color}
          strokeWidth='1.46708'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M16.232 15.3037C18.6842 12.8514 18.6842 8.87561 16.232 6.42339C13.7798 3.97118 9.80398 3.97118 7.35176 6.42339C4.89954 8.87561 4.89954 12.8514 7.35176 15.3037C9.80398 17.7559 13.7798 17.7559 16.232 15.3037Z'
          stroke={color}
          strokeWidth='1.46708'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M1.66472 20.9906C1.08982 20.4157 1.08982 19.4836 1.66472 18.9087L4.06564 16.5078L6.14753 18.5897L3.7466 20.9906C3.1717 21.5655 2.23962 21.5655 1.66472 20.9906Z'
          stroke={color}
          strokeWidth='1.46708'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M5.10815 17.5503L7.35186 15.3035'
          stroke={color}
          strokeWidth='1.46708'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M17.7277 12.9167H11.4797'
          stroke={color}
          strokeWidth='1.46708'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M18.0095 9.98242H11.4797'
          stroke={color}
          strokeWidth='1.46708'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M16.8009 7.04834H11.4797'
          stroke={color}
          strokeWidth='1.46708'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_1468_2714'>
          <rect width='25' height='25' fill='white' transform='translate(0.5)' />
        </clipPath>
      </defs>
    </svg>
  );
}
